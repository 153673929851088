import React from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { useSelector } from "react-redux";
import Step1A from "./Step1a";
import VirtualTradeStep1 from "./virtual-trade/VirtualTradeStep1";
import TradeOptionsSelector from "./TradeOptionsSelector";

function Step1C({ step, setShow }) {
  const {
    tradeState: { currentStep, tradeProcess },
  } = useSelector((state) => state);

  return (
    <div className={`${step === "1-c" ? styles.active : ""} ${styles.step}`}>
      <TradeOptionsSelector mode={"ai-btn"} />

      {tradeProcess === "exchange" && (
        <Step1A step={currentStep} setShow={setShow} />
      )}
      {tradeProcess === "paperTrading" && <VirtualTradeStep1 />}
    </div>
  );
}

export default Step1C;

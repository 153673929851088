import { Button, Modal } from "antd";
import warningIcon from "../../asset/Icons/warn100.gif";
import { useDispatch, useSelector } from "react-redux";
import { deleteBot } from "../../redux/bot/actions";
import styles from "../../styles/Components/bot/ConfirmationModal.module.css";

function ConfirmationModal({ show, setShow, id }) {
  const {
    botState: { deleteBotLoading },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const closeHandler = () => setShow(false);
  const submitHandler = async () => {
    if (deleteBotLoading) return;
    await dispatch(
      deleteBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        id,
      ),
    ).then(() => {
      setShow(false);
    });
  };

  return (
    <Modal
      centered
      open={show}
      onCancel={() => setShow(false)}
      footer={null}
      width={350}
      children={
        <div className={styles.container}>
          <img src={warningIcon} alt={"warning"} />
          <h3 className={styles.title}>
            Are you sure you want to delete this bot?
            <p>This action cannot be undone!</p>
          </h3>
          <div className={styles.btns}>
            <Button className={`${styles.btn}`} onClick={closeHandler}>
              Close
            </Button>
            <Button
              className={`${styles.btn} ${styles.submitBtn}`}
              onClick={submitHandler}
              type={"primary"}
              loading={deleteBotLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default ConfirmationModal;

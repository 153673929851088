// STYLES
import "../../styles/Components/faq/faqPublic.css";

// Images
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import port1 from "../../asset/Images/faq/AI Assistant/Portfolio/1.PNG";
import port2 from "../../asset/Images/faq/AI Assistant/Portfolio/2.png";
import in2 from "../../asset/Images/faq/Insights/HowToUseAISignals/2.png";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PortfolioSection = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="helpTitle">Portfolio Section</h3>
      <ul className="helpList">
        <p>
          In the Portfolio section, you can see your two separated portfolios.
          These portfolios are for testing and evaluating purposes only, and the
          trades are conducted with virtual money. Here's how you can use this
          section:
        </p>
        <li>
          To view your portfolio's current holdings and their current market
          values, check the "Portfolio Summary" table.
        </li>
        <LazyLoadImage
          src={port1}
          alt="portfolio table"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={port1} alt="portfolio table" />*/}
        <li>
          To add a new asset to your portfolio, click on the "Buy/Sell" button
          and follow the prompts.
        </li>
        <LazyLoadImage
          src={port2}
          alt="portfolio table"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={port2} alt="portfolio table" />*/}
      </ul>
    </div>
  );
};

export default PortfolioSection;

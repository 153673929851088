import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { FiPercent } from "react-icons/fi";
import { IoLogoUsd } from "react-icons/io";
import React from "react";

const ColumnTitle = ({ title, mode, toggleMode }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ marginRight: "0.5rem" }}>{title}</span>
      <span
        className={styles.toggleBtn}
        style={{ marginRight: ".2rem" }}
        onClick={() => toggleMode(true)}
      >
        <FiPercent
          style={{
            opacity: mode ? 1 : 0.7,
            fontSize: mode ? "17px" : "14px",
          }}
        />
      </span>
      <span
        className={styles.toggleBtn}
        style={{
          opacity: !mode ? 1 : 0.7,
          fontSize: !mode ? "17px" : "14px",
        }}
        onClick={() => toggleMode(false)}
      >
        <IoLogoUsd />
      </span>
    </div>
  );
};

export default ColumnTitle;

// pricing-plan values types

export const GET_FREE_PLAN_REQUEST = "GET_FREE_PLAN_REQUEST";
export const GET_FREE_PLAN_SUCCESS = "GET_FREE_PLAN_SUCCESS";
export const GET_FREE_PLAN_ERROR = "GET_FREE_PLAN_ERROR";

export const GET_REFERRAL_CODE_REQUEST = "GET_REFERRAL_CODE_REQUEST";
export const GET_REFERRAL_CODE_SUCCESS = "GET_REFERRAL_CODE_SUCCESS";
export const GET_REFERRAL_CODE_ERROR = "GET_REFERRAL_CODE_ERROR";

export const GET_REFERRAL_REWARD_REQUEST = "GET_REFERRAL_REWARD_REQUEST";
export const GET_REFERRAL_REWARD_SUCCESS = "GET_REFERRAL_REWARD_SUCCESS";
export const GET_REFERRAL_REWARD_ERROR = "GET_REFERRAL_REWARD_ERROR";

export const ACCLAIM_GIFT_REQUEST = "ACCLAIM_GIFT_REQUEST";
export const ACCLAIM_GIFT_SUCCESS = "ACCLAIM_GIFT_SUCCESS";
export const ACCLAIM_GIFT_ERROR = "ACCLAIM_GIFT_ERROR";

export const REDEEM_GIFT_REQUEST = "REDEEM_GIFT_REQUEST";
export const REDEEM_GIFT_SUCCESS = "REDEEM_GIFT_SUCCESS";
export const REDEEM_GIFT_ERROR = "REDEEM_GIFT_ERROR";
export const CLEAR_REDEEM_GIFT_MESSAGE = "CLEAR_REDEEM_GIFT_MESSAGE";

export const GET_STRIPE_CHECKOUT_SESSION_REQUEST =
  "GET_STRIPE_CHECKOUT_SESSION_REQUEST";
export const GET_STRIPE_CHECKOUT_SESSION_SUCCESS =
  "GET_STRIPE_CHECKOUT_SESSION_SUCCESS";
export const GET_STRIPE_CHECKOUT_SESSION_ERROR =
  "GET_STRIPE_CHECKOUT_SESSION_ERROR";

export const GET_USER_SUBSCRIPTIONS_REQUEST = "GET_USER_SUBSCRIPTIONS_REQUEST";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_ERROR = "GET_USER_SUBSCRIPTIONS_ERROR";

export const CANCEL_SUBSCRIPTION_REQUEST = "GET_USER_SUBSCRIPTIONS_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "GET_USER_SUBSCRIPTIONS_ERROR";

export const REFUND_REQUEST = "REFUND_REQUEST";
export const REFUND_SUCCESS = "REFUND_SUCCESS";
export const REFUND_ERROR = "REFUND_ERROR";

export const RENEW_REQUEST = "RENEW_REQUEST";
export const RENEW_SUCCESS = "RENEW_SUCCESS";
export const RENEW_ERROR = "RENEW_ERROR";

export const DISCOUNT_REQUEST = "DISCOUNT_REQUEST";
export const DISCOUNT_SUCCESS = "DISCOUNT_SUCCESS";
export const DISCOUNT_ERROR = "DISCOUNT_ERROR";

export const SEND_CHAT_REQUEST = "SEND_CHAT_REQUEST";
export const SEND_CHAT_SUCCESS = "SEND_CHAT_SUCCESS";
export const SEND_CHAT_ERROR = "SEND_CHAT_ERROR";

export const INCREASE_CREDIT_WITH_CARD_REQUEST =
  "INCREASE_CREDIT_WITH_CARD_REQUEST";
export const INCREASE_CREDIT_WITH_CARD_SUCCESS =
  "INCREASE_CREDIT_WITH_CARD_SUCCESS";
export const INCREASE_CREDIT_WITH_CARD_ERROR =
  "INCREASE_CREDIT_WITH_CARD_ERROR";

export const CHARGE_CREDIT_WITH_CRYPTO_REQUEST =
  "CHARGE_CREDIT_WITH_CRYPTO_REQUEST";
export const CHARGE_CREDIT_WITH_CRYPTO_SUCCESS =
  "CHARGE_CREDIT_WITH_CRYPTO_SUCCESS";
export const CHARGE_CREDIT_WITH_CRYPTO_ERROR =
  "CHARGE_CREDIT_WITH_CRYPTO_ERROR";

export const ACTIVATE_SERVICE_WITH_CREDIT_REQUEST =
  "ACTIVATE_SERVICE_WITH_CREDIT_REQUEST";
export const ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS =
  "ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS";
export const ACTIVATE_SERVICE_WITH_CREDIT_ERROR =
  "ACTIVATE_SERVICE_WITH_CREDIT_ERROR";
export const ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST =
  "ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST";
export const ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS =
  "ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS";

export const ACTIVATE_SERVICE_WITH_CRYPTO_ERROR =
  "ACTIVATE_SERVICE_WITH_CRYPTO_ERROR";

export const ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST =
  "ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST";
export const ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS =
  "ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS";
export const ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR =
  "ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR";

export const GET_PRICING_PLANS_REQUEST = "GET_PRICING_PLANS_REQUEST";
export const GET_PRICING_PLANS_SUCCESS = "GET_PRICING_PLANS_SUCCESS";
export const GET_PRICING_PLANS_ERROR = "GET_PRICING_PLANS_ERROR";

export const WITHDRAW_REQUEST = "WITHDRAW_REQUEST";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";

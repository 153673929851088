import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Ripples from "react-ripples";
import { Button, Modal } from "antd";
import Alert from "../../components/alerts/Alert";

// STYLES
import styles from "../../styles/pages/pricingPlan/index.module.sass";
import { TbSocial } from "react-icons/tb";
import {
  FaBell,
  FaBrain,
  FaBriefcase,
  FaChartLine,
  FaCopy,
  FaCrown,
  FaExchangeAlt,
  FaPlayCircle,
  FaRobot,
  FaSignal,
  FaTerminal,
} from "react-icons/fa";
import { GiTrade } from "react-icons/gi";
import Stripe from "../../components/payment/Stripe";

import warningIcon from "../../asset/Icons/warn.gif";
import smileIcon from "../../asset/Icons/happy1.gif";
import moment from "moment";

const PlanList = () => {
  const [showAlert, setShowAlert] = useState(false);
  let {
    pricingPlan: { freePlan, subscriptions },
    profile: { profileData },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!showAlert) return;

    if (freePlan?.message && freePlan?.pricing_plan === "Pro") {
      Alert(" ", freePlan?.message, "warning", {
        cancel: "Close",
      });
      // freePlan.message = null
    }
    // setShowAlert(false);
  }, [freePlan]);

  const activeStyle = {
    backgroundColor: "#0C4CFC",
    color: "white",
  };

  const [showBuyAlert, setShowBuyAlert] = useState(false);
  const [showYearlyAlert, setShowYearlyAlert] = useState(false);
  const [planTime, setPlanTime] = useState("monthly");
  const [showPayment, setShowPayment] = useState(false);
  const [disabledPlan, setDisabledPlan] = useState("any");
  const [modalMessage, setModalMessage] = useState("");

  const handleBuy = function () {
    const { monthly, yearly } = subscriptions;
    const { credit } = profileData;
    if (credit < 50) {
      setDisabledPlan("any");
      setShowPayment(true);
      return;
    }
    if (monthly && !monthly.cancel_at && credit > 50) {
      setDisabledPlan("monthly");
      setPlanTime("annually");
      const msg =
        "You have an active monthly pro plan and credit in your account, so you can change your plan to yearly.";
      setModalMessage(msg);
      setShowBuyAlert(true);
      return;
    }
    if (yearly && !yearly.cancel_at && profileData.credit > 50) {
      const endTime = moment.unix(yearly.current_period_end);
      const now = moment();
      const diff = endTime.diff(now, "months");
      if (diff > 1) {
        const date = endTime
          .local()
          .subtract(1, "month")
          .format("YYYY/MM/DD, HH:mm:ss");
        const msg = `If you want to change your plan, you can do so from ${date}`;
        setModalMessage(msg);
        setShowYearlyAlert(true);
        return;
      }
      setDisabledPlan("annually");
      setPlanTime("monthly");

      const msg =
        "You have an active yearly pro plan and credit in your account, so you can change your plan to monthly.";
      setModalMessage(msg);
      showBuyAlert(true);
      return;
    }

    setDisabledPlan("any");
    setShowPayment(true);
    // if (
    //   ((monthly && !monthly.cancel_at) || (yearly && !yearly.cancel_at)) &&
    //   profileData.credit > 0
    // ) {
    //   setShowBuyAlert(true);
    // } else {
    //   setShowPayment(true);
    // }
  };

  // const [planValue, setPlanValue] = useState({
  //   monthly: 19.99,
  //   yearly: 167.88,
  // });

  return (
    <div className={styles.plans}>
      <div className={styles.planWrapper}>
        <div className={styles.header}>
          <h2>
            <FaCrown />
            Pro
          </h2>
          <p>Pay as you go</p>

          <div>
            Get started with only
            <br />
            <span className={planTime === "annually" ? styles.off : ""}>
              19.99 USD
            </span>
            /month
          </div>
        </div>
        <Ripples className={styles.ripple} during={1000}>
          <Button onClick={handleBuy}>
            {/* {false ? (
              <ReactLoading type={"bars"} color="#fff" height={40} width={36} />
            ) : (
              <span onClick={() => setShowPayment(true)}>Buy It</span>
            )} */}
            <span>Buy It</span>
          </Button>
        </Ripples>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          <span
            style={planTime === "monthly" ? activeStyle : null}
            onClick={() => setPlanTime("monthly")}
            className={styles.planTimePicker}
          >
            Monthly
          </span>
          <span
            style={planTime === "annually" ? activeStyle : null}
            onClick={() => setPlanTime("annually")}
            className={styles.planTimePicker}
          >
            Annually
          </span>
        </div>
        <p>Features you'll love:</p>
        <ul>
          <li>
            <span>
              {/*<BiLineChart />*/}
              <FaRobot />
            </span>{" "}
            <span>
              {" "}
              Bot: Available (Investment budget up to <var>4k</var> USD)
            </span>
          </li>
          <li>
            <span>
              {/*<AiFillCreditCard />*/}
              <FaChartLine />
            </span>{" "}
            <span>
              {" "}
              Bot Backtests: <var>2</var> per bot per week{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<TbSocial />*/}
              <FaExchangeAlt />
            </span>{" "}
            <span>
              {" "}
              Exchanges: <var>Unlimited</var> integrations{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<BiLineChart />*/}
              <FaBrain />
            </span>{" "}
            <span>
              {" "}
              AI assistant: <var>200</var> positions
            </span>
          </li>
          <li>
            <span>
              {/*<FaBrain />*/}
              <FaBriefcase />
            </span>{" "}
            <span>
              {" "}
              Portfolio Management: <var>2</var> slot{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<IoEyeSharp />*/}
              <FaBell />
            </span>{" "}
            <span>Alert system:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<TbHeartRateMonitor />*/}
              <FaPlayCircle />
            </span>{" "}
            <span>Trade Simulator:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<FaWallet />*/}
              <GiTrade />
            </span>{" "}
            <span>Trade Inside Platform on Exchanges: Available</span>
          </li>
          <li>
            <span>
              {/*<GiShakingHands />*/}
              <FaCopy />
            </span>{" "}
            <span>
              {" "}
              Copy trading signals: <var>200</var> positions
            </span>
          </li>
          <li>
            <span>
              {/*<MdOutlineAddAlert />*/}
              <TbSocial />
            </span>{" "}
            <span>
              {" "}
              Social features: <var>200</var> Insights
            </span>
          </li>
          <li>
            <span>
              {/*<MdOutlineAddAlert />*/}
              <FaSignal />
            </span>{" "}
            <span>
              {" "}
              Trade with AI signals: <var>50</var> positions
            </span>
          </li>
        </ul>
      </div>
      <div className={styles.planWrapper}>
        <div className={styles.header}>
          <h2>
            <FaCrown className={styles.gold} />
            Enterprise
          </h2>
          <p>For partnership purposes</p>
        </div>
        <Ripples className={styles.ripple} during={1000}>
          <Button
            onClick={() => {
              window.open(
                "https://cryptocrispy.com.au/company.html#dvContact",
                "_blank",
              );
            }}
          >
            Contact Us
            {/*{false ? (*/}
            {/*  <ReactLoading type={"bars"} color="#fff" height={40} width={36} />*/}
            {/*) : (*/}
            {/*  "Contact Us"*/}
            {/*)}*/}
          </Button>
        </Ripples>
        <p className={styles.rowTitle}>Features you'll love:</p>
        <ul>
          <li>
            <span>
              {/*<BiLineChart />*/}
              <FaRobot />
            </span>{" "}
            <span>
              {" "}
              Bot: Available (Investment budget can be <var>unlimited</var>)
            </span>
          </li>
          <li>
            <span>
              {/*<AiFillCreditCard />*/}
              <FaChartLine />
            </span>{" "}
            <span>
              {" "}
              Bot Backtests: <var>Unlimited</var>{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<TbSocial />*/}
              <FaExchangeAlt />
            </span>{" "}
            <span>
              {" "}
              Exchanges: <var>Unlimited</var> integrations{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<BiLineChart />*/}
              <FaBrain />
            </span>{" "}
            <span> AI assistant:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<FaBrain />*/}
              <FaBriefcase />
            </span>{" "}
            <span>
              {" "}
              Portfolio Management: <var>2</var> slot{" "}
            </span>
          </li>
          <li>
            <span>
              {/*<IoEyeSharp />*/}
              <FaBell />
            </span>{" "}
            <span>Alert system:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<TbHeartRateMonitor />*/}
              <FaPlayCircle />
            </span>{" "}
            <span>Trade Simulator:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<FaWallet />*/}
              <GiTrade />
            </span>{" "}
            <span>Trade Inside Platform on Exchanges: Available</span>
          </li>
          <li>
            <span>
              {/*<GiShakingHands />*/}
              <FaCopy />
            </span>{" "}
            <span> Copy trading signals:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<MdOutlineAddAlert />*/}
              <TbSocial />
            </span>{" "}
            <span> Social features:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              {/*<MdOutlineAddAlert />*/}
              <FaSignal />
            </span>{" "}
            <span> Trade with AI signals:</span>
            <span>Unlimited</span>
          </li>
          <li>
            <span>
              <FaTerminal />
            </span>{" "}
            <span> Developer API: Available </span>
          </li>
        </ul>
      </div>
      <Modal
        open={showBuyAlert}
        onCancel={() => setShowBuyAlert(false)}
        onOk={() => {
          setShowBuyAlert(false);
          setShowPayment(true);
        }}
        okText="Continue"
        centered={true}
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <img src={warningIcon} alt="alert" />
            <p style={{ textAlign: "center" }}>
              {modalMessage}
              <img style={{ width: "20px" }} src={smileIcon} alt="smile" />
            </p>
          </div>
        }
      />
      <Modal
        open={showYearlyAlert}
        onOk={() => setShowYearlyAlert(false)}
        okText="Ok"
        onCancel={() => setShowYearlyAlert(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        centered={true}
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <img src={warningIcon} alt="alert" />
            <p style={{ textAlign: "center", fontSize: "16px" }}>
              {modalMessage}
              <img style={{ width: "20px" }} src={smileIcon} alt="smile" />
            </p>
          </div>
        }
      />
      <Stripe
        show={showPayment}
        hide={setShowPayment}
        disabledPlan={disabledPlan}
        data={{
          pricingPlanMonthly: { price: 19.99, id: 4 },
          pricingPlanAnnual: { price: 167.88, id: 4 },
        }}
        type={planTime}
        setPlan={setPlanTime}
      />
    </div>
  );
};

export default PlanList;

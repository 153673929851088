import { useSelector } from "react-redux";
import HideContentIcon from "../shared/HideContentIcon";
import { message } from "antd";
import { TbCopy } from "react-icons/tb";

function MiniSignalInfo({
  customClassName,
  customStyle,
  clickHandler,
  children,
  showCopyIcon,
}) {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const isServiceActive = profileData?.myais_active !== "False";

  const copyHandler = () => {
    navigator.clipboard.writeText(JSON.stringify(children));
    message.success("Copied to clipboard.");
  };

  return (
    <>
      {isServiceActive ? (
        <div
          className={`${customClassName || ""}`}
          style={{ display: "flex", alignItems: "center", gap: ".2rem" }}
        >
          {showCopyIcon && (
            <TbCopy
              size={18}
              color={"#0C4CFC"}
              style={{ cursor: "pointer" }}
              onClick={copyHandler}
            />
          )}
          <span style={{ fontWeight: 500 }}>{children}</span>
        </div>
      ) : (
        <HideContentIcon clickHandler={clickHandler} />
      )}
    </>
  );
}

export default MiniSignalInfo;

import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { Menu } from "antd";
import styles from "../../styles/pages/trading-assistant/index.module.sass";
import { useDispatch, useSelector } from "react-redux";
import useDownloader from "react-use-downloader";
import {
  getAllTradeSuggestion,
  getPortfolioData,
  getTradeAssistant,
  getTradeHistory,
  setVirtualPortfolio,
} from "../../redux/trading-assistant/actions";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/alerts/Alert";
import { GrDocumentCsv } from "react-icons/gr";
import { VscJson } from "react-icons/vsc";
import Portfolio from "../../components/trading-assistant/Portfolio";
import History from "../../components/trading-assistant/History";
import Assistant from "../../components/trading-assistant/Assistant";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";

const Index = ({ showRatingModal, setVisitedPage }) => {
  const dispatch = useDispatch();
  const {
    main: { userData },
    tradingAssistant: {
      loading,
      error,
      portfolioData,
      tradeHistory,
      tradeAssistant,
      balanceData,
      selectedPortfolio,
      aiMarketType,
    },
    profile: { profileData },
  } = useSelector((state) => state);
  const [portfolio, setPortfolio] = useState(
    selectedPortfolio?.toLowerCase() || "primary",
  );

  const [balanceChartData, setBalanceChartData] = useState([]);
  const navigate = useNavigate();
  const { section } = useParams();
  const [cache, setCache] = useState({
    p1: false,
    p2: false,
    s1: false,
    s2: false,
    3: false,
  });
  const { export_links } = tradeAssistant;

  const [modeKey, setModeKey] = useState("");

  const { download } = useDownloader();

  useEffect(() => {
    if (userData?.["cognito:username"]) {
      if (portfolio === "primary") {
        if (section === "portfolio" && !cache?.p1) {
          dispatch(
            getPortfolioData(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              portfolio,
            ),
          );
          setCache((prev) => ({
            ...prev,
            s1: false,
            s2: false,
            p1: true,
          }));
        } else if (section === "trade-history" && !cache?.p2) {
          dispatch(
            getTradeHistory(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              portfolio,
            ),
          );
          setCache((prev) => ({
            ...prev,
            s1: false,
            s2: false,
            p2: true,
          }));
        }
      } else {
        if (section === "portfolio" && !cache?.s1) {
          dispatch(
            getPortfolioData(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              portfolio,
            ),
          );
          setCache((prev) => ({
            ...prev,
            p1: false,
            p2: false,
            s1: true,
          }));
        } else if (section === "trade-history" && !cache?.s2) {
          dispatch(
            getTradeHistory(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              portfolio,
            ),
          );
          setCache((prev) => ({
            ...prev,
            p1: false,
            p2: false,
            s2: true,
          }));
        }
      }
    }
  }, [portfolio, userData, section]);
  useEffect(() => {
    if (userData?.["cognito:username"]) {
      if (
        section === "assistant" &&
        !cache?.["3"] &&
        !localStorage.getItem("assistantAtatus")
      ) {
        dispatch(
          getTradeAssistant(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            portfolio,
            aiMarketType,
          ),
        );
        dispatch(
          getAllTradeSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            aiMarketType,
          ),
        );
        setCache((prev) => ({
          ...prev,
          3: true,
        }));
      }
    }
  }, [cache, dispatch, portfolio, section, userData, aiMarketType]);
  useEffect(() => {
    if (section === "assistant") {
      localStorage.setItem("assistantAtatus", "loaded");
    }
  }, [section]);
  useEffect(() => {
    setBalanceChartData(portfolioData?.BALANCE_CHART);
  }, [portfolioData?.BALANCE_CHART]);

  useEffect(() => {
    setBalanceChartData(balanceData?.BALANCE_CHART);
  }, [balanceData]);

  const handleMenuClick = (e) => {
    if (e.key == 0) {
      if (section === "trade-history") {
        download(
          tradeHistory?.export_links?.csv_report,
          `csv_report-${new Date().toLocaleString()}.csv`,
        );
      } else {
        download(
          export_links?.csv_report,
          `csv_report-${new Date().toLocaleString()}.csv`,
        );
      }
    }
    if (e.key == 1) {
      if (section === "trade-history") {
        download(
          tradeHistory?.export_links?.json_report,
          `json_report-${new Date().toLocaleString()}`,
        );
      } else {
        download(
          export_links?.json_report,
          `json_report-${new Date().toLocaleString()}`,
        );
      }
    }
  };
  const menu = (
    <Menu
      className={styles.MenuClassName}
      onClick={handleMenuClick}
      items={[
        {
          label: "CSV",
          key: "0",
          icon: <GrDocumentCsv />,
        },
        {
          label: "JSON",
          key: "1",
          icon: <VscJson />,
        },
      ]}
    />
  );

  useEffect(() => {
    if (portfolio) {
      let selectedPortfolio = portfolio === "primary" ? "Primary" : "Secondary";
      dispatch(setVirtualPortfolio(selectedPortfolio));
    }
  }, [dispatch, portfolio]);

  useEffect(() => {
    if (selectedPortfolio) {
      const portfolio =
        selectedPortfolio.toLowerCase() === "primary" ? "primary" : "secondary";
      setPortfolio(portfolio);
    }
  }, [selectedPortfolio]);

  // useEffect(() => {
  //
  // }, [aiMarketType]);

  useEffect(() => {
    if (!section) {
      navigate("/ai-assistant/assistant");
    }
  }, [navigate, section]);

  return (
    <DashboardLayout aiLayout loading={loading} error={error} hasDemo>
      <div className={styles.tradeAssistantWrapper}>
        {/*<Navbar*/}
        {/*  changePortfolio={setPortfolio}*/}
        {/*  proUser={proUser}*/}
        {/*  portfolio={portfolio}*/}
        {/*/>*/}
        <div className={styles.main}>
          <ServiceStatusBar
            status={profileData?.myais_active}
            expirationDate={profileData?.myais_active_until}
            service={"aiSignal"}
            platform
          />
          {section === "portfolio" && <Portfolio portfolio={portfolio} />}
          {section === "trade-history" && (
            <History portfolio={portfolio} section={section} />
          )}
          {section === "assistant" && (
            <Assistant
              portfolio={portfolio}
              menu={menu}
              market={aiMarketType}
              setAIMode={setModeKey}
              aiMode={modeKey}
              showRatingModal={showRatingModal}
              setVisitedPage={setVisitedPage}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Index;

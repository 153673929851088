import { useParams } from "react-router";
import MiniReferralCodes from "./MiniReferralCodes";
import MiniAppHeader from "./layout/MiniAppHeader";
import MiniAppContainer from "./MiniAppContainer";
import MiniCreditCharge from "./mini-pricing/MiniCreditCharge";
import styles from "../../styles/Components/mini-app/MiniPricingPlan.module.css";
import {
  FaCreditCard,
  FaMoneyBill,
  FaRegCreditCard,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount, formatPrice } from "../../utility/helper";
import { useEffect, useState } from "react";
import {
  getReferralCode,
  getReferralRewards,
  getSubscriptions,
} from "../../redux/pricing-plan/action";
import { MdSubscriptions } from "react-icons/md";
import { Modal, Tooltip } from "antd";
import { HiPlus } from "react-icons/hi";
import { CLEAR_REDEEM_GIFT_MESSAGE } from "../../redux/pricing-plan/consts";
import { Link } from "react-router-dom";
import InviteFriendsModal from "./mini-pricing/InviteFriendsModal";

function MiniPricingPlan() {
  const {
    main: { userData },
    profile: { profileData, loading },
    pricingPlan: {
      referralCode,
      referralCodeLoading,
      referralData,
      referralRewardsLoading,
      subscriptions,
      getSubscriptionLoading,
      subscriptionError,
    },
  } = useSelector((state) => state);

  const [showChargeCreditModal, setShowChargeCreditModal] = useState(false);

  const credit = profileData?.credit / 100;
  const hasMonthlySubscription = subscriptions?.monthly?.status === "active";
  const hasYearlySubscription = subscriptions?.yearly?.status === "active";
  const hasSubscription = hasYearlySubscription || hasMonthlySubscription;

  const earnedMoney = referralData?.total_cashable_credit;
  const earnedCredit = referralData?.total_credit_reward;

  const [showShareModal, setShowShareModal] = useState(false);

  const invitationLink = `https://t.me/cryptocrispy_bot?start=${referralCode}`;

  const dispatch = useDispatch();
  const { section } = useParams();

  const pageTitle = !section
    ? "Pricing Plan"
    : section === "referrals"
    ? "Referrals"
    : "Credit Charge";

  useEffect(() => {
    if (
      userData?.email &&
      !Object.keys(subscriptions).length &&
      !getSubscriptionLoading &&
      !section &&
      !subscriptionError
    ) {
      dispatch(
        getSubscriptions(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, subscriptions, getSubscriptionLoading, subscriptionError]);

  useEffect(() => {
    if (!referralCode && userData?.email && !referralCodeLoading && !section) {
      dispatch(
        getReferralCode(userData?.email, userData?.["custom:custom_username"]),
      );
    }
  }, [dispatch, referralCode, userData, referralCodeLoading]);

  useEffect(() => {
    if (
      userData?.email &&
      !referralData?.user &&
      !referralRewardsLoading &&
      !section
    ) {
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, referralData, dispatch, referralRewardsLoading]);

  return (
    <MiniAppContainer>
      <MiniAppHeader page={pageTitle} />
      {!section && (
        <>
          <div className={styles.container}>
            <div className={styles.infoBox}>
              <span className={styles.title}>
                <FaRegCreditCard size={17} /> Account Credit
              </span>
              <div className={styles.information}>
                <span>
                  {loading ? "Loading..." : `$${formatPrice(credit, 2)}`}
                </span>
                <Tooltip title={"Increase credit"} color={"geekblue"}>
                  <span
                    className={styles.increaseBtn}
                    onClick={() => setShowChargeCreditModal(true)}
                  >
                    <HiPlus />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className={styles.infoBox}>
              <span className={styles.title}>
                <MdSubscriptions size={17} /> Subscription
              </span>
              <span
                className={`${
                  !loading
                    ? hasSubscription
                      ? styles.active
                      : styles.notActive
                    : ""
                }`}
              >
                {getSubscriptionLoading
                  ? "Loading..."
                  : hasSubscription
                  ? "Active"
                  : "Not Active"}
              </span>
            </div>

            <div className={styles.infoBox}>
              <span className={styles.title}>
                <FaCreditCard size={17} /> Earned Credit
              </span>
              <div className={styles.information}>
                <span>
                  {referralRewardsLoading
                    ? "Loading..."
                    : `$${formatAmount(earnedCredit)}`}
                </span>
                <Tooltip title={"Invite Friend"} color={"geekblue"}>
                  <span
                    className={styles.inviteFriends}
                    onClick={() => setShowShareModal(true)}
                  >
                    <FaUserFriends />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className={styles.infoBox}>
              <span className={styles.title}>
                <FaMoneyBill size={17} /> Earned Money
              </span>
              <div className={styles.information}>
                <span>
                  {referralRewardsLoading
                    ? "Loading..."
                    : `$${formatAmount(earnedMoney)}`}
                </span>
                <Tooltip title={"Invite Friend"} color={"geekblue"}>
                  <span
                    className={styles.inviteFriends}
                    onClick={() => setShowShareModal(true)}
                  >
                    <FaUserFriends />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <Link
            to={"/mini-pricing-plan/referrals"}
            className={styles.referralBtn}
          >
            <FaUsers size={16} /> Referrals
          </Link>

          <Modal
            open={showChargeCreditModal}
            onCancel={() => {
              setShowChargeCreditModal(false);
              // clear redeem gift message every time the modal opened
              dispatch({ type: CLEAR_REDEEM_GIFT_MESSAGE });
            }}
            centered
            footer={null}
            maskClosable={false}
          >
            <MiniCreditCharge show={showChargeCreditModal} />
          </Modal>
        </>
      )}

      {section === "referrals" && <MiniReferralCodes />}

      {showShareModal && (
        <InviteFriendsModal
          show={showShareModal}
          setShow={setShowShareModal}
          link={invitationLink}
        />
      )}
    </MiniAppContainer>
  );
}

export default MiniPricingPlan;

import MiniAppContainer from "./MiniAppContainer";
import MiniAppHeader from "./layout/MiniAppHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllTradeSuggestion } from "../../redux/trading-assistant/actions";
import Loader from "./shared/Loader";
import MiniSignals from "./mini-ai-signals/MiniSignals";
import ServiceStatusBar from "./shared/ServiceStatusBar";
import ErrorBox from "./shared/ErrorBox";

function MiniAiSignals() {
  const {
    main: { userData },
    tradingAssistant: {
      allTradeSuggestionLoading,
      allSuggestion,
      allTradeSuggestionError,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userData?.email &&
      !allTradeSuggestionLoading &&
      !allTradeSuggestionError &&
      allSuggestion?.length === 0
    ) {
      dispatch(
        getAllTradeSuggestion(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          "crypto",
        ),
      );
    }
  }, [
    userData,
    allTradeSuggestionError,
    allSuggestion,
    allTradeSuggestionLoading,
  ]);

  return (
    <MiniAppContainer>
      <MiniAppHeader
        page={"AI Signals"}
        description={"You will get alerts in telegram."}
      />
      <ServiceStatusBar
        status={profileData?.myais_active}
        expirationDate={profileData?.myais_active_until}
        service={"aiSignal"}
      />
      {allTradeSuggestionLoading && <Loader />}
      {allSuggestion?.length > 0 && <MiniSignals />}
      {allTradeSuggestionError && <ErrorBox style={{ minHeight: "400px" }} />}
    </MiniAppContainer>
  );
}

export default MiniAiSignals;

import styles from "../../../styles/Components/mini-app/shared/ServiceStatusBar.module.css";
import { FiInfo } from "react-icons/fi";
import { BsCalendarDate } from "react-icons/bs";
import { useSelector } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { IoSettings } from "react-icons/io5";
import React, { useState } from "react";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import { getRemainingTime } from "../../../utility/formatter-helper";

// status prop can be one ot these value :
// 1.alert : for alert system
// 2.insai: for insights
// 3.aiSignal: for ai signals (contains ai assistant and bot feature)

function ServiceStatusBar({
  status,
  expirationDate,
  service,
  style,
  platform,
}) {
  const {
    profile: { loading },
  } = useSelector((state) => state);

  const [showActivationModal, setShowActivationModal] = useState(false);

  const isActive = status === "True";

  const clickHandler = () => {
    if (!loading && !isActive) setShowActivationModal(true);
  };

  return (
    <div
      className={`${styles.container} ${
        platform ? styles.platformContainer : ""
      }`}
      style={style || {}}
    >
      <div
        className={`${styles.infoBox} ${!isActive ? styles.row : ""}`}
        onClick={clickHandler}
        style={!isActive ? { cursor: "pointer" } : {}}
      >
        <span className={styles.infoBoxTitle}>
          <FiInfo />
          Service Status:
        </span>
        <span
          className={`${
            loading || !status
              ? styles.loading
              : isActive
              ? styles.active
              : styles.notActive
          }`}
        >
          {loading || !status ? (
            "Checking..."
          ) : isActive ? (
            <>
              <FaCircleCheck /> Active
            </>
          ) : (
            <>
              <IoMdCloseCircle /> Not Active{" "}
              <IoSettings className={styles.settingsIcon} />
            </>
          )}
        </span>
      </div>
      {isActive && (
        <div className={styles.infoBox}>
          <span className={styles.infoBoxTitle}>
            <BsCalendarDate />
            Active Until:
          </span>
          <span>{getRemainingTime(expirationDate, true)}</span>
        </div>
      )}

      {showActivationModal && (
        <ServiceActivationModal
          service={service}
          show={showActivationModal}
          setShow={setShowActivationModal}
        />
      )}
    </div>
  );
}

export default ServiceStatusBar;

import { Checkbox, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setBuyLimit, setSellLimit } from "../../../../redux/trade/actions";
import { IoMdInformationCircleOutline } from "react-icons/io";

function LimitCheckbox() {
  const {
    tradeState: { buyLimit, sellLimit, tradeType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const checked = tradeType === "sell" ? sellLimit : buyLimit;

  const tooltipMsg = checked
    ? "Your order will be placed as a Staked Limit Order. The asset will be staked and sold at your desired price."
    : "Your order will be placed as a Stop Order. The asset will not be staked and the order will become a market order once your stop price is reached.";

  const handleCheckbox = () => {
    if (tradeType === "sell") {
      dispatch(setSellLimit());
    } else {
      dispatch(setBuyLimit());
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
      <Checkbox checked={checked} onChange={handleCheckbox} />
      <Tooltip title={tooltipMsg} color={"geekblue"}>
        <p
          style={{
            marginBottom: 0,
            display: "flex",
            alignItems: "center",
            gap: ".2rem",
          }}
        >
          {/*Set {tradeType} limit.*/}
          Use Staked Limit Order.
          <IoMdInformationCircleOutline />
        </p>
      </Tooltip>
    </div>
  );
}

export default LimitCheckbox;

import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import styles from "../../../styles/Components/mini-app/auth/MiniAppLoginPage.module.css";
import { CognitoUser } from "amazon-cognito-identity-js";
import { checkEmailException } from "../../../utility/helper";
import PoolData from "../../../pages/Auth/AWSConfig/PoolData";

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const codeValidationSchema = Yup.object({
  code: Yup.string().required("Confirmation code is required"),
});

function MiniAppResendConfirmationCode({ setAuthType }) {
  const [step, setStep] = useState(1);
  const [countdown, setCountdown] = useState(0);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      step === 1 ? emailValidationSchema : codeValidationSchema,
    ),
  });

  const resetTimer = () => setCountdown(20);

  const resendCodeHandler = (email) => {
    setLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: checkEmailException(email),
        Pool: PoolData,
      });
    };

    const onSuccess = (data) => {
      setLoading(false);
      setStep(2);
      resetTimer();
    };
    const onFailure = (err) => {
      message.error(err.message || JSON.stringify(err));
      setLoading(false);
    };

    getUser().resendConfirmationCode((err, data) => {
      if (err) {
        onFailure(err);
      } else {
        onSuccess(data);
      }
    });
  };

  const confirmAccount = (code) => {
    setLoading(true);
    const userData = {
      Username: email.toLowerCase(),
      Pool: PoolData,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        setLoading(false);
        if (err) {
          message.error(err?.message);
          reject(err);
        } else {
          message.success("Your email successfully confirmed.");
          resolve(result);
          setAuthType("login");
        }
      });
    });
  };

  const onSubmit = (data) => {
    console.log(data);
    if (loading) return;
    if (step === 1) {
      // Handle email submission
      resendCodeHandler(data?.email);
      setEmail(data?.email);
    } else {
      // Handle code submission
      confirmAccount(data?.code);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  return (
    <Form onFinish={handleSubmit(onSubmit)}>
      {step === 1 && (
        <Form.Item
          label="Email"
          validateStatus={errors.email ? "error" : ""}
          help={errors.email?.message}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input {...field} placeholder="Enter your email" />
            )}
          />
        </Form.Item>
      )}
      {step === 2 && (
        <Form.Item
          label="Confirmation Code"
          validateStatus={errors.code ? "error" : ""}
          help={errors.code?.message}
        >
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input {...field} placeholder="Enter confirmation code" />
            )}
          />
        </Form.Item>
      )}
      <Form.Item style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          type="primary"
          htmlType="submit"
          // disabled={step === 2 && countdown > 0}
          className={styles.submitBtn}
          loading={loading}
        >
          {step === 1 ? "Submit" : "Confirm"}
          {/*{step === 1 ? (*/}
          {/*  "Submit"*/}
          {/*) : countdown > 0 ? (*/}
          {/*  <>*/}
          {/*    Submit{" "}*/}
          {/*    <span style={{ fontSize: "12px" }}>*/}
          {/*      ()*/}
          {/*    </span>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  "Submit"*/}
          {/*)}*/}
        </Button>
      </Form.Item>
      {step === 2 && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {countdown > 0 ? (
            `Request a new code in ${countdown}s.`
          ) : (
            <span
              className={styles.resendCodeBtn}
              onClick={() => resendCodeHandler(email)}
            >
              Resend Code
            </span>
          )}
        </div>
      )}
      <p
        className={styles.createAccountBtn}
        onClick={() => setAuthType("login")}
      >
        Go to Login
      </p>
    </Form>
  );
}

export default MiniAppResendConfirmationCode;

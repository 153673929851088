import React from 'react';
import Chart from "react-apexcharts";

const LineChart = ({data}) => {
    const USAGE_STATISTICS = data?.USAGE_STATISTICS

    var options = {
        colors:['#1e514c'],
        stroke: {
            show: true,
            curve: 'smooth',
            width: 3,
            dashArray: 0,
        },

        series: [{
            name: 'Daily cost',
            data: USAGE_STATISTICS?.DAILY_COST?.map((item)=> item)
        }],
        chart: {
            type: 'area',
            stacked: false,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: .5,
        },
        title: {
            text: 'Daily Cost',
            align: 'left'
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: true,
                opacityFrom: 0.3,
                opacityTo: 0.7,
                stops: [0, 50, 100]
            },

        },
        yaxis: {
            // labels: {
            //     formatter: function (val) {
            //         return val.toFixed(0)
            //     },
            // },
            title: {
                text: 'Price (USD)'
            },
        },
        xaxis: {
            type:"datetime",
            categories: USAGE_STATISTICS?.TIME_STOMPS?.map(item=>item)
        },
        tooltip: {
            shared: false,
            x: {
                // format: 'dd/MM/yy HH:mm'
                format: 'dd/MM/yy'
            },

        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false
        }
    };

    return (data?.USAGE_STATISTICS ?
            <Chart options={options} series={options.series} type={"line"}
               height={300}
               width={"100%"}/>:null
    );
};

export default LineChart;

import { useState } from "react";
import { IoMdHelpCircleOutline } from "react-icons/io";
import styles from "../../styles/Components/layout/TutorialButton.module.css";
import { IoClose, IoVideocam } from "react-icons/io5";
import { RiQuestionnaireLine } from "react-icons/ri";
import DemoModal from "./DemoModal";
import { useLocation } from "react-router-dom";
import { convertRoutNameToTitle } from "../../utility/helper";
import FrequentlyAskedQuestionModal from "./FrequentlyAskedQuestionModal";
import { useNavigate } from "react-router";

function TutorialButton({ btnTitle }) {
  const [clicked, setClicked] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const clickHandler = () => {
    // handleClick();
    setClicked((prevState) => !prevState);
  };

  const showDemoHandler = () => {
    setShowDemoModal(true);
  };

  const closeDemoModalHandler = () => {
    setShowDemoModal(false);
  };

  const showQuestionsModalHandler = () => {
    // setShowQuestionsModal(true);
    navigate("/faq/all");
  };
  const closeQuestionsModalHandler = () => {
    setShowQuestionsModal(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.demoBtn} ${clicked ? styles.active : ""}`}
        onClick={clickHandler}
      >
        {clicked ? (
          <IoClose size={30} />
        ) : (
          btnTitle || (
            <>
              <span>Help</span>
              <IoMdHelpCircleOutline size={20} />
            </>
          )
        )}
      </div>
      <ul
        className={`${styles.content} ${clicked ? styles.open : styles.close}`}
      >
        <li className={styles.option} onClick={showDemoHandler}>
          <IoVideocam size={22} />
        </li>
        <li className={styles.option} onClick={showQuestionsModalHandler}>
          <RiQuestionnaireLine size={22} />
        </li>
      </ul>

      <DemoModal
        open={showDemoModal}
        handleClose={closeDemoModalHandler}
        page={convertRoutNameToTitle(location.pathname)}
      />

      <FrequentlyAskedQuestionModal
        show={showQuestionsModal}
        closeModal={closeQuestionsModalHandler}
        page={convertRoutNameToTitle(location.pathname)}
      />
    </div>
  );
}

export default TutorialButton;

import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import {
  FaBalanceScale,
  FaBitcoin,
  FaCaretRight,
  FaSmile,
} from "react-icons/fa";
import React from "react";
import CreateBotStepTitle from "./StepTitle";

function CreateBotStep5({ botData, changeBotData }) {
  return (
    <>
      <CreateBotStepTitle title={"Crypto Allocation"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          Choose your preferred crypto allocation strategy:
        </h4>
        <ul
          className={styles.options}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            width: "100%",
          }}
        >
          <li
            style={{
              width: "100%",
              maxWidth: "470px",
              margin: "0 auto",
              flexDirection: "row",
              gap: ".3rem",
            }}
            className={`${styles.optionItem} ${
              botData.crypto_allocation_strategy === "balanced"
                ? styles.selectedOption
                : ""
            }`}
            onClick={() =>
              changeBotData("crypto_allocation_strategy", "balanced")
            }
          >
            <FaBalanceScale color={"blue"} />
            Balanced (50% Bitcoin, 30% Ethereum, 20% Other Altcoins)
          </li>
          <li
            style={{
              width: "100%",
              maxWidth: "470px",
              margin: "0 auto",
              flexDirection: "row",
              gap: ".3rem",
            }}
            className={`${styles.optionItem} ${
              botData.crypto_allocation_strategy === "bitcoin_dominant"
                ? styles.selectedOption
                : ""
            }`}
            onClick={() =>
              changeBotData("crypto_allocation_strategy", "bitcoin_dominant")
            }
          >
            <FaBitcoin color={"orange"} />
            Bitcoin Dominant (70% Bitcoin, 20% Ethereum, 10% Other Altcoins)
          </li>
          <li
            style={{
              width: "100%",
              maxWidth: "470px",
              margin: "0 auto",
              flexDirection: "row",
              gap: ".3rem",
            }}
            className={`${styles.optionItem} ${
              botData.crypto_allocation_strategy === "altcoin_enthusiast"
                ? styles.selectedOption
                : ""
            }`}
            onClick={() =>
              changeBotData("crypto_allocation_strategy", "altcoin_enthusiast")
            }
          >
            <FaSmile color={"green"} />
            Altcoin Enthusiast (30% Bitcoin, 40% Ethereum, 30% Other Altcoins)
          </li>
        </ul>
      </div>
    </>
  );
}

export default CreateBotStep5;

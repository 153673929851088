// Styles
import styles from "../../styles/Components/dashboard/Tools.module.css";

// Components
import SkeletonContainer from "./SkeletonContainer";

// Icons
import toolsIcon from "../../asset/Icons/user(1).gif";
import { FaCode } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import ContentContainer from "./ContentContainer";
import paymentIcon from "../../asset/Icons/payment(1).gif";
import DashboardCardTitle from "./DashboardCardTitle";
import * as React from "react";

function Tools({ loading, data }) {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {loading && !data ? (
        <SkeletonContainer />
      ) : (
        <ContentContainer>
          <DashboardCardTitle
            text={"API Integration & Performance"}
            icon={toolsIcon}
          />
          {/*<h3>*/}
          {/*  <img*/}
          {/*    src={toolsIcon}*/}
          {/*    alt="feature"*/}
          {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
          {/*  />*/}
          {/*  API Integration & Performance*/}
          {/*</h3>*/}
          <div style={{ margin: "1rem 0" }}>
            <h4>
              <FaCode style={{ marginRight: ".3rem" }} />
              Developer API
            </h4>
            <p>
              You can easily link your exchange, bot, platform, or broker with
              our{" "}
              <span
                className={styles.link1}
                onClick={() => navigate("/developer-api")}
              >
                API
              </span>{" "}
              and enjoy our features.
            </p>
          </div>
          <div>
            <h4>
              <TbReportAnalytics style={{ marginRight: ".3rem" }} />
              Performance Report
            </h4>
            <p>
              Our{" "}
              <span className={styles.link2}>
                <a
                  href="https://cryptocrispy.com.au/reports.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  performance report
                </a>
              </span>{" "}
              gives you an overview of our capabilities and outcomes
            </p>
          </div>
          <div className={styles.links}>
            <Link className={styles.showLink} to="/developer-api">
              Developer API
            </Link>
            <a
              className={styles.showLink}
              href="https://cryptocrispy.com.au/reports.html"
              target="_blank"
              rel="noreferrer"
            >
              Performance Report
            </a>
          </div>
        </ContentContainer>
      )}
    </div>
  );
}

export default Tools;

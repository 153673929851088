import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaCaretRight } from "react-icons/fa";
import { InputNumber } from "antd";
import React from "react";
import CreateBotStepTitle from "./StepTitle";

const percentFormatter = (value) => `${value}%`;
const percentParser = (value) => value.replace("%", "");

function CreateBotStep4({ botData, changeBotData }) {
  return (
    <>
      <CreateBotStepTitle title={"Target Returns"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          What monthly percentage return are you aiming for in your crypto
          portfolio?
        </h4>
        <InputNumber
          min={1}
          max={100}
          formatter={percentFormatter}
          parser={percentParser}
          style={{ borderRadius: ".3rem" }}
          value={botData.target_return_percentage}
          onChange={(value) =>
            changeBotData("target_return_percentage", `${value}`)
          }
        />
      </div>
    </>
  );
}

export default CreateBotStep4;

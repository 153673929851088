import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaBalanceScale, FaCaretRight, FaShieldAlt } from "react-icons/fa";
import React from "react";
import StepTitle from "./StepTitle";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { HiRocketLaunch } from "react-icons/hi2";
import { AiTwotoneFire } from "react-icons/ai";

const riskOptions = [
  "Very Cautious",
  "Cautious",
  "Balanced",
  "Adventurous",
  "Very Adventurous",
];

function CreateBotStep3({ botData, changeBotData }) {
  return (
    <>
      <StepTitle title={"Risk Preferences"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          How would you rate your risk tolerance?
        </h4>
        <ul className={`${styles.options} ${styles.riskOptions}`}>
          {riskOptions.map((item, i) => (
            <li
              key={i}
              className={`${styles.optionItem} ${
                +botData.risk_tolerance === i + 1 ? styles.selectedOption : ""
              }`}
              style={{ width: "160px" }}
              onClick={() => changeBotData("risk_tolerance", `${i + 1}`)}
            >
              {i === 0 && <FaShieldAlt />}
              {i === 1 && <BsExclamationTriangleFill />}
              {i === 2 && <FaBalanceScale />}
              {i === 3 && <HiRocketLaunch />}
              {i === 4 && <AiTwotoneFire />}
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default CreateBotStep3;

import SkeletonContainer from "./SkeletonContainer";
import { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { Link } from "react-router-dom";
import NegativeAreaChart from "../charts/dashboard/NegativeAreaChart";

// STYLES
import styles from "../../styles/Components/dashboard/LastVisits.module.css";
import {
  convertToTitleCase,
  formatActionDate,
  sortData,
} from "../../utility/helper";

// ICONS
import insightIcon from "../../asset/Icons/icon-12.svg";
import alertIcon from "../../asset/Icons/icon-16.svg";
import pricingIcon from "../../asset/Icons/icon-13.svg";
import AIIcon from "../../asset/Icons/icon-15.svg";
import tradeSimulatorIcon from "../../asset/Icons/icon-18.svg";
import { IoIosArrowForward } from "react-icons/io";
import last from "../../asset/Icons/last۱.gif";
import { Skeleton, Spin } from "antd";
import ContentContainer from "./ContentContainer";
import DashboardCardTitle from "./DashboardCardTitle";

const LastVisits = ({ loading, data, dashboardData, featureRef, setStyle }) => {
  const [sortedData, setSortedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [showCahrtModal, setShowChartModal] = useState(false);

  const clickHandler = (e) => {
    e.preventDefault();
    featureRef.current.scrollIntoView({ behavior: "smooth" });
    setStyle({
      boxShadow:
        "0 5px 5px #5c87fd79, 0 -5px 5px #5c87fd79, 5px 0 5px #5c87fd79, -5px 0 5px #5c87fd79",
    });

    setTimeout(() => {
      setStyle({});
    }, 3000);
  };

  const sections = [
    "insight",
    "alert-system",
    "ai-assistant",
    "trade-simulator",
    "pricing",
    "portfolio",
  ];

  const icons = {
    insight: {
      icon: insightIcon,
      path: "/insight",
    },
    "ai-assistant": {
      icon: AIIcon,
      path: "/ai-assistant/assistant",
    },
    portfolio: {
      icon: AIIcon,
      path: "/ai-assistant/portfolio",
    },
    "alert-system": {
      icon: alertIcon,
      path: "/alert-system",
    },
    pricing: {
      icon: pricingIcon,
      path: "/pricing-billing",
    },
    "trade-simulator": {
      icon: tradeSimulatorIcon,
      path: "/trade-simulator",
    },
  };

  useEffect(() => {
    if (data) {
      const data2 = sortData(sections, data);
      setSortedData(data2);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <ContentContainer>
        <div className={styles.header}>
          <DashboardCardTitle text={"Latest Visits"} icon={last} />
          {/*<h3>*/}
          {/*  <img*/}
          {/*    src={last}*/}
          {/*    alt="latest alerts"*/}
          {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
          {/*  />*/}
          {/*  Latest Visits*/}
          {/*</h3>*/}
          {loading && !data && <Spin />}
        </div>
        {data && (
          <>
            <ul className={styles.actions}>
              {Object.values(data).every((actions) => actions.length === 0) ? (
                <p>
                  Welcome! It looks like this is your first time here.
                  <br />
                  You haven't visited anywhere yet.
                  <br />
                  <br />
                  See your recommended fetures{" "}
                  <span
                    style={{ color: "#0c4cfc", cursor: "pointer" }}
                    onClick={clickHandler}
                  >
                    here.
                  </span>
                </p>
              ) : (
                sortedData.map((item, i) => (
                  <li key={i}>
                    <div className={styles.info}>
                      <Link to={icons[item["section"]].path}>
                        <img
                          style={{ width: "25px", marginRight: ".5rem" }}
                          src={icons[item["section"]].icon}
                          alt={item}
                        />
                        <span style={{ marginRight: ".7rem" }}>
                          {convertToTitleCase(item["section"])}
                        </span>
                      </Link>
                      <span style={{ marginRight: ".7rem" }}>
                        {convertToTitleCase(item["description"])}
                      </span>
                      <span>{formatActionDate(item["action-datetime"])}</span>
                    </div>
                    <span
                      onClick={() => {
                        setSelectedItem(item);
                        setShowModal(true);
                      }}
                      className={styles.moreBtn}
                    >
                      See more
                      <IoIosArrowForward style={{ marginLeft: ".3rem" }} />
                    </span>
                  </li>
                ))
              )}
            </ul>
            <span
              className={styles.showLink}
              onClick={() => setShowChartModal(true)}
            >
              See Usage Chart
            </span>
            <Modal
              title="Usage Chart"
              open={showCahrtModal}
              handleOk={() => setShowChartModal(false)}
              handleCancel={() => setShowChartModal(false)}
              footer={false}
              width={800}
              centered={true}
              content={
                <div className={styles.chartContainer}>
                  {loading ? (
                    <>
                      <div className={styles.NegativeAreaChartSkeleton}>
                        <Skeleton.Input active={true} width={"100%"} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <NegativeAreaChart data={dashboardData} />
                      </div>
                    </>
                  )}
                </div>
              }
            />
            {data[selectedItem["section"]] && (
              <Modal
                title={"Last Visits"}
                open={showModal}
                handleOk={() => setShowModal(false)}
                handleCancel={() => setShowModal(false)}
                footer={false}
                width={700}
                centered={true}
                content={
                  <ul className={styles.modalList}>
                    {data[selectedItem["section"]].filter(
                      (item) => item !== selectedItem,
                    ).length === 0 ? (
                      <p>there is not more details</p>
                    ) : (
                      data[selectedItem["section"]]
                        .filter((item) => item !== selectedItem)
                        .map((item, i) => (
                          <li key={i}>
                            <div className={styles.info}>
                              <Link to={icons[item["section"]].path}>
                                <img
                                  style={{
                                    width: "25px",
                                    marginRight: ".5rem",
                                  }}
                                  src={icons[item["section"]].icon}
                                  alt={item}
                                />
                                <span style={{ marginRight: ".7rem" }}>
                                  {convertToTitleCase(item["section"])}
                                </span>
                              </Link>
                              <span style={{ marginRight: ".7rem" }}>
                                {convertToTitleCase(item["description"])}
                              </span>
                              <span>
                                {formatActionDate(item["action-datetime"])}
                              </span>
                            </div>
                          </li>
                        ))
                    )}
                  </ul>
                }
              />
            )}
          </>
        )}
      </ContentContainer>
    </div>
  );
};

export default LastVisits;

import { Button, message } from "antd";
import styles from "../../../styles/Components/pricing-plan/billing/SubscriptionActionButton.module.css";
import { MdOutlineAutorenew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { renew } from "../../../redux/pricing-plan/action";
import { useState } from "react";

function RenewSubscriptionButton({ isActive, subscriptionType }) {
  const {
    main: { userData },
    pricingPlan: { renewLoading, renewTarget },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleRenew = () => {
    if (renewTarget === subscriptionType && renewLoading) return;

    if (isActive) {
      return message.info("This subscription is already active.");
    }

    dispatch(
      renew(
        userData["cognito:username"],
        userData?.["custom:custom_username"],
        subscriptionType,
      ),
    );
  };

  return (
    <Button
      className={`${styles.renewBtn} ${styles.btn} ${
        isActive ? styles.disabled : ""
      }`}
      onClick={handleRenew}
      loading={renewLoading && renewTarget === subscriptionType}
    >
      <MdOutlineAutorenew /> Renew
    </Button>
  );
}

export default RenewSubscriptionButton;

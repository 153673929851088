import { Badge, Button, message, Modal, Radio, Slider, Spin } from "antd";
import styles from "../../../styles/Components/bot/bot-backtest/BacktestRequestModal.module.css";
import { useEffect, useState } from "react";
import { createBacktest, getBotList } from "../../../redux/bot/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import BacktestTimeSettings from "./BacktestTimeSettings";
import { GoDependabot } from "react-icons/go";
import BotDropdown from "./BotDropdownMenu";

const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

function BacktestRequestModal({ show, setShow }) {
  const {
    main: { userData },
    botState: {
      botList,
      getBotListLoading,
      fetchedBotList,
      createBacktestLoading,
    },
  } = useSelector((state) => state);

  const bots = [...botList]
    .sort((a, b) => (b.bot_status === "active" ? 1 : -1))
    .slice(0, 3);

  const [selectedBot, setSelectedBot] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateError, setDateError] = useState("");
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [marketType, setMarketType] = useState("futures");
  const [marginLeverage, setMarginLeverage] = useState("1");

  const dispatch = useDispatch();

  const handleClose = () => {
    if (createBacktestLoading) return;
    setShow(false);
    setSelectedBot(null);
    setSelectedDates([]);
    setShowTimePicker(false);
    setSelectedRange(null);
    setDateError("");
    setMarketType("futures");
    setMarginLeverage("1");
  };

  const hideTimePicker = () => setShowTimePicker(false);

  const handleSelectedTime = (num) => {
    if (showTimePicker) hideTimePicker();
    setSelectedRange(num);

    const endDate = moment();
    const startDate = moment().subtract(num, num === 1 ? "month" : "days");
    setSelectedDates([startDate, endDate]);
  };

  const handleSubmit = async () => {
    if (
      // eslint-disable-next-line no-mixed-operators
      !selectedBot ||
      // eslint-disable-next-line no-mixed-operators
      !(selectedDates && selectedDates[0] && selectedDates[1]) ||
      createBacktestLoading
    ) {
      return;
    }

    const startDate = selectedDates[0];
    const endDate = selectedDates[1];
    const diff = moment(endDate).diff(moment(startDate), "days");

    if (diff < 7 || diff > 60) {
      message.info(
        "Please select a date range with either one week or less than two months space.",
      );
      return;
    }

    await dispatch(
      createBacktest(
        userData["custom:custom_username"],
        userData["cognito:username"],
        selectedBot,
        formatDate(startDate),
        formatDate(endDate),
        marketType,
        marginLeverage,
      ),
    ).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    if (
      userData?.email &&
      !botList.length &&
      !fetchedBotList &&
      !getBotListLoading
    ) {
      dispatch(
        getBotList(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [botList.length, dispatch, fetchedBotList, getBotListLoading, userData]);

  useEffect(() => {
    if (selectedDates && selectedDates.length) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      const durationInDays = moment(endDate).diff(moment(startDate), "days");

      if (durationInDays < 7 || durationInDays > 60) {
        setDateError(
          "Please select a date range with a space between 1 week or 2 months.",
        );
      } else {
        setDateError("");
      }
    }
  }, [selectedDates]);

  useEffect(() => {
    if (botList.length > 0 && show) {
      setSelectedBot(bots[0]?.CreatedDate);
    }
  }, [botList, show]);

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      centered
      footer={null}
      closable={!createBacktestLoading}
      children={
        <div>
          <h3 className={styles.title}>
            <GoDependabot color={"#1640D6"} />
            Select bot to run backtest with bot parameters
          </h3>
          {getBotListLoading ? (
            <Spin tip={"Fetching bot list"} className={styles.spinner} />
          ) : (
            <>
              {botList.length === 0 && (
                <p>
                  You don't have any bot.{" "}
                  <Link
                    style={{ color: "#0C4CFC" }}
                    to={"/bot-automation"}
                    target={"_blank"}
                  >
                    Create now!
                  </Link>
                </p>
              )}
              {botList.length > 0 && (
                <div>
                  <Radio.Group
                    className={styles.botList}
                    onChange={(e) => setSelectedBot(e.target.value)}
                    value={selectedBot}
                  >
                    {bots.map((bot) => (
                      <Radio value={bot?.CreatedDate} key={bot?.CreatedDate}>
                        {bot.bot_status === "active" ? (
                          <Badge
                            count={"Active"}
                            size={"small"}
                            status={"default"}
                            className={styles.badge}
                          >
                            {bot?.bot_configurations?.bot_name}
                          </Badge>
                        ) : (
                          bot?.bot_configurations?.bot_name
                        )}
                      </Radio>
                    ))}
                  </Radio.Group>

                  {botList.length > 3 && (
                    <BotDropdown onSelectBot={setSelectedBot} />
                  )}

                  <div className={styles.timeBtns}>
                    {/* Existing content... */}
                    <Button
                      className={selectedRange === 7 ? styles.activeBtn : ""}
                      onClick={() => handleSelectedTime(7)}
                    >
                      Last week
                    </Button>
                    <Button
                      className={selectedRange === 14 ? styles.activeBtn : ""}
                      onClick={() => handleSelectedTime(14)}
                    >
                      Last 2 weeks
                    </Button>
                    <Button
                      className={selectedRange === 21 ? styles.activeBtn : ""}
                      onClick={() => handleSelectedTime(21)}
                    >
                      Last 3 weeks
                    </Button>
                    <Button
                      className={selectedRange === 1 ? styles.activeBtn : ""}
                      onClick={() => handleSelectedTime(1)}
                    >
                      Last month
                    </Button>
                    <Button
                      className={showTimePicker ? styles.activeBtn : ""}
                      onClick={() => {
                        setShowTimePicker(true);
                        setSelectedDates([]);
                        setSelectedRange(null);
                      }}
                    >
                      Custom
                    </Button>

                    {showTimePicker && (
                      <BacktestTimeSettings
                        selectedBot={selectedBot}
                        setSelectedDates={setSelectedDates}
                        selectedDate={selectedDates}
                      >
                        {dateError && (
                          <p
                            style={{
                              color: "red",
                              margin: ".2rem .5rem",
                              fontSize: "12px",
                            }}
                          >
                            {dateError}
                          </p>
                        )}
                      </BacktestTimeSettings>
                    )}
                  </div>

                  <div className={styles.market}>
                    <h4>Market Type:</h4>
                    <Radio.Group
                      className={styles.markets}
                      value={marketType}
                      onChange={(e) => setMarketType(e.target.value)}
                    >
                      <Radio value={"futures"}>Futures</Radio>
                      <Radio value={"spot"}>Spot</Radio>
                    </Radio.Group>
                  </div>

                  <div className={styles.marginLeverage}>
                    <h4>Margin Leverage:</h4>
                    <Slider
                      min={1}
                      max={10}
                      value={+marginLeverage}
                      onChange={(e) => setMarginLeverage(`${e}`)}
                      tipFormatter={(value) => `${value}x`}
                      marks={{ 1: "1x", 10: "10x" }}
                    />
                  </div>

                  <Button
                    loading={createBacktestLoading}
                    // disabled={
                    //   !calcDateRangeSpace(selectedDates) || !selectedBot
                    // }
                    onClick={handleSubmit}
                    className={`${styles.submitBtn} ${
                      !selectedBot ||
                      !(selectedDates && selectedDates[0] && selectedDates[1])
                        ? styles.inactive
                        : ""
                    }`}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      }
    />
  );
}

export default BacktestRequestModal;

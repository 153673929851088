// styles
import { Tabs, Tour } from "antd";
import styles from "../../styles/pages/trading-assistant/index.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceChart } from "../../redux/trading-assistant/actions";
import React, { useEffect, useRef, useState } from "react";
import PieChart from "../charts/trading-assistant/PieChart";
import LineChart from "../charts/trading-assistant/LineChart";
import PortfolioTable from "../tables/PortfolioTable";
import ShowActiveVirtualPortfolio from "./ShowActiveVirtualPortfolio";
import TabPane from "antd/lib/tabs/TabPane";
import "../../styles/antdTabs.css";
import { ImTable } from "react-icons/im";
import { TbChartPieFilled } from "react-icons/tb";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import VirtualPortfolioInfo from "./portfolio/VirtualPortfolioInfo";
import VirtualPortfolioTableActionButtons from "./portfolio/VirtualPortfolioTableActionButtons";
import NewVirtualPortfolioTable from "./portfolio/NewVirtualPortfolioTable";
import { FaChartColumn } from "react-icons/fa6";

function Portfolio({ portfolio }) {
  const dispatch = useDispatch();

  const {
    main: { userData },
    tradingAssistant: {
      portfolioData,
      getBalanceLoading,
      getPortfolioDataLoading,
      balanceData,
    },
  } = useSelector((state) => state);

  const { PORTFOLIO_TABLE_ROWS, REMANINING_INVESTMENT_BUDGET } = portfolioData;

  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [showTour, setShowTour] = useState(false);
  const [activeTab, setActiveTab] = useState("table");

  const [balanceChartData, setBalanceChartData] = useState(
    balanceData?.BALANCE_CHART || portfolioData?.BALANCE_CHART || [],
  );

  const portfolioChartsRef = useRef(null);

  const tourSteps = [
    {
      title: <FaChartColumn color={"#0C4CFC"} size={18} />,
      description: (
        <p
          style={{
            marginTop: "-16px",
            fontSize: "16px",
            marginBottom: 0,
            color: "#2B303A",
          }}
        >
          View portfolio chart here.
        </p>
      ),
      target: () => portfolioChartsRef.current,
    },
  ];

  const changeBalancePeriod = (value) => {
    setSelectedPeriod(value);
    dispatch(
      getBalanceChart(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        portfolio,
        value,
      ),
    );
  };

  useEffect(() => {
    if (balanceData?.BALANCE_CHART) {
      setBalanceChartData(balanceData?.BALANCE_CHART);
    } else {
      setBalanceChartData(portfolioData?.BALANCE_CHART);
    }
  }, [portfolioData?.BALANCE_CHART, balanceData]);

  useEffect(() => {
    setShowTour(true);
  }, []);

  return (
    <div>
      <div className={styles.tradingHeader}>
        <div>
          {" "}
          <h3>
            <strong>My Portfolio</strong>
          </h3>
        </div>

        <ExchangesAndPaperTradingTab
          activeTab={"paper"}
          pageType={"portfolio"}
        />

        <ShowActiveVirtualPortfolio
          style={{ margin: 0 }}
          section={"portfolio"}
          className={styles.portfolioOption}
        />
      </div>

      <div className={styles.portfolioTable}>
        <VirtualPortfolioTableActionButtons />
        <Tabs
          type={"card"}
          activeKey={activeTab}
          onChange={(key) => {
            if (showTour && key === "chart") setShowTour(false);
            setActiveTab(key);
          }}
          className={`${styles.tabs} custom-tabs`}
          animated
        >
          <TabPane
            tab={
              <span>
                <ImTable size={18} /> Portfolio Table
              </span>
            }
            key={"table"}
          >
            <NewVirtualPortfolioTable
              data={PORTFOLIO_TABLE_ROWS}
              budget={REMANINING_INVESTMENT_BUDGET}
              height={500}
            />
            {/*<PortfolioTable*/}
            {/*  data={PORTFOLIO_TABLE_ROWS}*/}
            {/*  budget={REMANINING_INVESTMENT_BUDGET}*/}
            {/*  height={500}*/}
            {/*/>*/}
          </TabPane>

          <TabPane
            tab={
              <span ref={portfolioChartsRef} onClick={() => setShowTour(false)}>
                <TbChartPieFilled size={18} />
                Portfolio Charts
              </span>
            }
            key={"chart"}
          >
            <VirtualPortfolioInfo />
            <div className={styles.portfolioCharts}>
              <PieChart
                chartData={PORTFOLIO_TABLE_ROWS}
                loading={getPortfolioDataLoading}
              />
              <div>
                <div className={getBalanceLoading ? styles.loading : ""}>
                  <span
                    onClick={() => changeBalancePeriod(7)}
                    className={selectedPeriod === 7 ? styles.activePeriod : ""}
                  >
                    1W
                  </span>
                  <span
                    onClick={() => changeBalancePeriod(14)}
                    className={selectedPeriod === 14 ? styles.activePeriod : ""}
                  >
                    2W
                  </span>
                  <span
                    onClick={() => changeBalancePeriod(30)}
                    className={selectedPeriod === 30 ? styles.activePeriod : ""}
                  >
                    1M
                  </span>
                  <span
                    onClick={() => changeBalancePeriod(60)}
                    className={selectedPeriod === 60 ? styles.activePeriod : ""}
                  >
                    2M
                  </span>
                  <span
                    onClick={() => changeBalancePeriod(1000)}
                    className={
                      selectedPeriod === 1000 ? styles.activePeriod : ""
                    }
                  >
                    All
                  </span>
                </div>
                <LineChart
                  data={balanceChartData}
                  loading={getPortfolioDataLoading}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
      {showTour && (
        <Tour
          animated
          onClose={() => setShowTour(false)}
          steps={tourSteps}
          isOpen={showTour}
        />
      )}
    </div>
  );
}

export default Portfolio;

import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/Components/pricing-plan/Billing.module.css";
import { message, Spin } from "antd";
import { useState } from "react";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { renew } from "../../redux/pricing-plan/action";
import SubscriptionDetails from "./SubscriptionDetails";
import { MdOutlineAutorenew, MdOutlineCancel } from "react-icons/md";
import moment from "moment";
import alert from "../../components/alerts/Alert";
import Chat from "./Chat";
import proPlan from "../../components/pricing-plan/plans/ProPlan";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";

function Billing() {
  const {
    profileData: { email, last_cancel_chat_date },
  } = useSelector((state) => state.profile);
  const { subscriptions, cancelSubscriptionLoading, renewLoading } =
    useSelector((state) => state.pricingPlan);
  const userData = useSelector((state) => state?.main?.userData);

  const {
    basicPlan,
    proPlan,
    proPlanData,
    basicPlanData,
    proPlanType,
    basicPlanType,
  } = useSubscriptionPlans();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const dispatch = useDispatch();

  const clacDifferenceTime = () => {
    if (subscriptions.yearly && !subscriptions.yearly?.cancel_at) {
      const endTime = moment.unix(subscriptions.yearly.current_period_end);
      const now = moment();
      const diff = endTime.diff(now, "months");
      return diff > 2;
    }
  };

  const calcLatChatDiff = () => {
    if (last_cancel_chat_date) {
      const now = moment.utc();
      const diff = now.diff(last_cancel_chat_date, "hours");
      return diff < 24;
    }
  };

  const checkCancellationActive = () => {
    // if (pricing_plan === "Free-tier") return true;
    calcLatChatDiff();
    clacDifferenceTime();
    if (!subscriptions.monthly && !subscriptions.yearly) return true;
    if (subscriptions.monthly || subscriptions.yearly)
      return (
        subscriptions.monthly?.cancel_at || subscriptions.yearly?.cancel_at
      );
  };

  const checkRenewSubscription = () => {
    // if (pricing_plan === "Free-tier") return false;
    if (subscriptions.monthly || subscriptions.yearly)
      return (
        subscriptions.monthly?.cancel_at || subscriptions.yearly?.cancel_at
      );
  };

  const handleRenew = () => {
    dispatch(
      renew(
        userData["cognito:username"],
        userData?.["custom:custom_username"],
        subscriptions.monthly ? "monthly" : "yearly",
      ),
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Billing info</h2>
      {email ? (
        <>
          <p>
            To see your subscription details and invoices or change credit card,
            VAT or address,{" "}
            <a
              href={`https://billing.stripe.com/p/login/4gw15yfxS0Xa2bKbII?prefilled_email=${email.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline", color: "#0c4cfc" }}
            >
              please click here
            </a>
            .
          </p>

          <div className={styles.subscriptionList}>
            <SubscriptionDetails
              plan={proPlan}
              data={proPlanData}
              planType="pro"
              subscription={proPlanType}
            />
            <SubscriptionDetails
              plan={basicPlan}
              data={basicPlanData}
              planType="basic"
              subscription={basicPlanType}
            />
          </div>

          {/*<CancelSubscriptionModal*/}
          {/*  show={showCancelModal}*/}
          {/*  // show={true}*/}
          {/*  setShow={setShowCancelModal}*/}
          {/*  showChat={setShowChat}*/}
          {/*/>*/}
          {/*<Chat show={showChat} close={setShowChat} />*/}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}

export default Billing;

// <div className={styles.btns}>
//             <span
//                 title={
//                   checkCancellationActive()
//                       ? "You don't have any active subscription"
//                       : ""
//                 }
//                 className={`${styles.btn} ${
//                     clacDifferenceTime() ||
//                     checkCancellationActive() ||
//                     calcLatChatDiff()
//                         ? styles.deactive
//                         : ""
//                 }`}
//                 onClick={() => {
//                   if (calcLatChatDiff()) {
//                     alert(
//                         null,
//                         "We will get in touch with you within 24 hours. After that, you can cancel again if you want.",
//                         "info",
//                         {
//                           cancel: "Ok",
//                         },
//                     );
//                     return;
//                   }
//                   if (clacDifferenceTime()) {
//                     alert(
//                         null,
//                         "You still have plenty of time left in this period. \nPlease come back later when it's closer to the end.",
//                         "info",
//                         {
//                           cancel: "Ok",
//                         },
//                     );
//                     return;
//                   }
//                   if (checkCancellationActive()) {
//                     message.info("You don't have any active subscription.");
//                     return;
//                   }
//
//                   setShowCancelModal(true);
//                 }}
//             >
//               {cancelSubscriptionLoading ? (
//                   "Loading..."
//               ) : (
//                   <>
//                     <MdOutlineCancel style={{ marginRight: ".3rem" }} />
//                     Cancel subscription
//                   </>
//               )}
//             </span>
//   <span
//       title={
//         !checkRenewSubscription()
//             ? "You don't have any cancelled subscription"
//             : ""
//       }
//       className={`${styles.btn} ${
//           !checkRenewSubscription() ? styles.deactive : ""
//       }`}
//       onClick={() => {
//         if (!checkRenewSubscription()) {
//           message.info(
//               "You don't have any cancelled subscription.",
//               "10",
//           );
//           return;
//         }
//         handleRenew();
//       }}
//   >
//               {renewLoading ? (
//                   "Loading..."
//               ) : (
//                   <>
//                     <MdOutlineAutorenew style={{ marginRight: ".3rem" }} />
//                     Renew subscription
//                   </>
//               )}
//             </span>
// </div>

import styles from "../../styles/Components/mini-app/mini-pricing/MiniReferralCodes.module.css";
import { useEffect } from "react";
import {
  getReferralCode,
  getReferralRewards,
} from "../../redux/pricing-plan/action";
import { useDispatch, useSelector } from "react-redux";
import UsedReferrals from "./mini-pricing/UsedReferrals";

function MiniReferralCodes() {
  const {
    main: { userData },
    pricingPlan: {
      referralCode,
      referralCodeLoading,
      referralData,
      referralRewardsLoading,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.email && !referralData?.user && !referralRewardsLoading) {
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, referralData, dispatch, referralRewardsLoading]);

  useEffect(() => {
    if (!referralCode && userData?.email && !referralCodeLoading) {
      dispatch(
        getReferralCode(userData?.email, userData?.["custom:custom_username"]),
      );
    }
  }, [dispatch, referralCode, userData, referralCodeLoading]);

  return (
    <div className={styles.container}>
      <UsedReferrals />
    </div>
  );
}

export default MiniReferralCodes;

import styles from "../../../styles/Components/integration/portfolio/ExchangePortfolioTableActionButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import alert from "../../alerts/Alert";
import { showWarningForSellCurrencies } from "../../../utility/helper";
import {
  sellAllCryptoAssets,
  sellAllFiatAssets,
} from "../../../redux/bot/actions";
import { FiRefreshCcw } from "react-icons/fi";
import {
  getExchangeBalanceChart,
  getExchangePortfolio,
} from "../../../redux/exchange/actions";
import LinkWithExchange from "../../../shared/link-with-exchange/LinkWithExchange";
import { setExchange, setMode, setStep } from "../../../redux/trade/actions";
import TradeModal from "../../../shared/trade-modal/TradeModal";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiDollarCircle, BiLink, BiSolidBank } from "react-icons/bi";

function ExchangePortfolioTableActionButtons() {
  const {
    main: { userData },
    profile: { profileData },
    exchangeState: {
      portfolioLoading,
      portfolioData,
      selectedPortfolioExchange,
    },
    botState: { sellAllFiatAssetsLoading, sellAllCryptoAssetsLoading },
  } = useSelector((state) => state);

  const [showTradeModal, setShowTradeModal] = useState(false);

  const portfolioFiatAmount = portfolioData?.find(
    (item) => item?.assetName?.toLowerCase()?.replace("/usdt", "") === "usdt",
  );

  const isAssetInPortfolio = portfolioData?.find(
    (item) => item?.assetName?.toLowerCase()?.replace("/usdt", "") !== "usdt",
  );

  const isNotExchangeLinked =
    profileData?.portfolio1_linked_exchange !== selectedPortfolioExchange &&
    profileData?.portfolio2_linked_exchange !== selectedPortfolioExchange;

  const dispatch = useDispatch();

  const handleSellAllFiatAsset = () => {
    if (sellAllFiatAssetsLoading || portfolioLoading) return;
    if (!portfolioData.length || !portfolioFiatAmount) {
      return alert(
        null,
        "You have no fiat currencies in your portfolio.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    if (+portfolioFiatAmount?.availableBalanceUSD < 1) {
      return alert(
        null,
        "Your fiat currencies are below 1$. Selling all your fiat currencies is not allowed.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    return showWarningForSellCurrencies(
      () =>
        dispatch(
          sellAllFiatAssets(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedPortfolioExchange,
          ),
        ),
      "fiat",
    );
  };

  const handleRefresh = () => {
    if (portfolioLoading) return;
    dispatch(
      getExchangePortfolio(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedPortfolioExchange,
      ),
    );

    dispatch(
      getExchangeBalanceChart(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedPortfolioExchange,
      ),
    );
  };
  const handleSellAllCryptoAssets = () => {
    if (sellAllCryptoAssetsLoading || portfolioLoading) return;

    if (!portfolioData.length || !isAssetInPortfolio) {
      return alert(
        null,
        "You have no crypto assets in your portfolio.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    return showWarningForSellCurrencies(
      () =>
        dispatch(
          sellAllCryptoAssets(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedPortfolioExchange,
          ),
        ),
      "crypto",
    );
  };

  const clickHandler = () => {
    setShowTradeModal(true);
    dispatch(setStep("1-a"));
    dispatch(setExchange(selectedPortfolioExchange));
    dispatch(setMode("integ-btn"));
  };

  const actionMenuItems = [
    {
      label: (
        <LinkWithExchange
          btnText={"Link to Virtual Portfolio"}
          className={styles.linkBtn}
        />
      ),
      icon: <BiLink />,
      key: "1",
    },
    {
      label: "Sell All Fiat",
      icon: <BiSolidBank />,
      key: "2",
    },
    {
      label: "Sell All Crypto",
      icon: <BiDollarCircle />,
      key: "3",
    },
  ];

  const clickMenuHandler = (key) => {
    switch (key) {
      case "1":
        return;
      case "2":
        handleSellAllFiatAsset();
        break;
      case "3":
        handleSellAllCryptoAssets();
        break;
      default:
        return;
    }
  };

  const actionMenu = (
    <Menu
      className={styles.actionMenu}
      items={actionMenuItems}
      onClick={(e) => clickMenuHandler(e.key)}
    />
  );

  return (
    <>
      <ul className={styles.container}>
        {isNotExchangeLinked && (
          <LinkWithExchange btnText={"Link to Virtual Portfolio"} />
        )}

        <Dropdown overlay={actionMenu}>
          <Button className={styles.moreBtn}>
            More Actions <MdKeyboardArrowDown />
          </Button>
        </Dropdown>

        <Button
          className={`${styles.sellFiatBtn} ${styles.desktopBtn}`}
          loading={sellAllFiatAssetsLoading}
          onClick={handleSellAllFiatAsset}
        >
          Sell All Fiat
        </Button>

        <Button
          className={`${styles.sellCryptoBtn} ${styles.desktopBtn}`}
          loading={sellAllCryptoAssetsLoading}
          onClick={handleSellAllCryptoAssets}
        >
          Sell All Crypto
        </Button>

        <Button onClick={clickHandler} className={styles.buySellBtn}>
          Buy / Sell
        </Button>

        <Button
          onClick={handleRefresh}
          // loading={portfolioLoading || fastPortfolioLoading || priceLoading}
          loading={portfolioLoading}
          type={"primary"}
          className={styles.refreshBtn}
        >
          {/*!portfolioLoading && !fastPortfolioLoading*/}
          {!portfolioLoading && <FiRefreshCcw />}
          <span className={styles.desktopView}>Refresh</span>
        </Button>
      </ul>
      <TradeModal show={showTradeModal} setShow={setShowTradeModal} />
    </>
  );
}

export default ExchangePortfolioTableActionButtons;

import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";
import {
  currencyFormatter,
  formatAmount,
  formatCustomDate,
  formatDate,
} from "../../utility/helper";
import { useSelector, useDispatch } from "react-redux";
import { BsPlusSquare } from "react-icons/bs";
import { setBasicTradeInfo, setMode, setStep } from "../../redux/trade/actions";
import TradeModal from "../../shared/trade-modal/TradeModal";
import { FaExchangeAlt } from "react-icons/fa";
import VirtualTradeHistoryExpandedRow from "../trading-assistant/history/VirtualTradeHistoryExpandedRow";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import moment from "moment";
import { getRelativeTime } from "../../utility/formatter-helper";

const TradeHistoryTable = ({ data, height, loading }) => {
  const [showTradeModal, setShowTradeModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const sortedHistory = data?.sort((a, b) =>
    moment(b?.DATE).diff(moment(a?.DATE)),
  );

  const dispatch = useDispatch();

  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const setTradeHandler = (item, e) => {
    e.stopPropagation();

    const { MARKET_TYPE: market, SYMBOL_NAME: asset } = item;
    dispatch(
      setBasicTradeInfo({
        market,
        asset,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  const date = new Date();
  const dateAsString = date.toString();
  const localTimeZone = dateAsString.match(/\(([^)]+)\)$/)[1];
  // (${localTimeZone})
  const columns = [
    {
      title: "Action",
      dataIndex: "type",
      key: "type",
      width: 80,
      align: "center",
      responsive: ["xs", "sm"],

      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          className={`${styles.tableContent} ${
            text?.TRADE_TYPE === "Buy" ? styles.buy : styles.sell
          }`}
          onClick={(e) => setTradeHandler(text, e)}
        >
          {text?.TRADE_TYPE}
        </span>
      ),
    },
    {
      title: "Market",
      dataIndex: "marketType",
      key: "marketType",
      align: "center",
      width: 75,
      responsive: ["md"],

      render: (text) => <span className={styles.tableContent}>{text}</span>,
    },
    {
      title: "Coin",
      dataIndex: "coin",
      key: "coin",
      width: 80,
      align: "center",
      responsive: ["xs", "sm"],

      render: (item) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#0c4cfc",
            // textDecoration: "underline",
            cursor: "pointer",
          }}
          className={styles.tableContent}
          onClick={(e) => setTradeHandler(item, e)}
        >
          <img
            style={{ width: "25px", height: "25px" }}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item?.SYMBOL_NAME}.png`}
            alt=""
          />
          {item?.SYMBOL_NAME}
        </span>
      ),
    },
    {
      title: `Date`,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 150,
      responsive: ["lg"],

      render: (text) => (
        <span className={styles.tableContent}>{getRelativeTime(text)}</span>
      ),
    },
    {
      title: "Native Amount",
      dataIndex: "nativeAmount",
      key: "nativeAmount",
      width: 100,
      responsive: ["sm"],
      align: "center",

      render: (text) => (
        <span className={styles.tableContent}>
          {formatAmount(Number(text))}
        </span>
      ),
    },
    {
      title: "Trade Price",
      dataIndex: "startPrice",
      key: "startPrice",
      align: "center",
      width: 80,
      responsive: ["xs", "sm"],

      render: (text) => (
        <span className={styles.tableContent}>
          ${currencyFormatter(Number(text))}
        </span>
      ),
    },
    {
      title: "Trade Method",
      dataIndex: "tradeMethod",
      key: "tradeMethod",
      align: "center",
      width: 80,
      responsive: ["xxl"],

      render: (text) => (
        <span className={styles.tableContent}>{text || "Manual"}</span>
      ),
    },
    {
      title: "Buy/Sell Asset",
      dataIndex: "buyNow",
      key: "buyNow",
      align: "center",
      responsive: ["md"],
      width: 100,

      render: (item) => (
        <Button
          className={styles.buyNow}
          onClick={(e) => setTradeHandler(item, e)}
        >
          {/*<BsPlusSquare />*/}
          <FaExchangeAlt />
        </Button>
      ),
    },
  ];
  const [tabletData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      sortedHistory?.map((item, index) => {
        return {
          key: index,
          marketType: item?.MARKET_TYPE,
          coin: item,
          date: item?.DATE,
          nativeAmount: item?.AMOUNT_NATIVE,
          usdAmount: item?.USD_AMOUNTS,
          startPrice: item?.START_PRICE,
          type: item,
          buyNow: item,
          tradeMethod: item?.TRADE_METHOD,
        };
      }),
    );
  }, [data]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={tabletData}
        pagination={false}
        scroll={{ y: height }}
        className={`${styles.table} expanded-table`}
        loading={loading}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={
          window.innerWidth < 1600 && {
            expandedRowRender: (record) => (
              <VirtualTradeHistoryExpandedRow data={record} />
            ),
            expandedRowKeys: expandedRowKeys,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (expanded) {
                return (
                  <IoChevronUp
                    className={styles.expandIcon}
                    onClick={(e) => {
                      onRowClick(record);
                    }}
                  />
                );
              } else {
                return (
                  <IoChevronDown
                    className={styles.expandIcon}
                    onClick={(e) => {
                      onRowClick(record);
                    }}
                  />
                );
              }
            },
          }
        }
      />
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
    </>
  );
};

export default TradeHistoryTable;

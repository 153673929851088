// STYLES
import "../../styles/Components/faq/faqPublic.css";

// IMAGES
import alert1 from "../../asset/Images/faq/Alert System/1.PNG";
import alert2 from "../../asset/Images/faq/Alert System/2.PNG";
import alert3 from "../../asset/Images/faq/Alert System/3.PNG";
import alert1_2 from "../../asset/Images/faq/Alert System/1-2.PNG";
import alert1_3 from "../../asset/Images/faq/Alert System/1-3.PNG";
import alert1_4 from "../../asset/Images/faq/Alert System/1-4.PNG";
import alert1_4_1 from "../../asset/Images/faq/Alert System/1-4-1.PNG";
import alert1_4_3 from "../../asset/Images/faq/Alert System/1-4-3.PNG";
import alert1_5 from "../../asset/Images/faq/Alert System/1-5.PNG";
import alert1_6 from "../../asset/Images/faq/Alert System/1-6.PNG";
import alert1_7 from "../../asset/Images/faq/Alert System/1-7.PNG";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import in2 from "../../asset/Images/faq/Insights/HowToUseAISignals/2.png";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AlertSystemGuide = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="hepTitle">Step By Step Guide </h3>
      <div className="stepContainer">
        <p>Here is a step-by-step guide on how to use the alert system:</p>
        <div className="subStepWrapper">
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">1.</span>Alert System: The Alert
              System is where you can customize your alert preferences. You can
              revise your current (default) settings by clicking on the "Revise
              Settings" button.
            </p>
            <LazyLoadImage
              src={alert1}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">2.</span>Tracked Signals: The Tracked
              Signals section allows you to check the list of signals that are
              being tracked. If you want to remove a signal from the list,
              simply click on the radio button before the category name of
              signal, and then click on the "Disable tracking of signal" button.
            </p>
            <LazyLoadImage
              src={alert2}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert2} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">3.</span>Emails: The Emails section
              shows you the latest alert emails that have been sent to you.
            </p>
            <LazyLoadImage
              src={alert3}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert3} />*/}
          </div>
        </div>
      </div>
      <div className="stepContainer">
        <p>
          Now, let's go through the steps to customize your alert settings using
          the Alert System section:
        </p>
        <div className="subStepWrapper">
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">1.</span> Click on the "Revise
              Settings" button to customize your alert settings.
            </p>
            <LazyLoadImage
              src={alert1}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">2.</span> Risk Appetite: Choose your
              preferred risk appetite from the list. You can choose from Very
              Cautious, Cautious, Balanced, Adventurous, or Very Adventurous.
            </p>
            <LazyLoadImage
              src={alert1_2}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1_2} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">3.</span>Alert Frequency: Choose your
              preferred alert frequency from the bu list. You can choose from
              Hourly, Every 3 hours, Every 6 hours, Every 12 hours, Daily, Twice
              a week, Weekly, Biweekly, or Monthly.
            </p>
            <LazyLoadImage
              src={alert1_3}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1_3} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">4.</span> Active Time Interval:
            </p>
            <div className="subStepWrapper">
              <div className="subStepContainer">
                <p>
                  <span className="stepNumber">4-1.</span> Choose your preferred
                  active time interval from the drop-down list. You can choose
                  from Morning (6am - 12pm), Afternoon (12pm - 6pm), Evening
                  (6pm - 12am), Overnight (12am - 6am), or Custom time interval.
                </p>
                <LazyLoadImage
                  src={alert1_4_1}
                  alt="alert system"
                  effect="blur"
                  wrapperClassName="imageContainer"
                />
                {/*<img className="helpImage" src={alert1_4_1} />*/}
              </div>
              <div className="subStepContainer">
                <p>
                  <span className="stepNumber">4-2.</span> Specific Time of Day
                  (for Daily, Twice a week, Weekly, Biweekly, or Monthly
                  alerts): If you have chosen a daily, twice a week, weekly,
                  biweekly, or monthly alert frequency, specify the exact time
                  of day within your chosen active time interval for receiving
                  alerts by entering the Hour and Minute.
                </p>
                <LazyLoadImage
                  src={alert1_4}
                  alt="alert system"
                  effect="blur"
                  wrapperClassName="imageContainer"
                />
                {/*<img className="helpImage" src={alert1_4} />*/}
              </div>
              <div className="subStepContainer">
                <p>
                  <span className="stepNumber">4-3.</span>Custom Time Interval:
                  If you choose a custom time interval, specify the start and
                  end time for your desired alert time interval.
                </p>
                <LazyLoadImage
                  src={alert1_4_3}
                  alt="alert system"
                  effect="blur"
                  wrapperClassName="imageContainer"
                />
                {/*<img className="helpImage" src={alert1_4_3} />*/}
              </div>
            </div>
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">5.</span> Portfolio Watchdog: Choose
              whether you want the portfolio watchdog for loss-making assets to
              be enabled or disabled by selecting "Yes" or "No".
            </p>
            <LazyLoadImage
              src={alert1_5}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1_5} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">6.</span> Prcentage Decrease: Use the
              slider to select the percentage decrease in your portfolio balance
              that you would like to receive a warning for.
            </p>
            <LazyLoadImage
              src={alert1_6}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1_6} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">7.</span> Click on the "Submit"
              button to update your settings.
            </p>
            <LazyLoadImage
              src={alert1_7}
              alt="alert system"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={alert1_7} />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">8.</span> Congratulations! You have
              successfully customized your alert settings.
            </p>
          </div>
        </div>
      </div>
      <p>
        That's it! You have now successfully customized your alert settings. If
        you want to check the list of signals being tracked, go to the Tracked
        Signals section. If you want to check the latest alert emails, go to the
        Emails section.
      </p>
    </div>
  );
};

export default AlertSystemGuide;

import styles from "../../../styles/Components/integration/History/ExchangeHistoryTableExpandedRow.module.css";
import { currencyFormatter, formatCustomDate } from "../../../utility/helper";

function ExchangeHistoryTableExpandedRow({ data }) {
  const isOrderSuccess = data?.info?.order_message
    .toLowerCase()
    ?.includes("success");
  return (
    <div className={styles.container}>
      <p className={styles.message}>
        <span className={styles.label}>
          {isOrderSuccess ? "Order Status" : "Failed Reason"}:
        </span>
        <span
          style={{
            color: `${isOrderSuccess ? "#02a82b" : ""}`,
            textAlign: "center",
          }}
        >
          {data?.info?.order_message}
        </span>
      </p>
      <ul className={styles.dataList}>
        <li>
          <span className={styles.label}>Currency:</span>
          <span>{data?.currency}</span>
        </li>
        <li>
          <span className={styles.label}>Date:</span>
          <span>{formatCustomDate(new Date(data?.date), "show")}</span>
        </li>
        <li>
          <span className={styles.label}>Trigger Price:</span>
          <span>
            {parseFloat(data?.price)
              ? currencyFormatter(data?.price)
              : data?.price}
          </span>
        </li>
        <li className={styles.orderMode}>
          <span className={styles.label}>Order Mode:</span>
          <span>{data?.orderMode}</span>
        </li>
        <li className={styles.orderMode}>
          <span className={styles.label}>Trade Status:</span>
          <span>{data?.status}</span>
        </li>
      </ul>
    </div>
  );
}

export default ExchangeHistoryTableExpandedRow;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-modal/LimitOrderPriceSettintg.module.css";
import { InputNumber, Slider } from "antd";
import { setVirtualTriggerPrice } from "../../../../redux/trade/actions";

function VirtualLimitOrderPriceSetting({ setIsDone, type, className }) {
  const {
    // tradeState: { selectedAsset, triggerPrice, fee },
    tradeState: {
      virtualTriggerPrice,
      virtualSecondTradeType,
      modalMode: mode,
      basicTradeData,
    },
    profile: {
      profileData: { transaction_fee: fee },
    },
    tradingAssistant: { price },
  } = useSelector((state) => state);

  const { stopLossPercent, takeProfitPercent } = basicTradeData;

  const [sliderValue, setSliderValue] = useState(type === " high" ? 8 : 1);
  const [highError, setHighError] = useState(false);
  const [lowError, setLowError] = useState(false);

  const dispatch = useDispatch();

  const handlePriceInputChange = (value) => {
    // Update price input value
    const newSliderValue = (parseFloat(value) / price?.PRICE - 1) * 100;

    dispatch(setVirtualTriggerPrice(value, newSliderValue));

    setSliderValue(newSliderValue);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    // Calculate new price based on slider's percentage value and update the price state
    const newPrice =
      type === "high"
        ? price?.PRICE * (1 + parseFloat(value) / 100)
        : price?.PRICE * (1 - parseFloat(value) / 100);
    // Update price input value
    dispatch(setVirtualTriggerPrice(newPrice, value));
  };

  useEffect(() => {
    if (
      virtualTriggerPrice.value > 0 &&
      sliderValue > 0 &&
      virtualSecondTradeType
    ) {
      if (type === "high") {
        if (
          virtualTriggerPrice.value >=
            Number(price?.PRICE) * (1 + (fee ? Number(fee) : 0.001)) &&
          virtualTriggerPrice.value <= Number(price?.PRICE * (1 + 99.99 / 100))
        ) {
          setIsDone(true);
          setHighError(false);
        } else {
          setIsDone(false);
          setHighError(true);
        }
      }

      if (type === "low") {
        if (
          virtualTriggerPrice.value >= price?.PRICE * (1 - 99.99 / 100) &&
          virtualTriggerPrice.value <= price?.PRICE * (1 - (fee ? fee : 0.001))
        ) {
          setIsDone(true);
          setLowError(false);
        } else {
          setIsDone(false);
          setLowError(true);
        }
      }
    }
  }, [virtualTriggerPrice, sliderValue]);

  useEffect(() => {
    if (type === "high") {
      if (mode === "ai-suggestion") {
        setSliderValue(Number(takeProfitPercent));
        dispatch(
          setVirtualTriggerPrice(
            price?.PRICE * (1 + takeProfitPercent / 100),
            Number(takeProfitPercent),
          ),
        );
      } else {
        setSliderValue(8);
        dispatch(setVirtualTriggerPrice(price?.PRICE * (1 + 8 / 100), 8));
      }
    }
    if (type === "low") {
      if (mode === "ai-suggestion") {
        if (virtualSecondTradeType === "stop_loss") {
          setSliderValue(stopLossPercent);
          dispatch(
            setVirtualTriggerPrice(
              price?.PRICE * (1 - stopLossPercent / 100),
              stopLossPercent,
            ),
          );
        }
        if (virtualSecondTradeType === "discount") {
          setSliderValue(Number(takeProfitPercent));
          dispatch(
            setVirtualTriggerPrice(
              price?.PRICE * (1 - takeProfitPercent / 100),
              Number(takeProfitPercent),
            ),
          );
        }
      } else {
        setSliderValue(1);
        dispatch(setVirtualTriggerPrice(price?.PRICE * (1 - 1 / 100), 1));
      }
    }
  }, [
    dispatch,
    mode,
    price?.PRICE,
    stopLossPercent,
    takeProfitPercent,
    type,
    virtualSecondTradeType,
  ]);

  useEffect(() => {
    if (price?.PRICE) {
      if (type === "high") {
        dispatch(
          setVirtualTriggerPrice(
            price?.PRICE * (1 + sliderValue / 100),
            sliderValue,
          ),
        );
      }

      if (type === "low") {
        dispatch(
          setVirtualTriggerPrice(
            price?.PRICE * (1 - sliderValue / 100),
            sliderValue,
          ),
        );
      }
    }
  }, [price]);

  return (
    <div className={`${styles.container} ${className ? className : ""}`}>
      <InputNumber
        min={
          type === "high"
            ? Number(price?.PRICE) * (1 + (fee ? Number(fee) : 0.001))
            : price?.PRICE * ((1 - 99.99) / 100)
        }
        max={
          type === "high"
            ? Number(price?.PRICE * (1 + 99.99 / 100))
            : price?.PRICE * (1 - (fee ? Number(fee) : 0.001))
        }
        status={
          (type === "high" && highError && "error") ||
          "" ||
          (type === "low" && lowError && "error") ||
          ""
        }
        step={price?.PRICE ? price?.PRICE / 10000 : 0.00001}
        value={virtualTriggerPrice.value}
        onChange={handlePriceInputChange}
        formatter={(value) => `$${value}`}
        parser={(value) => value.replace("$", "")}
        className={styles.input}
      />

      <div className={styles.sliderBox}>
        <Slider
          min={Number((fee ? fee : 0.001) * 100)}
          max={99.99}
          step={0.01}
          onChange={handleSliderChange}
          value={typeof sliderValue === "number" ? sliderValue : 0}
          tooltip={{ formatter: (value) => `${value.toFixed(2)}%` }}
          style={{ width: "70%" }}
        />
        <InputNumber
          min={Number((fee ? fee : 0.001) * 100)}
          max={99.99}
          status={
            (type === "high" && highError && "error") ||
            "" ||
            (type === "low" && lowError && "error") ||
            ""
          }
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={sliderValue}
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {mode === "ai-suggestion" &&
        type === "high" &&
        sliderValue === Number(takeProfitPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "high" &&
        takeProfitPercent &&
        sliderValue !== Number(takeProfitPercent) && (
          <p
            onClick={() => setSliderValue(Number(takeProfitPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "low" &&
        sliderValue === Number(stopLossPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "low" &&
        virtualSecondTradeType === "discount" &&
        sliderValue === Number(takeProfitPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "low" &&
        virtualSecondTradeType !== "discount" &&
        stopLossPercent &&
        sliderValue !== Number(stopLossPercent) && (
          <p
            onClick={() => setSliderValue(Number(stopLossPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "low" &&
        virtualSecondTradeType === "discount" &&
        takeProfitPercent &&
        sliderValue !== Number(takeProfitPercent) && (
          <p
            onClick={() => setSliderValue(Number(takeProfitPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {type === "high" && highError && (
        <p className={styles.errorMessage}>
          The trigger price should be higher than the current price!
        </p>
      )}
      {type === "low" && lowError && (
        <p className={styles.errorMessage}>
          The trigger price should be lower than the current price!
        </p>
      )}
    </div>
  );
}

export default VirtualLimitOrderPriceSetting;

import { Modal } from "antd";

function FrequentlyAskedQuestionModal({ show, closeModal, page }) {
  return (
    <Modal open={show} onCancel={closeModal} footer={null} centered>
      questions
    </Modal>
  );
}

export default FrequentlyAskedQuestionModal;

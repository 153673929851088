import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Slider } from "antd";
import { setTriggerPrice } from "../../../../redux/trade/actions";
import { extractCoinName } from "../../../../utility/tradeHelper";
import styles from "../../../../styles/shared/trade-modal/LimitOrderPriceSettintg.module.css";

function LimitOrderPriceSetting({ setIsDone, type, className }) {
  const {
    tradeState: {
      selectedAsset,
      triggerPrice,
      fee,
      modalMode: mode,
      basicTradeData,
    },
    exchangeState: { priceData },
  } = useSelector((state) => state);

  const { stopLossPercent, takeProfitPercent } = basicTradeData;

  const [sliderValue, setSliderValue] = useState(type === " high" ? 8 : 1);
  const [highError, setHighError] = useState(false);
  const [lowError, setLowError] = useState(false);

  const dispatch = useDispatch();

  const handlePriceInputChange = (value) => {
    // Update price input value
    dispatch(setTriggerPrice(value));

    const newSliderValue =
      (parseFloat(value) / priceData[extractCoinName(selectedAsset)] - 1) * 100;
    setSliderValue(newSliderValue);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    // Calculate new price based on slider's percentage value and update the price state
    const newPrice =
      type === "high"
        ? priceData[extractCoinName(selectedAsset)] *
          (1 + parseFloat(value) / 100)
        : priceData[extractCoinName(selectedAsset)] *
          (1 - parseFloat(value) / 100);
    // Update price input value
    dispatch(setTriggerPrice(newPrice));
  };

  useEffect(() => {
    if (triggerPrice > 0 && sliderValue > 0) {
      if (type === "high") {
        if (
          triggerPrice >=
            Number(priceData[extractCoinName(selectedAsset)]) *
              (1 + Number(fee)) &&
          triggerPrice <=
            Number(
              priceData[extractCoinName(selectedAsset)] * (1 + 99.99 / 100),
            )
        ) {
          setIsDone(true);
          setHighError(false);
        } else {
          setIsDone(false);
          setHighError(true);
        }
      }

      if (type === "low") {
        if (
          triggerPrice >=
            priceData[extractCoinName(selectedAsset)] * (1 - 99.99 / 100) &&
          triggerPrice <= priceData[extractCoinName(selectedAsset)] * (1 - fee)
        ) {
          setIsDone(true);
          setLowError(false);
        } else {
          setIsDone(false);
          setLowError(true);
        }
      }
    }
  }, [triggerPrice, sliderValue]);

  useEffect(() => {
    if (mode !== "ai-suggestion") {
      if (type === "high") {
        setSliderValue(8);
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] * (1 + 8 / 100),
          ),
        );
      }
      if (type === "low") {
        setSliderValue(1);
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] * (1 - 1 / 100),
          ),
        );
      }
    } else {
      if (type === "high") {
        setSliderValue(Number(takeProfitPercent));
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] *
              (1 + takeProfitPercent / 100),
          ),
        );
      }
      if (type === "low") {
        setSliderValue(Number(stopLossPercent));
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] *
              (1 - stopLossPercent / 100),
          ),
        );
      }
    }
  }, [type]);

  useEffect(() => {
    if (Object.keys(priceData).length > 0) {
      if (type === "high") {
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] * (1 + sliderValue / 100),
          ),
        );
      }

      if (type === "low") {
        dispatch(
          setTriggerPrice(
            priceData[extractCoinName(selectedAsset)] * (1 - sliderValue / 100),
          ),
        );
      }
    }
  }, [priceData]);

  return (
    <div className={`${styles.container} ${className ? className : ""}`}>
      <InputNumber
        min={
          type === "high"
            ? Number(priceData[extractCoinName(selectedAsset)]) *
              (1 + Number(fee))
            : priceData[extractCoinName(selectedAsset)] * (1 - 99.99 / 100)
        }
        max={
          type === "high"
            ? Number(
                priceData[extractCoinName(selectedAsset)] * (1 + 99.99 / 100),
              )
            : priceData[extractCoinName(selectedAsset)] * (1 - Number(fee))
        }
        status={
          (type === "high" && highError && "error") ||
          "" ||
          (type === "low" && lowError && "error") ||
          ""
        }
        step={priceData[extractCoinName(selectedAsset)] / 10000}
        value={triggerPrice}
        onChange={handlePriceInputChange}
        formatter={(value) => `$${value}`}
        parser={(value) => value.replace("$", "")}
        className={styles.input}
      />

      <div className={styles.sliderBox}>
        <Slider
          min={Number(fee * 100)}
          max={99.99}
          step={0.01}
          onChange={handleSliderChange}
          value={typeof sliderValue === "number" ? sliderValue : 0}
          tooltip={{ formatter: (value) => `${value.toFixed(2)}%` }}
          style={{ width: "70%" }}
        />
        <InputNumber
          min={Number(fee * 100)}
          max={99.99}
          status={
            (type === "high" && highError && "error") ||
            "" ||
            (type === "low" && lowError && "error") ||
            ""
          }
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={sliderValue}
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {mode === "ai-suggestion" &&
        type === "high" &&
        sliderValue === Number(takeProfitPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "high" &&
        takeProfitPercent &&
        sliderValue !== Number(takeProfitPercent) && (
          <p
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setSliderValue(Number(takeProfitPercent))}
          >
            Reset to AI suggestion.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "low" &&
        sliderValue === Number(stopLossPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "low" &&
        stopLossPercent &&
        sliderValue !== Number(stopLossPercent) && (
          <p
            onClick={() => setSliderValue(Number(stopLossPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {type === "high" && highError && (
        <p className={styles.errorMessage}>
          The trigger price should be higher than the current price!
        </p>
      )}
      {type === "low" && lowError && (
        <p className={styles.errorMessage}>
          The trigger price should be lower than the current price!
        </p>
      )}
    </div>
  );
}

export default LimitOrderPriceSetting;

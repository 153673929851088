import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { notify } from "../../utility/Toastify/toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setToken, setUserData } from "../../redux/main/action/main";
import ReferralModal from "../../components/auth/ReferralModal";
import AuthAlert from "./AuthAlert";

const clientId =
  "225357808354-8efg7jlf2sina9grvsj571mfnhhh1pms.apps.googleusercontent.com";

const GoogleSignUpButton = ({ referralCode, setLoading }) => {
  const [googleResponse, setGoogleResponseL] = useState({});
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signup = async (arg) => {
    setLoading(true);
    await axios
      .post(
        "https://vtq2y0v4ed.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        { mode: "signup", accessToken: arg.token, referralCode: arg.code },
      )
      .then((res) => {
        dispatch(setToken(res?.data.idToken?.jwtToken));
        dispatch(setUserData(res?.data.idToken?.payload));
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res?.data.idToken?.jwtToken}`;
        localStorage.setItem(
          "aws-amplify-federatedInfo",
          JSON.stringify({ token: res?.data?.idToken?.jwtToken }),
        );
        localStorage.setItem(
          "aws-amplify-USERDATA",
          JSON.stringify(res?.data?.idToken?.payload),
        );
        // window.location.reload();
        notify(res.data.message, "success");
        // setLoading(false);
        // navigate("/ai-assistant/assistant");
        navigate("/welcome");
      })
      .catch((err) => {
        const { data, status } = err.response;
        // notify(err.response.data.message, "error");
        setShowAlert(true);
        setErrorMsg(data.message);
        setErrorCode(status);
      });
    setLoading(false);
  };
  const responseGoogle = async (response) => {
    // Handle the Google login response
    if (referralCode) {
      signup({ token: response.credential, code: referralCode });
      return;
    }
    setShowReferralModal(true);
    setGoogleResponseL(response);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
      }}
    >
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          buttonText="Sign Up with Google"
          onSuccess={(response) => responseGoogle(response)}
          onFailure={(response) => responseGoogle(response)}
          cookiePolicy={"single_host_origin"}
          isSignedIn={false} // Set this prop to true for sign-up flow
        />
      </GoogleOAuthProvider>
      <ReferralModal
        show={showReferralModal}
        setShow={setShowReferralModal}
        submit={signup}
        data={{ token: googleResponse.credential, code: refCode }}
        setReferral={setRefCode}
      />
      <AuthAlert
        show={showAlert}
        setShow={setShowAlert}
        code={errorCode}
        msg={errorMsg}
        mode={errorCode === 422}
      />
    </div>
  );
};

export default GoogleSignUpButton;

import { useSelector } from "react-redux";
import styles from "../../../styles/Components/pricing-plan/referrals/Referrals.module.css";
import Loader from "../../mini-app/shared/Loader";
import ReferredUser from "../../mini-app/mini-pricing/ReferredUser";
import InviteFriendsButton from "../../mini-app/mini-pricing/InviteFriendsButton";
import { FaCreditCard, FaMoneyBill, FaUserFriends } from "react-icons/fa";
import WithdrawButton from "./WithdrawButton";
import { useState } from "react";
import InviteFriendsModal from "../../mini-app/mini-pricing/InviteFriendsModal";

function Referrals() {
  const {
    pricingPlan: { referralRewardsLoading, referralRewards },
  } = useSelector((state) => state);

  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const hasReferred = referralRewards?.length > 0;
  const totalCredit = referralRewards?.reduce(
    (acc, cur) => Number(cur?.addition_reward) + acc,
    0,
  );
  const totalMoney = referralRewards?.reduce(
    (acc, cur) => Number(cur?.cashable_reward) + acc,
    0,
  );

  if (referralRewardsLoading) return <Loader height={"120px"} />;

  return (
    <div className={styles.container}>
      {hasReferred && (
        <div className={styles.referredList}>
          {referralRewards?.map((user) => (
            <ReferredUser
              key={`${user?.referred_user_email}/${user?.createdDate}`}
              data={user}
            />
          ))}
          <InviteFriendsButton
            title={"+ Add more"}
            referrals={referralRewards}
            customClassName={styles.addMoreBtn}
          />
        </div>
      )}

      <div className={styles.summaryBox}>
        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaUserFriends /> Number of Friends
          </span>
          <span className={styles.infoValue}>{referralRewards?.length}</span>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaCreditCard /> Total Earned Credit
          </span>
          <span className={styles.infoValue}>$ {totalCredit / 100}</span>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaMoneyBill /> Total Earned Money
          </span>
          <span className={styles.infoValue}>$ {totalMoney}</span>
        </div>

        <WithdrawButton style={{ marginTop: "1rem" }} />
        <div
          className={styles.linkBtn}
          onClick={() => setShowInvitationModal(true)}
        >
          Invite & Refer
        </div>
      </div>

      {!hasReferred && (
        <InviteFriendsButton
          title={"Invite Friends"}
          referrals={referralRewards}
          customClassName={`${styles.addMoreBtn} ${styles.inviteBtn}`}
        />
      )}

      {showInvitationModal && (
        <InviteFriendsModal
          show={showInvitationModal}
          setShow={setShowInvitationModal}
        />
      )}
    </div>
  );
}

export default Referrals;

import { useSelector } from "react-redux";
import * as React from "react";
import { Col, Row, Statistic } from "antd";
import { formatPrice } from "../../../utility/helper";
import styles from "../../../styles/Components/dashboard/portfolio-summary/RenderPortfolioContent.module.css";
import { GoDotFill } from "react-icons/go";

const getStatColor = (value) =>
  Number(value.replace("%", "")) >= 0 ? "#3f8600" : "#cf1322";

function RenderPortfolioContent({ data, portfolioKey }) {
  const {
    dashboardData: { data: dashboardData },
    profile: { profileData },
  } = useSelector((state) => state);

  const portfolioData = data[`portfolio_${portfolioKey}`];

  return (
    <ul className={styles.container}>
      <li>
        <span className={styles.title}>
          <GoDotFill />
          Current Balance:
        </span>
        <span>${formatPrice(portfolioData?.current_balance)}</span>
      </li>

      <li>
        <span className={styles.title}>
          <GoDotFill />
          Balance Change (24h):
        </span>
        <span
          style={{
            color: `${getStatColor(
              portfolioData.balance_change_percentage_24h,
            )}`,
          }}
        >
          {portfolioData.balance_change_percentage_24h}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <GoDotFill />
          Balance Change (7d):
        </span>
        <span
          style={{
            color: `${getStatColor(
              portfolioData.balance_change_percentage_7d,
            )}`,
          }}
        >
          {portfolioData.balance_change_percentage_7d}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <GoDotFill />
          Remaining Budget:
        </span>
        <span>
          {formatPrice(
            profileData?.[
              `REMANINING_INVESTMENT_BUDGET_PORTFOLIO${portfolioKey}`
            ],
          )}
        </span>
      </li>
    </ul>
  );
}

export default RenderPortfolioContent;

// <Col span={12}>
//     <Card title={`Portfolio ${portfolioKey}`}>
//         <Statistic
//             title="Current balance"
//             value={formatPrice(portfolioData.current_balance)}
//             valueStyle={{
//                 fontSize: "16px",
//             }}
//             precision={2}
//             prefix="$"
//         />
//         <Statistic
//             title="Balance change (24h)"
//             value={portfolioData.balance_change_percentage_24h}
//             valueStyle={{
//                 color: getStatColor(portfolioData.balance_change_percentage_24h),
//                 fontSize: "16px",
//             }}
//         />
//         <Statistic
//             title="Balance change (7d)"
//             value={portfolioData.balance_change_percentage_7d}
//             valueStyle={{
//                 color: getStatColor(portfolioData.balance_change_percentage_7d),
//                 fontSize: "16px",
//             }}
//         />
//         {/*<Statistic*/}
//         {/*    title="Investment budget"*/}
//         {/*    value={profileData?.[`INVESTMENT_BUDGET_PORTFOLIO${portfolioKey}`]}*/}
//         {/*    valueStyle={{*/}
//         {/*        fontSize: '16px',*/}
//         {/*    }}*/}
//         {/*    precision={2}*/}
//         {/*    prefix="$"*/}
//         {/*/>*/}
//         <Statistic
//             title="Remaining budget"
//             value={formatPrice(
//                 profileData?.[
//                     `REMANINING_INVESTMENT_BUDGET_PORTFOLIO${portfolioKey}`
//                     ],
//             )}
//             valueStyle={{
//                 fontSize: "16px",
//             }}
//             precision={2}
//             prefix="$"
//         />
{
  /*    </Card>*/
}
{
  /*</Col>*/
}

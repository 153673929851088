import { useDispatch, useSelector } from "react-redux";
import styles from "./Test.module.css";
import SkeletonContainer from "../components/dashboard/SkeletonContainer";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { currencyFormatter, extractNumberFromString } from "../utility/helper";
import { useRef } from "react";
// import TradeModal from "../components/modal/TradeModal";
import { getPrice } from "../redux/trading-assistant/actions";
import { Link } from "react-router-dom";
import { setBasicTradeInfo, setMode, setStep } from "../redux/trade/actions";
import TradeModal from "../shared/trade-modal/TradeModal";
import ContentContainer from "../components/dashboard/ContentContainer";
import last from "../asset/Icons/last۱.gif";
import DashboardCardTitle from "../components/dashboard/DashboardCardTitle";
import topCoinIcon from "../asset/Icons/topCoin.gif";
import topGainerLoserIcon from "../asset/Icons/topGainerLoser.gif";
import topRelativelyGainerLoserIcon from "../asset/Icons/relGainerLoser.gif";

const slideIcons = {
  "Top Gainers and Losers": topGainerLoserIcon,
  "Top Coins": topCoinIcon,
  "Relatively Gainers and Losers": topRelativelyGainerLoserIcon,
};

const Slide = function ({ className, title, data1, data2, n = 5, type }) {
  const [showTradeModal, setShowTradeModal] = useState(false);
  const dispatch = useDispatch();

  const {
    main: { userData },
  } = useSelector((state) => state);

  const setTradeHandler = (item) => {
    const { SYMBOL_NAME: asset, MARKET_TYPE: market, value } = item;
    if (type === "suggestion") {
      let suggestedTrade;
      if (value < 0) {
        suggestedTrade = "Sell";
      } else {
        suggestedTrade = "Buy";
      }
      dispatch(
        setBasicTradeInfo({
          market,
          asset,
          tradeType: suggestedTrade,
        }),
      );
    } else {
      dispatch(
        setBasicTradeInfo({
          market,
          asset,
        }),
      );
    }
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  return (
    <div className={`${className ? className : ""} ${styles.slide}`}>
      {/*<h3>{title}</h3>*/}
      <DashboardCardTitle text={title} icon={slideIcons[title]} />
      <div className={styles.slideContainer}>
        <div className={styles.firstColumn}>
          {data1.slice(0, n).map((item) => (
            <div key={item.symbol} className={styles.item}>
              {item.icon}
              <span
                // onClick={() => getPriceHandler(item)}
                onClick={() => setTradeHandler(item)}
                style={{
                  color: "#0c4cfc",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {item.symbol}
              </span>
              <span
                className={styles.itemValue}
                style={item.value < 0 ? { color: "red" } : { color: "green" }}
              >
                {currencyFormatter(
                  item.value,
                  title === "Relatively Gainers and Losers",
                )}
              </span>
            </div>
          ))}
        </div>
        {data2 && (
          <div className="column">
            {data2.slice(0, n).map((item) => (
              <div key={item.symbol} className={styles.item}>
                {item.icon}
                <span
                  onClick={() => setTradeHandler(item)}
                  style={{
                    color: "#0c4cfc",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {item.symbol}
                </span>
                <span
                  className={styles.itemValue}
                  style={item.value < 0 ? { color: "red" } : { color: "green" }}
                >
                  {currencyFormatter(
                    item.value,
                    title === "Relatively Gainers and Losers",
                  )}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
    </div>
  );
};

const Test = ({ data, loading }) => {
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTTopLosers] = useState([]);
  const [topCoins, setTopCoins] = useState([]);
  const [relativelyGainers, setRelativelyGainers] = useState([]);
  const [relativelyLosers, setRelativelyLosers] = useState([]);
  const [slide, setSlide] = useState(1);
  const [stopSlideShow, setStopSlideShow] = useState(false);

  const timerRef = useRef();
  useEffect(() => {
    if (!stopSlideShow) {
      // Set the timer to update the slide index every 5 seconds
      timerRef.current = setTimeout(() => {
        setSlide((prevSlide) => (prevSlide === 3 ? 1 : prevSlide + 1));
      }, 5000);

      // Return a cleanup function to clear the timer
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [slide, stopSlideShow]);

  useEffect(() => {
    if (data) {
      setTopGainers(
        data.TOP_20_Gainers_SYMBOLS.map((symbol, index) => {
          return {
            SYMBOL_NAME: symbol,
            MARKET_TYPE: "crypto",
            symbol: symbol,
            value: data.TOP_20_Gainers[index],
            icon: (
              <img
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "100%",
                  marginRight: ".5rem",
                }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
                alt={symbol}
              />
            ),
          };
        }),
      );

      setTTopLosers(
        data.TOP_20_losers_SYMBOLS.map((symbol, index) => {
          return {
            SYMBOL_NAME: symbol,
            MARKET_TYPE: "crypto",
            symbol: symbol,
            value: data.TOP_20_losers[index],
            icon: (
              <img
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "100%",
                  marginRight: ".5rem",
                }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
                alt={symbol}
              />
            ),
          };
        }),
      );

      setTopCoins(
        data.TOP_20_SYMBOLS.map((symbol, index) => {
          return {
            SYMBOL_NAME: symbol,
            MARKET_TYPE: "crypto",
            symbol: symbol,
            value: data.TOP_20[index],
            icon: (
              <img
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "100%",
                  marginRight: ".5rem",
                }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
                alt={symbol}
              />
            ),
          };
        }),
      );

      setRelativelyGainers(
        data.TOP_20_Relatively_Gainers_SYMBOLS.map((symbol, index) => {
          return {
            SYMBOL_NAME: symbol,
            MARKET_TYPE: "crypto",
            symbol: symbol,
            value: data.TOP_20_Relatively_Gainers[index],
            icon: (
              <img
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "100%",
                  marginRight: ".5rem",
                }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
                alt={symbol}
              />
            ),
          };
        }),
      );

      setRelativelyLosers(
        data.TOP_20_Relatively_Losers_SYMBOLS.map((symbol, index) => {
          return {
            SYMBOL_NAME: symbol,
            MARKET_TYPE: "crypto",
            symbol: symbol,
            value: data.TOP_20_Relatively_Losers[index],
            icon: (
              <img
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "100%",
                  marginRight: ".5rem",
                }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
                alt={symbol}
              />
            ),
          };
        }),
      );
    }
  }, [data]);

  const clickHandler = (dir) => {
    // Clear the timer when the user clicks on the arrows or buttons
    clearTimeout(timerRef.current);

    if (dir === "back") {
      if (slide === 1) {
        setSlide(3);
        return;
      }
      setSlide((prevstate) => prevstate - 1);
    }

    if (dir === "next") {
      if (slide === 3) {
        setSlide(1);
        return;
      }
      setSlide((prevstate) => prevstate + 1);
    }
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setStopSlideShow(true)}
      onMouseLeave={() => setStopSlideShow(false)}
    >
      {loading && !data ? (
        <SkeletonContainer />
      ) : (
        data && (
          <ContentContainer className={styles.content}>
            <span
              className={`${styles.arrow} ${styles.left}`}
              onClick={() => clickHandler("back")}
            >
              <IoIosArrowBack />
            </span>
            {topGainers.length !== 0 && (
              <>
                <Slide
                  className={slide === 1 ? styles.show : ""}
                  title="Top Gainers and Losers"
                  data1={topGainers}
                  data2={topLosers}
                  type={"suggestion"}
                />
                <Slide
                  className={`${slide === 2 ? styles.show : ""} ${
                    styles.topCoins
                  }`}
                  title="Top Coins"
                  data1={topCoins}
                  n={10}
                />
                <Slide
                  className={slide === 3 ? styles.show : ""}
                  title="Relatively Gainers and Losers"
                  data1={relativelyGainers}
                  data2={relativelyLosers}
                  type={"suggestion"}
                />
              </>
            )}
            <span
              className={`${styles.arrow} ${styles.right}`}
              onClick={() => clickHandler("next")}
            >
              <IoIosArrowForward />
            </span>
            <Link className={styles.link} to="/ai-assistant">
              See more suggestion
            </Link>
          </ContentContainer>
        )
      )}
    </div>
  );
};

export default Test;

import styles from "../../styles/Components/trade-assistant/ShowActiveVirtualPortfolio.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setVirtualPortfolio } from "../../redux/trading-assistant/actions";
import { PiNumberCircleOneBold, PiNumberCircleTwoBold } from "react-icons/pi";

const formatLinkedExchange = (exchange) => {
  if (exchange.length > 6) {
    return `${exchange.slice(0, 6)}...`;
  }
  return exchange;
};

function ShowActiveVirtualPortfolio({ style, section, className }) {
  const {
    tradingAssistant: {
      selectedPortfolio,
      getPortfolioDataLoading,
      getTradeHistoryLoading,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const activePortfolio = selectedPortfolio?.toLowerCase();

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const changePortfolioHandler = (e) => {
    const portfolioEl = e.target.closest("li");
    if (portfolioEl) {
      const portfolio = portfolioEl.id;
      dispatch(setVirtualPortfolio(portfolio));
    }
  };

  return (
    <div
      className={`${styles.container} ${className || ""}`}
      style={style || {}}
    >
      {/*<span className={styles.title}>Portfolio:</span>*/}
      <ul className={styles.portfolioList} onClick={changePortfolioHandler}>
        <li
          id={"primary"}
          className={`${styles.portfolioItem} ${
            activePortfolio === "primary" ? styles.active : ""
          } ${
            (section === "portfolio"
              ? getPortfolioDataLoading
              : getTradeHistoryLoading) && activePortfolio !== "primary"
              ? styles.waiting
              : ""
          }`}
        >
          <PiNumberCircleOneBold size={20} />
          First
          {isFirstPortfolioConnected && (
            <span className={styles.badge}>
              {formatLinkedExchange(profileData?.portfolio1_linked_exchange)}
            </span>
          )}
        </li>
        <li
          id={"secondary"}
          className={`${styles.portfolioItem} ${
            activePortfolio === "secondary" ? styles.active : ""
          } ${
            (section === "portfolio"
              ? getPortfolioDataLoading
              : getTradeHistoryLoading) && activePortfolio !== "secondary"
              ? styles.waiting
              : ""
          }`}
        >
          <PiNumberCircleTwoBold size={20} />
          Second
          {isSecondPortfolioConnected && (
            <span className={styles.badge}>
              {formatLinkedExchange(profileData?.portfolio2_linked_exchange)}
            </span>
          )}
        </li>
      </ul>
    </div>
  );
}

export default ShowActiveVirtualPortfolio;

import styles from "../../../styles/Components/dashboard/Payments/Costs.module.css";

// ICONS
import insightIcon from "../../../asset/Icons/icon-12.svg";
import alertIcon from "../../../asset/Icons/icon-16.svg";
import apiIcon from "../../../asset/Icons/icon-09.svg";
import pricingIcon from "../../../asset/Icons/icon-13.svg";
import AIIcon from "../../../asset/Icons/icon-15.svg";
import tradeSimulatorIcon from "../../../asset/Icons/icon-18.svg";
import cash from "../../../asset/Icons/cash.gif";
import { Link } from "react-router-dom";
import * as React from "react";
import { Spin } from "antd";
import Modal from "../../modal/Modal";
import LineChart from "../../charts/dashboard/LineChart";
import { useState } from "react";
import { useSelector } from "react-redux";
import ContentContainer from "../ContentContainer";
import prediction from "../../../asset/Icons/prediction.gif";
import DashboardCardTitle from "../DashboardCardTitle";

// HELPER FUNCTION TO SUM THE COSTS OF AN OBJECT
const sumCosts = (obj) => {
  let total = 0;
  for (let key in obj) {
    total += obj[key];
  }
  return total;
};

const icons = {
  insights: {
    name: "Insights",
    icon: insightIcon,
    path: "/insight",
  },
  "ai-assistant": {
    name: "AI Assistant",
    icon: AIIcon,
    path: "/ai-assistant/assistant",
  },
  portfolio: {
    name: "Portfolio",
    icon: AIIcon,
    path: "/ai-assistant/portfolio",
  },
  "alert-system": {
    name: "Alert System",
    icon: alertIcon,
    path: "/alert-system",
  },
  pricing: {
    name: "Pricing Plan",
    icon: pricingIcon,
    path: "/pricing-billing",
  },
  "trade-simulator": {
    name: "Trade Simulator",
    icon: tradeSimulatorIcon,
    path: "/trade-simulator",
  },
  "dev-api": {
    name: "Developer API",
    icon: apiIcon,
    path: "/developer-api",
  },
};

const CostDetails = (obj) => {
  const sum = sumCosts(obj); // get the sum of the costs
  return sum === 0 ? ( // check if the sum is zero
    <p className="message">No costs incurred.</p> // show a message
  ) : (
    <ul className="list">
      {Object.entries(obj).map(([key, value]) => (
        <li key={key} className={styles.listItem}>
          <Link to={icons[key].path}>
            <img
              style={{ width: "20px", marginRight: ".5rem" }}
              src={icons[key].icon}
              alt="icon"
            />
            <span className="key">{icons[key].name}</span>
          </Link>
          <span className="value">$ {value.toFixed(2)}</span>
        </li>
      ))}
    </ul>
  );
};

const Costs = function ({ data, loading }) {
  const {
    dashboardData: { data: dashboardData },
  } = useSelector((state) => state);

  const [showChartModal, setShowChartModal] = useState(false);

  return (
    <div className={styles.container}>
      <ContentContainer>
        <div className={styles.header}>
          <DashboardCardTitle text={"Cost Analysis"} icon={cash} />
          {/*<h3>*/}
          {/*  <img*/}
          {/*    src={cash}*/}
          {/*    style={{ marginRight: ".5rem", width: "35px" }}*/}
          {/*    alt="cash"*/}
          {/*  />*/}
          {/*  Cost Analysis*/}
          {/*</h3>*/}
          {loading && !data && <Spin />}
        </div>
        {data ? (
          sumCosts(data["last_month"]) +
            sumCosts(data["last_2_month"]) +
            sumCosts(data["last_2_weeks"]) +
            sumCosts(data["last_week"]) ===
          0 ? (
            <p className={styles.message}>No costs incurred in any period.</p>
          ) : (
            <div className={styles.secContent}>
              <div className="column">
                <h4 className="subtitle">Total Costs:</h4>
                <div className={styles.totalItems}>
                  <p className={styles.total}>
                    <span className={styles.totalTitle}>Last week</span>
                    <span className={styles.totalValue}>
                      $ {sumCosts(data["last_week"]).toFixed(2)}
                    </span>
                  </p>
                  <p className={styles.total}>
                    <span className={styles.totalTitle}>Last two weeks</span>
                    <span className={styles.totalValue}>
                      $ {sumCosts(data["last_2_weeks"]).toFixed(2)}
                    </span>
                  </p>
                  <p className={styles.total}>
                    <span className={styles.totalTitle}>Last month</span>
                    <span className={styles.totalValue}>
                      $ {sumCosts(data["last_month"]).toFixed(2)}
                    </span>
                  </p>
                  <p className={styles.total}>
                    <span className={styles.totalTitle}>Last two months</span>
                    <span className={styles.totalValue}>
                      $ {sumCosts(data["last_2_month"]).toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="column">
                <h4 className="subtitle">Last Week Costs</h4>
                <ul className="list">{CostDetails(data["last_week"])}</ul>
              </div>
              <span
                className={styles.link}
                onClick={() => setShowChartModal(true)}
              >
                See Cost Chart
              </span>
              <Modal
                title="Cost Chart"
                open={showChartModal}
                handleOk={() => setShowChartModal(false)}
                handleCancel={() => setShowChartModal(false)}
                footer={false}
                width={800}
                centered={true}
                content={
                  <div className={styles.chartContainer}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 16,
                      }}
                    >
                      <div style={{ width: "100%", maxHeight: "300px" }}>
                        <LineChart data={dashboardData} />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          )
        ) : (
          ""
        )}
      </ContentContainer>
    </div>
  );
};

export default Costs;

import styles from "../../../../styles/Components/bot/bot-backtest/BacktestReport.module.css";
import { riskAppetiteColor, riskOptions } from "../../../../utility/data";
import { formatCustomDate } from "../../../../utility/helper";
import { useSelector } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";

function SummaryCard() {
  const {
    botState: { selectedBacktest, backtesetReportData, backtestReportLoading },
  } = useSelector((state) => state);

  const parsedData = Object.entries(
    JSON.parse(backtesetReportData?.trade_report),
  )
    .flat()
    .filter((item) => typeof item === "object");
  // const totalClosedPosition = parsedData.map(item => item[0])
  const totalClosedPosition = parsedData.reduce(
    (acc, cur) => acc + cur.closed_positions,
    0,
  );

  const totalProfit = parsedData.reduce(
    (acc, cur) => acc + cur.profitable_positions,
    0,
  );

  const averageProfit = (totalProfit / totalClosedPosition) * 100;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "2rem",
        flexDirection: "column",
      }}
    >
      <div className={styles.summaryCard}>
        <div className={styles.cardHeader}>
          <span>Summary</span>
        </div>
        <ul className={styles.cardBody}>
          <li className={styles.summaryItem}>
            <span>Bot Name:</span>
            <span>{selectedBacktest?.bot_name}</span>
          </li>
          <li className={styles.summaryItem}>
            <span>Status:</span>
            <span
              style={
                backtesetReportData?.status === "processed"
                  ? { color: "#65B741" }
                  : {}
              }
            >
              {backtesetReportData?.status[0].toUpperCase() +
                backtesetReportData?.status.slice(1)}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>Risk Appetite:</span>
            <span
              style={{
                color: `${
                  riskAppetiteColor[backtesetReportData?.risk_apetite - 1]
                }`,
              }}
            >
              {riskOptions[backtesetReportData?.risk_apetite - 1]}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>Exchange ID:</span>
            {backtesetReportData?.exchange_id === "None" && (
              <span>{backtesetReportData?.exchange_id}</span>
            )}
            {backtesetReportData?.exchange_id !== "None" && (
              <span className={styles.exchangeName}>
                <img
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${backtesetReportData?.exchange_id}.svg`}
                  alt={backtesetReportData?.exchange_id}
                />
                {backtesetReportData?.exchange_id}
              </span>
            )}
          </li>
          <li className={styles.summaryItem}>
            <span>Duration:</span>
            <span>
              {formatCustomDate(backtesetReportData?.start_date)} -{" "}
              {formatCustomDate(backtesetReportData?.end_date)}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>Signal Mode:</span>
            <span>
              {backtesetReportData?.signal_mode?.replace("_", " ")}{" "}
              {backtesetReportData?.signal_mode === "AI_Assistant" &&
                `(${backtesetReportData?.ai_assistant_mode})`}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>Average Win Rate:</span>
            <span
              style={{ color: `${averageProfit > 0 ? "#16a085" : "#e74c3c"}` }}
            >
              {averageProfit.toFixed(2)}%
            </span>
          </li>
        </ul>
      </div>
      <a
        className={styles.moreBtn}
        style={{ backgroundColor: "#e74c3c", borderColor: "#e74c3c" }}
        href={backtesetReportData?.pdf_url}
        download={`${selectedBacktest?.bot_name}- ${formatCustomDate(
          backtesetReportData?.start_date,
        )} - ${formatCustomDate(backtesetReportData?.end_date)}.pdf`}
      >
        <FaRegFilePdf />
        Export report as pdf
      </a>
    </div>
  );
}

export default SummaryCard;

import { Modal } from "antd";
import { useEffect, useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import MinimalExchangesList from "./MinimalExchangesList";
import MinimalBotActivationProcess from "./MinimalBotActivationProcess";
import { useDispatch, useSelector } from "react-redux";
import { clearIntegrationProcessData } from "../../../../redux/exchange/actions";
import { clearCreateBotData, createBot } from "../../../../redux/bot/actions";

function MinimalCreateBotModal({ show, setShow, botData }) {
  const {
    main: { userData },
    botState: {
      createBotLoading,
      createBotResponse,
      getBotListLoading,
      checkBotStatusLoading,
      activateBotLoading,
    },
  } = useSelector((state) => state);

  const [step, setStep] = useState(1);
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [botCreated, setBotCreated] = useState(false);

  const dispatch = useDispatch();

  const title = step === 1 ? "Select Exchange" : "Activate Bot";

  const closeModal = () => {
    setSelectedExchange(null);
    setBotCreated(false);
    setStep(1);
    setShow(false);
  };

  const createBotHandler = async () => {
    if (!selectedExchange) return;
    const data = {
      bot_name: botData?.bot_name,
      selected_exchange: selectedExchange,
      trade_in_virtual_portfolio: "False",
      risk_tolerance: "3",
      target_return_percentage: "10",
      crypto_allocation_strategy: "balanced",
      signal_source: botData?.signal_mode,
      ai_assistant_mode: botData?.ai_assistant_mode,
      selected_trading_strategies: ["Time-based AI-assisted"],
      number_of_coins: botData?.number_of_coins,
    };

    // setBotCreated(true);
    await dispatch(
      createBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        data,
        false,
      ),
    ).then(() => {
      // setStep(2);
      setBotCreated(true);
    });
  };

  useEffect(() => {
    if (createBotResponse && botCreated && !getBotListLoading) {
      setStep(2);
      setBotCreated(false);
    }
  }, [botCreated, createBotResponse, getBotListLoading]);

  useEffect(() => {
    if (show) {
      dispatch(clearIntegrationProcessData());
      dispatch(clearCreateBotData());
    }
  }, [dispatch, show]);

  return (
    <Modal
      open={show}
      onCancel={closeModal}
      centered
      footer={null}
      maskClosable={false}
      closable={
        !createBotLoading &&
        !checkBotStatusLoading &&
        !getBotListLoading &&
        !activateBotLoading
      }
    >
      <h3>
        <FaCaretRight /> {title}
      </h3>

      {step === 1 && (
        <MinimalExchangesList
          selectedExchange={selectedExchange}
          setSelectedExchange={setSelectedExchange}
          setStep={setStep}
          createBot={createBotHandler}
        />
      )}
      {step === 2 && (
        <MinimalBotActivationProcess
          step={step}
          exchangeId={selectedExchange}
          botId={botData?.bot_created_date}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
}

export default MinimalCreateBotModal;

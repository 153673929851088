// dashboard data types
export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";

export const GET_FAST_DASHBOARD_DATA_REQUEST =
  "GET_FAST_DASHBOARD_DATA_REQUEST";
export const GET_FAST_DASHBOARD_DATA_SUCCESS =
  "GET_FAST_DASHBOARD_DATA_SUCCESS";
export const GET_FAST_DASHBOARD_DATA_ERROR = "GET_FAST_DASHBOARD_DATA_ERROR";

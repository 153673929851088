import styles from "../../styles/shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab.module.css";
import { MdNewspaper, MdSwapHoriz } from "react-icons/md";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Tour } from "antd";
import { FaChartLine } from "react-icons/fa";

function ExchangesAndPaperTradingTab({ activeTab, pageType }) {
  const [showTour, setShowTour] = useState(false);

  const navigate = useNavigate();
  const simulatedTradingRef = useRef(null);

  const tourSteps = [
    {
      title: <FaChartLine color={"#0C4CFC"} size={18} />,
      description: (
        <p
          style={{
            marginTop: "-16px",
            fontSize: "16px",
            marginBottom: 0,
            color: "#2B303A",
          }}
        >
          Utilize a simulated portfolio to enhance your exchange asset
          management strategies effectively.
        </p>
      ),
      target: () => simulatedTradingRef.current,
    },
  ];

  const handleIntegratedExchangeNavigation = () => {
    if (activeTab === "exchange") return;
    if (pageType === "portfolio") {
      navigate("/integration/portfolio");
    }
    if (pageType === "history") {
      navigate("/integration/trade-history");
    }
  };
  const handlePaperTradingNavigation = () => {
    if (activeTab === "paper") return;
    if (pageType === "portfolio") {
      navigate("/ai-assistant/portfolio");
    }
    if (pageType === "history") {
      navigate("/ai-assistant/trade-history");
    }
  };

  useEffect(() => {
    if (activeTab === "exchange") {
      setShowTour(true);
    }
  }, [activeTab]);

  return (
    <ul className={styles.container}>
      <li
        className={`${styles.tabItem} ${
          activeTab === "exchange" ? styles.activeTab : ""
        }`}
        onClick={handleIntegratedExchangeNavigation}
      >
        <MdSwapHoriz size={18} />
        {/*<span className={styles.showOnDesktop}>Integrated </span>*/}
        Integrated Exchanges
      </li>
      <li
        className={`${styles.tabItem} ${
          activeTab === "paper" ? styles.activeTab : ""
        }`}
        onClick={handlePaperTradingNavigation}
        ref={simulatedTradingRef}
      >
        <MdNewspaper />
        Simulated <span className={styles.showOnDesktop}>Trading</span>{" "}
        Portfolios
      </li>
      <Tour
        open={showTour}
        steps={tourSteps}
        onClose={() => setShowTour(false)}
        animated
      />
    </ul>
  );
}

export default ExchangesAndPaperTradingTab;

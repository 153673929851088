import styles from "../../../styles/Components/pricing-plan/plans/PlanCardBtn.module.css";

function PlanCardBtn({ children, clickHandler, type = "buy" }) {
  const onClick = () => {
    if (clickHandler) clickHandler();
  };

  return (
    <div
      className={`${styles.button} ${
        type === "change" ? styles.changeBtn : ""
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default PlanCardBtn;

import styles from "../../../styles/Components/mini-app/mini-ai-signals/MiniSignal.module.css";
import SignalStrengthUi from "../../trading-assistant/SignalStrengthUi";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import { formatPrice } from "../../../utility/helper";
import MiniSignalInfo from "./MiniSignalInfo";
import SectionDivider from "../layout/SectionDivider";

function MiniSignal({ data }) {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const {
    CATEGORY_INFO: { category, subcategory, name },
    SIGNAL_STRENGTH: strength,
    SYMBOL_NAME: coin,
    TRADE_TYPE: action,
    EXPECTED_RETURN: expectedReturn,
    STOP_LOSS: stopLoss,
    STOP_LOSS_PERCENT: stopLossPercent,
    TAKE_PROFIT: takeProfit,
    TAKE_PROFIT_PERCENT: takeProfitPercent,
    CURRENT_PRICE: price,
    SUGGESTED_LEVERAGE: leverage,
    SUGGESTED_AMOUNT_PERCENTAGE: amount,
  } = data;

  const [showActivationModal, setShowActivationModal] = useState(false);

  const formattedAction =
    action?.toLowerCase() === "buy"
      ? action + "/Long"
      : action?.toLowerCase() === "sell"
      ? action + "/Short"
      : action;
  const isServiceActive = profileData?.myais_active !== "False";

  const openActivationModal = () => setShowActivationModal(true);
  const copySignalHandler = () => {
    if (!isServiceActive) {
      // message.info("You should active this service first!");
      openActivationModal();
    } else {
      navigator.clipboard.writeText(JSON.stringify(data));
      // notify("Signal data successfully copied to clipboard.", "success")
      message.success("Signal data successfully copied to clipboard.");
    }
  };

  return (
    <div className={styles.container}>
      {/*<button onClick={() => setShowActivationModal(true)}>show MOdal</button>*/}
      <div className={styles.header}>
        <h3>
          {category}
          <p>
            {subcategory}, {name}
          </p>
        </h3>
        <SignalStrengthUi
          level={strength}
          width={"auto"}
          showDescription={false}
        />
      </div>

      <SectionDivider />

      <div className={styles.info}>
        <span>{coin?.replace("/USDT", "")}</span>
        <img
          src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${coin?.replace(
            "/USDT",
            "",
          )}.png`}
          alt={coin}
        />
      </div>
      <div className={styles.info}>
        <p>Action</p>

        <MiniSignalInfo
          customClassName={`${action === "Buy" ? styles.buy : styles.sell}`}
          clickHandler={openActivationModal}
        >
          {formattedAction}
        </MiniSignalInfo>
      </div>
      <div className={styles.info}>
        <p>Monthly ROI</p>
        <MiniSignalInfo clickHandler={openActivationModal} showCopyIcon={true}>
          {`${expectedReturn}%`}
        </MiniSignalInfo>
      </div>
      <div className={styles.info}>
        <p>Entry Price</p>
        <MiniSignalInfo clickHandler={openActivationModal} showCopyIcon={true}>
          {`$${formatPrice(price, 2)}`}
        </MiniSignalInfo>
      </div>
      <div className={styles.info}>
        <p>Stop Loss</p>
        <MiniSignalInfo clickHandler={openActivationModal} showCopyIcon={true}>
          {`$${formatPrice(stopLoss)} (${formatPrice(stopLossPercent, 2)}%)`}
        </MiniSignalInfo>
      </div>
      <div className={styles.info}>
        <p>Take Profit</p>
        <MiniSignalInfo clickHandler={openActivationModal} showCopyIcon={true}>
          {`$${formatPrice(takeProfit)} (${formatPrice(
            takeProfitPercent,
            2,
          )}%)`}
        </MiniSignalInfo>
      </div>

      <div className={styles.info}>
        <p>Suggested Leverage</p>

        <MiniSignalInfo clickHandler={openActivationModal}>
          {leverage}
        </MiniSignalInfo>
      </div>

      <div className={styles.info}>
        <p>Suggested Amount</p>

        <MiniSignalInfo clickHandler={openActivationModal}>
          {Math.trunc(amount * 100)}%
        </MiniSignalInfo>
      </div>

      {/*<div className={styles.copyBtn} onClick={copySignalHandler}>*/}
      {/*  Copy to Clipboard*/}
      {/*</div>*/}
      <ServiceActivationModal
        service={"aiSignal"}
        show={showActivationModal}
        setShow={setShowActivationModal}
      />
    </div>
  );
}

export default MiniSignal;

import { BsStars } from "react-icons/bs";
import MinimalCard from "../MinimalCard";
import styles from "../../../styles/Components/mini-app/mini-dashboard/BotBanner.module.css";
import { PiChartLineUpBold } from "react-icons/pi";
import { MdChevronRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

function BotBanner() {
  const navigate = useNavigate();

  const goToBotPage = () => {
    navigate("/mini-bots");
  };
  return (
    <div>
      <div className={styles.text}>
        <h3 className={styles.title}>
          <BsStars color={"gold"} size={18} />
          Fully Automated Trade
        </h3>
        <h5>
          Trade in smart and automated robots, and let them trade for you.
        </h5>
      </div>
      <MinimalCard customClassName={styles.navigationBox}>
        <Link to={"/mini-bots"}>
          <div className={styles.navigationBoxLeftSide}>
            <span className={styles.navigationBoxIcon}>
              <PiChartLineUpBold size={22} />
            </span>
            <div className={styles.navigationBoxText}>
              <h3>Smart Trade</h3>
              <p>Let our next-gen AI bot trade for you.</p>
            </div>
          </div>
          <span className={styles.navigationBoxGoIcon} onClick={goToBotPage}>
            <MdChevronRight size={20} />
          </span>
        </Link>
      </MinimalCard>
    </div>
  );
}

export default BotBanner;

import AlertSettingStepHeader from "./AlertSettingStepHeader";
import styles from "../../../styles/Components/alert-system/alert-setting/AlertSettingsStep.module.css";

const alertFrequencies = [
  {
    value: "1H",
    label: "Hourly",
  },
  {
    value: "3H",
    label: "Every 3 hours",
  },
  {
    value: "6H",
    label: "Every 6 hours",
  },
  {
    value: "12H",
    label: "Every 12 hours",
  },
  {
    value: "1D",
    label: "Daily",
  },
  {
    value: "4D",
    label: "Twice a week",
  },
  {
    value: "7D",
    label: "Weekly",
  },
  {
    value: "14D",
    label: "Biweekly",
  },
  {
    value: "28D",
    label: "Monthly",
  },
];

function AlertFrequencySetting({ alertFrequency, setAlertFrequency }) {
  const changeAlertFrequency = (e) => {
    const frequencyEl = e.target.closest("li");

    if (frequencyEl) {
      const frequencyValue = frequencyEl.id;
      setAlertFrequency("alertFrequency", frequencyValue);
    }
  };

  return (
    <div>
      <AlertSettingStepHeader title={"Alert Frequency"} />
      <p>Please customize your alert settings:</p>

      <ul
        className={styles.optionsBtn}
        onClick={changeAlertFrequency}
        style={{ margin: "2rem auto" }}
      >
        {alertFrequencies.map((item) => (
          <li
            className={`${
              alertFrequency === item.value ? styles.selectedBtn : ""
            } ${styles.blueBtn}`}
            key={item.value}
            id={item.value}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AlertFrequencySetting;

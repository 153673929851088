import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getIntegratedExchanges } from "../../../../redux/exchange/actions";
import Loader from "../../shared/Loader";
import styles from "../../../../styles/Components/mini-app/mini-bots/create-bot/MinimalExchangesList.module.css";
import { Button, Spin } from "antd";
import { BiError } from "react-icons/bi";
import NoIntegratedExchange from "../../shared/NoIntegratedExchange";

function MinimalExchangesList({
  selectedExchange,
  setSelectedExchange,
  setStep,
  createBot = "Reintegrate",
}) {
  const {
    exchangeState: { integratedData, integratedDataLoading, getIntegratedList },
    botState: { createBotLoading, createBotError, getBotListLoading },
    main: { userData },
  } = useSelector((state) => state);

  const exchanges = [...integratedData]
    ?.map((ex) =>
      ex.id === "cryptocrispy"
        ? {
            ...ex,
            name: "Test Exchange",
          }
        : ex,
    )
    ?.filter((ex) => ex.status === "Active");

  const isExchangeIntegrated = integratedData?.find(
    (ex) => ex.id === selectedExchange,
  );

  const dispatch = useDispatch();

  const selectExchangeHandler = (id) => {
    setSelectedExchange(id);
    // setStep(2);
  };

  useEffect(() => {
    if (
      Object.keys(userData).length &&
      !integratedDataLoading &&
      !getIntegratedList
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [dispatch, integratedDataLoading, userData, getIntegratedList]);

  useEffect(() => {
    if (exchanges.length) {
      // todo: recomment this
      // setSelectedExchange(exchanges[0]?.id);
    }
  }, [integratedData]);

  return (
    <div>
      {integratedDataLoading && (
        <Loader title={"Fetching integrated exchanges"} height={"130px"} />
      )}

      {/*TODO: RIGHT CONDITION IS: !integratedDataLoading && exchanges.length > 0*/}
      {!integratedDataLoading && exchanges.length > 0 && (
        <div className={styles.exchanges}>
          {exchanges.map((ex) => (
            <span
              className={`${styles.exchange} ${
                selectedExchange === ex.id ? styles.selected : ""
              }`}
              key={ex.id}
              onClick={() => selectExchangeHandler(ex.id)}
            >
              {ex.name}
            </span>
          ))}
        </div>
      )}
      {!integratedDataLoading && exchanges.length > 0 && (
        <NoIntegratedExchange
          setExchange={setSelectedExchange}
          exchange={selectedExchange}
          createBotHandler={createBot}
          title={"You can also:"}
        />
      )}

      {/*TODO: RIGHT CONDITION IS: !integratedDataLoading && exchanges.length > 0*/}
      {!integratedDataLoading &&
        exchanges.length > 0 &&
        isExchangeIntegrated && (
          <div className={styles.createBotHandler}>
            {(createBotLoading || getBotListLoading) && (
              <Spin
                tip={"Creating bot..."}
                size={"small"}
                style={{ display: "flex", alignItems: "center", gap: ".2rem" }}
              />
            )}

            {createBotError && (
              <h4 className={styles.createBotError}>
                <BiError size={16} color={"#e84118"} /> Something went wrong
              </h4>
            )}

            <Button
              className={styles.createBotBtn}
              loading={createBotLoading || getBotListLoading}
              onClick={createBot}
              disabled={!selectedExchange}
            >
              Create Bot
            </Button>
          </div>
        )}
      {/*TODO: RIGHT CONDITION IS: !integratedDataLoading && exchanges.length === 0*/}
      {!integratedDataLoading && exchanges.length === 0 && (
        <NoIntegratedExchange
          setExchange={setSelectedExchange}
          exchange={selectedExchange}
          createBotHandler={createBot}
        />
      )}
    </div>
  );
}

export default MinimalExchangesList;

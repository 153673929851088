import React from "react";
import styles from "../../styles/Components/new-insight/InsightSignalsSection.module.css";
import InsightSignalsTable from "./signals-section/InsightSignalsTable";
import InsightSignalOperations from "./signals-section/InsightSignalOperations";
import CurrentStrategyInfo from "./signals-section/CurrentStrategyInfo";
import { useSelector } from "react-redux";
import TickerWidget from "../charts/TickerWidget";

function InsightSignalsSection() {
  const {
    newInsightState: { signalData },
  } = useSelector((state) => state);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h3>
          <strong>Strategy Builder</strong>
        </h3>
        <p style={{ marginBottom: 0 }}>
          Create, manage and use ready-made strategies.
        </p>
      </div>

      <InsightSignalOperations />

      <CurrentStrategyInfo />

      {signalData.length > 0 && (
        <TickerWidget symbolsData={signalData.map((s) => s.coin)} />
      )}

      <InsightSignalsTable />
    </div>
  );
}

export default InsightSignalsSection;

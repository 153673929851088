// Import react-share components
import { useEffect, useState } from "react";
import { FiLink } from "react-icons/fi";
import { useSelector } from "react-redux";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import styles from "../../styles/Components/pricing-plan/ShareButtons.module.css";
import { notify } from "../../utility/Toastify/toastify";
import { message } from "antd";

// A custom component that shows the share buttons
function ShareButtons({ link }) {
  const {
    pricingPlan: { referralCode, referralCodeLoading },
  } = useSelector((state) => state);
  const [invitationLink, setInvitationLink] = useState("");
  const [customText, setCustomText] = useState("");

  useEffect(() => {
    if (!referralCodeLoading) {
      if (referralCode) {
        setInvitationLink(
          link ||
            `${"https://app.cryptocrispy.com.au/auth/signup/referralcode"}=${referralCode}`,
        );
        setCustomText(
          `Join me on Cryptocrispy! You can use my referral code: ${referralCode}`,
        );
      }
    }
  }, [referralCode, referralCodeLoading]);

  const clickHandler = () => {
    navigator.clipboard.writeText(invitationLink);
    message.success(`Copied link to clipboard.`);
  };

  // Return a JSX element with the share buttons and icons
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        Share your invitation link with your friends:
      </h4>
      <div onClick={clickHandler} className={styles.invitationLink}>
        <FiLink style={{ marginRight: ".5rem" }} />
        <p className={styles.invitationText}>{invitationLink}</p>
      </div>
      <div className={styles.btns}>
        <FacebookShareButton url={invitationLink} quote={customText}>
          <FacebookIcon size={32} round style={{ marginRight: "1rem" }} />
        </FacebookShareButton>
        <TwitterShareButton url={invitationLink} title={customText}>
          <TwitterIcon size={32} round style={{ marginRight: "1rem" }} />
        </TwitterShareButton>
        <TelegramShareButton url={invitationLink} title={customText}>
          <TelegramIcon size={32} round style={{ marginRight: "1rem" }} />
        </TelegramShareButton>
        <WhatsappShareButton url={invitationLink} separator={customText}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
}

export default ShareButtons;

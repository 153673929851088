import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaCaretRight } from "react-icons/fa";
import React from "react";
import CreateBotStepTitle from "./StepTitle";

function CreateBotStep6({ botData, changeBotData }) {
  return (
    <>
      <CreateBotStepTitle title={"Signal Source"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          Which signal source you want to choose?
        </h4>
        <ul
          className={styles.options}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            width: "100%",
          }}
        >
          <li
            className={`${styles.optionItem} ${
              botData.signal_source === "AI_Assistant"
                ? styles.selectedOption
                : ""
            }`}
            style={{ width: "100%", maxWidth: "525px", margin: "0 auto" }}
            onClick={() => changeBotData("signal_source", "AI_Assistant")}
          >
            AI Assistant (It covers top 20 crypto coins)
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.signal_source === "Tracked_Signals"
                ? styles.selectedOption
                : ""
            }`}
            style={{ width: "100%", maxWidth: "525px", margin: "0 auto" }}
            onClick={() => changeBotData("signal_source", "Tracked_Signals")}
          >
            Tracked Signals (It covers top 20 crypto coins)
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.signal_source === "VIP_channel"
                ? styles.selectedOption
                : ""
            }`}
            style={{ width: "100%", maxWidth: "525px", margin: "0 auto" }}
            onClick={() => changeBotData("signal_source", "VIP_channel")}
          >
            VIP signals (It covers all coins but it is recommended to the
            adventurous users)
          </li>
        </ul>
        {botData.signal_source === "AI_Assistant" && (
          <div style={{ marginTop: "1.5rem" }}>
            <CreateBotStepTitle title={"Select AI Assistant Mode"} />
            <h4 className={styles.stepTitle}>
              <FaCaretRight />
              What AI assistant mode do you wanna use?
            </h4>
            <ul
              className={styles.options}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                width: "100%",
              }}
            >
              <li
                className={`${styles.optionItem} ${
                  botData.ai_assistant_mode === "default"
                    ? styles.selectedOption
                    : ""
                }`}
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  margin: "0 auto",
                }}
                onClick={() => changeBotData("ai_assistant_mode", "default")}
              >
                All categories (It is recommended since it covers all signals)
              </li>
              <li
                className={`${styles.optionItem} ${
                  botData.ai_assistant_mode === "only_ai"
                    ? styles.selectedOption
                    : ""
                }`}
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  margin: "0 auto",
                }}
                onClick={() => changeBotData("ai_assistant_mode", "only_ai")}
              >
                only AI signals
              </li>
              <li
                className={`${styles.optionItem} ${
                  botData.ai_assistant_mode === "only_onchain"
                    ? styles.selectedOption
                    : ""
                }`}
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  margin: "0 auto",
                }}
                onClick={() =>
                  changeBotData("ai_assistant_mode", "only_onchain")
                }
              >
                Only on-chain, copy-trading signals
              </li>
              <li
                className={`${styles.optionItem} ${
                  botData.ai_assistant_mode === "only_social"
                    ? styles.selectedOption
                    : ""
                }`}
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  margin: "0 auto",
                }}
                onClick={() =>
                  changeBotData("ai_assistant_mode", "only_social")
                }
              >
                Only social signal
              </li>
              <li
                className={`${styles.optionItem} ${
                  botData.ai_assistant_mode === "only_ta"
                    ? styles.selectedOption
                    : ""
                }`}
                style={{
                  width: "100%",
                  maxWidth: "390px",
                  margin: "0 auto",
                }}
                onClick={() => changeBotData("ai_assistant_mode", "only_ta")}
              >
                Only technical indicator signals
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateBotStep6;

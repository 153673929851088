import styles from "../../../styles/Components/mini-app/auth/MiniAppLoginPage.module.css";
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import poolData from "../../../pages/Auth/AWSConfig/PoolData";
import { saveUserPass } from "../../../redux/main/action/main";
import { notify } from "../../../utility/Toastify/toastify";
import { useSearchParams } from "react-router-dom";
import ReferralModal from "../../auth/ReferralModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { miniAppSignUpValidation } from "../../../utility/Validation/validation";
import { useDispatch } from "react-redux";
import { handleBlur } from "../../../utility/action";

function MiniAppSignupPage({ setAuthType }) {
  const [loading, setLoading] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(miniAppSignUpValidation),
    mode: "onBlur",
  });

  // Get the value of the 'telegram_auth' parameter
  const telegramAuth = params.get("tgWebAppStartParam");

  const referralCode = params.get("referal_code");

  const [referralValue, setReferralValue] = useState(referralCode || "");

  const onSubmit = (data) => {
    if (!referralCode && checkCount === 0) {
      setSubmitData(data);
      setShowReferralModal(true);
      setCheckCount(1);
      return;
    }
    setLoading(true);

    poolData.signUp(
      data?.email.toLowerCase(),
      data?.pass,
      [
        { Name: "email", Value: data?.email.toLowerCase() },
        {
          Name: "custom:custom_username",
          Value: data?.email.toLowerCase().split("@")[0],
        },
        { Name: "custom:about_us", Value: "" },
        {
          Name: "custom:referal_code",
          Value: referralValue.length < 10 ? "          " : referralValue,
        },
      ],
      [],
      (err, res) => {
        if (res) {
          // emailHandler(res.user.username.toLowerCase());
          dispatch(saveUserPass([data?.email.toLowerCase(), data?.pass]));
          localStorage.setItem("userPass", [
            data?.email.toLowerCase(),
            data?.pass,
          ]);
          setAuthType("confirm");
          setLoading(false);
        }
        if (err) {
          notify(err?.message, "error");
          setLoading(false);
        }
      },
      null,
    );
    setCheckCount(0);
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)}>
      <Form.Item
        label="Email"
        validateStatus={errors.email ? "error" : ""}
        help={errors.email ? errors.email.message : ""}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="email"
              onBlur={(e) => {
                field.onBlur(e);
                handleBlur(e);
              }}
            />
          )}
        />
      </Form.Item>

      <Form.Item
        label="Password"
        validateStatus={errors.pass ? "error" : ""}
        help={errors.pass ? errors.pass.message : ""}
      >
        <Controller
          name="pass"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              onBlur={(e) => {
                field.onBlur(e);
                handleBlur(e);
              }}
              autoComplete="new-password"
            />
          )}
        />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        validateStatus={errors.confirmPass ? "error" : ""}
        help={errors.confirmPass ? errors.confirmPass.message : ""}
      >
        <Controller
          name="confirmPass"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              onBlur={(e) => {
                field.onBlur(e);
                handleBlur(e);
              }}
              autoComplete="new-password"
            />
          )}
        />
      </Form.Item>

      <Form.Item
        style={{ alignSelf: "center", marginTop: "2rem", width: "100%" }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className={styles.submitBtn}
        >
          Sign Up
        </Button>
      </Form.Item>

      <p
        className={styles.switchToLoginBtn}
        onClick={() => setAuthType("login")}
      >
        Already have an account? <span>Login</span>
      </p>

      <ReferralModal
        data={submitData}
        setReferral={setReferralValue}
        setShow={setShowReferralModal}
        show={showReferralModal}
        submit={onSubmit}
      />
    </Form>
  );
}

export default MiniAppSignupPage;

import React, { useEffect, useState } from "react";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { IoIosCloseCircle } from "react-icons/io";
import { FiChevronLeft } from "react-icons/fi";
import { setStep } from "../../../../redux/trade/actions";
import { GrCircleQuestion } from "react-icons/gr";
import AddToVirtualConfirmationDialog from "../AddToVirtualConfirmationDialog";
import { FaCheckCircle } from "react-icons/fa";
import ShowRecommendation from "../ShowRecommendation";

function ResultMode({ step, setShow }) {
  const [defaultMode, setDefaultMode] = useState(true);
  const {
    exchangeState: { orderTradeError, orderTradeLoading, orderTradeData },
    tradeState: { secondTradeType, tradeType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const goBackHandler = () => {
    if (orderTradeLoading) return;
    dispatch(setStep("2"));
  };

  useEffect(() => {
    if (!tradeType.includes("buy")) {
      setDefaultMode(false);
    }
  }, [tradeType]);

  return (
    <div className={`${step === "result" ? styles.active : ""} ${styles.step}`}>
      {orderTradeLoading ? (
        <Loader text={"Please wait"} />
      ) : orderTradeError ? (
        <div className={styles.errorBox}>
          <span
            className={styles.backBtn}
            style={{ alignSelf: "flex-start" }}
            onClick={goBackHandler}
          >
            <FiChevronLeft />
            Back
          </span>
          <IoIosCloseCircle color={"#e74c3c"} size={30} />
          <h4 className={styles.errorMessage}>
            {orderTradeError.error || "Something went wrong!"}
          </h4>
          <p>{orderTradeError.description}</p>
        </div>
      ) : (
        Object.keys(orderTradeData).length > 0 && (
          <div className={styles.successBox}>
            <FaCheckCircle color={"#1abc9c"} size={30} />
            <p style={{ textAlign: "center", color: "rgba(0,0,0,.85)" }}>
              Congratulations, your order trade has been successfully done.
            </p>

            {defaultMode && (
              <div className={styles.questionBox}>
                <h4 style={{ textAlign: "center" }}>
                  <GrCircleQuestion style={{ marginRight: ".3rem" }} />
                  Would you like to set stop loss and take profit for risk
                  management (Recommended)?
                </h4>

                <div className={styles.answerBtns}>
                  <span
                    className={`${styles.yesBtn} ${styles.answerBtn}`}
                    onClick={() => dispatch(setStep("set-limit-order"))}
                  >
                    Yes
                  </span>
                  <span
                    className={`${styles.noBtn} ${styles.answerBtn}`}
                    onClick={() => setDefaultMode(false)}
                  >
                    No
                  </span>
                </div>
              </div>
            )}

            {!defaultMode &&
              (secondTradeType === "sell" || secondTradeType === "buy") && (
                <AddToVirtualConfirmationDialog setShow={setShow} />
              )}

            {!defaultMode &&
              secondTradeType !== "sell" &&
              secondTradeType !== "buy" && (
                <ShowRecommendation setShow={setShow} showCongrateMsg={false} />
              )}
          </div>
        )
      )}
    </div>
  );
}

export default ResultMode;

//portfolio data
export const GET_PORTFOLIO_DATA_REQUEST = "GET_PORTFOLIO_DATA_REQUEST";
export const GET_PORTFOLIO_DATA_SUCCESS = "GET_PORTFOLIO_DATA_SUCCESS";
export const GET_PORTFOLIO_DATA_ERROR = "GET_PORTFOLIO_DATA_ERROR";

//trade history data
export const GET_TRADE_HISTORY_SUCCESS = "GET_TRADE_HISTORY_SUCCESS";
export const GET_TRADE_HISTORY_REQUEST = "GET_TRADE_HISTORY_REQUEST";
export const GET_TRADE_HISTORY_ERROR = "GET_TRADE_HISTORY_ERROR";

//trade assistant data
export const GET_TRADE_ASSISTANT_SUCCESS = "GET_TRADE_ASSISTANT_SUCCESS";
export const GET_TRADE_ASSISTANT_REQUEST = "GET_TRADE_ASSISTANT_REQUEST";
export const GET_TRADE_ASSISTANT_ERROR = "GET_TRADE_ASSISTANT_ERROR";

//get price
export const GET_PRICE_REQUEST = "GET_PRICE_REQUEST";
export const GET_PRICE_SUCCESS = "GET_PRICE_SUCCESS";
export const GET_PRICE_ERROR = "GET_PRICE_ERROR";

//set Trade
export const SET_TRADING_REQUEST = "SET_TRADING_REQUEST";
export const SET_TRADING_SUCCESS = "SET_TRADING_SUCCESS";
export const SET_TRADING_ERROR = "SET_TRADING_ERROR";

//get 200 coins
export const GET_200COINS_REQUEST = "GET_200COINS_REQUEST";
export const GET_200COINS_SUCCESS = "GET_200COINS_SUCCESS";
export const GET_200COINS_ERROR = "GET_200COINS_ERROR";

//reset history
export const RESET_HISTORY_REQUEST = "RESET_HISTORY_REQUEST";
export const RESET_HISTORY_SUCCESS = "RESET_HISTORY_SUCCESS";
export const RESET_HISTORY_ERROR = "RESET_HISTORY_ERROR";

//get balance chart
export const GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_ERROR = "GET_BALANCE_ERROR";

// get investment budget
export const GET_INVESTMENT_BUDGET_REQUEST = "GET_INVESTMENT_BUDGET_REQUEST";
export const GET_INVESTMENT_BUDGET_SUCCESS = "GET_INVESTMENT_BUDGET_SUCCESS";
export const GET_INVESTMENT_BUDGET_ERROR = "GET_INVESTMENT_BUDGET_ERROR";

// set limit order
export const SET_LIMIT_ORDER_REQUEST = "SET_LIMIT_ORDER_REQUEST";
export const SET_LIMIT_ORDER_SUCCESS = "SET_LIMIT_ORDER_SUCCESS";
export const SET_LIMIT_ORDER_ERROR = "SET_LIMIT_ORDER_ERROR";

export const VIRTUAL_PORTFOLIO = "VIRTUAL_PORTFOLIO";

// SET MARKET TYPE
export const SET_AI_MARKET_TYPE = "SET_AI_MARKET_TYPE";

export const UPDATE_PORTFOLIO_DATA = "UPDATE_PORTFOLIO_DATA";

export const UPDATE_TRADE_ASSISTANT_DATA = "UPDATE_TRADE_ASSISTANT_DATA";

export const SET_AI_ASSISTANT_MODE = "SET_AI_ASSISTANT_MODE";

export const GET_ALL_TRADE_SUGGESTION_REQUEST =
  "GET_ALL_TRADE_SUGGESTION_REQUEST";
export const GET_ALL_TRADE_SUGGESTION_SUCCESS =
  "GET_ALL_TRADE_SUGGESTION_SUCCESS";
export const GET_ALL_TRADE_SUGGESTION_ERROR = "GET_ALL_TRADE_SUGGESTION_ERROR";

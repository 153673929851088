import styles from "../../../styles/Components/mini-app/mini-pricing/InviteFriendsButton.module.css";
import { FaUsers } from "react-icons/fa";
import { useEffect, useState } from "react";
import InviteFriendsModal from "./InviteFriendsModal";
import { useDispatch, useSelector } from "react-redux";
import { getReferralCode } from "../../../redux/pricing-plan/action";

function InviteFriendsButton({ title, customClassName }) {
  const {
    main: { userData },
    pricingPlan: { referralCode },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [showShareModal, setShowShareModal] = useState(false);

  const invitationLink = `https://t.me/cryptocrispy_bot?start=${referralCode}`;

  useEffect(() => {
    if (!referralCode) {
      if (userData?.email)
        dispatch(
          getReferralCode(
            userData?.email,
            userData?.["custom:custom_username"],
          ),
        );
    }
  }, [dispatch, referralCode, userData]);

  return (
    <>
      <div
        className={`${styles.addMoreBtn} ${customClassName || ""}`}
        onClick={() => setShowShareModal(true)}
      >
        <span className={styles.addMoreBtnIcon}>
          <FaUsers size={20} />
        </span>
        <span className={styles.addMoreBtnText}>{title}</span>
      </div>
      {showShareModal && (
        <InviteFriendsModal
          show={showShareModal}
          setShow={setShowShareModal}
          link={invitationLink}
        />
      )}
    </>
  );
}

export default InviteFriendsButton;

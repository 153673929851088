import SectionTitle from "../../shared/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  activateBot,
  checkBotStatus,
  deactivateBot,
  deleteBacktest,
  getBacktestHistory,
  getBacktestReportPdf,
} from "../../redux/bot/actions";
import BotBacktestHistoryTable from "./bot-backtest/BotBacktestHistoryTable";
import styles from "../../styles/Components/bot/BotBackTest.module.css";
import BacktestRequestModal from "./bot-backtest/BacktestRequestModal";
import { useNavigate } from "react-router-dom";
import alert from "../alerts/Alert";
import { Button, Dropdown, Menu, message, Spin } from "antd";
import { LuRefreshCcw } from "react-icons/lu";
import { IoPlayOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa";
import { formatCustomDate } from "../../utility/helper";
import { TbReport } from "react-icons/tb";
import { MdOutlineMoreVert } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";
import DisplayBotStatus from "../../shared/bot-activation-process/DisplayBotStatus";
import ActivateBotServiceModal from "./ActivateBotServiceModal";
import { RiLockFill } from "react-icons/ri";

function BotBackTest() {
  const {
    main: { userData },
    botState: {
      backtestHistoryFetched,
      backtestHistoryData,
      backktestHistoryLoading,
      selectedBacktest,
      activateBotLoading,
      deactivateBotLoading,
      backtesetPdfLoading,
      backtestPdfLink,
      botList,
      deleteBacktestLoading,
      backtestHistoryError,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  const [showBacktestModal, setShowBacktestModal] = useState(false);
  const [showBotActivationModal, setShowBotActivationModal] = useState(false);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isBotActive = botList
    ? botList.find(
        (item) => item.CreatedDate === selectedBacktest?.bot_created_date,
      )?.bot_status !== "inactive"
    : "";

  const isServiceActive = profileData?.mybot_active === "True";

  const connectedExchange = selectedBacktest?.exchange_id;

  const handleRefresh = () => {
    if (backktestHistoryLoading) return;
    dispatch(
      getBacktestHistory(
        userData["custom:custom_username"],
        userData["cognito:username"],
      ),
    );
  };

  const handleRequestBacktest = () => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
    } else {
      setShowBacktestModal(true);
    }
  };

  const botActivation = () => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }
    if (activateBotLoading || deactivateBotLoading) return;

    if (isBotActive) {
      dispatch(
        deactivateBot(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedBacktest?.bot_created_date,
        ),
      );
    } else {
      if (connectedExchange === "None") {
        return alert(
          "",
          "You cannot activate this bot because it is not integrated with any exchange.",
          "info",
          {
            cancel: "Ok",
          },
        );
      }
      // dispatch(
      //   activateBot(
      //     userData?.["custom:custom_username"],
      //     userData?.["cognito:username"],
      //     selectedBacktest?.bot_created_date,
      //   ),
      // );
      dispatch(
        checkBotStatus(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedBacktest?.bot_created_date,
          selectedBacktest?.exchange_id,
        ),
      );
      setShowBotActivationModal(true);
    }
  };

  const handleShowResult = () => {
    if (!selectedBacktest?.status)
      return message.info("Please select a backtest first!");
    if (selectedBacktest?.status === "processed") {
      navigate(
        `/bot-automation/backtest/report/${selectedBacktest.createdDate}`,
      );
      return;
    }

    if (selectedBacktest?.status === "not_processed") {
      return alert(
        null,
        "The backtest is currently pending. Please wait and try again in 20 minutes.",
        "info",
        {
          cancel: "Ok",
        },
      );
    }

    if (selectedBacktest?.status === "failed") {
      return alert(
        null,
        "The backtest has failed. Please select another backtest.",
        "info",
        {
          cancel: "Ok",
        },
      );
    }
  };

  const handleDeleteBacktest = () => {
    if (deleteBacktestLoading) return;
    dispatch(
      deleteBacktest(
        userData["custom:custom_username"],
        userData["cognito:username"],
        selectedBacktest?.createdDate,
      ),
    );
  };

  const menu = (
    <Menu className={styles.menu}>
      {selectedBacktest?.createdDate &&
        selectedBacktest?.status === "processed" && (
          <Menu.Item key="pdf">
            {backtesetPdfLoading ? (
              <span className={styles.loading}>
                <Spin size={"small"} />
                Preparing pdf
              </span>
            ) : (
              <a
                href={backtestPdfLink}
                download={`${selectedBacktest?.bot_name}_${formatCustomDate(
                  selectedBacktest?.start_date,
                )}-${formatCustomDate(selectedBacktest?.end_date)}.pdf`}
              >
                <FaRegFilePdf />
                Download Pdf
              </a>
            )}
          </Menu.Item>
        )}
      <Menu.Item key="report">
        <span onClick={handleShowResult}>
          <TbReport />
          Show report
        </span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (
      !backtestHistoryData.length &&
      !backtestHistoryFetched &&
      !backktestHistoryLoading &&
      userData["cognito:username"] &&
      !backtestHistoryError
    ) {
      dispatch(
        getBacktestHistory(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [
    backktestHistoryLoading,
    backtestHistoryData,
    backtestHistoryFetched,
    dispatch,
    userData,
  ]);

  useEffect(() => {
    if (selectedBacktest?.status === "processed") {
      dispatch(
        getBacktestReportPdf(
          userData["custom:custom_username"],
          userData["cognito:username"],
          selectedBacktest?.createdDate,
        ),
      );
    }
  }, [selectedBacktest]);

  return (
    <div className={styles.container}>
      <SectionTitle title={"Bot Backtests"}>
        Evaluate your bots in backtests
      </SectionTitle>
      <ul className={styles.contentOptions}>
        <li>
          <Button
            className={`${styles.btn} ${styles.refreshBtn}`}
            onClick={handleRefresh}
            loading={backktestHistoryLoading}
          >
            {!backktestHistoryLoading && <LuRefreshCcw />}
            Refresh
          </Button>
        </li>
        {selectedBacktest?.status === "not_processed" && (
          <li>
            <Button
              className={`${styles.deleteBtn}`}
              loading={deleteBacktestLoading}
              onClick={handleDeleteBacktest}
            >
              <FaRegTrashCan />
              Delete
            </Button>
          </li>
        )}
        {selectedBacktest?.bot_created_date && (
          <li>
            <Button
              onClick={botActivation}
              loading={activateBotLoading || deactivateBotLoading}
              className={`${
                isBotActive ? styles.deactivateBtn : styles.activateBtn
              }`}
            >
              {!isServiceActive && <RiLockFill />}
              {isBotActive ? (
                <>
                  {!deactivateBotLoading && isServiceActive && (
                    <AiOutlineStop />
                  )}
                  Deactivate
                </>
              ) : (
                <>
                  {!activateBotLoading && isServiceActive && <IoPlayOutline />}
                  Activate Bot
                </>
              )}
            </Button>
          </li>
        )}
        <li>
          <Button
            className={`${styles.btn} ${styles.requestBtn}`}
            onClick={handleRequestBacktest}
          >
            {!isServiceActive && <RiLockFill />} Request Backtest
          </Button>
        </li>

        <li>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className={styles.moreBtn}>
              <MdOutlineMoreVert />
              More Options
            </Button>
          </Dropdown>
        </li>
      </ul>
      <BotBacktestHistoryTable />

      <BacktestRequestModal
        show={showBacktestModal}
        setShow={setShowBacktestModal}
      />

      <DisplayBotStatus
        show={showBotActivationModal}
        onClose={setShowBotActivationModal}
        botId={selectedBacktest?.bot_created_date}
        exchangeId={selectedBacktest?.exchange_id}
      />

      {showServiceActivationModal && (
        <ActivateBotServiceModal
          setShowActivationModal={setShowServiceActivationModal}
          showActivationModal={showServiceActivationModal}
        />
      )}
    </div>
  );
}

export default BotBackTest;

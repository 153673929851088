import { Button, Modal } from "antd";
import warningIcon from "../../../../asset/Icons/warn100.gif";
import styles from "../../../../styles/shared/trade-modal/SetLimitConfirmation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { orderTrade } from "../../../../redux/exchange/actions";
import { setStep, setTargetPrice } from "../../../../redux/trade/actions";

function SetLimitConfirmation({ show, setShow }) {
  const {
    exchangeState: { orderTradeLoading },
    tradeState: {
      secondTradeType,
      selectedAsset,
      selectedExchange,
      tradeAmount,
      triggerPrice,
      tradeAmountUsd,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const submitHandler = () => {
    // const amount = selectedExchange === "swyftx" ? tradeAmountUsd : tradeAmount;
    const asset =
      selectedExchange === "swyftx"
        ? selectedAsset.split("/")[0]
        : selectedAsset;

    dispatch(
      orderTrade(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        asset,
        secondTradeType,
        `${tradeAmount}`,
        triggerPrice,
        "freeze",
      ),
    );
    dispatch(setTargetPrice(+triggerPrice));
    dispatch(setStep("result"));
  };

  return (
    <Modal
      open={show}
      onCancel={() => setShow(false)}
      centered
      width={350}
      footer={null}
      children={
        <div className={styles.container}>
          <img src={warningIcon} alt={"warning"} />
          <h3 className={styles.title}>
            {/*Your funds will be briefly held for a smooth trade. Ready to*/}
            {/*proceed?*/}
            Your funds will be temporarily reserved to ensure a seamless trade.
            Are you ready to proceed?
            <p>
              {/*It's just a little hold to make sure everything goes smoothly.*/}
              Don't worry; your funds will be back in action once the order is
              complete.
            </p>
          </h3>
          <div className={styles.btns}>
            <Button className={`${styles.btn}`} onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button
              className={`${styles.btn} ${styles.submitBtn}`}
              onClick={submitHandler}
              type={"primary"}
              loading={orderTradeLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default SetLimitConfirmation;

import { Button, Checkbox, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/shared/bot-activation-process/DisplayBotStatus.module.css";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  activateBot,
  checkBotStatus,
  sellAllCryptoAssets,
  sellAllFiatAssets,
} from "../../redux/bot/actions";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import { setSelectedPortfolioExchange } from "../../redux/exchange/actions";

const loadingTexts = [
  "Evaluating Bot Activation Conditions",
  "Checking API Expirations",
  "Checking Exchange Portfolio",
  "Checking Previous Bots",
  "Checking Bot Configurations",
];

const hasIntegrationBtnCodes = ["431", "432", "433", "434", "435", "438"];
const hasActivateBtnCodes = ["432", "433", "434", "435", "436", "200"];
const errorCodes = ["431", "437", "438"];
const noteCodes = ["436", "435", "434", "433", "432"];

function DisplayBotStatus({ show, onClose, botId, exchangeId, selectedBot }) {
  const {
    botState: {
      checkBotStatusLoading,
      checkBotStatusData,
      checkBotStatusError,
      activateBotLoading,
      sellAllFiatAssetsLoading,
      sellAllFiatAssetResult,
      sellAllFiatAssetError,
      sellAllCryptoAssetsLoading,
      sellAllCryptoAssetsResult,
      sellAllCryptoAssetsError,
      createBotLoading,
    },
    main: { userData },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const intervalRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const isError = errorCodes.includes(
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE,
  );
  const isNote = noteCodes.includes(
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE,
  );

  const statusMsg =
    !isError && !isNote
      ? "Your bot is ready to be activated."
      : isNote
      ? "Your bot is ready to be activated, but you can enhance its performance by following these suggestions:"
      : "The bot cannot be activated until you resolve these issues:";

  const closeModal = () => {
    onClose(false);
    setCurrentIndex(0);
    setShowSuggestion(false);
  };
  const goToIntegration = () => {
    navigate("/integration");
  };

  const handleActivateBot = async () => {
    if (activateBotLoading) return;
    await dispatch(
      activateBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        botId,
      ),
    ).then(() => {
      closeModal();
    });
  };

  const handleSellAllFiatAsset = () => {
    if (sellAllFiatAssetsLoading) return;
    dispatch(
      sellAllFiatAssets(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchangeId,
      ),
    );
  };

  const handleSellAllCryptoAssets = () => {
    if (sellAllCryptoAssetsLoading) return;
    dispatch(
      sellAllCryptoAssets(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchangeId,
      ),
    );
  };

  const toggleShowingSuggestion = () => {
    setShowSuggestion((show) => !show);
  };

  const handleUpdateForm = (e) => {
    if (e.target.checked) {
      setCurrentIndex(0);
      dispatch(
        checkBotStatus(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          botId,
          exchangeId,
        ),
      );
    }
  };

  useEffect(() => {
    if (show && checkBotStatusLoading) {
      intervalRef.current = setInterval(() => {
        if (currentIndex < loadingTexts.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }, 3000);
    }
    return () => clearInterval(intervalRef.current);
  }, [currentIndex, show, checkBotStatusLoading]);

  useEffect(() => {
    if (!show) {
      setCurrentIndex(0);
    }
  }, [show]);

  return (
    <Modal
      closable={!checkBotStatusLoading}
      open={show}
      onCancel={closeModal}
      maskClosable={false}
      footer={null}
      centered
      bodyStyle={{ minHeight: "180px" }}
    >
      {checkBotStatusLoading ? (
        <div className={styles.loadingBox}>
          <Spin />
          <h3>{loadingTexts[currentIndex]}</h3>
        </div>
      ) : Object.keys(checkBotStatusData).length > 0 ? (
        <div className={styles.statusBox}>
          <div>
            <h3>{statusMsg}</h3>
            {(isNote || isError) && (
              <div
                className={`${styles.detailsBox} ${
                  isNote ? styles.noteBox : styles.errBox
                }`}
              >
                <h4>
                  {isNote && <HiOutlineLightBulb color={"#4686ff"} size={19} />}
                  {isError && <AiOutlineWarning color={"#fd2727"} size={18} />}
                  {checkBotStatusData?.BOT_STATUS?.STATUS_TITLE}
                </h4>

                {isNote && (
                  <span
                    className={styles.expandBtn}
                    onClick={toggleShowingSuggestion}
                  >
                    {showSuggestion ? "Show less" : "Read more"}
                    {showSuggestion ? (
                      <MdOutlineExpandLess />
                    ) : (
                      <MdOutlineExpandMore />
                    )}
                  </span>
                )}

                {(showSuggestion || isError) && (
                  <div className={styles.suggestionBox}>
                    <p>{checkBotStatusData?.BOT_STATUS?.MESSAGE_TO_USER}</p>

                    <div className={styles.actionBtns}>
                      <Button
                        // onClick={goToIntegration}
                        className={styles.integrationBtn}
                      >
                        <Link
                          to={"/integration/portfolio"}
                          target={"_blank"}
                          onClick={() =>
                            dispatch(setSelectedPortfolioExchange(exchangeId))
                          }
                        >
                          Go to Integration
                        </Link>
                      </Button>
                      {checkBotStatusData?.BOT_STATUS?.STATUS_CODE ===
                        "435" && (
                        <Button
                          className={styles.sellBtn}
                          loading={sellAllFiatAssetsLoading}
                          onClick={handleSellAllFiatAsset}
                        >
                          Sell All Fiat Currencies
                        </Button>
                      )}
                      {checkBotStatusData?.BOT_STATUS?.STATUS_CODE ===
                        "433" && (
                        <Button
                          className={styles.sellBtn}
                          loading={sellAllCryptoAssetsLoading}
                          onClick={handleSellAllCryptoAssets}
                        >
                          Sell All Crypto Assets
                        </Button>
                      )}
                    </div>

                    {isError && (
                      <div className={styles.checkFixIssue}>
                        <Checkbox onChange={handleUpdateForm} />I fix the issue.
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className={styles.btns}>
              <div className={styles.actionButtons}>
                {!isError && (
                  <Button
                    className={styles.activateBtn}
                    loading={activateBotLoading}
                    onClick={handleActivateBot}
                  >
                    Activate
                  </Button>
                )}
                <Button onClick={closeModal}>Close</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        checkBotStatusError && (
          <div className={styles.errorBox}>
            <IoCloseCircleSharp color={"red"} size={35} />
            <h3 style={{ textAlign: "center" }}>
              Something went wrong. Please try again later.
            </h3>
            <Button onClick={closeModal}>Close</Button>
          </div>
        )
      )}
    </Modal>
  );
}

export default DisplayBotStatus;

import { errorHandler } from "../../utility/helper";
import {
  GENERATE_API_KEY_ERROR,
  GENERATE_API_KEY_REQUEST,
  GENERATE_API_KEY_SUCCESS,
  GET_DEVELOPER_API_DATA_ERROR,
  GET_DEVELOPER_API_DATA_REQUEST,
  GET_DEVELOPER_API_DATA_SUCCESS,
} from "./consts";

import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";

// get api data
export const getDeveloperApiData =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    // const { token } = JSON.parse(
    //   localStorage.getItem("aws-amplify-federatedInfo"),
    // );
    // const headerConfig = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // };
    dispatch({ type: GET_DEVELOPER_API_DATA_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "developer_api",
        request_type: "get_previous_key",
        email,
        user,
      })
      .then((res) =>
        dispatch({ type: GET_DEVELOPER_API_DATA_SUCCESS, payload: res.data }),
      )
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getDeveloperApiData(email, user, retryCount + 1),
          GET_DEVELOPER_API_DATA_ERROR,
          user,
          email,
          "get_previous_key",
        );
        // dispatch({ type: GET_DEVELOPER_API_DATA_ERROR, payload: err });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

//generate new api key
export const generateApikey =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GENERATE_API_KEY_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "developer_api",
        request_type: "generate_api",
        email,
        user,
      })
      .then((res) =>
        dispatch({ type: GENERATE_API_KEY_SUCCESS, payload: res.data }),
      )
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => generateApikey(email, user, retryCount + 1),
          GENERATE_API_KEY_ERROR,
          user,
          email,
          "generate_api",
        );
        // dispatch({ type: GENERATE_API_KEY_ERROR, payload: err });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

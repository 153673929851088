import React from 'react'
import "../styles/Components/button.css"
import Ripples from 'react-ripples'
import ReactLoading from "react-loading";

function Button({ButtonText,className,loading}) {



    return (

        <div className={`container-login100-form-btn ${className}`}>

            <div className="wrap-login100-form-btn">
                <Ripples className={"test"} during={1000}>
                <div className="login100-form-bgbtn"></div>

                <button className="login100-form-btn">
                    {loading? <ReactLoading type={"bars"} color="#fff" height={40} width={36} /> : ButtonText}
                </button>
            </Ripples>
            </div>


        </div>

    )
}

export default Button

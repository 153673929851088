import styles from "../../styles/Components/dashboard/ExchangeBox.module.css";
import exchangeIcon from "../../asset/Icons/exchange (1).gif";
import { useDispatch, useSelector } from "react-redux";
import { List, Spin } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import ContentContainer from "./ContentContainer";
import DashboardCardTitle from "./DashboardCardTitle";
import {
  setExchangeForConnection,
  setPreviousPath,
  setSelectedPortfolioExchange,
} from "../../redux/exchange/actions";
import { useState } from "react";
import UpdateExchangeIpModal from "../../shared/exchange-ip-setting/UpdateExchangeIpModal";

function ExchangeListItem({
  item,
  goToPortfolio,
  styles,
  showIpSetting,
  setSelectedExchange,
  goToSetting,
}) {
  const { id, name, status, ip_status } = item;
  const formattedStatus =
    status === "Active"
      ? status
      : ip_status !== "OK"
      ? "IP Reassign Required"
      : "Expired";
  // ip_status !== "OK"
  //     ? "IP Reassign Required"
  //     : status === "Active"
  //     ? status
  //     : "Expired";

  // status === "Active" && ip_status === "OK"
  //   ? status
  //   : ip_status !== "OK"
  //   ? "IP Reassign Required"
  //   : "Expired";

  const handleItemClick = () => {
    if (status !== "Active" && ip_status !== "OK") {
      showIpSetting(true);
      setSelectedExchange(item);
      return;
    }

    if (status !== "Active") {
      goToSetting(id, name);
      return;
    }

    if (status === "Active") {
      goToPortfolio(id);
    }
  };

  return (
    <List.Item key={id} onClick={handleItemClick}>
      <List.Item.Meta
        className={`${styles.exchangeInfo}`}
        avatar={
          <img
            style={{ width: "20px", height: "20px" }}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${id}.svg`}
            alt={name}
          />
        }
        title={<strong>{name}</strong>}
        description={
          <p
            className={`${styles.status} ${
              status === "Active"
                ? styles.active
                : ip_status !== "OK"
                ? styles.ipRequired
                : styles.inactive
              // ip_status !== "OK"
              //   ? styles.ipRequired
              //   : status === "Active"
              //   ? styles.active
              //   : styles.inactive
            }`}
          >
            {/*status === "Active" && ip_status === "OK"*/}
            {/*? styles.active*/}
            {/*: ip_status !== "OK"*/}
            {/*? styles.ipRequired*/}
            {/*: styles.inactive*/}
            Status: <span>{formattedStatus}</span>
          </p>
        }
      />
    </List.Item>
  );
}

function ExchangeBox({ className }) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      trustedIps,
    },
  } = useSelector((state) => state);

  const [showIpSettingsModal, setShowIpSettingsModal] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState("");

  const exchanges = [...integratedData].sort((a, b) =>
    b.id === "cryptocrispy" ? 1 : -1,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const goToPortfolio = (id) => {
    dispatch(setSelectedPortfolioExchange(id));
    navigate("/integration/portfolio");
  };

  const goToSettings = (id, name) => {
    navigate(`/integrate-exchange/${name}`);
    dispatch(setPreviousPath(location.pathname));
    dispatch(setExchangeForConnection(id));
  };

  return (
    <div className={`${styles.container} ${className || ""}`}>
      <ContentContainer
        style={
          getIntegratedList && integratedData.length === 0
            ? { minHeight: "300px" }
            : {}
        }
      >
        <div className={styles.header}>
          <DashboardCardTitle
            text={"Integrated Exchanges"}
            icon={exchangeIcon}
          />
          {integratedDataLoading && !getIntegratedList && <Spin />}
        </div>

        {/*when user does not have any exchange*/}
        {getIntegratedList && integratedData.length === 0 && (
          <div className={styles.noExchange}>
            <p>You haven't integrated with any exchanges yet.</p>
            <Link to={"/integration"} className={styles.integrateBtn}>
              Integrate now
            </Link>
          </div>
        )}

        {getIntegratedList && integratedData.length > 0 && (
          <List
            itemLayout="horizontal"
            dataSource={exchanges}
            renderItem={(item) => (
              <ExchangeListItem
                item={item}
                goToPortfolio={goToPortfolio}
                ip={trustedIps}
                styles={styles}
                showIpSetting={setShowIpSettingsModal}
                setSelectedExchange={setSelectedExchange}
                goToSetting={goToSettings}
              />
            )}
            className={styles.exchangeList}
          />
        )}

        <Link className={styles.link} to="/integration">
          More info
        </Link>
      </ContentContainer>

      <UpdateExchangeIpModal
        show={showIpSettingsModal}
        // show={true}
        setShow={setShowIpSettingsModal}
        exchangeData={selectedExchange}
      />
    </div>
  );
}

export default ExchangeBox;

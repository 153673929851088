import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import styles from "../../../styles/Components/trade-assistant/assistant/TradeAssistantTableDropdownMenu.module.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../redux/profile/actions";
import {
  getTradeAssistant,
  setAiAssistantMode,
  setAIMarketType,
} from "../../../redux/trading-assistant/actions";
import { FaExclamationTriangle } from "react-icons/fa";
import { BiCategory, BiMoney } from "react-icons/bi";

const { SubMenu } = Menu;

const TradeAssistantTableDropdownMenu = () => {
  const {
    main: { userData },
    tradingAssistant: { selectedPortfolio, aiMarketType, aiAssistantMode },
    profile: { profileData },
  } = useSelector((state) => state);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateRiskAppetite = (e) => {
    let attribute_names = ["ai_assistant_risk_appetite"];
    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [e.key],
      ),
    );
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        aiAssistantMode,
      ),
    );
    setDropdownVisible(false);
  };

  const handleChangeCategory = (e) => {
    // console.log("category", e.key);
    dispatch(setAiAssistantMode(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        e.key,
      ),
    );
    setDropdownVisible(false);
  };

  const handleChangeMarketType = (e) => {
    // console.log(e.key);
    // Handle market type change here
    // console.log("Market Type", e.key);
    dispatch(setAIMarketType(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio?.toLowerCase() || "primary",
        e.key,
        aiAssistantMode,
      ),
    );
    // You can dispatch actions or handle state accordingly
    setDropdownVisible(false);
  };

  // only_ta
  // only_on_chain
  // only_social
  // only_ai

  const riskMenu = (
    <Menu
      onClick={handleUpdateRiskAppetite}
      selectedKeys={[profileData?.ai_assistant_risk_appetite]}
    >
      <Menu.Item key="1">Very Cautious</Menu.Item>
      <Menu.Item key="2">Cautious</Menu.Item>
      <Menu.Item key="3">Balanced</Menu.Item>
      <Menu.Item key="4">Adventurous</Menu.Item>
      <Menu.Item key="5">Very Adventurous</Menu.Item>
    </Menu>
  );

  const categoryMenu = (
    <Menu
      onClick={handleChangeCategory}
      selectedKeys={aiAssistantMode || "null"}
    >
      <Menu.Item key="null">All Categories</Menu.Item>
      <Menu.Item key="only_ai">AI</Menu.Item>
      <Menu.Item key="only_social">Social</Menu.Item>
      <Menu.Item key="only_on_chain">On chain</Menu.Item>
      <Menu.Item key="only_ta">Technical Indicators</Menu.Item>
    </Menu>
  );

  const marketTypeMenu = (
    <Menu
      onClick={handleChangeMarketType}
      selectedKeys={aiMarketType || "null"}
    >
      <Menu.Item key="crypto">Crypto</Menu.Item>
      <Menu.Item key="forex">Forex</Menu.Item>
      <Menu.Item key="asx" disabled>
        Stock
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      className={styles.menu}
      overlay={
        <Menu>
          <SubMenu
            key="setRisk"
            title="Set Risk"
            popupClassName="sub-menu"
            icon={<FaExclamationTriangle size={14} />}
            className={styles.dropdownItem}
          >
            {riskMenu}
          </SubMenu>
          <SubMenu
            key="category"
            title="Category"
            popupClassName="sub-menu"
            icon={<BiCategory size={14} />}
            className={styles.dropdownItem}
          >
            {categoryMenu}
          </SubMenu>
          <SubMenu
            key="marketType"
            title="Market Type"
            popupClassName="sub-menu"
            icon={<BiMoney size={14} />} // Use BiMoney icon for Market Type
            className={styles.dropdownItem}
          >
            {marketTypeMenu}
          </SubMenu>
        </Menu>
      }
      // trigger={["click"]}
      open={dropdownVisible}
      onOpenChange={setDropdownVisible}
    >
      <Button className={styles.moreBtn}>
        More Actions <MdKeyboardArrowDown />
      </Button>
    </Dropdown>
  );
};

export default TradeAssistantTableDropdownMenu;

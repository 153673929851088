import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { setStep } from "../../../../redux/trade/actions";
import { GrCircleQuestion } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import ShowRecommendation from "../ShowRecommendation";

function VirtualTradeResult({ setShow }) {
  const {
    tradeState: { virtualSecondTradeType, virtualTradeType },
    tradingAssistant: {
      setTradeLoading,
      limitOrderLoading,
      limitOrderError,
      setTradeError,
      tradeData,
      limitOrderMessage,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const goBackHandler = () => {
    dispatch(setStep("paper-2"));
  };

  return (
    <div>
      {setTradeLoading || limitOrderLoading ? (
        <Loader text={"Please wait"} />
      ) : limitOrderError || setTradeError ? (
        <div className={styles.errorBox}>
          <span
            className={styles.backBtn}
            style={{ alignSelf: "flex-start" }}
            onClick={goBackHandler}
          >
            <FiChevronLeft />
            Back
          </span>
          <IoIosCloseCircle color={"#e74c3c"} size={30} />
          <h4 className={styles.errorMessage}>
            {tradeData?.MESSAGE || limitOrderError}
          </h4>
        </div>
      ) : (
        (!!limitOrderMessage || !!tradeData) && (
          <div className={styles.successBox}>
            <FaCheckCircle color={"#1abc9c"} size={30} />
            <p style={{ textAlign: "center", color: "rgba(0,0,0,.85)" }}>
              Congratulations, your order trade has been successfully done.
            </p>
            {virtualSecondTradeType !== "Buy" && (
              <ShowRecommendation setShow={setShow} showCongrateMsg={false} />
            )}
            {virtualSecondTradeType === "Buy" && (
              <div className={styles.questionBox}>
                <h4 style={{ textAlign: "center" }}>
                  <GrCircleQuestion style={{ marginRight: ".3rem" }} />
                  Would you like to set stop loss and take profit for risk
                  management (Recommended)?
                </h4>
                <div className={styles.answerBtns}>
                  <span
                    className={`${styles.yesBtn} ${styles.answerBtn}`}
                    onClick={() => dispatch(setStep("set-virtual-limit-order"))}
                  >
                    Yes
                  </span>
                  <span
                    className={`${styles.noBtn} ${styles.answerBtn}`}
                    onClick={() => {
                      // setShow()
                      dispatch(setStep("show-recommendation"));
                    }}
                  >
                    No
                  </span>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default VirtualTradeResult;

import React from "react";
import styles from "../../styles/Components/integration/ContentMenu.module.css";
import settingsIcon from "../../asset/Icons/settings.png";
import historyIcon from "../../asset/Icons/tradeHistory.png";
import portfolioIcon from "../../asset/Icons/portfolio.png";
import { Radio } from "antd";

const options = [
  {
    label: "Connections",
    value: "default",
    icon: settingsIcon,
    desc: "Linking Cryptos,\n" + "Trading Simplified",
  },
  {
    label: "Portfolio",
    value: "portfolio",
    icon: portfolioIcon,
    desc: "Keep an Eye on Your Finances ",
  },
  {
    label: "Trade History",
    value: "trade-history",
    icon: historyIcon,
    desc: "Check trade history",
  },
];

function ContentMenu({ changeSection, section }) {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Table of Content</h4>
      <Radio.Group
        value={section}
        buttonStyle="solid"
        style={{
          display: "flex",
          gap: "36px",
          flexDirection: "column",
        }}
        onChange={(e) => changeSection(e.target.value)}
      >
        {options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            className={styles.marketBtn}
          >
            <div className={styles.optionInfo}>
              <span className={styles.marketItem}>
                <img
                  className={styles.marketIcon}
                  src={option.icon}
                  alt={option.label}
                />
                {option.label}
              </span>
              <p>{option.desc}</p>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
}

export default ContentMenu;

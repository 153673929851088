import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import { message, Radio, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  getVirtualPortfolio,
  setStep,
  setVirtualSecondTradeType,
  setVirtualSelectedAsset,
  setVirtualSelectedPortfolio,
  setVirtualTradeType,
  setVirtualTriggerPrice,
} from "../../../../redux/trade/actions";
import cryptoIcon from "../../../../asset/Icons/cryptoIcon.png";
import forexIcon from "../../../../asset/Icons/forexIcon.png";
import stockIcon from "../../../../asset/Icons/stockIcon.png";
import RechargeBudget from "../../RechargeBudget";
import { isCoinInVirtualPortfolio } from "../../../../utility/tradeHelper";
import { IoIosWarning } from "react-icons/io";
import StatusLoader from "../../StatusLoader";
import VirtualPortfolioOptions from "../virtual-trade/VirtualPortfolioOptions";

const marketTypeData = [
  {
    value: "crypto",
    label: "Crypto",
    icon: cryptoIcon,
  },
  {
    value: "forex",
    label: "Forex",
    icon: forexIcon,
  },
  {
    value: "asx",
    label: "Stock",
    icon: stockIcon,
  },
];

function VirtualTradeFirstStep({ setShow }) {
  const {
    main: { userData },
    tradeState: {
      modalMode,
      virtualSelectedAsset,
      virtualMarketType,
      virtualSelectedPortfolio,
      virtualTradeType,
      virtualPortfolioLoading,
      virtualPortfolioData,
      basicTradeData,
    },
  } = useSelector((state) => state);

  const [buyTradeError, setBuyTradeError] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const dispatch = useDispatch();

  const marketTypeOnChange = (e) => {
    // setMarketType(e.target.value);
    dispatch(setVirtualTradeType(e.target.value));
  };

  const handleTradeType = (type) => {
    if (virtualPortfolioLoading) {
      message.info("Please wait, data is loading...");
      return;
    }
    setBuyTradeError(false);
    if (modalMode === "ai-btn") {
      dispatch(setVirtualSelectedAsset(""));
    }
    dispatch(setVirtualSecondTradeType(""));
    dispatch(setVirtualTriggerPrice("", ""));
    dispatch(setVirtualTradeType(type));
  };

  const goNextHandler = () => {
    if (!isDone) {
      return message.warning("Please enter the necessary fields.");
    }
    dispatch(setStep("paper-2"));
  };

  useEffect(() => {
    if (virtualSelectedPortfolio) {
      dispatch(
        getVirtualPortfolio(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedPortfolio.toLowerCase(),
        ),
      );
    }
  }, [virtualSelectedPortfolio]);

  useEffect(() => {
    if (
      modalMode === "ai-suggestion" &&
      !virtualPortfolioLoading &&
      Object.keys(virtualPortfolioData).length > 0 &&
      !virtualTradeType
    ) {
      const { tradeType } = basicTradeData;
      dispatch(setVirtualTradeType(tradeType.toLowerCase()));
    }
  }, [
    virtualPortfolioLoading,
    virtualPortfolioData,
    modalMode,
    basicTradeData,
    dispatch,
  ]);

  useEffect(() => {
    if (
      modalMode === "ai-table" &&
      !virtualPortfolioLoading &&
      Object.keys(virtualPortfolioData).length > 0 &&
      !virtualTradeType &&
      basicTradeData.tradeType
    ) {
      const { tradeType } = basicTradeData;
      dispatch(setVirtualTradeType(tradeType.toLowerCase()));
    }
  }, [
    virtualPortfolioLoading,
    virtualPortfolioData,
    modalMode,
    basicTradeData,
    dispatch,
    virtualTradeType,
  ]);

  useEffect(() => {
    if (
      virtualTradeType === "buy" &&
      !virtualPortfolioLoading &&
      Object.keys(virtualPortfolioData).length > 0
    ) {
      const budget = Number(virtualPortfolioData.REMANINING_INVESTMENT_BUDGET);
      if (budget < 1) {
        setBuyTradeError(true);
      } else {
        setBuyTradeError(false);
      }
    }
  }, [virtualTradeType, virtualPortfolioLoading, virtualPortfolioData]);

  useEffect(() => {
    if (
      virtualSelectedPortfolio &&
      virtualTradeType &&
      !virtualPortfolioLoading
    ) {
      if (virtualTradeType === "buy") {
        if (!buyTradeError) {
          setIsDone(true);
        } else {
          setIsDone(false);
        }
      } else {
        if (
          !isCoinInVirtualPortfolio(virtualPortfolioData, virtualSelectedAsset)
        ) {
          setIsDone(false);
        } else {
          setIsDone(true);
        }
      }
    }
  }, [
    buyTradeError,
    virtualPortfolioData,
    virtualPortfolioLoading,
    virtualSelectedAsset,
    virtualSelectedPortfolio,
    virtualTradeType,
  ]);

  useEffect(() => {
    if (virtualPortfolioLoading) {
      setIsDone(false);
    }
  }, [virtualPortfolioLoading]);

  return (
    <div>
      <div>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Choose market type
        </h3>
        <Radio.Group
          className={styles.marketTypeOptions}
          onChange={marketTypeOnChange}
          value={virtualMarketType || "crypto"}
        >
          {marketTypeData.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              className={styles.marketType}
              disabled={item.value !== "crypto"}
            >
              {" "}
              <img
                src={item.icon}
                className={styles.marketTypeIcon}
                alt={item.value}
              />{" "}
              {item.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>

      <div className={styles.portfolioBox}>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Choose your portfolio:
        </h3>

        <VirtualPortfolioOptions />
      </div>

      <div
        className={`${styles.tradeTypeBox} ${
          !virtualSelectedPortfolio ? styles.inactive : ""
        }`}
      >
        <h3 className={styles.title}>
          <AiFillCaretRight />
          What type of trade do you want to do?
        </h3>
        <ul className={styles.tradeTypes}>
          <li
            className={`${styles.tradeType} ${
              virtualTradeType === "buy" ? styles.selected : ""
            }`}
            style={
              !virtualSelectedPortfolio ? { cursor: "default" } : undefined
            }
            onClick={() => handleTradeType("buy")}
          >
            Buy
          </li>
          <li
            className={`${styles.tradeType} ${
              virtualTradeType === "sell" ? styles.selected : ""
            }`}
            style={
              !virtualSelectedPortfolio ? { cursor: "default" } : undefined
            }
            onClick={() => handleTradeType("sell")}
          >
            Sell
          </li>
        </ul>
      </div>

      {virtualTradeType === "buy" &&
        buyTradeError &&
        !virtualPortfolioLoading && (
          <div className={styles.budgetError}>
            <RechargeBudget
              portfolio={virtualSelectedPortfolio.toLowerCase()}
              neededBudget={1}
            />
          </div>
        )}

      {virtualTradeType === "sell" &&
        !virtualPortfolioLoading &&
        !isCoinInVirtualPortfolio(
          virtualPortfolioData,
          virtualSelectedAsset,
        ) && (
          <div className={styles.noUsdtBox}>
            <p className={styles.noUsdtMsg}>
              <IoIosWarning />
              Sorry, you don't have any {virtualSelectedAsset} in your{" "}
              {virtualSelectedPortfolio === "Primary" ? "first" : "second"}{" "}
              portfolio!
            </p>
            <span className={styles.closeBtn} onClick={() => setShow()}>
              Close
            </span>
          </div>
        )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {virtualPortfolioLoading && (
          <StatusLoader text={"Fetching portfolio data"} />
        )}
        <span
          className={`${styles.nextBtn} ${!isDone ? styles.inactive : ""}`}
          style={{ alignSelf: "flex-end", marginLeft: "auto" }}
          onClick={goNextHandler}
        >
          Next
        </span>
      </div>
    </div>
  );
}

export default VirtualTradeFirstStep;

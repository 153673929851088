import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStopLossAmount,
  setStopLossAmountPercent,
  setStopLossUsdAmount,
  setTakeProfitAmount,
  setTakeProfitAmountPercent,
  setTakeProfitUsdAmount,
} from "../../../../redux/trade/actions";
import styles from "../../../../styles/shared/trade-modal/SetLimitAmount.module.css";
import { InputNumber, Slider } from "antd";

function SetVirtualLimitAmount({ type, isSelect }) {
  const {
    tradeState: {
      virtualSelectedAsset,
      virtualTradeAmount,
      virtualSecondTradeType,
      virtualAssetAmount,
      takeProfitAmount,
      takeProfitAmountUsd,
      stopLossAmount,
      stopLossAmountUsd,
      stopLossAmountPercent,
      takeProfitAmountPercent,
      modalMode: mode,
      basicTradeData,
      currentStep: step,
    },
    tradingAssistant: { price },
  } = useSelector((state) => state);
  const [amountError, setAmountError] = useState(false);
  const sliderMax = 100;

  const dispatch = useDispatch();
  const changeNativeAmountHandler = (value) => {
    if (value > Number(virtualAssetAmount)) return;
    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent((value / virtualAssetAmount) * 100));
      dispatch(setStopLossAmount(value));
      dispatch(setStopLossUsdAmount(value * price?.PRICE));
    } else {
      dispatch(setTakeProfitAmountPercent((value / virtualAssetAmount) * 100));
      dispatch(setTakeProfitAmount(value));
      dispatch(setTakeProfitUsdAmount(value * virtualAssetAmount));
    }
  };

  const changeUsdAmountHandler = (value) => {
    if (value > Number(virtualAssetAmount * price?.PRICE)) {
      return;
    }

    const percentage = ((value * 100) / virtualAssetAmount) * price?.PRICE;

    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent(percentage));
      dispatch(setStopLossAmount(value / price?.PRICE));
      dispatch(setStopLossUsdAmount(value));
    } else {
      dispatch(setTakeProfitAmountPercent(percentage));
      dispatch(setTakeProfitAmount(value / price?.PRICE));
      dispatch(setTakeProfitUsdAmount(value));
    }
  };

  const handleSliderChange = (value) => {
    // setSliderValue(value);
    let percentage = value / 100;

    const nativeAmount = virtualAssetAmount * percentage;

    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent(value));
      dispatch(setStopLossAmount(nativeAmount));
      dispatch(setStopLossUsdAmount(nativeAmount * price?.PRICE));
    } else {
      dispatch(setTakeProfitAmountPercent(value));
      dispatch(setTakeProfitAmount(nativeAmount));
      dispatch(setTakeProfitUsdAmount(nativeAmount * price?.PRICE));
    }
  };

  useEffect(() => {
    if (virtualTradeAmount > 0) {
      if (type === "stopLoss" && stopLossAmount > Number(virtualAssetAmount)) {
        setAmountError(true);
      }
      if (
        type === "takeProfit" &&
        takeProfitAmount > Number(virtualAssetAmount)
      ) {
        setAmountError(true);
      }
    }
  }, [
    stopLossAmount,
    takeProfitAmount,
    type,
    virtualAssetAmount,
    virtualTradeAmount,
  ]);

  // Set a default value for the native and usd amount
  useEffect(() => {
    if (isSelect) {
      if (type === "stopLoss") {
        if (mode === "ai-suggestion" && step !== "set-virtual-limit-order") {
          dispatch(
            setStopLossAmountPercent(Number(basicTradeData.amountPercent)),
          );
        } else {
          dispatch(setStopLossAmountPercent(sliderMax));
        }
        dispatch(setStopLossAmount(virtualAssetAmount));
        dispatch(setStopLossUsdAmount(virtualAssetAmount * price?.PRICE));
      } else {
        if (mode === "ai-suggestion" && step !== "set-virtual-limit-order") {
          dispatch(
            setTakeProfitAmountPercent(Number(basicTradeData.amountPercent)),
          );
        } else {
          dispatch(setTakeProfitAmountPercent(sliderMax));
        }
        dispatch(setTakeProfitAmount(virtualAssetAmount));
        dispatch(setTakeProfitUsdAmount(virtualAssetAmount * price?.PRICE));
      }
    }
  }, [isSelect, step]);

  // update values based on the updated price
  useEffect(() => {
    if (price && isSelect) {
      // const percentage = sliderValue / 100;
      const percentage =
        type === "stopLoss"
          ? stopLossAmountPercent / 100
          : takeProfitAmountPercent / 100;
      const nativeAmount = virtualAssetAmount * percentage;
      if (type === "stopLoss") {
        dispatch(setStopLossAmount(nativeAmount));
        dispatch(setStopLossUsdAmount(nativeAmount * price?.PRICE));
      } else {
        dispatch(setTakeProfitAmount(nativeAmount));
        dispatch(setTakeProfitUsdAmount(nativeAmount * price?.PRICE));
      }
    }
  }, [
    dispatch,
    isSelect,
    price,
    stopLossAmountPercent,
    takeProfitAmountPercent,
    type,
    virtualAssetAmount,
  ]);

  return (
    <div
      className={`${styles.container} ${
        !virtualSecondTradeType ? styles.inactive : ""
      }`}
    >
      <div className={styles.inputBox}>
        <label>Native Amount:</label>
        <InputNumber
          className={styles.input}
          min={price ? 1 / price?.PRICE : 0.001}
          step={0.0001}
          max={Number(virtualAssetAmount)}
          value={type === "stopLoss" ? stopLossAmount : takeProfitAmount}
          status={amountError ? "error" : ""}
          onChange={changeNativeAmountHandler}
        />
      </div>
      {step !== "set-virtual-limit-order" && (
        <div className={styles.inputBox}>
          <label>USD Amount:</label>
          <InputNumber
            className={styles.input}
            value={
              type === "stopLoss" ? stopLossAmountUsd : takeProfitAmountUsd
            }
            min={1}
            max={virtualAssetAmount * price?.PRICE}
            step={0.01}
            status={amountError ? "error" : ""}
            onChange={changeUsdAmountHandler}
            formatter={(value) => `$${value}`}
            parser={(value) => value.replace("$", "")}
          />
        </div>
      )}
      <div className={styles.sliderBox}>
        <Slider
          min={price ? 100.001 / (price?.PRICE * virtualAssetAmount) : 0.001}
          max={sliderMax}
          step={0.01}
          value={
            type === "stopLoss"
              ? stopLossAmountPercent
              : takeProfitAmountPercent
          }
          onChange={handleSliderChange}
          className={styles.slider}
        />

        <InputNumber
          min={price ? 100.001 / (price?.PRICE * virtualAssetAmount) : 0.001}
          max={sliderMax}
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={
            type === "stopLoss"
              ? stopLossAmountPercent
              : takeProfitAmountPercent
          }
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {mode === "ai-suggestion" &&
        type === "stopLoss" &&
        step !== "set-virtual-limit-order" &&
        stopLossAmountPercent === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "stopLoss" &&
        step !== "set-virtual-limit-order" &&
        basicTradeData?.amountPercent &&
        stopLossAmountPercent !== Number(basicTradeData?.amountPercent) && (
          <p
            onClick={() =>
              dispatch(
                setStopLossAmountPercent(Number(basicTradeData?.amountPercent)),
              )
            }
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "takeProfit" &&
        step !== "set-virtual-limit-order" &&
        takeProfitAmountPercent === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "takeProfit" &&
        step !== "set-virtual-limit-order" &&
        basicTradeData?.amountPercent &&
        takeProfitAmountPercent !== Number(basicTradeData?.amountPercent) && (
          <p
            onClick={() =>
              dispatch(
                setTakeProfitAmountPercent(
                  Number(basicTradeData?.amountPercent),
                ),
              )
            }
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {amountError && (
        <p className={styles.errorMessage}>
          The amount you enter should be lower than the amount of the $
          {virtualSelectedAsset} in your portfolio. {Number(virtualAssetAmount)}
        </p>
      )}
    </div>
  );
}

export default SetVirtualLimitAmount;

// insight values types

//selected features
export const UPDATE_SELECTED_FEATURES="UPDATE_SELECTED_FEATURES"

//initial data
export const GET_INSIGHT_INITIAL_INFO_REQUEST="GET_INSIGHT_INITIAL_INFO_REQUEST"
export const GET_INSIGHT_INITIAL_INFO_SUCCESS="GET_INSIGHT_INITIAL_INFO_SUCCESS"
export const GET_INSIGHT_INITIAL_INFO_ERROR="GET_INSIGHT_INITIAL_INFO_ERROR"

//chart data
export const GET_INSIGHT_CHART_REQUEST="GET_INSIGHT_CHART_REQUEST"
export const GET_INSIGHT_CHART_SUCCESS="GET_INSIGHT_CHART_SUCCESS"
export const GET_INSIGHT_CHART_ERROR="GET_INSIGHT_CHART_ERROR"

import {BiError} from "react-icons/bi";
import styles from "../../../styles/Components/mini-app/shared/ErrorBox.module.css";

function ErrorBox({customClassName, style}) {
    return (
        <div className={`${styles.container} ${customClassName || ""}`} style={style || {}}>
            <BiError size={30} color={"#e84118"}/>
            <h3>Something went wrong!</h3>
            <p>Please try again later.</p>
        </div>
    );
}

export default ErrorBox;
import CreateBotStepsLine from "./CreateBotStepsLine";
import React, { useState } from "react";
import CreateBotSteps from "./CreateBotSteps";
import styles from "../../../styles/Components/bot/bot-configuration/CreateBotWizard.module.css";
import BotPool from "../BotPool";
import { Button } from "antd";
import { FaChevronLeft } from "react-icons/fa";

function CreateBotWizard({ setDisplayMode }) {
  const [step, setStep] = useState(0);

  const handleGoHome = () => setDisplayMode("default");

  return (
    <>
      {step === 0 && (
        <div className={styles.content}>
          <div className={styles.btns}>
            <span className={styles.backBtn} onClick={handleGoHome}>
              <FaChevronLeft />
            </span>
            <Button className={styles.createBtn} onClick={() => setStep(1)}>
              Create Bot Manually
            </Button>
          </div>
          <BotPool goHome={handleGoHome} />
        </div>
      )}
      {step >= 1 && (
        <div className={styles.container}>
          <CreateBotStepsLine step={step} setStep={setStep} />

          <CreateBotSteps step={step} setStep={setStep} goHome={handleGoHome} />
        </div>
      )}
    </>
  );
}

export default CreateBotWizard;

import React, { useEffect, useState } from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addToVirtualPortfolio,
  getVirtualAssetList,
  resetTradeData,
  resetVirtualTradeState,
  setAssetAmountUsd,
  setStep,
  setVirtualSelectedPortfolio,
  setVirtualTradeAmount,
} from "../../../redux/trade/actions";
import {
  getPrice,
  setLimitOrder,
  setTrade,
} from "../../../redux/trading-assistant/actions";
import { formatAmount, formatPrice } from "../../../utility/helper";
import { Button, message, Modal, Skeleton, Spin } from "antd";
import RechargeBudget from "../RechargeBudget";
import StatusLoader from "../StatusLoader";
import { unlinkFromExchange } from "../../../redux/exchange/actions";
import { BsSkipEndFill } from "react-icons/bs";

const tradeTypeConvertor = {
  sell: "Sell",
  buy: "Buy",
  sell_sl: "Stop loss sell",
  sell_tp: "Take profit sell",
  buy_db: "Discount buy",
  buy_bb: "Break out buy",
};

const Loader = ({ content }) => {
  const {
    tradingAssistant: { getPriceLoading },
  } = useSelector((state) => state);

  return getPriceLoading ? (
    <div className={styles.skeleton}>
      <Skeleton.Input active={true} width={"100%"} />
    </div>
  ) : (
    <p>{content}</p>
  );
};

function AddToVirtualPortfolio({ setShow }) {
  const [isDone, setIsDone] = useState(false);
  const [remainBudget, setRemainBudget] = useState("");
  const [sellError, setSellError] = useState("");

  const {
    tradeState: {
      virtualSelectedPortfolio,
      selectedAsset,
      secondTradeType,
      tradeAmount,
      tradeAmountUsd,
      virtualTradeAmount,
      virtualAssetsLoading,
      virtualAssetsData,
      takeProfitOrderData,
      stopLossOrderData,
      isTakeProfitOrdered,
      isStopLossOrdered,
      takeProfitOrderTriggerPrice,
      stopLossOrderTriggerPrice,
      stopLossAmountPercent,
      takeProfitAmountPercent,
      stopLossTriggerPricePercent,
      takeProfitTriggerPricePercent,
      selectedExchange,
    },
    exchangeState: { portfolioData, portfolioLoading },
    tradingAssistant: { price, setTradeLoading },
    main: { userData },
    profile: { profileData },
  } = useSelector((state) => state);

  const [isFirstSubmit, setIsFirstSubmit] = useState(false);

  const dispatch = useDispatch();

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const isBotDoingTradesOnPortfolio =
    profileData?.is_bot_forwarding_trades === "True";
  const isBotDoingTradesOnLinkedExchange =
    profileData?.bot_active_exchange ===
    profileData?.portfolio1_linked_exchange;

  const showWarning = () => {
    Modal.confirm({
      title: "Warning",
      centered: true,
      content:
        "We recommend not conducting manual trades in this portfolio since the bot is also pushing trades.",
      cancelText: "Ok",
      okButtonProps: { style: { display: "none" } },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const selectPortfolioHandler = (e) => {
    if (!price?.PRICE) return message.info("Please wait to load price.");
    if (virtualAssetsLoading) return;
    setSellError("");
    const type = e.target.id;

    if (
      (isFirstPortfolioConnected &&
        selectedExchange === profileData?.portfolio1_linked_exchange &&
        type === "secondary") ||
      (isSecondPortfolioConnected &&
        selectedExchange === profileData?.portfolio2_linked_exchange &&
        type === "primary")
    ) {
      return message.info(
        `Please choose the portfolio that is connected to the selected exchange (${selectedExchange}).`,
      );
    }

    if (
      type === "primary" &&
      isFirstPortfolioConnected &&
      selectedExchange !== profileData?.portfolio1_linked_exchange
    ) {
      return message.info(
        `This portfolio linked to ${profileData?.portfolio1_linked_exchange}. Select another portfolio.`,
      );
    }
    if (
      type === "secondary" &&
      isSecondPortfolioConnected &&
      selectedExchange !== profileData?.portfolio2_linked_exchange
    ) {
      return message.info(
        `This portfolio linked to ${profileData?.portfolio2_linked_exchange}. Select another portfolio.`,
      );
    }

    dispatch(setVirtualSelectedPortfolio(type));
  };

  const setTradeHandler = async () => {
    if (secondTradeType === "buy") {
      if (tradeAmount * price?.PRICE > remainBudget) {
        return;
      }

      if (
        virtualSelectedPortfolio === "primary" &&
        isBotDoingTradesOnPortfolio &&
        isBotDoingTradesOnLinkedExchange &&
        !isFirstSubmit
      ) {
        setIsFirstSubmit(true);
        showWarning();
        return;
      }

      const takeProfitCondition =
        isTakeProfitOrdered && Object.keys(takeProfitOrderData).length > 0;
      const stopLossCondition =
        isStopLossOrdered && Object.keys(stopLossOrderData).length > 0;

      if (takeProfitCondition || stopLossCondition) {
        const tradeTypes = [];
        const triggerPrices = [];
        const tradesAmount = [];

        if (takeProfitCondition) {
          tradeTypes.push("take_profit");
          triggerPrices.push(`${takeProfitTriggerPricePercent}`);
          tradesAmount.push(`${takeProfitAmountPercent}`);
        }

        if (stopLossCondition) {
          tradeTypes.push("stop_loss");
          triggerPrices.push(`${stopLossTriggerPricePercent}`);
          tradesAmount.push(`${stopLossAmountPercent}`);
        }

        dispatch(
          setLimitOrder(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            virtualSelectedPortfolio,
            "crypto",
            selectedAsset.split("/")[0],
            tradeTypes,
            tradesAmount,
            triggerPrices,
          ),
        );
      }
      await dispatch(
        setTrade(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          selectedAsset.split("/")[0],
          "crypto",
          `${tradeAmountUsd}`,
          "Buy",
          virtualSelectedPortfolio,
          "usd",
          "show",
        ),
      ).then(() => {
        // setShow();
        dispatch(addToVirtualPortfolio());
        dispatch(setStep("show-recommendation"));
      });
    }

    if (secondTradeType === "sell") {
      if (
        price.CURRENT_NATIVE_AMOUNT[
          virtualSelectedPortfolio[0].toUpperCase() +
            virtualSelectedPortfolio.slice(1)
        ] < +tradeAmount
      ) {
      } else {
        if (
          virtualSelectedPortfolio === "primary" &&
          isBotDoingTradesOnPortfolio &&
          isBotDoingTradesOnLinkedExchange &&
          !isFirstSubmit
        ) {
          setIsFirstSubmit(true);
          showWarning();
          return;
        }
        await dispatch(
          setTrade(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            selectedAsset.split("/")[0],
            "crypto",
            `${tradeAmount}`,
            "Sell",
            virtualSelectedPortfolio,
            "native",
          ),
        ).then(() => {
          dispatch(addToVirtualPortfolio());
          dispatch(setStep("show-recommendation"));
          // setShow();
        });
      }
    }
  };

  // GETTING PORTFOLIO DATA BASED ON THE USER CHOICE
  useEffect(() => {
    if (virtualSelectedPortfolio) {
      setSellError("");
      setIsDone(false);
      dispatch(
        getVirtualAssetList(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedPortfolio,
        ),
      );
    }
  }, [virtualSelectedPortfolio, dispatch, userData]);

  useEffect(() => {
    if (selectedAsset) {
      dispatch(
        getPrice(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          selectedAsset.split("/")[0],
          "crypto",
        ),
      );
    }
  }, [dispatch, selectedAsset, userData]);

  useEffect(() => {
    if (price?.PRICE && virtualSelectedPortfolio) {
      const formattedPortfolio =
        virtualSelectedPortfolio[0].toUpperCase() +
        virtualSelectedPortfolio.slice(1);
      setRemainBudget(price?.CURRENT_INVESTMENT_BUDGET?.[formattedPortfolio]);
    }
  }, [virtualSelectedPortfolio, price]);

  useEffect(() => {
    if (price?.PRICE) {
      if (secondTradeType === "buy") {
        dispatch(setVirtualTradeAmount(tradeAmountUsd));
      }

      if (secondTradeType === "sell") {
        dispatch(setVirtualTradeAmount(tradeAmount));
      }
    }
  }, [secondTradeType, price, tradeAmountUsd, tradeAmount]);

  useEffect(() => {
    if (virtualSelectedPortfolio) {
      if (
        secondTradeType === "buy" &&
        remainBudget &&
        !virtualAssetsLoading &&
        tradeAmount * price?.PRICE < remainBudget
      ) {
        setIsDone(true);
      }
    }
  }, [
    price?.PRICE,
    remainBudget,
    secondTradeType,
    virtualAssetsLoading,
    virtualSelectedPortfolio,
    tradeAmount,
  ]);

  useEffect(() => {
    if (!virtualSelectedPortfolio || !price) return;
    const formattedPortfolio =
      virtualSelectedPortfolio[0].toUpperCase() +
      virtualSelectedPortfolio.slice(1);
    if (secondTradeType === "sell") {
      if (!virtualAssetsLoading && virtualAssetsData.length > 0) {
        setSellError("");
        if (!virtualAssetsData[1].includes(selectedAsset.split("/")[0])) {
          setSellError(
            `You don't have any ${selectedAsset.split("/")[0]} in your ${
              virtualSelectedPortfolio === "primary" ? "first" : "second"
            } portfolio.`,
          );
        } else {
          setSellError("");
          if (price.CURRENT_NATIVE_AMOUNT[formattedPortfolio] < tradeAmount) {
            setSellError(
              `The traded amount (${tradeAmount}) is higher than the available amount in your portfolio (${price.CURRENT_NATIVE_AMOUNT[formattedPortfolio]}).`,
            );
          } else {
            setSellError("");
            setIsDone(true);
          }
        }
      }
    }
  }, [
    secondTradeType,
    virtualSelectedPortfolio,
    selectedAsset,
    tradeAmount,
    virtualAssetsData,
    price,
    virtualAssetsLoading,
  ]);

  useEffect(() => {
    if (price?.PRICE) {
      if (
        isFirstPortfolioConnected &&
        selectedExchange === profileData?.portfolio1_linked_exchange
      ) {
        dispatch(setVirtualSelectedPortfolio("primary"));
      }

      if (
        isSecondPortfolioConnected &&
        selectedExchange === profileData?.portfolio2_linked_exchange
      ) {
        dispatch(setVirtualSelectedPortfolio("secondary"));
      }
    }
  }, [price]);

  return (
    <div className={styles.step}>
      <div className={styles.assetInfo}>
        <div>
          <h4>Coin:</h4>
          <p>{selectedAsset.replace("/USDT", "")}</p>
        </div>
        <div>
          <h4>Trade Type:</h4>
          <p>{tradeTypeConvertor[secondTradeType]}</p>
        </div>
        <div>
          <h4>Current Price:</h4>
          <Loader content={`$${formatPrice(+price?.PRICE)}`} />
        </div>
        <div>
          <h4>Date:</h4>
          <Loader content={price?.TIME} />
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <h4> Trade {selectedAsset.replace("/USDT", "")} Amount:</h4>
          <p>
            {formatPrice(+tradeAmount)} ($ {formatPrice(+tradeAmountUsd)})
          </p>
        </div>
      </div>

      <div className={`${styles.question} ${styles.firstQuestion}`}>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Which virtual portfolio do you want to add this trade to?
        </h3>
        <ul className={styles.options}>
          <li
            className={`${styles.option} ${
              virtualSelectedPortfolio === "primary" ? styles.selected : ""
            } ${
              virtualAssetsLoading && virtualSelectedPortfolio !== "primary"
                ? styles.disable
                : ""
            } ${
              isFirstPortfolioConnected &&
              selectedExchange !== profileData?.portfolio1_linked_exchange
                ? styles.disable
                : ""
            } ${isFirstPortfolioConnected ? styles.linked : ""}`}
            id={"primary"}
            onClick={selectPortfolioHandler}
          >
            {virtualAssetsLoading && virtualSelectedPortfolio === "primary" && (
              <Spin size={"small"} />
            )}
            First
          </li>
          <li
            className={`${styles.option} ${
              virtualSelectedPortfolio === "secondary" ? styles.selected : ""
            } ${
              virtualAssetsLoading && virtualSelectedPortfolio !== "secondary"
                ? styles.disable
                : ""
            }
            ${
              isSecondPortfolioConnected &&
              selectedExchange !== profileData?.portfolio2_linked_exchange
                ? styles.disable
                : ""
            } ${isSecondPortfolioConnected ? styles.linked : ""}`}
            id={"secondary"}
            onClick={(e) => {
              selectPortfolioHandler(e);
            }}
          >
            {virtualAssetsLoading &&
              virtualSelectedPortfolio === "secondary" && (
                <Spin size={"small"} />
              )}
            Second
          </li>
        </ul>
      </div>

      <div>
        {price?.PRICE &&
          secondTradeType === "buy" &&
          remainBudget &&
          !virtualAssetsLoading &&
          tradeAmount * price?.PRICE > remainBudget && (
            <RechargeBudget
              portfolio={virtualSelectedPortfolio}
              neededBudget={tradeAmount * price?.PRICE}
            />
          )}
      </div>

      {secondTradeType === "sell" && !!sellError && (
        <div>
          <p className={styles.errorMessage}>{sellError}</p>
        </div>
      )}

      <div className={styles.btnContainer} style={{ flexWrap: "wrap" }}>
        {virtualAssetsLoading && (
          <StatusLoader
            style={{
              alignSelf: "flex-start",
              marginRight: "auto",
            }}
            text={`Checking your ${
              virtualSelectedPortfolio === "primary" ? "first" : "second"
            } virtual portfolio`}
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              color: "#2b5fec ",
              gap: ".3rem",
              padding: "0 .3rem",
              borderBottom: "1px solid #2b5fec",
              cursor: "pointer",
              marginRight: ".5rem",
            }}
            onClick={() => {
              dispatch(setStep("show-recommendation"));
            }}
          >
            Skip <BsSkipEndFill />
          </span>

          <Button
            type="primary"
            htmlType="submit"
            className={`${styles.submitBtn} ${!isDone ? styles.inactive : ""}`}
            onClick={setTradeHandler}
            loading={setTradeLoading}
          >
            Place Order
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddToVirtualPortfolio;

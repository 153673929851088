import { useStrategy } from "./useCreateStrategyContext";
import { useSelector } from "react-redux";
import { Checkbox, Empty, Tooltip } from "antd";
import styles from "../../../styles/Components/new-insight/create-strategy/SearchStrategyResult.module.css";
import React, { useState } from "react";
import { FiInfo } from "react-icons/fi";

const filterStrategies = (allStrategies, selectedStrategies) => {
  return allStrategies.filter(
    (generalItem) =>
      !selectedStrategies.some(
        (selectedItem) =>
          selectedItem.category === generalItem.category &&
          selectedItem.subcategory === generalItem.subcategory &&
          selectedItem.feature === generalItem.feature,
      ),
  );
};

function SearchStrategyResult() {
  const {
    newInsightState: { allFeatures },
  } = useSelector((state) => state);
  const {
    state: { strategyList, searchQuery, selectedFeatures },
    setFeatures,
    removeFeature,
  } = useStrategy();

  const [selectedStrategy] = useState(strategyList);

  const notSelectedFeatures = filterStrategies(allFeatures, selectedStrategy);
  const searchResult = notSelectedFeatures
    .filter((f) =>
      f.featureString.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    .slice(0, 5);
  const hasResult = searchResult.length > 0;

  const changeHandler = (e) => {
    const feature = e.target.value;

    if (e.target.checked) {
      setFeatures(feature);
    } else {
      removeFeature(feature);
    }
  };

  return (
    <div className={styles.list}>
      {!hasResult && (
        <div className={styles.emptyBox}>
          <Empty />
          <p>
            No matched feature that you have not selected.
            {/*<br />*/}
            {/*Consider refining your search or deselecting some features.*/}
          </p>
        </div>
      )}
      {hasResult &&
        searchResult.map((s) => (
          <Checkbox
            value={s}
            onChange={changeHandler}
            key={s.featureString}
            checked={selectedFeatures?.includes(s.feature)}
          >
            {s.category} / {s.subcategory} / {s.feature}{" "}
            <Tooltip
              className={styles.description}
              title={s.description}
              color={"geekblue"}
            >
              <FiInfo />
            </Tooltip>
          </Checkbox>
        ))}
    </div>
  );
}

export default SearchStrategyResult;

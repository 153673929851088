import * as React from "react";
import { Spin, Tabs } from "antd";
import styles from "../../styles/Components/dashboard/PortfolioSummary.module.css";
import { Link } from "react-router-dom";
import summary from "../../asset/Icons/summary.gif";
import { useState } from "react";
import RenderPortfolioContent from "./portfolio-summary/RenderPortfolioContent";
import ContentContainer from "./ContentContainer";
import newsIcon from "../../asset/Icons/news(1).gif";
import DashboardCardTitle from "./DashboardCardTitle";

// Helper function to get the color of the statistic based on the value

const { TabPane } = Tabs;

export default function PortfolioSummary({ loading, profileData, data }) {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className={styles.container}>
      <ContentContainer>
        <div className={styles.header}>
          <DashboardCardTitle text={"Portfolio Summary"} icon={summary} />
          {/*<h3>*/}
          {/*  /!* <FaChartPie style={{ marginRight: ".5rem" }} /> *!/*/}
          {/*  <img*/}
          {/*    src={summary}*/}
          {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
          {/*    alt="summary"*/}
          {/*  />*/}
          {/*  Portfolio Summary*/}
          {/*</h3>*/}
          {loading && !data && <Spin />}
        </div>
        {!!data && (
          <>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <TabPane tab="Portfolio 1" key="1">
                <RenderPortfolioContent data={data} portfolioKey={"1"} />
              </TabPane>
              <TabPane tab="Portfolio 2" key="2">
                <RenderPortfolioContent data={data} portfolioKey={"2"} />
                {/*{profileData?.pricing_plan !== "Free-tier" ? (*/}
                {/*  <RenderPortfolioContent data={data} portfolioKey={"2"} />*/}
                {/*) : (*/}
                {/*  <p style={{ textAlign: "center" }}>*/}
                {/*    Upgrade to Pro plan to access the second portfolio.*/}
                {/*    <Link className={styles.pricingBtn} to="/pricing-billing">*/}
                {/*      Upgrade*/}
                {/*    </Link>*/}
                {/*  </p>*/}
                {/*)}*/}
              </TabPane>
            </Tabs>
            <Link className={styles.link} to="/ai-assistant/portfolio">
              More info
            </Link>
          </>
        )}
      </ContentContainer>
    </div>
  );
}

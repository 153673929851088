import { IoMdEye } from "react-icons/io";
import React from "react";
import styles from "../../../styles/Components/mini-app/shared/HideContentIcon.module.css";

function HideContentIcon({ clickHandler, style }) {
  return (
    <span
      className={styles.container}
      style={style || {}}
      onClick={clickHandler}
    >
      <IoMdEye size={22} />
    </span>
  );
}

export default HideContentIcon;

import { Button, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { createBot } from "../../../../redux/bot/actions";
import styles from "../../../../styles/Components/bot/bot-configuration/IntegratedExchangesModal.module.css";
import { Link, useNavigate } from "react-router-dom";

function IntegratedExchangesModal({ show, onClose, data, goHome }) {
  const {
    exchangeState: { integratedData },
    main: { userData },
    botState: { createBotLoading },
  } = useSelector((state) => state);

  const [selectedExchange, setSelectedExchange] = useState(
    integratedData?.[0]?.id || "",
  );

  const dispatch = useDispatch();

  const activeExchanges = [...integratedData]?.filter(
    (item) => item.status === "Active",
  );

  const closeModal = () => {
    setSelectedExchange("");
    onClose(false);
  };

  const copyCustomBot = async () => {
    if (createBotLoading) return;
    if (!selectedExchange)
      return message.info("Please select an exchange first!");

    await dispatch(
      createBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        {
          ...data,
          selected_exchange: selectedExchange,
        },
      ),
    ).then(() => {
      closeModal();
      goHome();
    });
  };

  const copyBot = async () => {
    if (createBotLoading) return;
    setSelectedExchange("");
    await dispatch(
      createBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        data,
      ),
    ).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      open={show}
      onCancel={closeModal}
      footer={null}
      centered
      closable={!createBotLoading}
    >
      <h3 className={styles.title}>
        Select the exchange you want to trade from your integrated exchanges:
      </h3>

      {activeExchanges.length > 0 && (
        <ul className={styles.exchangeList}>
          {activeExchanges.map((exchange) => (
            <li
              key={exchange.id}
              className={`${
                selectedExchange === exchange.id ? styles.selected : ""
              }`}
              onClick={() => setSelectedExchange(exchange.id)}
            >
              <img
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange.id}.svg`}
                alt={exchange.id}
              />
              {exchange.name}
            </li>
          ))}
        </ul>
      )}

      {activeExchanges.length === 0 && (
        <p className={styles.noExchange}>
          You didn't integrate with any exchange.{"  "}
          <Link className={styles.emptyLink} to={"/integration"}>
            Integrate now!
          </Link>
        </p>
      )}
      <div className={styles.btns}>
        <Button
          loading={createBotLoading && !!selectedExchange}
          type={"primary"}
          onClick={copyCustomBot}
          className={styles.saveBtn}
        >
          Submit
        </Button>

        {/*<Button*/}
        {/*  loading={createBotLoading && !selectedExchange}*/}
        {/*  onClick={copyBot}*/}
        {/*>*/}
        {/*  Use default exchange*/}
        {/*  /!*Use {data?.selected_exchange}*!/*/}
        {/*</Button>*/}
      </div>
    </Modal>
  );
}

export default IntegratedExchangesModal;

import { riskAppetiteColor, riskOptions } from "../../../utility/data";
import { formatCustomDate } from "../../../utility/helper";
import styles from "../../../styles/Components/dashboard/performance/BotPerformanceDetails.module.css";
import {
  FaChartLine,
  FaFire,
  FaPercentage,
  FaRobot,
  FaUserTie,
} from "react-icons/fa";
import { GrStatusInfo } from "react-icons/gr";
import { MdCurrencyExchange, MdInfo, MdTimer } from "react-icons/md";
import { GoClockFill } from "react-icons/go";
import { IoTrophySharp } from "react-icons/io5";
import { capitalizeString } from "../../../utility/formatter-helper";

function BotPerformanceDetails({ data }) {
  const exchange =
    data?.exchange_id === "cryptocrispy" ? "Test Exchange" : data?.exchange_id;
  return (
    <ul className={styles.list}>
      <li>Summary</li>

      <li>
        <span className={styles.title}>
          <FaUserTie color={"#007bff"} />
          Trader:
        </span>
        <span>{data.trader}</span>
      </li>

      <li>
        <span className={styles.title}>
          <MdInfo color={"gray"} />
          Status:
        </span>
        <span
          style={{
            color: `${data.status === "Active" ? "#52c41a" : "#f5222d"}`,
          }}
        >
          {data.status}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <FaFire color={"#dc3545"} />
          Risk Appetite:
        </span>
        <span
          style={{
            color: `${riskAppetiteColor[data?.risk_apetite - 1]}`,
          }}
        >
          {riskOptions[data.risk_apetite - 1]}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <MdCurrencyExchange color={"purple"} />
          Exchange:
        </span>
        <span>
          {data?.exchange_id && (
            <img
              style={{ width: "20px", height: "20px", borderRadius: "100%" }}
              src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${data?.exchange_id}.svg`}
              alt={data?.exchange_id}
            />
          )}
          {capitalizeString(exchange) || "None"}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <FaChartLine color={"#28a745"} />
          Successful Trades:
        </span>
        <span>{data.num_successful_trades}</span>
      </li>

      <li>
        <span className={styles.title}>
          <GoClockFill color={"teal"} />
          Duration:
        </span>
        <span>
          {formatCustomDate(data?.start_date)} -{" "}
          {formatCustomDate(data?.end_date)}
        </span>
      </li>

      <li>
        <span className={styles.title}>
          <IoTrophySharp color={"gold"} />
          Win Rate:
        </span>
        <span>{data.win_rate}%</span>
      </li>

      <li>
        <span className={styles.title}>
          <FaPercentage color={"#fd7e14"} />
          Profit Percent:
        </span>
        <span>{data.profit_percent}%</span>
      </li>

      <li>
        <span className={styles.title}>
          <FaRobot color={"#17a2b8"} />
          AI Assistant Mode:
        </span>
        <span>
          {capitalizeString(data?.ai_assistant_mode)?.replace("_", "") || "-"}
        </span>
      </li>
    </ul>
  );
}

export default BotPerformanceDetails;

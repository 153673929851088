import styles from "../styles/Components/bot/BotSectionTitle.module.css";

function SectionTitle({ title, children }) {
  return (
    <div className={styles.container}>
      <h3>
        <strong>{title}</strong>
      </h3>
      {children && <p>{children}</p>}
    </div>
  );
}

export default SectionTitle;

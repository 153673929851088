import DashboardStatusBox from "./DashboardStatusBox";
import { useSelector } from "react-redux";
import { MdSubscriptions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "../../../styles/Components/dashboard/statusbar/SubscriptionInfo.module.css";
import { FaCrown } from "react-icons/fa";

const formatString = (str) => {
  if (str)
    return str
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(", "); // Join them with a comma and space
};

function SubscriptionInfo() {
  const {
    profile: { loading, profileData },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  const basicStatus =
    profileData?.subscription_status !== "no_plan" &&
    profileData?.subscription_status !== "None"
      ? formatString(profileData?.subscription_status)
      : "";

  const proStatus =
    profileData?.subscription_status_pro !== "None" &&
    profileData?.subscription_status_pro !== "no_plan"
      ? formatString(profileData?.subscription_status_pro)
      : "";

  return (
    <DashboardStatusBox
      title={"Subscriptions"}
      icon={<MdSubscriptions />}
      loading={loading}
      path={"/pricing-billing/subscription"}
    >
      <div className={styles.infoBox}>
        <span className={styles.infoBoxTitle}>
          <FaCrown color={"#CD7F32"} /> Basic:
        </span>
        <span
          className={`${basicStatus?.includes("Active") ? styles.active : ""}`}
        >
          {basicStatus || (
            <span
              className={styles.addBtn}
              onClick={() => navigate("/pricing-billing/subscription")}
            >
              Subscribe
            </span>
          )}
        </span>
      </div>

      <div className={styles.infoBox}>
        <span className={styles.infoBoxTitle}>
          <FaCrown color={"#C0C0C0"} /> Pro:
        </span>
        <span
          className={`${proStatus?.includes("Active") ? styles.active : ""}`}
        >
          {proStatus || (
            <span
              className={styles.addBtn}
              onClick={() => navigate("/pricing-billing/subscription")}
            >
              Subscribe
            </span>
          )}
        </span>
      </div>
    </DashboardStatusBox>
  );
}

export default SubscriptionInfo;

import {
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST,
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS,
  DISABLE_TRACK_SIGNALS_SUCCESS,
  GET_ALERT_STATUS_ERROR,
  GET_ALERT_STATUS_REQUEST,
  GET_ALERT_STATUS_SUCCESS,
  GET_PREVIOUS_EMAILS_ERROR,
  GET_PREVIOUS_EMAILS_REQUEST,
  GET_PREVIOUS_EMAILS_SUCCESS,
  GET_TELEGRAM_BOT_TOKEN_ERROR,
  GET_TELEGRAM_BOT_TOKEN_REQUEST,
  GET_TELEGRAM_BOT_TOKEN_SUCCESS,
  GET_TRACK_SIGNALS_SUCCESS,
  GET_TRACKED_SIGNALS_ERROR,
  GET_TRACKED_SIGNALS_REQUEST,
  GET_TRACKED_SIGNALS_SUCCESS,
  SET_TRACK_SIGNALS_SUCCESS,
  TRACK_SIGNALS_ERROR,
  TRACK_SIGNALS_REQUEST,
} from "./consts";

const initialState = {
  TrackSignalLoading: false,
  trackSignal: {},
  trackSignalError: "",
  loading: false,
  trackedSignals: [],
  alertStatus: {},
  alertStatusLoading: false,
  alertStatusError: "",
  previousEmails: [],
  getPreviousEmailsLoading: false,
  getPreviousEmailsError: "",
  error: "",
  activeAlertLoading: false,
  activeAlertData: {},
  activeAlertError: "",
  telegramBotToken: "",
  telegramBotTokenStatus: "",
};
export const alertSystemReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_TELEGRAM_BOT_TOKEN_REQUEST:
      return {
        ...state,
        telegramBotTokenStatus: "loading",
      };
    case GET_TELEGRAM_BOT_TOKEN_SUCCESS:
      return {
        ...state,
        telegramBotTokenStatus: "success",
        telegramBotToken: action?.payload,
      };
    case GET_TELEGRAM_BOT_TOKEN_ERROR:
      return {
        ...state,
        telegramBotTokenStatus: "error",
      };
    case TRACK_SIGNALS_REQUEST:
      return {
        ...state,
        TrackSignalLoading: true,
      };
    case SET_TRACK_SIGNALS_SUCCESS:
      return {
        ...state,
        TrackSignalLoading: false,
        trackSignal: action.payload,
      };
    case DISABLE_TRACK_SIGNALS_SUCCESS:
      return {
        ...state,
        TrackSignalLoading: false,
        trackSignal: action.payload,
      };
    case TRACK_SIGNALS_ERROR:
      return {
        ...state,
        TrackSignalLoading: false,
        trackSignalError: action.payload,
      };
    case GET_TRACKED_SIGNALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRACKED_SIGNALS_SUCCESS:
      return {
        ...state,
        loading: false,
        trackedSignals: action.payload,
      };
    case GET_TRACKED_SIGNALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST:
      return {
        ...state,
        activeAlertLoading: true,
      };
    case ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS:
      return {
        ...state,
        activeAlertLoading: false,
        activeAlertData: action.payload,
      };
    case ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR:
      return {
        ...state,
        activeAlertLoading: false,
        activeAlertError: action.payload,
      };
    case GET_ALERT_STATUS_REQUEST:
      return {
        ...state,
        alertStatusLoading: true,
        alertStatusError: "",
      };
    case GET_ALERT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        alertStatusLoading: false,
        alertStatus: action.payload,
      };
    case GET_ALERT_STATUS_ERROR:
      return {
        ...state,
        alertStatusLoading: false,
        alertStatusError: action.payload,
      };
    case GET_PREVIOUS_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        previousEmails: action.payload,
      };
    case GET_PREVIOUS_EMAILS_REQUEST:
      return {
        ...state,
        getPreviousEmailsLoading: true,
        getPreviousEmailsError: "",
      };

    case GET_PREVIOUS_EMAILS_ERROR:
      return {
        ...state,
        getPreviousEmailsLoading: false,
        getPreviousEmailsError: action.payload,
      };

    default:
      return state;
  }
};

import React from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { GrCircleQuestion } from "react-icons/gr";
import { resetTradeData, setStep } from "../../../redux/trade/actions";
import { useDispatch } from "react-redux";

function AddToVirtualConfirmationDialog({ setShow }) {
  const dispatch = useDispatch();

  return (
    <div className={styles.questionBox}>
      <h4 style={{ textAlign: "center" }}>
        <GrCircleQuestion style={{ marginRight: ".3rem" }} />
        Do you want to add this trade into your virtual portfolio?
      </h4>
      <div className={styles.answerBtns}>
        <span
          className={`${styles.yesBtn} ${styles.answerBtn}`}
          onClick={() => dispatch(setStep("add-to-virtual"))}
        >
          Yes
        </span>
        <span
          className={`${styles.noBtn} ${styles.answerBtn}`}
          onClick={() => {
            // setShow();
            dispatch(setStep("show-recommendation"));
          }}
        >
          No
        </span>
      </div>
    </div>
  );
}

export default AddToVirtualConfirmationDialog;

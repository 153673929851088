import styles from "../../../styles/Components/dashboard/statusbar/DashboardStatusBox.module.css";
import { Link } from "react-router-dom";
import { Spin } from "antd";

function DashboardStatusBox({ children, icon, title, path, loading, subIcon }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Link to={path} className={styles.icon}>
            {icon}
          </Link>
          <span className={styles.title}>{title}:</span>
        </div>
        {subIcon && <div>{subIcon}</div>}
      </div>
      <div className={styles.info}>
        {loading ? <Spin size={"small"} /> : children}
      </div>
    </div>
  );
}

export default DashboardStatusBox;

import React, { useEffect, useState } from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import { message, Tabs, Tooltip } from "antd";
import {
  setSecondTradeType,
  setVirtualSecondTradeType,
  setVirtualTradeType,
} from "../../../redux/trade/actions";
import { FiInfo } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const sellOptions = [
  {
    title: "Market sell",
    key: "Sell",
    info: "sell at the current market price.",
  },
  {
    title: "Stop loss",
    key: "stop_loss",
    info: "sell at a lower price than the current market price.",
  },
  {
    title: "Take profit",
    key: "take_profit",
    info: "sell at a higher price than the current market price.",
  },
];

const buyOptions = [
  {
    title: "Market buy",
    key: "Buy",
    info: "buy at the current market price.",
  },
  {
    title: "Discount buy",
    key: "discount",
    info: "buy at a lower price than the current market price.",
  },
  {
    title: "Break out buy",
    key: "breakout",
    info: "buy at a higher price than the current market price.",
  },
];

function TradeTypeOptions({ type }) {
  const {
    main: { userData },
    tradeState: {
      modalMode: mode,
      virtualSelectedAsset,
      virtualMarketType,
      virtualSelectedPortfolio,
      virtualRemainingBudget,
      virtualTradeAmount,
      virtualTradeAmountUsd,
      virtualTradeType,
      virtualSecondTradeType,
      basicTradeData,
    },
    profile: { profileData },
    tradingAssistant: { getPriceLoading, price, setTradeLoading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("instant");

  const changeTabHandler = (key) => {
    setActiveTab(key);
    if (getPriceLoading) {
      message.info("Please wait to fetching price data.");
      return;
    }

    if (type === "virtual") {
      if (key === "instant") {
        if (virtualTradeType.toLowerCase() === "sell") {
          dispatch(setVirtualSecondTradeType("Sell"));
        } else {
          dispatch(setVirtualSecondTradeType("Buy"));
        }
      }

      if (key === "limit") {
        if (virtualTradeType.toLowerCase() === "sell") {
          dispatch(setVirtualSecondTradeType("stop_loss"));
        } else {
          dispatch(setVirtualSecondTradeType("discount"));
        }
      }
      // dispatch(setVirtualSecondTradeType(""));
    }
  };
  const setSecondTradeTypeHandler = (value) => {
    if (getPriceLoading) {
      message.info("Please wait to load the asset price.");
      return;
    }
    if (!price?.PRICE) return;

    if (type === "virtual") {
      dispatch(setVirtualSecondTradeType(value));
    }
  };
  // TODO: remove this code, because of the bug
  // useEffect(() => {
  //   if (
  //     price?.PRICE &&
  //     type === "virtual" &&
  //     price?.SYMBOL === virtualSelectedAsset
  //   ) {
  //     dispatch(
  //       setVirtualSecondTradeType(
  //         virtualTradeType[0].toUpperCase() + virtualTradeType.slice(1),
  //       ),
  //     );
  //   }
  // }, [
  //   dispatch,
  //   price?.PRICE,
  //   price?.SYMBOL,
  //   type,
  //   virtualSelectedAsset,
  //   virtualTradeType,
  // ]);

  useEffect(() => {
    if (
      price?.PRICE &&
      price?.SYMBOL === virtualSelectedAsset &&
      !getPriceLoading &&
      type === "virtual"
    ) {
      if (activeTab === "instant") {
        if (virtualTradeType.toLowerCase() === "sell") {
          dispatch(setVirtualSecondTradeType("Sell"));
        } else {
          dispatch(setVirtualSecondTradeType("Buy"));
        }
      }

      if (activeTab === "limit") {
        if (virtualTradeType.toLowerCase() === "sell") {
          dispatch(setVirtualSecondTradeType("stop_loss"));
        } else {
          dispatch(setVirtualSecondTradeType("discount"));
        }
      }
    }
  }, [
    getPriceLoading,
    type,
    virtualTradeType,
    activeTab,
    dispatch,
    price?.PRICE,
    price?.SYMBOL,
    virtualSelectedAsset,
  ]);

  return (
    <div className={styles.secondTradeTypeBox}>
      <h3 className={styles.title}>
        <AiFillCaretRight />
        Which type of {virtualTradeType} trade do you want to do today?
      </h3>
      <Tabs
        activeTab={activeTab}
        onChange={changeTabHandler}
        style={{ padding: "1rem" }}
      >
        {[
          { key: "instant", tab: "Instant Order" },
          { key: "limit", tab: "Limit Order" },
        ].map((item) => (
          <Tabs.TabPane key={item.key} tab={item.tab}>
            {item.key === "instant" && virtualTradeType === "sell" && (
              <ul className={styles.secondTradeOptions}>
                {sellOptions[0] && (
                  <li
                    className={`${styles.secondTradeOption} ${
                      virtualSecondTradeType === sellOptions[0].key
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() =>
                      setSecondTradeTypeHandler(sellOptions[0].key)
                    }
                  >
                    {sellOptions[0].title}
                    <Tooltip
                      title={sellOptions[0].info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                )}
              </ul>
            )}
            {item.key === "instant" && virtualTradeType === "buy" && (
              <ul className={styles.secondTradeOptions}>
                {buyOptions[0] && (
                  <li
                    className={`${styles.secondTradeOption} ${
                      virtualSecondTradeType === buyOptions[0].key
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(buyOptions[0].key)}
                  >
                    {buyOptions[0].title}
                    <Tooltip
                      title={buyOptions[0].info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                )}
              </ul>
            )}
            {item.key === "limit" && virtualTradeType === "sell" && (
              <ul className={styles.secondTradeOptions}>
                {sellOptions.slice(1).map((opt) => (
                  <li
                    key={opt.key}
                    className={`${styles.secondTradeOption} ${
                      virtualSecondTradeType === opt.key ? styles.selected : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(opt.key)}
                  >
                    {opt.title}
                    <Tooltip
                      title={opt.info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                ))}
              </ul>
            )}
            {item.key === "limit" && virtualTradeType === "buy" && (
              <ul className={styles.secondTradeOptions}>
                {buyOptions.slice(1).map((opt) => (
                  <li
                    key={opt.key}
                    className={`${styles.secondTradeOption} ${
                      virtualSecondTradeType === opt.key ? styles.selected : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(opt.key)}
                  >
                    {opt.title}
                    <Tooltip
                      title={opt.info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                ))}
              </ul>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default TradeTypeOptions;

import { Input } from "antd";
import styles from "../../../styles/Components/new-insight/create-strategy/SearchStrategy.module.css";
import { useStrategy } from "./useCreateStrategyContext";
import { IoCloseCircle } from "react-icons/io5";

function SearchStrategy() {
  const {
    searchStrategy,
    state: { searchQuery },
  } = useStrategy();

  const handleSearch = (e) => {
    searchStrategy(e.target.value.trimStart());
  };

  const clearQuery = () => {
    searchStrategy("");
  };

  return (
    <div className={styles.container}>
      <Input
        placeholder="Search strategy"
        value={searchQuery}
        onChange={handleSearch}
        style={{ width: "100%" }} // Adjust width as needed
      />
      <IoCloseCircle
        className={styles.resetIcon}
        size={18}
        onClick={clearQuery}
      />
    </div>
  );
}

export default SearchStrategy;

import { useSelector } from "react-redux";
import { Spin } from "antd";
import BotPoolTable from "./BotPoolTable";

function RenderTopBotReport({ duration, goHome }) {
  const {
    botState: { botStatisticData, botStatisticLoading },
  } = useSelector((state) => state);

  return (
    <div>
      {botStatisticLoading ? (
        <div
          style={{
            display: "flex",
            minHeight: "300px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip={"Getting reports..."} />
        </div>
      ) : (
        ""
      )}
      {!botStatisticLoading && Object.keys(botStatisticData).length > 0 && (
        <BotPoolTable data={botStatisticData[duration]} goHome={goHome} />
      )}
    </div>
  );
}

export default RenderTopBotReport;

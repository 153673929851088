import React from "react";
import swal from "sweetalert";
import "../../styles/Components/alert/alert.css";

const alert = (title, text, icon, buttons, actionCallback) => {
  return swal({
    title,
    text,
    icon,
    className: "swalAlert",
    buttons,
    allowOutsideClick: false,
    closeOnClickOutside: false,
  }).then((value) => {
    if (actionCallback) {
      const callback = actionCallback[value];
      callback();
    }
    switch (value) {
      case "buyPlan":
        window.location.href = "/pricing-billing/plans-list";
        break;
      case "login":
        // localStorage.clear();
        window.location.href = "/auth/login";
        break;
    }
  });
};

export default alert;

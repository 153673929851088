const handleBlur = (e) => {

    if (e.target.value.trim() !== "") {

        e.target.classList.add("has-val")
    }
    else {
        e.target.classList.remove("has-val")
    }

}
let showPass = 0
const handleShowPass = (e) => {
    if (!showPass) {
        e.currentTarget.parentElement.nextSibling.setAttribute("type", "text")
        e.target.classList.add("fa", "fa-eye-slash")
        e.target.classList.remove("far", "fa-eye")
        showPass = 1

    }
    else {
        e.currentTarget.parentElement.nextSibling.setAttribute("type", "password")
        e.target.classList.add("far", "fa-eye")
        e.target.classList.remove("fa", "fa-eye-slash")

        showPass = 0
    }

}


export { handleBlur, handleShowPass }

import React, { useState } from "react";
import ReactPlayer from "react-player";
import styles from "../../styles/shared/video-player/VideoPlayer.module.css";
import { CiPlay1 } from "react-icons/ci";
import { Spin } from "antd";

const VideoPlayer = ({ videoLink }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleVideoClick = () => {
    setShowVideo(true);
    setLoading(true);
  };

  return (
    <div className={styles.container}>
      {!showVideo && (
        <div onClick={handleVideoClick} className={styles.poster}>
          <CiPlay1 className={styles.playIcon} size={35} />
          Click to Play Video
        </div>
      )}
      {loading && (
        <div className={styles.loader}>
          <Spin tip={"Preparing video"} />
        </div>
      )}
      {showVideo && (
        <ReactPlayer
          className={styles.player}
          url={videoLink}
          controls
          height="240px"
          playing={true}
          onReady={() => setLoading(false)}
        />
      )}
    </div>
  );
};

export default VideoPlayer;

import "../../styles/Components/faq/faqPublic.css";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import FaqItem from "./general/FaqItem";

const questions = [
  {
    question: "Do you offer automated bot trading capabilities?",
    answer:
      "Absolutely! We provide powerful tools to quickly create your bots based on your risk preferences and other parameters. Additionally, you can backtest your bots before deploying them on the exchange. Our bots leverage super-accurate trading signals generated by next-gen AI for profitable trading.",
  },
  {
    question:
      "How do I determine the optimal parameters to make my bot profitable?",
    answer:
      "We've extensively tested various parameters, evaluating bot performance. You can leverage different parameter categories to tailor your bot for profitability.",
  },
  {
    question:
      "How do I determine the optimal parameters to make my bot profitable?",
    answer:
      "We've extensively tested various parameters, evaluating bot performance. You can leverage different parameter categories to tailor your bot for profitability.",
  },
  {
    question:
      "Do your bots incorporate my current exchange portfolio into their operations, or do they begin by establishing a new portfolio?",
    answer:
      "Yes, they can incorporate your current exchange portfolio into their operations.",
  },
];

function BotFaq() {
  const navigate = useNavigate();

  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>

      <h3 className="helpTitle">Bot Frequently Asked Questions</h3>

      {questions.map((faq, index) => (
        <FaqItem key={index} {...faq} id={index} />
      ))}
    </div>
  );
}

export default BotFaq;

import DashboardLayout from "../../components/layout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getDashboardData,
  getDashboardDataFast,
} from "../../redux/dashboard/action";
import { getReferralRewards } from "../../redux/pricing-plan/action";
import DashboardContent from "../../components/dashboard/DashboardContent";
import { getIntegratedExchanges } from "../../redux/exchange/actions";
import ErrorComponent from "../../components/errors/ErrorComponent";
import DashboardStatusbar2 from "../../components/dashboard/DashboardStatusbar2";

function DashboardIndex() {
  const {
    main: { userData },
    exchangeState: {
      integratedDataLoading,
      getIntegratedList,
      integratedDataError,
    },
    dashboardData: { error, fastDashboardError },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [isFastDashboardReady, setIsFastDashboardReady] = useState(false);
  const [isDashboardReady, setIsDashboardReady] = useState(false);
  const [isRewardsCalc, setIsRewardsCalc] = useState(false);

  useEffect(() => {
    if (userData?.email && !isFastDashboardReady) {
      dispatch(
        getDashboardDataFast(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
      setIsFastDashboardReady(true);
    }
  }, [dispatch, userData, isFastDashboardReady]);

  useEffect(() => {
    if (userData?.email && !isRewardsCalc) {
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
      setIsRewardsCalc(true);
    }
  }, [userData, dispatch, isRewardsCalc]);

  useEffect(() => {
    if (userData?.email && !isDashboardReady) {
      dispatch(
        getDashboardData(userData?.email, userData?.["custom:custom_username"]),
      );
      setIsDashboardReady(true);
    }
  }, [userData, isDashboardReady]);

  useEffect(() => {
    if (
      userData?.email &&
      !getIntegratedList &&
      !integratedDataLoading &&
      !integratedDataError
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.email,
        ),
      );
    }
  }, [userData, getIntegratedList, integratedDataLoading, integratedDataError]);

  return (
    <DashboardLayout hasDemo>
      {error && fastDashboardError ? (
        <ErrorComponent />
      ) : (
        <>
          {/*<DashboardStatusbar />*/}
          <DashboardStatusbar2 />
          <DashboardContent />
        </>
      )}
    </DashboardLayout>
  );
}

export default DashboardIndex;

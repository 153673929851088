import MiniCreditCharge from "../mini-app/mini-pricing/MiniCreditCharge";
import styles from "../../styles/Components/pricing-plan/ChargeCredit.module.css";

function ChargeCredit() {
  return (
    <MiniCreditCharge
      btnsClassName={styles.btns}
      inputClassName={styles.input}
      redeemClassName={styles.redeem}
      paymentBtnClassName={styles.paymentBtn}
      optionBtn={styles.optionBtn}
      selectedOptionClassName={styles.selectedOption}
    />
  );
}

export default ChargeCredit;

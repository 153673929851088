import React from "react";
import VirtualFeeSetting from "../../../VirtualFeeSetting";
import { Modal } from "antd";
import { useSelector } from "react-redux";

function FeeSettingModal({ show, setShow, setTrade }) {
  const {
    profile: { updateLoading },
  } = useSelector((state) => state);
  return (
    <Modal
      open={show}
      onCancel={() => setShow(false)}
      closable={!updateLoading}
      maskClosable={false}
      footer={null}
      centered={true}
    >
      <VirtualFeeSetting setShow={setShow} orderTrade={setTrade} />
    </Modal>
  );
}

export default FeeSettingModal;

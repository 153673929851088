import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/shared/trade-modal/TradeModal.module.css";
import Step1A from "./steps/Step1a";
import Step1B from "./steps/Step1b";
import {
  resetTradeData,
  resetVirtualTradeState,
} from "../../redux/trade/actions";
import Step2 from "./steps/Step2";
import ResultMode from "./steps/step-components/ResultMode";
import Step1C from "./steps/Step1c";
import VirtualTradeStep2 from "./steps/virtual-trade/VirtualTradeStep2";
import AddToVirtualPortfolio from "./steps/AddToVirtualPortfolio";
import LimitOrderSetting from "./steps/LimitOrderSetting";
import LimitOrderResult from "./steps/step-components/LimitOrderResult";
import VirtualTradeResult from "./steps/virtual-trade/VirtualTradeResult";
import SetVirtualLimitOrder from "./steps/virtual-trade/SetVirtualLimitOrder";
import Step1D from "./steps/Step1d";
import AddToVirtualConfirmationDialog from "./steps/AddToVirtualConfirmationDialog";
import VirtualLimitOrderResult from "./steps/virtual-trade/VirtualLimitOrderResult";
import ShowRecommendation from "./steps/ShowRecommendation";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsStars } from "react-icons/bs";
import { FaRegQuestionCircle } from "react-icons/fa";

function TradeModal({ show, setShow }) {
  const {
    tradeState: { currentStep, takeProfitOrderLoading, stopLossOrderLoading },
    exchangeState: { orderTradeLoading },
    tradingAssistant: { setTradeLoading, limitOrderLoading },
  } = useSelector((state) => state);
  const { pathname } = useLocation();
  const [showSteps, setShowSteps] = useState(
    pathname === "/ai-assistant/assistant" || pathname?.includes("/insight"),
  );

  const dispatch = useDispatch();

  const cancelHandler = () => {
    if (
      orderTradeLoading ||
      setTradeLoading ||
      takeProfitOrderLoading ||
      stopLossOrderLoading ||
      limitOrderLoading
    )
      return;
    setShowSteps(
      pathname === "/ai-assistant/assistant" || pathname?.includes("/insight"),
    );
    setShow(false);
    dispatch(resetTradeData());
    dispatch(resetVirtualTradeState());
  };

  if (!show) return null;

  return (
    <Modal
      open={show}
      centered={true}
      footer={null}
      onCancel={cancelHandler}
      maskClosable={false}
      width={450}
      closable={
        !orderTradeLoading ||
        !setTradeLoading ||
        !takeProfitOrderLoading ||
        !stopLossOrderLoading ||
        !limitOrderLoading
      }
      style={{ overflow: "hidden" }}
      bodyStyle={{ minHeight: "200px" }}
      children={
        <div className={styles.steps}>
          {!showSteps ? (
            <div>
              <h3>
                <FaRegQuestionCircle /> Would you like to see and use our AI
                trade suggestion for this trade?
              </h3>
              <p>
                <BsStars color="gold" /> Using the AI suggestion is safer than
                making decisions on your own.
              </p>
              <div className={styles.btns}>
                <Link className={styles.btn} to="/ai-assistant/assistant">
                  Yes, Show Me!
                </Link>
                <span className={styles.btn} onClick={() => setShowSteps(true)}>
                  No, Thanks
                </span>
              </div>
            </div>
          ) : (
            <>
              {currentStep === "1-a" && (
                <Step1A
                  step={currentStep}
                  setShow={cancelHandler}
                  show={show}
                />
              )}
              {/*<Step1A step={currentStep} setShow={setShow} />*/}
              {currentStep === "1-b" && (
                <Step1B step={currentStep} setShow={cancelHandler} />
              )}
              {currentStep === "1-d" && (
                <Step1D step={currentStep} setShow={cancelHandler} />
              )}
              {currentStep === "2" && (
                <Step2 step={currentStep} setShow={cancelHandler} />
              )}
              {currentStep === "result" && (
                <ResultMode step={currentStep} setShow={cancelHandler} />
              )}
              {currentStep === "1-c" && (
                <Step1C step={currentStep} setShow={cancelHandler} />
              )}
              {currentStep === "paper-2" && (
                <VirtualTradeStep2 setShow={cancelHandler} />
              )}
              {currentStep === "add-to-virtual" && (
                <AddToVirtualPortfolio setShow={cancelHandler} />
              )}
              {currentStep === "set-limit-order" && (
                <LimitOrderSetting setshow={cancelHandler} />
              )}
              {currentStep === "limit-order-result" && (
                <LimitOrderResult setShow={cancelHandler} step={currentStep} />
              )}
              {currentStep === "virtual-trade-result" && (
                <VirtualTradeResult setShow={cancelHandler} />
              )}
              {currentStep === "set-virtual-limit-order" && (
                <SetVirtualLimitOrder setShow={cancelHandler} />
              )}
              {currentStep === "add-to-virtual-confirmation" && (
                <AddToVirtualConfirmationDialog setShow={cancelHandler} />
              )}
              {currentStep === "virtual-limit-order-result" && (
                <VirtualLimitOrderResult setShow={cancelHandler} />
              )}
              {currentStep === "show-recommendation" && (
                <ShowRecommendation
                  showCongrateMsg={false}
                  setShow={cancelHandler}
                />
              )}
            </>
          )}
        </div>
      }
    />
  );
}

export default TradeModal;

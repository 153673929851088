import {
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_FAST_DASHBOARD_DATA_ERROR,
  GET_FAST_DASHBOARD_DATA_REQUEST,
  GET_FAST_DASHBOARD_DATA_SUCCESS,
} from "../dashboard/consts";

const dashboardInitialState = {
  loading: false,
  data: {},
  error: "",
  fastDashboardLoading: false,
  fastDashboardData: {},
  fastDashboardError: "",
};
export const getDashboardAllDataReducer = (
  state = dashboardInitialState,
  action,
) => {
  switch (action?.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload,
      };
    case GET_DASHBOARD_DATA_ERROR:
      if (action?.payload?.data?.status != 401) {
        return {
          ...state,
          loading: false,
          error: action?.payload,
        };
      } else {
        return {
          ...state,
          loading: true,
          error: action?.payload,
        };
      }

    case GET_FAST_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        fastDashboardLoading: true,
        fastDashboardError: "",
      };
    case GET_FAST_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        fastDashboardLoading: false,
        fastDashboardData: action?.payload,
      };
    case GET_FAST_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        fastDashboardLoading: false,
        fastDashboardError: action?.payload,
      };

    default:
      return state;
  }
};

import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/Components/header/HeaderNavigationMenu.module.css";
import { FiActivity, FiBarChart2, FiEye, FiSettings } from "react-icons/fi";
import { SiTestcafe } from "react-icons/si";
import { TbReport } from "react-icons/tb";
import { FaBriefcase, FaHistory, FaRobot } from "react-icons/fa";
import { AiFillAlert, AiFillSignal, AiOutlineMail } from "react-icons/ai";

const botMenuItems = [
  {
    label: "Configuration",
    path: "/bot-automation",
    icon: <FiSettings size={20} />,
  },
  {
    label: "Backtests",
    path: "/bot-automation/backtest",
    icon: <SiTestcafe size={23} />,
  },
  {
    label: "Performance",
    path: "/bot-automation/bot-performance",
    icon: <TbReport size={21} />,
  },
];

const integrationMenuItems = [
  {
    label: "Connections",
    path: "/integration",
    icon: <FiSettings size={20} />,
  },
  {
    label: "Portfolio",
    path: "/integration/portfolio",
    icon: <FaBriefcase size={20} />,
  },
  {
    label: "History",
    path: "/integration/trade-history",
    icon: <FaHistory size={20} />,
  },
];

const aiAssistantMenuItems = [
  {
    label: "Assistant",
    path: "/ai-assistant/assistant",
    icon: <FaRobot size={23} />,
  },
  {
    label: "Portfolio",
    path: "/ai-assistant/portfolio",
    icon: <FaBriefcase size={20} />,
  },
  {
    label: "History",
    path: "/ai-assistant/trade-history",
    icon: <FaHistory size={20} />,
  },
];

const alertSystemMenu = [
  {
    label: "Alert System",
    path: "/alert-system",
    icon: <AiFillAlert size={22} />,
  },
  {
    label: "Tracked Signals",
    path: "/alert-system/tracked-signals",
    icon: <AiFillSignal size={20} />,
  },
  {
    label: "Emails",
    path: "/alert-system/previous-emails",
    icon: <AiOutlineMail size={22} />,
  },
];

const insightsMenu = [
  {
    label: "Signal",
    path: "/insight/signal",
    icon: <FiActivity />,
  },
  {
    label: "Chart",
    path: "/insight/chart",
    icon: <FiBarChart2 />,
  },
  {
    label: "Watchlist",
    path: "/insight/watchlist",
    icon: <FiEye />,
  },
];

function HeaderNavigationMenu() {
  const location = useLocation();
  return (
    <ul className={styles.menu}>
      {/*{!location.pathname.includes("bot-automation") && "menu"}*/}
      {/*{console.log(location)}*/}
      {location.pathname.includes("bot-automation") &&
        botMenuItems.map((item) => (
          <li
            className={`${styles.menuItem}  ${
              location.pathname === item.path ? styles.active : ""
            }`}
            key={item.label}
          >
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}

      {location.pathname.includes("integration") &&
        integrationMenuItems.map((item) => (
          <li
            className={`${styles.menuItem}  ${
              location.pathname === item.path ? styles.active : ""
            }`}
            key={item.label}
          >
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}

      {location.pathname.includes("ai-assistant") &&
        aiAssistantMenuItems.map((item) => (
          <li
            className={`${styles.menuItem}  ${
              location.pathname === item.path ? styles.active : ""
            }`}
            key={item.label}
          >
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}

      {location.pathname.includes("alert-system") &&
        alertSystemMenu.map((item) => (
          <li
            className={`${styles.menuItem}  ${
              location.pathname === item.path ? styles.active : ""
            }`}
            key={item.label}
          >
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}

      {location.pathname.includes("insight") &&
        insightsMenu.map((item) => (
          <li
            className={`${styles.menuItem}  ${
              location.pathname === item.path ? styles.active : ""
            }`}
            key={item.label}
          >
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default HeaderNavigationMenu;

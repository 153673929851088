import { createContext, useContext, useReducer, useState } from "react";

// Define initial state
const initialState = {
  strategyList: [],
  selectedCategory: "",
  selectedSubcategory: "",
  selectedFeatures: [],
  strategyName: "",
  strategyTag: "",
  strategyLabel: "",
  strategyInfo: {
    strategy_name: "",
    strategy_tag: "",
    strategy_label: "",
  },
  searchQuery: "",
};

// Define action types
const actionTypes = {
  ADD_STRATEGY: "ADD_STRATEGY",
  SET_CATEGORY: "SET_CATEGORY",
  SET_SUBCATEGORY: "SET_SUBCATEGORY",
  SET_FEATURES: "SET_FEATURES",
  REMOVE_FEATURE: "REMOVE_FEATURE",
  SET_NAME: "SET_NAME",
  SET_TAG: "SET_TAG",
  SET_LABEL: "SET_LABEL",
  RESET_DATA: "RESET_DATA",
  SET_STRATEGY_INFO: "SET_STRATEGY_INFO",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
};

// Define reducer
const strategyReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_STRATEGY_INFO:
      return {
        ...state,
        strategyInfo: action.payload,
      };
    case actionTypes.ADD_STRATEGY:
      return {
        ...state,
        strategyList: [...state.strategyList, action.payload],
      };
    case actionTypes.SET_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case actionTypes.SET_SUBCATEGORY:
      return { ...state, selectedSubcategory: action.payload };
    case actionTypes.SET_FEATURES:
      const {
        symbol_list: symbols,
        subcategory,
        category,
        name,
      } = action.payload;
      const strategy = {
        symbol: symbols?.length ? symbols[0] : "",
        subcategory: subcategory,
        category: category,
        feature: name,
      };
      return {
        ...state,
        selectedFeatures: [...state.selectedFeatures, action.payload?.feature],
        strategyList: [...state.strategyList, strategy],
      };
    case actionTypes.REMOVE_FEATURE:
      return {
        ...state,
        selectedFeatures: state.selectedFeatures.filter(
          (feature) => feature !== action.payload?.feature,
        ),
        strategyList: state.strategyList?.filter(
          (s) => s.feature !== action.payload?.feature,
        ),
      };
    case actionTypes.SET_NAME:
      return { ...state, strategyName: action.payload };
    case actionTypes.SET_TAG:
      return { ...state, strategyTag: action.payload };
    case actionTypes.SET_LABEL:
      return { ...state, strategyLabel: action.payload };
    case actionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case actionTypes.RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

// Create context
const StrategyContext = createContext();

// Create provider component
const StrategyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(strategyReducer, initialState);

  const addStrategy = (strategy) =>
    dispatch({ type: actionTypes.ADD_STRATEGY, payload: strategy });
  const setCategory = (category) =>
    dispatch({ type: actionTypes.SET_CATEGORY, payload: category });
  const setSubcategory = (subcategory) =>
    dispatch({ type: actionTypes.SET_SUBCATEGORY, payload: subcategory });
  const setFeatures = (feature) =>
    dispatch({ type: actionTypes.SET_FEATURES, payload: feature });
  const removeFeature = (feature) =>
    dispatch({ type: actionTypes.REMOVE_FEATURE, payload: feature });
  const setName = (name) =>
    dispatch({ type: actionTypes.SET_NAME, payload: name });
  const setTag = (tag) => dispatch({ type: actionTypes.SET_TAG, payload: tag });
  const setLabel = (label) =>
    dispatch({ type: actionTypes.SET_LABEL, payload: label });
  const resetData = () => dispatch({ type: actionTypes.RESET_DATA });
  const setStrategyInfo = (info) =>
    dispatch({ type: actionTypes.SET_STRATEGY_INFO, payload: info });
  const searchStrategy = (query) => {
    dispatch({
      type: actionTypes.SET_SEARCH_QUERY,
      payload: query.toLowerCase(),
    });
  };

  return (
    <StrategyContext.Provider
      value={{
        state,
        addStrategy,
        setCategory,
        setSubcategory,
        setFeatures,
        removeFeature,
        setName,
        setTag,
        setLabel,
        resetData,
        setStrategyInfo,
        searchStrategy,
      }}
    >
      {children}
    </StrategyContext.Provider>
  );
};
const useStrategy = () => {
  const context = useContext(StrategyContext);

  if (context === undefined)
    throw new Error(
      "Strategy context was used outside of StrategyContextProvider",
    );

  return context;
};

export { useStrategy };

export default StrategyContextProvider;

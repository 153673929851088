import React, { useEffect } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import API from "../../components/Apis";
import { useDispatch, useSelector } from "react-redux";
import {
  generateApikey,
  getDeveloperApiData,
} from "../../redux/developer-api/actions";
import Documents from "../../components/developer-api/Documents";
import styles from "../../styles/pages/developer-api/index.module.sass";
import { showUpgradeModal } from "../../utility/helper";
import { useNavigate } from "react-router-dom";
import { FaRocket } from "react-icons/fa";
import { Button } from "antd";

const Index = () => {
  const {
    main: { userData },
    developerApi: { loading, data, error },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (userData?.["cognito:username"] && !loading && !data?.ResponseMetadata) {
      dispatch(
        getDeveloperApiData(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, loading, data]);
  const GenrateApiKey = () => {
    dispatch(
      generateApikey(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
      ),
    );
  };

  useEffect(() => {
    if (
      data?.Item?.pricing_plan === "Pro" ||
      data?.Item?.pricing_plan === "Free-tier"
    ) {
      showUpgradeModal(() => navigate("/pricing-billing"));
    }
  }, [data]);
  return (
    <DashboardLayout loading={loading} error={error}>
      <h2>Developer API</h2>
      {data?.Item?.pricing_plan !== "Pro" &&
      data?.Item?.pricing_plan !== "Free-tier" ? (
        <API data={data} generateApiKeyHandler={GenrateApiKey} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              // fontSize: "16px",
            }}
          >
            <FaRocket color={"#E72929"} size={18} />
            This feature is part of our Enterprise plan. Click the 'Buy Plan'
            button below and get started!
          </h3>
          <Button
            // type={"primary"}
            // style={{ backgroundColor: "#0C4CFC" }}
            className={styles.buyPlanBtn}
            onClick={() => navigate("/pricing-billing")}
          >
            Buy Plan
          </Button>
        </div>
      )}

      <div className={styles.documents}>
        {/* {data?.Item?.pricing_plan==="Pro"&&<Documents/>} */}
        <Documents />
      </div>
    </DashboardLayout>
  );
};

export default Index;

export const SET_EXCHANGE = "SET_EXCHANGE";
export const SET_ASSET = "SET_ASSET";
export const SET_TRADE_TYPE = "TRADE_TYPE";
export const SET_SECOND_TRADE_TYPE = "SECOND_TRADE_TYPE";
export const SET_STEP = "SET_STEP";
export const RESET_TRADE = "RESET_TRADE";
export const GET_TRADE_AMOUNT = "GET_TRADE_AMOUNT";
export const SET_TRADE_AMOUNT_USD = "SET_TRADE_AMOUNT_USD";
export const GET_TRIGGER_PRICE = "GET_TRIGGER_PRICE";
export const GET_TRADE_PORTFOLIO_REQUEST = "GET_TRADE_PORTFOLIO_REQUEST";
export const GET_TRADE_PORTFOLIO_SUCCESS = "GET_TRADE_PORTFOLIO_SUCCESS";
export const GET_TRADE_PORTFOLIO_ERROR = "GET_TRADE_PORTFOLIO_ERROR";
export const SET_USDT_AMOUNT = "SET_USDT_AMOUNT";
export const SET_ASSET_AMOUNT = "SET_ASSET_AMOUNT";
export const SET_ASSET_AMOUNT_USD = "SET_ASSET_AMOUNT_USD";
export const SET_MODAL_MODE = "SET_MODAL_MODE";

// virtual trade usages
export const SET_VIRTUAL_MARKET_TYPE = "SET_VIRTUAL_MARKET_TYPE";
export const SET_TRADE_PROCESS = "SET_TRADE_PROCESS";
export const SET_VIRTUAL_ASSET = "SET_VIRTUAL_ASSET";
export const SET_VIRTUAL_ASSET_AMOUNT_NATIVE =
  "SET_VIRTUAL_ASSET_AMOUNT_NATIVE";
export const SET_VIRTUAL_PORTFOLIO = "SET_VIRTUAL_PORTFOLIO";
export const SET_VIRTUAL_REMAINING_BUDGET = "SET_VIRTUAL_REMAINING_BUDGET";
export const SET_VIRTUAL_TRADE_TYPE = "SET_VIRTUAL_TRADE_TYPE";
export const SET_VIRTUAL_SECOND_TRADE_TYPE = "SET_VIRTUAL_SECOND_TRADE_TYPE";
export const SET_VIRTUAL_TRADE_AMOUNT = "SET_VIRTUAL_TRADE_AMOUNT";
export const SET_VIRTUAL_TRADE_AMOUNT_PERCENT =
  "SET_VIRTUAL_TRADE_AMOUNT_PERCENT";
export const SET_VIRTUAL_TRADE_AMOUNT_USD = "SET_VIRTUAL_TRADE_AMOUNT_USD";
export const SET_VIRTUAL_TRIGGER_PRICE = "SET_VIRTUAL_TRIGGER_PRICE";
// export const SET_VIRTUAL_TRIGGER_PRICE_PERCENT = "SET_VIRTUAL_TRIGGER_PRICE_PERCENT";
export const RESET_VIRTUAL_TRADE_STATE = "RESET_VIRTUAL_TRADE_STATE";
export const GET_VIRTUAL_PORTFOLIO_REQUEST = "GET_VIRTUAL_PORTFOLIO_REQUEST";
export const GET_VIRTUAL_PORTFOLIO_SUCCESS = "GET_VIRTUAL_PORTFOLIO_SUCCESS";
export const GET_VIRTUAL_PORTFOLIO_ERROR = "GET_VIRTUAL_PORTFOLIO_ERROR";

export const GET_VIRTUAL_ASSET_LIST_REQUEST = "GET_VIRTUAL_ASSET_LIST_REQUEST";
export const GET_VIRTUAL_ASSET_LIST_SUCCESS = "GET_VIRTUAL_ASSET_LIST_SUCCESS";
export const GET_VIRTUAL_ASSET_LIST_ERROR = "GET_VIRTUAL_ASSET_LIST_ERROR";
// export const GET_VIRTUAL_TRADE_PORTFOLIO_LOADING = "";
export const SET_STOP_LOSS_ORDER_REQUEST = "SET_STOP_LOSS_ORDER_REQUEST";
export const SET_STOP_LOSS_ORDER_SUCCESS = "SET_STOP_LOSS_ORDER_SUCCESS";
export const SET_STOP_LOSS_ORDER_ERROR = "SET_STOP_LOSS_ORDER_ERROR";
export const SET_STOP_LOSS_TRIGGER_PRICE = "SET_STOP_LOSS_TRIGGER_PRICE";
export const SET_STOP_LOSS_AMOUNT_PERCENT = "SET_STOP_LOSS_AMOUNT_PERCENT";
export const SET_STOP_LOSS_AMOUNT = "SET_STOP_LOSS_AMOUNT";
export const SET_STOP_LOSS_AMOUNT_USD = "SET_STOP_LOSS_AMOUNT_USD";
export const SET_STOP_LOSS_TRIGGER_PRICE_PERCENT =
  "SET_STOP_LOSS_TRIGGER_PRICE_PERCENT";
export const SET_TAKE_PROFIT_ORDER_REQUEST = "SET_TAKE_PROFIT_ORDER_REQUEST";
export const SET_TAKE_PROFIT_ORDER_SUCCESS = "SET_TAKE_PROFIT_ORDER_SUCCESS";
export const SET_TAKE_PROFIT_ORDER_ERROR = "SET_TAKE_PROFIT_ORDER_ERROR";
export const SET_TAKE_PROFIT_TRIGGER_PRICE = "SET_TAKE_PROFIT_TRIGGER_PRICE";
export const SET_TAKE_PROFIT_AMOUNT_PERCENT = "SET_TAKE_PROFIT_AMOUNT_PERCENT";
export const SET_TAKE_PROFIT_AMOUNT = "SET_TAKE_PROFIT_AMOUNT";
export const SET_TAKE_PROFIT_AMOUNT_USD = "SET_TAKE_PROFIT_AMOUNT_USD";
export const SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT =
  "SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT";
export const SET_TAKE_PROFIT = "SET_TAKE_PROFIT";
export const SET_STOP_LOSS = "SET_STOP_LOSS";
export const SET_BASIC_TRADE_INFO = "SET_BASIC_TRADE_INFO";

// LIMIT ORDER
export const SET_BUY_LIMIT = "SET_BUY_LIMIT";
export const SET_SELL_LIMIT = "SET_SELL_LIMIT";
export const SET_TARGET_PRICE = "SET_TARGET_PRICE";

export const ADD_TO_VIRTUAL_PORTFOLIO = "ADD_TO_VIRTUAL_PORTFOLIO";
export const SHOW_CONGRATULATION_MESSAGE = "SHOW_CONGRATULATION_MESSAGE";

import PlanCard from "./PlanCard";
import PlanTitle from "./PlanTitle";
import styles from "../../../styles/Components/pricing-plan/plans/Plan.module.css";
import PlanCardBtn from "./PlanCardBtn";
import { useState } from "react";
import PlanFeaturesList from "./PlanFeaturesList";

function EnterprisePlan() {
  const [type, setType] = useState("exchange");
  const title = type === "exchange" ? "One-time access fee" : "No cost";
  const description =
    type === "exchange"
      ? "60% to 90% discount per user per month"
      : "Earn 40% - 70% from the subscription fee";

  return (
    <PlanCard>
      <PlanTitle type={"enterprise"} />
      <div className={styles.description}>
        <span className={styles.costInfo}>{title}</span>
        {type === "exchange" && (
          <span style={{ fontWeight: 500, color: "#2b303a" }}>Plus</span>
        )}
        <span
          // className={styles.costValue}
          style={{ fontWeight: 500, color: "#2b303a", fontSize: "16px" }}
        >
          {description}
        </span>
      </div>

      <PlanCardBtn>
        <a className={styles.btnLink} href={"https://t.me/Mehdiravab"}>
          Contact Us
        </a>
      </PlanCardBtn>
      <div className={styles.tabs}>
        <span
          className={`${styles.tab} ${
            type === "exchange" ? styles.activeTab : ""
          }`}
          onClick={() => setType("exchange")}
        >
          Exchanges
        </span>
        <span
          className={`${styles.tab} ${
            type === "influencer" ? styles.activeTab : ""
          }`}
          onClick={() => setType("influencer")}
        >
          Influencers
        </span>
      </div>

      <div className={styles.featureBox}>
        <span className={styles.featureBoxTitle}>Features you'll love:</span>
        {type === "exchange" ? (
          <PlanFeaturesList plan={"enterprise"} />
        ) : (
          <div
            style={{
              fontWeight: 500,
              color: "#2b303a",
              fontSize: "13px",
              marginTop: "1rem",
            }}
          >
            Opportunity to earn money from your community
          </div>
        )}
      </div>
    </PlanCard>
  );
}

export default EnterprisePlan;

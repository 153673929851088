// STYLES
import "../../styles/Components/faq/faqPublic.css";

// Images
import ai1 from "../../asset/Images/faq/AI Assistant/Assistant/1.PNG";
import ai2 from "../../asset/Images/faq/AI Assistant/Assistant/2.PNG";
import ai3 from "../../asset/Images/faq/AI Assistant/Assistant/3.PNG";
import ai4 from "../../asset/Images/faq/AI Assistant/Assistant/4.PNG";
import { useNavigate } from "react-router";
import { BiChevronLeft } from "react-icons/bi";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AssistantSection = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="helpTitle">Assistant Section</h3>
      <p>
        In the Assistant section, you can see AI assistant suggestions for your
        portfolios. These suggestions are based on a combination of AI and
        algorithmic approaches and are tailored to your current risk appetite.
        Here's how you can use this section:
        <ul className="helpList">
          <li>
            To view the AI assistant suggestions, click on the "Assistant" in
            the right-hand menu.
          </li>
        </ul>
      </p>
      <div className="stepContainer">
        <p style={{ marginBottom: 0 }}>
          Additionally, you can update your risk appetite by following these
          steps:
        </p>
        <div className="subStepWrapper">
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 1:</span> Click on the "Risk
              Appetite" text.
            </p>
            <LazyLoadImage
              src={ai1}
              alt="ai assistant"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={ai1} alt="ai assistant" />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 2:</span> You will be presented
              with 5 options to choose from: "Very Cautious", "Cautious",
              "Balanced", "Adventurous", and "Very Adventurous".
            </p>
            <LazyLoadImage
              src={ai2}
              alt="ai assistant"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={ai2} alt="ai assistant" />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 3:</span> Select the option that
              best reflects your current risk appetite.{" "}
            </p>
            <LazyLoadImage
              src={ai3}
              alt="ai assistant"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={ai3} alt="ai assistant" />*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 4:</span> Click on the "Save"
              button to update your risk appetite.
            </p>
            <LazyLoadImage
              src={ai4}
              alt="ai assistant"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img className="helpImage" src={ai4} alt="ai assistant" />*/}
          </div>
        </div>
      </div>
      <p>
        Once you update your risk appetite, the AI assistant will adjust its
        suggestions accordingly to match your risk preferences. Keep in mind
        that higher risk typically comes with higher potential rewards, but also
        higher potential losses. It's important to understand your risk
        tolerance and make informed decisions based on your own financial
        situation and goals.
      </p>
      <p>
        If you have any further questions or concerns about using the AI
        assistant of Cryptocrispy, don't hesitate to reach out to our support
        team.
      </p>
    </div>
  );
};

export default AssistantSection;

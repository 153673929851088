import styles from "../../styles/shared/service-activation/DisplayActivationWithCreditResponse.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import Loader from "../../components/mini-app/shared/Loader";
import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";

function DisplayActivationWithCreditResponse({
  lowCredit,
  changeContent,
  setStep,
}) {
  const {
    pricingPlan: {
      activateServiceWithCreditResponse,
      activateServiceWithCreditStatus,
    },
  } = useSelector((state) => state);

  const goBack = () => {
    setStep(2);
    changeContent("selectDuration");
  };

  return (
    <div className={styles.activationBox}>
      {activateServiceWithCreditStatus === "loading" ? (
        <Loader height={"150px"} />
      ) : (
        <>
          {!lowCredit && (
            <div className={styles.backBtn} onClick={goBack}>
              <IoArrowBackCircleOutline />
            </div>
          )}
          {activateServiceWithCreditResponse?.can_proceed === "True" && (
            <>
              <FaCheckCircle size={30} color={"#44bd32"} />
              <h3>
                Simply press the ‘Continue’ button below to finalize the
                activation process.
              </h3>
              <a
                className={styles.activationButton}
                href={`${activateServiceWithCreditResponse?.checkout_session_url}`}
              >
                Continue
              </a>
            </>
          )}
          {(activateServiceWithCreditResponse?.can_proceed === "False" ||
            activateServiceWithCreditStatus === "error") && (
            <>
              <RiAlertFill size={30} color={"#e84118"} />
              <h3>Something went wrong. Please try again later.</h3>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default DisplayActivationWithCreditResponse;

// GET BOT LIST
export const GET_BOT_LIST_REQUEST = "GET_BOT_LIST_REQUEST";
export const GET_BOT_LIST_SUCCESS = "GET_BOT_LIST_SUCCESS";
export const GET_BOT_LIST_ERROR = "GET_BOT_LIST_ERROR";

//CREATE BOT
export const CREATE_BOT_REQUEST = "CREATE_BOT_REQUEST";
export const CREATE_BOT_SUCCESS = "CREATE_BOT_SUCCESS";
export const CREATE_BOT_ERROR = "CREATE_BOT_ERROR";

//ACTIVATE BOT
export const ACTIVATE_BOT_REQUEST = "ACTIVATE_BOT_REQUEST";
export const ACTIVATE_BOT_SUCCESS = "ACTIVATE_BOT_SUCCESS";
export const ACTIVATE_BOT_ERROR = "ACTIVATE_BOT_ERROR";

//DEACTIVATE BOT
export const DEACTIVATE_BOT_REQUEST = "DEACTIVATE_BOT_REQUEST";
export const DEACTIVATE_BOT_SUCCESS = "DEACTIVATE_BOT_SUCCESS";
export const DEACTIVATE_BOT_ERROR = "DEACTIVATE_BOT_ERROR";

// DELETE BOT
export const DELETE_BOT_REQUEST = "DELETE_BOT_REQUEST";
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS";
export const DELETE_BOT_ERROR = "DELETE_BOT_ERROR";

// CREATE BACKTEST
export const CREATE_BACKTEST_REQUEST = "CREATE_BACKTEST_REQUEST";
export const CREATE_BACKTEST_SUCCESS = "CREATE_BACKTEST_SUCCESS";
export const CREATE_BACKTEST_ERROR = "CREATE_BACKTEST_ERROR";

// DELETE BACKTEST
export const DELETE_BACKTEST_REQUEST = "DELETE_BACKTEST_REQUEST";
export const DELETE_BACKTEST_SUCCESS = "DELETE_BACKTEST_SUCCESS";
export const DELETE_BACKTEST_ERROR = "DELETE_BACKTEST_ERROR";

//GET BACKTEST HISTORY
export const GET_BACKTEST_HISTORY_REQUEST = "GET_BACKTEST_HISTORY_REQUEST";
export const GET_BACKTEST_HISTORY_SUCCESS = "GET_BACKTEST_HISTORY_SUCCESS";
export const GET_BACKTEST_HISTORY_ERROR = "GET_BACKTEST_HISTORY_ERROR";

// GET BACKTESET REPORT
export const GET_BACKTESET_REPORT_REQUEST = "GET_BACKTESET_REPORT_REQUEST";
export const GET_BACKTESET_REPORT_SUCCESS = "GET_BACKTESET_REPORT_SUCCESS";
export const GET_BACKTESET_REPORT_ERROR = "GET_BACKTESET_REPORT_ERROR";

export const GET_BACKTESET_REPORT_PDF_REQUEST =
  "GET_BACKTESET_REPORT_PDF_REQUEST";
export const GET_BACKTESET_REPORT_PDF_SUCCESS =
  "GET_BACKTESET_REPORT_PDF_SUCCESS";
export const GET_BACKTESET_REPORT_PDF_ERROR = "GET_BACKTESET_REPORT_PDF_ERROR";

// SELECTED BACKTEST FOR REPORT
export const SELECT_BACKTEST = "SELECT_BACKTEST";

// BOT PERFORMANCE

// BOT ACTIVITY
export const GET_BOT_ACTIVITY_REQUEST = "GET_BOT_ACTIVITY_REQUEST";
export const GET_BOT_ACTIVITY_SUCCESS = "GET_BOT_ACTIVITY_SUCCESS";
export const GET_BOT_ACTIVITY_ERROR = "GET_BOT_ACTIVITY_ERROR";
export const SELECT_BOT_ACTIVITY = "SELECT_BOT_ACTIVITY";

//BOT REPORT
export const GET_BOT_REPORT_REQUEST = "GET_BOT_REPORT_REQUEST";
export const GET_BOT_REPORT_SUCCESS = "GET_BOT_REPORT_SUCCESS";
export const GET_BOT_REPORT_ERROR = "GET_BOT_REPORT_ERROR";

export const GET_BOT_REPORT_PDF_REQUEST = "GET_BOT_REPORT_PDF_REQUEST";
export const GET_BOT_REPORT_PDF_SUCCESS = "GET_BOT_REPORT_PDF_SUCCESS";
export const GET_BOT_REPORT_PDF_ERROR = "GET_BOT_REPORT_PDF_ERROR";

export const GET_BOT_STATUS_LOADING = "GET_BOT_STATUS_LOADING";
export const GET_BOT_STATUS_SUCCESS = "GET_BOT_STATUS_SUCCESS";
export const GET_BOT_STATUS_ERROR = "GET_BOT_STATUS_ERROR";

// BOT STATISTICS
export const GET_BOT_STATISTICS_REPORT_REQUEST =
  "GET_BOT_STATISTICS_REPORT_REQUEST";
export const GET_BOT_STATISTICS_REPORT_SUCCESS =
  "GET_BOT_STATISTICS_REPORT_SUCCESS";
export const GET_BOT_STATISTICS_REPORT_ERROR =
  "GET_BOT_STATISTICS_REPORT_ERROR";

// CHECK BOT STATUS FOR BOT ACTIVATION
export const CHECK_BOT_STATUS_REQUEST = "CHECK_BOT_STATUS_REQUEST";
export const CHECK_BOT_STATUS_SUCCESS = "CHECK_BOT_STATUS_SUCCESS";
export const CHECK_BOT_STATUS_ERROR = "CHECK_BOT_STATUS_ERROR";

// SELL ALL FIAT ASSETS
export const SELL_ALL_FIAT_ASSETS_REQUEST = "SELL_ALL_FIAT_ASSETS_REQUEST";
export const SELL_ALL_FIAT_ASSETS_SUCCESS = "SELL_ALL_FIAT_ASSETS_SUCCESS";
export const SELL_ALL_FIAT_ASSETS_ERROR = "SELL_ALL_FIAT_ASSETS_ERROR";

// SELL ALL CRYPTO ASSETS
export const SELL_ALL_CRYPTO_ASSETS_REQUEST = "SELL_ALL_CRYPTO_ASSETS_REQUEST";
export const SELL_ALL_CRYPTO_ASSETS_SUCCESS = "SELL_ALL_CRYPTO_ASSETS_SUCCESS";
export const SELL_ALL_CRYPTO_ASSETS_ERROR = "SELL_ALL_CRYPTO_ASSETS_ERROR";

import React, { useState, useEffect } from "react";
import Modal from "../modal/Modal";
import poster from "../../asset/Images/alertPoster.jpg";
import styles from "../../styles/Components/help/insightHelp.module.sass";
import VideoPlayer from "../player/VideoPlayer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../redux/profile/actions";
import { BsFillPlayCircleFill } from "react-icons/bs";

const AIHelp = ({ open, handleOk, handleCancel }) => {
  const [show, setShow] = useState(false);
  const [showDemo, setShowDemo] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const dispatch = useDispatch();

  let {
    main: { userData },
    profile: { profileData },
  } = useSelector((state) => state);

  useEffect(async () => {
    if (userData?.email) {
      setShowDemo(profileData?.show_alert_system_demos === "True");
    }
  }, [userData, profileData]);

  const changeHandler = async (e) => {
    setShowDemo(!e.target.checked);

    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["show_alert_system_demos"],
        [e.target.checked ? "False" : "True"],
      ),
    );
  };

  const handleShow = (url, title) => {
    setSelectedVideo({
      title,
      url,
    });
    setShow(true);
  };

  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        title={"Watch this video to quickly learn how to use our platform"}
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        width={550}
        footer={false}
        centered={true}
        content={
          <div className={styles.wrapper}>
            <div>
              <img src={poster} alt="" />

              <span>
                <span>Introduction to Alert System</span>
                <BsFillPlayCircleFill
                  onClick={() =>
                    handleShow(
                      "https://d13eiv722he0se.cloudfront.net/alert_system_demo.mp4",
                      "Introduction to Alert System",
                    )
                  }
                />
              </span>
            </div>
            <Checkbox checked={!showDemo} onChange={changeHandler}>
              Disable automatic display.
            </Checkbox>
          </div>
        }
      />
      <VideoPlayer
        clickHandler={handleClose}
        show={show}
        hide={setShow}
        url={selectedVideo?.url}
        title={selectedVideo?.title}
      />
    </>
  );
};

export default AIHelp;

import {
  ACTIVATE_BOT_ERROR,
  ACTIVATE_BOT_REQUEST,
  ACTIVATE_BOT_SUCCESS,
  CHECK_BOT_STATUS_ERROR,
  CHECK_BOT_STATUS_REQUEST,
  CHECK_BOT_STATUS_SUCCESS,
  CREATE_BACKTEST_ERROR,
  CREATE_BACKTEST_REQUEST,
  CREATE_BACKTEST_SUCCESS,
  CREATE_BOT_ERROR,
  CREATE_BOT_REQUEST,
  CREATE_BOT_SUCCESS,
  DEACTIVATE_BOT_ERROR,
  DEACTIVATE_BOT_REQUEST,
  DEACTIVATE_BOT_SUCCESS,
  DELETE_BACKTEST_ERROR,
  DELETE_BACKTEST_REQUEST,
  DELETE_BACKTEST_SUCCESS,
  DELETE_BOT_ERROR,
  DELETE_BOT_REQUEST,
  DELETE_BOT_SUCCESS,
  GET_BACKTESET_REPORT_ERROR,
  GET_BACKTESET_REPORT_PDF_ERROR,
  GET_BACKTESET_REPORT_PDF_REQUEST,
  GET_BACKTESET_REPORT_PDF_SUCCESS,
  GET_BACKTESET_REPORT_REQUEST,
  GET_BACKTESET_REPORT_SUCCESS,
  GET_BACKTEST_HISTORY_ERROR,
  GET_BACKTEST_HISTORY_REQUEST,
  GET_BACKTEST_HISTORY_SUCCESS,
  GET_BOT_ACTIVITY_ERROR,
  GET_BOT_ACTIVITY_REQUEST,
  GET_BOT_ACTIVITY_SUCCESS,
  GET_BOT_LIST_ERROR,
  GET_BOT_LIST_REQUEST,
  GET_BOT_LIST_SUCCESS,
  GET_BOT_REPORT_ERROR,
  GET_BOT_REPORT_PDF_ERROR,
  GET_BOT_REPORT_PDF_REQUEST,
  GET_BOT_REPORT_PDF_SUCCESS,
  GET_BOT_REPORT_REQUEST,
  GET_BOT_REPORT_SUCCESS,
  GET_BOT_STATISTICS_REPORT_ERROR,
  GET_BOT_STATISTICS_REPORT_REQUEST,
  GET_BOT_STATISTICS_REPORT_SUCCESS,
  GET_BOT_STATUS_ERROR,
  GET_BOT_STATUS_LOADING,
  GET_BOT_STATUS_SUCCESS,
  SELECT_BACKTEST,
  SELECT_BOT_ACTIVITY,
  SELL_ALL_CRYPTO_ASSETS_ERROR,
  SELL_ALL_CRYPTO_ASSETS_REQUEST,
  SELL_ALL_CRYPTO_ASSETS_SUCCESS,
  SELL_ALL_FIAT_ASSETS_ERROR,
  SELL_ALL_FIAT_ASSETS_REQUEST,
  SELL_ALL_FIAT_ASSETS_SUCCESS,
} from "./consts";

const initialState = {
  botList: [],
  getBotListLoading: false,
  fetchedBotList: false,
  getBotListError: "",
  createBotResponse: "",
  createBotLoading: false,
  createBotError: "",
  activateBotResponse: "",
  activateBotLoading: false,
  activateBotError: "",
  deactivateBotResponse: "",
  deactivateBotLoading: false,
  deactivateBotError: "",
  deleteBotResponse: "",
  deleteBotLoading: false,
  deleteBotError: "",
  createBacktestResponse: "",
  createBacktestLoading: false,
  createBacktestError: "",
  deleteBacktestResponse: "",
  deleteBacktestLoading: false,
  deleteBacktestError: "",
  backtestHistoryData: [],
  backtestHistoryFetched: false,
  backktestHistoryLoading: false,
  backtestHistoryError: "",
  backtestReportLoading: false,
  backtesetReportData: {},
  backtesetReportError: "",
  selectedBacktest: {},
  getBotActivityLoading: false,
  botActivityData: [],
  getBotActivityError: "",
  botActivityFetched: false,
  selectedBot: {},
  getBotReportLoading: false,
  botReportData: {},
  getBotReportError: "",
  backtesetPdfLoading: false,
  backtestPdfLink: "",
  backtesetPdfError: "",
  performancePdfLoading: false,
  performancePdfLink: "",
  performancePdfError: "",
  botStatusLoading: false,
  botStatusData: {},
  botStatusError: "",
  botStatisticLoading: false,
  botStatisticData: {},
  botStatisticError: "",
  checkBotStatusLoading: false,
  checkBotStatusData: {},
  checkBotStatusError: "",
  sellAllFiatAssetsLoading: false,
  sellAllFiatAssetResult: "",
  sellAllFiatAssetError: "",
  sellAllCryptoAssetsLoading: false,
  sellAllCryptoAssetsResult: "",
  sellAllCryptoAssetsError: "",
};

export const botReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET LIST
    case GET_BOT_LIST_REQUEST:
      return {
        ...state,
        getBotListLoading: true,
        fetchedBotList: false,
        getBotListError: "",
      };
    case GET_BOT_LIST_SUCCESS:
      return {
        ...state,
        fetchedBotList: true,
        getBotListLoading: false,
        botList: payload,
      };
    case GET_BOT_LIST_ERROR:
      return {
        ...state,
        getBotListLoading: false,
        getBotListError: payload,
      };

    //   CREATE
    case CREATE_BOT_REQUEST:
      return {
        ...state,
        createBotLoading: true,
        createBotError: "",
        createBotResponse: "",
      };
    case CREATE_BOT_SUCCESS:
      return {
        ...state,
        createBotLoading: false,
        createBotResponse: payload,
      };
    case CREATE_BOT_ERROR:
      return {
        ...state,
        createBotLoading: false,
        createBotError: payload,
      };

    case "clear-create-bot-data":
      return {
        ...state,
        createBotLoading: false,
        createBotError: "",
        createBotResponse: "",
      };

    //   ACTIVATE
    case ACTIVATE_BOT_REQUEST:
      return {
        ...state,
        activateBotLoading: true,
        activateBotResponse: "",
        activateBotError: "",
      };
    case ACTIVATE_BOT_SUCCESS:
      return {
        ...state,
        activateBotLoading: false,
        activateBotResponse: payload,
      };
    case ACTIVATE_BOT_ERROR:
      return {
        ...state,
        activateBotLoading: false,
        activateBotError: payload,
      };

    //   DEACTIVATE
    case DEACTIVATE_BOT_REQUEST:
      return {
        ...state,
        deactivateBotLoading: true,
        deactivateBotResponse: "",
        deactivateBotError: "",
      };
    case DEACTIVATE_BOT_SUCCESS:
      return {
        ...state,
        deactivateBotLoading: false,
        deactivateBotResponse: payload,
      };
    case DEACTIVATE_BOT_ERROR:
      return {
        ...state,
        deactivateBotLoading: false,
        deactivateBotError: payload,
      };

    // DELETE
    case DELETE_BOT_REQUEST:
      return {
        ...state,
        deleteBotLoading: true,
        deleteBotError: "",
      };
    case DELETE_BOT_SUCCESS:
      return {
        ...state,
        deleteBotLoading: false,
        deleteBotResponse: payload,
      };
    case DELETE_BOT_ERROR:
      return {
        ...state,
        deleteBotLoading: false,
        deleteBotError: payload,
      };

    // CREATE BACKTEST
    case CREATE_BACKTEST_REQUEST:
      return {
        ...state,
        createBacktestLoading: true,
        createBacktestError: "",
      };
    case CREATE_BACKTEST_SUCCESS:
      return {
        ...state,
        createBacktestLoading: false,
        createBacktestResponse: payload,
      };
    case CREATE_BACKTEST_ERROR:
      return {
        ...state,
        createBacktestLoading: false,
        createBacktestError: payload,
      };

    //  DELETE BACKTEST
    case DELETE_BACKTEST_REQUEST:
      return {
        ...state,
        deleteBacktestLoading: true,
        deleteBacktestError: "",
      };
    case DELETE_BACKTEST_SUCCESS:
      return {
        ...state,
        deleteBacktestLoading: false,
        deleteBacktestResponse: payload,
      };
    case DELETE_BACKTEST_ERROR:
      return {
        ...state,
        deleteBacktestLoading: false,
        deleteBacktestError: payload,
      };

    // BACKTEST HISTORY
    case GET_BACKTEST_HISTORY_REQUEST:
      return {
        ...state,
        backktestHistoryLoading: true,
        backtestHistoryFetched: true,
        backtestHistoryError: "",
      };
    case GET_BACKTEST_HISTORY_SUCCESS:
      return {
        ...state,
        backktestHistoryLoading: false,
        backtestHistoryData: payload,
      };
    case GET_BACKTEST_HISTORY_ERROR:
      return {
        ...state,
        backktestHistoryLoading: false,
        backtestHistoryFetched: false,
        backtestHistoryError: payload,
      };

    //   BACKTESET REPORT
    case GET_BACKTESET_REPORT_REQUEST:
      return {
        ...state,
        backtestReportLoading: true,
        backtesetReportError: "",
      };
    case GET_BACKTESET_REPORT_SUCCESS:
      return {
        ...state,
        backtestReportLoading: false,
        backtesetReportData: payload,
      };
    case GET_BACKTESET_REPORT_ERROR:
      return {
        ...state,
        backtestReportLoading: false,
        backtesetReportError: payload,
      };

    //   SELECT BACKTESET
    case SELECT_BACKTEST:
      return {
        ...state,
        selectedBacktest: payload,
      };

    //   GET BOT ACTIVITY
    case GET_BOT_ACTIVITY_REQUEST:
      return {
        ...state,
        getBotActivityLoading: true,
        getBotActivityError: "",
      };
    case GET_BOT_ACTIVITY_SUCCESS:
      return {
        ...state,
        getBotActivityLoading: false,
        botActivityData: payload,
        botActivityFetched: true,
      };
    case GET_BOT_ACTIVITY_ERROR:
      return {
        ...state,
        getBotActivityLoading: false,
        getBotActivityError: payload,
        botActivityFetched: true,
      };
    case SELECT_BOT_ACTIVITY:
      return {
        ...state,
        selectedBot: payload,
      };

    //   BOT PERFORMANCE REPORT
    case GET_BOT_REPORT_REQUEST:
      return {
        ...state,
        getBotReportLoading: true,
        getBotReportError: "",
      };
    case GET_BOT_REPORT_SUCCESS:
      return {
        ...state,
        getBotReportLoading: false,
        botReportData: payload,
      };
    case GET_BOT_REPORT_ERROR:
      return {
        ...state,
        getBotListLoading: false,
        getBotReportError: payload,
      };

    case GET_BACKTESET_REPORT_PDF_REQUEST:
      return {
        ...state,
        backtesetPdfLoading: true,
        backtesetPdfError: "",
        backtestPdfLink: "",
      };

    case GET_BACKTESET_REPORT_PDF_SUCCESS:
      return {
        ...state,
        backtesetPdfLoading: false,
        backtestPdfLink: payload,
      };
    case GET_BACKTESET_REPORT_PDF_ERROR:
      return {
        ...state,
        backtesetPdfLoading: false,
        backtesetPdfError: payload,
      };

    case GET_BOT_REPORT_PDF_REQUEST:
      return {
        ...state,
        performancePdfLoading: true,
        performancePdfError: "",
        performancePdfLink: "",
      };
    case GET_BOT_REPORT_PDF_SUCCESS:
      return {
        ...state,
        performancePdfLoading: false,
        performancePdfLink: payload,
      };
    case GET_BOT_REPORT_PDF_ERROR:
      return {
        ...state,
        performancePdfLoading: false,
        performancePdfError: payload,
      };

    //   BOT STATUS
    case GET_BOT_STATUS_LOADING:
      return {
        ...state,
        botStatusLoading: true,
        botStatusData: {},
        botStatusError: "",
      };
    case GET_BOT_STATUS_SUCCESS:
      return {
        ...state,
        botStatusLoading: false,
        botStatusData: payload,
      };
    case GET_BOT_STATUS_ERROR:
      return {
        ...state,
        botStatusLoading: false,
        botStatusError: payload,
      };

    //   CHECK BOT STATUS
    case CHECK_BOT_STATUS_REQUEST:
      return {
        ...state,
        checkBotStatusLoading: true,
        checkBotStatusData: {},
        checkBotStatusError: "",
      };
    case CHECK_BOT_STATUS_SUCCESS:
      return {
        ...state,
        checkBotStatusLoading: false,
        checkBotStatusData: payload,
      };
    case CHECK_BOT_STATUS_ERROR:
      return {
        ...state,
        checkBotStatusLoading: false,
        checkBotStatusError: payload,
      };

    case GET_BOT_STATISTICS_REPORT_REQUEST:
      return {
        ...state,
        botStatisticLoading: true,
        botStatisticError: "",
      };
    case GET_BOT_STATISTICS_REPORT_SUCCESS:
      return {
        ...state,
        botStatisticLoading: false,
        botStatisticData: payload,
      };
    case GET_BOT_STATISTICS_REPORT_ERROR:
      return {
        ...state,
        botStatisticLoading: false,
        botStatisticError: payload,
      };

    case SELL_ALL_FIAT_ASSETS_REQUEST:
      return {
        ...state,
        sellAllFiatAssetsLoading: true,
        sellAllFiatAssetError: "",
        sellAllFiatAssetResult: "",
      };
    case SELL_ALL_FIAT_ASSETS_SUCCESS:
      return {
        ...state,
        sellAllFiatAssetsLoading: false,
        sellAllFiatAssetResult: payload,
      };
    case SELL_ALL_FIAT_ASSETS_ERROR:
      return {
        ...state,
        sellAllFiatAssetsLoading: false,
        sellAllFiatAssetError: payload,
      };

    case SELL_ALL_CRYPTO_ASSETS_REQUEST:
      return {
        ...state,
        sellAllCryptoAssetsLoading: true,
        sellAllCryptoAssetsError: "",
        sellAllCryptoAssetsResult: "",
      };
    case SELL_ALL_CRYPTO_ASSETS_SUCCESS:
      return {
        ...state,
        sellAllCryptoAssetsLoading: false,
        sellAllCryptoAssetsResult: payload,
      };
    case SELL_ALL_CRYPTO_ASSETS_ERROR:
      return {
        ...state,
        sellAllCryptoAssetsLoading: false,
        sellAllCryptoAssetsError: payload,
      };

    default:
      return state;
  }
};

import TradingViewWidget from "./chart-section/TradingViewWidget";
import SignalsSummary from "./chart-section/SignalsSummary";

function InsightChartSection() {
  return (
    <>
      <TradingViewWidget />
      <SignalsSummary />
    </>
  );
}

export default InsightChartSection;

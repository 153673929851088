import styles from "../../../styles/Components/trade-assistant/history/VirtualTradeHistoryExpandedRow.module.css";
import { formatAmount, formatCustomDate } from "../../../utility/helper";

function VirtualTradeHistoryExpandedRow({ data }) {
  return (
    <div className={styles.container}>
      <ul className={styles.dataList}>
        <li className={styles.market}>
          <span className={styles.label}>Market:</span>
          <span>{data?.marketType}</span>
        </li>
        <li className={styles.date}>
          <span className={styles.label}>Date:</span>
          <span>{formatCustomDate(new Date(data?.date), "show")}</span>
        </li>
        <li>
          <span className={styles.label}>Trade Method:</span>
          <span>{data?.tradeMethod || "-"}</span>
        </li>
        <li className={styles.nativeAmount}>
          <span className={styles.label}>Native Amount:</span>
          <span>{formatAmount(Number(data?.nativeAmount))}</span>
        </li>
      </ul>
    </div>
  );
}

export default VirtualTradeHistoryExpandedRow;

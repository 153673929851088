import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CreateBotStepTitle = ({ title }) => {
  return (
    <h4 className={styles.title}>
      <IoIosArrowForward size={12} />
      {title}
      <IoIosArrowBack size={12} />
    </h4>
  );
};

export default CreateBotStepTitle;

import React, { useEffect, useState } from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Input, message, Tabs } from "antd";
import { getExchangePrice } from "../../../redux/exchange/actions";
import { formatAmount, formatAssetPrice } from "../../../utility/helper";
import {
  handleStopLossSelection,
  handleTakeProfitSelection,
  setAssetAmount,
  setAssetAmountUsd,
  setStep,
  setStopLossOrder,
  setTakeProfitOrder,
} from "../../../redux/trade/actions";
import SetLimitPrice from "./step-components/SetLimitPrice";
import SetLimitAmount from "./step-components/SetLimitAmount";
import { FaCheck } from "react-icons/fa";
import { BsSkipEndFill } from "react-icons/bs";

const { TabPane } = Tabs;

function LimitOrderSetting({ setshow }) {
  const {
    tradeState: {
      selectedAsset,
      secondTradeType,
      selectedExchange,
      tradeAmount,
      tradeAmountUsd,
      assetAmount,
      takeProfitOrderData,
      stopLossOrderData,
      isTakeProfitOrdered,
      isStopLossOrdered,
      isStopLossChecked,
      isTakeProfitChecked,
      stopLossOrderTriggerPrice,
      takeProfitOrderTriggerPrice,
      tradeProcess,
      targetPrice,
    },
    exchangeState: { priceData, priceLoading, portfolioLoading, portfolioData },
    tradingAssistant: { setTradeLoading },
    main: { userData },
  } = useSelector((state) => state);

  const [isDone, setIsDone] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  const dispatch = useDispatch();

  const handleTabChange = (key) => {
    if ((secondTradeType !== "buy" && priceLoading) || portfolioLoading) {
      return message.info(" Please wait to load data");
    }
    if (key === "takeProfit") {
      dispatch(handleTakeProfitSelection(true));
    }
    if (key === "stopLoss") {
      dispatch(handleStopLossSelection(true));
    }
    setActiveTab(key);
  };

  const handleCheckboxChange = (type) => {
    if ((secondTradeType !== "buy" && priceLoading) || portfolioLoading) {
      return message.info(" Please wait to load data");
    }
    if (type === "stopLoss") {
      dispatch(handleStopLossSelection(!isStopLossChecked));
    }
    if (type === "takeProfit") {
      dispatch(handleTakeProfitSelection(!isTakeProfitChecked));
    }
  };

  const setOrderHandler = () => {
    if (!isDone) return;
    if (secondTradeType !== "buy" && priceLoading) return;

    const amount = selectedExchange === "swyftx" ? tradeAmountUsd : tradeAmount;
    const currency = selectedExchange === "swyftx" ? "USDT" : "";
    const asset =
      selectedExchange === "swyftx"
        ? selectedAsset.split("/")[0]
        : selectedAsset;

    if (isTakeProfitChecked) {
      if (!Object.keys(takeProfitOrderData).length) {
        dispatch(
          setTakeProfitOrder(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedExchange,
            asset,
            "sell_tp",
            `${amount}`,
            `${takeProfitOrderTriggerPrice}`,
            currency,
          ),
        );
      }
    }

    if (isStopLossChecked) {
      if (!Object.keys(stopLossOrderData).length) {
        dispatch(
          setStopLossOrder(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedExchange,
            asset,
            "sell_sl",
            `${amount}`,
            `${stopLossOrderTriggerPrice}`,
            currency,
          ),
        );
      }
    }

    dispatch(setStep("limit-order-result"));
  };

  useEffect(() => {
    if (!isTakeProfitChecked && !isStopLossChecked) {
      setIsDone(false);
    }
  }, [isTakeProfitChecked, isStopLossChecked]);

  useEffect(() => {
    if (secondTradeType !== "buy_bb" && secondTradeType !== "buy_db") {
      dispatch(
        getExchangePrice(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
          [selectedAsset.split("/")[0]],
        ),
      );
      // Fetch price data every minute
      const intervalId = setInterval(() => {
        if (selectedAsset) {
          dispatch(
            getExchangePrice(
              userData?.["custom:custom_username"],
              userData?.["cognito:username"],
              selectedExchange,
              [selectedAsset.replace("/USDT", "")],
            ),
          );
        }
      }, 60000); // 60000 milliseconds = 1 minute

      // Clean up the interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [secondTradeType]);

  useEffect(() => {
    if (!portfolioLoading && portfolioData.length > 0) {
      dispatch(
        setAssetAmount(
          portfolioData.find(
            (item) => item.assetName === selectedAsset.split("/")[0],
          )?.availableBalance || tradeAmount,
        ),
      );
      const usdAmount =
        portfolioData.find(
          (item) => item.assetName === selectedAsset.split("/")[0],
        )?.availableBalanceUSD || tradeAmountUsd;
      dispatch(setAssetAmountUsd(usdAmount));
    }
  }, [dispatch, portfolioData, portfolioLoading, selectedAsset]);

  useEffect(() => {
    if (
      Object.keys(priceData).length &&
      !priceLoading &&
      !portfolioLoading &&
      !activeTab
    ) {
      setActiveTab("stopLoss");
    }
  }, [priceData, activeTab, priceLoading, portfolioLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.assetInfo}>
        <div>
          <h4>Asset Name:</h4> <p>{selectedAsset.replace("/USDT", "")}</p>
        </div>
        {secondTradeType !== "buy_bb" && secondTradeType !== "buy_db" && (
          <div>
            <h4>Current Price:</h4>{" "}
            {priceLoading ? (
              <p>Loading...</p>
            ) : (
              <p>
                $ {formatAssetPrice(priceData[selectedAsset.split("/")[0]])}
              </p>
            )}
          </div>
        )}
        {(secondTradeType === "buy_bb" || secondTradeType === "buy_db") && (
          <div>
            <h4>Target Price:</h4>
            <p>$ {formatAssetPrice(targetPrice)}</p>
          </div>
        )}
        <div>
          <h4>Native Amount:</h4>{" "}
          <p>{portfolioLoading ? "loading..." : formatAmount(assetAmount)}</p>
        </div>
      </div>
      <h3>
        <AiFillCaretRight />
        What limit order type you want? <br />
        <p>
          <strong style={{ marginRight: ".3rem" }}>Note:</strong>You can set
          both of them.
        </p>
      </h3>

      <Tabs
        activeKey={activeTab}
        animated
        type="card"
        onChange={handleTabChange}
      >
        <TabPane
          tab={
            <span>
              Stop Loss{" "}
              {isStopLossChecked && !priceLoading && !portfolioLoading && (
                <FaCheck color={"green"} />
              )}
            </span>
          }
          key="stopLoss"
        >
          {/* Content for Stop Loss tab */}
          <Checkbox
            checked={isStopLossChecked}
            onChange={() => handleCheckboxChange("stopLoss")}
            disabled={
              isStopLossOrdered && Object.keys(stopLossOrderData).length > 0
            }
          >
            Set Stop loss
          </Checkbox>

          {isStopLossChecked && (
            <div className={styles.input} style={{ margin: "1rem 0 0" }}>
              <label>Amount:</label>
              <SetLimitAmount
                type="stopLoss"
                isSelect={isStopLossChecked}
                fixedPrice={
                  secondTradeType === "buy_bb" || secondTradeType === "buy_db"
                }
              />
              <label>Trigger price:</label>
              <SetLimitPrice
                type="low"
                setIsDone={setIsDone}
                fixedPrice={
                  secondTradeType === "buy_bb" || secondTradeType === "buy_db"
                }
                className={styles.priceSetting}
              />
            </div>
          )}
        </TabPane>

        <TabPane
          tab={
            <span>
              Take Profit{" "}
              {isTakeProfitChecked && !priceLoading && !portfolioLoading && (
                <FaCheck color={"green"} />
              )}
            </span>
          }
          key="takeProfit"
        >
          {/* Content for Take Profit tab */}
          <Checkbox
            checked={isTakeProfitChecked}
            onChange={() => handleCheckboxChange("takeProfit")}
            disabled={
              isTakeProfitOrdered && Object.keys(takeProfitOrderData).length > 0
            }
          >
            Set Take profit
          </Checkbox>

          {isTakeProfitChecked && (
            <div className={styles.input} style={{ margin: "1rem 0 0" }}>
              <label>Amount:</label>
              <SetLimitAmount
                type="takeProfit"
                isSelect={isTakeProfitChecked}
                fixedPrice={
                  secondTradeType === "buy_bb" || secondTradeType === "buy_db"
                }
              />
              <label>Trigger price:</label>
              <SetLimitPrice
                type="high"
                setIsDone={setIsDone}
                className={styles.priceSetting}
                fixedPrice={
                  secondTradeType === "buy_bb" || secondTradeType === "buy_db"
                }
              />
            </div>
          )}
        </TabPane>
      </Tabs>

      <div
        className={styles.btnContainer}
        style={{ marginTop: "1rem", gap: "1rem" }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            color: "#2b5fec ",
            gap: ".3rem",
            padding: "0 .3rem",
            borderBottom: "1px solid #2b5fec",
            cursor: "pointer",
          }}
          onClick={() => {
            if (tradeProcess === "exchange") {
              dispatch(setStep("add-to-virtual-confirmation"));
            } else {
              // setshow();
              dispatch(setStep("show-recommendation"));
            }
          }}
        >
          Skip <BsSkipEndFill />
        </span>
        <Button
          loading={setTradeLoading}
          type="primary"
          htmlType="submit"
          className={`${styles.submitBtn} ${!isDone ? styles.inactive : ""}`}
          onClick={setOrderHandler}
        >
          Place Order
        </Button>
      </div>
    </div>
  );
}

export default LimitOrderSetting;

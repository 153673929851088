import { Link } from "react-router-dom";

// ANTD COMPONENTS
import { Col } from "antd";

// STYLES
import classes from "../../styles/Components/faq/FaqList.module.css";
import DemoModal from "../layout/DemoModal";
import { useState } from "react";
import { MdOutlineOndemandVideo } from "react-icons/md";

const FaqList = function ({ data }) {
  const { title, questions, links, type } = data;

  const [showDemoModal, setShowDemoModal] = useState(false);

  return (
    <Col span={8} xs={24} sm={12} md={8}>
      <h4 className={classes.title}>{title}</h4>
      <ul className={classes.list}>
        {questions.map((q, i) => (
          <li key={i}>
            <Link to={`/faq/${links[i]}`}>{q}</Link>
          </li>
        ))}
        {type && (
          <li
            className={classes.tutorialLink}
            onClick={() => setShowDemoModal(true)}
          >
            <MdOutlineOndemandVideo />
            Watch Tutorial
          </li>
        )}
      </ul>

      <DemoModal
        open={showDemoModal}
        handleClose={() => setShowDemoModal(false)}
        page={type}
      />
    </Col>
  );
};

export default FaqList;

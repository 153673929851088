import React, { useEffect, useState } from "react";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  setStep,
  setVirtualAssetAmount,
  setVirtualRemainingBudget,
} from "../../../../redux/trade/actions";
import { Button, Skeleton } from "antd";
import {
  getPrice,
  setLimitOrder,
  setTrade,
} from "../../../../redux/trading-assistant/actions";
import { AiFillCaretRight } from "react-icons/ai";
import { formatAmount, formatAssetPrice } from "../../../../utility/helper";
import TradeTypeOptions from "../TradeTypeOptions";
import VirtualTradeAmountSetting from "./VirtualTradeAmountSetting";
import VirtualLimitOrderPriceSetting from "./VirtualLimitOrderPriceSetting";
import FeeSettingModal from "./FeeSettingModal";

const Loader = ({ content }) => {
  const {
    tradingAssistant: { getPriceLoading },
  } = useSelector((state) => state);

  return getPriceLoading ? (
    <div className={styles.skeleton}>
      <Skeleton.Input active={true} width={"100%"} />
    </div>
  ) : (
    <p>{content}</p>
  );
};

function VirtualTradeStep2({ setShow }) {
  const {
    main: { userData },
    tradeState: {
      modalMode,
      virtualSelectedAsset,
      virtualMarketType,
      virtualSelectedPortfolio,
      virtualRemainingBudget,
      virtualTradeAmount,
      virtualTradeAmountUsd,
      virtualTriggerPrice,
      virtualTradeType,
      virtualSecondTradeType,
      virtualAssetAmount,
      virtualTradAmountPercent,
    },
    profile: { profileData },
    tradingAssistant: {
      getPriceLoading,
      price,
      setTradeLoading,
      limitOrderLoading,
    },
  } = useSelector((state) => state);
  const [isDone, setIsDone] = useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);

  const dispatch = useDispatch();

  const goBackHandler = () => {
    if (modalMode === "ai-btn") {
      dispatch(setStep("1-c"));
    }
    if (modalMode === "ai-table") {
      dispatch(setStep("1-d"));
    }

    if (modalMode === "ai-suggestion") {
      dispatch(setStep("1-d"));
    }
  };

  const orderTradeHandler = (e, isFeeSet = false) => {
    if (!isDone) return;
    if (setTradeLoading || limitOrderLoading) return;

    if (
      virtualSecondTradeType !== "Buy" &&
      virtualSecondTradeType !== "Sell" &&
      !profileData.transaction_fee &&
      !isFeeSet
    ) {
      setShowFeeModal(true);
      return;
    }

    if (virtualSecondTradeType === "Buy" || virtualSecondTradeType === "Sell") {
      const amount =
        virtualSecondTradeType === "Buy"
          ? virtualTradeAmountUsd
          : virtualTradeAmount;
      const nativeOrUsd = virtualSecondTradeType === "Buy" ? "usd" : "native";

      dispatch(
        setTrade(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedAsset,
          virtualMarketType,
          amount,
          virtualSecondTradeType,
          virtualSelectedPortfolio.toLowerCase(),
          nativeOrUsd,
        ),
      );
    } else {
      dispatch(
        setLimitOrder(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedPortfolio.toLowerCase(),
          virtualMarketType,
          virtualSelectedAsset,
          [virtualSecondTradeType],
          [`${virtualTradAmountPercent}`],
          [`${virtualTriggerPrice.percent}`],
        ),
      );
    }
    dispatch(setStep("virtual-trade-result"));
  };

  const amountValidation = (amount) => {
    if (
      virtualSecondTradeType === "stop_loss" ||
      virtualSecondTradeType === "take_profit" ||
      virtualSecondTradeType === "Sell"
    ) {
      // if (Number(amount) === 0 && amount !== "") return false;
      return Number(amount) <= Number(virtualAssetAmount);
    }
    if (
      virtualSecondTradeType === "breakout" ||
      virtualSecondTradeType === "discount" ||
      virtualSecondTradeType === "Buy"
    ) {
      // if (Number(amount) === 0 && amount !== "") return false;
      return Number(amount) * price?.PRICE <= Number(virtualRemainingBudget);
    }
  };

  const triggerPriceValidation = (amount) => {
    if (
      virtualSecondTradeType === "stop_loss" ||
      virtualSecondTradeType === "discount"
    ) {
      if (Number(amount) === 0 && amount !== "") return false;
      return Number(amount) < Number(price?.PRICE);
    }
    if (
      virtualSecondTradeType === "take_profit" ||
      virtualSecondTradeType === "breakout"
    ) {
      return Number(amount) > Number(price?.PRICE);
    }
  };

  useEffect(() => {
    if (virtualMarketType && virtualSelectedAsset) {
      if (!price || price?.SYMBOL !== virtualSelectedAsset) {
        dispatch(
          getPrice(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            virtualSelectedAsset,
            virtualMarketType,
          ),
        );
      }
      const intervalId = setInterval(() => {
        if (virtualSelectedAsset) {
          dispatch(
            getPrice(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              virtualSelectedAsset,
              virtualMarketType,
            ),
          );
        }
      }, 60000); // 60000 milliseconds = 1 minute

      // Clean up the interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [virtualSelectedAsset, virtualMarketType, dispatch, userData, price]);
  useEffect(() => {
    if (!getPriceLoading && price && price?.SYMBOL === virtualSelectedAsset) {
      dispatch(
        setVirtualAssetAmount(
          price["CURRENT_NATIVE_AMOUNT"][virtualSelectedPortfolio],
        ),
      );
    }
  }, [price, getPriceLoading, dispatch, virtualSelectedPortfolio]);

  useEffect(() => {
    if (virtualSelectedPortfolio && price && !virtualRemainingBudget) {
      dispatch(
        setVirtualRemainingBudget(
          price["CURRENT_INVESTMENT_BUDGET"][virtualSelectedPortfolio],
        ),
      );
    }
  }, [virtualSelectedPortfolio, price, virtualRemainingBudget]);

  useEffect(() => {
    if (virtualSecondTradeType !== "Buy" && virtualSecondTradeType !== "Sell") {
      if (
        virtualTradeAmount &&
        amountValidation(virtualTradeAmount) &&
        virtualTriggerPrice.value &&
        triggerPriceValidation(virtualTriggerPrice.value)
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    } else {
      if (virtualTradeAmount && amountValidation(virtualTradeAmount)) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    }
  }, [
    virtualTriggerPrice,
    virtualTradeAmount,
    amountValidation,
    triggerPriceValidation,
    virtualSecondTradeType,
  ]);

  return (
    <div>
      <span className={styles.backBtn} onClick={goBackHandler}>
        <FiChevronLeft />
        Back
      </span>
      <div className={styles.assetInfo}>
        <div>
          <h4>Coin Name:</h4>
          <p>
            <img
              alt={virtualSelectedAsset}
              style={{
                width: "20px",
                borderRadius: "100%",
                marginRight: ".2rem",
              }}
              src={`\thttps://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${virtualSelectedAsset}.png`}
            />
            {virtualSelectedAsset}
          </p>
        </div>
        <div>
          <h4>Date:</h4>
          <Loader content={price?.TIME} />
        </div>
        <div>
          <h4>Current Price:</h4>
          <Loader content={`$ ${formatAssetPrice(price?.PRICE)}`} />
        </div>
        {virtualTradeType.toLowerCase() === "sell" && (
          <div>
            <h4>Native Amount:</h4>
            <Loader
              content={`${formatAmount(
                price?.CURRENT_NATIVE_AMOUNT?.[virtualSelectedPortfolio],
              )}`}
            />
          </div>
        )}
        {virtualTradeType === "buy" ? (
          <div>
            <h4>Budget:</h4>
            <p>
              ${" "}
              {parseFloat(
                (Math.floor(virtualRemainingBudget * 100) / 100).toFixed(2),
              ).toLocaleString() || 0}{" "}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      {price?.SYMBOL === virtualSelectedAsset &&
      virtualAssetAmount * price?.PRICE < 1 &&
      virtualTradeType === "sell" ? (
        <div>
          {/*<FaRegComment size={30} />*/}
          <p style={{ margin: "1rem 0", color: "navy", textAlign: "center" }}>
            Sorry, you can't do the sell trade because the amount of asset is
            less than $1. This is the minimum amount required to execute a sell
            order on Cryptocrispy.
          </p>
        </div>
      ) : (
        <>
          <TradeTypeOptions type={"virtual"} />

          <div className={`${!virtualSecondTradeType ? styles.inactive : ""}`}>
            <h3 className={styles.title}>
              <AiFillCaretRight />
              Enter the amount you want to trade.
            </h3>
            <VirtualTradeAmountSetting setIsDone={setIsDone} />
            {virtualSecondTradeType !== "Sell" &&
              virtualSecondTradeType !== "Buy" &&
              virtualSecondTradeType && (
                <div>
                  <h3 className={styles.title}>
                    <AiFillCaretRight />
                    Enter the trigger price you want to set for your limit
                    order.
                  </h3>
                  <VirtualLimitOrderPriceSetting
                    type={
                      virtualSecondTradeType === "take_profit" ||
                      virtualSecondTradeType === "breakout"
                        ? "high"
                        : "low"
                    }
                    setIsDone={setIsDone}
                  />
                </div>
              )}
          </div>

          <div className={styles.setOrderBtn}>
            <Button
              type="primary"
              htmlType="submit"
              className={`${styles.submitBtn} ${
                !isDone ? styles.inactive : ""
              }`}
              // loading={setTradeLoading || limitOrderLoading}
              onClick={orderTradeHandler}
            >
              Place Order
            </Button>
          </div>
        </>
      )}
      <FeeSettingModal
        show={showFeeModal}
        setShow={setShowFeeModal}
        setTrade={orderTradeHandler}
      />
    </div>
  );
}

export default VirtualTradeStep2;

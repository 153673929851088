import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaCaretRight } from "react-icons/fa";
import React from "react";
import CreateBotStepTitle from "./StepTitle";

function CreateBotStep7({ botData, changeBotData }) {
  return (
    <>
      <CreateBotStepTitle title={"Trading Strategy Preferences"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          Select your preferred crypto trading strategies (you can choose
          multiple)
        </h4>
        <ul
          className={styles.options}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            width: "100%",
          }}
        >
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes(
                "Time-based AI-assisted",
              )
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData(
                "selected_trading_strategies",
                "Time-based AI-assisted",
              )
            }
          >
            Time-based AI-assisted (Recommended)
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes("Trend Following")
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData("selected_trading_strategies", "Trend Following")
            }
          >
            Trend Following
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes(
                "Reversal Strategies",
              )
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData(
                "selected_trading_strategies",
                "Reversal Strategies",
              )
            }
          >
            Reversal Strategies
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes(
                "Volatility Management",
              )
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData(
                "selected_trading_strategies",
                "Volatility Management",
              )
            }
          >
            Volatility Management
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes("All Day Trading")
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData("selected_trading_strategies", "All Day Trading")
            }
          >
            All Day Trading
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes(
                "Momentum Strategies",
              )
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData(
                "selected_trading_strategies",
                "Momentum Strategies",
              )
            }
          >
            Momentum Strategies
          </li>
          <li
            className={`${styles.optionItem} ${
              botData.selected_trading_strategies.includes(
                "Breakout Strategies",
              )
                ? styles.selectedOption
                : ""
            }`}
            style={{
              width: "100%",
              maxWidth: "390px",
              margin: "0 auto",
            }}
            onClick={() =>
              changeBotData(
                "selected_trading_strategies",
                "Breakout Strategies",
              )
            }
          >
            Breakout Strategies
          </li>
        </ul>
      </div>
    </>
  );
}

export default CreateBotStep7;

import { Steps } from "antd";
import { BsType } from "react-icons/bs";
import { BiSelectMultiple } from "react-icons/bi";
import { FaChartLine, FaSignal } from "react-icons/fa";
import { PiKeyReturn, PiStrategy } from "react-icons/pi";
import { TbCoins, TbNumber } from "react-icons/tb";
import { GiConfirmed } from "react-icons/gi";

import styles from "../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";

const { Step } = Steps;

const stepsData = [
  {
    icon: <BsType />,
    description: "Naming",
  },
  {
    icon: <BiSelectMultiple />,
    description: "Select exchange",
  },
  {
    icon: <FaChartLine />,
    description: "Risk appetite",
  },
  {
    icon: <PiKeyReturn />,
    description: "Target returns",
  },
  {
    icon: <TbCoins />,
    description: "Allocation",
  },
  {
    icon: <FaSignal />,
    description: "Signal source",
  },
  {
    icon: <PiStrategy />,
    description: "Strategy preference",
  },
  {
    icon: <TbNumber />,
    description: "Number of coin",
  },
  {
    icon: <GiConfirmed />,
    description: "Confirmation",
  },
];

function CreateBotStepsLine({ step, setStep }) {
  return (
    <Steps className={styles.steps} current={step - 1} progressDot={false}>
      {stepsData.map((item, i) => (
        <Step icon={item.icon} key={i} />
      ))}
    </Steps>
  );
}

export default CreateBotStepsLine;

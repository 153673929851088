const titleStyle = {
  fontSize: "1.2rem",
  fontFamily: "Poppins-Medium",
  fontWeight: "400",
  lineHeight: "1.5rem",
  color: "#2B303A",
  marginBottom: "25px",
};

const captionStyle = {
  fontFamily: "Poppins-Medium",
  fontWeight: "400",
  lineHeight: "1.5rem",
  color: "#666666",
  fontSize: "0.875rem",
  minWidth: "fit-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "justify",
};

function AlertSettingStepHeader({ title, caption }) {
  return (
    <div>
      <h4 style={titleStyle}>{title}</h4>
      {caption && <p style={captionStyle}>{caption}</p>}
    </div>
  );
}

export default AlertSettingStepHeader;

import newsIcon from "../../asset/Icons/news(1).gif";

// STYLES
import styles from "../../styles/Components/dashboard/News.module.css";

// COMPONENTS
import { useSelector } from "react-redux";
// import NewsItem from "./NewsItem";
import { useState } from "react";
import { Spin, Tabs } from "antd";
import Modal from "../modal/Modal";
import CountryList from "./CountryNews/CountryList";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { notify } from "../../utility/Toastify/toastify";
import TabPane from "antd/lib/tabs/TabPane";
import ContentContainer from "./ContentContainer";
import last from "../../asset/Icons/last۱.gif";
import DashboardCardTitle from "./DashboardCardTitle";

// A custom component to render each news item
const NewsItem = ({ title, media, date, desc, link }) => {
  return (
    <div className={styles.itemcontainer}>
      <h4 className={styles.itemtitle}>
        <a href={link} target="blink">
          {title.substring(0, 40) + "..."}
        </a>
      </h4>
      <div className={styles.itemsubtitle}>
        <span>{media}</span>
        <span>{date}</span>
      </div>
    </div>
  );
};

// A custom component to render each tab content
const TabContent = ({ news }) => {
  return (
    <div>
      {news.slice(0, 3).map((item, index) => (
        <NewsItem key={index} {...item} />
      ))}
    </div>
  );
};

const News = ({ loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("crypto");
  const [stopSlideShow, setStopSlideShow] = useState(false);

  const { data } = useSelector((state) => state?.dashboardData);

  const timeRef = useRef();
  useEffect(() => {
    if (!stopSlideShow) {
      // Change active tab every 5 seconds
      timeRef.current = setInterval(() => {
        setActiveTab((key) => {
          if (key === "crypto") return "forex";
          if (key === "forex") return "stock";
          if (key === "stock") return "crypto";
        });
      }, 5000);
      // Clear timer on unmount
      return () => clearInterval(timeRef.current);
    }
  }, [activeTab, stopSlideShow]);

  const tabChangeHandler = (key) => {
    // Clear the timer when the user clicks on the tabs
    clearInterval(timeRef.current);

    setActiveTab(key);
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setStopSlideShow(true)}
      onMouseLeave={() => setStopSlideShow(false)}
    >
      <ContentContainer className={styles.content}>
        <div className={styles.header}>
          <DashboardCardTitle text={"Hot News"} icon={newsIcon} />
          {/*<h4>*/}
          {/*  <img*/}
          {/*    src={newsIcon}*/}
          {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
          {/*    alt="news"*/}
          {/*  />*/}
          {/*  Hot News*/}
          {/*</h4>*/}
          {loading && !data?.news_json && <Spin />}
        </div>
        {data?.news_json ? (
          <>
            {Object.values(data.news_json).some((arr) => arr.length > 0) ? (
              <>
                <Tabs
                  tabPosition="left"
                  activeKey={activeTab}
                  onChange={tabChangeHandler}
                >
                  <TabPane tab="crypto" key="crypto">
                    <TabContent news={data.news_json.crypto} />
                  </TabPane>
                  <TabPane tab="forex" key="forex">
                    <TabContent news={data.news_json.forex} />
                  </TabPane>
                  <TabPane tab="stock" key="stock">
                    <TabContent news={data.news_json.stock} />
                  </TabPane>
                </Tabs>

                <div className={styles.btns}>
                  <Link
                    className={styles.link}
                    to="/ai-assistant"
                    onClick={() =>
                      notify(
                        "You are redirectiong to Insights page.",
                        "success",
                      )
                    }
                  >
                    See AI suggestions
                  </Link>
                  <span
                    onClick={() => setShowModal(true)}
                    className={styles.showLink}
                  >
                    Show more
                  </span>
                </div>
              </>
            ) : (
              <p>
                We are working hard to bring you the best news. Please be
                patient and check again later.
              </p>
            )}
            {data.news_json["stock"].length
              ? activeTab === "stock" && <CountryList />
              : ""}
          </>
        ) : (
          ""
        )}
      </ContentContainer>
      <Modal
        title={`Hot News | ${activeTab[0].toUpperCase() + activeTab.slice(1)}`}
        footer={false}
        open={showModal}
        handleOk={() => setShowModal(false)}
        handleCancel={() => setShowModal(false)}
        centered={true}
        content={
          <ul>
            {data.news_json &&
              data?.news_json[activeTab]
                .slice(3)
                .map((item, index) => <NewsItem key={index} {...item} />)}
          </ul>
        }
      />
    </div>
  );
};

export default News;

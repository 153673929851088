// Styles
import { MdNotificationsActive, MdNotificationsOff } from "react-icons/md";
import styles from "../../styles/Components/alert-system/Navbar.module.css";
import { FiChevronDown } from "react-icons/fi";

// Components
import { Menu, Dropdown, Button } from "antd";
import React, { useState } from "react";
import {
  activeAutomaticAlertSystem,
  deactivateAutomaticAlert,
  getAlertStatus,
} from "../../redux/alert-system/actions";
import { useDispatch, useSelector } from "react-redux";
import AIAssistantServiceActivationModal from "../trading-assistant/AIAssistantServiceActivationModal";

function Navbar() {
  const {
    alertSystem: { alertStatus, activeAlertLoading },
    main: { userData },
    profile: { profileData },
  } = useSelector((state) => state);

  // const [status, setStatus] = useState(alertStatus?.STATUS === "True");
  const status = alertStatus?.STATUS === "True";
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const isServiceActive = profileData?.myais_active === "True";

  const dispatch = useDispatch();
  const changeStatus = async (val) => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }
    // setStatus(val);

    if (val) {
      if (alertStatus.STATUS === "True") return;
      dispatch(
        activeAutomaticAlertSystem(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          alertStatus.prefered_alert_time,
          alertStatus.alert_frequency,
          alertStatus.asset_in_danger_watch,
          alertStatus.risk_taking_amount,
          alertStatus.start_time_interval_active,
          alertStatus.end_time_interval_active,
          `${-Math.abs(alertStatus.balance_thresh)}`,
        ),
      ).then(() => {
        dispatch(
          getAlertStatus(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
          ),
        );
        // setStatus(alertStatus.STATUS == "True" ? true : false);
      });
    } else {
      if (alertStatus.STATUS === "False") return;
      dispatch(
        deactivateAutomaticAlert(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      ).then(() => {
        dispatch(
          getAlertStatus(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
          ),
        );
      });
    }
  };

  // Define the menu items
  const menu = (
    <Menu onClick={(e) => changeStatus(e.key === "1")}>
      <Menu.Item key="1">
        <MdNotificationsActive
          style={{ color: "#0C4CFC", marginRight: ".3rem" }}
        />
        Enabled
      </Menu.Item>
      <Menu.Item key="2">
        <MdNotificationsOff
          style={{ color: "#0C4CFC", marginRight: ".3rem" }}
        />
        Disabled
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={styles.container}>
      <Dropdown overlay={menu}>
        <Button loading={activeAlertLoading}>
          Status ( {status ? "Enabled" : "Disabled"}
          {status ? (
            <MdNotificationsActive style={{ color: "#0C4CFC" }} size={18} />
          ) : (
            <MdNotificationsOff style={{ color: "#0C4CFC" }} size={18} />
          )}
          ) <FiChevronDown />
        </Button>
      </Dropdown>

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />
    </div>
  );
}

export default Navbar;

import "../../styles/Components/faq/faqPublic.css";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import FaqItem from "./general/FaqItem";

const questions = [
  {
    question: "How does the integration with exchanges work?",
    answer:
      "Our integration with exchanges is a seamless process that allows users to import their portfolio data, trading history, and assets from various exchanges. You can connect with up to 100 different exchanges, with the top 10 exchanges being verified for added security and reliability. ",
  },
  {
    question: "Can I trade on my integrated exchanges using AI Assistant?",
    answer:
      "Yes, with our AI Assistant, you can trade directly on your integrated exchanges. It provides a user-friendly interface for executing trades, managing your portfolio, and monitoring market conditions in real time. Whether you prefer manual or automated bot trading, AI Assistant and bots offer the flexibility you need.",
  },
];

function BotFaq() {
  const navigate = useNavigate();

  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>

      <h3 className="helpTitle">Integration Frequently Asked Questions</h3>

      {questions.map((faq, index) => (
        <FaqItem key={index} {...faq} id={index} />
      ))}
    </div>
  );
}

export default BotFaq;

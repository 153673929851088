import React, { useEffect, useState } from "react";
import styles from "../../styles/Components/players/videoPlayer.module.sass";
import ReactPlayer from "react-player";
import { motion } from "framer-motion/dist/framer-motion";
import { GrClose } from "react-icons/gr";

const VideoPlayer = ({ show = false, hide, url, title, clickHandler }) => {
  return (
    <motion.div
      className={`${styles.vedioPlayerWrapper} ${!show ? styles.hide : ""}`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      style={{ zIndex: "10" }}
    >
      <span className={styles.closeBtn} onClick={() => hide(false)}>
        <GrClose onClick={clickHandler} />
      </span>
      {/*<h1>{title}</h1>*/}
      <ReactPlayer
        width={window.innerWidth <= 1200 ? "100%" : 1200}
        height={"auto"}
        playing={show}
        url={url}
        controls
      />
    </motion.div>
  );
};

export default VideoPlayer;

import ShareButtons from "../../../pages/pricing-plan/ShareButtons";
import { message, Modal, Spin } from "antd";
import { useEffect } from "react";
import { getReferralCode } from "../../../redux/pricing-plan/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../shared/Loader";
import { MdOutlineContentCopy } from "react-icons/md";

function InviteFriendsModal({ show, setShow, link }) {
  const {
    main: { userData },
    pricingPlan: { referralCode, referralCodeLoading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const invitationLink = `https://t.me/cryptocrispy_bot?start=${referralCode}`;

  const copyCodeHandler = () => {
    navigator.clipboard.writeText(referralCode);
    message.success(`Copied referral code to clipboard.`);
  };

  useEffect(() => {
    if (!referralCode && !link) {
      if (userData?.email)
        dispatch(
          getReferralCode(
            userData?.email,
            userData?.["custom:custom_username"],
          ),
        );
    }
  }, [dispatch, link, referralCode, userData]);

  return (
    <Modal open={show} onCancel={() => setShow(false)} centered footer={null}>
      {referralCodeLoading && <Loader title={"Please wait"} height={130} />}
      {!referralCodeLoading && referralCode ? (
        <>
          <ShareButtons link={link || invitationLink} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <h4 style={{ marginBottom: "0" }}>Referral Code:</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".2rem",
                cursor: "pointer",
              }}
              onClick={copyCodeHandler}
            >
              <p style={{ marginBottom: "0" }}>{referralCode}</p>
              <MdOutlineContentCopy />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </Modal>
  );
}

export default InviteFriendsModal;

import { Button, Result } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useNavigate } from "react-router";

function AuthAlert({ mode, msg, code, show, setShow }) {
  const navigate = useNavigate();
  return (
    <Modal
      open={show}
      closable={false}
      onCancel={undefined}
      centered
      width={400}
      footer={null}
    >
      <Result
        status="error"
        // title={msg}
        subTitle={<h3>{msg}</h3>}
        extra={[
          mode ? (
            <Button
              type="primary"
              style={{ backgroundColor: "#2d4df8" }}
              key="login"
              onClick={() => {
                navigate("/auth/login");
                setShow(false);
              }}
            >
              Login
            </Button>
          ) : (
            ""
          ),
          code === 423 || code === 215 ? (
            <Button
              type="primary"
              style={{ backgroundColor: "#2d4df8", border: "none" }}
              key="login"
              onClick={() => {
                navigate("/auth/signup");
                setShow(false);
              }}
            >
              Signup
            </Button>
          ) : (
            ""
          ),
          <Button key="close" onClick={() => setShow(false)}>
            Close
          </Button>,
        ]}
      ></Result>
    </Modal>
  );
}

export default AuthAlert;

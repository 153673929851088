import { Spin } from "antd";
import React from "react";

function StatusLoader({ text, style }) {
  return (
    <Spin
      style={{ display: "flex", gap: "5px", alignItems: "center", ...style }}
      size={"small"}
      tip={text}
    />
  );
}

export default StatusLoader;

import { Modal, List, Button } from "antd";
import styles from "../../../styles/Components/bot/bot-configuration/BotDetailsModal.module.css";
import { FaRobot, FaChartPie, FaClock, FaCoins } from "react-icons/fa";
import { PiVirtualReality } from "react-icons/pi";
import { FaRegTrashCan, FaScaleBalanced } from "react-icons/fa6";
import { IoMdReturnLeft } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  activateBot,
  checkBotStatus,
  deactivateBot,
  deleteBot,
} from "../../../redux/bot/actions";
import alert from "../../alerts/Alert";
import { riskOptions } from "../../../utility/data";
import { useState } from "react";
import DisplayBotStatus from "../../../shared/bot-activation-process/DisplayBotStatus";

function BotDetailsModal({ show, setShow, data }) {
  const {
    botState: { activateBotLoading, deactivateBotLoading, deleteBotLoading },
    main: { userData },
  } = useSelector((state) => state);

  const [showBotActivationModal, setShowBotActivationModal] = useState(false);

  const connectedExchange = data && data?.bot_configurations?.selected_exchange;

  const dispatch = useDispatch();

  const dataSource = [
    // {
    //   title: "Trade in Virtual Portfolio",
    //   icon: <PiVirtualReality size={20} color={"#3dc1d3"} />,
    //   value:
    //     data?.bot_configurations?.trade_in_virtual_portfolio === "False"
    //       ? "No"
    //       : "Yes",
    // },

    {
      title: "Risk Tolerance",
      icon: <FaScaleBalanced size={20} color={"#c44569"} />,
      value: riskOptions[data?.bot_configurations?.risk_tolerance - 1],
    },
    {
      title: "Target Return Percentage",
      icon: <IoMdReturnLeft color={"#44bd32"} size={20} />,
      value: data?.bot_configurations?.target_return_percentage + "%",
    },
    // {
    //   title: "Bot Name",
    //   icon: <FaRobot />,
    //   value: data.bot_configurations.bot_name,
    // },
    // {
    //   title: "Selected Exchange",
    //   icon: <FaExchangeAlt color={"#16a085"} size={20} />,
    //   value: data?.bot_configurations?.selected_exchange,
    // },
    {
      title: "Crypto Allocation Strategy",
      icon: <FaChartPie color={"#EF4040"} size={20} />,
      value: data?.bot_configurations?.crypto_allocation_strategy,
    },
    // {
    //   title: "Bot Status",
    //   icon: <FaInfo size={20} />,
    //   value: data?.bot_status,
    // },
    {
      title: "Selected Trading Strategies",
      icon: <FaClock color={"#B6BBC4"} size={20} />,
      value: data?.bot_configurations?.selected_trading_strategies.join(", "),
    },
    {
      title: "Number of Coins",
      icon: <FaCoins color={"#FFB534"} size={20} />,
      value: data?.bot_configurations?.number_of_coins,
    },
    // {
    //   title: "Signal Source",
    //   icon: <FaSignal color={"#0984e3"} size={20} />,
    //   value: data?.bot_configurations?.signal_source.replace("_", " "),
    // },
    // {
    //   title: "AI Assistant Mode",
    //   icon: <GoDependabot color={"#f78fb3"} size={20} />,
    //   value:
    //     data?.bot_configurations?.ai_assistant_mode === "default"
    //       ? "All Categories"
    //       : formatAIMode[data?.bot_configurations?.ai_assistant_mode],
    // },
  ];

  const handleActiveBot = async () => {
    if (connectedExchange === "None") {
      return alert(
        "",
        "You cannot activate this bot because it is not integrated with any exchange.",
        "info",
        {
          cancel: "Ok",
        },
      );
    }
    if (deleteBotLoading) return;
    dispatch(
      checkBotStatus(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        data?.CreatedDate,
        data?.bot_configurations?.selected_exchange,
      ),
    );
    setShowBotActivationModal(true);
    // await dispatch(
    //   activateBot(
    //     userData?.["custom:custom_username"],
    //     userData?.["cognito:username"],
    //     data?.CreatedDate,
    //   ),
    // ).then(() => {
    //   setShow(false);
    // });
  };

  const handleDeactiveBot = async () => {
    if (deleteBotLoading) return;
    await dispatch(
      deactivateBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        data?.CreatedDate,
      ),
    ).then(() => {
      setShow(false);
    });
  };

  const handleDeleteBot = async () => {
    await dispatch(
      deleteBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        data?.CreatedDate,
      ),
    ).then(() => {
      setShow(false);
    });
  };

  return (
    <Modal
      title={
        <span className={styles.title}>
          <FaRobot size={25} color={"#0C4CFC"} /> Bot Details -{" "}
          {data?.bot_configurations?.bot_name}
        </span>
      }
      centered
      footer={null}
      open={show}
      width={450}
      onCancel={() => setShow(false)}
      children={
        <>
          <List
            className={styles.list}
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.icon}
                  title={item.title}
                  description={item.value}
                />
              </List.Item>
            )}
          />
          <div className={styles.actionBtns}>
            <Button onClick={() => setShow(false)}>Close</Button>
            {data?.bot_status === "inactive" && (
              <Button
                className={styles.activateBtn}
                type={"primary"}
                loading={activateBotLoading}
                onClick={handleActiveBot}
              >
                Activate
              </Button>
            )}
            {data?.bot_status !== "inactive" && (
              <Button
                className={styles.activateBtn}
                loading={deactivateBotLoading}
                type={"primary"}
                onClick={handleDeactiveBot}
              >
                Deactivate
              </Button>
            )}

            <Button
              className={styles.deleteBtn}
              onClick={handleDeleteBot}
              loading={deleteBotLoading}
            >
              <FaRegTrashCan />
              Delete bot
            </Button>
          </div>

          <DisplayBotStatus
            show={showBotActivationModal}
            onClose={setShowBotActivationModal}
            botId={data?.CreatedDate}
            exchangeId={data?.bot_configurations?.selected_exchange}
          />
        </>
      }
    />
  );
}

export default BotDetailsModal;

import styles from "../../../styles/Components/integration/History/ExchangeHistoryTableExpandedRow.module.css";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { IoCheckmarkDoneCircle, IoCloseCircle } from "react-icons/io5";

const getLastOrders = (data) => {
  let lastTakeProfit = null;
  let lastStopLoss = null;
  let lastTriggeredSellOrder = null;

  for (let i = data?.length - 1; i >= 0; i--) {
    if (lastTakeProfit === null && data[i]["Take Profit Limit Order"]) {
      lastTakeProfit = data[i]["Take Profit Limit Order"];
    }
    if (lastStopLoss === null && data[i]["Stop Loss Limit Order"]) {
      lastStopLoss = data[i]["Stop Loss Limit Order"];
    }
    if (lastTriggeredSellOrder === null && data[i]["Triggered Sell Order"]) {
      lastTriggeredSellOrder = data[i]["Triggered Sell Order"];
    }
    if (lastTakeProfit && lastStopLoss) break;
  }

  return { lastTakeProfit, lastStopLoss, lastTriggeredSellOrder };
};

function ComprehensiveHistoryTableExpandedRow({ data }) {
  const { lastTakeProfit, lastStopLoss, lastTriggeredSellOrder } =
    getLastOrders(data?.info?.Details);
  const isTakeProfitSuccessfully = lastTakeProfit
    ?.toLowerCase()
    ?.includes("success");
  const isStopLossSuccessfully = lastStopLoss
    ?.toLowerCase()
    ?.includes("success");
  const islastSellOrderSuccessfully = lastTriggeredSellOrder
    ?.toLowerCase()
    ?.includes("success");

  return (
    <div className={styles.container}>
      <ul className={styles.dataList}>
        <li>
          <span className={styles.label}>Trade ID:</span>
          <span>{data?.info?.["Trade ID"]}</span>
        </li>
        <li>
          <span className={styles.label}>Date:</span>
          <span>{getRelativeTime(data?.date)}</span>
        </li>
        <li>
          <span className={styles.label}>Trading Duration</span>
          <span>{data?.info?.["Trading Duration"]}</span>
        </li>
        <li className={styles.smSize}>
          <span className={styles.label}>Trader:</span>
          <span>{data?.trader}</span>
        </li>
        <li className={styles.mdSize}>
          <span className={styles.label}>Amount:</span>
          <span>{data?.amount}</span>
        </li>
        <li className={styles.mdSize}>
          <span className={styles.label}>Status:</span>
          <span>{data?.status}</span>
        </li>
        <li className={styles.lgSize}>
          <span className={styles.label}>Position:</span>
          <span>Long</span>
        </li>
        <li className={styles.xlSize}>
          <span className={styles.label}>Entry Price:</span>
          <span>{data?.entryPrice}</span>
        </li>
        <li className={styles.xlSize}>
          <span className={styles.label}>Exit Price:</span>
          <span>{data?.exitPrice}</span>
        </li>
        {lastTriggeredSellOrder && (
          <li
            className={`${!islastSellOrderSuccessfully ? styles.failedOrder : ""}`}
          >
            <span className={styles.label}>Triggered Sell Order:</span>
            <span className={`${styles.limitOrderStatus}`}>
              {!islastSellOrderSuccessfully && <IoCloseCircle color={"red"} />}
              {islastSellOrderSuccessfully && (
                <IoCheckmarkDoneCircle color={"green"} />
              )}
              {lastTriggeredSellOrder}
            </span>
          </li>
        )}
        {lastTakeProfit && (
          <li
            className={`${!isTakeProfitSuccessfully ? styles.failedOrder : ""}`}
          >
            <span className={styles.label}>Last Take Profit Limit Order:</span>
            <span className={`${styles.limitOrderStatus}`}>
              {!isTakeProfitSuccessfully && <IoCloseCircle color={"red"} />}
              {isTakeProfitSuccessfully && (
                <IoCheckmarkDoneCircle color={"green"} />
              )}
              {lastTakeProfit}
            </span>
          </li>
        )}
        {lastStopLoss && (
          <li
            className={`${!isStopLossSuccessfully ? styles.failedOrder : ""}`}
          >
            <span className={styles.label}>Last Stop Loss Limit Order:</span>
            <span className={`${styles.limitOrderStatus}`}>
              {!isStopLossSuccessfully && <IoCloseCircle color={"red"} />}
              {isStopLossSuccessfully && (
                <IoCheckmarkDoneCircle color={"green"} />
              )}
              {lastStopLoss}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ComprehensiveHistoryTableExpandedRow;

import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import { FaCheckCircle } from "react-icons/fa";
import ShowRecommendation from "../ShowRecommendation";

function VirtualLimitOrderResult({ setShow }) {
  const {
    tradingAssistant: { limitOrderLoading },
  } = useSelector((state) => state);
  return (
    <div className={styles.successBox}>
      {limitOrderLoading ? (
        <Loader text={"Setting the order..."} />
      ) : (
        // <>
        //   <FaCheckCircle color={"#1abc9c"} size={30} />
        //   <p style={{ textAlign: "center", color: "rgba(0,0,0,.85)" }}>
        //     Congratulations, your order trade has been successfully done.
        //   </p>
        // </>
        <ShowRecommendation showCongrateMsg setShow={setShow} />
      )}
    </div>
  );
}

export default VirtualLimitOrderResult;

import DashboardStatusBox from "./DashboardStatusBox";
import { FaMoneyBill } from "react-icons/fa";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../utility/helper";
import styles from "../../../styles/Components/dashboard/statusbar/ReferralsInfo.module.css";
import WithdrawButton from "../../pricing-plan/referrals/WithdrawButton";
import { MdPersonAddAlt1 } from "react-icons/md";
import { useState } from "react";
import InviteFriendsModal from "../../mini-app/mini-pricing/InviteFriendsModal";

function ReferralsInfo() {
  const {
    pricingPlan: { referralRewardsLoading, referralData },
  } = useSelector((state) => state);

  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const totalMoney = referralData?.total_cashable_credit;
  const totalCredit = referralData?.total_credit_reward;

  return (
    <DashboardStatusBox
      title={"Referrals"}
      icon={<FaMoneyBill />}
      path={"/pricing-billing/rewards"}
      loading={referralRewardsLoading}
      subIcon={
        <MdPersonAddAlt1
          className={styles.addIcon}
          size={20}
          title={"Add more friends"}
          onClick={() => setShowInvitationModal(true)}
        />
      }
    >
      <div className={styles.infoBox}>
        <span>Earned Money:</span>
        <span>{totalCredit && `$${formatAmount(totalMoney)}`}</span>
        <WithdrawButton className={styles.withdrawBtn} />
      </div>

      <div className={styles.infoBox}>
        <span>Earned Credit:</span>
        <span>{totalCredit && `$${formatAmount(totalCredit)}`}</span>
      </div>

      {showInvitationModal && (
        <InviteFriendsModal
          show={showInvitationModal}
          setShow={setShowInvitationModal}
        />
      )}
    </DashboardStatusBox>
  );
}

export default ReferralsInfo;

import axios from "axios";
import {
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST,
  ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS,
  GET_ALERT_STATUS_ERROR,
  GET_ALERT_STATUS_REQUEST,
  GET_ALERT_STATUS_SUCCESS,
  GET_PREVIOUS_EMAILS_ERROR,
  GET_PREVIOUS_EMAILS_REQUEST,
  GET_PREVIOUS_EMAILS_SUCCESS,
  GET_TELEGRAM_BOT_TOKEN_ERROR,
  GET_TELEGRAM_BOT_TOKEN_REQUEST,
  GET_TELEGRAM_BOT_TOKEN_SUCCESS,
  GET_TRACKED_SIGNALS_ERROR,
  GET_TRACKED_SIGNALS_REQUEST,
  GET_TRACKED_SIGNALS_SUCCESS,
  SET_TRACK_SIGNALS_SUCCESS,
  TRACK_SIGNALS_ERROR,
  TRACK_SIGNALS_REQUEST,
} from "./consts";
import { notify } from "../../utility/Toastify/toastify";
import { errorHandler } from "../../utility/helper";
import alert from "../../components/alerts/Alert";
import { axiosErrorHandler } from "../../utility/action-helper";
import { GET_FAST_DASHBOARD_DATA_ERROR } from "../dashboard/consts";

export const setTrackSignal =
  (email, user, category, name, subcategory, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: TRACK_SIGNALS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        email,
        user,
        request_type: "set_signal_to_track",
        category,
        subcategory,
        name,
      })
      .then((res) => {
        dispatch({ type: SET_TRACK_SIGNALS_SUCCESS, payload: res?.data });
        notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            setTrackSignal(
              email,
              user,
              category,
              name,
              subcategory,
              retryCount + 1,
            ),
          TRACK_SIGNALS_ERROR,
          user,
          email,
          "set_signal_to_track",
        );
        // dispatch({ type: TRACK_SIGNALS_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const activeAutomaticAlertSystem =
  (
    email,
    user,
    prefered_alert_time,
    alert_frequency,
    asset_in_danger_watch,
    risk_taking_amount,
    start_time_interval_active,
    end_time_interval_active,
    balance_thresh,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "activate_automatic_alert_system",
        prefered_alert_time,
        alert_frequency,
        asset_in_danger_watch,
        risk_taking_amount,
        start_time_interval_active,
        end_time_interval_active,
        balance_thresh,
      })
      .then((res) => {
        dispatch({
          type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS,
          payload: res?.data,
        });
        alert(null, res.data.MESSAGE, "success", {
          cancel: "Ok",
        });
        // notify(res.data.MESSAGE, "success");
        // notify("Congratulations! You have successfully customized your alert settings.", "success")
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            activeAutomaticAlertSystem(
              email,
              user,
              prefered_alert_time,
              alert_frequency,
              asset_in_danger_watch,
              risk_taking_amount,
              start_time_interval_active,
              end_time_interval_active,
              balance_thresh,
              retryCount + 1,
            ),
          ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
          user,
          email,
          "activate_automatic_alert_system",
        );
        // dispatch({
        //   type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
        //   payload: err?.response,
        // });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };
export const disableTrackSignal =
  (email, user, feature_key, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: TRACK_SIGNALS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "disable_signal_to_track",
        feature_key,
      })
      .then((res) => {
        dispatch({ type: SET_TRACK_SIGNALS_SUCCESS, payload: res?.data });
        notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => disableTrackSignal(email, user, feature_key, retryCount + 1),
          TRACK_SIGNALS_ERROR,
          user,
          email,
          "disable_signal_to_track",
        );
        // dispatch({ type: TRACK_SIGNALS_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };
export const getTrackedSignal =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_TRACKED_SIGNALS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "get_signals_to_track_list",
      })
      .then((res) => {
        dispatch({ type: GET_TRACKED_SIGNALS_SUCCESS, payload: res?.data });
        notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getTrackedSignal(email, user, retryCount + 1),
          GET_TRACKED_SIGNALS_ERROR,
          user,
          email,
          "get_signals_to_track_list",
        );
        // dispatch({ type: GET_TRACKED_SIGNALS_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };
export const getAlertStatus =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_ALERT_STATUS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "get_alert_system_status",
      })
      .then((res) => {
        dispatch({ type: GET_ALERT_STATUS_SUCCESS, payload: res?.data });
        notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getAlertStatus(email, user, retryCount + 1),
          GET_ALERT_STATUS_ERROR,
          user,
          email,
          "get_alert_system_status",
        );

        // dispatch({ type: GET_TRACKED_SIGNALS_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const deactivateAutomaticAlert =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "deactivate_automatic_alert_system",
      })
      .then((res) => {
        dispatch({
          type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS,
          payload: res?.data,
        });
        alert(null, res.data.MESSAGE, "success", {
          cancel: "Ok",
        });
        // notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deactivateAutomaticAlert(email, user, retryCount + 1),
          ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
          user,
          email,
          "deactivate_automatic_alert_system",
        );
        // dispatch({
        //   type: ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR,
        //   payload: err?.response,
        // });
      });
  };
export const getPreviousEmails =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_TRACKED_SIGNALS_REQUEST });
    dispatch({ type: GET_PREVIOUS_EMAILS_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "alert_system",
        user,
        email,
        request_type: "get_latest_alert_emails",
      })
      .then((res) => {
        dispatch({ type: GET_PREVIOUS_EMAILS_SUCCESS, payload: res?.data });
        notify(res.data.MESSAGE, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getPreviousEmails(email, user, retryCount + 1),
          GET_PREVIOUS_EMAILS_ERROR,
          user,
          email,
          "get_latest_alert_emails",
        );
        // dispatch({ type: GET_TRACKED_SIGNALS_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getTelegramBotToken =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_TELEGRAM_BOT_TOKEN_REQUEST });

    await axios
      .post(
        "https://0znr1xesf9.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "login",
          email,
          user,
        },
      )
      .then((res) => {
        const {
          accessToken: { jwtToken: token },
        } = res?.data;
        dispatch({ type: GET_TELEGRAM_BOT_TOKEN_SUCCESS, payload: token });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getTelegramBotToken(email, user, retryCount + 1),
          GET_TELEGRAM_BOT_TOKEN_ERROR,
          user,
          email,
          "login",
        );
      });
  };

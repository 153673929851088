import {
  GET_PROFILE_DATA_ERROR,
  GET_PROFILE_DATA_REQUEST,
  GET_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_ATTRIBUTE,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "./consts";

const initialState = {
  loading: false,
  profileData: [],
  error: "",
  updateLoading: false,
};
export const profileDateReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action?.payload,
      };
    case GET_PROFILE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateLoading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateLoading: false,
      };

    case UPDATE_PROFILE_ATTRIBUTE:
      const {
        payload: { attribute, value },
      } = action;
      return {
        ...state,
        profileData: {
          ...state.profileData,
          [attribute]: value,
        },
      };

    default:
      return state;
  }
};

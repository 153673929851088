// useSubscriptionPlans.js

import { useSelector } from "react-redux";
import {
  getSpecificSubscription,
  getActiveSubscriptions,
  getLastCanceledSubscription,
} from "../utility/helper"; // Update with actual path
import { FaCrown } from "react-icons/fa";
import { formattedSubscription } from "../utility/formatter-helper";

const useSubscriptionPlans = () => {
  const {
    pricingPlan: { subscriptions },
  } = useSelector((state) => state);

  const proSubscriptions = getSpecificSubscription(subscriptions, "pro");
  const basicSubscriptions = getSpecificSubscription(subscriptions, "basic");

  const activeProSubscription = getActiveSubscriptions(proSubscriptions)?.length
    ? getActiveSubscriptions(proSubscriptions)[0]
    : null;
  const activeBasicSubscription = getActiveSubscriptions(basicSubscriptions)
    ?.length
    ? getActiveSubscriptions(basicSubscriptions)[0]
    : null;

  const lastCanceledPro = !activeProSubscription
    ? getLastCanceledSubscription(proSubscriptions)
    : null;

  const lastCanceledBasic = !activeBasicSubscription
    ? getLastCanceledSubscription(basicSubscriptions)
    : null;

  const proPlan =
    formattedSubscription[activeProSubscription] ||
    formattedSubscription[lastCanceledPro];

  const proPlanType = activeProSubscription || lastCanceledPro;

  const proPlanData =
    subscriptions[activeProSubscription] || subscriptions[lastCanceledPro];

  const basicPlan =
    formattedSubscription[activeBasicSubscription] ||
    formattedSubscription[lastCanceledBasic];

  const basicPlanData =
    subscriptions[activeBasicSubscription] || subscriptions[lastCanceledBasic];

  const basicPlanType = activeBasicSubscription || lastCanceledBasic;

  const hasProPlan =
    subscriptions?.monthly_pro ||
    subscriptions?.daily_pro ||
    subscriptions?.yearly_pro;

  const hasBasicPlan =
    subscriptions?.monthly || subscriptions?.daily || subscriptions?.yearly;

  const hasActivePro = activeProSubscription;
  const hasActiveBasic = activeBasicSubscription;

  const hasPlan = hasProPlan || hasBasicPlan;

  const basicIcon = <FaCrown color={"#CD7F32"} />;
  const proIcon = <FaCrown color={"#C0C0C0"} />;

  const proColor = "#C0C0C0";
  const basicColor = "#CD7F32";

  return {
    proPlan,
    proPlanData,
    basicPlan,
    basicPlanData,
    hasProPlan,
    hasBasicPlan,
    hasPlan,
    basicIcon,
    proIcon,
    proColor,
    basicColor,
    hasActivePro,
    hasActiveBasic,
    proPlanType,
    basicPlanType,
  };
};

export default useSubscriptionPlans;

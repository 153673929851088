import { Table } from "antd";
import styles from "../../../../styles/Components/tables/portfolioTable.module.sass";
import { formatCustomDate, formatPrice } from "../../../../utility/helper";

function TradeReportTable({ data }) {
  const columns = [
    {
      title: "Date (UTC)",
      dataIndex: "created",
      key: "created",
      align: "center",
      render: (text) => {
        return <span>{formatCustomDate(text, "show")}</span>;
      },
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
      key: "order_type",
      align: "center",
    },
    { title: "Size", dataIndex: "size", key: "size", align: "center" },
    {
      title: "Price (USD)",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (text) => <span>{formatPrice(+text, 3)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => (
        <span style={{ color: `${text === "Filled" ? "#16a085" : "inherit"}` }}>
          {text}
        </span>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={[...data].sort(
        (a, b) => new Date(b.created) - new Date(a.created),
      )}
      pagination={false}
      height={500}
      scroll={{ y: 500 }}
      style={{ marginTop: "1rem" }}
      className={`${styles.table} bot-table`}
    />
  );
}

export default TradeReportTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStopLossTriggerPrice,
  setStopLossTriggerPricePercent,
  setTakeProfitTriggerPrice,
  setTakeProfitTriggerPricePercent,
  setTriggerPrice,
} from "../../../../redux/trade/actions";
import { extractCoinName } from "../../../../utility/tradeHelper";
import styles from "../../../../styles/shared/trade-modal/LimitOrderPriceSettintg.module.css";
import { InputNumber, Slider } from "antd";

function SetVirtualLimitPrice({ type, className, setIsDone }) {
  const {
    tradeState: {
      triggerPrice,
      stopLossOrderTriggerPrice,
      takeProfitOrderTriggerPrice,
      modalMode: mode,
      basicTradeData,
    },
    tradingAssistant: { price },
    profile: {
      profileData: { transaction_fee: fee },
    },
  } = useSelector((state) => state);
  const { stopLossPercent, takeProfitPercent } = basicTradeData;

  const [sliderValue, setSliderValue] = useState(type === " high" ? 8 : 1);
  const [highError, setHighError] = useState(false);
  const [lowError, setLowError] = useState(false);

  const dispatch = useDispatch();

  const handlePriceInputChange = (value) => {
    // Update price input value
    dispatch(setTriggerPrice(value));

    const newSliderValue = (parseFloat(value) / price?.PRICE - 1) * 100;
    setSliderValue(newSliderValue);

    if (type === "high") {
      // dispatch(setTakeProfitAmountPercent(newSliderValue));
      dispatch(setTakeProfitTriggerPrice(value));
    }
    if (type === "low") {
      // dispatch(setStopLossAmountPercent(newSliderValue));
      dispatch(setStopLossTriggerPrice(value));
    }
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    // Calculate new price based on slider's percentage value and update the price state
    const newPrice =
      type === "high"
        ? price?.PRICE * (1 + parseFloat(value) / 100)
        : price?.PRICE * (1 - parseFloat(value) / 100);
    // Update price input value
    // dispatch(setTriggerPrice(newPrice));

    if (type === "high") {
      // dispatch(setTakeProfitAmountPercent(value));
      dispatch(setTakeProfitTriggerPrice(newPrice));
    }
    if (type === "low") {
      // dispatch(setStopLossAmountPercent(value));
      dispatch(setStopLossTriggerPrice(newPrice));
    }
  };

  useEffect(() => {
    if (
      (stopLossOrderTriggerPrice > 0 || takeProfitOrderTriggerPrice > 0) &&
      sliderValue > 0
    ) {
      if (type === "high") {
        // console.log("high check");
        if (
          takeProfitOrderTriggerPrice >=
            Number(price?.PRICE) * (1 + (Number(fee) || 0.001)) &&
          takeProfitOrderTriggerPrice <=
            Number(price?.PRICE * (1 + 99.99 / 100))
        ) {
          // console.log("done");
          setIsDone(true);
          setHighError(false);
        } else {
          setIsDone(false);
          setHighError(true);
        }
      }

      if (type === "low") {
        // console.log(
        //   stopLossOrderTriggerPrice,
        //   price?.PRICE,
        //   price?.PRICE * (1 - 99.99 / 100),
        // );
        // console.log(fee, price?.PRICE * (1 - fee));
        if (
          stopLossOrderTriggerPrice >= price?.PRICE * (1 - 99.99 / 100) &&
          stopLossOrderTriggerPrice <=
            price?.PRICE * (1 - (Number(fee) || 0.001))
        ) {
          // console.log("low done");
          setIsDone(true);
          setLowError(false);
        } else {
          setIsDone(false);
          setLowError(true);
        }
      }
    }
  }, [
    triggerPrice,
    sliderValue,
    stopLossOrderTriggerPrice,
    takeProfitOrderTriggerPrice,
    type,
    price,
    fee,
    setIsDone,
  ]);

  useEffect(() => {
    if (type === "high") {
      if (mode === "ai-suggestion") {
        setSliderValue(Number(takeProfitPercent));
      } else {
        setSliderValue(8);
      }
      dispatch(
        setTakeProfitTriggerPrice(price?.PRICE * (1 + sliderValue / 100)),
      );
      // dispatch(setTakeProfitAmountPercent(8));
    }
    if (type === "low") {
      if (mode === "ai-suggestion") {
        setSliderValue(Number(stopLossPercent));
      } else {
        setSliderValue(1);
      }
      dispatch(setStopLossTriggerPrice(price?.PRICE * (1 - sliderValue / 100)));
      // dispatch(setStopLossAmountPercent(1));
    }
  }, [type]);

  useEffect(() => {
    if (price) {
      if (type === "high") {
        dispatch(
          setTakeProfitTriggerPrice(price?.PRICE * (1 + sliderValue / 100)),
        );
      }

      if (type === "low") {
        dispatch(
          setStopLossTriggerPrice(price?.PRICE * (1 - sliderValue / 100)),
        );
      }
    }
  }, [price]);

  useEffect(() => {
    if (type === "low") {
      dispatch(setStopLossTriggerPricePercent(sliderValue));
    }
    if (type === "high") {
      dispatch(setTakeProfitTriggerPricePercent(sliderValue));
    }
  }, [dispatch, sliderValue, type]);

  return (
    <div className={`${styles.container} ${className ? className : ""}`}>
      <InputNumber
        min={
          type === "high"
            ? Number(price?.PRICE) * (1 + (Number(fee) || 0.001))
            : price?.PRICE * (1 - 99.99 / 100)
        }
        max={
          type === "high"
            ? Number(price?.PRICE * (1 + 99.99 / 100))
            : price?.PRICE * (1 - (Number(fee) || 0.001))
        }
        status={
          (type === "high" && highError && "error") ||
          "" ||
          (type === "low" && lowError && "error") ||
          ""
        }
        step={price?.PRICE / 10000}
        value={
          type === "high"
            ? takeProfitOrderTriggerPrice
            : stopLossOrderTriggerPrice
        }
        onChange={handlePriceInputChange}
        formatter={(value) => `$${value}`}
        parser={(value) => value.replace("$", "")}
        className={styles.input}
      />
      <div className={styles.sliderBox}>
        <Slider
          min={Number((Number(fee) || 0.001) * 100)}
          max={99.99}
          step={0.01}
          onChange={handleSliderChange}
          value={typeof sliderValue === "number" ? sliderValue : 0}
          tooltip={{ formatter: (value) => `${value.toFixed(2)}%` }}
          style={{ width: "70%" }}
        />
        <InputNumber
          min={Number((Number(fee) || 0.001) * 100)}
          max={99.99}
          status={
            (type === "high" && highError && "error") ||
            "" ||
            (type === "low" && lowError && "error") ||
            ""
          }
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={sliderValue}
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {mode === "ai-suggestion" &&
        type === "high" &&
        sliderValue === Number(takeProfitPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "high" &&
        takeProfitPercent &&
        sliderValue !== Number(takeProfitPercent) && (
          <p
            onClick={() => setSliderValue(Number(takeProfitPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "low" &&
        sliderValue === Number(stopLossPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "low" &&
        stopLossPercent &&
        sliderValue !== Number(stopLossPercent) && (
          <p
            onClick={() => setSliderValue(Number(stopLossPercent))}
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {type === "high" && highError && (
        <p className={styles.errorMessage}>
          The trigger price should be higher than the current price!
        </p>
      )}
      {type === "low" && lowError && (
        <p className={styles.errorMessage}>
          The trigger price should be lower than the current price!
        </p>
      )}
    </div>
  );
}

export default SetVirtualLimitPrice;

import React from "react";
import Chart from "react-apexcharts";
import { Empty, Spin } from "antd";

const getLabel = (item) => {
  if (item?.SYMBOL_NAME) {
    return `${item?.SYMBOL_NAME}-${item?.MARKET_TYPE}`;
  } else {
    const marketType = item?.assetName !== "USDT" ? "-Crypto" : "";
    return `${item?.assetName}${marketType}`;
  }
};

const PieChart = ({ chartData, loading }) => {
  let data = {
    series: chartData
      ? chartData?.map(
          (item) => item?.USD_AMOUNTS || Number(item?.availableBalanceUSD),
        )
      : [100],
    options: {
      chart: {
        type: "donut",
      },
      labels: chartData
        ? chartData?.map((item) => getLabel(item))
        : ["no data"],
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        type: "gradient",

        opacity: 0.8,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(0) + "%";
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <Spin spinning={loading}>
        {chartData?.length > 0 ? (
          <Chart
            options={data.options}
            series={data.series}
            type="donut"
            height={400}
            width={"100%"}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default PieChart;

import {useSelector} from "react-redux";
import MiniSignal from "./MiniSignal";
import {useState} from "react";
import styles from "../../../styles/Components/mini-app/mini-ai-signals/MiniSignals.module.css";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

function MiniSignals() {
    const {
        tradingAssistant: {allSuggestion},
    } = useSelector((state) => state);

    const [showMore, setShowMore] = useState(false);

    // const signals = [...allSuggestion]
    //     ?.sort((a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH)
    //     ?.sort((a, b) => (b.TRADE_TYPE === "Buy" ? 1 : -1));

    const strongSignals = [...allSuggestion]?.sort(
        (a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH,
    );
    const buySignals = strongSignals.filter((s) => s.TRADE_TYPE === "Buy");
    const otherSignals = strongSignals.filter((s) => s.TRADE_TYPE !== "Buy");
    const signals = buySignals.concat(otherSignals);
    const threeTopSignals = signals?.slice(0, 3);
    const moreSignals = signals?.slice(3);

    const showSignalsHandler = () => {
        setShowMore((prevState) => !prevState);
        if (showMore) {
            window.scrollTo(0, 0)
        }
    };

    return (
        <div className={styles.container}>
            {threeTopSignals?.map((signal, i) => (
                <MiniSignal
                    data={signal}
                    key={`${signal.SYMBOL_NAME} ${signal?.EXPECTED_RETURN}`}
                />
            ))}

            {showMore &&
                moreSignals?.map((signal, i) => (
                    <MiniSignal
                        data={signal}
                        key={`${signal.SYMBOL_NAME} ${signal?.EXPECTED_RETURN}`}
                    />
                ))}

            {signals?.length > 3 && (
                <div className={styles.actionButton} onClick={showSignalsHandler}>
                    Show {!showMore ? "More" : "Less"} Signals
                    {!showMore ? <IoIosArrowDown/> : <IoIosArrowUp/>}
                </div>
            )}
        </div>
    );
}

export default MiniSignals;

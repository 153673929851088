import { Button, Modal } from "antd";
import { useStrategy } from "./useCreateStrategyContext";
import styles from "../../../styles/Components/new-insight/create-strategy/AddStrategy.module.css";
import { useSelector } from "react-redux";

function AddStrategy({ setStep }) {
  const {
    newInsightState: { createStrategyStatus },
  } = useSelector((state) => state);
  const {
    state: { strategyList },
  } = useStrategy();

  const clickHandler = () => {
    Modal.confirm({
      title: "Confirmation",
      content:
        "Have you chosen all data metrics? Click Yes to proceed, or No to add more.",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        style: {
          backgroundColor: "#0c3fce",
          borderColor: "#0c3fce",
        },
      },
      onOk: () => setStep(2),
      onCancel: () => Modal.destroyAll(),
    });
  };

  return (
    <div className={styles.container}>
      <Button
        disabled={!strategyList.length}
        loading={createStrategyStatus === "loading"}
        onClick={clickHandler}
      >
        Create Strategy
      </Button>
      <span>{strategyList.length} items selected</span>
    </div>
  );
}

export default AddStrategy;

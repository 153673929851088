import styles from "../../../styles/Components/bot/bot-configuration/ExpandedRowBotTable.module.css";
import { BsCalendarDate } from "react-icons/bs";
import { formatCustomDate } from "../../../utility/helper";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { FaScaleBalanced } from "react-icons/fa6";
import { IoMdReturnLeft } from "react-icons/io";
import { riskOptions } from "../../../utility/data";
import { FaChartPie, FaClock, FaCoins } from "react-icons/fa";

function ExpandedRowBotTable({ data }) {
  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <span className={styles.label}>
          <FaScaleBalanced size={16} color={"#c44569"} />
          Risk Tolerance:
        </span>
        <span>{riskOptions[data?.bot_configurations?.risk_tolerance - 1]}</span>
      </div>

      <div className={styles.infoBox}>
        <span className={styles.label}>
          <IoMdReturnLeft color={"#44bd32"} size={16} />
          Target Return Percentage:
        </span>
        <span>{data?.bot_configurations?.target_return_percentage}%</span>
      </div>

      <div className={styles.infoBox}>
        <span className={styles.label}>
          <FaChartPie color={"#EF4040"} size={16} />
          Crypto Allocation Strategy:
        </span>
        <span>{data?.bot_configurations?.crypto_allocation_strategy}</span>
      </div>

      <div className={styles.infoBox}>
        <span className={styles.label}>
          <FaClock color={"#B6BBC4"} size={16} />
          Selected Trading Strategies:
        </span>
        <span>
          {data?.bot_configurations?.selected_trading_strategies.join(", ")}
        </span>
      </div>

      <div className={styles.infoBox}>
        <span className={styles.label}>
          <FaCoins color={"#FFB534"} size={16} />
          Number of Coins:
        </span>
        <span>{data?.bot_configurations?.number_of_coins}</span>
      </div>

      <div className={`${styles.infoBox} ${styles.showInMobile}`}>
        <span className={styles.label}>
          <BsCalendarDate color={"#5E1675"} size={15} /> Creation Date:
        </span>
        <span>{formatCustomDate(data?.CreatedDate, true)}</span>
      </div>

      <div className={`${styles.infoBox} ${styles.showInMobile}`}>
        <span className={styles.label}>
          <MdOutlineSignalCellularAlt color={"#0C4CFC"} size={16} /> Signal
          Source:
        </span>
        <span>
          {data?.bot_configurations?.signal_source?.replaceAll("_", " ")}
          {data?.bot_configurations?.ai_assistant_mode && (
            <span className={styles.aiMode}>
              {data?.bot_configurations?.ai_assistant_mode === "default"
                ? "(All Categories)"
                : data?.bot_configurations?.ai_assistant_mode}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

export default ExpandedRowBotTable;

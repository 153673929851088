import { Spin, Table, Tooltip } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getBotList } from "../../../redux/bot/actions";
import { formatCustomDate } from "../../../utility/helper";
import { RxCheck, RxCross2 } from "react-icons/rx";
import { botScoreColor } from "../../../utility/data";
import { FiInfo } from "react-icons/fi";
import ExpandedRowBotTable from "./ExpandedRowBotTable";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { getRelativeTime } from "../../../utility/formatter-helper";

const formatAIMode = {
  only_ai: "Only AI",
  only_onchain: "Only on-chain",
  only_ta: "Only technical indicator",
  only_social: "Only social",
};

function BotsTable({ handleBotSelection, selectedBot }) {
  const {
    main: { userData },
    botState: {
      botList,
      getBotListLoading,
      fetchedBotList,
      botStatusLoading,
      botStatusData,
      getBotListError,
    },
  } = useSelector((state) => state);

  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState([]);

  const onRowClick = (record) => {
    if (expandedRowKey.includes(record.key)) {
      return setExpandedRowKey([]);
    }
    setExpandedRowKey([record.key]);
  };

  const sortedData = [...botList]
    .sort((a, b) => new Date(b?.CreatedDate) - new Date(a?.CreatedDate))
    .sort((a, b) => {
      return b?.bot_status === "active"
        ? 1
        : a?.bot_status === "active"
        ? -1
        : 0;
    });

  const dispatch = useDispatch();

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 10,
      align: "center",
      responsive: ["md"],
      render: (index) => <span>{index + 1}</span>,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Bot Owner",
      dataIndex: "name",
      key: "name",
      width: 20,
      align: "center",
      render: (name) => <span>{name}</span>,
    },
    {
      title: "Creation Date",
      dataIndex: "date",
      key: "date",
      width: 15,
      align: "center",
      responsive: ["md"],
      render: (date) => <span>{getRelativeTime(date)}</span>,
    },
    {
      title: "Exchange",
      dataIndex: "exchange",
      key: "exchange",
      width: 20,
      align: "center",
      render: (exchange) => <span>{exchange}</span>,
    },
    {
      title: "Signal Source",
      dataIndex: "source",
      key: "source",
      width: 20,
      align: "center",
      responsive: ["md"],
      render: ([source, mode]) => (
        <span>
          {source.replace("_", " ")} <br />
          <span style={{ fontSize: "13px", color: "#7f8c8d" }}>
            {source === "AI_Assistant" &&
              `( ${
                mode === "default" ? "All categories" : formatAIMode[mode]
              } )`}
          </span>
        </span>
      ),
    },
    {
      title: "Bot Status",
      dataIndex: "status",
      key: "status",
      width: 20,
      align: "center",
      render: (status) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: ".5rem",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: `${status === "inactive" ? "#e74c3c" : "#00af31"}`,
              fontWeight: 450,
              display: "flex",
              alignItems: "center",
              gap: ".2rem",
            }}
          >
            {status === "inactive" && <RxCross2 />}
            {status !== "inactive" && <RxCheck />}
            {status[0].toUpperCase() + status.slice(1)}
          </span>
          {status === "active" && botStatusLoading && <Spin size={"small"} />}
          {status === "active" && botStatusData?.BOT_STATUS && (
            <>
              <span
                style={{
                  color: `${botScoreColor(
                    botStatusData?.BOT_STATUS?.SCORE_BOT_ACTIVITY,
                  )}`,
                }}
              >
                {botStatusData?.BOT_STATUS?.SCORE_BOT_ACTIVITY}%
              </span>
              <Tooltip
                title={`${botStatusData?.BOT_STATUS?.STATUS_DESCRIPTION}`}
                color={"geekblue"}
                placement={"bottom"}
              >
                <FiInfo color={"#0C4CFC"} />
              </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
      handleBotSelection(keys.length > 0 ? sortedData[keys[0]] : {});
    },
  };

  const handleRowExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.key : []);
  };

  useEffect(() => {
    if (
      userData?.email &&
      // !botList.length &&
      !fetchedBotList &&
      !getBotListLoading &&
      !getBotListError
    ) {
      dispatch(
        getBotList(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [dispatch, fetchedBotList, getBotListError, getBotListLoading, userData]);

  useEffect(() => {
    if (botList.length > 0 && !getBotListLoading) {
      setTableData(
        sortedData.map((bot, i) => {
          const {
            bot_configurations: {
              bot_name,
              selected_exchange,
              signal_source,
              ai_assistant_mode,
            },
            bot_status,
            CreatedDate,
          } = bot;
          return {
            key: i,
            index: i,
            name: bot_name,
            date: CreatedDate,
            exchange: selected_exchange,
            source: [signal_source, ai_assistant_mode],
            status: bot_status,
            data: bot,
          };
        }),
      );
    }
  }, [botList, getBotListLoading]);

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      handleBotSelection({});
    }
  }, [selectedRowKeys, botList, handleBotSelection]);

  useEffect(() => {
    if (getBotListLoading) {
      setSelectedRowKeys([]);
    }
  }, [getBotListLoading]);

  return (
    <>
      <Table
        columns={columns}
        scroll={{ y: 500 }}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        height={500}
        pagination={false}
        dataSource={tableData}
        loading={getBotListLoading}
        className={`${styles.table} bot-table`}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedRowBotTable data={record?.data} />
          ),
          expandedRowKeys: expandedRowKey,
          onExpand: handleRowExpand,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? (
              <IoChevronUp
                className={styles.expandIcon}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <IoChevronDown
                className={styles.expandIcon}
                onClick={(e) => onExpand(record, e)}
              />
            );
          },
        }}
      />
    </>
  );
}

export default BotsTable;

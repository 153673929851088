// STYLES
import "../../styles/Components/faq/faqPublic.css";

// Images
import in1 from "../../asset/Images/faq/Insights/HowToUseTheInsights/1.png";
import in2 from "../../asset/Images/faq/Insights/HowToUseTheInsights/2.PNG";
import in3 from "../../asset/Images/faq/Insights/HowToUseTheInsights/3.PNG";
import in4 from "../../asset/Images/faq/Insights/HowToUseTheInsights/4.PNG";
import in5 from "../../asset/Images/faq/Insights/HowToUseTheInsights/5.PNG";
import in6 from "../../asset/Images/faq/Insights/HowToUseTheInsights/6.PNG";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HowUseInsights = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="helpTitle">How to use the Insights?</h3>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 1: </span> Navigate to the Insights
          from the left-hand menu.
        </p>
        <LazyLoadImage
          src={in1}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in1} alt="insights" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 2: </span> In Insights, there are
          several features that can be used to gain insight. Click on "Features"
          on the left side of the screen to see the available options. These
          consist of market features such as price, various technical
          indicators, various social indicators, on-chain indicators, AI
          signals, and more.
        </p>
        <LazyLoadImage
          src={in2}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in2} alt="insights" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 3: </span> Select your desired
          feature to get insights.{" "}
        </p>
        <LazyLoadImage
          src={in3}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in3} alt="insights" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 4: </span> Check the coin trends in
          the "Trends" section on the right side of the screen.
        </p>
        <LazyLoadImage
          src={in4}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in4} alt="insights" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 5: </span> In the "Signals" section
          on the right side of the screen, you will see suggested buy/sell
          signals or recommended long/short positions based on the platform's
          predictions. Detailed information about the profitability of the
          signal and the prediction confidence can also be found here.
        </p>
        <LazyLoadImage
          src={in5}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in5} alt="insights" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 6: </span> Once you've made your
          decision, you can go to your exchange and execute the trade. You can
          also save your trade actions in our platform to manage your portfolio
          and receive suggestions from the alert system. Please note that our
          platform does not offer trading functionalities.
        </p>
        <LazyLoadImage
          src={in6}
          alt="insights"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in6} alt="insights" />*/}
      </div>
    </div>
  );
};

export default HowUseInsights;

import React from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import TradeOptionsSelector from "./TradeOptionsSelector";
import { useSelector } from "react-redux";
import ExchangeTradeFirstStep from "./step1-d/ExchangeTradeFirstStep";
import VirtualTradeFirstStep from "./step1-d/VirtualTradeFirstStep";

function Step1D({ step, setShow }) {
  const {
    tradeState: { tradeProcess },
  } = useSelector((state) => state);
  return (
    <div className={`${step === "1-d" ? styles.active : ""} ${styles.step}`}>
      <TradeOptionsSelector />
      {/*<TradeOptionsSelector mode={"ai-table"} />*/}
      {tradeProcess === "exchange" && (
        <ExchangeTradeFirstStep setShow={setShow} />
      )}
      {tradeProcess === "paperTrading" && (
        <VirtualTradeFirstStep setShow={setShow} />
      )}
    </div>
  );
}

export default Step1D;

import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import styles from "../../styles/Components/developer-api/documents.module.sass"
import { githubLight, githubDark } from '@uiw/codemirror-theme-github';
import { dracula } from '@uiw/codemirror-theme-dracula';

import {python} from '@codemirror/lang-python';
import codes from "./codes";

const Documents = () => {
    return (
        <div>
            <div className={styles.titles}>
                <p>CryptoCrispy API is useful for providing developers with the latest insights about financial markets using the Python language.</p>
                <h2>Get Started
                </h2>
                <hr/>
                <span>First, register and create an account with CryptoCrispy and purchase the appropriate pricing plan.</span>
                <br/>
                    <span>  Go to the API section and create API_KEY and API_URL for your account.</span>

                <h2>Query user status
                </h2>
                <hr/>
                <p>To know the remaining balance, expiration date, usage data, and payment information, you can use the following snippet

                </p>
            </div>
            <CodeMirror
                editable={false}
                value={codes.part1}
                height={"auto"}
                theme={dracula}
                extensions={[python()]}
                className={styles.codes}

            />
            <div className={styles.titles}>
                <h2>Retrieve available markets</h2>
                <hr/>
                <p>To retrieve the available markets, you can use the following snippet

                </p>
            </div>
            <CodeMirror
                editable={false}
                value={codes.part2}
                height={"auto"}
                theme={dracula}
                extensions={[python()]}
                className={styles.codes}

            />
            <div className={styles.titles}>
                <h2>Get initial information
                </h2>
                <hr/>
                <p>You can use the following snippet to get the tree structure of the different features

                </p>
            </div>
            <CodeMirror
                editable={false}
                value={codes.part3}
                height={"auto"}
                theme={dracula}
                className={styles.codes}

                extensions={[python()]}
            />
            <div className={styles.titles}>
                <h2>Retrieve information about chart and signals
                </h2>
                <hr/>
                <p>To get detailed information about the historical data and the suggested signals, you can use the following snippet

                </p>
            </div>
            <CodeMirror
                editable={false}
                value={codes.part4}
                height={"auto"}
                theme={dracula}
                className={styles.codes}

                extensions={[python()]}
            />
        </div>
    )
};

export default Documents;

import MiniAppContainer from "../MiniAppContainer";
import MiniAppHeader from "../layout/MiniAppHeader";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { useEffect, useState } from "react";
import MiniBotInfo from "./all-bots/MiniBotInfo";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

function MiniAppAllBots() {
  const {
    botState: { botList },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  const [selectedBot, setSelectedBot] = useState(null);

  const activeBot = botList?.find((bot) => bot?.bot_status !== "inactive");
  const lastBot = [...botList]?.sort((a, b) =>
    new Date(b.CreatedDate) > new Date(a.CreatedDate) ? 1 : -1,
  )[0];

  const currentBot = activeBot || lastBot;
  const otherBot = [...botList]?.filter(
    (bot) => bot?.CreatedDate !== currentBot?.CreatedDate,
  );

  const handleSelectChange = (value) => {
    setSelectedBot(value);
  };

  useEffect(() => {
    setSelectedBot(otherBot[0]?.CreatedDate);
  }, []);

  useEffect(() => {
    if (!botList?.length) {
      navigate("/mini-bots");
    }
  }, [botList]);

  return (
    <MiniAppContainer>
      <MiniAppHeader page={"All Bots"} />
      <Select
        placeholder="Select a bot"
        value={selectedBot}
        style={{ width: 200 }}
        onChange={handleSelectChange}
      >
        {otherBot.map((bot, index) => (
          <Option key={index} value={bot.CreatedDate}>
            {bot.bot_configurations.bot_name}
          </Option>
        ))}
      </Select>
      {selectedBot && <MiniBotInfo botId={selectedBot} />}
    </MiniAppContainer>
  );
}

export default MiniAppAllBots;

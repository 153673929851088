import React from 'react';
import DashboardLayout from "../../components/layout/DashboardLayout";
import {useParams} from "react-router";

const PreviousEmail = () => {
        let params = useParams();
        return (
            <DashboardLayout>



                <div>
                    <title />
                    <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    {/*[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]*/}
                    <style dangerouslySetInnerHTML={{__html: `
                            * {
                                box-sizing: border-box;
                    }

                        body {
                        margin: 0;
                        padding: 0;
                    }

                        a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }

                        #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }

                        p {
                        line-height: inherit
                    }

                        @media (max-width:520px) {
                        .icons-inner {
                        text-align: center;
                    }

                        .icons-inner td {
                        margin: 0 auto;
                    }

                        .row-content {
                        width: 100% !important;
                    }

                        .column .border {
                        display: none;
                    }

                        table {
                        table-layout: fixed !important;
                    }

                        .stack .column {
                        width: 100%;
                        display: block;
                    }
                    }
                       ` }} />
                        <table border={0} cellPadding={0} cellSpacing={0} className="nl-container" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","background-color":"#FFFFFF"}} width="100%">
                        <tbody>
                        <tr>
                        <td><table align="center" border={0} cellPadding={0} cellSpacing={0} className="row row-1" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt"}} width="100%">
                        <tbody>
                        <tr>
                        <td><table align="center" border={0} cellPadding={0} cellSpacing={0} className="row-content stack" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","color":"#000000","width":"500px"}} width={500}>
                        <tbody>
                        <tr>
                        <td className="column column-1" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","font-weight":"400","-webkit-text-align":"left","text-align":"left","vertical-align":"top","padding-top":"5px","padding-bottom":"5px","border-top":"0px","border-right":"0px","border-bottom":"0px","border-left":"0px"}} width="100%"><table border={0} cellPadding={0} cellSpacing={0} className="image_block" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt"}} width="100%">
                        <tbody>
                        <tr>
                        <td style={{"width":"100%","padding-right":"0px","padding-left":"0px","padding-top":"60px"}}><div align="center" style={{"line-height":"10px"}}><img alt="I'm an image" src="https://s3.ap-southeast-2.amazonaws.com/www.cryptocrispy.com.au/feedback_emails/verification_email/images/cryptocrispy.png" style={{"display":"block","height":"auto","border":"0","width":"125px","max-width":"100%"}} title="I'm an image" width={125} /></div></td>
                        </tr>
                        </tbody>
                        </table>
                        <table border={0} cellPadding={10} cellSpacing={0} className="divider_block" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt"}} width="100%">
                        <tbody>
                        <tr>
                        <td><div align="center">
                        <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt"}} width="100%">
                        <tbody>
                        <tr>
                        <td className="divider_inner" style={{"font-size":"1px","line-height":"1px","border-top":"1px solid #BBBBBB"}}><span> </span></td>
                        </tr>
                        </tbody>
                        </table>
                        </div></td>
                        </tr>
                        </tbody>
                        </table>
                        <table border={0} cellPadding={10} cellSpacing={0} className="text_block" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","word-break":"break-word"}} width="100%">
                        <tbody>
                        <tr>
                        <td />
                        </tr>
                        </tbody>
                        </table>
                        <table border={0} cellPadding={10} cellSpacing={0} className="text_block" role="presentation" style={{"mso-table-lspace":"0pt","mso-table-rspace":"0pt","word-break":"break-word"}} width="100%">
                        <tbody>
                        <tr>
                        <td><div style={{"font-family":"sans-serif"}}>
                        <div className="txtTinyMce-wrapper" style={{"font-size":"12px","font-family":"Arial, Helvetica Neue, Helvetica, sans-serif","mso-line-height-alt":"14.399999999999999px","color":"#555555","line-height":"1.2"}}>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left","mso-line-height-alt":"14.399999999999999px"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>Hi amirhossein!</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}> <span style={{"color":"#010101"}}>Check the signals below generated by AI. &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}><strong style={{"color":"red"}}>Assets in Danger:&nbsp;&nbsp;</strong></p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;Your balance has decreased -0.84 %  in the last 24 hours. Please check your portfolio.&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}><strong style={{"color":"blue"}}>Latest Assistant Signals:&nbsp;&nbsp;</strong></p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left","color":"cyan"}}>&nbsp;</p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}> <span id="8c8c3e26-1623-4788-a61b-b8642b78bf" style={{}}>We suggest you to buy/sell the following. Don't miss them.&nbsp; &nbsp;&nbsp;</span></p>
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}>&nbsp;</p>
                        <div style={{"-ms-flex-line-pack":"center","align-content":"center","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center"}}>
                        <table width={500} border={1} align="center" style={{"-webkit-text-align":"center","text-align":"center"}}>
                        <tbody style={{"-ms-flex-line-pack":"center","align-content":"center"}}>
                        <tr>
                        <td>&nbsp;Market Type</td>
                        <td>&nbsp;Symbol Name</td>
                        <td>&nbsp;Suggested Action</td>
                        <td>&nbsp;Expected Return (%)</td>
                        <td>&nbsp;Signal Strength (%)</td>
                        </tr>
                        <tr><td>&nbsp;crypto</td><td>&nbsp;BNB</td><td>&nbsp;Sell</td><td>&nbsp;19.82</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;BTC</td><td>&nbsp;Buy</td><td>&nbsp;7.42</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;DOGE</td><td>&nbsp;Buy</td><td>&nbsp;91.8</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;DOT</td><td>&nbsp;Sell</td><td>&nbsp;16.65</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;ETH</td><td>&nbsp;Buy</td><td>&nbsp;18.59</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;MATIC</td><td>&nbsp;Buy</td><td>&nbsp;48.74</td><td>&nbsp;100</td></tr><tr><td>&nbsp;crypto</td><td>&nbsp;XRP</td><td>&nbsp;Sell</td><td>&nbsp;33.91</td><td>&nbsp;100</td></tr>

                        </tbody>
                        </table>
                        </div>
                        <p>&nbsp;</p>

                        <p>&nbsp;</p>
                        <div align="center">
                    {/*[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://monitoring-panel.mapizy-studio.com/" style="height:42px;width:168px;v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#3AAEE0"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><![endif]*/}
                        <a href="https://app.cryptocrispy.com.au/" style={{"-webkit-text-decoration":"none","text-decoration":"none","display":"inline-block","color":"#ffffff","background-color":"#3AAEE0","border-radius":"4px","width":"auto","border-top":"1px solid #3AAEE0","border-right":"1px solid #3AAEE0","border-bottom":"1px solid #3AAEE0","border-left":"1px solid #3AAEE0","padding-top":"5px","padding-bottom":"5px","font-family":"Arial, Helvetica Neue, Helvetica, sans-serif","-webkit-text-align":"center","text-align":"center","mso-border-alt":"none","word-break":"keep-all"}} target="_blank"><span style={{"padding-left":"20px","padding-right":"20px","font-size":"16px","display":"inline-block","letter-spacing":"normal"}}><span style={{"font-size":"16px","margin":"0","line-height":"2","word-break":"break-word","mso-line-height-alt":"32px"}}>Go to the Platform&nbsp;&nbsp;</span></span></a>
                        <a href="https://vsx5glh4h0.execute-api.ap-southeast-2.amazonaws.com/dev/api?user=amirhossein&email 36d535da5c@boxomail.live" style={{"-webkit-text-decoration":"none","text-decoration":"none","display":"inline-block","color":"#ffffff","background-color":"#E34926","border-radius":"4px","width":"auto","border":"1px solid #6C35E1","padding-top":"5px","padding-bottom":"5px","font-family":"Arial, Helvetica Neue, Helvetica, sans-serif","-webkit-text-align":"center","text-align":"center","mso-border-alt":"none","word-break":"keep-all"}} target="_blank"><span style={{"padding-left":"20px","padding-right":"20px","font-size":"16px","display":"inline-block","letter-spacing":"normal"}}><span style={{"font-size":"16px","margin":"0","line-height":"2","word-break":"break-word","mso-line-height-alt":"32px"}}>Unsubscribe Alerts&nbsp;&nbsp;</span></span></a>
                    {/*[if mso]></center></v:textbox></v:roundrect><![endif]*/}
                        </div>
                        <p>&nbsp; </p>
                        <p />
                        <p style={{"margin":"0","font-size":"14px","-webkit-text-align":"left","text-align":"left"}}><span id="c56cc832-f374-4241-9dce-5c003a3b8ac1" style={{}}>&nbsp;</span></p>
                        </div>
                        </div></td>
                        </tr>
                        </tbody>
                        </table></td>
                        </tr>
                        </tbody>
                        </table></td>
                        </tr>
                        </tbody>
                        </table>
                        </td></tr></tbody>
                        </table>
                    {/* End */}
                        </div>


                        </DashboardLayout>
        );
    }
;

export default PreviousEmail;

import { Badge, Button, message, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/shared/link-with-exchange/LinkWithExchange.module.css";
import { IoMdArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  getIntegratedExchanges,
  linkWithExchange,
} from "../../redux/exchange/actions";
import UnlinkFromExchange from "./UnlinkFromExchange";
import Loader from "../trade-modal/steps/Loader";

function LinkWithExchange({
  style,
  btnText = "Link with Exchange",
  exchange = null,
  showExchange = true,
  className,
}) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      linkWithExchangeLoading,
      unlinkFromExchangeLoading,
    },
    profile: { profileData },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState(exchange || null);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [step, setStep] = useState(1);

  const exchanges = [...integratedData]
    ?.filter(
      (item) =>
        item?.id !== profileData?.portfolio1_linked_exchange &&
        item?.id !== profileData?.portfolio2_linked_exchange,
    )
    .sort((a, b) => (b.id === "cryptocrispy" ? 1 : -1));

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const closeModalHandler = () => {
    if (linkWithExchangeLoading || unlinkFromExchangeLoading) return;

    setShowModal(false);
    setSelectedExchange(null);
    setSelectedPortfolio(null);
  };
  const handleSelectPortfolio = (type) => {
    if (
      (type === "secondary" && isSecondPortfolioConnected) ||
      (type === "primary" && isFirstPortfolioConnected)
    ) {
      return;
    }

    setSelectedPortfolio(type);
  };

  const submitHandler = async () => {
    if (linkWithExchangeLoading) return;
    if (!selectedPortfolio)
      return message.info("Please select a virtual portfolio.");
    if (!selectedExchange) return message.info("Please select an exchange.");

    await dispatch(
      linkWithExchange(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedExchange,
        selectedPortfolio,
      ),
    ).then(() => {
      closeModalHandler();
    });
  };

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !integratedDataLoading &&
      !getIntegratedList &&
      showModal &&
      showExchange
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    integratedData,
    integratedDataLoading,
    getIntegratedList,
    userData,
    showModal,
    showExchange,
  ]);

  useEffect(() => {
    if (
      integratedData.length &&
      showModal &&
      showExchange &&
      selectedPortfolio
    ) {
      setSelectedExchange(exchanges[0]?.id);
      // if (isSecondPortfolioConnected || isSecondPortfolioConnected) {
      //   const linkedExchange = integratedData.find(
      //     (exchange) =>
      //       exchange.id === profileData?.portfolio1_linked_exchange ||
      //       exchange.id === profileData?.portfolio2_linked_exchange,
      //   );
      //   setSelectedExchange(linkedExchange?.id);
      // } else {
      //   setSelectedExchange(exchanges[0]?.id);
      // }
    }
  }, [integratedData, showModal, showExchange, selectedPortfolio, profileData]);

  return (
    <div style={style ? style : {}} className={className || ""}>
      <span className={styles.linkBtn} onClick={() => setShowModal(true)}>
        <FaLink />
        {btnText}
      </span>
      <Modal
        open={showModal}
        onCancel={closeModalHandler}
        footer={null}
        centered
        width={400}
        closable={!linkWithExchangeLoading && !unlinkFromExchangeLoading}
        bodyStyle={{ padding: "1rem 24px" }}
      >
        <div className={styles.portfolioBox}>
          <h4 style={{ marginBottom: "1.2rem" }}>
            <IoMdArrowDropright />
            Select the portfolio that you want to link:
          </h4>

          <ul className={styles.portfolioList}>
            <li
              className={`${styles.portfolioItem} ${
                isFirstPortfolioConnected ? styles.disable : ""
              } ${
                selectedPortfolio === "primary" ? styles.selectedPortfolio : ""
              }`}
              onClick={() => handleSelectPortfolio("primary")}
            >
              {isFirstPortfolioConnected ? (
                <Badge
                  count={profileData?.portfolio1_linked_exchange}
                  size={"small"}
                  status={"default"}
                  className={styles.badge}
                >
                  First
                </Badge>
              ) : (
                <span>First</span>
              )}
            </li>

            <li
              className={`${styles.portfolioItem} ${
                isSecondPortfolioConnected ? styles.disable : ""
              } ${
                selectedPortfolio === "secondary"
                  ? styles.selectedPortfolio
                  : ""
              }`}
              onClick={() => handleSelectPortfolio("secondary")}
            >
              {isSecondPortfolioConnected ? (
                <Badge
                  count={profileData?.portfolio2_linked_exchange}
                  size={"small"}
                  status={"default"}
                  className={styles.badge}
                >
                  Second
                </Badge>
              ) : (
                <span>Second</span>
              )}
            </li>
          </ul>
        </div>

        {isSecondPortfolioConnected &&
          isFirstPortfolioConnected &&
          !unlinkFromExchangeLoading && (
            <UnlinkFromExchange
              className={styles.unlinkBtn}
              btnText={"Unlink portfolio #1."}
              portfolio={"primary"}
            />
          )}
        {isSecondPortfolioConnected &&
          isFirstPortfolioConnected &&
          !unlinkFromExchangeLoading && (
            <UnlinkFromExchange
              className={styles.unlinkBtn}
              btnText={"Unlink portfolio #2."}
              portfolio={"secondary"}
            />
          )}

        {unlinkFromExchangeLoading && (
          <Loader text={"Detaching from exchange, please wait..."} />
        )}

        {showExchange && selectedPortfolio && (
          <div className={styles.exchangeBox}>
            <h4>
              <IoMdArrowDropright />
              Select the exchange that you want to link with:
            </h4>
            {integratedDataLoading ? (
              <Spin className={styles.loader} tip={"Fetching exchange lists"} />
            ) : (
              <>
                {integratedData.length > 0 && (
                  <ul className={styles.exchangeList}>
                    {exchanges.length > 0 ? (
                      exchanges.map((exchange) => (
                        <li
                          key={exchange.id}
                          onClick={() => setSelectedExchange(exchange.id)}
                          className={`${styles.exchangeItem} ${
                            selectedExchange === exchange.id
                              ? styles.selectedExchange
                              : ""
                          }`}
                        >
                          <img
                            className={styles.exchangeIcon}
                            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange.id}.svg`}
                            alt={exchange.id}
                          />
                          {exchange.name}
                        </li>
                      ))
                    ) : (
                      <div style={{ gridColumn: "span 3", margin: "0 auto" }}>
                        <p>Your exchange already linked!</p>

                        {isFirstPortfolioConnected &&
                          !unlinkFromExchangeLoading && (
                            <UnlinkFromExchange
                              className={styles.unlinkBtn}
                              btnText={"Unlink exchange (portfolio #1)."}
                              portfolio={"primary"}
                            />
                          )}

                        {isSecondPortfolioConnected &&
                          !unlinkFromExchangeLoading && (
                            <UnlinkFromExchange
                              className={styles.unlinkBtn}
                              btnText={"Unlink exchange (portfolio #2)."}
                              portfolio={"secondary"}
                            />
                          )}
                      </div>
                    )}
                  </ul>
                )}

                {integratedData.length === 0 && (
                  <p>
                    You didn't integrate with any exchange.{" "}
                    <Link to={"/integration"}>Integrate now.</Link>
                  </p>
                )}
              </>
            )}
          </div>
        )}

        {selectedPortfolio && (
          <Button
            onClick={submitHandler}
            className={`${styles.submitBtn} ${
              !selectedExchange || !selectedPortfolio ? styles.disable : ""
            }`}
            loading={linkWithExchangeLoading}
          >
            Submit
          </Button>
        )}
      </Modal>
    </div>
  );
}

export default LinkWithExchange;

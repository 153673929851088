import DashboardLayout from "../../components/layout/DashboardLayout";
import classes from "../../styles/pages/bot/BotAndAutomation.module.css";
import { useParams } from "react-router-dom";
import BotConfiguration from "../../components/bot/BotConfiguration";
import { useState } from "react";
import BotBackTest from "../../components/bot/BotBackTest";
import BotReports from "../../components/bot/BotReports";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";
import { useSelector } from "react-redux";

function BotAndAutomation() {
  const {
    profile: { profileData },
  } = useSelector((state) => state);
  const { section } = useParams();
  const [showDemo, setShowDemo] = useState(true);

  return (
    <DashboardLayout hasDemo={showDemo}>
      <div className={classes.main}>
        <ServiceStatusBar
          status={profileData?.mybot_active}
          expirationDate={profileData?.mybot_active_until}
          service={"mybot"}
          platform
        />
        {!section && (
          <BotConfiguration
            hideDemo={() => setShowDemo(false)}
            showDemo={() => setShowDemo(true)}
          />
        )}
        {section === "backtest" && <BotBackTest />}
        {section === "bot-performance" && <BotReports />}
      </div>
    </DashboardLayout>
  );
}

export default BotAndAutomation;

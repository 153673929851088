import feature from "../../asset/Icons/idea1.gif";

import { Link } from "react-router-dom";

// STYLES
import styles from "../../styles/Components/dashboard/FeatureSuggestion.module.css";

// COMPONENTS
import SkeletonContainer from "./SkeletonContainer";

// ICONS
import insightIcon from "../../asset/Icons/icon-12.svg";
import alertIcon from "../../asset/Icons/icon-16.svg";
import AIIcon from "../../asset/Icons/icon-15.svg";
import tradeSimulatorIcon from "../../asset/Icons/icon-18.svg";
import developer from "../../asset/Icons/icon-09.svg";
import ContentContainer from "./ContentContainer";
import botIcon from "../../asset/Icons/robot (1).gif";
import DashboardCardTitle from "./DashboardCardTitle";
import * as React from "react";

const dateFormatter = (str) => {
  const date = new Date(str);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[date.getDay()];

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthsOfYear[date.getMonth()];

  const dayOfMonth = date.getDate();

  const year = date.getFullYear();

  const time = date.toLocaleTimeString();

  return `${dayOfWeek}, ${month} ${dayOfMonth}, ${year} ${time}`;
};

const featureDetails = {
  Insight: {
    icon: insightIcon,
    path: "/insight",
  },
  "AI assistant": {
    icon: AIIcon,
    path: "/ai-assistant/assistant",
  },
  "Trade simulator": {
    icon: tradeSimulatorIcon,
    path: "/trade-simulator",
  },
  "Alert system": {
    icon: alertIcon,
    path: "/alert-system",
  },
  "Developer API": {
    icon: developer,
    path: "/developer-api",
  },
};

const FeatureSuggestion = ({ loading, data, featureRef, featureStyle }) => {
  return (
    <div ref={featureRef} className={styles.container}>
      {loading && !localStorage.getItem("dashboardStatus") ? (
        <SkeletonContainer />
      ) : (
        data.user && (
          <ContentContainer style={featureStyle}>
            <DashboardCardTitle text={"Recommended features:"} icon={feature} />
            {/*<h4 className={styles.title}>*/}
            {/*  /!* <HiOutlineLightBulb style={{ marginRight: ".5rem", fontSize: "20px", color: "#ffcb00" }} /> *!/*/}
            {/*  <img*/}
            {/*    src={feature}*/}
            {/*    alt="feature"*/}
            {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
            {/*  />*/}
            {/*  Recommended features:*/}
            {/*</h4>*/}
            <div style={{ paddingLeft: ".5rem", margin: "1rem 0" }}>
              <p style={{ width: "90%", lineHeight: 2 }}>
                Based on{" "}
                <span title={dateFormatter(data?.answer_date)}>
                  your answer
                </span>{" "}
                to the start wizard questions , we suggest you these features:
              </p>
              <ul>
                {data?.suggested_features.split(", ").map((item, i) => (
                  <li className={styles.feature} key={i}>
                    <Link to={featureDetails[item].path}>
                      <img
                        src={featureDetails[item].icon}
                        alt={item}
                        style={{ width: "25px" }}
                      />
                      <span className={styles.featureName}>{item}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <p style={{ marginTop: "auto" }}>
              Revisit{" "}
              <Link to="/start-wizard" style={{ color: "#0c4cfc" }}>
                wizard
              </Link>{" "}
              for new recommendations.
            </p>
          </ContentContainer>
        )
      )}
      {/* suggested_feature */}
    </div>
  );
};

export default FeatureSuggestion;

import React, { useEffect, useState } from "react";
import { InputNumber, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-modal/InstantOrderAmountSetting.module.css";
import {
  setTradeAmount,
  setTradeAmountUsd,
} from "../../../../redux/trade/actions";

function InstantOrderAmountSetting({ setIsDone }) {
  const {
    tradeState: {
      tradeType,
      secondTradeType,
      selectedAsset,
      tradeAmount,
      usdtAmount,
      assetAmount,
      tradeAmountUsd,
      assetAmountUsd,
      modalMode: mode,
      basicTradeData,
    },
    exchangeState: { priceData, priceLoading },
  } = useSelector((state) => state);

  const [amountError, setAmountError] = useState(false);

  const dispatch = useDispatch();
  const changeNativeAmountHandler = (value) => {
    // if (!value) {
    //   dispatch(
    //     setTradeAmount(
    //       Object.keys(priceData).length > 0
    //         ? 1 / priceData[selectedAsset.split("/")[0]]
    //         : 0.001,
    //     ),
    //   );
    //   dispatch(
    //     setTradeAmountUsd(
    //       Object.keys(priceData).length > 0
    //         ? 1 / priceData[selectedAsset.split("/")[0]]
    //         : 0.001,
    //     ) * priceData[selectedAsset.split("/")[0]],
    //   );
    //   return;
    // }
    if (tradeType.includes("sell")) {
      if (value > Number(assetAmount)) return;
    } else {
      if (value > usdtAmount / priceData[selectedAsset.split("/")[0]]) {
        return;
      }
    }

    if (tradeType.includes("sell")) {
      setSliderValue((value / assetAmount) * 100);
    } else {
      const percentage =
        ((value * priceData[selectedAsset.split("/")[0]]) / usdtAmount) * 100;
      setSliderValue(percentage);
    }
    dispatch(setTradeAmount(value));
    dispatch(setTradeAmountUsd(value * priceData[selectedAsset.split("/")[0]]));
  };

  const changeUsdAmountHandler = (value) => {
    // if (!value) {
    //   dispatch(setTradeAmountUsd(1));
    //   dispatch(setTradeAmount(1 / priceData[selectedAsset.split("/")[0]]));
    //   return;
    // }
    if (tradeType.includes("buy")) {
      if (value > usdtAmount) return;
    } else {
      if (value > Number(assetAmountUsd)) {
        return;
      }
    }

    if (tradeType.includes("sell")) {
      const percentage =
        ((value * 100) / assetAmount) * priceData[selectedAsset.split("/")[0]];
      setSliderValue(percentage);
    } else {
      const percentage = (value * 100) / usdtAmount;
      setSliderValue(percentage);
    }

    dispatch(setTradeAmountUsd(value));
    dispatch(setTradeAmount(value / priceData[selectedAsset.split("/")[0]]));
  };

  const [sliderValue, setSliderValue] = useState(50); // Initial slider value set to 50%
  const sliderMax = tradeType.includes("sell") ? 100 : 99.99;

  const handleSliderChange = (value) => {
    if (!value) {
      setSliderValue(
        Object.keys(priceData).length > 0
          ? tradeType.includes("sell")
            ? 100.001 / (priceData[selectedAsset.split("/")[0]] * assetAmount)
            : 100.001 / usdtAmount
          : 0.001,
      );
      return;
    }
    setSliderValue(value);
    let percentage = value / 100;

    if (tradeType.includes("sell")) {
      // percentage = value / 100.001;
      const nativeAmount = assetAmount * percentage;
      dispatch(setTradeAmount(nativeAmount));
      dispatch(
        setTradeAmountUsd(
          nativeAmount * priceData[selectedAsset.split("/")[0]],
        ),
      );
    } else {
      const usdAmount = usdtAmount * percentage;
      dispatch(setTradeAmountUsd(usdAmount));
      dispatch(
        setTradeAmount(usdAmount / priceData[selectedAsset.split("/")[0]]),
      );
    }
  };

  const focusHandler = () => {
    if (!secondTradeType) return;
  };

  useEffect(() => {
    if (tradeAmount > 0) {
      if (tradeType.includes("buy")) {
        if (tradeAmount * priceData[selectedAsset.split("/")[0]] > usdtAmount) {
          setAmountError(true);
          setIsDone(false);
        } else {
          setAmountError(false);
          setIsDone(true);
        }
      }

      if (tradeType.includes("sell")) {
        if (tradeAmount > Number(assetAmount)) {
          setAmountError(true);
          setIsDone(false);
        } else {
          setAmountError(false);
          setIsDone(true);
        }
      }
    }
  }, [assetAmount, tradeAmount, tradeType, usdtAmount]);

  // Set a default value for the native and usd amount
  useEffect(() => {
    // if (
    //   mode === "ai-suggestion" &&
    //   (secondTradeType === "sell_sl" || secondTradeType === "sell_tp") &&
    //   basicTradeData.amountPercent
    // ) {
    //   setSliderValue(Number(basicTradeData.amountPercent));
    // } else {
    //   setSliderValue(sliderMax);
    // }

    if (
      mode === "ai-suggestion" &&
      (tradeType === "buy" || secondTradeType === "sell") &&
      basicTradeData.amountPercent
    ) {
      if (
        (secondTradeType === "sell" &&
          Number(basicTradeData.amountPercent) >=
            100.001 / (priceData[selectedAsset.split("/")[0]] * assetAmount)) ||
        (tradeType === "buy" &&
          Number(basicTradeData.amountPercent) >= 100.001 / usdtAmount)
      ) {
        setSliderValue(Number(basicTradeData.amountPercent));
      } else {
        setSliderValue(sliderMax);
      }
    } else {
      setSliderValue(sliderMax);
    }
    // setSliderValue(50);
    const percentage = sliderValue / 100;
    if (secondTradeType.includes("sell")) {
      const nativeAmount = assetAmount * percentage;
      dispatch(setTradeAmount(nativeAmount));
      dispatch(
        setTradeAmountUsd(
          nativeAmount * priceData[selectedAsset.split("/")[0]],
        ),
      );
    } else {
      const usdAmount = usdtAmount * percentage;
      dispatch(setTradeAmountUsd(usdAmount));
      dispatch(
        setTradeAmount(usdAmount / priceData[selectedAsset.split("/")[0]]),
      );
    }
  }, [secondTradeType]);

  // update values based on the updated price
  useEffect(() => {
    if (Object.keys(priceData).length > 0 && secondTradeType) {
      const percentage = sliderValue / 100;
      if (secondTradeType.includes("sell")) {
        const nativeAmount = assetAmount * percentage;
        dispatch(setTradeAmount(nativeAmount));
        dispatch(
          setTradeAmountUsd(
            nativeAmount * priceData[selectedAsset.split("/")[0]],
          ),
        );
      } else {
        const usdAmount = usdtAmount * percentage;
        dispatch(setTradeAmountUsd(usdAmount));
        dispatch(
          setTradeAmount(usdAmount / priceData[selectedAsset.split("/")[0]]),
        );
      }
    }
  }, [
    assetAmount,
    dispatch,
    priceData,
    secondTradeType,
    selectedAsset,
    sliderValue,
    usdtAmount,
  ]);

  return (
    <div
      className={`${styles.container} ${
        !secondTradeType ? styles.inactive : ""
      }`}
    >
      <div className={styles.inputBox}>
        <label>Native Amount:</label>
        <InputNumber
          className={styles.input}
          min={
            Object.keys(priceData).length > 0
              ? 1 / priceData[selectedAsset.split("/")[0]]
              : 0.001
          }
          step={0.0001}
          max={
            tradeType.includes("sell")
              ? Number(assetAmount)
              : usdtAmount / priceData[selectedAsset.split("/")[0]]
          }
          value={tradeAmount}
          status={amountError ? "error" : ""}
          onChange={changeNativeAmountHandler}
          onFocus={focusHandler}
        />
      </div>
      <div className={styles.inputBox}>
        <label>USD Amount:</label>
        <InputNumber
          className={styles.input}
          value={tradeAmountUsd}
          min={1}
          max={
            tradeType.includes("buy")
              ? usdtAmount
              : assetAmount * priceData[selectedAsset.split("/")[0]]
          }
          step={0.01}
          status={amountError ? "error" : ""}
          onChange={changeUsdAmountHandler}
          onFocus={focusHandler}
          formatter={(value) => `$${value}`}
          parser={(value) => value.replace("$", "")}
        />
      </div>
      <div className={styles.sliderBox}>
        <Slider
          min={
            Object.keys(priceData).length > 0
              ? tradeType.includes("sell")
                ? 100.001 /
                  (priceData[selectedAsset.split("/")[0]] * assetAmount)
                : 100.001 / usdtAmount
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          value={sliderValue}
          onChange={handleSliderChange}
          className={styles.slider}
        />

        <InputNumber
          min={
            Object.keys(priceData).length > 0
              ? tradeType.includes("sell")
                ? 100.001 /
                  (priceData[selectedAsset.split("/")[0]] * assetAmount)
                : 100.001 / usdtAmount
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={sliderValue}
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>

      {mode === "ai-suggestion" &&
        sliderValue === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}

      {mode === "ai-suggestion" &&
        ((secondTradeType === "sell" &&
          Number(basicTradeData.amountPercent) >=
            100.001 / (priceData[selectedAsset.split("/")[0]] * assetAmount)) ||
          (tradeType === "buy" &&
            Number(basicTradeData.amountPercent) >= 100.001 / usdtAmount)) &&
        basicTradeData?.amountPercent &&
        sliderValue !== Number(basicTradeData?.amountPercent) && (
          <p
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              setSliderValue(Number(basicTradeData?.amountPercent))
            }
          >
            Reset to AI suggestion.
          </p>
        )}

      {amountError && (
        <p className={styles.errorMessage}>
          {secondTradeType.includes("sell") &&
            `The amount you enter should be lower than the amount of the ${selectedAsset} in your portfolio. [${Number(
              assetAmount,
            )}]`}
          {secondTradeType.includes("buy") &&
            `The amount [${
              Number(tradeAmount) *
              Number(priceData[selectedAsset.split("/")[0]])
            }] is greater than the amount of USDT in your portfolio.[${Number(
              usdtAmount * 0.99,
            )}]`}
        </p>
      )}
    </div>
  );
}

export default InstantOrderAmountSetting;

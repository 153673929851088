// import React from "react";
// import { Menu, Dropdown, Button } from "antd";
//
// const data = {
//   "Strategy Type": [
//     "Momentum",
//     "Mean Reversion",
//     "Spot",
//     "Future",
//     "Trend",
//     "Volume",
//     "Volatility",
//   ],
//   "Data Source": [
//     "Social Sentiment",
//     "Technical Indicators",
//     "Fundamental Analysis",
//     "Blockchain Data",
//   ],
//   "Risk Appetite": ["High Risk", "Moderate Risk", "Low Risk", "Risk-Neutral"],
//   "Time Horizon": ["Short Term", "Medium Term", "Long Term"],
//   "Asset Class": ["Cryptocurrency", "Stocks", "Forex", "Commodities"],
//   Blockchain: ["Network activity", "Wallet Data"],
// };
//
// const renderMenuItems = (items) =>
//   items.map((item) => <Menu.Item key={item}>{item}</Menu.Item>);
//
// const menu = (
//   <Menu>
//     {Object.entries(data).map(([category, items]) => (
//       <Menu.SubMenu key={category} title={category}>
//         {renderMenuItems(items)}
//       </Menu.SubMenu>
//     ))}
//   </Menu>
// );
//
// const StrategyLabels = () => (
//   <Dropdown overlay={menu} trigger={["click"]}>
//     <Button>Open Menu</Button>
//   </Dropdown>
// );
//
// export default StrategyLabels;

import { Select } from "antd";

const { Option, OptGroup } = Select;

function StrategyLabels({ onChange, defaultValue }) {
  return (
    <Select
      placeholder="Select a strategy label"
      defaultValue={defaultValue || ""}
      onChange={onChange}
    >
      <OptGroup label="Strategy Type">
        <Option value="Momentum">Momentum</Option>
        <Option value="Mean Reversion">Mean Reversion</Option>
        <Option value="Spot">Spot</Option>
        <Option value="Future">Future</Option>
        <Option value="Trend">Trend</Option>
        <Option value="Volume">Volume</Option>
        <Option value="Volatility">Volatility</Option>
      </OptGroup>
      <OptGroup label="Data Source">
        <Option value="Social Sentiment">Social Sentiment</Option>
        <Option value="Technical Indicators">Technical Indicators</Option>
        <Option value="Fundamental Analysis">Fundamental Analysis</Option>
        <Option value="Blockchain Data">Blockchain Data</Option>
      </OptGroup>
      <OptGroup label="Risk Appetite">
        <Option value="High Risk">High Risk</Option>
        <Option value="Moderate Risk">Moderate Risk</Option>
        <Option value="Low Risk">Low Risk</Option>
        <Option value="Risk-Neutral">Risk-Neutral</Option>
      </OptGroup>
      <OptGroup label="Time Horizon">
        <Option value="Short Term">Short Term</Option>
        <Option value="Medium Term">Medium Term</Option>
        <Option value="Long Term">Long Term</Option>
      </OptGroup>
      <OptGroup label="Asset Class">
        <Option value="Cryptocurrency">Cryptocurrency</Option>
        <Option value="Stocks">Stocks</Option>
        <Option value="Forex">Forex</Option>
        <Option value="Commodities">Commodities</Option>
      </OptGroup>
      <OptGroup label="Blockchain">
        <Option value="Network activity">Network activity</Option>
        <Option value="Wallet Data">Wallet Data</Option>
      </OptGroup>
    </Select>
  );
}

export default StrategyLabels;

import styles from "../../styles/Components/header/MenuItem.module.css";

function MenuItem({ img, title, description }) {
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={img} alt={title} />
      <div
        className={`${styles.texts} ${
          title === "My Insights Sessions" || title === "My Backtests"
            ? styles.insights
            : ""
        }`}
      >
        <h5 className={styles.title}>{title}</h5>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
}

export default MenuItem;

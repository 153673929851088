import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import PortfolioTableChart from "../charts/trading-assistant/PortfolioTableChart";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";
import { useSelector, useDispatch } from "react-redux";
import TradeModal from "../../shared/trade-modal/TradeModal";
import { currencyFormatter, formatAmount } from "../../utility/helper";
import { setBasicTradeInfo, setMode, setStep } from "../../redux/trade/actions";
import { FaExchangeAlt } from "react-icons/fa";
import VirtualPortfolioTableExpandedRow from "../trading-assistant/portfolio/VirtualPortfolioTableExpandedRow";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import AIAssistantServiceActivationModal from "../trading-assistant/AIAssistantServiceActivationModal";

const PortfolioTable = ({ data, height, budget }) => {
  const {
    tradingAssistant: { getPortfolioDataLoading },
    profile: { profileData },
  } = useSelector((state) => state);

  const [showTradeModal, setShowTradeModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const isServiceActive = profileData?.myais_active === "True";

  const dispatch = useDispatch();

  const onRowClick = (record) => {
    if (record?.coin?.SYMBOL_NAME === "USDT") return;

    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const setTradeHandler = (item, e) => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }

    if (item?.SYMBOL_NAME === "USDT") return;

    e.stopPropagation();

    const { MARKET_TYPE: market, SYMBOL_NAME: asset } = item;
    dispatch(
      setBasicTradeInfo({
        market,
        asset,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  const columns = [
    {
      title: "Market",
      dataIndex: "marketType",
      key: "marketType",
      align: "center",
      width: 85,
      responsive: ["md"],
      render: (text) => <span className={styles.tableContent}>{text}</span>,
    },
    {
      title: "Coin",
      dataIndex: "coin",
      key: "coin",
      align: "center",
      width: 70,
      responsive: ["xs", "sm"],
      render: (item) => (
        <span
          style={{
            color: `${item?.SYMBOL_NAME === "USDT" ? "" : "#0c4cfc"}`,
            // textDecoration: "underline",
            cursor: `${item.SYMBOL_NAME === "USDT" ? "" : "pointer"}`,
          }}
          onClick={(e) => setTradeHandler(item, e)}
          className={styles.tableContent}
        >
          <img
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item?.SYMBOL_NAME}.png`}
            alt={item?.SYMBOL_NAME}
          />
          {item?.SYMBOL_NAME}
        </span>
      ),
    },
    {
      title: "1h",
      dataIndex: "oneHour",
      key: "oneHour",
      width: 75,
      responsive: ["sm"],
      align: "center",
      render: (text) => {
        return text ? (
          <span
            className={`${styles.tableContent} ${
              text > 0 ? styles.green : styles.red
            }`}
          >
            {text}%
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "24h",
      dataIndex: "oneDay",
      key: "oneDay",
      width: 100,
      align: "center",
      responsive: ["sm"],

      render: (text) => {
        return text ? (
          <span
            className={`${styles.tableContent} ${
              text > 0 ? styles.green : styles.red
            }`}
          >
            {text}%
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "7day",
      dataIndex: "oneWeek",
      key: "oneWeek",
      width: 90,
      align: "center",
      responsive: ["sm"],

      render: (text) => {
        return text ? (
          <span
            className={`${styles.tableContent} ${
              text > 0 ? styles.green : styles.red
            }`}
          >
            {text}%
          </span>
        ) : (
          "-"
        );
      },
    },

    {
      title: "Last 7 Days",
      key: "last7Days",
      dataIndex: "last7Days",
      align: "center",
      width: 80,
      responsive: ["xxl"],
      render: (_, { last7Days }) => {
        return last7Days ? <PortfolioTableChart chartData={last7Days} /> : "-";
      },
    },
    {
      title: "Native Amount",
      dataIndex: "nativeAmount",
      key: "nativeAmount",
      align: "center",
      width: 90,
      responsive: ["lg"],
      render: (text) => (
        <span className={styles.tableContent}>{formatAmount(+text)}</span>
      ),
      // render: (text) => <span className={styles.tableContent}>{text?.toFixed(2)}</span>,
    },
    {
      title: "USD Amount",
      dataIndex: "usdAmount",
      key: "usdAmount",
      align: "center",
      width: 80,
      responsive: ["xs", "sm"],
      render: (text) => (
        <span className={styles.tableContent}>${currencyFormatter(text)}</span>
      ),
      // render: (text) => <span className={styles.tableContent}>{`$ ${text?.toFixed(2)}`}</span>,
    },
    {
      title: "Buy/Sell Asset",
      dataIndex: "buyNow",
      key: "buyNow",
      align: "center",
      width: 82,
      responsive: ["xs", "sm"],
      render: (item) =>
        item?.SYMBOL_NAME === "USDT" ? (
          "-"
        ) : (
          <Button
            className={styles.buyNow}
            onClick={(e) => setTradeHandler(item, e)}
          >
            {/*<BsPlusSquare />*/}
            <FaExchangeAlt />
          </Button>
        ),
      // render: (item) => (
      //   <Button className={styles.buyNow} onClick={() => getPriceHandler(item)}>
      //     <BsPlusSquare />
      //   </Button>
      // ),
    },
  ];
  const [tabletData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(() => {
        const budgetData = {
          MARKET_TYPE: "crypto",
          NATIVE_AMOUNT: budget,
          USD_AMOUNTS: budget,
          SYMBOL_NAME: "USDT",
          SYMBOL_1D_PRICE_CHANGE: "",
          SYMBOL_1H_PRICE_CHANGE: "",
          SYMBOL_7D_PRICE_CHANGE: "",
          SYMBOL_7D_PRICE_HISTORY: "",
        };
        return [budgetData, ...data]?.map((item, index) => {
          return {
            key: index,
            marketType: item?.MARKET_TYPE,
            coin: item,
            oneHour: item?.SYMBOL_1H_PRICE_CHANGE,
            oneDay: item?.SYMBOL_1D_PRICE_CHANGE,
            oneWeek: item?.SYMBOL_7D_PRICE_CHANGE,
            nativeAmount: item?.NATIVE_AMOUNT,
            usdAmount: item?.USD_AMOUNTS,
            last7Days: item?.SYMBOL_7D_PRICE_HISTORY,
            buyNow: item,
          };
        });
      });
    }
  }, [budget, data]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={tabletData}
        pagination={false}
        scroll={{ y: height }}
        className={`${styles.table} expanded-table`}
        loading={getPortfolioDataLoading}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={
          window.innerWidth < 992 && {
            expandedRowRender: (record) => (
              <VirtualPortfolioTableExpandedRow data={record} />
            ),
            rowExpandable: (record) => record?.coin?.SYMBOL_NAME !== "USDT",
            expandedRowKeys: expandedRowKeys,

            expandIcon: ({ expanded, onExpand, record }) => {
              if (record?.coin?.SYMBOL_NAME === "USDT") return "";
              if (expanded) {
                return (
                  <IoChevronUp
                    className={styles.expandIcon}
                    onClick={(e) => {
                      onRowClick(record);
                      // e.stopPropagation(); // Prevents the onRow click event from being triggered
                      // onExpand(record, e);
                    }}
                  />
                );
              } else {
                return (
                  <IoChevronDown
                    className={styles.expandIcon}
                    onClick={(e) => {
                      onRowClick(record);
                      // e.stopPropagation(); // Prevents the onRow click event from being triggered
                      // onExpand(record, e);
                    }}
                  />
                );
              }
            },
          }
        }
      />
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />
    </>
  );
};

export default PortfolioTable;

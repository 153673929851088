import SkeletonContainer from "./SkeletonContainer";
import * as React from "react";
import styles from "../../styles/Components/dashboard/Payments/Purchases.module.css";
import paymentIcon from "../../asset/Icons/payment(1).gif";
import { Link } from "react-router-dom";
import Modal from "../modal/Modal";
import { Skeleton } from "antd";
import LineChart from "../charts/dashboard/LineChart";
import { useState } from "react";
import ContentContainer from "./ContentContainer";
import summary from "../../asset/Icons/summary.gif";
import DashboardCardTitle from "./DashboardCardTitle";
import { formatCustomDate } from "../../utility/helper";
import { getRelativeTime } from "../../utility/formatter-helper";

const formatDate = (str) => {
  const date = new Date(str);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

function PurchasesInfo({ loading, data }) {
  return (
    <div className={styles.container}>
      {loading && !data ? (
        <SkeletonContainer />
      ) : (
        data && (
          <ContentContainer>
            <DashboardCardTitle
              text={"Your Recent Purchases:"}
              icon={paymentIcon}
            />
            {/*<h3 className={styles.title}>*/}
            {/*  <img*/}
            {/*    src={paymentIcon}*/}
            {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
            {/*    alt="payment"*/}
            {/*  />*/}
            {/*  Your Recent Purchases:*/}
            {/*</h3>*/}
            {data["LAST_THREE_PURCHASES"]["TIME_STOMPS"].length === 0 &&
            data["LAST_THREE_PURCHASES"]["PURCHASES"].length === 0 ? (
              <div className={styles.noPaymentsBox}>
                <p>You haven't made any purchases yet.</p>
              </div>
            ) : (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Purchase</th>
                  </tr>
                </thead>
                <tbody>
                  {data["LAST_THREE_PURCHASES"]["TIME_STOMPS"].map(
                    (item, i) => (
                      <tr key={item}>
                        <td>{i + 1}</td>
                        {/*<td>{formatCustomDate(item, "show")}</td>*/}
                        <td>{getRelativeTime(item)}</td>
                        <td>
                          {data["LAST_THREE_PURCHASES"]["PURCHASES"][i] / 100}{" "}
                          <span className={styles.paymentValue}>USD</span>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            )}
            <div className={styles.btns}>
              <Link className={styles.link} to="/pricing-billing/plans-list">
                Pricing Plan
              </Link>
            </div>
          </ContentContainer>
        )
      )}
    </div>
  );
}

export default PurchasesInfo;

import { message, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/Components/pricing-plan/StripeCheckoutModal.module.css";
import { useEffect, useState } from "react";
import { getCheckoutLink } from "../../redux/pricing-plan/action";
import { BiError } from "react-icons/bi";
import { FaCaretRight } from "react-icons/fa";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";
import moment from "moment";

const checkSubscriptionRemainingTime = (timestamp, differenceType) => {
  const date = moment.unix(timestamp);
  const currentDate = moment();
  const difference = currentDate.diff(date, differenceType);

  // check remaining time of monthly plan is equal or greater than one day or not
  if (differenceType === "hours") return Math.abs(difference) >= 24;
  // check remaining time of yearly plan is equal or greater than one month or not
  if (differenceType === "days") return Math.abs(difference) >= 30;
};

const canShowDailyPlan = (email) => {
  email = email.toLowerCase();
  return (
    email === "hadisematloob@yahoo.com" ||
    email === "mo.ghorbani.g@gmail.com" ||
    email === "cryptocrispy.ceo@gmail.com" ||
    email?.includes("@cryptocrispy.com.au")
  );
};

const planOptions = {
  basic: [
    { label: "Daily", id: "daily" },
    {
      label: "Monthly",
      id: "monthly",
    },
    {
      label: "Yearly",
      id: "yearly",
    },
  ],
  pro: [
    {
      label: "Daily",
      id: "daily_pro",
    },
    { label: "Monthly", id: "monthly_pro" },
    {
      label: "Yearly",
      id: "yearly_pro",
    },
  ],
};

function StripeCheckoutModal({ show, setShow, planType, prices }) {
  const {
    main: { userData },
    pricingPlan: {
      subscriptions,
      checkoutLoading,
      checkoutSession,
      checkoutError,
    },
  } = useSelector((state) => state);

  const { hasActivePro, hasActiveBasic, basicPlanData, proPlanData } =
    useSubscriptionPlans();

  const [selectedPlan, setSelectedPlan] = useState(null);

  const dispatch = useDispatch();
  const closeModal = () => {
    setSelectedPlan(null);
    setShow(false);
  };

  const relatedOptions = canShowDailyPlan(userData?.email)
    ? planOptions[planType]
    : planOptions[planType]?.filter((op) => !op.id?.includes("daily"));

  const availableOptions = relatedOptions?.map((op) => {
    const subscription = subscriptions[op.id];
    if (subscription && !subscription?.cancel_at) {
      return { ...op, status: "disable" };
    } else {
      return { ...op, status: "enable" };
    }
  });

  const handleSelectPlan = (plan) => {
    if (checkoutLoading) return message.info("Please wait..");

    if (planType === "basic" && hasActiveBasic) {
      // prevent user to select monthly plan if it has active yearly plan, and it has one or more month remaining
      if (
        hasActiveBasic.includes("yearly") &&
        plan.id === "monthly" &&
        checkSubscriptionRemainingTime(basicPlanData.current_period_end, "days")
      ) {
        return message.info(
          "Your Yearly plan has several months remaining. Please try again later",
        );
      }
      // prevent user to select monthly plan if it has active monthly plan, and it has one or more day remaining
      if (
        hasActiveBasic.includes("monthly") &&
        plan.id === "daily" &&
        checkSubscriptionRemainingTime(
          basicPlanData.current_period_end,
          "hours",
        )
      ) {
        return message.info(
          "Your Monthly plan has several days remaining. Please try again later",
        );
      }
    }

    if (planType === "pro" && hasActivePro) {
      // prevent user to select monthly plan if it has active yearly plan, and it has one or more month remaining
      if (
        hasActivePro.includes("yearly") &&
        plan.id === "monthly_pro" &&
        checkSubscriptionRemainingTime(basicPlanData.current_period_end, "days")
      ) {
        return message.info(
          "Your Yearly plan has several months remaining. Please try again later",
        );
      }
      // prevent user to select monthly plan if it has active monthly plan, and it has one or more day remaining
      if (
        hasActivePro.includes("monthly") &&
        plan.id === "daily_pro" &&
        checkSubscriptionRemainingTime(
          basicPlanData.current_period_end,
          "hours",
        )
      ) {
        return message.info(
          "Your Monthly plan has several days remaining. Please try again later",
        );
      }
    }

    if (plan.status === "disable") {
      return message.info(
        `You already have an active ${plan.label} subscription.`,
      );
    }

    if (
      (planType === "pro" && hasActivePro) ||
      (planType === "basic" && hasActiveBasic)
    )
      return Modal.confirm({
        title: "Are you sure?",
        centered: true,
        content:
          "If you change your subscription to the new plan, your current subscription will be cancelled.",
        okButtonProps: {
          style: { backgroundColor: "#0C4CFC", borderColor: "#0C4CFC" },
        },
        onOk() {
          setSelectedPlan(plan.id);
        },
      });

    setSelectedPlan(plan.id);
  };

  useEffect(() => {
    if (selectedPlan) {
      const redirectUrl = window.location.href;
      dispatch(
        getCheckoutLink(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
          selectedPlan,
          redirectUrl,
          redirectUrl,
        ),
      );
    }
  }, [dispatch, selectedPlan, userData]);

  return (
    <Modal
      footer={null}
      open={show}
      onCancel={closeModal}
      maskClosable={false}
      centered
    >
      <div className={styles.container}>
        <h3>
          <FaCaretRight /> Select a plan:
        </h3>
        <ul className={styles.options}>
          {availableOptions?.map((op) => (
            <li
              key={op.id}
              className={`${styles.option} ${
                op?.status === "disable" ? styles.disabled : ""
              } ${op.id === selectedPlan ? styles.selected : ""}`}
              onClick={() => handleSelectPlan(op)}
            >
              <span>{op.label}</span>
              <span className={styles.price}>
                {prices?.[op.label]?.toFixed(2)} USD
              </span>
            </li>
          ))}
        </ul>
        {selectedPlan && checkoutLoading && (
          <Spin size={"small"} tip={"Preparing checkout link..."} />
        )}
        {selectedPlan &&
          !checkoutLoading &&
          checkoutSession &&
          !checkoutError && (
            <a className={styles.buyBtn} href={checkoutSession}>
              Buy
            </a>
          )}
        {selectedPlan && !checkoutLoading && checkoutError && (
          <div className={styles.errorBox}>
            <BiError size={30} color={"#e84118"} />
            <p>Something went wrong! Please try again later.</p>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default StripeCheckoutModal;

import * as React from "react";

function DashboardCardTitle({ icon, text }) {
  return (
    <h3
      style={{
        marginBottom: 0,
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: "bold",
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{ width: "40px", marginRight: ".5rem" }}
      />
      {text}
    </h3>
  );
}

export default DashboardCardTitle;

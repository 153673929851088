import { RxDotFilled } from "react-icons/rx";
import styles from "../../../styles/Components/faq/GeneralQuestions.module.css";
import { HiOutlineRocketLaunch } from "react-icons/hi2";

// const questions = [
//   "AI-powered platform",
//   "Unique features",
//   "Plans comparison",
//   "AI accuracy",
//   "Automated trading",
//   "Other assets",
//   "Two portfolios",
//   "Real money",
//   "Social media",
// ];
const questions = [
  "CryptoCrispy: Exchange?",
  "CryptoCrispy's Uniqueness",
  "Spot and Futures?",
  "Data for Trading",
  "VIP Signals Access",
  "Automated Features",
  "Social Media Links",
  "Platform: FREE?",
];

const QuickAccessMenu = () => {
  return (
    <ul className={styles.quickAccess}>
      <h3
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <HiOutlineRocketLaunch />
        Quick Access:
      </h3>
      {questions.map((question, index) => (
        <li key={index} className={styles.quickItem}>
          <a
            href={`#q${index}`}
            onClick={(event) => {
              event.preventDefault();
              const faqItem = document.getElementById(`q${index}`);
              faqItem.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <RxDotFilled />
            {question}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default QuickAccessMenu;

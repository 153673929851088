import React from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/Help/index.module.sass";
import faqImg from "../../asset/Images/faq.png";
import { Row } from "antd";
import { useParams } from "react-router";
import FaqList from "../../components/faq/FaqList";
import GetPricePredictions from "../../components/faq/GetPricePredictions";
import HowUseInsights from "../../components/faq/HowUseInsights";
import PortfolioSection from "../../components/faq/PortfolioSection";
import TradeHistorySection from "../../components/faq/TradeHistorySection";
import AssistantSection from "../../components/faq/AssistantSection";
import AlertSystemGuide from "../../components/faq/AlertSystemGuide";
import GeneralQuestions from "../../components/faq/GeneralQuestions";
import BotFAQ from "../../components/faq/BotFAQ";
import AiAssistantFAQ from "../../components/faq/AiAssistantFAQ";
import IntegrationFAQ from "../../components/faq/IntegrationFAQ";
import exchange from "../../asset/Images/exchange.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";

// const { Panel } = Collapse;

const faqData = [
  {
    title: "AI Assistant",
    questions: [
      "Portfolio Section",
      "Trade History Section",
      "Assistant Section",
      "AI Assistant Frequently Asked Questions",
    ],
    links: [
      "portfolio-section",
      "trade-history-section",
      "assistant-section",
      "ai-assistant-faq",
    ],
    type: "AI_Assistant",
  },
  {
    title: "Bot and Automation",
    questions: ["Frequently Asked Questions"],
    links: ["bot-faq"],
    type: "Bot_and_Automation",
  },
  {
    title: "Integration",
    questions: ["Frequently Asked Questions"],
    links: ["integration-faq"],
    type: "Integration",
  },
  {
    title: "Alert System",
    questions: ["Step By Step Guide"],
    links: ["alert-system-guide"],
    type: "Alert_System",
  },
  {
    title: "Insights",
    questions: ["How to get AI price predictions?", "How to use the Insights?"],
    links: ["how-get-price-predictions", "how-use-insights"],
    type: "Insight",
  },
  {
    title: "Other Questions",
    questions: ["General Questions"],
    links: ["general-questions"],
    type: "",
  },
];

const Index = () => {
  const params = useParams();

  return (
    <DashboardLayout>
      <div className={styles.faqWrapper}>
        {params?.section === "all" && (
          <>
            <LazyLoadImage
              src={faqImg}
              alt="FAQ"
              effect="blur"
              wrapperClassName={styles.coverImg}
            />
            {/*<img*/}
            {/*  className={styles.coverImg}*/}
            {/*  src={faqImg}*/}
            {/*  alt="FAQ"*/}
            {/*  loading={"lazy"}*/}
            {/*/>*/}
            <Row
              gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              style={{ width: "100%", margin: "0 auto" }}
            >
              {faqData.map((item, index) => (
                <FaqList key={index} data={item} />
              ))}
            </Row>
          </>
        )}
        {params?.section === "how-get-price-predictions" && (
          <GetPricePredictions />
        )}
        {params?.section === "bot-faq" && <BotFAQ />}
        {params?.section === "integration-faq" && <IntegrationFAQ />}
        {params?.section === "ai-assistant-faq" && <AiAssistantFAQ />}
        {params?.section === "how-use-insights" && <HowUseInsights />}
        {params?.section === "portfolio-section" && <PortfolioSection />}
        {params?.section === "trade-history-section" && <TradeHistorySection />}
        {params?.section === "assistant-section" && <AssistantSection />}

        {params?.section === "alert-system-guide" && <AlertSystemGuide />}

        {params?.section === "general-questions" && <GeneralQuestions />}

        {/* <Collapse bordered={false} defaultActiveKey={['1']} className={styles.collapse}>
                <h3>Insights</h3>
                <Panel header="How to get AI price predictions? " key="1">
                    <p>Price predictions powered by AI can help you discover price actions and find trading opportunities. Follow these steps to access price predictions on the Insights platform: </p>
                    <p><span>Step 1:</span> Navigate to the <Link to={"/insight"}>Insights</Link> from the left-hand menu.</p>
                    <p><span>Step 2:</span> Select "AI-Signal" on the left side.</p>

                    <p><span>Step 3:</span> Select "Predicted Price" from the available options. </p>
                    <p><span>Step 4:</span> Choose the time interval for the prediction. For example, if you want to know the forecast for the next 6 hours, select "Next 6 Hours Price".
                    </p>
                    <p><span>Step 5:</span>  You will be prompted to choose the coin for which you want the prediction generated.

                        <p><span>Step 5-1:</span> Once you've selected your coin, you can view the historical predictions in the "Chart" area on the right-hand side. The current predictions can be seen in the "Signals" area on the right-hand side.
                        </p>

                        <p><span>Step 5-2:</span>
                            In the "Signals" area, we provide suggested buy/sell signals or long/short positions based on the prediction. You can find detailed information about the profitability of the signal and the prediction confidence in this area.
                        </p>
                        <p> <span>Step 5-3:</span>
                            After you've made your decision, you can go to your exchange and execute your trade. You can also save your trade actions on our platform to manage your portfolio and receive suggestions from our alert system. Please note that our platform does not offer trading functionalities.
                        </p>
                    </p>
                    <p>By following these steps, you can access price predictions powered by AI on the Insights platform and use them to inform your trading decisions.</p>
                </Panel>
                <Panel header="How to use the Insights? " key="2">
                    <p><span>Step 1:</span> Navigate to the <Link to={"/insight"}>Insights</Link> from the left-hand menu.</p>
                    <p><span>Step 2:</span> In Insights, there are several features that can be used to gain insight. Click on "Features" on the left side of the screen to see the available options. These consist of market features such as price, various technical indicators, various social indicators, on-chain indicators, AI signals, and more.</p>
                    <p><span>Step 3:</span> Select your desired feature to get insights. </p>
                    <p><span>Step 4:</span> Check the coin trends in the "Trends" section on the right side of the screen.</p>
                    <p><span>Step 5:</span>  In the "Signals" section on the right side of the screen, you will see suggested buy/sell signals or recommended long/short positions based on the platform's predictions. Detailed information about the profitability of the signal and the prediction confidence can also be found here.</p>
                    <p><span>Step 6:</span>  Once you've made your decision, you can go to your exchange and execute the trade. You can also save your trade actions in our platform to manage your portfolio and receive suggestions from the alert system. Please note that our platform does not offer trading functionalities.</p>
                </Panel>
                <h3 style={{ marginTop: "2rem" }}>AI Assistant</h3>
                <p style={{ fontSize: "1rem" }}>Welcome to the AI assistant of Cryptocrispy! In this tutorial, we'll walk you through the different features of our AI assistant and show you how to make the most of them.</p>
                <Panel header="Portfolio Section " key="3">
                    <p>In the Portfolio section, you can see your two separated portfolios. These portfolios are for testing and evaluating purposes only, and the trades are conducted with virtual money. Here's how you can use this section:</p>
                    <p>
                        <ul className={styles.list}>
                            <li>To view your portfolio's current holdings and their current market values, check the "Portfolio Summary" table.</li>
                            <li>To add a new asset to your portfolio, click on the "Buy/Sell" button and follow the prompts.</li>
                        </ul>
                    </p>
                </Panel>
                <Panel header="Trade History Section " key="4">
                    <p>In the Trade History section, you can see your past trades for each portfolio. Here's how you can use this section:</p>
                    <p>
                        <ul className={styles.list}>
                            <li>To view your trade history, click on the "Trade History" in the right-hand menu.</li>
                            <li>To export your trade history, click on the "Export" button.</li>
                        </ul>
                    </p>
                </Panel>
                <Panel header="Assistant Section " key="5">
                    <p>In the Assistant section, you can see AI assistant suggestions for your portfolios. These suggestions are based on a combination of AI and algorithmic approaches and are tailored to your current risk appetite. Here's how you can use this section:</p>
                    <p>
                        <ul className={styles.list}>
                            <li>To view the AI assistant suggestions, click on the "Assistant" in the right-hand menu.</li>
                        </ul>
                    </p>
                    <p>
                        Additionally, you can update your risk appetite by following these steps:
                        <p><span>Step 1:</span> Click on the "Risk Appetite" button located in the top right corner of the Assistant section.</p>
                        <p><span>Step 2:</span> You will be presented with 5 options to choose from: "Very Cautious", "Cautious", "Balanced", "Adventurous", and "Very Adventurous".</p>
                        <p><span>Step 3:</span> Select the option that best reflects your current risk appetite. </p>
                        <p><span>Step 4:</span> Click on the "Save" button to update your risk appetite.</p>
                        Once you update your risk appetite, the AI assistant will adjust its suggestions accordingly to match your risk preferences. Keep in mind that higher risk typically comes with higher potential rewards, but also higher potential losses. It's important to understand your risk tolerance and make informed decisions based on your own financial situation and goals.
                    </p>
                    <p>If you have any further questions or concerns about using the AI assistant of Cryptocrispy, don't hesitate to reach out to our support team.</p>
                </Panel>
                <h3 style={{ marginTop: "2rem" }}>Alert System</h3>
                <Panel header="Step By Step Guide " key="6">
                    <p>
                        Here is a step-by-step guide on how to use the alert system:
                        <p><span>1.</span> Alert System: The Alert System is where you can customize your alert preferences. You can revise your current (default) settings by clicking on the "Revise Settings" button.</p>
                        <p><span>2.</span> Tracked Signals: The Tracked Signals section allows you to check the list of signals that are being tracked. If you want to remove a signal from the list, simply click on the radio button before the category name of signal, and then click on the "Disable tracking of signal" button.</p>
                        <p><span>3.</span> Emails: The Emails section shows you the latest alert emails that have been sent to you.</p>
                    </p>
                    <p>
                        Now, let's go through the steps to customize your alert settings using the Alert System section:
                        <p><span>1.</span> Click on the "Revise Settings" button to customize your alert settings.</p>
                        <p><span>2.</span> Risk Appetite: Choose your preferred risk appetite from the list. You can choose from Very Cautious, Cautious, Balanced, Adventurous, or Very Adventurous.</p>
                        <p><span>3.</span> Alert Frequency: Choose your preferred alert frequency from the bu list. You can choose from Hourly, Every 3 hours, Every 6 hours, Every 12 hours, Daily, Twice a week, Weekly, Biweekly, or Monthly.</p>
                        <p>
                            <span>4.</span> Active Time Interval:
                            <p><span>4-1.</span> Choose your preferred active time interval from the drop-down list. You can choose from Morning (6am - 12pm), Afternoon (12pm - 6pm), Evening (6pm - 12am), Overnight (12am - 6am), or Custom time interval.</p>
                            <p><span>4-2.</span> Specific Time of Day (for Daily, Twice a week, Weekly, Biweekly, or Monthly alerts): If you have chosen a daily, twice a week, weekly, biweekly, or monthly alert frequency, specify the exact time of day within your chosen active time interval for receiving alerts by entering the Hour and Minute.</p>
                            <p><span>4-3.</span> Custom Time Interval: If you choose a custom time interval, specify the start and end time for your desired alert time interval.</p>
                        </p>
                        <p><span>5.</span> Portfolio Watchdog: Choose whether you want the portfolio watchdog for loss-making assets to be enabled or disabled by selecting "Yes" or "No".</p>
                        <p><span>6.</span> Percentage Decrease: Use the slider to select the percentage decrease in your portfolio balance that you would like to receive a warning for.</p>
                        <p><span>7.</span> Click on the "Submit" button to update your settings.</p>
                        <p><span>8.</span> Congratulations! You have successfully customized your alert settings.</p>
                    </p>
                    <p>That's it! You have now successfully customized your alert settings. If you want to check the list of signals being tracked, go to the Tracked Signals section. If you want to check the latest alert emails, go to the Emails section.</p>
                </Panel>
                <h3 style={{ marginTop: "2rem" }}>General Questions</h3>
                <Panel header="Questions " key="7">
                    <p>
                        <ul className={styles.list}>
                            <li>
                                <h4>Can you tell me more about CryptoCrispy's AI-powered data analytics platform?</h4>
                                <p>CryptoCrispy's platform is designed to help traders make informed decisions by providing deep market insights derived from various sources, including social media, technical indicators, whales, and blockchain transactions. The platform's AI engine integrates these signal sources to generate buy/sell signals that users can act on. In addition to AI price predictions and trade signals, the platform offers an alert system that can be customized to fit the user's risk appetite and delivered through email. The platform also includes a Trade Simulator that allows users to test trading strategies resulting from AI and other analytics, as well as portfolio management tools that monitor asset performance and provide real-time alerts.</p>
                            </li>
                            <li>
                                <h4>What sets CryptoCrispy apart from other data analytics platforms?</h4>
                                <p>CryptoCrispy stands out from other data analytics platforms by offering an AI-powered engine that integrates various signal sources to generate highly accurate buy/sell signals. The platform's alert system can be customized to fit the user's risk appetite, and real-time performance alerts are provided for portfolios. Additionally, the Trade Simulator allows users to test their strategies in a simulated environment before trading with real money. CryptoCrispy also offers a free plan that provides access to advanced features, making it a great option for both beginner and experienced traders.</p>
                            </li>
                            <li>
                                <h4>Can you explain how CryptoCrispy's Pro and Enterprise plans differ from the free plan?</h4>
                                <p>The free plan provides access to many of CryptoCrispy's advanced features for a period of 7 days. The Pro plan offers usage-based pricing starting at $5 and includes additional features such as unlimited signal alerts, a customizable dashboard, and unlimited portfolio tracking. The Enterprise plan is designed for larger organizations and includes unlimited usage of all CryptoCrispy's features, as well as personalized support and custom reporting. More information about the plans can be found at <Link to={"/pricing-plan"}>Pricing Plan</Link>.</p>
                            </li>
                            <li>
                                <h4>How accurate are CryptoCrispy's AI predictions?</h4>
                                <p>CryptoCrispy's AI engine generates highly accurate buy/sell signals compared to other methods. However, profitability can change over time and in different time frames. To learn more about the accuracy of CryptoCrispy's AI predictions, users can read the accuracy report provided on the platform.</p>
                            </li>
                            <li>
                                <h4>Do you offer automated trading through bots?</h4>
                                <p>No, CryptoCrispy does not offer automated trading done through bots. Users are in control of their trading decisions and must go to their exchange of choice to execute trades.</p>
                            </li>
                            <li>
                                <h4>Can users trade assets other than cryptocurrencies on CryptoCrispy's platform?</h4>
                                <p>Yes, users can create portfolios with assets from both crypto and forex markets.</p>
                            </li>
                            <li>
                                <h4>What is the benefit of having two portfolios in CryptoCrispy's platform for Pro users?</h4>
                                <p>Pro users have access to two portfolios, which allows them to evaluate both paper trading and backtesting in parallel. Free users, on the other hand, can only do this one at a time.</p>
                            </li>
                            <li>
                                <h4>Does CryptoCrispy use real money for trades?</h4>
                                <p>No, CryptoCrispy uses virtual money for trades in its platform, so there is no risk involved.</p>
                            </li>
                            <li>
                                <h4>How can users access CryptoCrispy's social media accounts?</h4>
                                <p>CryptoCrispy can be found on Instagram, Twitter, Facebook, Discord, Telegram, and LinkedIn. Users can access these accounts by searching for CryptoCrispy on each respective social media platform.</p>
                            </li>

                        </ul>
                    </p>
                </Panel>

            </Collapse> */}
      </div>
    </DashboardLayout>
  );
};

export default Index;

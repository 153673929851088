import { useSelector } from "react-redux";
import InputComponent from "../../../shared/connect-exchange/InputComponent";
import styles from "../../../styles/Components/mini-app/shared/IntegrationForm.module.css";
import { FaRegCirclePlay } from "react-icons/fa6";
import { message, Modal, Radio } from "antd";
import VideoPlayer from "../../../shared/video-player/VideoPlayer";
import { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

function IntegrationForm({
  exchange,
  setApiKey,
  apiKey,
  secretKey,
  setSecretKey,
  passphrase,
  setPassphrase,
  isRestricted,
  setIsRestricted,
}) {
  const {
    exchangeState: { exchangesConnectionInfo, trustedIps },
  } = useSelector((state) => state);

  const [openVideo, setOpenVideo] = useState(false);

  const connectionInfo = exchangesConnectionInfo.find(
    (ex) => ex.id === exchange,
  );

  const copyToClipboardHandler = () => {
    setIsRestricted(true);
    navigator.clipboard.writeText(trustedIps?.[0]);
    message.success("Copied to clipboard.");
  };

  return (
    <div className={styles.container}>
      <div className={styles.videoBtn} onClick={() => setOpenVideo(true)}>
        <span>Video Tutorial</span>
        <FaRegCirclePlay />
      </div>
      <InputComponent value={apiKey} label="API Key" setValue={setApiKey} />{" "}
      {connectionInfo?.mandatory?.includes("secret") ? (
        <InputComponent
          value={secretKey}
          label="Secret Key"
          setValue={setSecretKey}
        />
      ) : (
        ""
      )}
      {connectionInfo?.mandatory?.includes("passphrase") ? (
        <InputComponent
          value={passphrase}
          label="API Passphrase"
          setValue={setPassphrase}
        />
      ) : (
        ""
      )}
      {exchange !== "cryptocrispy" && (
        <div className={styles.restrictionBox}>
          <div className={styles.ipContainer}>
            <p>Copy the IP value below and add it to your exchange.</p>
            <div className={styles.ipBox} onClick={copyToClipboardHandler}>
              <span>{trustedIps?.[0]}</span>
              <span className={styles.copyOption}>
                <FaRegCopy style={{ marginRight: ".3rem" }} />
                Copy to clipboard
              </span>
            </div>
          </div>
          <Radio.Group
            value={isRestricted}
            onChange={(e) => setIsRestricted(e.target.value)}
          >
            <Radio value={false}>The API works for any IP.</Radio>
            <Radio value={true}>I added IP into whitelist.</Radio>
          </Radio.Group>
        </div>
      )}
      {/*{console.log(connectionInfo, exchange)}*/}
      {openVideo && (
        <Modal
          open={openVideo}
          maskClosable={false}
          footer={null}
          centered
          onCancel={() => setOpenVideo(false)}
        >
          <VideoPlayer videoLink={connectionInfo.video_url} />
        </Modal>
      )}
    </div>
  );
}

export default IntegrationForm;

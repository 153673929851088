import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/new-insight/NewInsight.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getInsightInitialDataNew } from "../../redux/new-insight/actions";
import InsightSignalsSection from "../../components/new-insight/InsightSignalsSection";
import { useParams } from "react-router";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";
import InsightChartSection from "../../components/new-insight/InsightChartSection";
import InsightWatchlist from "../../components/new-insight/InsightWatchlist";

function NewInsight() {
  const {
    main: { userData },
    newInsightState: {
      getInitialDataLoading,
      initialDataFetched,
      insightMarketType,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { section } = useParams();

  useEffect(() => {
    if (userData?.email && !initialDataFetched && !getInitialDataLoading) {
      dispatch(
        getInsightInitialDataNew(
          userData?.email,
          userData["custom:custom_username"],
          insightMarketType,
          "True",
        ),
      );
    }
  }, [
    dispatch,
    getInitialDataLoading,
    initialDataFetched,
    insightMarketType,
    userData,
  ]);

  return (
    <DashboardLayout hasDemo>
      <div className={styles.main}>
        <ServiceStatusBar
          status={profileData?.insai_active}
          expirationDate={profileData?.insai_active_until}
          service={"insai"}
          platform
        />

        {(!section || section === "signal") && <InsightSignalsSection />}
        {section === "chart" && <InsightChartSection />}

        {section === "watchlist" && <InsightWatchlist />}
      </div>
    </DashboardLayout>
  );
}

export default NewInsight;

import styles from "../../../styles/Components/mini-app/mini-pricing/ReferredUser.module.css";
import Avatar from "react-avatar";
import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { FaCreditCard, FaMoneyBill } from "react-icons/fa";

function ReferredUser({ data }) {
  const {
    addition_reward: earnedCredit,
    createdDate: date,
    cashable_reward: earnedMoney,
    referred_user_avatar: avatar,
    referred_user_email: email,
    referred_user_username: userName,
  } = data;

  // <FaCreditCard size={17} />
  // <FaMoneyBill size={17} />

  const formattedUserName = `${userName?.substring(0, 15)}${
    userName?.length > 15 ? "..." : ""
  }`;
  const formattedDate = moment(date).format("DD MMMM YYYY");

  return (
    <div className={styles.container}>
      <div className={styles.userBox}>
        <div className={styles.profileImage}>
          {avatar ? (
            <img
              className={styles.profileImg}
              src={avatar}
              alt="profile image"
            />
          ) : (
            <Avatar name={userName} size="100%" round={true} />
          )}
        </div>

        <div className={styles.userInfo}>
          <h3>
            <Tooltip title={userName} color={"geekblue"}>
              {formattedUserName}
            </Tooltip>
          </h3>
          <p>Joined at {formattedDate}</p>

          <div className={styles.earnedBox}>
            <p className={styles.title}>
              <FaMoneyBill />
              Earned Money:
            </p>
            <p className={styles.earnedValue}>${earnedMoney}</p>
          </div>

          <div className={styles.earnedBox}>
            <p className={styles.title}>
              <FaCreditCard />
              Earned Credit:
            </p>
            <p className={styles.earnedValue}>
              ${(earnedCredit / 100).toFixed(1)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferredUser;

import { useEffect } from "react";
import {
  getReferralCode,
  getReferralRewards,
} from "../../redux/pricing-plan/action";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/shared/service-activation/ReferralSuggestions.module.css";
import { FaKey, FaLink, FaMoneyBillWave } from "react-icons/fa";
import { BiSolidCopy } from "react-icons/bi";
import { message } from "antd";
import SectionDivider from "../../components/mini-app/layout/SectionDivider";
import { formatAmount } from "../../utility/helper";
import ShareButtons from "../../pages/pricing-plan/ShareButtons";

function ReferralSuggestions() {
  let {
    main: { userData },
    pricingPlan: {
      referralCode,
      referralCodeLoading,
      referralRewards,
      referralData,
      referralRewardsLoading,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const invitationLink = `https://t.me/cryptocrispy_bot?start=${referralCode}`;
  const copyReferralCode = () => {
    if (referralCodeLoading) return;

    navigator.clipboard.writeText(referralCode);
    message.success("Copied to clipboard.");
  };

  const copyInvitationLink = () => {
    if (referralCodeLoading) return;

    navigator.clipboard.writeText(invitationLink);
    message.success("Copied to clipboard.");
  };

  useEffect(() => {
    if (!referralCode && userData?.email) {
      dispatch(
        getReferralCode(userData?.email, userData?.["custom:custom_username"]),
      );
    }
  }, [dispatch, referralCode, userData]);

  useEffect(() => {
    if (userData?.email && !referralData?.email) {
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, referralData, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.referralGifBox}>
        <h3>
          <FaMoneyBillWave />
          Earned Money:
        </h3>
        {referralRewardsLoading && (
          <span className={styles.referralCodeInfo}>Checking...</span>
        )}
        {!referralRewardsLoading && (
          <span className={styles.referralCodeInfo}>
            $ {formatAmount(referralData?.total_cashable_credit)}
          </span>
        )}
      </div>

      <SectionDivider />

      <div className={styles.referralCodeBox}>
        <h4>
          <FaKey /> Referral Code:
        </h4>
        {referralCodeLoading && (
          <span className={styles.referralCodeInfo}>Loading...</span>
        )}
        {!referralCodeLoading && (
          <span className={styles.referralCodeInfo}>
            {referralCode} <BiSolidCopy className={styles.copyIcon} size={20} />
          </span>
        )}
      </div>

      <div className={styles.referralCodeBox} onClick={copyInvitationLink}>
        {/*<h4>*/}
        {/*  <FaLink /> Invitation Link:*/}
        {/*</h4>*/}
        {referralCodeLoading && (
          <span className={styles.referralCodeInfo}>Loading...</span>
        )}
        {/*{!referralCodeLoading && (*/}
        {/*  <>*/}
        {/*    <span className={styles.referralCodeInfo}>{invitationLink}</span>*/}

        {/*    <BiSolidCopy*/}
        {/*      className={styles.copyIcon}*/}
        {/*      size={20}*/}
        {/*      style={{ alignSelf: "flex-end", marginLeft: "auto" }}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}

        {!referralCodeLoading && <ShareButtons link={invitationLink} />}
      </div>
    </div>
  );
}

export default ReferralSuggestions;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/shared/bot-activation-process/DisplayBotStatus.module.css";
import { Button, Checkbox, Spin } from "antd";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  activateBot,
  checkBotStatus,
  sellAllCryptoAssets,
  sellAllFiatAssets,
} from "../../../../redux/bot/actions";
import { showWarningForSellCurrencies } from "../../../../utility/helper";
import ReintegrateForm from "../ReintegrateForm";
import { useNavigate } from "react-router-dom";

const loadingTexts = [
  "Evaluating Bot Activation Conditions",
  "Checking API Expirations",
  "Checking Exchange Portfolio",
  "Checking Previous Bots",
  "Checking Bot Configurations",
];

const errorCodes = ["431", "437", "438"];
const noteCodes = ["436", "435", "434", "433", "432"];
const integrationCodes = ["431", "432", "433", "434", "435", "438"];

function MinimalBotActivationProcess({ bot, closeModal }) {
  const {
    botState: {
      checkBotStatusLoading,
      checkBotStatusData,
      checkBotStatusError,
      activateBotLoading,
      sellAllFiatAssetsLoading,
      sellAllCryptoAssetsLoading,
      botList,
      getBotListLoading,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intervalRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showIntegrateForm, setShowIntegrateForm] = useState(false);

  const isError = errorCodes.includes(
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE,
  );
  const isNote = noteCodes.includes(
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE,
  );
  const isIntegrationError = integrationCodes.includes(
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE,
  );

  const needReintegration =
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE === "431" ||
    checkBotStatusData?.BOT_STATUS?.STATUS_CODE === "438";

  const notIntegrated = checkBotStatusData?.BOT_STATUS?.STATUS_CODE === "438";

  const targetBot = bot || botList[botList.length - 1];

  const statusMsg =
    !isError && !isNote
      ? "Your bot is ready to be activated."
      : isNote
      ? "Your bot is ready to be activated, but you can enhance its performance by following these suggestions:"
      : "The bot cannot be activated until you resolve these issues:";

  const checkBotStatusHandler = () => {
    dispatch(
      checkBotStatus(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetBot?.CreatedDate,
        targetBot?.bot_configurations?.selected_exchange,
      ),
    );
  };

  const toggleShowingSuggestion = () => {
    setShowSuggestion((show) => !show);
  };

  const handleSellAllCryptoAssets = () => {
    if (sellAllCryptoAssetsLoading) return;
    dispatch(
      sellAllCryptoAssets(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetBot?.bot_configurations?.selected_exchange,
      ),
    );
  };

  const handleSellAllFiatAsset = () => {
    if (sellAllFiatAssetsLoading) return;
    dispatch(
      sellAllFiatAssets(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetBot?.bot_configurations?.selected_exchange,
      ),
    );
  };

  const handleActivateBot = async () => {
    await dispatch(
      activateBot(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetBot?.CreatedDate,
      ),
    ).then(() => {
      if (closeModal) {
        closeModal();
        navigate("/mini-bots");
      }
    });
  };

  const handleUpdateForm = (e) => {
    if (e.target.checked) {
      setCurrentIndex(0);
      checkBotStatusHandler();
    }
  };

  useEffect(() => {
    if (checkBotStatusLoading) {
      intervalRef.current = setInterval(() => {
        if (currentIndex < loadingTexts.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }, 3000);
    }
    return () => clearInterval(intervalRef.current);
  }, [currentIndex, checkBotStatusLoading]);

  useEffect(() => {
    if (!getBotListLoading && botList?.length > 0) {
      checkBotStatusHandler();
    }
  }, [botList, getBotListLoading]);

  return (
    <div>
      {checkBotStatusLoading ? (
        <div className={styles.loadingBox}>
          <Spin />
          <h3>{loadingTexts[currentIndex]}</h3>
        </div>
      ) : Object.keys(checkBotStatusData).length > 0 ? (
        <div className={styles.statusBox}>
          <div>
            <h3>{statusMsg}</h3>
            {(isNote || isError) && (
              <div
                className={`${styles.detailsBox} ${
                  isNote ? styles.noteBox : styles.errBox
                }`}
              >
                <h4>
                  {isNote && <HiOutlineLightBulb color={"#4686ff"} size={19} />}
                  {isError && <AiOutlineWarning color={"#fd2727"} size={18} />}
                  {checkBotStatusData?.BOT_STATUS?.STATUS_TITLE}
                </h4>

                {isNote && (
                  <span
                    className={styles.expandBtn}
                    onClick={toggleShowingSuggestion}
                  >
                    {showSuggestion ? "Show less" : "Read more"}
                    {showSuggestion ? (
                      <MdOutlineExpandLess />
                    ) : (
                      <MdOutlineExpandMore />
                    )}
                  </span>
                )}
                {needReintegration && (
                  <Button
                    className={styles.integrationBtn}
                    onClick={() => setShowIntegrateForm(true)}
                  >
                    {notIntegrated ? "Integrate" : "Reintegrate"}
                  </Button>
                )}

                {showSuggestion && (
                  <div className={styles.suggestionBox}>
                    <p>{checkBotStatusData?.BOT_STATUS?.MESSAGE_TO_USER}</p>

                    {isIntegrationError && (
                      <div className={styles.actionBtns}>
                        {needReintegration && (
                          <Button
                            className={styles.integrationBtn}
                            onClick={() =>
                              setShowIntegrateForm((prevState) => !prevState)
                            }
                          >
                            {notIntegrated ? "Integrate" : "Reintegrate"}
                          </Button>
                        )}
                        {checkBotStatusData?.BOT_STATUS?.STATUS_CODE ===
                          "435" && (
                          <Button
                            className={styles.sellBtn}
                            loading={sellAllFiatAssetsLoading}
                            onClick={handleSellAllFiatAsset}
                          >
                            Sell All Fiat Currencies
                          </Button>
                        )}
                        {checkBotStatusData?.BOT_STATUS?.STATUS_CODE ===
                          "433" && (
                          <Button
                            className={styles.sellBtn}
                            loading={sellAllCryptoAssetsLoading}
                            onClick={handleSellAllCryptoAssets}
                          >
                            Sell All Crypto Assets
                          </Button>
                        )}
                      </div>
                    )}

                    {isIntegrationError && (
                      <div className={styles.checkFixIssue}>
                        <Checkbox onChange={handleUpdateForm} />I fix the issue.
                      </div>
                    )}
                  </div>
                )}

                {showIntegrateForm && (
                  <ReintegrateForm
                    exchange={targetBot?.bot_configurations?.selected_exchange}
                    checkStatus={checkBotStatusHandler}
                    buttonTitle={notIntegrated ? "Integrate" : "Reintegrate"}
                  />
                )}
              </div>
            )}
            <div className={styles.btns}>
              <div className={styles.actionButtons}>
                {!isError && (
                  <Button
                    className={styles.activateBtn}
                    loading={activateBotLoading}
                    onClick={handleActivateBot}
                  >
                    Activate
                  </Button>
                )}
                {/*<Button onClick={closeModal}>Close</Button>*/}
              </div>
            </div>
          </div>
        </div>
      ) : (
        checkBotStatusError && (
          <div className={styles.errorBox}>
            <IoCloseCircleSharp color={"red"} size={35} />
            <h3 style={{ textAlign: "center" }}>
              Something went wrong. Please try again later.
            </h3>
            {/*<Button onClick={closeModal}>Close</Button>*/}
          </div>
        )
      )}
    </div>
  );
}

export default MinimalBotActivationProcess;

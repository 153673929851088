import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import React from "react";
import styles from "../../styles/Components/dashboard/DashboardStatusbar.module.css";
import AccountCreditInfo from "./statusbar/AccountCreditInfo";
import ReferralsInfo from "./statusbar/ReferralsInfo";
import SubscriptionInfo from "./statusbar/SubscriptionInfo";
import ServicesInfo from "./statusbar/ServicesInfo";

function DashboardStatusbar2() {
  const {
    dashboardData: {
      fastDashboardLoading,
      fastDashboardData,
      loading: dashboardLoading,
      data: dashboardData,
    },
  } = useSelector((state) => state);

  const isContentLoading =
    fastDashboardLoading &&
    Object.keys(fastDashboardData).length === 0 &&
    dashboardLoading &&
    Object.keys(dashboardData).length === 0;

  const contentData = !isContentLoading
    ? Object.keys(fastDashboardData).length > Object.keys(dashboardData).length
      ? fastDashboardData
      : dashboardData
    : {};

  return (
    <div className={styles.container}>
      {isContentLoading ? (
        <>
          {Array.from({ length: 4 }).map((_, i) => (
            <Skeleton.Input active={true} width={"100%"} key={i} />
          ))}
        </>
      ) : (
        <>
          <AccountCreditInfo
            loading={isContentLoading}
            credit={contentData?.credit}
          />
          <ReferralsInfo />
          <SubscriptionInfo />
          <ServicesInfo />
        </>
      )}
    </div>
  );
}

export default DashboardStatusbar2;

import { useSelector } from "react-redux";

import styles from "../../styles/Components/pricing-plan/Header.module.css";
import { RxCalendar } from "react-icons/rx";
import { TbCalendarCancel, TbFileInvoice } from "react-icons/tb";
import { Spin } from "antd";
import { getCustomRemainingTime } from "../../utility/formatter-helper";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";

// subscription_status

function Header() {
  const {
    profile: { profileData },
    pricingPlan: { getSubscriptionLoading },
  } = useSelector((state) => state);

  const {
    hasPlan,
    hasProPlan,
    hasBasicPlan,
    basicIcon,
    proPlan,
    basicPlan,
    proPlanData,
    basicPlanData,
    proIcon,
    proColor,
    basicColor,
  } = useSubscriptionPlans();

  return (
    <div className={styles.container}>
      {profileData.loading ? (
        <Spin />
      ) : (
        <>
          <div className={styles.textBox}>
            <h2 className={styles.textBoxTitle}>Current plan:</h2>
            {getSubscriptionLoading ? (
              <p className={styles.loader}>loading...</p>
            ) : hasPlan ? (
              <div className={styles.plansList}>
                {hasProPlan && (
                  <span className={styles.textBoxInfoTitle}>{proIcon} Pro</span>
                )}
                {hasBasicPlan && (
                  <span className={styles.textBoxInfoTitle}>
                    {basicIcon} Basic
                  </span>
                )}
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.textBox}>
            <h2 className={styles.textBoxTitle}>Subscription:</h2>
            {getSubscriptionLoading ? (
              <p className={styles.loader}>loading...</p>
            ) : hasPlan ? (
              <div className={styles.subscriptionList} style={{ gap: ".5rem" }}>
                {proPlan && (
                  <div className={styles.info}>
                    <RxCalendar color={proColor} />
                    <span className={styles.textBoxInfoTitle}>{proPlan}</span>
                  </div>
                )}

                {basicPlan && (
                  <div className={styles.info}>
                    <RxCalendar color={basicColor} />
                    <span className={styles.textBoxInfoTitle}>{basicPlan}</span>
                  </div>
                )}
              </div>
            ) : (
              <p className={styles.loader}>No subscription</p>
            )}
          </div>

          <div className={styles.textBox}>
            <h2 className={styles.textBoxTitle}>Next invoice:</h2>
            {getSubscriptionLoading ? (
              <p className={styles.loader}>loading...</p>
            ) : hasPlan ? (
              <div className={styles.subscriptionList} style={{ gap: ".5rem" }}>
                <div className={styles.textBoxInfo} style={{ marginBottom: 0 }}>
                  {!proPlanData?.canceled_at && (
                    <TbFileInvoice color={proColor} />
                  )}
                  <span className={styles.textBoxInfoTitle}>
                    {!proPlanData?.canceled_at &&
                      getCustomRemainingTime(
                        new Date(proPlanData.current_period_end * 1000),
                        "",
                        "In",
                      )}

                    {proPlanData?.cancel_at && (
                      <span style={{ display: "flex", gap: ".2rem" }}>
                        {" "}
                        <TbCalendarCancel color={proColor} />
                        Will cancel after{" "}
                        {getCustomRemainingTime(
                          proPlanData?.current_period_end * 1000,
                        )}
                      </span>
                    )}
                  </span>
                </div>

                <div className={styles.textBoxInfo} style={{ marginBottom: 0 }}>
                  {!basicPlanData?.canceled_at && (
                    <TbFileInvoice color={basicColor} />
                  )}
                  <span className={styles.textBoxInfoTitle}>
                    {!basicPlanData?.canceled_at &&
                      getCustomRemainingTime(
                        new Date(basicPlanData.current_period_end * 1000),
                        "",
                        "In",
                      )}

                    {basicPlanData?.cancel_at && (
                      <span
                        style={{
                          display: "flex",
                          gap: ".2rem",
                          alignItems: "baseline",
                          justifyContent: "center",
                        }}
                      >
                        <TbCalendarCancel color={basicColor} />
                        Will cancel after{" "}
                        {getCustomRemainingTime(
                          basicPlanData?.current_period_end * 1000,
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <p className={styles.loader}>No subscription</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Header;

import { useSelector } from "react-redux";

import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategySidebar.module.css";
import { FiInfo } from "react-icons/fi";
import { Menu, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useStrategy } from "./useCreateStrategyContext";

function CreateStrategySidebar() {
  const {
    newInsightState: { categories, categoriesTooltip, categoriesFeatureTree },
  } = useSelector((state) => state);

  const {
    state: { selectedCategory, selectedSubcategory },
    setCategory,
    setSubcategory,
    searchStrategy,
  } = useStrategy();
  const handleClick = (ctg) => {
    setCategory(ctg);
    const firstSubcategory = Object.keys(categoriesFeatureTree?.[ctg])[0];
    setSubcategory(firstSubcategory);
    searchStrategy("");
  };

  useEffect(() => {
    if (
      Object.keys(categoriesFeatureTree).length > 0 &&
      !selectedCategory &&
      !selectedSubcategory
    ) {
      const firstSubcategory = Object.keys(
        categoriesFeatureTree?.[categories[0]],
      )[0];
      setCategory(categories[0]);
      setSubcategory(firstSubcategory);
    }
  }, [
    categories,
    categoriesFeatureTree,
    selectedCategory,
    selectedSubcategory,
    setCategory,
    setSubcategory,
  ]);

  return (
    <>
      <Menu
        mode="horizontal"
        className={styles.menu}
        selectedKeys={[selectedCategory]}
      >
        {categories.map((ctg) => (
          <Menu.Item
            key={ctg}
            onClick={() => handleClick(ctg)}
            className={`${styles.categoryItem} ${
              selectedCategory === ctg ? styles.selectedItem : ""
            }`}
          >
            <div className={styles.categoryName}>
              <img
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_category_icons/${ctg}.png`}
                alt={ctg}
                className={styles.categoryIcon}
              />
              {ctg}
            </div>
            <Tooltip
              title={categoriesTooltip[ctg]}
              color={"geekblue"}
              placement={"bottom"}
            >
              <FiInfo className={styles.infoIcon} />
            </Tooltip>
          </Menu.Item>
        ))}
      </Menu>

      <ul className={styles.list}>
        {categories?.map((ctg) => (
          <li
            className={`${styles.categoryItem} ${
              selectedCategory === ctg ? styles.selectedItem : ""
            }`}
            key={ctg}
            onClick={() => handleClick(ctg)}
          >
            <div className={styles.categoryName}>
              <img
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_category_icons/${ctg}.png`}
                alt={ctg}
              />
              {ctg}
            </div>
            <Tooltip
              title={categoriesTooltip[ctg]}
              color={"geekblue"}
              placement={"bottom"}
            >
              <FiInfo />
            </Tooltip>
          </li>
        ))}
      </ul>
    </>
  );
}

export default CreateStrategySidebar;

import React, { useEffect, useState } from "react";
import { Button, Input, InputNumber, message, Modal, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, updateProfile } from "../../redux/profile/actions";
import { currencyFormatter, showLinkingWarning } from "../../utility/helper";
import { FiAlertCircle } from "react-icons/fi";
import styles from "../../styles/shared/trade-modal/RechargeBudget.module.css";
import { getVirtualPortfolio } from "../../redux/trade/actions";
import { useNavigate } from "react-router-dom";
import { setVirtualPortfolio } from "../../redux/trading-assistant/actions";
import { unlinkFromExchange } from "../../redux/exchange/actions";

function RechargeBudget({ portfolio, neededBudget, type }) {
  const {
    main: { userData },
    profile: { profileData, updateLoading },
  } = useSelector((state) => state);

  const [budget, setBudget] = useState(neededBudget);
  const [budgetMax, setBudgetMax] = useState(neededBudget * 1.2);

  const [firstPortfolioMinBudget, setFirstPortfolioMinBudget] = useState(0);
  const [secondPortfolioMinBudget, setSecondPortfolioMinBudget] = useState(0);
  const [sliderMin, setSliderMin] = useState(0.1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const handleChangeBudget = (value) => {
    // Handle budget change for InputNumber
    setBudget(value);

    if (value >= budgetMax) {
      setBudgetMax((prevState) => value * 1.2);
    }
  };

  const handleUpdateBudget = async () => {
    let attribute_names = [];
    let newBudget;

    if (
      (portfolio === "primary" && isFirstPortfolioConnected) ||
      (portfolio === "secondary" && isSecondPortfolioConnected)
    ) {
      return showLinkingWarning();
    }
    // let minBudget;
    // if (portfolio === "primary") {
    //   minBudget = firstPortfolioMinBudget;
    // } else {
    //   minBudget = secondPortfolioMinBudget;
    // }

    if (portfolio === "primary") {
      attribute_names.push("investment_budget_portfolio1");
      newBudget =
        budget -
        Number(profileData?.REMANINING_INVESTMENT_BUDGET_PORTFOLIO1) +
        Number(profileData?.INVESTMENT_BUDGET_PORTFOLIO1);
    } else {
      attribute_names.push("investment_budget_portfolio2");
      newBudget =
        budget -
        Number(profileData?.REMANINING_INVESTMENT_BUDGET_PORTFOLIO2) +
        Number(profileData?.INVESTMENT_BUDGET_PORTFOLIO2);
    }

    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [`${newBudget}`],
      ),
    );

    if (type === "setting") {
      navigate("/ai-assistant/portfolio");
      dispatch(setVirtualPortfolio(portfolio));
    } else {
      dispatch(
        getVirtualPortfolio(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          portfolio,
        ),
      );
    }

    // TODO: CLEAR INPUT
    setBudget("");
  };

  // Update minimum budget with profile data
  useEffect(() => {
    if (profileData.investment_budget_portfolio1) {
      const minBudget1 = +parseFloat(
        profileData.REMANINING_INVESTMENT_BUDGET_PORTFOLIO1,
      ).toFixed(2);

      const minBudget2 = +parseFloat(
        profileData.REMANINING_INVESTMENT_BUDGET_PORTFOLIO2,
      ).toFixed(2);

      setFirstPortfolioMinBudget(minBudget1);
      setSecondPortfolioMinBudget(minBudget2);

      if (portfolio === "primary") {
        if (minBudget1 >= 5) {
          // setBudget(neededBudget + minBudget1);
          // setBudgetMax((neededBudget + minBudget1) * 1.2);
          setBudget(minBudget1);
          setBudgetMax(minBudget1 * 1.2);
        } else {
          if (minBudget1 === 0) {
            setBudget(2.5);
          } else {
            setBudget(minBudget1);
            setSliderMin(0);
          }
          setBudgetMax(5);
        }
      } else {
        if (minBudget2 >= 5) {
          // setBudget(neededBudget + minBudget2);
          // setBudgetMax((neededBudget + minBudget2) * 1.2);
          setBudget(minBudget2);
          setBudgetMax(minBudget2 * 1.2);
        } else {
          if (minBudget2 === 0) {
            setBudget(2.5);
          } else {
            setBudget(minBudget2);
            setSliderMin(0);
          }
          setBudgetMax(5);
        }
      }
    }
  }, [neededBudget, portfolio, profileData]);

  useEffect(() => {
    if (userData?.email && type !== "setting") {
      dispatch(
        getProfileData(userData?.email, userData?.["custom:custom_username"]),
      );
    }
  }, [dispatch, type, userData]);

  return (
    <div className={styles.container}>
      {type !== "setting" && (
        <h4 className={styles.alertMessage}>
          <FiAlertCircle />
          You didn't have enough budget for this trade, set a new budget.
          <br />
          Needed budget for this trade: {currencyFormatter(neededBudget)}
        </h4>
      )}

      <div className={styles.currentBudget}>
        <h4 style={{ marginRight: ".3rem" }}>USDT Balance:</h4>{" "}
        <p>
          {portfolio === "primary"
            ? currencyFormatter(
                profileData.REMANINING_INVESTMENT_BUDGET_PORTFOLIO1,
              )
            : currencyFormatter(
                profileData.REMANINING_INVESTMENT_BUDGET_PORTFOLIO2,
              )}{" "}
          USD
        </p>
      </div>
      <p style={{ marginBottom: 0 }}>
        Please enter the new investment budget for the{" "}
        {portfolio === "primary" ? "first" : "second"} portfolio:
      </p>
      <div style={{ display: "flex", gap: "15px" }}>
        <InputNumber
          placeholder={"Budget"}
          style={{
            borderRadius: ".4rem",
            width: "150px",
          }}
          min={sliderMin}
          value={budget}
          step={1}
          onChange={handleChangeBudget}
        />

        <Slider
          min={sliderMin}
          max={budgetMax}
          step={1}
          value={parseFloat(budget)}
          onChange={handleChangeBudget}
          style={{ width: "70%" }}
        />
      </div>

      <div className={styles.submitBtn}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleUpdateBudget}
          loading={updateLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default RechargeBudget;

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBacktestReport, selectBackteset } from "../../../redux/bot/actions";
import styles from "../../../styles/Components/bot/bot-backtest/BacktestReport.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { Collapse, Spin } from "antd";
import TradeReportTable from "./reports/TradeReportTable";
import { AiFillTrophy, AiFillWarning } from "react-icons/ai";
import { GoIssueClosed } from "react-icons/go";
import { FaSackDollar } from "react-icons/fa6";
import SummaryCard from "./reports/SummaryCard";

const { Panel } = Collapse;

function BacktestReport() {
  const {
    botState: { selectedBacktest, backtesetReportData, backtestReportLoading },
    main: { userData },
  } = useSelector((state) => state);

  const [tradeReport, setTradeReport] = useState(null);
  const [activePanel, setActivePanel] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoBack = () => {
    navigate("/bot-automation/backtest");
    dispatch(selectBackteset({}));
  };

  const changeActivePanel = (keys) => {
    setActivePanel(keys);
  };

  useEffect(() => {
    if (!id || Object.keys(selectedBacktest).length === 0) {
      navigate("/bot-automation/backtest");
    }
  }, [id, navigate, selectedBacktest]);

  useEffect(() => {
    if (Object.keys(selectedBacktest).length) {
      dispatch(
        getBacktestReport(
          userData["custom:custom_username"],
          userData["cognito:username"],
          selectedBacktest.createdDate,
        ),
      );
    }
  }, [dispatch, selectedBacktest]);

  useEffect(() => {
    if (!backtestReportLoading && Object.keys(backtesetReportData).length) {
      const data = JSON.parse(backtesetReportData.trade_report);
      const reportData = Object.entries(data).map(([currency, details]) => {
        return {
          currency,
          ...details,
        };
      });
      setTradeReport(reportData);
      // setActiveKey(reportData[0].currency);
    }
  }, [backtesetReportData, backtestReportLoading]);

  return (
    <div className={styles.container}>
      <span className={styles.logo}>CryptoCrispy</span>
      <div className={styles.backBtn} onClick={handleGoBack}>
        <FaArrowLeft />
      </div>
      <h3 className={styles.title}>Backtest Report</h3>

      {backtestReportLoading && (
        <Spin className={styles.loader} tip={"Please wait"} />
      )}

      {!backtestReportLoading &&
        Object.keys(backtesetReportData).length > 0 && (
          <div className={styles.header}>
            <SummaryCard />

            <img
              src={`data:image/png;base64,${backtesetReportData?.plot_image_base64}`}
              alt={"plot image"}
              className={styles.plotImg}
            />
          </div>
        )}

      {!backtestReportLoading &&
        Object.keys(backtesetReportData).length > 0 &&
        tradeReport &&
        tradeReport.map((item, i) => (
          <Collapse
            key={item.currency}
            bordered={false}
            activeKey={activePanel}
            onChange={changeActivePanel}
          >
            <Panel
              header={
                <div className={styles.panelHeader}>
                  <ul className={styles.panelInfo}>
                    <li className={styles.coinName}>
                      <img
                        src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item.currency}.png`}
                        alt={item.currency}
                        className={styles.coinIcon}
                      />
                      {item.currency}
                    </li>{" "}
                    -{" "}
                    <li>
                      <AiFillTrophy color={"#f1c40f"} />
                      Win Rate:{" "}
                      <span
                        style={{
                          color: `${
                            item.win_rate >= 50 ? "#16a085" : "#e74c3c"
                          }`,
                        }}
                      >
                        {item.win_rate}%
                      </span>
                    </li>
                    |{" "}
                    <li>
                      <GoIssueClosed color={"#0C4CFC"} />
                      Closed Positions: {item.closed_positions}
                    </li>{" "}
                    |{" "}
                    <li>
                      <FaSackDollar color={"#16a085"} />
                      Profitable Positions: {item.profitable_positions}
                    </li>{" "}
                    |{" "}
                    <li>
                      <AiFillWarning color={"#e74c3c"} />
                      Non-Profitable Positions: {item.non_profitable_positions}
                    </li>
                  </ul>
                  <div className={styles.moreBtn}>
                    {activePanel.includes(item.currency) ? "Hide" : "See"} Order
                    History
                    {/*<MdKeyboardDoubleArrowRight />*/}
                  </div>
                </div>
              }
              key={item.currency}
              className={styles.panel}
              // style={{ backgroundColor: "white" }}
            >
              <h3>Order History</h3>
              <TradeReportTable data={item.trade_history} />
            </Panel>
          </Collapse>
        ))}
    </div>
  );
}

export default BacktestReport;

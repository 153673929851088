import AlertSettingStepHeader from "./AlertSettingStepHeader";
import styles from "../../../styles/Components/alert-system/alert-setting/AlertSettingsStep.module.css";
import { useEffect, useState } from "react";
import { InputNumber } from "antd";

const precentages = ["0.1", "0.5", "1", "2", "5"];

function AlertPortfolioSetting({
  assetInDanger,
  setPortfolioSetting,
  balanceThresh,
}) {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const changeAssetInDangerHandler = (e) => {
    const buttonEl = e.target.closest("li");

    if (buttonEl) {
      setPortfolioSetting("assetInDanger", buttonEl.id);
      if (buttonEl.id === "No") {
        setShowCustomInput(false);
        setPortfolioSetting("balanceThresh", "0");
      }
    }
  };

  const changeBalanceThreshHandler = (e) => {
    const buttonEl = e.target.closest("li");

    if (buttonEl) {
      const threshValue = buttonEl.id;
      setPortfolioSetting("balanceThresh", "");

      if (threshValue === "custom") {
        setShowCustomInput(true);
      } else {
        setShowCustomInput(false);
        setPortfolioSetting("balanceThresh", threshValue);
      }
    }
  };

  useEffect(() => {
    if (assetInDanger === "Yes") {
      if (!precentages.includes(balanceThresh) && balanceThresh !== "0")
        setShowCustomInput(true);
    }
  }, [balanceThresh, assetInDanger]);

  return (
    <div>
      <AlertSettingStepHeader title={"Portfolio"} />
      <p className={styles.question}>
        Would you like us to notify you of your risky positions in your
        portfolio #1?
      </p>

      <ul className={styles.optionsBtn} onClick={changeAssetInDangerHandler}>
        <li
          id={"Yes"}
          className={`${styles.blueBtn} ${
            assetInDanger === "Yes" ? styles.selectedBtn : ""
          }`}
        >
          Yes
        </li>
        <li
          id={"No"}
          className={`${styles.blueBtn} ${
            assetInDanger === "No" ? styles.selectedBtn : ""
          }`}
        >
          No
        </li>
      </ul>

      {assetInDanger === "Yes" && (
        <div className={styles.balanceThreshBox}>
          <p className={styles.question}>
            At what percentage decrease in your portfolio balance should we
            notify you for a review?
          </p>
          <ul
            className={styles.optionsBtn}
            style={{ margin: "2rem auto" }}
            onClick={changeBalanceThreshHandler}
          >
            {precentages.map((p) => (
              <li
                key={p}
                id={p}
                className={`${styles.blueBtn} ${
                  balanceThresh === p ? styles.selectedBtn : ""
                }`}
              >
                {p}%
              </li>
            ))}
            <li
              id={"custom"}
              className={`${styles.blueBtn} ${
                showCustomInput ? styles.selectedBtn : ""
              }`}
            >
              Custom
            </li>
          </ul>
        </div>
      )}

      {showCustomInput && (
        <div className={styles.customInput}>
          <span className={styles.blueButton}>Enter a custom percentage:</span>
          <InputNumber
            min={0.1}
            max={100}
            step={0.1}
            // placeholder={"Balance threshold"}
            value={balanceThresh !== "0" ? Math.abs(balanceThresh) : ""}
            onChange={(e) =>
              setPortfolioSetting("balanceThresh", `${e || 0.1}`)
            }
          />
        </div>
      )}
    </div>
  );
}

export default AlertPortfolioSetting;

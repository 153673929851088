import BotSectionTitle from "./BotSectionTitle";
import React, { useEffect, useState } from "react";
import BotsTable from "./bot-configuration/BotsTable";
import styles from "../../styles/Components/bot/BotConfiguration.module.css";
import CreateBotWizard from "./bot-configuration/CreateBotWizard";
import { Button } from "antd";
import BotDetailsModal from "./bot-configuration/BotDetailsModal";
import { AiOutlineStop } from "react-icons/ai";
import { IoPlayOutline } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import ConfirmationModal from "./ConfirmationModal";
import { checkBotStatus, deactivateBot } from "../../redux/bot/actions";
import { useDispatch, useSelector } from "react-redux";
import alert from "../alerts/Alert";
import DisplayBotStatus from "../../shared/bot-activation-process/DisplayBotStatus";
import { getIntegratedExchanges } from "../../redux/exchange/actions";
import ActivateBotServiceModal from "./ActivateBotServiceModal";
import { RiLockFill } from "react-icons/ri";

function BotConfiguration({ hideDemo, showDemo }) {
  const {
    botState: { activateBotLoading, deactivateBotLoading, deleteBotLoading },
    main: { userData },
    exchangeState: { integratedData, getIntegratedList, integratedDataLoading },
    profile: { profileData },
  } = useSelector((state) => state);

  const [contentMode, setContentMode] = useState("default");
  const [selectedBot, setSelectedBot] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showBotActivationModal, setShowBotActivationModal] = useState(false);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const dispatch = useDispatch();
  const connectedExchange = selectedBot?.bot_configurations?.selected_exchange;

  const isServiceActive = profileData?.mybot_active === "True";

  const handleBotActivity = (status) => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }

    if (status === "inactive") {
      if (connectedExchange === "None") {
        return alert(
          "",
          "You cannot activate this bot because it is not integrated with any exchange.",
          "info",
          {
            cancel: "Ok",
          },
        );
      }
      dispatch(
        checkBotStatus(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedBot?.CreatedDate,
          selectedBot?.bot_configurations?.selected_exchange,
        ),
      );
      setShowBotActivationModal(true);
    } else {
      dispatch(
        deactivateBot(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedBot?.CreatedDate,
        ),
      );
    }
  };

  useEffect(() => {
    if (
      Object.keys(userData).length &&
      !integratedDataLoading &&
      !getIntegratedList
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    dispatch,
    integratedData,
    getIntegratedList,
    integratedDataLoading,
    userData,
  ]);

  useEffect(() => {
    if (contentMode !== "default") {
      hideDemo();
    } else {
      showDemo();
    }
  }, [contentMode]);

  return (
    <div className={styles.container}>
      {contentMode !== "createBot" && (
        <BotSectionTitle title={"Bot Configurations"}>
          Create your bots or activate the one you want
        </BotSectionTitle>
      )}
      {contentMode !== "createBot" && (
        <ul className={styles.contentOptions}>
          {Object.keys(selectedBot).length > 0 && (
            <li
              className={styles.deleteBtn}
              onClick={() => setShowDeleteConfirmationModal(true)}
            >
              <Button
                className={`${styles.deleteBtn} ${styles.btn}`}
                loading={deleteBotLoading}
              >
                <FaRegTrashCan />
                Delete
              </Button>
            </li>
          )}
          {Object.keys(selectedBot).length > 0 && (
            <li
              className={`${
                selectedBot.bot_status === "inactive"
                  ? styles.activateBtn
                  : styles.deactivateBtn
              }`}
              onClick={() => handleBotActivity(selectedBot.bot_status)}
            >
              <Button
                className={`${styles.btn} ${
                  selectedBot.bot_status === "inactive"
                    ? styles.activateBtn
                    : styles.deactivateBtn
                }`}
                type={"primary"}
                disabled={!Object.keys(selectedBot).length}
                loading={
                  selectedBot.bot_status === "inactive"
                    ? activateBotLoading
                    : deactivateBotLoading
                }
              >
                {!isServiceActive && <RiLockFill />}
                {selectedBot.bot_status === "inactive" ? (
                  <>
                    {isServiceActive && <IoPlayOutline />}
                    Activate
                  </>
                ) : (
                  <>
                    {!deactivateBotLoading && isServiceActive && (
                      <AiOutlineStop />
                    )}
                    Deactivate
                  </>
                )}
              </Button>
            </li>
          )}
          <li
            className={`${contentMode === "createBot" ? styles.active : ""}`}
            onClick={() => setContentMode("createBot")}
          >
            <Button className={`${styles.btn} ${styles.createBtn}`}>
              Create Bot
            </Button>
          </li>
        </ul>
      )}

      <div>
        {contentMode === "details" && <div>details</div>}
        {contentMode === "createBot" && (
          <CreateBotWizard setDisplayMode={setContentMode} />
        )}
      </div>
      {contentMode !== "createBot" && (
        <BotsTable
          handleBotSelection={setSelectedBot}
          selectedBot={selectedBot}
        />
      )}

      <BotDetailsModal
        data={selectedBot}
        setShow={setShowDetailsModal}
        show={showDetailsModal}
      />

      <DisplayBotStatus
        show={showBotActivationModal}
        onClose={setShowBotActivationModal}
        botId={selectedBot?.CreatedDate}
        exchangeId={selectedBot?.bot_configurations?.selected_exchange}
      />

      <ConfirmationModal
        title={"Are you sure"}
        setShow={setShowDeleteConfirmationModal}
        show={showDeleteConfirmationModal}
        id={selectedBot?.CreatedDate}
      />

      {showServiceActivationModal && (
        <ActivateBotServiceModal
          setShowActivationModal={setShowServiceActivationModal}
          showActivationModal={showServiceActivationModal}
        />
      )}
    </div>
  );
}

export default BotConfiguration;

import styles from "../../styles/Components/dashboard/BotPerformanceSummary.module.css";
import { Empty, Modal, Spin } from "antd";
import BotPerformanceChart from "./bot-performance/BotPerformanceChart";
import botIcon from "../../asset/Icons/robot (1).gif";
import BotPerformanceDetails from "./bot-performance/BotPerformanceDetails";
import { useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { Link } from "react-router-dom";
import * as React from "react";
import ContentContainer from "./ContentContainer";
import DashboardCardTitle from "./DashboardCardTitle";
import { useDispatch } from "react-redux";
import { setSelectedPortfolioExchange } from "../../redux/exchange/actions";
import { useNavigate } from "react-router";

function BotPerformanceSummary({ loading, data }) {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToBalanceChart = () => {
    dispatch(setSelectedPortfolioExchange(data?.exchange_id));
    navigate("/integration/portfolio");
  };

  return (
    <div className={styles.container}>
      <ContentContainer
        style={data && data?.message_code !== "3" ? { minHeight: "300px" } : {}}
      >
        <div className={styles.header}>
          <DashboardCardTitle text={"Bot Performance"} icon={botIcon} />
          {loading && !data && <Spin />}
        </div>

        {data && (
          <div className={styles.body}>
            <div className={styles.info}>
              {data?.message_code === "3" && (
                <>
                  <div className={styles.detailsContainer}>
                    <BotPerformanceDetails data={data} />
                  </div>
                  <span
                    className={styles.moreBtn}
                    onClick={() => setShowDetailsModal(true)}
                  >
                    <MdOutlineMoreVert />
                    See details
                  </span>

                  <BotPerformanceChart
                    balanceDateList={data?.balance_date_list}
                    balanceLists={data?.balance_lists}
                  />
                  <div className={styles.linkBtns}>
                    <Link
                      to={"/bot-automation/bot-performance"}
                      className={styles.link}
                      style={{ width: "fit-content" }}
                    >
                      View Full Report
                    </Link>
                    <span className={styles.link} onClick={goToBalanceChart}>
                      Balance Chart
                    </span>
                  </div>
                </>
              )}
              {data?.message_code !== "3" && (
                <>
                  <p style={{ gridColumn: "span 2" }}>{data?.message}</p>
                  <Empty style={{ gridColumn: "span 2" }} />
                  {(data?.message_code === "2" ||
                    data?.message_code === "1") && (
                    <div className={styles.btns}>
                      <Link
                        to={"/bot-automation/bot-performance"}
                        className={styles.link}
                      >
                        Bots
                      </Link>
                      <Link
                        to={"/bot-automation/bot-performance"}
                        className={styles.link}
                      >
                        Manual trade
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <Modal
          centered
          footer={null}
          onCancel={() => setShowDetailsModal(false)}
          open={showDetailsModal}
        >
          <BotPerformanceDetails data={data} />
        </Modal>
      </ContentContainer>
    </div>
  );
}

export default BotPerformanceSummary;

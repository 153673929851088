import MinimalCard from "../MinimalCard";
import styles from "../../../styles/Components/mini-app/mini-dashboard/BotBanner.module.css";
import { MdChevronRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { TbMoneybag } from "react-icons/tb";

function ReferralBanner() {
  const navigate = useNavigate();

  const goToBotPage = () => {
    navigate("/mini-pricing-plan");
  };
  return (
    <div>
      {/*<div className={styles.text}>*/}
      {/*  <h3 className={styles.title}>*/}
      {/*    <FaChartLine color={"gold"} size={18} />*/}
      {/*    Fully Automated Trade*/}
      {/*  </h3>*/}
      {/*  <h5>*/}
      {/*    Trade in smart and automated robots, and let them trade for you.*/}
      {/*  </h5>*/}
      {/*</div>*/}
      <MinimalCard customClassName={styles.navigationBox}>
        <Link to={"/mini-pricing-plan/referrals"}>
          <div className={styles.navigationBoxLeftSide}>
            <span className={styles.navigationBoxIcon}>
              <TbMoneybag size={20} />
            </span>
            <div className={styles.navigationBoxText}>
              <h3>Earn Credit and Money</h3>
              <p>Invite your friends.</p>
            </div>
          </div>
          <span className={styles.navigationBoxGoIcon} onClick={goToBotPage}>
            <MdChevronRight size={20} />
          </span>
        </Link>
      </MinimalCard>
    </div>
  );
}

export default ReferralBanner;

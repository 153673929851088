import React from 'react';
import Chart from "react-apexcharts";

const PortfolioTableChart = ({chartData}) => {
    let data={

        series: [{
            data: chartData
        }],
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: false,
                    autoScaleYaxis: false
                },
                toolbar: {
                    autoSelected: 'zoom',
                    show:false
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                colors:["#27AE60"]

            },
            title: {
                // text: 'Stock Price Movement',
                align: 'left'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            grid: {
                show: false,
            },
            yaxis: {
                labels: {
                    // formatter: function (val) {
                    //     return (val / 1000000).toFixed(0);
                    // },
                    show:false
                },

                show:false
            },
            xaxis: {
                type: 'datetime',
                categories: ["2022-10-18","2022-10-19","2022-10-20","2022-10-21","2022-10-22","2022-10-23","2022-10-24","2022-10-25","2022-10-26","2022-10-27"],
                show: false,
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            tooltip: {
                shared: false,
                enabled:false,
                y: {
                    // formatter: function (val) {
                    //     return (val / 1000000).toFixed(0)
                    // }
                }
            }
        },


    };

    return (
        <div style={{width:"100%",maxWidth:"120px"}}>
            {chartData && <Chart options={data.options} series={data.series} type="area" width={"100%"}/>}

        </div>
    );
};

export default PortfolioTableChart;

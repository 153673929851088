import {
    GET_MINIMAL_DASHBOARD_ERROR,
    GET_MINIMAL_DASHBOARD_REQUEST,
    GET_MINIMAL_DASHBOARD_SUCCESS,
} from "./consts";

const initialState = {
    minimalDashboardStatus: "",
    minimalDashboardData: {},
};

export const minimalAppReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_MINIMAL_DASHBOARD_REQUEST:
            return {
                ...state,
                minimalDashboardStatus: "loading",
            };

        case GET_MINIMAL_DASHBOARD_SUCCESS:
            return {
                ...state,
                minimalDashboardStatus: "ready",
                minimalDashboardData: payload,
            };

        case GET_MINIMAL_DASHBOARD_ERROR:
            return {
                ...state,
                minimalDashboardStatus: "error",
            };

        default:
            return state;
    }
};

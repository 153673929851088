import {GET_VALUE_BACKTEST_ERROR, GET_VALUE_BACKTEST_REQUEST, GET_VALUE_BACKTEST_SUCCESS} from "./consts";


const initialState = {
    loading: false,
    backTestValue:[],
    error: ""
}
export const tradeSimulatorReducer = (state = initialState, action) => {
    switch (action?.type) {
        case GET_VALUE_BACKTEST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_VALUE_BACKTEST_SUCCESS:
            return {
                ...state,
                loading: false,
                backTestValue: action?.payload


            }
        case GET_VALUE_BACKTEST_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state


    }

}

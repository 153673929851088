import {
  GET_INSIGHT_CHART_ERROR,
  GET_INSIGHT_CHART_REQUEST,
  GET_INSIGHT_CHART_SUCCESS,
  GET_INSIGHT_INITIAL_INFO_ERROR,
  GET_INSIGHT_INITIAL_INFO_REQUEST,
  GET_INSIGHT_INITIAL_INFO_SUCCESS,
  UPDATE_SELECTED_FEATURES,
} from "../insight/consts";

const initialState = {
  loading: false,
  selectedFeatures: [],
  initialInfo: {},
  chartData: [],
  error: "",
};
export const insightReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_INSIGHT_INITIAL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INSIGHT_INITIAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        initialInfo: action?.payload,
      };
    case GET_INSIGHT_INITIAL_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case GET_INSIGHT_CHART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INSIGHT_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action?.payload,
      };
    case GET_INSIGHT_CHART_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case UPDATE_SELECTED_FEATURES:
      return {
        ...state,
        selectedFeatures: action?.payload,
      };
    default:
      return state;
  }
};

import { FaCrown } from "react-icons/fa";
import { capitalizeString } from "../../../utility/formatter-helper";
import styles from "../../../styles/Components/pricing-plan/plans/PlanTitle.module.css";

function PlanTitle({ type }) {
  const iconColor =
    type === "basic" ? "#CD7F32" : type === "pro" ? "#C0C0C0" : "#FFD700";
  const description =
    type === "basic"
      ? "For Everyday Trading"
      : type === "pro"
      ? "For Professional Trading"
      : "For Partnership Purposes";
  return (
    <>
      <h2 className={styles.title}>
        <FaCrown color={iconColor} />
        {capitalizeString(type)}
      </h2>
      <p className={styles.description}>{description}</p>
    </>
  );
}

export default PlanTitle;

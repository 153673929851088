import React from "react";
import Chart from "react-apexcharts";
import { Spin } from "antd";

const LineChart = ({ data, loading }) => {
  let chartData = {
    series: [
      {
        name: "balance",
        data: data?.VALUES?.map((item, index) => {
          return {
            x: data?.TIME_STAMPS[index],
            y: item?.toFixed(2),
          };
        }),
      },
    ],
    stroke: {
      show: true,
      curve: "smooth",
      width: 3,
      dashArray: 0,
    },
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: "Balance",
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          // formatter: function (val) {
          //     return (val / 1000000).toFixed(0);
          // },
        },
        title: {
          text: "USD",
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        shared: false,
        x: {
          format: "MM/dd HH:00",
        },
        y: {
          // formatter: function (val) {
          //     return (val / 1000000).toFixed(0)
          // }
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  const containerStyle = {
    position: "relative",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(255,255,255, 0.5)",
  };

  return (
    <div style={containerStyle}>
      {loading && (
        <div style={overlayStyle}>
          <Spin />
        </div>
      )}
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </div>
  );
};

export default LineChart;

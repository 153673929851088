import AlertSettingStepHeader from "./AlertSettingStepHeader";
import RiskAppetiteOptions from "../../../shared/RiskAppetiteOptions";
import styles from "../../../styles/Components/alert-system/alert-setting/AlertSettingsStep.module.css";

function AlertRiskSetting({ setRiskAppetite, defaultRisk }) {
  const changeRiskHandler = (value) => {
    setRiskAppetite("riskAppetite", value);
  };
  return (
    <div>
      <h4
        style={{
          fontSize: "1.2rem",
          fontFamily: "Poppins-Medium",
          fontWeight: "400",
          lineHeight: "1.5rem",
          color: "#2B303A",
          marginBottom: "2rem",
        }}
      >
        Lets Get Started.
      </h4>
      <AlertSettingStepHeader
        title={"Risk Appetite"}
        caption={
          "Traders vary from risk-averse to risk-seeking. We consider this when suggesting signals to cater to all preferences."
        }
      />

      <RiskAppetiteOptions
        onChange={changeRiskHandler}
        defaultRisk={defaultRisk}
        activeBtnClassName={styles.activeBtn}
        riskBtnClassName={styles.riskBtn}
      />
    </div>
  );
}

export default AlertRiskSetting;

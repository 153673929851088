import { RxDotFilled } from "react-icons/rx";
import styles from "../../../styles/Components/faq/GeneralQuestions.module.css";

const FaqItem = ({ question, answer, id }) => {
  return (
    <li className={styles.FaqItem} id={`q${id}`}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <RxDotFilled />
        {question}
      </h2>
      <p className={styles.answer}>{answer}</p>
    </li>
  );
};

export default FaqItem;

import {
  GET_200COINS_ERROR,
  GET_200COINS_REQUEST,
  GET_200COINS_SUCCESS,
  GET_ALL_TRADE_SUGGESTION_ERROR,
  GET_ALL_TRADE_SUGGESTION_REQUEST,
  GET_ALL_TRADE_SUGGESTION_SUCCESS,
  GET_BALANCE_ERROR,
  GET_BALANCE_REQUEST,
  GET_BALANCE_SUCCESS,
  GET_INVESTMENT_BUDGET_ERROR,
  GET_INVESTMENT_BUDGET_REQUEST,
  GET_INVESTMENT_BUDGET_SUCCESS,
  GET_PORTFOLIO_DATA_ERROR,
  GET_PORTFOLIO_DATA_REQUEST,
  GET_PORTFOLIO_DATA_SUCCESS,
  GET_PRICE_ERROR,
  GET_PRICE_REQUEST,
  GET_PRICE_SUCCESS,
  GET_TRADE_ASSISTANT_ERROR,
  GET_TRADE_ASSISTANT_REQUEST,
  GET_TRADE_ASSISTANT_SUCCESS,
  GET_TRADE_HISTORY_ERROR,
  GET_TRADE_HISTORY_REQUEST,
  GET_TRADE_HISTORY_SUCCESS,
  RESET_HISTORY_ERROR,
  RESET_HISTORY_REQUEST,
  RESET_HISTORY_SUCCESS,
  SET_AI_ASSISTANT_MODE,
  SET_AI_MARKET_TYPE,
  SET_LIMIT_ORDER_ERROR,
  SET_LIMIT_ORDER_REQUEST,
  SET_LIMIT_ORDER_SUCCESS,
  SET_TRADING_ERROR,
  SET_TRADING_REQUEST,
  SET_TRADING_SUCCESS,
  UPDATE_PORTFOLIO_DATA,
  UPDATE_TRADE_ASSISTANT_DATA,
  VIRTUAL_PORTFOLIO,
} from "./consts";

const initialState = {
  loading: false,
  getPriceLoading: false,
  setTradeLoading: false,
  get200CoinsLoading: false,
  resetHistoryLoading: false,
  getBalanceLoading: false,
  TrackSignalLoading: false,
  getPortfolioDataLoading: false,
  getTradeHistoryLoading: false,
  getTradeAssistantLoading: false,
  getInvestmentBudgetLoading: false,
  portfolioData: [],
  tradeHistory: [],
  comprehensiveHistory: [],
  tradeAssistant: [],
  balanceData: [],
  _200Coins: [],
  resetHistoryData: [],
  investmentBudgetData: {},
  trackSignal: {},
  tradeData: null,
  price: null,
  getPriceError: "",
  getBalanceError: "",
  setTradeError: "",
  get200CoinsError: "",
  resetHistoryError: "",
  trackSignalError: "",
  getPortfolioDataError: null,
  getInvestmentBudgetError: null,
  getTradeHistoryError: "",
  selectedPortfolio: "",
  error: "",
  limitOrderLoading: false,
  limitOrderMessage: "",
  limitOrderError: "",
  aiMarketType: "crypto",
  aiAssistantMode: "",
  allTradeSuggestionData: [],
  allTradeSuggestionLoading: false,
  allTradeSuggestionError: "",
  allSuggestion: [],
};
export const tradingAssistantReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_PORTFOLIO_DATA_REQUEST:
      return {
        ...state,
        getPortfolioDataLoading: true,
        getPortfolioDataError: null,
      };
    case GET_PORTFOLIO_DATA_SUCCESS:
      return {
        ...state,
        getPortfolioDataLoading: false,
        portfolioData: action?.payload,
        getPortfolioDataError: "",
      };
    case GET_PORTFOLIO_DATA_ERROR:
      return {
        ...state,
        getPortfolioDataLoading: false,
        getPortfolioDataError: action?.payload,
      };
    case GET_TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        getTradeHistoryLoading: false,
        tradeHistory: action?.payload,
        getTradeHistoryError: "",
        comprehensiveHistory: action?.payload?.COMPREHENSIVE_TRADE_HISTORY,
      };
    case GET_TRADE_HISTORY_REQUEST:
      return {
        ...state,
        getTradeHistoryLoading: true,
        getTradeHistoryError: "",
      };
    case GET_TRADE_HISTORY_ERROR:
      return {
        ...state,
        getTradeHistoryLoading: false,
        getTradeHistoryError: action?.payload,
      };
    case GET_TRADE_ASSISTANT_SUCCESS:
      return {
        ...state,
        getTradeAssistantLoading: false,
        tradeAssistant: action?.payload,
        error: "",
      };
    case GET_TRADE_ASSISTANT_REQUEST:
      return {
        ...state,
        getTradeAssistantLoading: true,
        tradeAssistant: [],
        error: "",
      };
    case GET_TRADE_ASSISTANT_ERROR:
      return {
        ...state,
        getTradeAssistantLoading: false,
        tradeAssistant: [],
        error: action?.payload,
      };
    case GET_PRICE_REQUEST:
      return {
        ...state,
        getPriceLoading: true,
        getPriceError: "",
      };
    case GET_PRICE_SUCCESS:
      return {
        ...state,
        getPriceLoading: false,
        price: action.payload,
        getPriceError: "",
      };
    case GET_PRICE_ERROR:
      return {
        ...state,
        getPriceLoading: false,
        getPriceError: action.payload,
      };
    case SET_TRADING_REQUEST:
      return {
        ...state,
        setTradeLoading: true,
      };
    case SET_TRADING_SUCCESS:
      return {
        ...state,
        setTradeLoading: false,
        tradeData: action.payload,
      };
    case SET_TRADING_ERROR:
      return {
        ...state,
        setTradeLoading: false,
        setTradeError: action.payload,
      };
    case GET_200COINS_REQUEST:
      return {
        ...state,
        get200CoinsLoading: true,
      };
    case GET_200COINS_SUCCESS:
      return {
        ...state,
        get200CoinsLoading: false,
        _200Coins: action.payload,
      };
    case GET_200COINS_ERROR:
      return {
        ...state,
        get200CoinsLoading: false,
        get200CoinsError: action.payload,
      };
    case RESET_HISTORY_REQUEST:
      return {
        ...state,
        resetHistoryLoading: true,
      };
    case RESET_HISTORY_SUCCESS:
      return {
        ...state,
        resetHistoryLoading: false,
        resetHistoryData: action.payload,
      };
    case RESET_HISTORY_ERROR:
      return {
        ...state,
        resetHistoryLoading: false,
        resetHistoryError: action.payload,
      };
    case GET_BALANCE_REQUEST:
      return {
        ...state,
        getBalanceLoading: true,
      };
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        getBalanceLoading: false,
        balanceData: action.payload,
      };
    case GET_BALANCE_ERROR:
      return {
        ...state,
        getBalanceLoading: false,
        getBalanceError: action.payload,
      };
    case GET_INVESTMENT_BUDGET_REQUEST:
      return {
        ...state,
        getInvestmentBudgetLoading: true,
        getInvestmentBudgetError: null,
        investmentBudgetData: {},
      };
    case GET_INVESTMENT_BUDGET_SUCCESS:
      return {
        ...state,
        getInvestmentBudgetLoading: false,
        investmentBudgetData: action?.payload,
        getInvestmentBudgetError: null,
      };
    case GET_INVESTMENT_BUDGET_ERROR:
      return {
        ...state,
        getInvestmentBudgetLoading: false,
        investmentBudgetData: {},
        getInvestmentBudgetError: action?.payload,
      };
    case SET_LIMIT_ORDER_REQUEST:
      return {
        ...state,
        limitOrderLoading: true,
        limitOrderError: "",
      };
    case SET_LIMIT_ORDER_SUCCESS:
      return {
        ...state,
        limitOrderLoading: false,
        limitOrderMessage: action.payload,
      };
    case SET_LIMIT_ORDER_ERROR:
      return {
        ...state,
        limitOrderError: action.payload,
        limitOrderLoading: false,
      };
    case VIRTUAL_PORTFOLIO:
      return {
        ...state,
        selectedPortfolio: action.payload,
      };
    case SET_AI_MARKET_TYPE:
      return {
        ...state,
        aiMarketType: action.payload,
      };

    case UPDATE_PORTFOLIO_DATA:
      return {
        ...state,
        portfolioData: action.payload,
      };

    case UPDATE_TRADE_ASSISTANT_DATA:
      return {
        ...state,
        tradeAssistant: {
          ...state.tradeAssistant,
          ...action.payload,
        },
      };

    case SET_AI_ASSISTANT_MODE:
      return {
        ...state,
        aiAssistantMode: action.payload,
      };

    case GET_ALL_TRADE_SUGGESTION_REQUEST:
      return {
        ...state,
        allTradeSuggestionLoading: true,
        allTradeSuggestionError: "",
      };

    case GET_ALL_TRADE_SUGGESTION_SUCCESS:
      const otherCoins = action?.payload?.other_coins_analyse?.map((item) => {
        return {
          ...item,
          SYMBOL_NAME: item?.SYMBOL_NAME?.replaceAll("/USDT", ""),
        };
      });

      return {
        ...state,
        allTradeSuggestionLoading: false,
        allTradeSuggestionData: otherCoins,
        allSuggestion: [
          ...otherCoins,
          ...action?.payload?.ASSISTANT_SUGGESTIONS,
        ],
        // tradeAssistant: {
        //   ...state.tradeAssistant,
        //   other_coins_analyse: action?.payload?.other_coins_analyse,
        //   ASSISTANT_SUGGESTIONS: [
        //     ...state?.tradeAssistant?.ASSISTANT_SUGGESTIONS,
        //     ...action?.payload?.other_coins_analyse,
        //   ],
        // },
      };

    case GET_ALL_TRADE_SUGGESTION_ERROR:
      return {
        ...state,
        allTradeSuggestionLoading: false,
        allTradeSuggestionError: action?.payload,
      };

    default:
      return state;
  }
};

import styles from "../../../styles/Components/mini-app/mini-bots/NoBotBox.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiLockFill } from "react-icons/ri";
import React, { useState } from "react";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";

function NoBotBox() {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const isServiceActive = profileData?.mybot_active === "True";

  const [showActivationModal, setShowActivationModal] = useState(false);

  const navigate = useNavigate();

  const startBotHandler = () => {
    if (!isServiceActive) {
      setShowActivationModal(true);
    } else {
      navigate("/mini-create-bot");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.startBtn} onClick={startBotHandler}>
        {!isServiceActive && <RiLockFill />} Start Your Trading Adventure
      </div>
      <p>
        You can try Test exchange if you have no exchange or you wanna learn to
        try.
      </p>

      {showActivationModal && (
        <ServiceActivationModal
          service={"mybot"}
          show={showActivationModal}
          setShow={setShowActivationModal}
        />
      )}
    </div>
  );
}

export default NoBotBox;

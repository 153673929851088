import React, { useEffect, useState } from "react";
import styles from "../styles/shared/GlobalErrorBubble.module.css";
import { BiError } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPublicMessage } from "../redux/global-message/messageSlice";
import { publicMessageRouteConvertor } from "../utility/data";
import moment from "moment";

// const markdownString = `
// ### Step
// 1. Test
// 2. Deploy
//
// #### There are some *problems*:
//
// - Bot section has some <strong style="color: red;">bugs</strong>
//    - Some issue.
//
// <strong style="color: blue;">- AI section has some bugs</strong>
//    - \`Working\` on **it**.
// `;

// const markdownString = `;
// # Step
// - Test
// - Deploy
//
// ### There are some problems:
//
// <strong style="color: red;">- Bot section has some bugs</strong>
//    - Some issue.
//
// <strong style="color: blue;">- AI section has some bugs</strong>
//    - Working on it.
// `;

const formattedDate = (dateString) => moment(dateString).format("DD MMM HH:mm");

function GlobalErrorBubble() {
  const {
    main: { userData },
    publicMessage: { messages, messagesStatus, messagesError },
  } = useSelector((state) => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const [showErrorBubble, setShowErrorBubble] = useState(true);
  const currentRoute = location?.pathname?.replace("/", "");
  const routeMessage = messages?.find(
    (item) => item?.location === publicMessageRouteConvertor?.[currentRoute],
  );

  useEffect(() => {
    if (
      userData?.email &&
      !messages?.length &&
      messagesStatus !== "idle" &&
      messagesStatus !== "loading" &&
      !messagesError
    ) {
      dispatch(getPublicMessage());
    }
  }, [dispatch, messages?.length, messagesError, messagesStatus, userData]);

  useEffect(() => {
    if (routeMessage) {
      setShowErrorBubble(true);
    } else {
      setShowErrorBubble(false);
    }
  }, [routeMessage]);

  if (!routeMessage) return null;

  return (
    <div
      className={`${styles.errorBubble} ${!showErrorBubble ? styles.hide : ""}`}
    >
      <div>
        <ReactMarkdown
          children={routeMessage?.markdown_msg}
          rehypePlugins={[rehypeRaw]}
        />
        <strong style={{ color: "black" }}>
          Fixed by {formattedDate(routeMessage?.expiration_date)}
        </strong>
      </div>
      {/*<p className={styles.errorMessage}>*/}
      {/*  <BiError /> Something went wrong!*/}
      {/*</p>*/}
      <IoClose
        className={styles.closeIcon}
        onClick={() => setShowErrorBubble(false)}
      />
    </div>
  );
}

export default GlobalErrorBubble;

import React, { useState, useEffect } from "react";
import Modal from "../modal/Modal";
import poster1 from "../../asset/Images/videoPoster1.jpg";
import poster2 from "../../asset/Images/videoPoster2.jpg";
import styles from "../../styles/Components/help/insightHelp.module.sass";
import VideoPlayer from "../player/VideoPlayer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../redux/profile/actions";
import { BsFillPlayCircleFill } from "react-icons/bs";

const InsightHelp = ({ open, handleOk, handleCancel }) => {
  const [show, setShow] = useState(false);
  const [showDemo, setShowDemo] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const dispatch = useDispatch();

  let {
    main: { userData },
    profile: { profileData },
  } = useSelector((state) => state);

  useEffect(async () => {
    if (userData?.email) {
      setShowDemo(profileData?.show_insight_demos === "True" ? true : false);
    }
  }, [userData, profileData]);

  const changeHandler = async (e) => {
    setShowDemo(e.target.checked ? false : true);

    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["show_insight_demos"],
        [e.target.checked ? "False" : "True"],
      ),
    );
  };

  const handleShow = (url, title) => {
    setSelectedVideo({
      title,
      url,
    });
    setShow(true);
  };
  return (
    <>
      <Modal
        title={"Watch these video to quickly learn how to use our platform"}
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        width={700}
        footer={false}
        centered={true}
        content={
          <div className={styles.wrapper}>
            <div
              className={styles.videoContainer}
              style={{ boxShadow: "none" }}
            >
              <div>
                <img src={poster1} alt="" />

                <span>
                  <span>AI Predictions for Trading</span>
                  <BsFillPlayCircleFill
                    onClick={() =>
                      handleShow(
                        "https://d13eiv722he0se.cloudfront.net/insight_demo1.mp4",
                        "AI Predictions for Trading",
                      )
                    }
                  />
                </span>
              </div>
              <div>
                <img src={poster2} alt="" />
                <span>
                  <span>Trends and Indicators for Trading</span>
                  <BsFillPlayCircleFill
                    onClick={() =>
                      handleShow(
                        "https://d13eiv722he0se.cloudfront.net/insight_demo2.mp4",
                        "Trends and Indicators for Trading",
                      )
                    }
                  />
                </span>
              </div>
            </div>
            <Checkbox checked={!showDemo} onChange={changeHandler}>
              Disable automatic display.
            </Checkbox>
          </div>
        }
      />
      <VideoPlayer
        show={show}
        hide={setShow}
        url={selectedVideo?.url}
        title={selectedVideo?.title}
      />
    </>
  );
};

export default InsightHelp;

import { Button, message, Modal, Spin, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/Components/tables/portfolioTable.module.sass";
import React, { useEffect, useState } from "react";
import {
  calcDifferenceInDays, formatAIMode,
  formatCustomDate,
} from "../../../../utility/helper";
import { createBot } from "../../../../redux/bot/actions";
import IntegratedExchangesModal from "./IntegratedExchangesModal";
import ExpandedRowBotTable from "../ExpandedRowBotTable";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import BotPoolTableExpandedRow from "./BotPoolTableExpandedRow";



const returnDataForCreateBot = (basicData) => {
  const {
    bot_name,
    exchange_id: selected_exchange,
    signal_mode: signal_source,
    ai_assistant_mode,
    number_of_coins,
  } = basicData;

  return {
    bot_name,
    selected_exchange,
    trade_in_virtual_portfolio: "False",
    risk_tolerance: "3",
    target_return_percentage: "10",
    crypto_allocation_strategy: "balanced",
    signal_source,
    ai_assistant_mode,
    selected_trading_strategies: ["Time-based AI-assisted"],
    number_of_coins,
  };
};

function BotPoolTable({ data = [], goHome }) {
  const {
    botState: { botStatisticData, botStatisticLoading, createBotLoading },
    exchangeState: { integratedData, getIntegratedList },
    main: { userData },
  } = useSelector((state) => state);

  const [tableData, setTableData] = useState([]);
  const [copiedBot, setCopiedBot] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState([]);

  const onRowClick = (record) => {
    if (expandedRowKey.includes(record.key)) {
      return setExpandedRowKey([]);
    }
    setExpandedRowKey([record.key]);
  };

  const dispatch = useDispatch();

  const copyBotHandler = async (botData) => {
    if (createBotLoading)
      return message.info("Please wait to finish the process.");

    setCopiedBot(botData);

    if (integratedData.length > 0) {
      setShowExchangeModal(true);
    }

    if (getIntegratedList && integratedData.length === 0) {
      await dispatch(
        createBot(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          returnDataForCreateBot(botData),
        ),
      ).then(() => {
        goHome();
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 7,
      align: "center",
      responsive: ["md"],
      render: (index) => <span>{index + 1}</span>,
    },
    {
      title: "Bot Name",
      dataIndex: "name",
      key: "name",
      width: 20,
      align: "center",
      render: (name) => <span>{name}</span>,
    },
    // {
    //   title: "Creation Date",
    //   dataIndex: "date",
    //   key: "date",
    //   width: 15,
    //   align: "center",
    //   responsive: ["md"],
    //   render: (date) => <span>{formatCustomDate(date)}</span>,
    // },
    // {
    //   title: "Exchange",
    //   dataIndex: "exchange",
    //   key: "exchange",
    //   width: 20,
    //   align: "center",
    //   render: ([exchangeId, exchangeName]) => (
    //     <span>
    //       <img
    //         style={{ width: "20px", height: "20px", borderRadius: "100%" }}
    //         src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchangeId}.svg`}
    //         alt={data?.exchange_id}
    //       />
    //       {exchangeName}
    //     </span>
    //   ),
    // },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 20,
      align: "center",
      render: ([endDate, startDate]) => (
        <span>{calcDifferenceInDays(endDate, startDate)}</span>
      ),
    },
    {
      title: "Net Value Profit %",
      dataIndex: "netValue",
      key: "netValue",
      width: 20,
      responsive: ["lg"],
      align: "center",
      render: (value) => <span>{value.toFixed(2)}%</span>,
    },
    {
      title: "Signal Source",
      dataIndex: "source",
      key: "source",
      width: 20,
      responsive: ["md"],
      align: "center",
      render: ([source, mode]) => (
        <span>
          {source.replace("_", " ")} <br />
          <span style={{ fontSize: "13px", color: "#7f8c8d" }}>
            {source === "AI_Assistant" &&
              `( ${
                mode === "default" ? "All categories" : formatAIMode[mode]
              } )`}
          </span>
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "copyBot",
      key: "copyBot",
      width: 20,
      align: "center",
      render: (botData) => (
        <Button
          type={"primary"}
          className={styles.copyBtn}
          loading={
            copiedBot?.bot_name === botData?.bot_name && createBotLoading
          }
          onClick={() => copyBotHandler(botData)}
        >
          {window.innerWidth > 768 ? "Create a Copy" : "Copy"}
        </Button>
      ),
    },
  ];

  const handleRowExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.key : []);
  };

  useEffect(() => {
    if (data.length > 0) {
      setTableData(
        data.map((bot, i) => {
          const {
            bot_created_date: date,
            bot_name: name,
            ai_assistant_mode: aiMode,
            budget_usd: budgetUsd,
            end_date: endDate,
            exchange_id: exchangeId,
            number_of_coins: coinsNumber,
            pdf_url: pdfUrl,
            profit_percent: profitPercent,
            profit_usd: profitUsd,
            signal_mode: signalMode,
            start_date: startDate,
            exchange_name: exchangeName,
          } = bot;

          return {
            key: i,
            index: i,
            name,
            date,
            exchange: [exchangeId, exchangeName],
            source: [signalMode, aiMode],
            duration: [endDate, startDate],
            copyBot: bot,
            netValue: profitPercent,
          };
        }),
      );
    }
  }, [data]);

  return (
    <>
      <Table
        scroll={{ y: 500 }}
        height={500}
        pagination={false}
        loading={botStatisticLoading}
        className={`${styles.table} bot-table`}
        style={{ marginTop: "1rem" }}
        dataSource={tableData}
        columns={columns}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={
          window.innerWidth < 768 && {
            expandedRowRender: (record) => <BotPoolTableExpandedRow data={record}/>,
            expandedRowKeys: expandedRowKey,
            onExpand: handleRowExpand,
            expandIcon: ({ expanded, onExpand, record }) => {
              return expanded ? (
                <IoChevronUp
                  className={styles.expandIcon}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <IoChevronDown
                  className={styles.expandIcon}
                  onClick={(e) => onExpand(record, e)}
                />
              );
            },
          }
        }
      />
      <Modal open={showModal} onCancel={() => setShowModal(false)}></Modal>

      <IntegratedExchangesModal
        show={showExchangeModal}
        onClose={setShowExchangeModal}
        goHome={goHome}
        data={returnDataForCreateBot(copiedBot)}
      />
    </>
  );
}

export default BotPoolTable;

import React from "react";
import styles from "../../../styles/shared/trade-modal/TradeModal.module.css";
import { Spin } from "antd";

function Loader({ text }) {
  return (
    <div className={styles.loader}>
      <Spin tip={text || "Loading..."} />
    </div>
  );
}

export default Loader;

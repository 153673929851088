import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaCaretRight } from "react-icons/fa";
import { InputNumber } from "antd";
import React from "react";
import CreateBotStepTitle from "./StepTitle";

function CreateBotStep8({ botData, changeBotData }) {
  return (
    <>
      <CreateBotStepTitle title={" Number of Coins Selection"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          Number of Coins to Include:
        </h4>
        <p>
          <strong style={{ marginRight: ".3rem", color: "green" }}>
            NOTE:
          </strong>
          For optimal results, we suggest that you choose between 3 and 5 coins.
        </p>
        <InputNumber
          style={{ borderRadius: ".3rem" }}
          min={1}
          max={30}
          value={botData.number_of_coins}
          onChange={(value) => changeBotData("number_of_coins", `${value}`)}
        />
      </div>
    </>
  );
}

export default CreateBotStep8;

import styles from "../../styles/pages/dashboard/index.module.sass";
import { useSelector } from "react-redux";
import TopCryptoPrediction from "./TopCryptoPrediction";
import React, { useRef, useState } from "react";
import PurchasesInfo from "./PurchasesInfo";
import Test from "../../test/Test";
import Tools from "./Tools";
import FeatureSuggestion from "./FeatureSuggestion";
import News from "./News";
import LastVisits from "./LatestVisists";
import Costs from "./Payments/Costs";
import PortfolioSummary from "./PortfolioSummary";
import BotPerformanceSummary from "./BotPerformanceSummary";
import ExchangeBox from "./ExchangeBox";

function DashboardContent() {
  const {
    dashboardData: {
      loading: dashboardLoading,
      data: dashboardData,
      fastDashboardLoading,
      fastDashboardData,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  // const isFirstDataReady = fastDashboardData?.pricing_plan;

  const isContentLoading =
    fastDashboardLoading &&
    Object.keys(fastDashboardData).length === 0 &&
    dashboardLoading &&
    Object.keys(dashboardData).length === 0;

  const contentData = !isContentLoading
    ? Object.keys(fastDashboardData).length > Object.keys(dashboardData).length
      ? fastDashboardData
      : dashboardData
    : {};

  const [featureStyle, setFeatureStyle] = useState({});
  const featureRef = useRef();

  return (
    <div className={styles.main} style={{ flexWrap: "wrap" }}>
      <div className={styles.topContent}>
        {!isContentLoading && <ExchangeBox className={styles.desktopView} />}
        {!isContentLoading && (
          <BotPerformanceSummary
            loading={dashboardLoading}
            data={contentData?.overall_bot_performance}
          />
        )}
        {!isContentLoading && <ExchangeBox className={styles.mobileView} />}
        <TopCryptoPrediction
          loading={isContentLoading}
          data={contentData?.top_crypto_predictions}
        />
        {!isContentLoading && (
          <PortfolioSummary
            loading={dashboardLoading}
            data={contentData?.portfolio_summary}
            profileData={profileData}
          />
        )}
        {/*{!isContentLoading && (*/}
        {/*  <Costs data={contentData?.cost_analysis} loading={dashboardLoading} />*/}
        {/*)}*/}
        <PurchasesInfo
          data={contentData?.PAYMENTS_INFO}
          dashboardData={contentData}
          loading={isContentLoading}
        />
        {!isContentLoading && (
          <LastVisits
            loading={dashboardLoading}
            data={contentData?.last_10_actions}
            dashboardData={contentData}
            suggestion={profileData}
            featureRef={featureRef}
            setStyle={setFeatureStyle}
          />
        )}
        <Test
          loading={isContentLoading}
          data={contentData?.overall_statistics}
        />
        <Tools loading={isContentLoading} data={contentData?.PAYMENTS_INFO} />
        {!isContentLoading && <News loading={dashboardLoading} />}
        {profileData?.suggested_features && (
          <FeatureSuggestion
            loading={isContentLoading}
            data={profileData}
            featureStyle={featureStyle}
            featureRef={featureRef}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardContent;

import styles from "../../../styles/shared/service-activation/SubscriptionSettings.module.css";
import { FaCrown } from "react-icons/fa";
import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";

function SubscriptionProPlan({
  proPlanDuration,
  selectDurationHandler,
  buySubscriptionHandler,
}) {
  const {
    pricingPlan: { pricingPlan, checkoutLoading, checkoutSession },
  } = useSelector((state) => state);

  const annualCost =
    (
      (1 - pricingPlan?.["yearly_discount"] / 100) *
      pricingPlan?.["subscription_aiSignal"]
    )?.toFixed(2) || 13.99;

  return (
    <div className={styles.proPlanBox}>
      <h3 className={styles.header}>
        <FaCrown color={"#C0C0C0"} /> Pro Plan
      </h3>
      <h4 className={styles.description}>
        Subscribe to our Pro Plan for{" "}
        <span
          className={`${proPlanDuration === "yearly_pro" ? styles.off : ""} ${
            styles.price
          }`}
        >
          ${pricingPlan["subscription_aiSignal"] || 149.99}
        </span>
        {proPlanDuration === "yearly_pro" && (
          <span className={styles.offPrice}>({annualCost})</span>
        )}
        /month and access our AI Signals. Save 10% compared to non-subscription.
        {proPlanDuration === "monthly_pro" && (
          <>
            <br />
            Annual subscription gets a {pricingPlan?.["yearly_discount"] || 30}%
            discount.
          </>
        )}
      </h4>
      <div className={styles.durationOptions}>
        <span
          className={`${styles.durationOption} ${
            proPlanDuration === "monthly_pro" ? styles.selectedOption : ""
          }`}
          onClick={() => selectDurationHandler("monthly_pro")}
        >
          Monthly
        </span>
        <span
          className={`${styles.durationOption} ${styles.yearlyPlan} ${
            proPlanDuration === "yearly_pro" ? styles.selectedOption : ""
          }`}
          onClick={() => selectDurationHandler("yearly_pro")}
        >
          Annually
        </span>
      </div>
      <Button
        loading={checkoutLoading}
        className={styles.buyBtn}
        disabled={checkoutLoading || !proPlanDuration}
      >
        <a href={checkoutSession || "#"} onClick={buySubscriptionHandler}>
          Buy it
        </a>
      </Button>
    </div>
  );
}

export default SubscriptionProPlan;

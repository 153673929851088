import React from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/integration/portfolio/ExchangePortfolioCharts.module.css";
import LineChart from "../../charts/trading-assistant/LineChart";
import PieChart from "../../charts/trading-assistant/PieChart";

function ExchangePortfolioCharts() {
  const {
    exchangeState: {
      balanceChartStatus,
      balanceChartData,
      portfolioLoading,
      portfolioData,
    },
  } = useSelector((state) => state);

  return (
    <div className={styles.container}>
      <PieChart chartData={portfolioData} loading={portfolioLoading} />
      <LineChart
        data={balanceChartData?.BALANCE_CHART}
        loading={balanceChartStatus === "loading"}
      />
    </div>
  );
}

export default ExchangePortfolioCharts;

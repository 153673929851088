import { errorHandler } from "../../utility/helper";
import {
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_REQUEST, // dashboard data types
  GET_DASHBOARD_DATA_SUCCESS,
  GET_FAST_DASHBOARD_DATA_ERROR,
  GET_FAST_DASHBOARD_DATA_REQUEST,
  GET_FAST_DASHBOARD_DATA_SUCCESS,
} from "../dashboard/consts";
import axios from "axios";
import {
  axiosErrorHandler,
  logErrorToTelegram,
  maintenanceErrorCodes,
} from "../../utility/action-helper";

// get dashboard all data
export const getDashboardData =
  (email, user, country = "australia", retryCount = 0) =>
  async (dispatch) => {
    // const {token} = JSON.parse(localStorage.getItem("aws-amplify-federatedInfo"))
    // const headerConfig = {
    //     headers: {
    //         "Authorization": `Bearer ${token}`
    //     }
    // }
    const localCountry = localStorage.getItem("country");
    dispatch({ type: GET_DASHBOARD_DATA_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "dashboard_info",
        email,
        user,
        number_of_news: 10,
        stock_country: localCountry || country,
      })
      .then((res) => {
        dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getDashboardData(email, user, country, retryCount + 1),
          GET_DASHBOARD_DATA_ERROR,
          user,
          email,
          "dashboard_info",
        );
      });
  };

export const getDashboardDataFast =
  (email, user, country = "australia", retryCount = 0) =>
  async (dispatch) => {
    const localCountry = localStorage.getItem("country");
    dispatch({ type: GET_FAST_DASHBOARD_DATA_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "dashboard_info_fast",
        email,
        user,
        number_of_news: 10,
        stock_country: localCountry || country,
      })
      .then((res) => {
        dispatch({ type: GET_FAST_DASHBOARD_DATA_SUCCESS, payload: res.data });
        // dispatch(getDashboardData(email, user, country));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getDashboardDataFast(email, user, country, retryCount + 1),
          GET_FAST_DASHBOARD_DATA_ERROR,
          user,
          email,
          "dashboard_info_fast",
        );
      });
  };

import styles from "../../../styles/shared/service-activation/SubscriptionSettings.module.css";
import { FaCrown } from "react-icons/fa";
import React from "react";

function SubscriptionEnterprisePlan() {
  return (
    <div className={styles.enterprisePlanBox}>
      <h3 className={styles.header}>
        <FaCrown color={"gold"} /> Enterprise
      </h3>

      <h4 className={styles.description}>
        Ideal for large platforms, startups, influencers, and small brokers.{" "}
        <br />
        Contact us for details.
      </h4>

      <a className={styles.buyBtn} href={"https://t.me/Mehdiravab"}>
        Contact us
      </a>
    </div>
  );
}

export default SubscriptionEnterprisePlan;

import React from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import { Tooltip } from "antd";
import { FiInfo } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  setAsset,
  setMode,
  setStopLossTriggerPricePercent,
  setTradeProcess,
  setVirtualMarketType,
  setVirtualSelectedAsset,
  setVirtualSelectedPortfolio,
} from "../../../redux/trade/actions";

function TradeOptionsSelector() {
  const {
    tradeState: { currentStep, tradeProcess, basicTradeData, modalMode: mode },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  // HANDLE HOW TO SHOW CONTENT BASED ON THE TRADE PROCESS
  const tradeProcessHandler = (e) => {
    const type = e.target.id;
    // if (type === "exchange") {
    //   dispatch(setStep("1-a"));
    // }
    if (mode === "ai-table") {
      const { asset, market } = basicTradeData;

      if (type === "exchange") {
        dispatch(setAsset(asset + "/USDT"));
      }
      if (type === "paperTrading") {
        dispatch(setVirtualSelectedAsset(asset));
        dispatch(setVirtualMarketType(market));
      }
    }

    if (mode === "ai-suggestion") {
      const { market, asset } = basicTradeData;

      if (type === "exchange") {
        dispatch(setAsset(asset + "/USDT"));
      }
      if (type === "paperTrading") {
        dispatch(setVirtualSelectedAsset(asset));
        dispatch(setVirtualMarketType(market));
      }
    }
    dispatch(setTradeProcess(type));
    // dispatch(setMode(mode));
  };
  return (
    <div>
      {(mode === "ai-suggestion" || mode === "ai-table") && (
        <div className={styles.coinName}>
          <h3>Selected asset:</h3>
          <p>
            <img
              alt={basicTradeData?.asset}
              style={{
                width: "20px",
                borderRadius: "100%",
                marginRight: ".2rem",
              }}
              src={`\thttps://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${basicTradeData?.asset}.png`}
            />
            {basicTradeData?.asset}
          </p>
        </div>
      )}
      <h3 className={styles.title}>
        <AiFillCaretRight />
        Choose how you want to trade:
      </h3>
      <ul className={styles.tradeOptions}>
        <li
          id={"exchange"}
          className={`${styles.tradeOption} ${
            tradeProcess === "exchange" ? styles.selected : ""
          }`}
          onClick={tradeProcessHandler}
        >
          Exchange
          <Tooltip
            title={"Trade with real money"}
            color={"geekblue"}
            placement={"bottom"}
          >
            <FiInfo />
          </Tooltip>
        </li>
        <li
          id={"paperTrading"}
          className={`${styles.tradeOption} ${
            tradeProcess === "paperTrading" ? styles.selected : ""
          }`}
          onClick={tradeProcessHandler}
        >
          Paper trading{" "}
          <Tooltip
            title={"Trade with virtual money"}
            color={"geekblue"}
            placement={"bottom"}
          >
            <FiInfo />
          </Tooltip>
        </li>
      </ul>
    </div>
  );
}

export default TradeOptionsSelector;

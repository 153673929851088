import React, { useEffect, useState } from "react";
import styles from "../../styles/Components/alert-system/AlertSystem.module.sass";
import AlertSystemSettingsReview from "./alert/AlertSystemSettingsReview";
import AlertSystemSetting from "./alert/AlertSystemSetting";

const AlertSystem2 = ({ data, showDemo, hideDemo }) => {
  const [displayMode, setDisplayMode] = useState("overview");

  useEffect(() => {
    if (displayMode !== "overview") {
      hideDemo();
    } else {
      showDemo();
    }
  }, [displayMode]);

  return (
    <div>
      <div className={styles.main}>
        {displayMode === "overview" && (
          <AlertSystemSettingsReview
            data={data}
            setDisplayMode={setDisplayMode}
          />
        )}
        {displayMode === "setting" && (
          <AlertSystemSetting setDisplayMode={setDisplayMode} />
        )}
      </div>
    </div>
  );
};
export default AlertSystem2;

import { useSelector } from "react-redux";
import { useStrategy } from "./useCreateStrategyContext";
import { Checkbox, Tooltip } from "antd";

import styles from "../../../styles/Components/new-insight/create-strategy/FeatureOptions.module.css";
import { FiInfo } from "react-icons/fi";

// const CheckboxGroup = Checkbox.Group;

function FeatureOptions() {
  const {
    newInsightState: { categoriesFeatureTree },
  } = useSelector((state) => state);

  const {
    state: {
      selectedCategory: category,
      selectedSubcategory: subcategory,
      selectedFeatures,
    },
    setFeatures,
    removeFeature,
  } = useStrategy();

  const data = categoriesFeatureTree?.[category]?.[subcategory];
  // const featuresData = categoriesFeatureTree[category][subcategory];
  const features = Object.keys(data).map((key) => {
    return {
      feature: key,
      ...data[key],
    };
  });

  const onChange = (e, feature) => {
    if (e.target.checked) {
      setFeatures(feature);
    } else {
      removeFeature(feature);
    }
  };

  return (
    <div className={styles.container}>
      {features.map((f) => (
        <Checkbox
          key={`${f?.category}_${f?.subcategory}_${f.feature}`}
          checked={selectedFeatures?.includes(f.feature)}
          onChange={(e) => onChange(e, f)}
        >
          {window.innerWidth <= 768 && f?.feature?.length > 30 ? (
            <Tooltip title={f?.feature} color={"geekblue"}>
              {f?.feature?.substring(0, 30)}...
            </Tooltip>
          ) : (
            f?.feature
          )}
          <Tooltip
            className={styles.description}
            title={f.description}
            color="geekblue"
            placement="top"
          >
            <FiInfo />
          </Tooltip>
        </Checkbox>
      ))}
    </div>
  );
}

export default FeatureOptions;

import { CognitoUserPool } from 'amazon-cognito-identity-js';

// aws config

// new pool
// export const userPoolNew = new CognitoUserPool({
//     UserPoolId: "us-east-1_hndfVAcrv",
//     ClientId: "2ripi3sesj469upiiggivqa926"
// });

const userPool = {
    UserPoolId: 'us-east-1_X8ZvlAfzE',
    ClientId: '1pf2nms2247fik73t0rsju4s9h'
};
// const userPool = {
//     UserPoolId: 'us-east-1_8WMsl8AMg',
//     ClientId: '41qemsprq6sqqjraln2kqr04k7'
// };

export default new CognitoUserPool(userPool);

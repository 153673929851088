import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/Components/new-insight/signals-section/CurrentStrategyInfo.module.css";
import { FaLightbulb } from "react-icons/fa";
import { useEffect } from "react";
import {
  getStrategySignal,
  setCurrentStrategy,
} from "../../../redux/new-insight/actions";
import { Tooltip } from "antd";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

function CurrentStrategyInfo() {
  const {
    main: { userData },
    newInsightState: { currentStrategy, systemStrategies },
  } = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const {
    strategy_name: name,
    strategy_label: label,
    strategy_tag: tag,
  } = currentStrategy;

  useEffect(() => {
    if (
      userData?.email &&
      systemStrategies.length > 0 &&
      Object.keys(currentStrategy).length === 0
    ) {
      const cryptoCrispyStrategy = systemStrategies.find((s) =>
        s.strategy_name.toLowerCase().includes("cryptocrispy"),
      );
      searchParams.set("strategy", cryptoCrispyStrategy.createdDate);
      setSearchParams(searchParams);

      dispatch(setCurrentStrategy(cryptoCrispyStrategy));
      dispatch(
        getStrategySignal(
          userData["custom:custom_username"],
          userData?.email,
          cryptoCrispyStrategy?.createdDate,
          true,
        ),
      );
    }
  }, [currentStrategy, dispatch, systemStrategies, userData]);

  if (Object.keys(currentStrategy)?.length === 0) return null;

  return (
    <div className={styles.container}>
      <h4>
        <FaLightbulb color={"#f9ca24"} /> Current Strategy:
      </h4>
      <span className={styles.name} title="Name">
        {name}
      </span>
      {label && (
        <span className={styles.label} title="Label">
          {label}
        </span>
      )}
      {tag && (
        <span className={styles.tag} title="Tag">
          {tag}
        </span>
      )}

      {(label || tag) && (
        <Tooltip
          title={`${label ? "Label: " + label : ""} ${
            tag ? "Tag: " + tag : ""
          }`}
          className={styles.tooltip}
          color="geekblue"
        >
          <MdOutlineMoreHoriz />
        </Tooltip>
      )}
    </div>
  );
}

export default CurrentStrategyInfo;

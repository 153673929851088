import Modal from "../modal/Modal";
import styles from "../../styles/pages/profile/profile.module.sass";
import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { countryList } from "../../utility/data";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/profile/actions";

function NumberAndPostalCodeForm({ show, setShow }) {
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Australia",
    code: "AU",
    phone: 61,
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    main: { userData },
    profile: { updateLoading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const closeModal = () => {
    setShow(false);
    setSelectedCountry({
      name: "Australia",
      code: "AU",
      phone: 61,
    });
    setPhoneNumber("");
  };

  const selectBefore = (
    <Select
      showSearch
      value={`${selectedCountry.name}(${selectedCountry.phone})`}
      style={{ width: 200 }}
      placeholder="Select a country"
      optionFilterProp="children"
      onChange={(value) => {
        const countryData = countryList.find((item) => item.phone === value);
        setSelectedCountry(countryData);
      }}
      // filterOption={(input, option) => {
      //   // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //   setSearchedCountry(input.toLowerCase());
      // }}
      // onDropdownVisibleChange={(open) => {
      //   if (!open) {
      //     // Reset the searchedCountry state when the dropdown is closed
      //     setSearchedCountry("");
      //   }
      // }}
    >
      {countryList.map((item) => (
        <Select.Option key={item.name} value={item.phone}>
          {item.name} ({item.phone})
        </Select.Option>
      ))}
    </Select>
  );

  const updatePhoneNumber = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length > 10) return;
    setPhoneNumber(inputValue);
  };
  const submitHandler = async () => {
    if (!phoneNumber.length) return;
    const phoneNum = `+${selectedCountry.phone}${phoneNumber}`;

    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["phone_number"],
        [phoneNum],
      ),
    ).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      open={show}
      handleCancel={closeModal}
      footer={false}
      title={"Complete Info"}
      content={
        <div className={styles.infoModal}>
          {/*{console.log(selectedCountry)}*/}
          <Form
            layout="vertical"
            className={styles.form}
            name="control-ref"
            // onFinish={onFinish}
          >
            <Form.Item
              name="PhoneNumber"
              label="Phone Number"
              className={styles.formItem}
            >
              <Input
                addonBefore={selectBefore}
                style={{ borderRadius: 8 }}
                className={styles.input}
                value={phoneNumber}
                onChange={updatePhoneNumber}
              />
            </Form.Item>
            {/*<Form.Item*/}
            {/*  name="PostalCode"*/}
            {/*  label="Postal Code"*/}
            {/*  className={styles.formItem}*/}
            {/*>*/}
            {/*  <Input*/}
            {/*    label={"test"}*/}
            {/*    placeholder={"Postal Code"}*/}
            {/*    style={{ borderRadius: 8 }}*/}
            {/*    className={styles.input}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            <Form.Item name="submit">
              <Button
                type="primary"
                htmlType="submit"
                size={"large"}
                onClick={submitHandler}
                loading={updateLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      }
    />
  );
}

export default NumberAndPostalCodeForm;

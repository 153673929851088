import {
  ACCLAIM_GIFT_ERROR,
  ACCLAIM_GIFT_REQUEST,
  ACCLAIM_GIFT_SUCCESS,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS,
  ACTIVATE_SERVICE_WITH_CREDIT_ERROR,
  ACTIVATE_SERVICE_WITH_CREDIT_REQUEST,
  ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS,
  ACTIVATE_SERVICE_WITH_CRYPTO_ERROR,
  ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST,
  ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHARGE_CREDIT_WITH_CRYPTO_ERROR,
  CHARGE_CREDIT_WITH_CRYPTO_REQUEST,
  CHARGE_CREDIT_WITH_CRYPTO_SUCCESS,
  CLEAR_REDEEM_GIFT_MESSAGE,
  DISCOUNT_ERROR,
  DISCOUNT_REQUEST,
  DISCOUNT_SUCCESS,
  GET_FREE_PLAN_ERROR,
  GET_FREE_PLAN_REQUEST,
  GET_FREE_PLAN_SUCCESS,
  GET_PRICING_PLANS_ERROR,
  GET_PRICING_PLANS_REQUEST,
  GET_PRICING_PLANS_SUCCESS,
  GET_REFERRAL_CODE_ERROR,
  GET_REFERRAL_CODE_REQUEST,
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_REWARD_ERROR,
  GET_REFERRAL_REWARD_REQUEST,
  GET_REFERRAL_REWARD_SUCCESS,
  GET_STRIPE_CHECKOUT_SESSION_ERROR,
  GET_STRIPE_CHECKOUT_SESSION_REQUEST,
  GET_STRIPE_CHECKOUT_SESSION_SUCCESS,
  GET_USER_SUBSCRIPTIONS_ERROR,
  GET_USER_SUBSCRIPTIONS_REQUEST,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  INCREASE_CREDIT_WITH_CARD_ERROR,
  INCREASE_CREDIT_WITH_CARD_REQUEST,
  INCREASE_CREDIT_WITH_CARD_SUCCESS,
  REDEEM_GIFT_ERROR,
  REDEEM_GIFT_REQUEST,
  REDEEM_GIFT_SUCCESS,
  REFUND_ERROR,
  REFUND_REQUEST,
  REFUND_SUCCESS,
  RENEW_ERROR,
  RENEW_REQUEST,
  RENEW_SUCCESS,
  SEND_CHAT_ERROR,
  SEND_CHAT_REQUEST,
  SEND_CHAT_SUCCESS,
  WITHDRAW_ERROR,
  WITHDRAW_REQUEST,
  WITHDRAW_SUCCESS,
} from "./consts";

const initialState = {
  freePlanLoading: false,
  freePlan: {},
  referralCode: "",
  referralCodeLoading: false,
  referralCodeError: "",
  referralRewards: [],
  referralData: {},
  referralRewardsLoading: false,
  referralRewardsError: "",
  acclaimGiftLoading: false,
  acclaimGiftError: "",
  acclaimGiftMessage: "",
  redeemGiftLoading: false,
  redeemGiftMessage: "",
  redeemGiftError: "",
  checkoutSession: "",
  checkoutError: "",
  checkoutLoading: false,
  getSubscriptionLoading: false,
  subscriptions: {},
  subscriptionError: "",
  cancelSubscriptionLoading: false,
  cancelSubscription: {},
  cancelSubscriptionError: "",
  refundMessage: "",
  refundLoading: false,
  refundError: "",
  renewLoading: false,
  renewMessage: "",
  renewError: "",
  discountLoading: false,
  discountMessage: "",
  discountError: "",
  sendChatLoading: false,
  sendChatMessage: "",
  sendChatError: "",
  error: "",
  chargeCreditWithCardStatus: "",
  chargeCreditWithCardResponse: {},
  chargeCreditWithCryptoStatus: "",
  chargeCreditWithCryptoResponse: {},
  activateServiceWithCreditStatus: "",
  activateServiceWithCreditResponse: {},
  activateServiceWithCryptoStatus: "",
  activateServiceWithCryptoResponse: {},
  activateServiceWithCardStatus: "",
  activateServiceWithCardResponse: {},
  pricingPlan: {},
  pricingPlanStatus: "",
  renewTarget: null,
  withdrawStatus: "",
  withdrawMessage: "",
  withdrawError: "",
};
export const pricingPlanReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_FREE_PLAN_REQUEST:
      return {
        ...state,
        freePlanLoading: true,
      };
    case GET_FREE_PLAN_SUCCESS:
      return {
        ...state,
        freePlanLoading: false,
        freePlan: action?.payload,
      };
    case GET_FREE_PLAN_ERROR:
      return {
        ...state,
        freePlanLoading: false,
        error: action?.payload,
      };
    case GET_STRIPE_CHECKOUT_SESSION_REQUEST:
      return {
        ...state,
        checkoutLoading: true,
        checkoutSession: "",
      };
    case GET_STRIPE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        checkoutLoading: false,
        checkoutSession: action?.payload,
      };
    case GET_STRIPE_CHECKOUT_SESSION_ERROR:
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: action?.payload,
      };
    case GET_USER_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        getSubscriptionLoading: true,
        subscriptions: {},
      };
    case GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        getSubscriptionLoading: false,
        subscriptions: action?.payload,
      };
    case GET_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        getSubscriptionLoading: false,
        subscriptionError: action?.payload,
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelSubscriptionLoading: true,
        cancelSubscription: {},
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionLoading: false,
        cancelSubscription: action?.payload,
      };
    case CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        cancelSubscriptionLoading: false,
        cancelSubscription: {},
        cancelSubscriptionError: action?.payload,
      };
    case REFUND_REQUEST:
      return {
        ...state,
        refundLoading: true,
        refundError: "",
        refundMessage: "",
      };
    case REFUND_SUCCESS:
      return {
        ...state,
        refundLoading: false,
        refundError: "",
        refundMessage: action?.payload,
      };
    case REFUND_ERROR:
      return {
        ...state,
        refundLoading: false,
        refundError: action?.payload,
        refundMessage: "",
      };
    case RENEW_REQUEST:
      return {
        ...state,
        renewLoading: true,
        renewMessage: "",
        renewError: "",
        renewTarget: action?.payload,
      };
    case RENEW_SUCCESS:
      return {
        ...state,
        renewLoading: false,
        renewMessage: action?.payload,
        renewError: "",
        renewTarget: null,
      };
    case RENEW_ERROR:
      return {
        ...state,
        renewLoading: false,
        renewMessage: "",
        renewError: action?.payload,
        renewTarget: null,
      };
    case DISCOUNT_REQUEST:
      return {
        ...state,
        discountLoading: true,
        discountMessage: "",
        discountError: "",
      };
    case DISCOUNT_SUCCESS:
      return {
        ...state,
        discountLoading: false,
        discountMessage: action?.payload,
      };
    case DISCOUNT_ERROR:
      return {
        ...state,
        discountLoading: false,
        discountMessage: "",
        discountError: action?.payload,
      };
    case SEND_CHAT_REQUEST:
      return {
        ...state,
        sendChatLoading: true,
        sendChatMessage: "",
        sendChatError: "",
      };
    case SEND_CHAT_SUCCESS:
      return {
        ...state,
        sendChatLoading: false,
        sendChatMessage: action?.payload,
        sendChatError: "",
      };
    case SEND_CHAT_ERROR:
      return {
        ...state,
        sendChatLoading: false,
        sendChatMessage: "",
        sendChatError: action?.payload,
      };
    case GET_REFERRAL_CODE_REQUEST:
      return {
        ...state,
        referralCodeLoading: true,
      };
    case GET_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        referralCodeLoading: false,
        referralCode: action?.payload,
      };
    case GET_REFERRAL_CODE_ERROR:
      return {
        ...state,
        referralCodeError: action?.payload,
        referralCodeLoading: false,
      };
    case GET_REFERRAL_REWARD_REQUEST:
      return {
        ...state,
        referralRewardsLoading: true,
      };
    case GET_REFERRAL_REWARD_SUCCESS:
      return {
        ...state,
        referralRewardsLoading: false,
        referralRewards: action?.payload?.referral_items,
        referralData: action?.payload,
      };
    case GET_REFERRAL_REWARD_ERROR:
      return {
        ...state,
        referralRewardsLoading: false,
        referralRewardsError: action?.payload,
      };
    case ACCLAIM_GIFT_REQUEST:
      return {
        ...state,
        acclaimGiftLoading: true,
        acclaimGiftMessage: "",
        acclaimGiftError: "",
      };
    case ACCLAIM_GIFT_SUCCESS:
      return {
        ...state,
        acclaimGiftLoading: false,
        acclaimGiftMessage: action?.payload,
        acclaimGiftError: "",
      };
    case ACCLAIM_GIFT_ERROR:
      return {
        ...state,
        acclaimGiftLoading: false,
        acclaimGiftError: action?.payload,
        acclaimGiftMessage: "",
      };
    case REDEEM_GIFT_REQUEST:
      return {
        ...state,
        redeemGiftLoading: true,
        redeemGiftMessage: "",
        redeemGiftError: "",
      };
    case REDEEM_GIFT_SUCCESS:
      return {
        ...state,
        redeemGiftLoading: false,
        redeemGiftMessage: action.payload,
        redeemGiftError: "",
      };
    case REDEEM_GIFT_ERROR:
      return {
        ...state,
        redeemGiftLoading: false,
        redeemGiftError: action.payload,
        redeemGiftMessage: "",
      };
    case CLEAR_REDEEM_GIFT_MESSAGE:
      return {
        ...state,
        redeemGiftMessage: "",
        redeemGiftError: "",
      };

    case INCREASE_CREDIT_WITH_CARD_REQUEST:
      return {
        ...state,
        chargeCreditWithCardStatus: "loading",
        chargeCreditWithCardResponse: {},
      };
    case INCREASE_CREDIT_WITH_CARD_SUCCESS:
      return {
        ...state,
        chargeCreditWithCardStatus: "ready",
        chargeCreditWithCardResponse: action.payload,
      };

    case INCREASE_CREDIT_WITH_CARD_ERROR:
      return {
        ...state,
        chargeCreditWithCardStatus: "error",
      };

    // chargeCreditWithCryptoStatus
    // chargeCreditWithCryptoResponse
    case CHARGE_CREDIT_WITH_CRYPTO_REQUEST:
      return {
        ...state,
        chargeCreditWithCryptoStatus: "loading",
        chargeCreditWithCryptoResponse: {},
      };

    case CHARGE_CREDIT_WITH_CRYPTO_SUCCESS:
      return {
        ...state,
        chargeCreditWithCryptoStatus: "ready",
        chargeCreditWithCryptoResponse: action.payload,
      };

    case CHARGE_CREDIT_WITH_CRYPTO_ERROR:
      return {
        ...state,
        chargeCreditWithCryptoStatus: "error",
      };

    case ACTIVATE_SERVICE_WITH_CREDIT_REQUEST:
      return {
        ...state,
        activateServiceWithCreditStatus: "loading",
        activateServiceWithCreditResponse: {},
      };

    case ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS:
      return {
        ...state,
        activateServiceWithCreditStatus: "ready",
        activateServiceWithCreditResponse: action?.payload,
      };

    case ACTIVATE_SERVICE_WITH_CREDIT_ERROR:
      return {
        ...state,
        activateServiceWithCreditStatus: "error",
      };

    case ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST:
      return {
        ...state,
        activateServiceWithCryptoStatus: "loading",
        activateServiceWithCryptoResponse: {},
      };

    case ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS:
      return {
        ...state,
        activateServiceWithCryptoStatus: "ready",
        activateServiceWithCryptoResponse: action?.payload,
      };

    case ACTIVATE_SERVICE_WITH_CRYPTO_ERROR:
      return {
        ...state,
        activateServiceWithCryptoStatus: "error",
      };

    case "reset-activationWithCreditData":
      return {
        ...state,
        activateServiceWithCreditStatus: "",
        activateServiceWithCreditResponse: {},
      };

    case "reset-activationWithCryptoData":
      return {
        ...state,
        activateServiceWithCryptoStatus: "",
        activateServiceWithCryptoResponse: {},
      };

    case ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST:
      return {
        ...state,
        activateServiceWithCardStatus: "loading",
        activateServiceWithCardResponse: {},
      };
    case ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        activateServiceWithCardStatus: "ready",
        activateServiceWithCardResponse: action.payload,
      };
    case ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR:
      return {
        ...state,
        activateServiceWithCardStatus: "error",
      };

    case "reset-activationWithCardData":
      return {
        ...state,
        activateServiceWithCardStatus: "",
        activateServiceWithCardResponse: {},
      };

    case GET_PRICING_PLANS_REQUEST:
      return {
        ...state,
        pricingPlanStatus: "loading",
      };
    case GET_PRICING_PLANS_SUCCESS:
      return {
        ...state,
        pricingPlanStatus: "ready",
        pricingPlan: action.payload,
      };
    case GET_PRICING_PLANS_ERROR:
      return {
        ...state,
        pricingPlanStatus: "error",
      };

    case WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawStatus: "loading",
        withdrawError: "",
        withdrawMessage: "",
      };

    case WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawStatus: "ready",
        withdrawMessage: action?.payload,
      };

    case WITHDRAW_ERROR:
      return {
        ...state,
        withdrawStatus: "error",
        withdrawError: action?.payload,
      };

    default:
      return state;
  }
};

import { Select } from "antd";

const { Option } = Select;

function StrategyTags({ handleTagChange, defaultValue }) {
  return (
    <Select
      placeholder="Select a tag"
      defaultValue={defaultValue || ""}
      onChange={handleTagChange}
    >
      <Option value="News Sentiment">News Sentiment</Option>
      <Option value="Social Media Sentiment">Social Media Sentiment</Option>
      <Option value="Volume Trends">Volume Trends</Option>
      <Option value="Volatility Index">Volatility Index</Option>
      <Option value="Market Mood Analysis">Market Mood Analysis</Option>
      <Option value="Emotional Tone Detection">Emotional Tone Detection</Option>
      <Option value="Pattern Analysis">Pattern Analysis</Option>
      <Option value="Portfolio Balancing">Portfolio Balancing</Option>
      <Option value="Risk Mitigation">Risk Mitigation</Option>
      <Option value="High-Frequency Algorithms">
        High-Frequency Algorithms
      </Option>
      <Option value="Sentiment Rating">Sentiment Rating</Option>
      <Option value="Custom">Custom</Option>
    </Select>
  );
}

export default StrategyTags;

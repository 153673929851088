import { Spin } from "antd";

const containerStyle = {
  width: "100%",
  height: "400px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function Loader({ title, height }) {
  const style = {
    ...containerStyle,
  };

  if (height) style.height = height;

  return (
    <div style={style}>
      <Spin tip={title || "Please wait"} />
    </div>
  );
}

export default Loader;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import Loader from "../Loader";
import { FiChevronLeft } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { setStep } from "../../../../redux/trade/actions";
import LimitOrderResultBox from "../LimitOrderResultBox";
import AddToVirtualConfirmationDialog from "../AddToVirtualConfirmationDialog";

function LimitOrderResult({ setShow, step }) {
  const {
    tradeState: {
      takeProfitOrderLoading,
      stopLossOrderLoading,
      takeProfitOrderError,
      stopLossOrderError,
      takeProfitOrderData,
      stopLossOrderData,
      isTakeProfitOrdered,
      isStopLossOrdered,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const goBackHandler = () => {
    dispatch(setStep("set-limit-order"));
  };
  return (
    <div
      className={`${step === "limit-order-result" ? styles.active : ""} ${
        styles.step
      }`}
    >
      {!takeProfitOrderLoading &&
        !stopLossOrderLoading &&
        (takeProfitOrderError || stopLossOrderError) && (
          <span
            className={styles.backBtn}
            style={{ alignSelf: "flex-start" }}
            onClick={goBackHandler}
          >
            <FiChevronLeft />
            Back
          </span>
        )}

      <div className={styles.resultSummary}>
        {isTakeProfitOrdered && (
          <LimitOrderResultBox
            loading={takeProfitOrderLoading}
            title={"Take Profit"}
            errorData={takeProfitOrderError}
            successData={takeProfitOrderData}
          />
        )}

        {isStopLossOrdered && (
          <LimitOrderResultBox
            loading={stopLossOrderLoading}
            title={"Stop Loss"}
            errorData={stopLossOrderError}
            successData={stopLossOrderData}
          />
        )}
      </div>

      {!takeProfitOrderLoading && !stopLossOrderLoading && (
        <AddToVirtualConfirmationDialog setShow={setShow} />
      )}
    </div>
  );
}

export default LimitOrderResult;

import React, { useEffect, useState } from "react";
import styles from "../styles/shared/VirtualFeeSetting.module.css";
import { Button, InputNumber, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfile,
  updateProfileAttribute,
} from "../redux/profile/actions";

const suggestions = [
  {
    exchange: "Binance",
    fee: 0.1,
  },
  { exchange: "Coinbase", fee: 0.5 },
  { exchange: "Kraken", fee: 0.26 },
  { exchange: "Bittrex", fee: 0.35 },
  { exchange: "Swyftx", fee: 0.6 },
];

function VirtualFeeSetting({ setShow, orderTrade }) {
  const {
    profile: {
      profileData: { transaction_fee: fee },
      loading,
      updateLoading,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(fee * 100 || 0.1);
  const [inputValue, setInputValue] = useState(fee * 100 || 0.1);

  const handleSliderChange = (value) => {
    setSliderValue(value);
    setInputValue(value);
  };

  const handleInputChange = (value) => {
    setSliderValue(value);
    setInputValue(value);
  };

  const handleButtonClick = (percent) => {
    setSliderValue(percent);
    setInputValue(percent);
  };

  const submitHandler = async () => {
    if (loading || updateLoading) return;

    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["transaction_fee"],
        [`${sliderValue / 100}`],
        false,
      ),
    );
    dispatch(updateProfileAttribute("transaction_fee", `${sliderValue / 100}`));
    if (setShow) {
      setShow(false);
      orderTrade("", true);
    }
  };

  useEffect(() => {
    if (!loading && fee) {
      setInputValue(fee * 100);
      setSliderValue(fee * 100);
    }
  }, [loading, fee]);

  return (
    <div className={styles.container}>
      <p>
        Adjust the transaction fee for your virtual trades. The transaction fee
        is a percentage of your trade value that covers the cost of using the
        trading platform.
      </p>
      <div className={styles.sliderBox}>
        <Slider
          min={0.1}
          max={10}
          step={0.01}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{ width: "80%" }}
        />
        <InputNumber
          min={0.1}
          max={10}
          step={0.01}
          value={inputValue}
          onChange={handleInputChange}
          style={{ borderRadius: ".4rem", width: "20%" }}
        />
      </div>
      <p>Suggested Fees for Top Exchanges:</p>
      <div className={styles.suggestions}>
        {suggestions.map((item, index) => (
          <Button
            key={index}
            className={`${styles.suggestionBtn} ${
              item.fee === sliderValue ? styles.active : ""
            }`}
            onClick={() => handleButtonClick(item.fee)}
          >
            <span>{item.exchange}</span>
            <span>{item.fee}%</span>
          </Button>
        ))}
      </div>
      <Button
        loading={updateLoading}
        className={styles.submitBtn}
        onClick={submitHandler}
      >
        Submit
      </Button>
    </div>
  );
}

export default VirtualFeeSetting;

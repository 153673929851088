import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import TradeModal from "../../../shared/trade-modal/TradeModal";
import AIAssistantServiceActivationModal from "../AIAssistantServiceActivationModal";
import {
  setBasicTradeInfo,
  setMode,
  setStep,
} from "../../../redux/trade/actions";
import { formatAmount, formatPrice } from "../../../utility/helper";
import { FaExchangeAlt } from "react-icons/fa";
import NewVirtualPortfolioTableExpandedRow from "./NewVirtualPortfolioTableExpandedRow";

function NewVirtualPortfolioTable({ data, height, budget }) {
  const {
    tradingAssistant: { getPortfolioDataLoading },
    profile: { profileData },
  } = useSelector((state) => state);

  const [showTradeModal, setShowTradeModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const isServiceActive = profileData?.myais_active === "True";

  const dispatch = useDispatch();

  const onRowClick = (record) => {
    if (record?.coin?.SYMBOL_NAME === "USDT") return;

    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const setTradeHandler = (item, e) => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }

    if (item?.SYMBOL_NAME === "USDT") return;

    e.stopPropagation();

    const { MARKET_TYPE: market, SYMBOL_NAME: asset } = item;
    dispatch(
      setBasicTradeInfo({
        market,
        asset,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  const columns = [
    {
      title: "Balance in USDT",
      dataIndex: "balance",
      key: "balance",
      align: "center",
      responsive: ["xs", "sm"],
      width: 80,
      render: (text) => (
        <div className={styles.tableContent}>
          <h4
            className={styles.coin}
            onClick={(e) => setTradeHandler(text?.item, e)}
          >
            <img
              src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${
                text.asset.includes("/") ? text.asset.slice("/")[0] : text.asset
              }.png`}
              alt={text}
              className={styles.coinIcon}
            />
            {text?.asset}
          </h4>
          <div className={styles.balances}>
            <span>{formatAmount(text.balance)}</span>
            <p>{formatAmount(text.amount)} USD</p>
          </div>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 60,
      responsive: ["sm"],
      align: "center",
      render: (text) => (
        <span className={`${styles.tableContent}`}>${formatPrice(text)}</span>
      ),
    },
    {
      title: "PNL",
      dataIndex: "pnl",
      key: "pnl",
      width: 60,
      responsive: ["xs", "sm"],
      align: "center",
      render: ({ value, percent }) => (
        <div className={`${styles.pnlInfo}`}>
          {!value && !percent ? (
            "-"
          ) : (
            <>
              <span
                className={`${Number(value) > 0 ? styles.positive : ""} ${
                  Number(value) < 0 ? styles.negative : ""
                }`}
              >
                {value || "-"}
                {value && "USDT"}
              </span>
              {percent && (
                <span
                  className={`${Number(percent) > 0 ? styles.positive : ""} ${
                    Number(percent) < 0 ? styles.negative : ""
                  }`}
                >
                  ({percent})%
                </span>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Buy/Sell Asset",
      dataIndex: "buyNow",
      key: "buyNow",
      align: "center",
      width: 82,
      responsive: ["xs", "sm"],
      render: (item) =>
        item?.SYMBOL_NAME === "USDT" ? (
          "-"
        ) : (
          <Button
            className={styles.buyNow}
            onClick={(e) => setTradeHandler(item, e)}
          >
            {/*<BsPlusSquare />*/}
            <FaExchangeAlt />
          </Button>
        ),
    },
  ];
  const [tabletData, setTableData] = useState([]);

  useEffect(() => {
    if (data) {
      setTableData(() => {
        const budgetData = {
          MARKET_TYPE: "crypto",
          NATIVE_AMOUNT: +budget,
          USD_AMOUNTS: +budget,
          SYMBOL_NAME: "USDT",
          SYMBOL_1D_PRICE_CHANGE: "",
          SYMBOL_1H_PRICE_CHANGE: "",
          SYMBOL_7D_PRICE_CHANGE: "",
          SYMBOL_7D_PRICE_HISTORY: "",
          CURRENT_PRICE: 1,
        };
        return [budgetData, ...data]?.map((item, index) => {
          return {
            key: index,
            marketType: item?.MARKET_TYPE,
            coin: item,
            oneHour: item?.SYMBOL_1H_PRICE_CHANGE,
            oneDay: item?.SYMBOL_1D_PRICE_CHANGE,
            oneWeek: item?.SYMBOL_7D_PRICE_CHANGE,
            nativeAmount: item?.NATIVE_AMOUNT,
            usdAmount: item?.USD_AMOUNTS,
            last7Days: item?.SYMBOL_7D_PRICE_HISTORY,
            buyNow: item,
            balance: {
              asset: item?.SYMBOL_NAME,
              balance: item?.NATIVE_AMOUNT,
              amount: item?.USD_AMOUNTS,
              item,
            },
            price: item?.CURRENT_PRICE,
            pnl: {
              value: item?.PNL,
              percent: item?.PNL_PERCENT,
            },
          };
        });
      });
    }
  }, [budget, data]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={tabletData}
        pagination={false}
        scroll={{ y: height }}
        className={`${styles.table} expanded-table`}
        loading={getPortfolioDataLoading}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
        expandable={{
          expandedRowRender: (record) => (
            <NewVirtualPortfolioTableExpandedRow data={record} />
          ),
          rowExpandable: (record) => record?.coin?.SYMBOL_NAME !== "USDT",
          expandedRowKeys: expandedRowKeys,

          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.coin?.SYMBOL_NAME === "USDT") return "";
            if (expanded) {
              return (
                <IoChevronUp
                  className={styles.expandIcon}
                  size={20}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            } else {
              return (
                <IoChevronDown
                  size={20}
                  className={styles.expandIcon}
                  onClick={(e) => {
                    onRowClick(record);
                  }}
                />
              );
            }
          },
        }}
      />
      {showTradeModal && (
        <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
      )}

      {showServiceActivationModal && (
        <AIAssistantServiceActivationModal
          showActivationModal={showServiceActivationModal}
          setShowActivationModal={setShowServiceActivationModal}
        />
      )}
    </>
  );
}

export default NewVirtualPortfolioTable;

import {
  ADD_TO_VIRTUAL_PORTFOLIO,
  GET_TRADE_AMOUNT,
  GET_TRADE_PORTFOLIO_ERROR,
  GET_TRADE_PORTFOLIO_REQUEST,
  GET_TRADE_PORTFOLIO_SUCCESS,
  GET_TRIGGER_PRICE,
  GET_VIRTUAL_ASSET_LIST_ERROR,
  GET_VIRTUAL_ASSET_LIST_REQUEST,
  GET_VIRTUAL_ASSET_LIST_SUCCESS,
  GET_VIRTUAL_PORTFOLIO_ERROR,
  GET_VIRTUAL_PORTFOLIO_REQUEST,
  GET_VIRTUAL_PORTFOLIO_SUCCESS,
  RESET_TRADE,
  RESET_VIRTUAL_TRADE_STATE,
  SET_ASSET,
  SET_ASSET_AMOUNT,
  SET_ASSET_AMOUNT_USD,
  SET_BASIC_TRADE_INFO,
  SET_BUY_LIMIT,
  SET_EXCHANGE,
  SET_MODAL_MODE,
  SET_SECOND_TRADE_TYPE,
  SET_SELL_LIMIT,
  SET_STEP,
  SET_STOP_LOSS,
  SET_STOP_LOSS_AMOUNT,
  SET_STOP_LOSS_AMOUNT_PERCENT,
  SET_STOP_LOSS_AMOUNT_USD,
  SET_STOP_LOSS_ORDER_ERROR,
  SET_STOP_LOSS_ORDER_REQUEST,
  SET_STOP_LOSS_ORDER_SUCCESS,
  SET_STOP_LOSS_TRIGGER_PRICE,
  SET_STOP_LOSS_TRIGGER_PRICE_PERCENT,
  SET_TAKE_PROFIT,
  SET_TAKE_PROFIT_AMOUNT,
  SET_TAKE_PROFIT_AMOUNT_PERCENT,
  SET_TAKE_PROFIT_AMOUNT_USD,
  SET_TAKE_PROFIT_ORDER_ERROR,
  SET_TAKE_PROFIT_ORDER_REQUEST,
  SET_TAKE_PROFIT_ORDER_SUCCESS,
  SET_TAKE_PROFIT_TRIGGER_PRICE,
  SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT,
  SET_TARGET_PRICE,
  SET_TRADE_AMOUNT_USD,
  SET_TRADE_PROCESS,
  SET_TRADE_TYPE,
  SET_USDT_AMOUNT,
  SET_VIRTUAL_ASSET,
  SET_VIRTUAL_ASSET_AMOUNT_NATIVE,
  SET_VIRTUAL_MARKET_TYPE,
  SET_VIRTUAL_PORTFOLIO,
  SET_VIRTUAL_REMAINING_BUDGET,
  SET_VIRTUAL_SECOND_TRADE_TYPE,
  SET_VIRTUAL_TRADE_AMOUNT,
  SET_VIRTUAL_TRADE_AMOUNT_PERCENT,
  SET_VIRTUAL_TRADE_AMOUNT_USD,
  SET_VIRTUAL_TRADE_TYPE,
  SET_VIRTUAL_TRIGGER_PRICE,
} from "./const";

const defaultState = {
  selectedExchange: "",
  selectedAsset: "",
  tradeType: "",
  secondTradeType: "",
  tradeAmount: "",
  tradeAmountUsd: "",
  triggerPrice: "",
  currentStep: "",
  tradePortfolio: [],
  fee: "",
  tradePortfolioLoading: false,
  tradePortfolioError: "",
  usdtAmount: "",
  assetAmount: "",
  assetAmountUsd: "",
  modalMode: "",
  tradeProcess: "",
  virtualMarketType: "",
  virtualSelectedAsset: "",
  virtualSelectedPortfolio: "",
  virtualRemainingBudget: "",
  virtualTradeType: "",
  virtualSecondTradeType: "",
  virtualAssetAmount: "",
  virtualTradeAmount: "",
  virtualTradAmountPercent: "",
  virtualTradeAmountUsd: "",
  virtualPortfolioLoading: false,
  virtualPortfolioData: {},
  virtualPortfolioError: "",
  virtualTriggerPrice: { value: "", percent: "" },
  virtualAssetsLoading: false,
  virtualAssetsData: [],
  virtualAssetsError: "",
  stopLossOrderLoading: false,
  stopLossOrderData: {},
  stopLossOrderError: "",
  stopLossOrderTriggerPrice: "",
  stopLossTriggerPricePercent: "",
  stopLossAmountPercent: "",
  stopLossAmount: "",
  stopLossAmountUsd: "",
  isStopLossOrdered: false,
  takeProfitOrderLoading: false,
  isTakeProfitOrdered: false,
  takeProfitOrderData: {},
  takeProfitOrderError: "",
  takeProfitOrderTriggerPrice: "",
  takeProfitAmount: "",
  takeProfitAmountUsd: "",
  takeProfitAmountPercent: "",
  takeProfitTriggerPricePercent: "",
  isStopLossChecked: true,
  isTakeProfitChecked: false,
  basicTradeData: {},
  buyLimit: true,
  sellLimit: false,
  targetPrice: null,
  addedToVirtual: false,
  showCongratulationMessage: false,
};

const initialState = {
  selectedExchange: "",
  selectedAsset: "",
  tradeType: "",
  secondTradeType: "",
  tradeAmount: "",
  tradeAmountUsd: "",
  triggerPrice: "",
  currentStep: "",
  tradePortfolio: [],
  fee: "",
  tradePortfolioLoading: false,
  tradePortfolioError: "",
  usdtAmount: "",
  assetAmount: "",
  assetAmountUsd: "",
  modalMode: "",
  tradeProcess: "",
  virtualMarketType: "",
  virtualSelectedAsset: "",
  virtualSelectedPortfolio: "",
  virtualRemainingBudget: "",
  virtualTradeType: "",
  virtualSecondTradeType: "",
  virtualAssetAmount: "",
  virtualTradeAmount: "",
  virtualTradAmountPercent: "",
  virtualTradeAmountUsd: "",
  virtualPortfolioLoading: false,
  virtualPortfolioData: {},
  virtualPortfolioError: "",
  virtualTriggerPrice: { value: "", percent: "" },
  virtualAssetsLoading: false,
  virtualAssetsData: [],
  virtualAssetsError: "",
  stopLossOrderLoading: false,
  stopLossOrderData: {},
  stopLossOrderError: "",
  stopLossOrderTriggerPrice: "",
  stopLossTriggerPricePercent: "",
  stopLossAmountPercent: "",
  stopLossAmount: "",
  stopLossAmountUsd: "",
  isStopLossOrdered: false,
  takeProfitOrderLoading: false,
  isTakeProfitOrdered: false,
  takeProfitOrderData: {},
  takeProfitOrderError: "",
  takeProfitOrderTriggerPrice: "",
  takeProfitTriggerPricePercent: "",
  takeProfitAmountPercent: "",
  takeProfitAmount: "",
  takeProfitAmountUsd: "",
  isStopLossChecked: true,
  isTakeProfitChecked: false,
  basicTradeData: {},
  buyLimit: true,
  sellLimit: false,
  targetPrice: null,
  addedToVirtual: false,
};

export const tradeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EXCHANGE:
      return {
        ...state,
        selectedExchange: payload,
      };
    case SET_ASSET:
      return {
        ...state,
        selectedAsset: payload,
      };
    case SET_TRADE_TYPE:
      return {
        ...state,
        tradeType: payload,
      };
    case SET_SECOND_TRADE_TYPE:
      return {
        ...state,
        secondTradeType: payload,
      };
    case SET_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case RESET_TRADE:
      return defaultState;
    case GET_TRADE_AMOUNT:
      return {
        ...state,
        tradeAmount: payload,
      };
    case SET_TRADE_AMOUNT_USD:
      return {
        ...state,
        tradeAmountUsd: payload,
      };
    case GET_TRIGGER_PRICE:
      return {
        ...state,
        triggerPrice: payload,
      };
    case GET_TRADE_PORTFOLIO_REQUEST:
      return {
        ...state,
        tradePortfolioLoading: true,
        tradePortfolio: [],
        tradePortfolioError: "",
      };
    case GET_TRADE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        tradePortfolio: payload.portfolio,
        fee: parseFloat(payload.transaction_fee),
        tradePortfolioLoading: false,
      };
    case GET_TRADE_PORTFOLIO_ERROR:
      return {
        ...state,
        tradePortfolioLoading: false,
        tradePortfolioError: payload,
      };
    case SET_USDT_AMOUNT:
      return {
        ...state,
        usdtAmount: payload,
      };
    case SET_ASSET_AMOUNT:
      return {
        ...state,
        assetAmount: payload,
      };
    case SET_ASSET_AMOUNT_USD:
      return {
        ...state,
        assetAmountUsd: payload,
      };
    case SET_MODAL_MODE:
      return {
        ...state,
        modalMode: payload,
      };
    case SET_TRADE_PROCESS:
      return {
        ...state,
        tradeProcess: payload,
      };
    case SET_VIRTUAL_MARKET_TYPE:
      return {
        ...state,
        virtualMarketType: payload,
      };
    case SET_VIRTUAL_ASSET:
      return {
        ...state,
        virtualSelectedAsset: payload,
      };
    case SET_VIRTUAL_PORTFOLIO:
      return {
        ...state,
        virtualSelectedPortfolio: payload,
      };
    case SET_VIRTUAL_REMAINING_BUDGET:
      return {
        ...state,
        virtualRemainingBudget: payload,
      };
    case SET_VIRTUAL_TRADE_TYPE:
      return {
        ...state,
        virtualTradeType: payload,
      };
    case SET_VIRTUAL_ASSET_AMOUNT_NATIVE:
      return {
        ...state,
        virtualAssetAmount: payload,
      };
    case SET_VIRTUAL_SECOND_TRADE_TYPE:
      return { ...state, virtualSecondTradeType: payload };
    case SET_VIRTUAL_TRADE_AMOUNT:
      return {
        ...state,
        virtualTradeAmount: payload,
      };
    case SET_VIRTUAL_TRADE_AMOUNT_PERCENT:
      return {
        ...state,
        virtualTradAmountPercent: payload,
      };
    case SET_VIRTUAL_TRADE_AMOUNT_USD:
      return {
        ...state,
        virtualTradeAmountUsd: payload,
      };
    case GET_VIRTUAL_PORTFOLIO_REQUEST:
      return {
        ...state,
        virtualPortfolioLoading: true,
        virtualPortfolioError: "",
      };
    case GET_VIRTUAL_PORTFOLIO_SUCCESS:
      return {
        ...state,
        virtualPortfolioData: payload,
        virtualPortfolioLoading: false,
      };
    case GET_VIRTUAL_PORTFOLIO_ERROR:
      return {
        ...state,
        virtualPortfolioLoading: false,
        virtualPortfolioError: payload,
      };
    case SET_VIRTUAL_TRIGGER_PRICE:
      return {
        ...state,
        virtualTriggerPrice: {
          value: payload.value,
          percent: payload.percent,
        },
      };
    case GET_VIRTUAL_ASSET_LIST_REQUEST:
      return {
        ...state,
        virtualAssetsLoading: true,
        virtualAssetsError: "",
      };
    case GET_VIRTUAL_ASSET_LIST_SUCCESS:
      return {
        ...state,
        virtualAssetsData: payload,
        virtualAssetsLoading: false,
      };
    case GET_VIRTUAL_ASSET_LIST_ERROR:
      return {
        ...state,
        virtualAssetsLoading: false,
        virtualAssetsError: payload,
      };
    case SET_STOP_LOSS_ORDER_REQUEST:
      return {
        ...state,
        isStopLossOrdered: true,
        stopLossOrderLoading: true,
        stopLossOrderError: "",
      };
    case SET_STOP_LOSS_ORDER_SUCCESS:
      return {
        ...state,
        stopLossOrderLoading: false,
        stopLossOrderData: payload,
      };
    case SET_STOP_LOSS_ORDER_ERROR:
      return {
        ...state,
        stopLossOrderLoading: false,
        stopLossOrderError: payload,
      };
    case SET_STOP_LOSS_TRIGGER_PRICE:
      return {
        ...state,
        stopLossOrderTriggerPrice: payload,
      };
    case SET_TAKE_PROFIT_ORDER_REQUEST:
      return {
        ...state,
        isTakeProfitOrdered: true,
        takeProfitOrderLoading: true,
        takeProfitOrderError: "",
      };
    case SET_TAKE_PROFIT_ORDER_SUCCESS:
      return {
        ...state,
        takeProfitOrderLoading: false,
        takeProfitOrderData: payload,
      };
    case SET_TAKE_PROFIT_ORDER_ERROR:
      return {
        ...state,
        takeProfitOrderLoading: false,
        takeProfitOrderError: payload,
      };
    case SET_TAKE_PROFIT_TRIGGER_PRICE:
      return {
        ...state,
        takeProfitOrderTriggerPrice: payload,
      };
    case SET_STOP_LOSS:
      return {
        ...state,
        isStopLossChecked: payload,
      };
    case SET_TAKE_PROFIT:
      return {
        ...state,
        isTakeProfitChecked: payload,
      };
    case SET_STOP_LOSS_AMOUNT_PERCENT:
      return {
        ...state,
        stopLossAmountPercent: payload,
      };
    case SET_TAKE_PROFIT_AMOUNT_PERCENT:
      return {
        ...state,
        takeProfitAmountPercent: payload,
      };
    case SET_TAKE_PROFIT_AMOUNT:
      return {
        ...state,
        takeProfitAmount: payload,
      };
    case SET_STOP_LOSS_AMOUNT:
      return {
        ...state,
        stopLossAmount: payload,
      };
    case SET_STOP_LOSS_AMOUNT_USD:
      return {
        ...state,
        stopLossAmountUsd: payload,
      };
    case SET_TAKE_PROFIT_AMOUNT_USD:
      return {
        ...state,
        takeProfitAmountUsd: payload,
      };
    case SET_STOP_LOSS_TRIGGER_PRICE_PERCENT:
      return { ...state, stopLossTriggerPricePercent: payload };
    case SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT:
      return { ...state, takeProfitTriggerPricePercent: payload };
    case SET_BASIC_TRADE_INFO:
      return {
        ...state,
        basicTradeData: payload,
      };

    case SET_BUY_LIMIT:
      return {
        ...state,
        buyLimit: !state.buyLimit,
      };

    case SET_SELL_LIMIT:
      return {
        ...state,
        sellLimit: !state.sellLimit,
      };

    case SET_TARGET_PRICE:
      return {
        ...state,
        targetPrice: payload,
      };

    case ADD_TO_VIRTUAL_PORTFOLIO:
      return {
        ...state,
        addedToVirtual: true,
      };

    case RESET_VIRTUAL_TRADE_STATE:
      return defaultState;
    default:
      return state;
  }
};

import MiniAppHeader from "./layout/MiniAppHeader";
import MiniAppContainer from "./MiniAppContainer";
import { useDispatch, useSelector } from "react-redux";
import ServiceStatusBar from "./shared/ServiceStatusBar";
import { useEffect } from "react";
import { getBotList } from "../../redux/bot/actions";
import Loader from "./shared/Loader";
import MiniBotSummary from "./mini-bots/MiniBotSummary";
import NoBotBox from "./mini-bots/NoBotBox";

function MiniBots() {
  const {
    profile: { profileData },
    main: { userData },
    botState: { botList, getBotListLoading, fetchedBotList, getBotListError },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userData?.email &&
      // !botList.length &&
      !fetchedBotList &&
      !getBotListLoading &&
      !getBotListError
    ) {
      dispatch(
        getBotList(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [dispatch, fetchedBotList, getBotListError, getBotListLoading, userData]);

  return (
    <MiniAppContainer>
      <MiniAppHeader page={"Bots"} />
      <ServiceStatusBar
        status={profileData?.mybot_active}
        expirationDate={profileData?.mybot_active_until}
        service={"mybot"}
      />

      {getBotListLoading && <Loader />}
      {!getBotListLoading && botList?.length > 0 && <MiniBotSummary />}
      {!getBotListLoading && botList?.length === 0 && <NoBotBox />}
    </MiniAppContainer>
  );
}

export default MiniBots;

import { useSelector } from "react-redux";
import styles from "../../../styles/Components/trade-assistant/portfolio/VirtualPortfolioInfo.module.css";
import React from "react";
import { Spin } from "antd";

export const numFormatter = (num, percent, usd) => {
  if (typeof num !== "number") {
    num = Number(num?.split("%")?.[0]);
  }
  if (Math.abs(num) > 99999999) {
    if (percent) {
      return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(2) + " B %";
    } else {
      return (
        Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(2) + " B USD"
      );
    }
  } else if (Math.abs(num) > 999999) {
    if (percent) {
      return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + " M %";
    } else {
      return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + " M USD";
    }
  } else if (Math.abs(num) > 999) {
    if (percent) {
      return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + " K %";
    } else {
      return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + " K USD";
    }
  }
  if (percent) {
    return Math.sign(num) * Math.abs(num).toFixed(2) + " %";
  } else if (usd) {
    return Math.sign(num) * Math.abs(num).toFixed(2) + " USD";
  }
};

function VirtualPortfolioInfo() {
  const {
    tradingAssistant: { portfolioData, getPortfolioDataLoading },
  } = useSelector((state) => state);
  return (
    <ul className={styles.container}>
      <li className={styles.infoBox}>
        <span>Balance Change 24H:</span>
        <span>
          {getPortfolioDataLoading ? (
            <Spin size={"small"} />
          ) : portfolioData?.BALANCE_CHANGE_USD_24H ? (
            `${numFormatter(
              portfolioData?.BALANCE_CHANGE_USD_24H,
              false,
              true,
            )} (${numFormatter(
              portfolioData?.BALANCE_CHANGE_PERCENTAGE_24H,
              true,
              false,
            )})`
          ) : (
            0
          )}
          {/*{getPortfolioDataLoading ? (*/}
          {/*  // ? "loading..."*/}
          {/*  <Spin size={"small"} />*/}
          {/*) : portfolioData?.BALANCE_CHANGE_PERCENTAGE_24H ? (*/}
          {/*  numFormatter(*/}
          {/*    portfolioData?.BALANCE_CHANGE_PERCENTAGE_24H,*/}
          {/*    true,*/}
          {/*    false,*/}
          {/*  )*/}
          {/*) : (*/}
          {/*  0*/}
          {/*)}*/}
        </span>
      </li>
      {/*<li className={styles.infoBox}>*/}
      {/*  <span>*/}
      {/*    {getPortfolioDataLoading ? (*/}
      {/*      // ? "loading..."*/}
      {/*      <Spin size={"small"} />*/}
      {/*    ) : portfolioData?.BALANCE_CHANGE_PERCENTAGE_24H ? (*/}
      {/*      numFormatter(*/}
      {/*        portfolioData?.BALANCE_CHANGE_PERCENTAGE_24H,*/}
      {/*        true,*/}
      {/*        false,*/}
      {/*      )*/}
      {/*    ) : (*/}
      {/*      0*/}
      {/*    )}*/}
      {/*  </span>*/}
      {/*  <span>Balance Change Percentage 24H</span>*/}
      {/*</li>*/}
      <li className={styles.infoBox}>
        <span>Balance Change 7D:</span>
        <span>
          {getPortfolioDataLoading ? (
            <Spin size={"small"} />
          ) : portfolioData?.BALANCE_CHANGE_USD_7D ? (
            `${numFormatter(
              portfolioData?.BALANCE_CHANGE_USD_7D,
              false,
              true,
            )} (${numFormatter(
              portfolioData?.BALANCE_CHANGE_PERCENTAGE_7D,
              true,
              false,
            )})`
          ) : (
            0
          )}
          {/*{getPortfolioDataLoading ? (*/}
          {/*  // ? "loading..."*/}
          {/*  <Spin size={"small"} />*/}
          {/*) : portfolioData?.BALANCE_CHANGE_USD_24H ? (*/}
          {/*  numFormatter(portfolioData?.BALANCE_CHANGE_USD_24H, false, true)*/}
          {/*) : (*/}
          {/*  0*/}
          {/*)}*/}
        </span>
      </li>
      {/*<li className={styles.infoBox}>*/}
      {/*  <span>*/}
      {/*    {getPortfolioDataLoading ? (*/}
      {/*      // ? "loading..."*/}
      {/*      <Spin size={"small"} />*/}
      {/*    ) : portfolioData?.BALANCE_CHANGE_PERCENTAGE_7D ? (*/}
      {/*      numFormatter(*/}
      {/*        portfolioData?.BALANCE_CHANGE_PERCENTAGE_7D,*/}
      {/*        true,*/}
      {/*        false,*/}
      {/*      )*/}
      {/*    ) : (*/}
      {/*      0*/}
      {/*    )}*/}
      {/*  </span>*/}
      {/*  <span>Balance Change Percentage 7D</span>*/}
      {/*</li>*/}
      {/*<li className={styles.infoBox}>*/}
      {/*  <span>*/}
      {/*    {getPortfolioDataLoading ? (*/}
      {/*      // ? "loading..."*/}
      {/*      <Spin size={"small"} />*/}
      {/*    ) : portfolioData?.BALANCE_CHANGE_USD_7D ? (*/}
      {/*      numFormatter(portfolioData?.BALANCE_CHANGE_USD_7D, false, true)*/}
      {/*    ) : (*/}
      {/*      0*/}
      {/*    )}*/}
      {/*  </span>*/}
      {/*  <span>Balance Change USD 7D</span>*/}
      {/*</li>*/}
    </ul>
  );
}

export default VirtualPortfolioInfo;

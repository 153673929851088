import { InputNumber } from "antd";
import styles from "../styles/shared/ChargeCreditInput.module.css";

function ChargeCreditInput({ value, handleChange, customClassName }) {
  return (
    <div className={`${styles.container} ${customClassName || ""}`}>
      <p>Enter the desired amount to add funds to your account instantly.</p>
      <InputNumber
        className={styles.input}
        min={5}
        value={value}
        onChange={handleChange}
        // formatter={(value) => `Amount: ${value}`}
        // parser={(value) => value.replace('Amount: ', '')}
        addonAfter="USDT"
        addonBefore={"Amount"}
      />
      <p className={styles.minimumText}>
        Minimum deposit: <span>5 USDT</span>
      </p>
    </div>
  );
}

export default ChargeCreditInput;

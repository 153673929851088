import { Link, useNavigate } from "react-router-dom";
import sorry from "../asset/Images/sorry.png";
import styles from "../styles/ExpiredPage.module.css";
import { ImHome } from "react-icons/im";

const ExpiredPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <img style={{ width: "400px" }} src={sorry} alt="Sorry" />
      <h2>Your account has been expired!</h2>
      <p>
        Please choose one of our pricing plans to continue using our service.
      </p>
      <div className={styles.links}>
        <span className={styles.homeBtn} onClick={() => navigate("/dashboard")}>
          <ImHome style={{ marginRight: ".5rem" }} />
          Home
        </span>
        <Link className={styles.planBtn} to="/pricing-billing/plans-list">
          See Pricing Plans
        </Link>
      </div>
    </div>
  );
};

export default ExpiredPage;

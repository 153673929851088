import styles from "../../styles/Components/mini-app/MinimalCard.module.css";

function MinimalCard({ children, customClassName, clickHandler }) {
  const handleClick = () => {
    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <div
      className={`${customClassName || ""} ${styles.container}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

export default MinimalCard;

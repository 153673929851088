import { RiHome4Line } from "react-icons/ri";
import styles from "../../../styles/Components/mini-app/layout/MiniAppHeader.module.css";
import { useNavigate } from "react-router-dom";

function MiniAppHeader({ page, description }) {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/mini-dashboard");
  };

  return (
    <div className={styles.container}>
      <span className={styles.homeIcon} onClick={goToHome}>
        <RiHome4Line size={22} />
      </span>
      <div className={styles.text}>
        <h3>{page}</h3>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}

export default MiniAppHeader;

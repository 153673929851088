import {
  FaBell,
  FaBrain,
  FaBriefcase,
  FaChartLine,
  FaCopy,
  FaExchangeAlt,
  FaPlayCircle,
  FaRobot,
  FaSignal,
  FaTerminal,
} from "react-icons/fa";
import { GiTrade } from "react-icons/gi";
import { TbSocial } from "react-icons/tb";
import styles from "../../../styles/Components/pricing-plan/plans/PlanFeaturesList.module.css";

const features = (planType) => {
  return [
    {
      icon: <FaExchangeAlt />,
      label: "Exchanges",
      status: "Unlimited integrations",
    },
    {
      icon: <FaBriefcase />,
      label: "Portfolio Management",
      status: "2 slot",
    },
    {
      icon: <GiTrade />,
      label: "Trade Inside Platform on Exchanges",
      status: "Available",
    },
    {
      icon: <FaRobot />,
      label: "Trading Bot",
      status: planType === "pro" ? "Disabled" : "",
    },
    {
      icon: <FaChartLine />,
      label: "Bot Backtests",
      status:
        planType === "basic"
          ? "2 per bot per week"
          : planType === "pro"
          ? "Disabled"
          : "Unlimited",
    },

    {
      icon: <FaBrain />,
      label: "AI Signals",
      status: planType === "basic" ? "Disabled" : "Unlimited",
    },
    {
      icon: <FaBell />,
      label: "Alert System",
      status: "Unlimited",
    },
    {
      icon: <FaPlayCircle />,
      label: "Trade Simulator",
      status: planType === "pro" ? "Disabled" : "Unlimited",
    },
    {
      icon: <FaCopy />,
      label: "Copy trading signals",
      status: planType === "basic" ? "Disabled" : "Unlimited",
    },
    {
      icon: <TbSocial />,
      label: "Social features",
      status: planType === "basic" ? "Disabled" : "Unlimited",
    },
    {
      icon: <FaSignal />,
      label: "AI Insights",
      status: planType === "basic" ? "Disabled" : "Unlimited",
    },
    {
      icon: <FaTerminal />,
      label: "Developer API",
      status: planType === "enterprise" ? "Available" : "Disabled",
    },
  ];
};

function PlanFeaturesList({ plan }) {
  return (
    <ul className={styles.features}>
      {features(plan)?.map((f, i) => (
        <li key={i} className={styles.feature}>
          <span
            className={`${styles.icon} ${
              f.status === "Disabled" ? styles.disabledIcon : ""
            }`}
          >
            {f.icon}
          </span>
          <span>
            {f.label}
            {f.status && ":"}
          </span>
          <span
            className={` ${styles.status} ${
              f.status === "Disabled" ? styles.disabledFeature : ""
            } ${f.status === "Unlimited" ? styles.unlimitedFeature : ""}`}
          >
            {f.status}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default PlanFeaturesList;

import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/Components/mini-app/mini-bots/MiniBotSummary.module.css";
import { riskAppetiteColor, riskOptions } from "../../../utility/data";
import React, { useEffect, useState } from "react";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import { RiExchangeLine, RiLockFill } from "react-icons/ri";
import { FiInfo } from "react-icons/fi";
import { FaChartBar, FaChartLine, FaRobot } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import MinimalBotActivationProcess from "./create-bot/MinimalBotActivationProcess";
import { deactivateBot, getBotActivity } from "../../../redux/bot/actions";
import { AiFillTrophy } from "react-icons/ai";
import { capitalizeString } from "../../../utility/formatter-helper";
import {
  MdAccountBalanceWallet,
  MdKeyboardDoubleArrowRight,
  MdMoreVert,
} from "react-icons/md";

const formatProfit = (profitValue) => {
  return Number(profitValue) !== 0 ? `${Number(profitValue).toFixed(2)}%` : 0;
};

function MiniBotSummary() {
  const {
    profile: { profileData },
    botState: {
      botList,
      checkBotStatusLoading,
      botActivityData,
      botActivityFetched,
      getBotActivityLoading,
    },
    main: { userData },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  const isServiceActive = profileData?.mybot_active === "True";

  const activeBot = botList?.find((bot) => bot?.bot_status !== "inactive");
  const lastBot = [...botList]?.sort((a, b) =>
    new Date(b.CreatedDate) > new Date(a.CreatedDate) ? 1 : -1,
  )[0];

  const displayBot = activeBot || lastBot;

  const {
    bot_configurations: {
      bot_name: name,
      selected_exchange: exchange,
      risk_tolerance: riskAppetite,
      number_of_coins: coinsNum,
    },
    CreatedDate: date,
    bot_status: status,
    exchange_name: exchangeName,
  } = displayBot;

  const botOtherData = botActivityData?.find((bot) => bot?.trader_id === date);

  const formatWinRate =
    botOtherData?.win_rate === "TBA"
      ? botOtherData?.win_rate
      : Number(botOtherData?.win_rate) !== 0
      ? `${botOtherData?.win_rate}%`
      : 0;

  const lastMonthProfit = formatProfit(botOtherData?.last_month_profit);
  const lastTwoWeeksProfit = formatProfit(botOtherData?.last_two_weeks_profit);
  const lastWeekProfit = formatProfit(botOtherData?.last_week_profit);

  const isBotActive = status !== "inactive";

  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showBotActivationModal, setShowBotActivationModal] = useState(false);

  const dispatch = useDispatch();

  const openActivationModal = () => {
    setShowActivationModal(true);
  };

  const showWarningForDeactivateBot = () => {
    Modal.confirm({
      title: "Warning",
      centered: true,
      content: "Are you sure you want to deactivate this bot?",
      okText: "No problem",
      okButtonProps: {
        style: {
          backgroundColor: "#0c3fce",
          borderColor: "#0c3fce",
        },
      },
      onOk: async () => {
        await dispatch(
          deactivateBot(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            date,
          ),
        );
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const activationHandler = () => {
    if (!isServiceActive) {
      openActivationModal();
    } else {
      if (isBotActive) {
        showWarningForDeactivateBot();
      } else {
        setShowBotActivationModal(true);
      }
    }
  };

  const createBotHandler = () => {
    if (!isServiceActive) {
      openActivationModal();
    } else {
      navigate("/mini-create-bot");
    }
  };

  useEffect(() => {
    if (userData?.email && botList?.length > 0 && !botActivityFetched) {
      dispatch(
        getBotActivity(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [userData, botList, dispatch, botActivityFetched]);

  return (
    <div className={styles.container}>
      <div className={styles.botInfoCard}>
        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaRobot /> Bot Name
          </span>
          <p className={styles.infoDescription}>{name}</p>
        </div>

        <div className={styles.info}>
          <span className={`${styles.infoTitle}`}>
            <FiInfo />
            Bot Status
          </span>
          <p
            className={`${styles.infoDescription} ${
              activeBot ? styles.activeBot : styles.notActiveBot
            }`}
          >
            {capitalizeString(status)}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <RiExchangeLine /> Exchange
          </span>
          <p className={`${styles.infoDescription} ${styles.exchange}`}>
            {exchange !== "None" && (
              <img
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange}.svg`}
                alt={exchange}
              />
            )}
            {exchange === "None"
              ? "-"
              : exchange === "cryptocrispy"
              ? "Test Exchange"
              : capitalizeString(exchangeName) || capitalizeString(exchange)}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <MdAccountBalanceWallet />
            Minimum USDT
          </span>
          <p className={styles.infoDescription}>{coinsNum * 10}$</p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaChartLine /> Risk Appetite
          </span>
          <p
            className={styles.infoDescription}
            style={{ color: `${riskAppetiteColor[riskAppetite - 1]}` }}
          >
            {riskOptions[riskAppetite - 1]}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <AiFillTrophy /> Win Rate
          </span>
          <p
            className={`${styles.infoDescription} ${
              getBotActivityLoading ? styles.loading : ""
            }`}
          >
            {getBotActivityLoading ? "Loading" : formatWinRate}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaChartBar /> Bot ROI (last week)
          </span>
          <p
            className={`${styles.infoDescription} ${
              getBotActivityLoading ? styles.loading : ""
            }`}
          >
            {getBotActivityLoading ? "Loading" : lastWeekProfit}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaChartBar /> Bot ROI (last 2 weeks)
          </span>
          <p
            className={`${styles.infoDescription} ${
              getBotActivityLoading ? styles.loading : ""
            }`}
          >
            {getBotActivityLoading ? "Loading" : lastTwoWeeksProfit}
          </p>
        </div>

        <div className={styles.info}>
          <span className={styles.infoTitle}>
            <FaChartBar /> Bot ROI (last month)
          </span>
          <p
            className={`${styles.infoDescription} ${
              getBotActivityLoading ? styles.loading : ""
            }`}
          >
            {getBotActivityLoading ? "Loading" : lastMonthProfit}
          </p>
        </div>
      </div>
      <div className={`${styles.actionBtns}`}>
        <div className={`${styles.actionBtn}`} onClick={activationHandler}>
          {!isServiceActive && <RiLockFill />}{" "}
          {isBotActive ? "Deactivate" : "Activate"}
        </div>

        <div className={`${styles.actionBtn}`} onClick={createBotHandler}>
          {!isServiceActive && <RiLockFill />} Create a New Bot
        </div>
      </div>

      {botList.length > 1 && (
        <div
          className={`${styles.otherBotsBtn}`}
          onClick={() => navigate("/mini-all-bots")}
        >
          {/*<MdMoreVert /> */}
          See All Bots
          <MdKeyboardDoubleArrowRight />
        </div>
      )}

      {showActivationModal && (
        <ServiceActivationModal
          service={"mybot"}
          show={showActivationModal}
          setShow={setShowActivationModal}
        />
      )}

      {showBotActivationModal && (
        <Modal
          open={showBotActivationModal}
          onCancel={() => setShowBotActivationModal(false)}
          maskClosable={false}
          footer={null}
          centered
          closable={!checkBotStatusLoading}
        >
          <MinimalBotActivationProcess
            bot={displayBot}
            closeModal={() => setShowBotActivationModal(false)}
          />
        </Modal>
      )}
    </div>
  );
}

export default MiniBotSummary;

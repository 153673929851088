import styles from "../../../styles/shared/trade-modal/ShowRecommnedation.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import { Button } from "antd";

function ShowRecommendation({ setShow, showCongrateMsg = true }) {
  const {
    tradeState: {
      selectedExchange,
      tradeProcess,
      virtualSelectedPortfolio,
      addedToVirtual,
    },
    profile: { profileData },
    tradingAssistant: { tradeData },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  const navigateToAIPortfolio = () => {
    navigate("/ai-assistant/portfolio");
  };

  const handleDirection = () => {
    if (tradeProcess === "paperTrading" || addedToVirtual) {
      navigateToAIPortfolio();
    } else {
      navigate("/integration/portfolio");
    }

    setShow(false);

    // if (
    //   selectedExchange === profileData?.portfolio1_linked_exchange ||
    //   selectedExchange === profileData?.portfolio2_linked_exchange
    // ) {
    //   navigateToAIPortfolio();
    //   return;
    // }
  };

  return (
    <div>
      <div className={styles.message}>
        {(showCongrateMsg ||
          (addedToVirtual && tradeData?.Message === "done")) && (
          <FaCheckCircle color={"#1abc9c"} size={30} />
        )}
        <p style={{ textAlign: "center", color: "rgba(0,0,0,.85)" }}>
          {(showCongrateMsg ||
            (addedToVirtual && tradeData?.Message === "done")) &&
            "Your trades are completed."}{" "}
          You can check the portfolio or keep trading using our signals.
        </p>
      </div>
      <div className={styles.btns}>
        <Button className={`${styles.btn}`} onClick={setShow}>
          <BsCurrencyExchange />
          Keep Trading
        </Button>
        <Button
          className={`${styles.btn} ${styles.checkBtn}`}
          type={"primary"}
          onClick={handleDirection}
        >
          <RiAccountPinBoxFill />
          Check Portfolio
        </Button>
      </div>
    </div>
  );
}

export default ShowRecommendation;

import styles from "../../../styles/Components/integration/portfolio/ExchangePortfolioTableExpandedRow.module.css";
import { formatAmount, formatPrice } from "../../../utility/helper";

function ExchangePortfolioTableExpandedRow({ data }) {
  return (
    <div className={styles.container}>
      <ul className={styles.dataList}>
        <li>
          <span className={styles.label}>Stop Loss:</span>
          <div className={styles.limitBox}>
            <span className={styles.secLabel}>Trigger Price:</span>
            <span>
              {data?.stopLoss?.trigger_price
                ? data?.stopLoss?.trigger_price
                : "-"}
            </span>
          </div>
          <div className={styles.limitBox}>
            <span className={styles.secLabel}>Amount:</span>
            <span>
              {data?.stopLoss?.amount
                ? formatAmount(+data?.stopLoss?.amount)
                : "-"}
            </span>
          </div>
        </li>
        <li>
          <span className={styles.label}>Take Profit:</span>
          <div className={styles.limitBox}>
            <span className={styles.secLabel}>Trigger Price:</span>
            <span>
              {data?.takeProfit?.trigger_price
                ? data?.takeProfit?.trigger_price
                : "-"}
            </span>
          </div>
          <div className={styles.limitBox}>
            <span className={styles.secLabel}>Amount:</span>
            <span>
              {data?.takeProfit?.amount
                ? formatAmount(+data?.takeProfit?.amount)
                : "-"}
            </span>
          </div>
        </li>

        {/*<li className={styles.nativeAmount}>*/}
        {/*  <span className={styles.label}>Native Amount:</span>*/}
        {/*  <span>{formatAmount(data?.availableBalance)}</span>*/}
        {/*</li>*/}
      </ul>
    </div>
  );
}

export default ExchangePortfolioTableExpandedRow;

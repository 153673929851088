import React, { useState } from "react";
import IntegratedExchangeList from "./portfolio-section/IntegratedExchangeList";
import TradeModal from "../../shared/trade-modal/TradeModal";
import styles from "../../styles/Components/integration/Portfolio.module.css";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import ExchangeNewPortfolioTable from "./portfolio-section/ExchangeNewPortfolioTable";
import { Tabs } from "antd";
import { ImTable } from "react-icons/im";
import TabPane from "antd/lib/tabs/TabPane";
import { TbChartPieFilled } from "react-icons/tb";
import ExchangePortfolioTableActionButtons from "./portfolio-section/ExchangePortfolioTableActionButtons";
import ExchangePortfolioCharts from "./portfolio-section/ExchangePortfolioCharts";
import ExchangeBalanceInfo from "./portfolio-section/ExchangeBalanceInfo";

function Portfolio() {
  const [showTradeModal, setShowTradeModal] = useState(false);

  return (
    <div>
      <h3 className={styles.title}>
        {console.log("run portfolio")}
        <strong>Portfolio</strong>
      </h3>

      <ExchangesAndPaperTradingTab
        activeTab={"exchange"}
        pageType={"portfolio"}
      />

      <IntegratedExchangeList />

      <ExchangePortfolioTableActionButtons />
      <Tabs type={"card"} className={`${styles.tabs} custom-tabs`} animated>
        <TabPane
          tab={
            <span>
              <ImTable size={18} /> Portfolio Table
            </span>
          }
          key={"table"}
        >
          <ExchangeNewPortfolioTable />
        </TabPane>
        <TabPane
          tab={
            <span>
              <TbChartPieFilled size={18} />
              Portfolio Charts
            </span>
          }
          key={"chart"}
        >
          <ExchangeBalanceInfo />
          <ExchangePortfolioCharts />
        </TabPane>
      </Tabs>
      {/*<IntegratedExchangeList page={"portfolio"} />*/}

      {/*<ExchangePortfolioTable />*/}
      <TradeModal show={showTradeModal} setShow={setShowTradeModal} />
    </div>
  );
}

export default Portfolio;

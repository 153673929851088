import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyModal.module.css";
import React, { useState } from "react";
import CreateStrategySidebar from "./CreateStrategySidebar";
import CreateStrategyMain from "./CreateStrategyMain";
import StrategyContextProvider from "./useCreateStrategyContext";
import CreateStrategyForm from "./CreateStrategyForm";
import SearchStrategy from "./SearchStrategy";

function CreateStrategy() {
  const [step, setStep] = useState(1);
  return (
    <StrategyContextProvider>
      <div className={styles.container}>
        {step === 2 ? (
          <CreateStrategyForm goToPrevStep={() => setStep(1)} />
        ) : (
          <>
            <SearchStrategy />
            <div className={styles.body}>
              <CreateStrategySidebar />
              <CreateStrategyMain setStep={setStep} />
            </div>
          </>
        )}
      </div>
    </StrategyContextProvider>
  );
}

export default CreateStrategy;

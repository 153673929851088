import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getAssetList,
  getIntegratedExchanges,
} from "../../../../redux/exchange/actions";
import Loader from "../Loader";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import {
  getTradePortfolio,
  setAssetAmount,
  setAssetAmountUsd,
  setExchange,
  setStep,
  setTradeType,
  setUsdtAmount,
} from "../../../../redux/trade/actions";
import { AiFillCaretRight } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
import {
  checkBudgetIsEnough,
  isCoinInAssetList,
  isCoinInPortfolio,
  showWarning,
} from "../../../../utility/tradeHelper";
import StatusLoader from "../../StatusLoader";
import { LuRefreshCw } from "react-icons/lu";

function ExchangeTradeFirstStep({ setShow }) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      assetsLoading,
      assetsList,
      integratedDataFetched,
    },
    tradeState: {
      selectedExchange,
      tradeType,
      selectedAsset,
      tradePortfolio,
      tradePortfolioLoading,
      modalMode,
      basicTradeData,
    },
    profile: { profileData },
    main: { userData },
  } = useSelector((state) => state);

  const [isAssetAvailable, setIsAssetAvailable] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [needToRefreshIntegration, setNeedToRefreshIntegration] =
    useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const activeBotExchange = profileData?.bot_active_exchange;

  const dispatch = useDispatch();

  const exchangeList = [...integratedData]
    .filter((item) => item?.status === "Active")
    .sort((a, b) => (b?.id === "cryptocrispy" ? 1 : -1));

  const updateIntegration = async () => {
    if (integratedDataLoading) return;
    await dispatch(
      getIntegratedExchanges(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
      ),
    ).then(() => {
      setNeedToRefreshIntegration(false);
    });
  };

  // HANDLE SELECTING EXCHANGE ACTION
  const selectExchangeHandler = (exchange) => {
    if (assetsLoading || tradePortfolioLoading) return;

    setIsFirstTime(true);
    dispatch(setExchange(exchange));
    if (tradeType) {
      dispatch(setTradeType(""));
    }
  };

  // HANDLE SELECT TRADE TYPE ACTION
  const handleTradeType = (type) => {
    if (!selectedExchange) return;
    if (tradePortfolioLoading)
      return message.info("Please wait, data is loading...");
    dispatch(setTradeType(type));
  };

  // GET INTEGRATED EXCHANGES IF THEY DON'T FETCH YET
  useEffect(() => {
    if (!getIntegratedList && userData?.email && !integratedDataLoading) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [getIntegratedList, userData, dispatch, integratedDataLoading]);

  // GET ASSET LIST AND PORTFOLIO WHENEVER THE SELECTED EXCHANGE CHANGE
  useEffect(() => {
    if (selectedExchange) {
      dispatch(
        getAssetList(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
        ),
      );
      dispatch(
        getTradePortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
          "False",
        ),
      );
    }
  }, [dispatch, selectedExchange, userData]);

  useEffect(() => {
    if (!assetsLoading && assetsList.length > 0) {
      const isAvailable = !!isCoinInAssetList(
        assetsList,
        selectedAsset,
        selectedExchange,
      );
      setIsAssetAvailable(isAvailable);
    }
  }, [assetsList, assetsLoading]);

  useEffect(() => {
    if (modalMode === "ai-suggestion") {
      if (tradePortfolio.length > 0 && !tradePortfolioLoading && !tradeType) {
        const { tradeType } = basicTradeData;
        dispatch(setTradeType(tradeType.toLowerCase()));
      }
    }
  }, [
    basicTradeData,
    dispatch,
    modalMode,
    tradePortfolio,
    tradePortfolioLoading,
    tradeType,
  ]);

  useEffect(() => {
    if (modalMode === "ai-table") {
      if (
        tradePortfolio.length > 0 &&
        !tradePortfolioLoading &&
        !tradeType &&
        basicTradeData.tradeType
      ) {
        const { tradeType } = basicTradeData;
        dispatch(setTradeType(tradeType.toLowerCase()));
      }
    }
  }, [
    basicTradeData,
    dispatch,
    modalMode,
    tradePortfolio,
    tradePortfolioLoading,
    tradeType,
  ]);

  useEffect(() => {
    if (selectedExchange && tradeType && !tradePortfolioLoading) {
      if (tradeType === "buy") {
        if (checkBudgetIsEnough(tradePortfolio)) {
          setIsDone(true);
        } else {
          setIsDone(false);
        }
      } else {
        if (
          isCoinInPortfolio(tradePortfolio, selectedAsset, selectedExchange)
        ) {
          setIsDone(true);
        } else {
          setIsDone(false);
        }
      }
    }
  }, [selectedExchange, tradePortfolio, tradePortfolioLoading, tradeType]);

  useEffect(() => {
    if (tradePortfolio && tradePortfolio.length && !tradePortfolioLoading) {
      const asset = tradePortfolio.find((item) => item.assetName === "USDT");
      if (asset && parseFloat(asset?.availableBalanceUSD) > 0) {
        // SAVE USDT AMOUNT IN THE TRADE STATE
        dispatch(setUsdtAmount(asset.availableBalanceUSD));
      }
    }
  }, [dispatch, tradePortfolio, tradePortfolioLoading]);

  useEffect(() => {
    if (
      !tradePortfolioLoading &&
      isCoinInPortfolio(tradePortfolio, selectedAsset, selectedExchange)
    ) {
      const asset =
        selectedExchange === "swyftx" || selectedExchange === "cryptocrispy"
          ? selectedAsset.replace("/USDT", "")
          : selectedAsset;
      const assetInfo = tradePortfolio.find((item) => item.assetName === asset);
      if (assetInfo) {
        dispatch(setAssetAmount(assetInfo.availableBalance));
        // dispatch(setAssetAmountUsd(assetInfo.availableBalanceUSD));
      }
    }
  }, [dispatch, selectedAsset, tradePortfolio, tradePortfolioLoading]);

  useEffect(() => {
    if (getIntegratedList && integratedData.length > 0) {
      const firstActiveExchange = integratedData.find(
        (item) => item.status === "Active",
      );
      dispatch(setExchange(firstActiveExchange.id));
    }
  }, [integratedData, getIntegratedList]);

  return (
    <div>
      <h3 className={styles.title}>
        <AiFillCaretRight />
        Select the exchange that you want to order trade with that.
      </h3>
      {integratedDataLoading ? (
        <Loader text={"Fetching integrated exchange list"} />
      ) : integratedData.length > 0 ? (
        <div className={styles.steps}>
          <ul className={styles.exchangeList}>
            {exchangeList.map((item, i) => (
              <li
                key={item.id}
                className={`${styles.exchangeItem} ${
                  selectedExchange === item.id ? styles.selected : ""
                } ${
                  selectedExchange !== item.id &&
                  (tradePortfolioLoading || assetsLoading)
                    ? styles.inactive
                    : ""
                } ${item.id === "cryptocrispy" ? styles.virtual : ""}`}
                onClick={() => selectExchangeHandler(item.id)}
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                  alt={item.name}
                />
                <span className={styles.exchangeName}>{item.name}</span>
              </li>
            ))}
          </ul>

          {assetsLoading ? (
            <Loader text={"Please wait"} />
          ) : isAssetAvailable ? (
            <div
              className={`${styles.tradeTypeBox} ${
                !selectedExchange ? styles.inactive : ""
              }`}
            >
              <h3 className={styles.title}>
                <AiFillCaretRight />
                What type of trade do you want to do?
              </h3>
              <ul className={styles.tradeTypes}>
                <li
                  className={`${styles.tradeType} ${
                    tradeType === "buy" ? styles.selected : ""
                  }`}
                  style={!selectedExchange ? { cursor: "default" } : undefined}
                  onClick={() => handleTradeType("buy")}
                >
                  Buy
                </li>
                <li
                  className={`${styles.tradeType} ${
                    tradeType === "sell" ? styles.selected : ""
                  }`}
                  style={!selectedExchange ? { cursor: "default" } : undefined}
                  onClick={() => handleTradeType("sell")}
                >
                  Sell
                </li>
              </ul>
            </div>
          ) : (
            <div className={styles.noUsdtBox}>
              <p className={styles.noUsdtMsg}>
                <IoIosWarning />
                Sorry this exchange does not support{" "}
                {selectedAsset.replace("/USDT", "")}
              </p>
              <span className={styles.closeBtn} onClick={() => setShow()}>
                Close
              </span>
            </div>
          )}

          {tradeType === "sell" &&
            !isCoinInPortfolio(
              tradePortfolio,
              selectedAsset,
              selectedExchange,
            ) && (
              <div className={styles.noUsdtBox}>
                <p className={styles.noUsdtMsg}>
                  <IoIosWarning />
                  Sorry, you don't have any {selectedAsset.replace(
                    "/USDT",
                    "",
                  )}{" "}
                  in your{" "}
                  {
                    integratedData.find((item) => item.id === selectedExchange)
                      .name
                  }{" "}
                  portfolio!
                </p>
                <span className={styles.closeBtn} onClick={() => setShow()}>
                  Close
                </span>
              </div>
            )}

          {!tradePortfolioLoading &&
          !checkBudgetIsEnough(tradePortfolio) &&
          tradeType === "buy" ? (
            <div className={styles.noUsdtBox}>
              <p className={styles.noUsdtMsg}>
                <IoIosWarning />
                Sorry you didn't have enough USDT in your portfolio!
              </p>
              <span className={styles.closeBtn} onClick={() => setShow()}>
                Close
              </span>
            </div>
          ) : (
            ""
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {tradePortfolioLoading && (
              <StatusLoader text={"Fetching portfolio data"} />
            )}
            <span
              className={`${styles.nextBtn} ${!isDone ? styles.inactive : ""}`}
              style={{ alignSelf: "flex-end", marginLeft: "auto" }}
              onClick={() => {
                if (tradePortfolioLoading) {
                  return message.info("Please wait, data loading...");
                }
                if (isDone) {
                  if (activeBotExchange === selectedExchange && isFirstTime) {
                    showWarning();
                    setIsFirstTime(false);
                  } else {
                    dispatch(setStep("2"));
                  }
                }
              }}
            >
              Next
            </span>
          </div>
        </div>
      ) : (
        <p>
          You didn't integrate with any exchange.
          {needToRefreshIntegration && (
            <>
              <br />
              <Button
                type={"primary"}
                onClick={updateIntegration}
                loading={integratedDataLoading}
              >
                Refresh <LuRefreshCw />
              </Button>
              <br />
            </>
          )}
          <Link
            className={styles.integrationLink}
            onClick={() => setNeedToRefreshIntegration(true)}
            to={"/integration"}
            target={"_blank"}
          >
            Integrate now!
          </Link>
        </p>
      )}
    </div>
  );
}

export default ExchangeTradeFirstStep;

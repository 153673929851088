import { Table } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getBotActivity, setSelectedBot } from "../../../redux/bot/actions";
import { formatCustomDate } from "../../../utility/helper";
import BotActivityTableExpandedRow from "./BotActivityTableExpandedRow";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { CgMenuRound } from "react-icons/cg";

function BotOverallActivityTable() {
  const {
    main: { userData },
    botState: {
      getBotActivityLoading,
      botActivityData,
      getBotActivityError,
      botActivityFetched,
      selectedBot,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
      dispatch(setSelectedBot(keys.length > 0 ? botActivityData[keys[0]] : {}));
    },
  };

  const onRowClick = (record) => {
    if (expandedRowKeys.includes(record.key)) {
      return setExpandedRowKeys([]);
    }
    setExpandedRowKeys([record.key]);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 8,
      align: "center",
      responsive: ["lg"],
      render: (index) => <span>{index + 1}</span>,
    },
    {
      title: "Bot Name",
      dataIndex: "name",
      key: "name",
      width: 20,
      align: "center",
      render: (name) => <span>{name}</span>,
    },
    // {
    //   title: "Request time",
    //   dataIndex: "date",
    //   key: "date",
    //   width: 15,
    //   align: "center",
    //   responsive: ["md"],
    //   render: (date) => <span>{formatCustomDate(date, "yes")}</span>,
    // },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 20,
      align: "center",
      responsive: ["sm"],
      render: (date) => <span>{date ? formatCustomDate(date) : "-"}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 20,
      align: "center",
      responsive: ["md"],
      render: (date) => <span>{date ? formatCustomDate(date) : "-"}</span>,
    },
    {
      title: "Net Value Profit (%)",
      dataIndex: "netValue",
      key: "netValue",
      width: 20,
      align: "center",
      render: (value) => {
        // const value =
        //   result?.Total &&
        //   ((result?.Total?.value / Number(budget) - 1) * 100).toFixed(2);
        return (
          <span
            style={
              value ? { color: `${Number(value) > 0 ? "green" : ""}` } : {}
            }
          >
            {value === "TBA" || value === "0" ? value : value + "%"}
          </span>
        );
      },
    },
    {
      title: "Win Rate",
      dataIndex: "winRate",
      key: "winRate",
      width: 20,
      align: "center",
      render: (value) => {
        return (
          <span
            style={
              value ? { color: `${Number(value) > 0 ? "green" : ""}` } : {}
            }
          >
            {value === "TBA" || value === "0" ? value : value + "%"}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 12,
      align: "center",
      responsive: ["md"],
      render: (status) => (
        <span style={{ color: `${status === "Active" ? "green" : "red"}` }}>
          {status === "Active" ? status : "Inactive"}
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (botActivityData.length) {
      setTableData(
        botActivityData.map((item, i) => {
          return {
            key: i,
            index: i,
            name: item?.trader,
            startDate: item?.start_date,
            endDate: item?.end_date,
            netValue: item?.profit_percent,
            status: item?.status,
            winRate: item?.win_rate,
            info: item,
          };
        }),
      );
    }
  }, [botActivityData]);

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !botActivityFetched &&
      !getBotActivityLoading &&
      !getBotActivityError
    ) {
      dispatch(
        getBotActivity(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    userData,
    botActivityFetched,
    getBotActivityLoading,
    getBotActivityError,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedBot && botActivityData.length) {
      const index = botActivityData.findIndex(
        (item) => item.trader_id === selectedBot.trader_id,
      );
      setSelectedRowKeys([index]);
    }
  }, [selectedBot, botActivityData]);

  return (
    <Table
      columns={columns}
      scroll={{ y: 700 }}
      rowSelection={{
        type: "radio",
        ...rowSelection,
      }}
      height={500}
      pagination={false}
      dataSource={tableData}
      className={`${styles.table} bot-table`}
      loading={getBotActivityLoading}
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
      expandable={{
        expandedRowRender: (record) => (
          <BotActivityTableExpandedRow data={record} />
        ),
        expandedRowKeys,
        expandIcon: ({ expanded, onExpand, record }) => {
          // return (
          //   <CgMenuRound
          //     size={20}
          //     className={styles.expandIcon}
          //     onClick={(e) => {
          //       onRowClick(record);
          //     }}
          //   />
          // );
          if (expanded) {
            return (
              <IoChevronUp
                className={styles.expandIcon}
                onClick={(e) => {
                  onRowClick(record);
                }}
              />
            );
          } else {
            return (
              <IoChevronDown
                className={styles.expandIcon}
                onClick={(e) => {
                  onRowClick(record);
                }}
              />
            );
          }
        },
      }}
    />
  );
}

export default BotOverallActivityTable;

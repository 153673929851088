import React, { useEffect, useState } from "react";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";
import { Button, message, Table } from "antd";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import {
  disableTrackSignal,
  getTrackedSignal,
} from "../../redux/alert-system/actions";

const TrackedSignals = ({ data, height }) => {
  const [tabletData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (text) => <span className={styles.tableContent}>{text}</span>,
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
      align: "center",

      render: (text) => <span className={styles.tableContent}>{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text) => <span className={styles.tableContent}>{text}</span>,
    },
  ];
  const dispatch = useDispatch();
  const {
    main: { userData },
    alertSystem: { TrackSignalLoading },
  } = useSelector((state) => state);
  const disableTrackSignalHandler = async () => {
    if (!selectedRows.length)
      return message.info("Please select a signal first.");

    await dispatch(
      disableTrackSignal(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedRows?.[0]?.feature_key,
      ),
    ).then(() => {
      setSelectedRows([]);
      dispatch(
        getTrackedSignal(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    });
  };

  useEffect(() => {
    setTableData(
      data?.map((item, index) => {
        return {
          key: index,
          category: item?.category,
          subcategory: item?.subcategory,
          name: item?.name,
          feature_key: item?.feature_key,
        };
      }),
    );
  }, [data]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className={styles.trackedSignalsWrapper}>
      <h3>Signals tracked by the alert system</h3>
      <p>
        Check the tracked signals & remove any of signal categories by disabling
        them
      </p>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        pagination={false}
        columns={columns}
        dataSource={tabletData}
        scroll={{ y: height }}
        className={styles.table}
      />

      <div className={styles.disabledTrackingBtn}>
        <Button
          onClick={disableTrackSignalHandler}
          style={{ opacity: `${selectedRows.length ? 1 : 0.5}` }}
        >
          {TrackSignalLoading ? (
            <ReactLoading type={"bars"} color="#fff" height={40} width={36} />
          ) : (
            "Disable tracking of signal"
          )}
        </Button>
      </div>
    </div>
  );
};

export default TrackedSignals;

import { Card, Checkbox, Modal, Rate } from "antd";
import rateIcon from "../asset/Icons/rate.gif";

// STYLES
import styles from "../styles/RatingModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../redux/profile/actions";
import { useState } from "react";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { notify } from "../utility/Toastify/toastify";
import { FaAngleLeft } from "react-icons/fa";

function RatingModal({ show, handleShow, page }) {
  const {
    main: { userData },
  } = useSelector((state) => state);
  const [step, setStep] = useState(1);
  const [rate, setRate] = useState(0);
  const [reasons, setReasons] = useState([]);
  const dispatch = useDispatch();

  const aiReason = {
    notSatisfy: [
      "Inaccurate or irrelevant suggestions",
      "Unhelpful virtual portfolio",
      "Hard to use or understand",
      "Boring or annoying",
    ],
    notSure: [
      "Some useful suggestions, but not all",
      "Somewhat helpful virtual portfolio, but not very",
      "Somewhat easy to use or understand, but not very",
      "Somewhat fun or engaging, but not very",
    ],
  };

  const insightReason = {
    notSatisfy: [
      "Unreliable or incomplete backtests results",
      "Vague or biased insights from different sources",
      "Static or confusing charts and historical data",
      "Outdated or irrelevant trends of crypto",
    ],
    notSure: [
      "Some reliable and comprehensive backtests results, but not all",
      "Some detailed and diverse insights from different sources, but not all",
      "Some interactive and informative charts and historical data, but not all",
      "Some current and relevant trends of crypto, but not all",
    ],
  };

  const renderCheckboxGroup = (reason, rateType) => {
    return (
      <Checkbox.Group
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "2rem 0 1rem",
        }}
        value={reasons}
        onChange={(values) => {
          setReasons(values);
        }}
      >
        {reason[rateType].map((r) => (
          <Checkbox
            style={{ marginLeft: "0", marginBottom: "1rem" }}
            key={r}
            value={r}
            children={<span>{r}</span>}
          />
        ))}
      </Checkbox.Group>
    );
  };

  const submitData = (rate, reason = "") => {
    return dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        [`${page}_rating_date`, `${page}_rate`, `${page}_rate_reason`],
        [new Date(), rate, reason],
      ),
    );
  };

  const handleRating = (value) => {
    setRate(value);
    if (value === 5) {
      submitData(value);
      handleShow(false);
      setRate(0);
      return;
    }
    setStep(2);
  };

  const submitHandler = () => {
    if (!reasons.length) {
      notify("Please Select a Valid Option", "warning");
      return;
    }

    const reason = reasons.join(", ");

    submitData(rate, reason);
    handleShow(false);
    setStep(1);
    setRate(0);
  };

  return (
    <Modal
      title="" // Set the title of the modal
      width={550}
      centered
      open={show} // Set the visibility of the modal based on the show prop
      footer={null} // Remove the default footer of the modal
      maskClosable={false} // Disable closing the modal by clicking outside
      onCancel={() => handleShow(false)}
    >
      <Card style={{ margin: "1rem" }}>
        <div className={styles.container}>
          {step === 1 && (
            <>
              <h3 style={{ textAlign: "center" }}>
                Rate your experience with our{" "}
                {page === "ai"
                  ? "AI Assistant"
                  : page === "insight"
                  ? "Insights"
                  : ""}
                . <img style={{ width: "25px" }} src={rateIcon} alt="rating" />
              </h3>
              <Rate value={rate} onChange={handleRating} />
            </>
          )}
          {step === 2 && (
            <div className={styles.stepContainer}>
              {page === "ai" && (
                <div>
                  <h3 style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {rate === 1 || rate === 2
                        ? "Why are you not satisfied with our AI Assistant?"
                        : (rate === 3 || rate === 4) &&
                          "Why are you not fully satisfied with our AI Assistant?"}
                    </span>
                    <HiOutlineEmojiSad
                      style={{ fontSize: "20px", marginLeft: ".2rem" }}
                    />
                  </h3>
                  <div>
                    {/* call the extracted function with different arguments */}
                    {(rate === 1 || rate === 2) &&
                      renderCheckboxGroup(aiReason, "notSatisfy")}
                    {(rate === 3 || rate === 4) &&
                      renderCheckboxGroup(aiReason, "notSure")}
                  </div>
                </div>
              )}
              {page === "insight" && (
                <div>
                  <h3 style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {rate === 1 || rate === 2
                        ? "Why are you not satisfied with our Insights?"
                        : (rate === 3 || rate === 4) &&
                          "Why are you not fully satisfied with our Insights?"}
                    </span>
                    <HiOutlineEmojiSad
                      style={{ fontSize: "20px", marginLeft: ".2rem" }}
                    />
                  </h3>
                  <div>
                    {/* call the extracted function with different arguments */}
                    {(rate === 1 || rate === 2) &&
                      renderCheckboxGroup(insightReason, "notSatisfy")}
                    {(rate === 3 || rate === 4) &&
                      renderCheckboxGroup(insightReason, "notSure")}
                  </div>
                </div>
              )}

              <div className={styles.btns}>
                <span className={styles.backBtn} onClick={() => setStep(1)}>
                  Back
                </span>

                <span className={styles.submitButton} onClick={submitHandler}>
                  Submit
                </span>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
}

export default RatingModal;

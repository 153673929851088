import ServiceActivationModal from "../../shared/service-activation/ServiceActivationModal";
import React from "react";

function ActivateBotServiceModal({
  showActivationModal,
  setShowActivationModal,
}) {
  return (
    <ServiceActivationModal
      service={"mybot"}
      show={showActivationModal}
      setShow={setShowActivationModal}
    />
  );
}

export default ActivateBotServiceModal;

import React, { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { notify } from "../../utility/Toastify/toastify";
import axios from "axios";
import { useNavigate } from "react-router";
import { setToken, setUserData } from "../../redux/main/action/main";
import { useDispatch } from "react-redux";
import AuthAlert from "./AuthAlert";

const clientId =
  "225357808354-8efg7jlf2sina9grvsj571mfnhhh1pms.apps.googleusercontent.com";

const GoogleLoginButton = ({
  setLoading,
  customStyle = {},
  customClassName,
  navigator,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const responseGoogle = async (response) => {
    // Handle the Google login response
    setLoading(true);
    await axios
      .post(
        "https://vtq2y0v4ed.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        { mode: "signin", accessToken: response.credential },
      )
      .then((res) => {
        dispatch(setToken(res?.data.idToken?.jwtToken));
        dispatch(setUserData(res?.data.idToken?.payload));
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res?.data.idToken?.jwtToken}`;
        localStorage.setItem(
          "aws-amplify-federatedInfo",
          JSON.stringify({ token: res?.data?.idToken?.jwtToken }),
        );
        localStorage.setItem(
          "aws-amplify-USERDATA",
          JSON.stringify(res?.data?.idToken?.payload),
        );
        // window.location.reload();
        // setLoading(false);
        notify(res.data.message, "success");
        if (navigator) {
          navigator();
          return;
        }
        navigate("/welcome");
      })
      .catch((err) => {
        // notify(err.response.data.message, "error");
        const { data, status } = err.response;
        // notify(err.response.data.message, "error");
        setShowAlert(true);
        setErrorMsg(data.message);
        setErrorCode(status);
      });

    setLoading(false);
  };

  const handleError = (res) => {
    notify(res.details, "error");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        ...customStyle,
      }}
      className={customClassName || ""}
    >
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          buttonText="Login with Google"
          onSuccess={(response) => responseGoogle(response)}
          onFailure={(res) => handleError(res)}
          cookiePolicy={"single_host_origin"}
        />
        <AuthAlert
          show={showAlert}
          setShow={setShowAlert}
          code={errorCode}
          msg={errorMsg}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginButton;

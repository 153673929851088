import React from "react";

function NotEnoughAssetError() {
  return (
    <p style={{ margin: "1rem 0", color: "navy", textAlign: "center" }}>
      Sorry, you can't do the {/*sell*/} trade because the amount of asset is
      less than $1. This is the minimum amount required to execute a {/*sell*/}{" "}
      order on Cryptocrispy.
    </p>
  );
}

export default NotEnoughAssetError;

import React, { useEffect, useState } from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import {
  setAssetAmountUsd,
  setSecondTradeType,
  setStep,
  setTargetPrice,
  setTradeAmount,
  setTradeAmountUsd,
  setTriggerPrice,
} from "../../../redux/trade/actions";
import { FiChevronLeft, FiInfo } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCaretRight } from "react-icons/ai";
import { message, Tabs, Tooltip } from "antd";
import { getExchangePrice, orderTrade } from "../../../redux/exchange/actions";
import {
  formatAmount,
  formatAssetPrice,
  formatPrice,
} from "../../../utility/helper";
import InstantOrderAmountSetting from "./step-components/InstantOrderAmountSetting";
import LimitOrderPriceSetting from "./step-components/LimitOrderPriceSetting";
import LimitCheckbox from "./step-components/LimitCheckbox";
import SetLimitConfirmation from "./step-components/SetLimitConfirmation";
import NotEnoughAssetError from "../NotEnoughAssetError";

// const sellOptions = ["Market sell", "Stop loss", "Take profit"];

const sellOptions = [
  {
    title: "Market sell",
    key: "sell",
    info: "sell at the current market price.",
  },
  {
    title: "Stop loss",
    key: "sell_sl",
    info: "sell at a lower price than the current market price.",
  },
  {
    title: "Take profit",
    key: "sell_tp",
    info: "sell at a higher price than the current market price.",
  },
];

const buyOptions = [
  {
    title: "Market buy",
    key: "buy",
    info: "buy at the current market price.",
  },
  {
    title: "Discount buy",
    key: "buy_db",
    info: "buy at a lower price than the current market price.",
  },
  {
    title: "Break out buy",
    key: "buy_bb",
    info: "buy at a higher price than the current market price.",
  },
];

const { TabPane } = Tabs;

function Step2({ step, setShow }) {
  const {
    tradeState: {
      tradeType,
      secondTradeType,
      selectedAsset,
      selectedExchange,
      tradeAmount,
      usdtAmount,
      tradeAmountUsd,
      assetAmount,
      triggerPrice,
      modalMode,
      buyLimit,
      sellLimit,
    },
    exchangeState: { priceData, priceLoading },
    main: { userData },
  } = useSelector((state) => state);

  const [isDone, setIsDone] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [triggerPriceError, setTriggerPriceError] = useState(false);
  const [usdAmount, setUsdAmount] = useState("");
  const [usdAmountError, setUsdAmountError] = useState(false);
  const [activeTab, setActiveTab] = useState("instant");
  const [showLimitConfirmationModal, setShowLimitConfirmation] =
    useState(false);

  const dispatch = useDispatch();

  const changeTabHandler = (key) => {
    setActiveTab(key);

    if (priceLoading) {
      message.info("Please wait to fetching price data.");
      return;
    }

    if (key === "instant") {
      if (tradeType === "sell") {
        dispatch(setSecondTradeType("sell"));
      } else {
        dispatch(setSecondTradeType("buy"));
      }
    }

    if (key === "limit") {
      if (tradeType === "sell") {
        dispatch(setSecondTradeType("sell_sl"));
      } else {
        dispatch(setSecondTradeType("buy_db"));
      }
    }
  };

  // HANDLE SET TRADE
  const setTradeHandler = () => {
    if (!isDone) return;
    if (priceLoading) return;

    if (
      ((secondTradeType === "sell_sl" || secondTradeType === "sell_tp") &&
        sellLimit) ||
      ((secondTradeType === "buy_db" || secondTradeType === "buy_bb") &&
        buyLimit)
    ) {
      setShowLimitConfirmation(true);
      return;
    }

    // const amount = selectedExchange === "swyftx" ? tradeAmountUsd : tradeAmount;
    const asset =
      selectedExchange === "swyftx"
        ? selectedAsset.split("/")[0]
        : selectedAsset;

    // dispatch(setTradeAmountUsd(usdAmount));

    dispatch(
      orderTrade(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        asset,
        secondTradeType,
        `${tradeAmount}`,
        triggerPrice,
      ),
    );
    dispatch(setTargetPrice(+triggerPrice));
    dispatch(setStep("result"));
  };

  const goBackHandler = () => {
    if (modalMode === "integ-btn") {
      dispatch(setStep("1-a"));
    }
    if (modalMode === "integ-table") {
      dispatch(setStep("1-b"));
    }
    if (modalMode === "ai-btn") {
      dispatch(setStep("1-c"));
    }
    if (modalMode === "ai-table") {
      dispatch(setStep("1-d"));
    }
    if (modalMode === "ai-suggestion") {
      dispatch(setStep("1-d"));
    }
  };

  const setSecondTradeTypeHandler = (value) => {
    if (priceLoading) {
      message.info("Please wait to load the asset price.");
      return;
    }
    if (!priceData[selectedAsset.split("/")[0]]) return;
    dispatch(setSecondTradeType(value));
  };

  // HANDLE TRIGGER PRICE VALIDATION
  const triggerPriceValidation = (amount) => {
    if (secondTradeType === "sell_sl" || secondTradeType === "buy_db") {
      if (Number(amount) === 0 && amount !== "") return false;
      return Number(amount) < Number(priceData[selectedAsset.split("/")[0]]);
    }
    if (secondTradeType === "sell_tp" || secondTradeType === "buy_bb") {
      return Number(amount) > Number(priceData[selectedAsset.split("/")[0]]);
    }
  };

  // HANDLE AMOUNT VALIDATION
  const amountValidation = (amount) => {
    if (secondTradeType.includes("sell")) {
      // if (Number(amount) === 0 && amount !== "") return false;
      return Number(amount) <= Number(assetAmount);
    }
    if (secondTradeType.includes("buy")) {
      // if (Number(amount) === 0 && amount !== "") return false;
      return (
        Number(amount) * Number(priceData[selectedAsset.split("/")[0]]) <=
        Number(usdtAmount)
      );
    }
  };

  useEffect(() => {
    setAmountError(!amountValidation(tradeAmount));
  }, [amountValidation, tradeAmount]);

  useEffect(() => {
    if (!priceLoading && priceData[selectedAsset.split("/")[0]]) {
      dispatch(
        setAssetAmountUsd(assetAmount * priceData[selectedAsset.split("/")[0]]),
      );
    }
  }, [assetAmount, dispatch, priceData, priceLoading, selectedAsset]);

  useEffect(() => {
    // console.log(priceLoading, priceData[selectedAsset.split("/")[0]]);
    if (!priceLoading && priceData[selectedAsset.split("/")[0]]) {
      // console.log("setting");
      if (activeTab === "instant") {
        if (tradeType === "sell") {
          dispatch(setSecondTradeType("sell"));
        } else {
          dispatch(setSecondTradeType("buy"));
        }
      }

      if (activeTab === "limit") {
        if (tradeType === "sell") {
          dispatch(setSecondTradeType("sell_sl"));
        } else {
          dispatch(setSecondTradeType("buy_db"));
        }
      }
    }
  }, [activeTab, dispatch, priceData, priceLoading, selectedAsset, tradeType]);
  // useEffect(() => {
  //   if (secondTradeType) {
  //     dispatch(setTriggerPrice(""));
  //     setTriggerPriceError(false);
  //   }
  // }, [secondTradeType]);

  // GET ASSET PRICE
  useEffect(() => {
    if (selectedAsset && !priceData[selectedAsset.split("/")[0]]) {
      dispatch(
        getExchangePrice(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
          [selectedAsset.split("/")[0]],
        ),
      );
    }
    // Fetch price data every minute
    const intervalId = setInterval(() => {
      if (selectedAsset) {
        dispatch(
          getExchangePrice(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedExchange,
            [selectedAsset.replace("/USDT", "")],
          ),
        );
      }
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [priceData, selectedAsset, userData, dispatch, selectedExchange]);

  useEffect(() => {
    if (secondTradeType !== "buy" && secondTradeType !== "sell") {
      if (
        tradeAmount &&
        amountValidation(tradeAmount) &&
        triggerPrice &&
        triggerPriceValidation(triggerPrice)
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    } else {
      if (tradeAmount && amountValidation(tradeAmount)) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    }
  }, [
    triggerPrice,
    tradeAmount,
    amountValidation,
    triggerPriceValidation,
    secondTradeType,
  ]);

  useEffect(() => {
    // setUsdAmount("");
    // dispatch(setTradeAmount(""));
    // dispatch(setTradeAmountUsd(""));
    setUsdAmountError(false);
    setTriggerPriceError(false);
  }, [activeTab, dispatch]);

  return (
    <div className={`${step === "2" ? styles.active : ""} ${styles.step}`}>
      <span className={styles.backBtn} onClick={goBackHandler}>
        <FiChevronLeft />
        Back
      </span>
      <div className={styles.assetInfo}>
        <div>
          <h4>Asset Name:</h4> <p>{selectedAsset.replace("/USDT", "")}</p>
        </div>
        <div>
          <h4>Current Price:</h4>{" "}
          {priceLoading ? (
            <p>Loading...</p>
          ) : (
            <p>$ {formatAssetPrice(priceData[selectedAsset.split("/")[0]])}</p>
          )}
        </div>
        {tradeType === "sell" && (
          <div>
            <h4>Native Amount:</h4> <p>{formatAmount(assetAmount)}</p>
          </div>
        )}
        {tradeType === "buy" && (
          <div>
            <h4>Budget (USDT):</h4> <p>{formatPrice(+usdtAmount)}</p>
          </div>
        )}
      </div>
      <div className={styles.secondTradeTypeBox}>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Which type of {tradeType} trade do you want to do today?
        </h3>
        <Tabs
          activeKey={activeTab}
          onChange={changeTabHandler}
          style={{ padding: "1rem" }}
        >
          <TabPane tab="Instant Order" key="instant">
            {tradeType === "sell" && (
              <ul className={styles.secondTradeOptions}>
                {sellOptions[0] && (
                  <li
                    className={`${styles.secondTradeOption} ${
                      secondTradeType === sellOptions[0].key
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() =>
                      setSecondTradeTypeHandler(sellOptions[0].key)
                    }
                  >
                    {sellOptions[0].title}
                    <Tooltip
                      title={sellOptions[0].info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                )}
              </ul>
            )}
            {tradeType === "buy" && (
              <ul className={styles.secondTradeOptions}>
                {buyOptions[0] && (
                  <li
                    className={`${styles.secondTradeOption} ${
                      secondTradeType === buyOptions[0].key
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(buyOptions[0].key)}
                  >
                    {buyOptions[0].title}
                    <Tooltip
                      title={buyOptions[0].info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                )}
              </ul>
            )}
          </TabPane>
          <TabPane tab="Limit Order" key="limit">
            {tradeType === "sell" && (
              <ul className={styles.secondTradeOptions}>
                {sellOptions.slice(1).map((opt) => (
                  <li
                    key={opt.key}
                    className={`${styles.secondTradeOption} ${
                      secondTradeType === opt.key ? styles.selected : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(opt.key)}
                  >
                    {opt.title}
                    <Tooltip
                      title={opt.info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                ))}
              </ul>
            )}

            {tradeType === "buy" && (
              <ul className={styles.secondTradeOptions}>
                {buyOptions.slice(1).map((opt) => (
                  <li
                    key={opt.key}
                    className={`${styles.secondTradeOption} ${
                      secondTradeType === opt.key ? styles.selected : ""
                    }`}
                    onClick={() => setSecondTradeTypeHandler(opt.key)}
                  >
                    {opt.title}
                    <Tooltip
                      title={opt.info}
                      color={"geekblue"}
                      placement={"bottom"}
                    >
                      <FiInfo />
                    </Tooltip>
                  </li>
                ))}
              </ul>
            )}
          </TabPane>
        </Tabs>
      </div>
      {Object.keys(priceData).length > 0 &&
      tradeType === "sell" &&
      assetAmount * priceData[selectedAsset.split("/")[0]] < 1 ? (
        <NotEnoughAssetError />
      ) : (
        <>
          <div className={`${!secondTradeType ? styles.inactive : ""}`}>
            <h3 className={styles.title}>
              <AiFillCaretRight />
              Enter the amount you want to trade.
            </h3>
            <InstantOrderAmountSetting setIsDone={setIsDone} />
            {secondTradeType !== "sell" &&
              secondTradeType !== "buy" &&
              secondTradeType && (
                <div>
                  <h3 className={styles.title}>
                    <AiFillCaretRight />
                    Enter the trigger price you want to set for your limit
                    order.
                  </h3>
                  <LimitOrderPriceSetting
                    type={
                      secondTradeType === "sell_tp" ||
                      secondTradeType === "buy_bb"
                        ? "high"
                        : "low"
                    }
                    setIsDone={setIsDone}
                  />
                </div>
              )}
          </div>
          {activeTab === "limit" && <LimitCheckbox />}
          <div className={styles.setOrderBtn}>
            <span
              className={`${styles.nextBtn} ${isDone ? "" : styles.inactive}`}
              onClick={setTradeHandler}
            >
              Place Order
            </span>
          </div>
        </>
      )}

      <SetLimitConfirmation
        show={showLimitConfirmationModal}
        setShow={setShowLimitConfirmation}
      />
    </div>
  );
}

export default Step2;

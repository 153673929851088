import { Input } from "antd";
import React from "react";
import StepTitle from "./StepTitle";
import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";

function CreateBitStep1({ botData, changeBotData }) {
  return (
    <>
      <StepTitle title={"Name of your CryptoCrispy Bot"} />
      <div className={styles.stepContent}>
        <Input
          value={botData.bot_name}
          style={{
            borderRadius: ".3rem",
            maxWidth: "300px",
          }}
          onChange={(e) => changeBotData("bot_name", e.target.value)}
        />
      </div>
    </>
  );
}

export default CreateBitStep1;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../redux/dashboard/action";

const countries = [
  "Australia",
  "Belgium",
  "Brazil",
  "Canada",
  "China",
  "Denmark",
  "Finland",
  "France",
  "Germany",
  "India",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Poland",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];

const options = countries.map((country) => ({
  label: country,
  value: country[0].toLowerCase() + country.slice(1),
}));

const CountryList = () => {
  const { userData } = useSelector((state) => state?.main);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption.label);
    localStorage.setItem("country", selectedOption.label);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (selectedCountry) {
      let localToken = JSON.parse(
        localStorage.getItem("aws-amplify-federatedInfo")
      )?.token;
      if (
        userData?.email &&
        localToken &&
        userData?.["custom:custom_username"]
      ) {
        dispatch(
          getDashboardData(
            userData?.email,
            userData?.["custom:custom_username"],
            selectedCountry
          )
        );
      }
    }
  }, [selectedCountry]);

  return (
    <div
      style={{
        position: "absolute",
        top: "30px",
        right: "30px",
        zIndex: 1000,
        cursor: "pointer",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <GiEarthAsiaOceania size="1.8em" color="gray" />
        <span style={{ color: "gray", marginLeft: ".5rem" }}>
          {localStorage.getItem("country")
            ? localStorage.getItem("country")
            : "Australia"}
        </span>
      </div>
      <div
        style={{
          width: "160px",
          position: "absolute",
          top: "30px",
          right: 0,
          opacity: `${showDropdown ? 1 : 0}`,
          transition: ".4s all",
        }}
      >
        <Select
          value={selectedCountry}
          onChange={handleChange}
          options={options}
          placeholder="Search country"
        />
      </div>
    </div>
  );
};

export default CountryList;

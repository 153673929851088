import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { handleBlur, handleShowPass } from "../../utility/action";
import {
  ForgotPasswordStep1Validation,
  ForgotPasswordStep2Validation,
} from "../../utility/Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utility/Toastify/toastify";
import PoolData from "./AWSConfig/PoolData";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import "../../styles/pages/Auth/auth.css";
import { checkEmailException } from "../../utility/helper";
import Logo from "../../shared/Logo";
import Text from "../../components/auth/Text";
import axios from "axios";
import AuthAlert from "./AuthAlert";
import ConfirmAlert from "./ConfirmAlert";

function SignUpPage() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      stage === 1
        ? ForgotPasswordStep1Validation
        : ForgotPasswordStep2Validation,
    ),
  });

  const onsubmit = (data) => {
    setLoading(true);
    axios
      .post(
        "https://vtq2y0v4ed.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        { mode: "check_status", email: checkEmailException(data?.email) },
      )
      .then((res) => {
        if (res.status === 201) {
          const getUser = () => {
            return new CognitoUser({
              Username: checkEmailException(data?.email),
              Pool: PoolData,
            });
          };

          if (data?.confirmationCode) {
            getUser().confirmPassword(data?.confirmationCode, data?.pass, {
              onSuccess: (data) => {
                notify(
                  "Your password has been successfully changed",
                  "success",
                );
                navigate("/auth/login");
                setLoading(false);
              },
              onFailure: (err) => {
                notify(err?.message, "error");
                setLoading(false);
              },
            });
          } else {
            getUser().forgotPassword({
              onSuccess: (data) => {
                notify("verification code sent", "success");
                setLoading(false);
              },
              onFailure: (err) => {
                notify(err?.message, "error");
                setLoading(false);
              },
              inputVerificationCode: (data) => {
                notify(data, "success");
                setStage(2);
                setLoading(false);
              },
            });
          }
          return;
        }

        if (res.status === 203) {
          setLoading(false);
          setUserEmail(checkEmailException(data?.email));
          setErrorMsg(res.data.message);
          setShowConfirmAlert(true);
          return;
        }
        setLoading(false);
        setShowAlert(true);
        setErrorCode(res.status);
        setErrorMsg(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setShowAlert(true);
        setErrorCode(err.response.status);
        setErrorMsg(err.response.data.message);
      });
  };

  useEffect(() => {
    if (stage === 2) {
      document.querySelector('input[name="confirmationCode"]').value = "";
    }
  }, [stage]);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="content">
          <Text />
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onsubmit)}
              autoComplete="off"
            >
              {stage === 1 && (
                <>
                  <h4 className="form-title">
                    Enter your email address to reset your password.
                  </h4>
                  <div
                    className={`wrap-input100 validate-input ${
                      errors.email && "border-error"
                    }`}
                  >
                    <input
                      className="input100"
                      type="text"
                      name="email"
                      {...register("email")}
                      onBlur={(e) => handleBlur(e)}
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="Email"
                    ></span>
                  </div>
                  {errors.email && (
                    <span className="error-text">{errors.email.message}</span>
                  )}
                  <Button
                    loading={loading}
                    ButtonText="Send Code"
                    className="signup"
                  />
                </>
              )}

              {stage === 2 && (
                <>
                  <div
                    className={`wrap-input100 validate-input ${
                      errors.confirmationCode && "border-error"
                    }`}
                  >
                    <input
                      className="input100"
                      type="text"
                      name="confirmationCode"
                      autoComplete="off"
                      autoFocus
                      {...register("confirmationCode")}
                      onBlur={(e) => handleBlur(e)}
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="Confirmation code"
                    ></span>
                  </div>
                  {errors.confirmationCode && (
                    <span className="error-text">
                      {errors.confirmationCode.message}
                    </span>
                  )}
                  <div
                    className={`wrap-input100 validate-input ${
                      errors.pass && "border-error"
                    }`}
                  >
                    <span className="btn-show-pass">
                      <i
                        className="far fa-eye"
                        onClick={(e) => handleShowPass(e)}
                      ></i>
                    </span>
                    <input
                      className="input100"
                      type="password"
                      {...register("pass")}
                      autoComplete="new-password"
                      onBlur={(e) => handleBlur(e)}
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="Password"
                    ></span>
                  </div>
                  {errors.pass && (
                    <span className="error-text">{errors.pass.message}</span>
                  )}

                  <div
                    className={`wrap-input100 validate-input ${
                      errors.confirmPass && "border-error"
                    }`}
                  >
                    <span className="btn-show-pass">
                      <i
                        className="far fa-eye"
                        onClick={(e) => handleShowPass(e)}
                      ></i>
                    </span>
                    <input
                      className="input100"
                      type="password"
                      {...register("confirmPass")}
                      autoComplete="off"
                      onBlur={(e) => handleBlur(e)}
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="Confirm password"
                    ></span>
                  </div>
                  {errors.confirmPass && (
                    <span className="error-text">
                      {errors.confirmPass.message}
                    </span>
                  )}
                  <Button
                    ButtonText="Reset"
                    className="signup"
                    loading={loading}
                  />
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <AuthAlert
        show={showAlert}
        setShow={setShowAlert}
        code={errorCode}
        msg={errorMsg}
        mode={errorCode === 202}
      />
      <ConfirmAlert
        show={showConfirmAlert}
        setShow={setShowConfirmAlert}
        email={userEmail}
        msg={errorMsg}
        mode="changePass"
      />
    </div>
  );
}

export default SignUpPage;

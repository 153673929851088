import styles from "../../../../styles/Components/bot/bot-backtest/BacktestReport.module.css";
import { riskAppetiteColor, riskOptions } from "../../../../utility/data";
import { formatCustomDate } from "../../../../utility/helper";
import { useSelector } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";

function BotPerformanceSummaryCard({ data }) {
  const {
    botState: { selectedBot, botReportData },
  } = useSelector((state) => state);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "2rem",
        flexDirection: "column",
      }}
    >
      <div className={styles.summaryCard}>
        <div className={styles.cardHeader}>
          <span>Summary</span>
        </div>
        <ul className={styles.cardBody}>
          <li className={styles.summaryItem}>
            <span>Trader:</span>
            <span>{selectedBot?.trader}</span>
          </li>

          <li className={styles.summaryItem}>
            <span>Risk Appetite:</span>
            <span
              style={{
                color: `${riskAppetiteColor[botReportData?.risk_apetite - 1]}`,
              }}
            >
              {riskOptions[botReportData?.risk_apetite - 1]}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>Exchange ID:</span>
            {botReportData?.exchange_id === "" && <span>None</span>}
            {botReportData?.exchange_id && (
              <span className={styles.exchangeName}>
                <img
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${botReportData?.exchange_id}.svg`}
                  alt={botReportData?.exchange_id}
                />
                {botReportData?.exchange_id}
              </span>
            )}
          </li>
          <li className={styles.summaryItem}>
            <span>Duration:</span>
            <span>
              {formatCustomDate(botReportData?.start_date)} -{" "}
              {formatCustomDate(botReportData?.end_date)}
            </span>
          </li>
          <li className={styles.summaryItem}>
            <span>AI Assistant Mode:</span>
            <span>
              {botReportData?.ai_assistant_mode
                ? botReportData?.ai_assistant_mode.replace("_", "")
                : "-"}
            </span>
          </li>
          {/*<li className={styles.summaryItem}>*/}
          {/*  <span>Average Profit:</span>*/}
          {/*  <span*/}
          {/*    style={{ color: `${averageProfit > 0 ? "#16a085" : "#e74c3c"}` }}*/}
          {/*  >*/}
          {/*    {averageProfit.toFixed(2)}%*/}
          {/*  </span>*/}
          {/*</li>*/}
        </ul>
      </div>
      <a
        className={styles.moreBtn}
        href={botReportData?.pdf_url}
        style={{ backgroundColor: "#e74c3c", borderColor: "#e74c3c" }}
        download={`${botReportData?.trader}_ ${formatCustomDate(
          botReportData?.start_date,
        )}-${formatCustomDate(botReportData?.end_date)}.pdf`}
      >
        <FaRegFilePdf />
        Export report as pdf
      </a>
    </div>
  );
}

export default BotPerformanceSummaryCard;

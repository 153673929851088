import React, { useEffect, useState } from "react";
import { DatePicker, Space, Button, message } from "antd";
import moment from "moment";
import { MdOutlineDateRange } from "react-icons/md";

const { RangePicker } = DatePicker;

const TwoMonthDateRangePicker = ({
  setSelectedDates,
  children,
  selectedBot,
  selectedDate,
}) => {
  // const [selectedDates, setSelectedDates] = useState([]);

  const handleFocus = (e) => {
    if (!selectedBot) {
      message.warn("Please select a bot first.");
      e.preventDefault();
      e.target.blur();
    }
  };

  const disabledDate = (current) => {
    // const twoMonthsAgo = moment().subtract(2, "months").endOf("day");
    const startDate = moment("2023-10-14 00:00");
    const currentDayEnd = moment().endOf("day");
    // const twoMonthsFromNow = moment().add(2, "months").endOf("day");

    // return current && (current < twoMonthsAgo || current > twoMonthsFromNow);
    return current && (current < startDate || current > currentDayEnd);
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  return (
    <Space
      direction="horizontal"
      style={{
        width: "100%",
        justifyContent: "center",
        margin: "1rem auto",
        display: "flex",
        flexDirection: "column",
        opacity: `${selectedBot ? 1 : 0.5}`,
      }}
    >
      <h3 style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <MdOutlineDateRange color={"#49108B"} />
        Choose the start and end date:
      </h3>
      <RangePicker
        format="YYYY-MM-DD"
        style={{ borderRadius: ".3rem" }}
        value={selectedDate}
        disabledDate={disabledDate}
        onChange={handleDateChange}
        onFocus={handleFocus}
      />
      {children}
    </Space>
  );
};

export default TwoMonthDateRangePicker;

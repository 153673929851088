import { Button, Modal, Spin } from "antd";
import styles from "../../styles/shared/exchange-ip-setting/UpdateExchangeIpModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaCheckCircle, FaRegCopy } from "react-icons/fa";
import { notify } from "../../utility/Toastify/toastify";
import {
  checkExchangeIpStatus,
  checkExchangeStatus,
  getTrustedIps,
  resetExchangeStatusData,
  setExchangeForConnection,
  setPreviousPath,
} from "../../redux/exchange/actions";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function UpdateExchangeIpModal({ show, setShow, exchangeData }) {
  const {
    exchangeState: {
      trustedIps,
      getTrustedIpsStatus,
      checkExchangeIpStatus,
      checkExchangeIpResponse,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIp, setSelectedIp] = useState(trustedIps?.[0]);
  const [step, setStep] = useState(1);

  const closeModal = () => {
    if (checkExchangeIpStatus === "loading") return;

    setShow(false);
    setSelectedIp(trustedIps?.[0]);
    setStep(1);
    dispatch(resetExchangeStatusData());
  };

  const getIps = () => {
    dispatch(getTrustedIps());
  };

  const copyHandler = (ip) => {
    setSelectedIp(ip);
    navigator.clipboard.writeText(ip);
    notify(`Copied: ${ip} to clipboard.`, "success");
  };

  const goToSettings = () => {
    navigate(`/integrate-exchange/${exchangeData.name}`);
    dispatch(setPreviousPath(location.pathname));
    dispatch(setExchangeForConnection(exchangeData.id));
  };
  const updateHandler = async () => {
    if (checkExchangeIpStatus === "loading") return;

    if (step === 1) {
      setStep(2);
    }

    await dispatch(
      checkExchangeStatus(
        userData["custom:custom_username"],
        userData?.email,
        exchangeData?.id,
        // "cryptocrispy",
      ),
    );
  };

  return (
    <Modal
      open={show}
      onCancel={closeModal}
      maskClosable={false}
      centered
      footer={false}
      closable={checkExchangeIpStatus !== "loading"}
    >
      <div className={styles.container}>
        {step === 1 && (
          <>
            <div className={styles.title}>
              <RiErrorWarningLine size={30} color={"#0C4CFC"} />
              <h3>Update IP</h3>
            </div>
            <p className={styles.description}>
              Please add this IP to trusted IP list in your exchange:
            </p>
          </>
        )}

        {step === 2 &&
          checkExchangeIpStatus !== "loading" &&
          checkExchangeIpResponse?.status === "Active" && (
            <div className={styles.successMsg}>
              <FaCheckCircle size={30} color={"#2ecc71"} />
              <h3>Your exchange successfully activate.</h3>
            </div>
          )}

        {step === 2 &&
          checkExchangeIpResponse?.status !== "Active" &&
          checkExchangeIpStatus !== "loading" && (
            <div className={styles.failedMsg}>
              <p>
                Since you have updated the IP settings and still we have problem
                getting data from API it might be because:
              </p>
              <p>
                1. You have not updated yet in this case you should{" "}
                <strong>try again</strong>:
              </p>
            </div>
          )}

        {(step === 1 ||
          (checkExchangeIpStatus !== "loading" &&
            checkExchangeIpResponse?.status !== "Active")) && (
          <div className={styles.ipBox}>
            {getTrustedIpsStatus === "loading" && (
              <Spin size={"small"} tip={"Please wait"} />
            )}
            {trustedIps.length > 0 &&
              trustedIps.map((ip) => (
                <p
                  key={ip}
                  className={styles.ip}
                  onClick={() => copyHandler(ip)}
                >
                  {ip}
                  <FaRegCopy className={styles.copyIcon} />
                </p>
              ))}
            {getTrustedIpsStatus === "error" && (
              <p className={styles.errorMsg}>
                Something went wrong! Please{" "}
                <span className={styles.getIpButton} onClick={getIps}>
                  try again
                </span>
                .
              </p>
            )}
          </div>
        )}

        {step === 2 &&
          checkExchangeIpResponse?.status !== "Active" &&
          checkExchangeIpStatus !== "loading" && (
            <div className={styles.failedMsg}>
              <p>
                2. The exchange might take some time to adjust so try later{" "}
              </p>
            </div>
          )}

        {(step === 1 ||
          (checkExchangeIpStatus !== "loading" &&
            checkExchangeIpResponse?.status !== "Active")) && (
          <p className={styles.settingsMsg}>
            If retrying fails, check and adjust your API settings using{" "}
            <span onClick={goToSettings}>Modify API</span> button. (A{" "}
            <strong>new API key</strong> may be required due to exchange
            limits.)
          </p>
        )}

        {step === 2 && checkExchangeIpStatus === "loading" && (
          <Spin tip={"We're checking the exchange ip status."} />
        )}
        <div className={styles.btns}>
          {checkExchangeIpStatus !== "loading" && (
            <Button onClick={closeModal}>Close</Button>
          )}
          {(step === 1 ||
            (checkExchangeIpStatus !== "loading" &&
              checkExchangeIpResponse?.status !== "Active")) && (
            <Button
              loading={checkExchangeIpStatus === "loading"}
              className={styles.updateBtn}
              onClick={updateHandler}
            >
              {step === 1 ? "I've updated IP" : "Check again"}
            </Button>
          )}
          {checkExchangeIpStatus !== "loading" &&
            checkExchangeIpResponse?.status !== "Active" && (
              <Button onClick={goToSettings} className={styles.modifyApiBtn}>
                Modify API
              </Button>
            )}
        </div>
      </div>
    </Modal>
  );
}

export default UpdateExchangeIpModal;

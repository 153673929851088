import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import {
  getReferralCode,
  getReferralRewards,
} from "../../redux/pricing-plan/action";
import { notify } from "../../utility/Toastify/toastify";
import classes from "../../styles/Components/pricing-plan/Rewards.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import styles from "../../styles/Components/mini-app/mini-pricing/UsedReferrals.module.css";
import ReferredUser from "../../components/mini-app/mini-pricing/ReferredUser";
import {
  FaCreditCard,
  FaMoneyBill,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import InviteFriendsModal from "../../components/mini-app/mini-pricing/InviteFriendsModal";
import Loader from "../../components/mini-app/shared/Loader";
import Referrals from "../../components/pricing-plan/referrals/Referrals";

const InviteFriendsButton = ({ title, clickHandler }) => {
  return (
    <div className={classes.addMoreBtn} onClick={clickHandler}>
      <span className={styles.addMoreBtnIcon}>
        <FaUsers size={20} />
      </span>
      <span className={styles.addMoreBtnText}>{title}</span>
    </div>
  );
};

const Rewards = () => {
  const dispatch = useDispatch();
  let {
    main: { userData },
    pricingPlan: {
      referralCode,
      referralCodeLoading,
      referralRewards,
      referralRewardsLoading,
      referralData,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!referralCode) {
      if (userData?.email)
        dispatch(
          getReferralCode(
            userData?.email,
            userData?.["custom:custom_username"],
          ),
        );
    }
  }, [dispatch, referralCode, userData]);

  useEffect(() => {
    if (userData?.email && !referralData?.user && !referralRewardsLoading)
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
  }, [dispatch, referralRewardsLoading, userData]);

  const clickCodeHandler = () => {
    navigator.clipboard.writeText(referralCode);
    notify(`Copied: ${referralCode} to clipboard.`, "success");
  };
  return (
    <div className={classes.container}>
      {/*<ShareButtons />*/}
      <h4 className={classes.title}>Referred Users:</h4>

      <Referrals />

      {/*<div className={classes.referralCode}>*/}
      {/*<Tooltip*/}
      {/*  placement="topLeft"*/}
      {/*  title={"Tap on the code, it will copy to your clipboard."}*/}
      {/*>*/}
      {/*  <p>*/}
      {/*    <AiOutlineInfoCircle />*/}
      {/*    Your Referral Code:*/}
      {/*  </p>*/}
      {/*</Tooltip>*/}
      {/*<div>*/}
      {/*  {referralCodeLoading ? (*/}
      {/*    "loading..."*/}
      {/*  ) : (*/}
      {/*    <span className={classes.code} onClick={clickCodeHandler}>*/}
      {/*      {referralCode}*/}
      {/*    </span>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Rewards;

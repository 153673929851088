import styles from "../../../styles/Components/mini-app/mini-pricing/UsedReferrals.module.css";
import { useSelector } from "react-redux";
import Loader from "../shared/Loader";
import ReferredUser from "./ReferredUser";
import {
  FaCreditCard,
  FaLink,
  FaMoneyBill,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import { useState } from "react";
import { message, Tooltip } from "antd";
import { FiArrowLeftCircle, FiCopy } from "react-icons/fi";
import { RiUserShared2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import InviteFriendsModal from "./InviteFriendsModal";

const InviteFriendsButton = ({ title, clickHandler, referrals }) => {
  return (
    <div className={styles.addMoreBtn} onClick={clickHandler}>
      <span className={styles.addMoreBtnIcon}>
        <FaUsers size={20} />
      </span>
      <span className={styles.addMoreBtnText}>{title}</span>
    </div>
  );
};

function UsedReferrals() {
  const {
    pricingPlan: {
      referralCode,
      referralCodeLoading,
      referralRewardsLoading,
      referralRewards,
    },
  } = useSelector((state) => state);

  const [showShareModal, setShowShareModal] = useState(false);

  const invitationLink = `https://t.me/cryptocrispy_bot?start=${referralCode}`;
  const totalCredit = referralRewards?.reduce(
    (acc, cur) => Number(cur?.addition_reward) + acc,
    0,
  );
  const totalMoney = referralRewards?.reduce(
    (acc, cur) => Number(cur?.cashable_reward) + acc,
    0,
  );

  const formattedLink = `${invitationLink.substring(0, 25)}${
    invitationLink?.length > 25 ? "..." : ""
  }`;

  const openShareModal = () => {
    setShowShareModal(true);
  };

  const showCopyNotification = () =>
    message.success("Copied link to clipboard.");
  const copyReferralCode = () => {
    navigator.clipboard.writeText(referralCode);
    showCopyNotification();
  };
  const copyInvitationLink = () => {
    navigator.clipboard.writeText(invitationLink);
    showCopyNotification();
  };

  return (
    <div>
      <div className={styles.header}>
        <Link to={"/mini-pricing-plan"} className={styles.backBtn}>
          <FiArrowLeftCircle size={25} />
        </Link>
        <div>
          <h3 className={styles.title}>Used Referrals</h3>
          <p className={styles.description}>
            Here you can see friends that used your referral code and the amount
            you earned from each one.
          </p>
        </div>
      </div>
      {referralRewardsLoading && <Loader height={"120px"} />}

      {!referralRewardsLoading && referralRewards?.length > 0 && (
        <div className={styles.referredUsersList}>
          {referralRewards?.map((user) => (
            <ReferredUser
              key={`${user?.referred_user_email}/${user?.createdDate}`}
              data={user}
            />
          ))}
          <InviteFriendsButton
            title={"+ Add more"}
            referrals={referralRewards}
            clickHandler={openShareModal}
          />
        </div>
      )}

      {!referralRewardsLoading && (
        <div className={styles.summaryBox}>
          <div className={styles.info}>
            <span className={styles.infoTitle}>
              <FaUserFriends /> Number of Friends
            </span>
            <span className={styles.infoValue}>{referralRewards?.length}</span>
          </div>

          <div className={styles.info}>
            <span className={styles.infoTitle}>
              <FaCreditCard /> Total Earned Credit
            </span>
            <span className={styles.infoValue}>$ {totalCredit / 100}</span>
          </div>

          <div className={styles.info}>
            <span className={styles.infoTitle}>
              <FaMoneyBill /> Total Earned Money
            </span>
            <span className={styles.infoValue}>$ {totalMoney}</span>
          </div>
        </div>
      )}

      {!referralRewardsLoading && referralRewards?.length === 0 && (
        <InviteFriendsButton
          title={"Invite Friends"}
          referrals={referralRewards}
          clickHandler={openShareModal}
        />
      )}

      <div className={styles.referralCodes}>
        <div className={styles.referBox}>
          <span>
            <RiUserShared2Fill size={15} /> Referral Code:
          </span>
          {referralCodeLoading && (
            <span className={styles.loading}>Loading...</span>
          )}
          {!referralCodeLoading && (
            <p className={styles.code} onClick={copyReferralCode}>
              {referralCode} <FiCopy />
            </p>
          )}
        </div>

        <div className={styles.referBox}>
          <span>
            <FaLink />
            Referral Link:
          </span>
          {referralCodeLoading && (
            <span className={styles.loading}>Loading...</span>
          )}
          {!referralCodeLoading && (
            <Tooltip title={invitationLink}>
              <p className={styles.code} onClick={copyInvitationLink}>
                {formattedLink} <FiCopy />
              </p>
            </Tooltip>
          )}
        </div>
      </div>

      {showShareModal && (
        <InviteFriendsModal
          show={showShareModal}
          setShow={setShowShareModal}
          link={invitationLink}
        />
      )}
    </div>
  );
}

export default UsedReferrals;

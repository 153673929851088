import { useSelector } from "react-redux";
import { useStrategy } from "./useCreateStrategyContext";
import styles from "../../../styles/Components/new-insight/create-strategy/SubcategoryOptions.module.css";
import { Menu } from "antd";
import { useEffect } from "react";

function SubcategoryOptions() {
  const {
    newInsightState: { categoriesFeatureTree },
  } = useSelector((state) => state);

  const {
    state: { selectedCategory, selectedSubcategory },
    setSubcategory,
  } = useStrategy();

  if (!selectedCategory) return null;

  return (
    <>
      <ul className={styles.container}>
        {Object.keys(categoriesFeatureTree?.[selectedCategory])?.map((sub) => (
          <li
            className={`${styles.subcategory} ${
              selectedSubcategory === sub ? styles.selected : ""
            }`}
            key={sub}
            onClick={() => setSubcategory(sub)}
          >
            {sub}
          </li>
        ))}
      </ul>

      <Menu
        mode="horizontal"
        className={styles.menu}
        selectedKeys={[selectedSubcategory]}
      >
        {Object.keys(categoriesFeatureTree?.[selectedCategory])?.map((sub) => (
          <Menu.Item
            key={sub}
            onClick={() => setSubcategory(sub)}
            className={`${styles.subcategory} ${
              selectedSubcategory === sub ? styles.selected : ""
            }`}
          >
            {sub}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
}

export default SubcategoryOptions;

import styles from "../../../styles/Components/trade-assistant/portfolio/VirtualPortfolioTableExpandedRow.module.css";
import { formatAmount } from "../../../utility/helper";
import PortfolioTableChart from "../../charts/trading-assistant/PortfolioTableChart";
import React from "react";

function NewVirtualPortfolioTableExpandedRow({ data }) {
  return (
    <div className={styles.container}>
      <ul className={styles.dataList}>
        <li className={styles.marketType}>
          <span className={styles.label}>Market Type:</span>
          <span>{data?.marketType}</span>
        </li>
        <li className={styles.oneHour}>
          <span className={styles.label}>1H:</span>
          <span className={`${data?.oneHour < 0 ? styles.red : styles.green}`}>
            {data?.oneHour}%
          </span>
        </li>
        <li className={styles.oneDay}>
          <span className={styles.label}>1Day:</span>
          <span className={`${data?.oneDay < 0 ? styles.red : styles.green}`}>
            {data?.oneDay}%
          </span>
        </li>
        <li className={styles.oneWeekValue}>
          <span className={styles.label}>7Day:</span>
          <span className={`${data?.oneWeek < 0 ? styles.red : styles.green}`}>
            {data?.oneWeek}%
          </span>
        </li>
        <li>
          <span className={styles.label}>Last 7Days:</span>
          <PortfolioTableChart chartData={data?.last7Days} />
        </li>
      </ul>
    </div>
  );
}

export default NewVirtualPortfolioTableExpandedRow;

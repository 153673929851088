import { useSelector } from "react-redux";
import { Table, Tabs } from "antd";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";

const TabContent = ({ symbols, values }) => {
  const {
    newInsightState: { getInitialDataLoading },
  } = useSelector((state) => state);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      render: (coin) => (
        <span>
          <img
            style={{ width: "28px", height: "28px", borderRadius: "100%" }}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${coin}.png`}
            alt={coin}
          />
          {coin}
        </span>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
  ];

  const dataSource = symbols?.map((symbol, index) => ({
    key: index,
    symbol,
    value: values[index],
  }));

  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={getInitialDataLoading}
    />
  );
};
const InsightWatchlist = () => {
  const {
    newInsightState: { overallStatistics },
  } = useSelector((state) => state);

  const tabs = [
    {
      label: "Tops",
      key: "tops",
      dataKey: "TOP_20",
      symbolKey: "TOP_20_SYMBOLS",
    },
    {
      label: "Top Gainers",
      key: "top_gainers",
      dataKey: "TOP_20_Gainers",
      symbolKey: "TOP_20_Gainers_SYMBOLS",
    },
    {
      label: "Top Losers",
      key: "top_losers",
      dataKey: "TOP_20_losers",
      symbolKey: "TOP_20_losers_SYMBOLS",
    },
    {
      label: "Top Relatively Losers/Gainers",
      key: "top_relatively_losers_gainers",
      dataKey: "TOP_20_Relatively_Gainers",
      symbolKey: "TOP_20_Relatively_Gainers_SYMBOLS",
    },
  ];

  return (
    <Tabs>
      {tabs?.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          <TabContent
            symbols={overallStatistics?.[tab.symbolKey]}
            values={overallStatistics?.[tab.dataKey]}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default InsightWatchlist;

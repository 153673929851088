import "../../styles/Components/faq/faqPublic.css";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import FaqItem from "./general/FaqItem";

const questions = [
  {
    question: "Is AI Assistant a bot?",
    answer:
      "AI Assistant isn't a bot, but our bots receive signals from AI Assistant for automated trading. AI Assistant is a versatile tool providing traders with profitable signals. Additionally, it provides insights and recommendations, including risk parameters such as stop loss and take profit for every signal. Traders maintain full control over their trading activities and can execute trades on their integrated exchange using AI Assistant.",
  },
  {
    question: "How does AI Assistant generate signals?",
    answer:
      "AI Assistant generates signals by utilizing a combination of data sources, including AI predictions, technical indicators, on-chain data, social media, whale analysis, and more. By integrating these diverse sources of information, AI Assistant is able to provide reliable trading signals to assist traders in making informed decisions.",
  },
  {
    question: "Who can benefit from your AI tools?",
    answer:
      "Traders, investors, and businesses in the cryptocurrency can benefit from CryptoCrispy's AI tools. Our platform offers AI-powered signals and bots, backtesting and paper trading, alerts and portfolio monitoring, and performance reports for informed decision-making in the crypto market.",
  },
  {
    question: "How profitable are your AI predictions? ",
    answer:
      "Our AI predictions have consistently shown to generate highly profitable signals when compared to other methods. It's important to note that profitability can vary over time and across different time frames. For more detailed information, we encourage you to refer to our accuracy report, which provides insights into the performance of our AI predictions and their profitability.",
  },
  {
    question: "What is the easiest way to get buy/sell signals?",
    answer:
      "The easiest way to obtain buy/sell signals is by utilizing the AI Assistant tools provided on our platform. AI Assistant processes and evaluates a variety of signals and selects the most profitable ones for you. Without the AI Assistant, you would need to manually analyze various types of analytics to identify profitable signals, which can be time-consuming and require significant effort. By using the AI Assistant, you can save valuable time as it automates this process for you.",
  },
  {
    question: "What does AI Active Portfolio do?",
    answer:
      "Imagine you have created a portfolio consisting of various crypto assets. By adding these assets to your portfolio, the AI Active Portfolio can easily monitor their performance and provide timely alerts. It also rebalances your portfolio based on AI signals when your portfolio drops below a certain percentage set by you.",
  },
];

function BotFaq() {
  const navigate = useNavigate();

  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>

      <h3 className="helpTitle">AI Assistant Frequently Asked Questions</h3>

      {questions.map((faq, index) => (
        <FaqItem key={index} {...faq} id={index} />
      ))}
    </div>
  );
}

export default BotFaq;

import { Modal } from "antd";
import styles from "../../styles/start-wizard/IntegrationAlert.module.css";
import warn from "../../asset/Icons/warn.gif";

function IntegrationAlert({ show, setShow, goStep }) {
  return (
    <Modal
      width={400}
      open={show}
      closable={false}
      centered
      footer={null}
      children={
        <div className={styles.container}>
          <img src={warn} alt="note" className={styles.icon} />
          <h3 className={styles.title}>
            You haven't integrated with any exchanges yet. Would you like to
            integrate now?
          </h3>
          <div className={styles.btns}>
            <span className={styles.yesBtn} onClick={() => setShow(false)}>
              Integrate now
            </span>
            <span
              className={styles.noBtn}
              onClick={() => {
                setShow(false);
                goStep(1);
              }}
            >
              Integrate later
            </span>
          </div>
        </div>
      }
    />
  );
}

export default IntegrationAlert;

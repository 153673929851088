import {useSelector} from "react-redux";
import {formatPrice} from "../../../utility/helper";
import styles from "../../../styles/Components/mini-app/shared/DisplayCredit.module.css";
import {FaRegCreditCard} from "react-icons/fa";

function DisplayCredit({style, customClassName}) {
    const {
        profile: {profileData},
    } = useSelector((state) => state);

    return (
        <div className={`${styles.container} ${customClassName || ""}`} style={style || {}}>
      <span className={styles.title}>
        <FaRegCreditCard/>
        Current Credit:
      </span>
            <span>${formatPrice(profileData?.credit / 100, 2)}</span>
        </div>
    );
}

export default DisplayCredit;

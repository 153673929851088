import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redeemGift } from "../../redux/pricing-plan/action";
import styles from "../../styles/Components/pricing-plan/RedeemGift.module.css";
import { CLEAR_REDEEM_GIFT_MESSAGE } from "../../redux/pricing-plan/consts";

const RedeemGift = ({ customClassName }) => {
  const [code, setCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const { redeemGiftLoading, redeemGiftMessage, redeemGiftError } = useSelector(
    (state) => state.pricingPlan,
  );
  const { userData } = useSelector((state) => state.main);
  // Regex to validate the code format
  const codeRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const exceptionRegex = /^[A-Z0-9]{4}$/;

  // Event handler to update the code state and check its validity
  const handleCodeChange = (e) => {
    if (redeemGiftLoading) return;

    const value = e.target.value;
    setCode(value);
    setIsValid(codeRegex.test(value) || exceptionRegex.test(value));
  };

  // Event handler to dispatch the redeemGift action with the code and some dummy email and user values
  const handleRedeemClick = (e) => {
    if (!isValid) return;

    e.preventDefault();
    dispatch(
      redeemGift(userData?.email, userData?.["custom:custom_username"], code),
    );

    dispatch({ type: CLEAR_REDEEM_GIFT_MESSAGE });
  };

  // Use useEffect to clear the messages on first render
  useEffect(() => {
    dispatch({ type: CLEAR_REDEEM_GIFT_MESSAGE });
  }, []);

  return (
    <div className={`${styles.container} ${customClassName || ""}`}>
      <h4 style={{ textAlign: "center" }}>Enter your gift code here:</h4>
      <div className={styles.inputBox}>
        <div>
          <input
            className={`${styles.codeInput} ${
              !isValid && code ? styles.invalidInput : ""
            }`}
            type="text"
            value={code}
            onChange={handleCodeChange}
            placeholder="Enter your code here"
          />
          {!isValid && code && (
            <p className={styles.invalidMessage}>
              Please enter a valid code format
            </p>
          )}
        </div>
        <button
          onClick={handleRedeemClick}
          disabled={!isValid || redeemGiftLoading}
        >
          Redeem
        </button>
      </div>
      <div className={styles.messageBox}>
        {redeemGiftLoading && (
          <p className={`${styles.resultMessage} ${styles.loadingMessage}`}>
            Loading...
          </p>
        )}
        {redeemGiftMessage && (
          <p className={`${styles.resultMessage} ${styles.successMessage}`}>
            {redeemGiftMessage}
          </p>
        )}
        {redeemGiftError && (
          <p className={`${styles.resultMessage} ${styles.errorMessage}`}>
            {redeemGiftError}
          </p>
        )}
      </div>
    </div>
  );
};

export default RedeemGift;

import FaqItem from "./general/FaqItem";
import QuickAccessMenu from "./general/QuickAccessMenu";

import styles from "../../styles/Components/faq/GeneralQuestions.module.css";
import { useNavigate } from "react-router";
import { BiChevronLeft } from "react-icons/bi";
import { Link } from "react-router-dom";

const faqList = [
  {
    question:
      "Can you tell me more about CryptoCrispy's AI-powered data analytics platform?",
    answer:
      "CryptoCrispy's platform is designed to help traders make informed decisions by providing deep market insights derived from various sources, including social media, technical indicators, whales, and blockchain transactions. The platform's AI engine integrates these signal sources to generate buy/sell signals that users can act on. In addition to AI price predictions and trade signals, the platform offers an alert system that can be customized to fit the user's risk appetite and delivered through email. The platform also includes a Trade Simulator that allows users to test trading strategies resulting from AI and other analytics, as well as portfolio management tools that monitor asset performance and provide real-time alerts.",
  },
  {
    question:
      "What sets CryptoCrispy apart from other data analytics platforms?",
    answer:
      "CryptoCrispy stands out from other data analytics platforms by offering an AI-powered engine that integrates various signal sources to generate highly accurate buy/sell signals. The platform's alert system can be customized to fit the user's risk appetite, and real-time performance alerts are provided for portfolios. Additionally, the Trade Simulator allows users to test their strategies in a simulated environment before trading with real money. CryptoCrispy also offers a free plan that provides access to advanced features, making it a great option for both beginner and experienced traders.",
  },
  {
    question:
      "Can you explain how CryptoCrispy's Pro and Enterprise plans differ from the free plan?",
    answer: `The free plan provides access to many of CryptoCrispy's advanced features for a period of 14 days. The Pro plan offers usage-based pricing starting at $5 and includes additional features such as unlimited signal alerts, a customizable dashboard, and unlimited portfolio tracking. The Enterprise plan is designed for larger organizations and includes unlimited usage of all CryptoCrispy's features, as well as personalized support and custom reporting. More information about the plans can be found at Pricing Plan`,
  },
  {
    question: "How accurate are CryptoCrispy's AI predictions?",
    answer:
      "CryptoCrispy's AI engine generates highly accurate buy/sell signals compared to other methods. However, profitability can change over time and in different time frames. To learn more about the accuracy of CryptoCrispy's AI predictions, users can read the accuracy report provided on the platform.",
  },
  {
    question: "Do you offer automated trading through bots?",
    answer:
      "No, CryptoCrispy does not offer automated trading done through bots. Users are in control of their trading decisions and must go to their exchange of choice to execute trades.",
  },
  {
    question:
      "Can users trade assets other than cryptocurrencies on CryptoCrispy's platform?",
    answer:
      "Yes, users can create portfolios with assets from both crypto and forex markets.",
  },
  {
    question:
      "What is the benefit of having two portfolios in CryptoCrispy's platform for Pro users?",
    answer:
      "Pro users have access to two portfolios, which allows them to evaluate both paper trading and backtesting in parallel. Free users, on the other hand, can only do this one at a time.",
  },
  {
    question: "Does CryptoCrispy use real money for trades?",
    answer:
      "No, CryptoCrispy uses virtual money for trades in its platform, so there is no risk involved.",
  },
  {
    question: "How can users access CryptoCrispy's social media accounts?",
    answer:
      "CryptoCrispy can be found on Instagram, Twitter, Facebook, Discord, Telegram, and LinkedIn. Users can access these accounts by searching for CryptoCrispy on each respective social media platform.",
  },
];

const newQuestions = [
  {
    question: "Is CryptoCrispy a cryptocurrency exchange?",
    answer:
      "No, CryptoCrispy is not a cryptocurrency exchange. It is an AI-powered platform focused on providing smart tools and bots for average crypto traders. The platform can be integrated with various cryptocurrency exchanges, offering users the ability to benefit from AI-driven trading strategies without the need to deposit funds directly into the CryptoCrispy platform.",
  },
  {
    question: "What makes CryptoCrispy unique?",
    answer:
      "CryptoCrispy stands out by creating intelligent AI bots designed for everyday traders. We specialize in delivering highly accurate signals using advanced AI and big data. Our reliable bots have undergone extensive testing, proving their profitability. We are committed to constant updates, making user experience better, adding features, and enhancing overall performance.",
  },
  {
    question: "Does your service support both spot and futures trading?",
    answer:
      "Our AI assistant provides signals for both short and long positions, making it suitable for both spot and futures trading. However, for integrations and bot implementations, we currently only support spot trading.",
  },
  {
    question: "Is your data suitable for both spot and future trading?",
    answer:
      "Yes, you can use CryptoCrispy's signals and bots for both spot and futures trading. The platform provides automated bots and AI-powered signals that can be applied to different trading strategies in both spot and futures markets.",
  },
  {
    question: "What are VIP signals, and how can I access them?",
    answer: `VIP signals are a premium feature that helps you identify potential market pumps and high-performing coins. While our AI Assistant focuses on top coins and rising assets, VIP signals offer an extra layer of market insight. You can access VIP signals through the alert system, and some are also published on our Telegram channel.
`,
  },
  {
    question: "What features are automated on your platform?",
    answer:
      "Key automated features include intelligent trade execution, AI-driven signal generation, risk management, and real-time alerts for informed decision-making.",
  },
  {
    question: "Where can I find you on social media?",
    answer:
      "You can connect with us on various social media platforms including Instagram, Twitter, Facebook, Discord, Telegram, and LinkedIn. Stay updated with our latest news, insights, and community discussions by following us on these channels.",
  },
  {
    question: "Is your platform really FREE?",
    answer:
      "Absolutely! Simply connect your exchange to CryptoCrispy and kickstart your AI bot trading for free.",
  },
];

const GeneralQuestions = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.mainContainer}>
      <ul>
        <div className={styles.backBtn} onClick={() => navigate("/faq/all")}>
          <BiChevronLeft style={{ marginRight: ".3rem" }} />
          Back
        </div>
        {newQuestions.map((faq, index) => (
          <FaqItem key={index} {...faq} id={index} />
        ))}
      </ul>
      <QuickAccessMenu />
      <div className={styles.firstBackBtn} onClick={() => navigate("/faq/all")}>
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
    </div>
  );
};

export default GeneralQuestions;

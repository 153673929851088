import { FaCaretRight } from "react-icons/fa";
import styles from "../../styles/shared/service-activation/ActivateServiceByCrypto.module.css";
import { servicesCost } from "../../utility/data";
import { useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  activateServiceWithCard,
  activateServiceWithCrypto,
} from "../../redux/pricing-plan/action";
import {
  calculateOffPrice,
  renderServiceActivationMode,
} from "../../utility/helper";
import ActivationDurationOptions from "./ActivationDurationOptions";

function ActivateServiceByPayment({
  service,
  style,
  paymentType,
  changeContent,
  setStep,
}) {
  const {
    pricingPlan: {
      activateServiceWithCryptoStatus,
      activateServiceWithCardStatus,
      pricingPlan,
    },
    main: { userData },
  } = useSelector((state) => state);

  const offPercentage =
    pricingPlan["yearly_discount"] || servicesCost["yearly_discount"];
  const cost = pricingPlan[service] || servicesCost[service];
  console.log(pricingPlan[service], servicesCost[service], cost, service);

  const [selectedDuration, setSelectedDuration] = useState(null);

  const dispatch = useDispatch();

  const activateServiceByCrypto = () => {
    if (
      activateServiceWithCryptoStatus === "loading" ||
      activateServiceWithCardStatus === "loading" ||
      !selectedDuration
    )
      return;

    const amount =
      selectedDuration > 1
        ? calculateOffPrice(offPercentage, cost, selectedDuration, "price")
        : cost;

    const redirectUrl = window.location.href;

    if (paymentType === "crypto") {
      dispatch(
        activateServiceWithCrypto(
          userData?.["custom:custom_username"],
          userData?.email,
          renderServiceActivationMode(service),
          `${amount}`,
          redirectUrl,
          redirectUrl,
        ),
      );
    }

    if (paymentType === "card") {
      dispatch(
        activateServiceWithCard(
          userData?.["custom:custom_username"],
          userData?.email,
          renderServiceActivationMode(service),
          `${amount}`,
          redirectUrl,
          redirectUrl,
        ),
      );
    }

    changeContent("result");
    setStep(3);
  };

  return (
    <div className={styles.container} style={style || {}}>
      <p className={styles.question}>
        <FaCaretRight />
        How many months would you like to activate this service for?
      </p>
      <ActivationDurationOptions
        service={service}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        activationType={"payment"}
      />
      <div className={styles.submitBtnContainer}>
        <Button
          className={styles.submitBtn}
          loading={
            activateServiceWithCryptoStatus === "loading" ||
            activateServiceWithCardStatus === "loading"
          }
          disabled={!selectedDuration}
          onClick={activateServiceByCrypto}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ActivateServiceByPayment;

import React, { useEffect, useState } from 'react';
import { Button, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDeveloperApiData } from "../redux/developer-api/actions";
import styles from "../styles/Components/api.module.sass"
import ReactLoading from "react-loading";
import Ripples from 'react-ripples'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FaRegCopy } from "react-icons/fa";
import { days_between, formatDate } from "../utility/helper";


const Apis = ({ data, generateApiKeyHandler }) => {
    const [copied, setCopied] = useState({
        API_URL: false,
        API_KEY: false
    })
    const { generateApiKey } = useSelector(state => state)

    const item = data?.Item
    const freeTier = item?.pricing_plan === "Free-tier"
    if (!item) {
        return <Empty />
    }
    return (

        <div className={styles.apiContainer}>
            <p>Creating Developer API key</p>
            <p>The API key is a unique identifier that authenticates requests associated with your account for usages purpose. You must have at least one API key associated with your account</p>
            <div>
                <div>
                    <span> API URL:</span>
                    <span>{freeTier ? null : item?.API_URL}</span>
                    <CopyToClipboard text={item?.API_URL}
                        onCopy={() => setCopied(prev => ({ ...prev, API_URL: true }))}
                        className={styles.copyBtn}>
                        <Button>{copied.API_URL ? "copied" : <FaRegCopy />}</Button>

                    </CopyToClipboard>
                </div>
                <div>
                    <span>Your API key:</span>

                    <span>{freeTier ? null : generateApiKey?.data?.API_KEY ? generateApiKey?.data?.API_KEY : item?.API_KEY}</span>
                    <CopyToClipboard text={item?.API_KEY}
                        onCopy={() => setCopied(prev => ({ ...prev, API_KEY: true }))}
                        className={styles.copyBtn}>
                        <Button>{copied.API_KEY ? "copied" : <FaRegCopy />}</Button>
                    </CopyToClipboard>
                </div>
                <div>
                    <span>Last invocation date:</span>
                    <span> {freeTier ? null : formatDate(new Date(item?.API_LAST_INVOCATION_DATE))}</span>
                </div>
                <div>
                    <span>Days until expiration:</span>
                    <span>{freeTier ? null : days_between(new Date(), new Date(generateApiKey.data?.API_EXPIRATION_DATE ? generateApiKey.data?.API_EXPIRATION_DATE : item?.API_EXPIRATION_DATE))} days</span>
                </div>

            </div>
            <Ripples className={"test"} during={1000}>
                <Button onClick={generateApiKeyHandler} disabled={freeTier}>
                    {generateApiKey?.loading ?
                        <ReactLoading type={"bars"} color="#fff" height={40} width={36} /> : "Generate new API key"}
                </Button>
            </Ripples>
        </div>
    );
};

export default Apis;

import poolData from "../../../pages/Auth/AWSConfig/PoolData";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { notify } from "../../../utility/Toastify/toastify";
import { checkEmailException } from "../../../utility/helper";
import PoolData from "../../../pages/Auth/AWSConfig/PoolData";
import {
  setToken,
  setUserData,
  signinUpMiniApp,
} from "../../../redux/main/action/main";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/Components/mini-app/auth/MiniAppLoginPage.module.css";
import { Button, Form, Input, message } from "antd";
import { useSearchParams } from "react-router-dom";
import { miniAppConfirmationCodeValidation } from "../../../utility/Validation/validation";

function MiniAppConfirmPage({ navigate, setAuthType }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(miniAppConfirmationCodeValidation),
  });
  const {
    main: { userPass, telegramData },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(20);
  const [params] = useSearchParams();

  const dispatch = useDispatch();

  const savedUserPass = localStorage.getItem("userPass")?.split(",");
  const savedEmail = savedUserPass?.[0];
  const savedPass = savedUserPass?.[1];

  const telegramAuth = params.get("tgWebAppStartParam");
  const telegram_id = telegramData?.telegramId || telegramAuth?.slice(0, 20);

  const resetTimer = () => setCountdown(20);
  const resendCodeHandler = () => {
    setLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: checkEmailException(savedEmail),
        Pool: PoolData,
      });
    };

    const onSuccess = (data) => {
      setLoading(false);
      resetTimer();
    };
    const onFailure = (err) => {
      message.error(err.message || JSON.stringify(err));
      setLoading(false);
    };

    getUser().resendConfirmationCode((err, data) => {
      if (err) {
        onFailure(err);
      } else {
        onSuccess(data);
      }
    });
  };
  const onSubmit = (data) => {
    setLoading(true);
    const userData = {
      Username: savedEmail.toLowerCase(),
      Pool: poolData,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        data?.confirmationCode,
        true,
        function (err, result) {
          if (err) {
            message.error(err?.message);
            reject(err);
            setLoading(false);
          } else {
            message.success("You have successfully signed up");
            resolve(result);

            let user = new CognitoUser({
              Username: checkEmailException(userPass[0] || savedEmail),
              Pool: PoolData,
            });
            let authDetails = new AuthenticationDetails({
              Username: userPass[0] || savedEmail,
              Password: userPass[1] || savedPass,
              ValidationData: { captcha: "rtspa_mehdi_ravanbakhsh_761" },
            });

            user.authenticateUser(authDetails, {
              onSuccess: (data) => {
                dispatch(setToken(data?.idToken?.jwtToken));
                dispatch(setUserData(data?.idToken?.payload));
                const telegramId = telegram_id?.replace(/^0+/, "");
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${data?.idToken?.jwtToken}`;
                // window.location.reload();
                // notify("You have successfully logged in", "success");
                localStorage.setItem(
                  "aws-amplify-federatedInfo",
                  JSON.stringify({ token: data?.idToken?.jwtToken }),
                );
                localStorage.setItem(
                  "aws-amplify-USERDATA",
                  JSON.stringify(data?.idToken?.payload),
                );

                dispatch(
                  signinUpMiniApp(
                    telegramId,
                    data?.idToken?.payload["custom:custom_username"],
                    data?.idToken?.payload?.email,
                  ),
                );

                localStorage.setItem("telegramData", telegramAuth);
                setLoading(false);
                // navigate("/ai-assistant/assistant");
                navigate();
              },

              onFailure: function (err) {
                window.grecaptcha.reset();
                setLoading(false);
                if (err?.message.includes("captcha")) return;
                notify(err?.message, "error");
              },

              newPasswordRequired: (data) => {
                notify(data, "success");
                setLoading(false);
              },
            });
            // setLoading(false);
            // navigate("/auth/login");
          }
        },
      );
    });
    // [{Name:"email",Value:data?.email}]
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  return (
    <Form onFinish={handleSubmit(onSubmit)}>
      <Form.Item
        label="Confirmation Code"
        validateStatus={errors.confirmationCode ? "error" : ""}
        help={errors.confirmationCode ? errors.confirmationCode.message : ""}
      >
        <Controller
          name="confirmationCode"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              onBlur={field.onBlur}
              placeholder="Enter confirmation code"
            />
          )}
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center", marginTop: "2rem" }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className={styles.submitBtn}
        >
          Confirm
        </Button>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {countdown > 0 ? (
            `Request a new code in ${countdown}s.`
          ) : (
            <span className={styles.resendCodeBtn} onClick={resendCodeHandler}>
              Resend Code
            </span>
          )}
        </div>
      </Form.Item>
      <p
        className={styles.createAccountBtn}
        onClick={() => setAuthType("login")}
      >
        Back to Login
      </p>
    </Form>
  );
}

export default MiniAppConfirmPage;

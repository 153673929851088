import { useStrategy } from "./useCreateStrategyContext";
import { Empty } from "antd";
import AddStrategy from "./AddStrategy";

import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyMain.module.css";
import SubcategoryOptions from "./SubcategoryOptions";
import FeatureOptions from "./FeatureOptions";
import SearchStrategyResult from "./SearchStrategyResult";

function CreateStrategyMain({ setStep }) {
  const {
    state: { strategyList, selectedCategory, selectedSubcategory, searchQuery },
  } = useStrategy();
  return (
    <div className={styles.container}>
      {searchQuery.length > 0 ? (
        <SearchStrategyResult />
      ) : (
        <>
          <SubcategoryOptions />
          {selectedSubcategory && <FeatureOptions />}
          {strategyList.length === 0 && !selectedCategory && (
            <div className={styles.emptyBox}>
              <Empty imageStyle={{ width: "80px", height: "80px" }} />
              <p>No strategy is made; Create your own strategy.</p>
            </div>
          )}
        </>
      )}
      <AddStrategy setStep={setStep} />
    </div>
  );
}

export default CreateStrategyMain;

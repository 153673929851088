import MiniAppContainer from "./MiniAppContainer";
import styles from "../../styles/Components/mini-app/MiniDashboard.module.css";
import { MdEmail, MdMoreVert, MdSubscriptions } from "react-icons/md";
import MinimalCard from "./MinimalCard";
import {
  FaGift,
  FaMoneyBill,
  FaRegCreditCard,
  FaRobot,
  FaSignal,
  FaUser,
} from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getMinimalDashboardData } from "../../redux/minimal-app/actions";
import { formatAmount, formatPrice } from "../../utility/helper";
import BotBanner from "./mini-dashboard/BotBanner";
import SectionDivider from "./layout/SectionDivider";
import Avatar from "react-avatar";
import {
  getReferralRewards,
  getSubscriptions,
} from "../../redux/pricing-plan/action";
import moment from "moment";
import AiSignalBanner from "./mini-dashboard/AiSignalBanner";
import ReferralBanner from "./mini-dashboard/ReferralBanner";
import { Tooltip } from "antd";
import WithdrawButton from "../pricing-plan/referrals/WithdrawButton";

const returnServiceActivationStatus = (loading, status) => {
  const isActive = status === "True";

  return loading || !status ? (
    "Checking..."
  ) : isActive ? (
    <>
      {/*<FaCircleCheck /> */}
      Active
    </>
  ) : (
    <>
      {/*<IoMdCloseCircle /> */}
      Not Active
    </>
  );
};

function MiniDashboard() {
  const {
    main: { userData },
    minimalAppState: { minimalDashboardStatus, minimalDashboardData },
    profile: { profileData, loading },
    pricingPlan: {
      referralData,
      referralRewardsLoading,
      subscriptions,
      getSubscriptionLoading,
      subscriptionError,
      referralRewardsError,
    },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasMonthlySubscription = subscriptions?.monthly?.status === "active";
  const hasYearlySubscription = subscriptions?.yearly?.status === "active";

  const subscriptionEndDate =
    hasYearlySubscription || hasMonthlySubscription
      ? moment.unix(
          hasMonthlySubscription
            ? subscriptions?.monthly?.current_period_end
            : subscriptions?.yearly?.current_period_end,
        )
      : null;
  const currentDate = moment();
  const difference = subscriptionEndDate
    ? subscriptionEndDate.diff(currentDate, "days")
    : "";
  const subscriptionTimeLeft = difference
    ? difference < 30
      ? `${difference} days left`
      : subscriptionEndDate.format("YYYY-MM-DD HH:mm:ss")
    : "";

  const goToCreditChargePage = () => {
    navigate("/mini-pricing-plan");
  };

  useEffect(() => {
    if (
      userData?.email &&
      minimalDashboardStatus !== "loading" &&
      minimalDashboardStatus !== "error" &&
      Object.keys(minimalDashboardData).length === 0
    ) {
      dispatch(
        getMinimalDashboardData(
          userData?.["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [userData, minimalDashboardStatus, minimalDashboardData, dispatch]);

  useEffect(() => {
    if (
      userData?.email &&
      !referralData?.user &&
      !referralRewardsLoading &&
      !referralRewardsError
    ) {
      dispatch(
        getReferralRewards(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [
    userData,
    referralData,
    dispatch,
    referralRewardsLoading,
    referralRewardsError,
  ]);

  useEffect(() => {
    if (
      userData?.email &&
      !Object.keys(subscriptions).length &&
      !getSubscriptionLoading &&
      !subscriptionError
    ) {
      dispatch(
        getSubscriptions(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, subscriptions, getSubscriptionLoading, subscriptionError]);

  // useEffect(() => {
  //   if (!referralCode && userData?.email && !referralCodeLoading) {
  //     dispatch(
  //       getReferralCode(userData?.email, userData?.["custom:custom_username"]),
  //     );
  //   }
  // }, [dispatch, referralCode, userData, referralCodeLoading]);

  return (
    <MiniAppContainer>
      <div className={styles.title}>
        <h3>Home </h3>
        <MdMoreVert size={20} />
      </div>
      <MinimalCard customClassName={styles.profileBox}>
        <div className={styles.profileBoxLeftSide}>
          {/*<FaUserCircle size={35} color={"#0C359E"} />*/}
          <div className={styles.profileImage}>
            {profileData?.avatar_url ? (
              <img
                className={styles.profileImg}
                src={profileData?.avatar_url}
                alt="profile image"
              />
            ) : (
              <Avatar
                name={userData?.["custom:custom_username"]}
                size="100%"
                round={true}
              />
            )}
          </div>
          <div className={styles.profileBoxDetail}>
            <h3>
              <FaUser size={14} />
              <Tooltip title={userData?.["custom:custom_username"]}>
                {userData?.["custom:custom_username"]?.substring(0, 15)}
                {userData?.email &&
                  userData?.["custom:custom_username"]?.length > 15 &&
                  "..."}
              </Tooltip>
            </h3>
            <span className={styles.email}>
              <MdEmail />
              <Tooltip title={userData?.email}>
                {userData?.email?.substring(0, 15)}...
              </Tooltip>
            </span>

            <p className={styles.credit}>
              <FaRegCreditCard />
              Account Credit:{" "}
              <span>
                {minimalDashboardStatus === "loading" && "loading..."}
                {minimalDashboardStatus === "ready" &&
                  `$${formatPrice(minimalDashboardData?.credit / 100, 2)}`}
              </span>
            </p>

            <p className={styles.credit}>
              <FaMoneyBill />
              Earned Money:{" "}
              <span>
                {referralRewardsLoading && "loading"}
                {!referralRewardsLoading &&
                  referralData?.total_cashable_credit &&
                  `$${formatAmount(referralData?.total_cashable_credit)}`}
              </span>
              <WithdrawButton className={styles.withdrawBtn} />
            </p>

            <p className={styles.credit}>
              <MdSubscriptions />
              Subscription:{" "}
              <span>
                {getSubscriptionLoading && "loading..."}
                {!getSubscriptionLoading &&
                  `${
                    hasMonthlySubscription
                      ? `Monthly, ${subscriptionTimeLeft}`
                      : hasYearlySubscription
                      ? `Annually, ${subscriptionTimeLeft}`
                      : "No Subscription"
                  }`}
              </span>
            </p>
          </div>
        </div>
        <div
          className={styles.profileBoxRightSide}
          onClick={goToCreditChargePage}
        >
          <IoWalletOutline size={25} />
        </div>
      </MinimalCard>
      <div className={styles.links}>
        <MinimalCard
          customClassName={styles.link}
          clickHandler={() => navigate("/mini-ai-signals")}
        >
          <FaSignal size={20} />
          <span>AI Signals</span>
          <span
            className={`${styles.serviceActivationStatus} ${
              loading
                ? styles.loading
                : profileData?.myais_active !== "True"
                ? styles.notActive
                : styles.active
            }`}
          >
            {returnServiceActivationStatus(loading, profileData?.myais_active)}
          </span>
        </MinimalCard>
        <MinimalCard
          customClassName={styles.link}
          clickHandler={() => navigate("/mini-bots")}
        >
          <FaRobot size={20} />
          <span>Bot</span>
          <span
            className={`${styles.serviceActivationStatus} ${
              loading
                ? styles.loading
                : profileData?.mybot_active !== "True"
                ? styles.notActive
                : styles.active
            }`}
          >
            {returnServiceActivationStatus(loading, profileData?.mybot_active)}
          </span>
        </MinimalCard>
        <MinimalCard
          customClassName={styles.link}
          clickHandler={() => navigate("/mini-pricing-plan/referrals")}
        >
          <FaGift size={20} />
          <span>Referral</span>
        </MinimalCard>
      </div>
      <SectionDivider />
      <BotBanner />
      <SectionDivider />
      <AiSignalBanner />
      <SectionDivider />
      <ReferralBanner />
    </MiniAppContainer>
  );
}

export default MiniDashboard;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getBotStatistics } from "../../redux/bot/actions";
import BotSectionTitle from "./BotSectionTitle";
import { Tabs } from "antd";
import RenderTopBotReport from "./bot-configuration/top-bots/RenderTopBotReport";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineAccessTime } from "react-icons/md";
import { FaChartLine } from "react-icons/fa";

const { TabPane } = Tabs;

const tabTitleStyle = {
  display: "flex",
  alignItems: "center",
  gap: ".2rem",
  justifyContent: "center",
};

function BotPool({ goHome }) {
  const {
    main: { userData },
    botState: { botStatisticLoading, botStatisticData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("one_month");

  useEffect(() => {
    if (
      userData?.email &&
      !Object.keys(botStatisticData).length &&
      !botStatisticLoading
    ) {
      dispatch(
        getBotStatistics(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [userData, botStatisticData, botStatisticLoading]);

  return (
    <div>
      <BotSectionTitle title={"Bot Pool"}>
        {window.innerWidth > 600 ? "Choose a bot from the table below based on recent performances, or\n" +
            "        create a new bot from scratch." : ""}
      </BotSectionTitle>

      <Tabs
        type="card"
        activeKey={activeTab}
        style={{ marginTop: "3rem" }}
        onChange={(key) => setActiveTab(key)}
      >
        <TabPane
          tab={
            <span style={tabTitleStyle}>
              <MdOutlineAccessTime />
              Short-Term
            </span>
          }
          key={"one_month"}
        >
          <RenderTopBotReport goHome={goHome} duration={activeTab} />
        </TabPane>
        <TabPane
          tab={
            <span style={tabTitleStyle}>
              <FaChartLine />
              Medium-Term
            </span>
          }
          key={"two_months"}
        >
          <RenderTopBotReport goHome={goHome} duration={activeTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default BotPool;

// Styles
import { Button, Dropdown, Menu, Modal } from "antd";
import styles from "../../styles/pages/trading-assistant/index.module.sass";
import useDownloader from "react-use-downloader";
import { useDispatch, useSelector } from "react-redux";
import { GrDocumentCsv, GrPowerReset } from "react-icons/gr";
import { VscJson } from "react-icons/vsc";
import { TiWarningOutline } from "react-icons/ti";
import {
  getTradeHistory,
  resetHistory,
} from "../../redux/trading-assistant/actions";
import { FiRefreshCcw } from "react-icons/fi";
import TradeHistoryTable from "../tables/TradeHistoryTable";
import { TbFileExport } from "react-icons/tb";
import ShowActiveVirtualPortfolio from "./ShowActiveVirtualPortfolio";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import React, { useState } from "react";
import ComprehensiveHistoryTable from "../Integration/history-section/ComprehensiveHistoryTable";
import { MdKeyboardArrowDown } from "react-icons/md";

function History({ portfolio, section }) {
  const dispatch = useDispatch();
  const {
    main: { userData },
    tradingAssistant: {
      tradeHistory,
      tradeAssistant,
      resetHistoryLoading,
      getTradeHistoryLoading,
    },
  } = useSelector((state) => state);

  const [tableMode, setTableMode] = useState("positions");

  let { TRADE_HISTORY } = tradeHistory;

  const { export_links } = tradeAssistant;

  const { download } = useDownloader();

  const handleMenuClick = (e) => {
    if (e.key === "0" || e.key === "export-csv") {
      if (section === "trade-history") {
        download(
          tradeHistory?.export_links?.csv_report,
          `csv_report-${new Date().toLocaleString()}`,
        );
      } else {
        download(
          export_links?.csv_report,
          `csv_report-${new Date().toLocaleString()}`,
        );
      }
    }
    if (e.key === "1" || e.key === "export-json") {
      if (section === "trade-history") {
        download(
          export_links?.json_report,
          `json_report-${new Date().toLocaleString()}`,
        );
      } else {
        download(
          tradeHistory?.export_links?.json_report,
          `json_report-${new Date().toLocaleString()}`,
        );
      }
    }
  };

  const confirm = () => {
    if (!TRADE_HISTORY || !TRADE_HISTORY.length) return;
    Modal.confirm({
      title: "warning!",
      icon: <TiWarningOutline className={styles.warningIcon} />,
      centered: true,
      content:
        "Do you want to reset your trade history?\n Resetting your trade history will remove all trades and is non-reversible.",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        // dispatch resetHistory and wait for it to finish
        await dispatch(
          resetHistory(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            portfolio,
          ),
        ).then(() => {
          Modal.destroyAll();
        });
      },
    });
  };

  const resetHistoryHandler = () => {
    if (resetHistoryLoading) return;
    confirm();
  };

  const menu = (
    <Menu
      className={styles.MenuClassName}
      onClick={handleMenuClick}
      items={[
        {
          label: "CSV",
          key: "0",
          icon: <GrDocumentCsv />,
        },
        {
          label: "JSON",
          key: "1",
          icon: <VscJson />,
        },
      ]}
    />
  );

  const handleMoreOptionsClick = (e) => {
    if (e.key === "export-csv" || e.key === "export-json") {
      handleMenuClick(e);
    }
    if (e.key === "reset-history") {
      resetHistoryHandler();
    }
  };

  const moreOptionsMenu = (
    <Menu onClick={handleMoreOptionsClick}>
      <Menu.Item key="reset-history" icon={<GrPowerReset />}>
        Reset History
      </Menu.Item>
      <Menu.SubMenu key="export" icon={<TbFileExport />} title="Export">
        <Menu.Item key="export-csv" icon={<GrDocumentCsv />}>
          CSV
        </Menu.Item>
        <Menu.Item key="export-json" icon={<VscJson />}>
          JSON
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <div>
      <div className={styles.tradingHeader}>
        <div>
          <h3>
            <strong>Trade History</strong>
          </h3>
        </div>

        <ExchangesAndPaperTradingTab activeTab={"paper"} pageType={"history"} />
        <ShowActiveVirtualPortfolio
          section={"history"}
          style={{ marginTop: 0 }}
        />

        <div className={styles.btns}>
          <div
            className={styles.tableBtns}
            style={{ marginTop: 0, alignSelf: "flex-end" }}
          >
            <div className={styles.tableModeBtns}>
              <span
                className={`${styles.tableModeBtn} ${styles.positionBtn} ${tableMode === "positions" ? styles.selectedMode : ""}`}
                onClick={() => setTableMode("positions")}
              >
                Positions
              </span>
              <span
                className={`${styles.tableModeBtn} ${styles.orderBtn} ${tableMode === "orders" ? styles.selectedMode : ""}`}
                onClick={() => setTableMode("orders")}
              >
                Orders
              </span>
            </div>
            <Dropdown
              overlay={moreOptionsMenu}
              trigger={["click"]}
              className={styles.moreMenu}
            >
              <Button className={styles.moreBtn}>
                <span>
                  More <span className={styles.desktopVersion}>options</span>{" "}
                  <MdKeyboardArrowDown />
                </span>
              </Button>
            </Dropdown>

            <Dropdown
              overlay={menu}
              trigger={["click"]}
              className={styles.exportMenu}
            >
              <Button>
                <TbFileExport />
                <span className={styles.desktopVersion}>Export</span>
              </Button>
            </Dropdown>
            <Button
              disabled={!TRADE_HISTORY || !TRADE_HISTORY.length}
              style={{
                opacity: `${!TRADE_HISTORY || !TRADE_HISTORY.length ? 0.5 : 1}`,
              }}
              className={`${styles.resetBtn}`}
              onClick={resetHistoryHandler}
              loading={resetHistoryLoading}
            >
              {!resetHistoryLoading && <GrPowerReset />}
              Reset History
            </Button>

            <Button
              className={styles.resetHistory}
              loading={getTradeHistoryLoading}
              onClick={() => {
                if (getTradeHistoryLoading) return;
                dispatch(
                  getTradeHistory(
                    userData?.["cognito:username"],
                    userData?.["custom:custom_username"],
                    portfolio,
                  ),
                );
              }}
            >
              {!getTradeHistoryLoading && <FiRefreshCcw />}
              <span className={styles.desktopVersion}>Refresh</span>
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.portfolioTable}>
        {tableMode === "orders" && (
          <TradeHistoryTable
            data={TRADE_HISTORY}
            height={800}
            loading={getTradeHistoryLoading}
          />
        )}
        {tableMode === "positions" && (
          <ComprehensiveHistoryTable type="virtual" />
        )}
      </div>
    </div>
  );
}

export default History;

import React from 'react';
import Chart from "react-apexcharts";


const NegativeAreaChart = ({data}) => {
    const USAGE_STATISTICS = data?.USAGE_STATISTICS
    const options = {
        colors:['#58b5b5', 'rgba(0,64,253,0.63)', '#b382d3'],

        series: [
            {
            name: 'Daily usage of analytics',
            data: USAGE_STATISTICS?.DAILY_USAGE_OF_ANALYTICS?.map((item,index)=>({x:USAGE_STATISTICS?.TIME_STOMPS[index],y: item}))
        }, {
            name: 'Daily usage of free features',
            data: USAGE_STATISTICS?.DAILY_USAGE_OF_FREE_FEATURES?.map((item,index)=>({x:USAGE_STATISTICS?.TIME_STOMPS[index],y: item}))
        }, {
            name: 'Daily usage of pro features',
            data: USAGE_STATISTICS?.DAILY_USAGE_OF_PRO_FEATURES?.map((item,index)=>({x:USAGE_STATISTICS?.TIME_STOMPS[index],y: item}))
        }],
        chart: {
            type: 'area',
            height: 350
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },

        title: {
            text: 'Usage Statistics',
            align: 'left',
            style: {
                fontSize: '16px'
            }
        },
        xaxis: {
            type: 'datetime',
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            }
        },
        yaxis: {
            tickAmount: 0,
            floating: false,

            labels: {
                style: {
                    colors: '#8e8da4',
                },

                    formatter: function (val) {
                        return val.toFixed(0)
                    },

                offsetY: 5,
                offsetX: 0,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            }
        },
        fill: {

            opacity: 0.5,
            colors: ['#85b9ec']
        },
        tooltip: {
            x: {
                // format: "dd/MM/yy HH:mm",
                format: "dd/MM/yy",
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        },
        grid: {
            yaxis: {
                lines: {
                    offsetX: -30
                },


            },
            padding: {
                left: 20
            },
        }
    };

    return (data?.USAGE_STATISTICS ? <Chart options={options} series={options.series} type="area" height={380}
                   width={"100%"}/>:null);
};

export default NegativeAreaChart;

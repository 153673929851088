import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useStrategy } from "./useCreateStrategyContext";
import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyForm.module.css";
import StrategyLabels from "./StrategyLabels";
import StrategyTags from "./StrategyTags";
import { useDispatch, useSelector } from "react-redux";
import { createStrategyPack } from "../../../redux/new-insight/actions";
import { useShowCreateStrategyContext } from "./CreateStrategyModalV2";

function CreateStrategyForm() {
  const {
    main: { userData },
    newInsightState: { createStrategyStatus },
  } = useSelector((state) => state);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [isCustomTag, setIsCustomTag] = useState(false);
  const {
    setStrategyInfo,
    state: { strategyList },
    resetData,
  } = useStrategy();
  const closeModal = useShowCreateStrategyContext();

  const handleTagChange = (value) => {
    setIsCustomTag(value === "Custom");
    form.setFieldsValue({ strategy_tag: value === "Custom" ? "" : value });
  };
  const onFinish = async (values) => {
    const { strategy_name, strategy_label, strategy_tag } = values;
    const info = {
      strategy_name,
      strategy_label: strategy_label || "",
      strategy_tag: strategy_tag || "",
    };
    setStrategyInfo(info);
    await dispatch(
      createStrategyPack(
        userData["custom:custom_username"],
        userData?.email,
        "crypto",
        strategy_name,
        strategy_label,
        strategy_tag,
        strategyList,
      ),
    ).then(() => {
      resetData();
      closeModal();
    });
  };

  return (
    <div className={styles.container}>
      {/* Title */}
      <Typography.Title level={5}>Create Your Strategy</Typography.Title>

      <Form
        form={form}
        name="create_strategy"
        layout="vertical"
        onFinish={onFinish}
        className={styles.form}
      >
        {/* Strategy Name */}
        <Form.Item
          label="Name"
          name="strategy_name"
          rules={[
            { required: true, message: "Please enter the strategy name!" },
            { min: 3, message: "Strategy name must be at least 3 characters!" },
          ]}
        >
          <Input placeholder="Enter strategy name" />
        </Form.Item>

        {/* Strategy Label */}
        <Form.Item
          label="Label"
          name="strategy_label"
          rules={[{ required: false }]}
        >
          <StrategyLabels
            onChange={(value) => form.setFieldsValue({ strategy_label: value })}
          />
        </Form.Item>

        {/* Strategy Tag */}
        <Form.Item
          label="Tag"
          name="strategy_tag"
          rules={[{ required: false }]}
          className={styles.tagContainer}
        >
          <StrategyTags handleTagChange={handleTagChange} />

          {isCustomTag && (
            <Input
              placeholder="Enter custom tag"
              onChange={(e) =>
                form.setFieldsValue({ strategy_tag: e.target.value })
              }
            />
          )}
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            htmlType="submit"
            loading={createStrategyStatus === "loading"}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CreateStrategyForm;

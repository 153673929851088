// STYLES
import "../../styles/Components/faq/faqPublic.css";
import trade1 from "../../asset/Images/faq/AI Assistant/TradeHistory/1.PNG";
import trade2 from "../../asset/Images/faq/AI Assistant/TradeHistory/2.PNG";

// Images
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";
import in2 from "../../asset/Images/faq/Insights/HowToUseAISignals/2.png";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TradeHistorySection = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="helpTitle">Trade History Section</h3>
      <ul className="helpList">
        <p>
          In the Trade History section, you can see your past trades for each
          portfolio. Here's how you can use this section:
        </p>
        <li>
          To view your trade history, click on the "Trade History" in the
          right-hand menu.
        </li>
        <LazyLoadImage
          src={trade1}
          alt="trade history"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={trade1} alt="trade history" />*/}
        <li>To export your trade history, click on the "Export" button.</li>
        <LazyLoadImage
          src={trade2}
          alt="trade history"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={trade2} alt="trade history" />*/}
      </ul>
    </div>
  );
};

export default TradeHistorySection;

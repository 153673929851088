import { useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import styles from "../../../styles/Components/bot/bot-backtest/BotDropdownMenu.module.css";
import { IoCaretDown } from "react-icons/io5";

const BotDropdown = ({ onSelectBot }) => {
  const {
    botState: {
      botList,
      getBotListLoading,
      fetchedBotList,
      createBacktestLoading,
    },
  } = useSelector((state) => state);

  const handleSelectChange = (value) => {
    // Handle the selected value as needed
    onSelectBot(value.key);
  };

  const menu = (
    <Menu onClick={handleSelectChange} className={styles.menu}>
      {[...botList]
        .sort((a, b) => (b.bot_status === "active" ? 1 : -1))
        .slice(3)
        .map((bot) => (
          <Menu.Item key={bot.CreatedDate} style={{ fontsize: "14px" }}>
            {bot.bot_configurations.bot_name}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomCenter"
      trigger={["click"]}
      className={styles.dropdownMenu}
    >
      <Button>
        More <IoCaretDown />
      </Button>
    </Dropdown>
  );
};

export default BotDropdown;

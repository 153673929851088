// STYLES
import "../../styles/pages/Auth/auth.css";
import Logo from "../../shared/Logo";

const Text = function () {
  return (
    <div className="texts">
      {/*<h1 className='title'>Trade Better with <br /> Next Generation AI </h1>*/}
      <div>
        <Logo />
        <h1 style={{ color: "white", fontWeight: 500 }}>
          Unlock financial freedom <br /> with AI-powered trading <br />
          signals and automated <br /> bots.
        </h1>
      </div>
      {/*<div>*/}
      {/*    <h3>Highly Accurate</h3>*/}
      {/*    <p>Price prediction, insights and signals</p>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*    <h3>Advanced Tools</h3>*/}
      {/*    <p>AI assistant, alerts and trade simulator</p>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*    <h3>Easy API Integration</h3>*/}
      {/*    <p>Seamless access within your best practice</p>*/}
      {/*</div>*/}
    </div>
  );
};

export default Text;

import React from "react";
import styles from "../../../styles/shared/trade-modal/LimitOrderResultBox.module.css";
import Loader from "./Loader";
import { IoIosCloseCircle } from "react-icons/io";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import ShowRecommendation from "./ShowRecommendation";

function LimitOrderResultBox({ loading, title, errorData, successData }) {
  return (
    <div className={styles.resultBox}>
      <h3>{title} result:</h3>
      {loading ? (
        <Loader text={"Loading"} />
      ) : errorData ? (
        <div className={styles.errorBox}>
          <IoIosCloseCircle color={"#e74c3c"} size={25} />
          <h4>{errorData.error}</h4>
          <p>{errorData.description}</p>
        </div>
      ) : (
        Object.keys(successData).length > 0 && (
          <div className={styles.successBox}>
            <FaCheckCircle color={"#1abc9c"} size={25} />
            <p style={{ textAlign: "center", color: "rgba(0,0,0,.85)" }}>
              Congratulations, your order trade has been successfully done.
            </p>
          </div>
          //   <ShowRecommendation showCongrateMsg setShow={}/>
        )
      )}
    </div>
  );
}

export default LimitOrderResultBox;

import styles from "../../../styles/Components/pricing-plan/referrals/WithdrawButton.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, InputNumber, message, Modal } from "antd";
import { useState } from "react";
import { requestWithdraw } from "../../../redux/pricing-plan/action";

function WithdrawButton({ className, style }) {
  const {
    pricingPlan: { referralData, withdrawStatus },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [requestedMoneyAmount, setRequestedMoneyAmount] = useState(60);

  const totalMoney = referralData?.total_cashable_credit;

  const enableWithdraw = totalMoney >= 60;

  const handleWithdraw = () => {
    if (!enableWithdraw)
      return message.info(
        "You need to earn at least $60 to withdraw your money!",
      );
    setShowModal(true);
  };

  const handleCancel = () => {
    setRequestedMoneyAmount(60);
    setShowModal(false);
  };

  const requestWithdrawHandler = async () => {
    if (!requestedMoneyAmount || requestedMoneyAmount < 60) return;

    await dispatch(
      requestWithdraw(
        userData?.email,
        userData?.["custom:custom_username"],
        requestedMoneyAmount,
      ),
    ).then(() => {
      handleCancel();
    });
  };

  return (
    <>
      <span
        className={`${styles.withdrawBtn} ${
          !enableWithdraw ? styles.disabled : ""
        } ${className || ""}`}
        style={style || {}}
        onClick={handleWithdraw}
      >
        Withdraw
      </span>
      {showModal && (
        <Modal
          title="Withdraw Money"
          open={showModal}
          onCancel={handleCancel}
          closable={withdrawStatus !== "loading"}
          maskClosable={false}
          footer={null}
          centered
        >
          <Form layout="vertical" onFinish={requestWithdrawHandler}>
            <Form.Item
              label="How much of your earned money do you want to withdraw?"
              name="amount"
              rules={[{ required: true, message: "Please enter an amount" }]}
            >
              <InputNumber
                min={60}
                max={totalMoney}
                style={{ width: "100%" }}
                value={requestedMoneyAmount}
                onChange={(val) => console.log(val)}
              />
            </Form.Item>
            <Form.Item>
              <p>
                <strong>NOTE:</strong> The minimum amount is $60 and the maximum
                is ${totalMoney.toFixed(2)}.
              </p>
            </Form.Item>
            <Form.Item className={styles.submitBtnBox}>
              <Button
                type="primary"
                htmlType="submit"
                loading={withdrawStatus === "loading"}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default WithdrawButton;

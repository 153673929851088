import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBotStatistics } from "../../../redux/bot/actions";
import { Tabs } from "antd";
import { MdOutlineAccessTime } from "react-icons/md";
import TabPane from "antd/lib/tabs/TabPane";
import { FaChartLine } from "react-icons/fa";
import MiniAppTopBots from "./MiniAppTopBots";
import MiniAppContainer from "../MiniAppContainer";
import MiniAppHeader from "../layout/MiniAppHeader";
import { FiArrowLeftCircle } from "react-icons/fi";
import styles from "../../../styles/Components/mini-app/mini-bots/CreateBotProcess.module.css";

function CreateBotProcess() {
  const {
    main: { userData },
    botState: { botStatisticLoading, botStatisticData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("one_month");

  const goBack = () => {
    navigate("/mini-bots");
  };

  useEffect(() => {
    if (
      userData?.email &&
      !Object.keys(botStatisticData).length &&
      !botStatisticLoading
    ) {
      dispatch(
        getBotStatistics(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [userData, botStatisticData, botStatisticLoading]);

  return (
    <MiniAppContainer>
      <MiniAppHeader page={"Create Bot"} />
      <FiArrowLeftCircle
        className={styles.backBtn}
        onClick={goBack}
        size={25}
      />
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        className={styles.tabs}
      >
        <TabPane
          tab={
            <span className={styles.tabTitle}>
              <MdOutlineAccessTime />
              Short-Term
            </span>
          }
          key={"one_month"}
        >
          <MiniAppTopBots type={activeTab} />
        </TabPane>

        <TabPane
          tab={
            <span className={styles.tabTitle}>
              <FaChartLine />
              Medium-Term
            </span>
          }
          key={"two_months"}
        >
          <MiniAppTopBots type={activeTab} />
        </TabPane>
      </Tabs>
    </MiniAppContainer>
  );
}

export default CreateBotProcess;

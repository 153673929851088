import Modal from "antd/lib/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import styles from "../../styles/shared/connect-exchange/LinkToVirtual.module.css";
import { Badge, Button, message } from "antd";
import { linkWithExchange } from "../../redux/exchange/actions";

function LinkToVirtualModal({ show, setShow, onClose }) {
  const {
    exchangeState: { exchangeForConnection, linkWithExchangeLoading },
    profile: { profileData },
    main: { userData },
  } = useSelector((state) => state);

  const [step, setStep] = useState(1);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  // const isLink = linkWithExchangeMessage && !linkWithExchangeError;

  const dispatch = useDispatch();

  const closeHandler = () => {
    if (linkWithExchangeLoading) return;
    onClose();
  };

  const handleSelectPortfolio = (type) => {
    if (
      (type === "secondary" && isSecondPortfolioConnected) ||
      (type === "primary" && isFirstPortfolioConnected)
    ) {
      return;
    }

    setSelectedPortfolio(type);
  };

  const submitHandler = async () => {
    if (linkWithExchangeLoading) return;
    if (!selectedPortfolio)
      return message.info("Please select a virtual portfolio.");

    await dispatch(
      linkWithExchange(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        exchangeForConnection,
        selectedPortfolio,
      ),
    ).then(() => {
      onClose();
    });
  };

  // useEffect(() => {
  //   console.log("check", isLink);
  //   if (isLink) {
  //     onClose();
  //   }
  // }, [isLink]);

  return (
    <Modal
      centered
      footer={null}
      open={show}
      onCancel={closeHandler}
      closable={!linkWithExchangeLoading}
      width={420}
      bodyStyle={{
        position: "relative",
        minHeight: `${step === 1 ? "150px" : "220px"}`,
        overflow: "hidden",
      }}
    >
      <div
        className={`${step === 1 ? styles.visible : styles.hide} ${
          styles.step
        }`}
      >
        <h3>Do you want to link it with virtual portfolio?</h3>
        <div className={styles.btns}>
          <Button className={`${styles.btn}`} onClick={onClose}>
            Close
          </Button>
          <Button
            className={`${styles.btn}`}
            type={"primary"}
            onClick={() => setStep(2)}
          >
            Yes
          </Button>
        </div>
      </div>

      <div
        className={`${step === 2 ? styles.visible : styles.hide} ${
          styles.step
        }`}
      >
        <h3>Select the portfolio that you want to link it:</h3>
        <ul className={styles.btns}>
          <li
            className={`${styles.portfolioItem} ${
              isFirstPortfolioConnected ? styles.disable : ""
            } ${
              selectedPortfolio === "primary" ? styles.selectedPortfolio : ""
            }`}
            onClick={() => handleSelectPortfolio("primary")}
          >
            {isFirstPortfolioConnected ? (
              <Badge
                count={profileData?.portfolio1_linked_exchange}
                size={"small"}
                status={"default"}
                className={styles.badge}
              >
                First
              </Badge>
            ) : (
              <span>First</span>
            )}
          </li>

          <li
            className={`${styles.portfolioItem} ${
              isSecondPortfolioConnected ? styles.disable : ""
            } ${
              selectedPortfolio === "secondary" ? styles.selectedPortfolio : ""
            }`}
            onClick={() => handleSelectPortfolio("secondary")}
          >
            {isSecondPortfolioConnected ? (
              <Badge
                count={profileData?.portfolio2_linked_exchange}
                size={"small"}
                status={"default"}
                className={styles.badge}
              >
                Second
              </Badge>
            ) : (
              <span>Second</span>
            )}
          </li>
        </ul>

        <Button
          onClick={submitHandler}
          className={`${styles.submitBtn} ${
            !selectedPortfolio ? styles.disable : ""
          }`}
          loading={linkWithExchangeLoading}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default LinkToVirtualModal;

import {
  GENERATE_API_KEY_ERROR,
  GENERATE_API_KEY_REQUEST,
  GENERATE_API_KEY_SUCCESS,
  GET_DEVELOPER_API_DATA_ERROR,
  GET_DEVELOPER_API_DATA_REQUEST,
  GET_DEVELOPER_API_DATA_SUCCESS,
} from "./consts";

const getDeveloperApiDataInitialState = {
  loading: false,
  data: {},
  error: "",
};
export const getDeveloperApiDataReducer = (
  state = getDeveloperApiDataInitialState,
  action,
) => {
  switch (action?.type) {
    case GET_DEVELOPER_API_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEVELOPER_API_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload,
      };
    case GET_DEVELOPER_API_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    default:
      return state;
  }
};

const generateApiKeyInitialState = {
  loading: false,
  data: [],
  error: "",
};
export const generateApiKeyReducer = (
  state = generateApiKeyInitialState,
  action,
) => {
  switch (action?.type) {
    case GENERATE_API_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_API_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload,
      };
    case GENERATE_API_KEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    default:
      return state;
  }
};

import {GET_MINIMAL_DASHBOARD_ERROR, GET_MINIMAL_DASHBOARD_REQUEST, GET_MINIMAL_DASHBOARD_SUCCESS} from "./consts";
import axios from "axios";
import {axiosErrorHandler} from "../../utility/action-helper";

export const getMinimalDashboardData = (user, email, retryCount = 0) => async (dispatch) => {
    dispatch({type: GET_MINIMAL_DASHBOARD_REQUEST});

    await axios.post(
        "https://ruf0xnvqk9.execute-api.us-east-1.amazonaws.com/dev/api",
        {
            platform_mode: "minimal_dashboard",
            user,
            email,
            number_of_news: 10,
            stock_country: "australia",
        },
    ).then(res => {
        dispatch({type: GET_MINIMAL_DASHBOARD_SUCCESS, payload: res?.data})
    }).catch(err => {
        axiosErrorHandler(
            err,
            retryCount,
            dispatch,
            () =>
                getMinimalDashboardData(
                    email,
                    user,
                    retryCount + 1,
                ),
            GET_MINIMAL_DASHBOARD_ERROR,
            user,
            email,
            "minimal_dashboard",
        );
    })
};

import styles from "../../../styles/Components/bot/bot-reports/BotActivityTableExpandedRow.module.css";
import { riskAppetiteColor, riskOptions } from "../../../utility/data";
import { formatCustomDate } from "../../../utility/helper";

function BotActivityTableExpandedRow({ data }) {
  return (
    <div className={styles.container}>
      <ul className={styles.dataList}>
        <li>
          <span className={styles.label}>Exchange:</span>
          <span style={{ display: "flex", alignItems: "center", gap: ".2rem" }}>
            <img
              className={styles.exchangeIcon}
              src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${data?.info?.exchange_id}.svg`}
              alt={data?.info?.exchange_id}
            />
            {data?.info?.exchange_id}
          </span>
        </li>
        <li>
          <span className={styles.label}>Risk Appetite:</span>
          <span style={{ color: riskAppetiteColor[data?.info?.risk_apetite] }}>
            {riskOptions[data?.info?.risk_apetite]}
          </span>
        </li>
        <li>
          <span className={styles.label}>Successful Trades:</span>
          <span>{data?.info?.num_successful_trades}</span>
        </li>
        <li>
          <span className={styles.label}>Failed Trades:</span>
          <span>{data?.info?.num_failed_trades}</span>
        </li>
        <li>
          <span className={styles.label}>Open Positions:</span>
          <span>{data?.info?.total_open_positions}</span>
        </li>
        {data?.info?.open_positions_coins?.length > 0 && (
          <li>
            <span className={styles.label}>Open Position Coins:</span>
            <span>{data?.info?.open_positions_coins.join(", ")}</span>
          </li>
        )}
        <li className={styles.status}>
          <span className={styles.label}>Status:</span>
          <span
            className={`${
              data?.status === "Active" ? styles.active : styles.deactivate
            }`}
          >
            {data?.status}
          </span>
        </li>
        <li className={styles.startDate}>
          <span className={styles.label}>Start Date:</span>
          <span>
            {data?.startDate ? formatCustomDate(data?.startDate) : "-"}
          </span>
        </li>
        <li className={styles.status}>
          <span className={styles.label}>End Date:</span>
          <span>{data?.endDate ? formatCustomDate(data?.endDate) : "-"}</span>
        </li>
      </ul>
    </div>
  );
}

export default BotActivityTableExpandedRow;

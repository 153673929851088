import styles from "../../../../styles/Components/bot/bot-configuration/bot-pool/BotPoolTableExpandedRow.module.css";
import { formatAIMode } from "../../../../utility/helper";

function BotPoolTableExpandedRow({ data }) {
  const { copyBot: info } = data;
  return (
    <div className={styles.container}>
      {/*{console.log(data)}*/}
      <div className={styles.infoBox}>
        <span className={styles.label}>Signal Source:</span>
        <span className={styles.description}>
            {info?.signal_mode?.replaceAll("_", " ")}
          {info?.signal_mode === "AI_Assistant" && (
              <span className={styles.aiMode}>
                ({info?.ai_assistant_mode === "default"
                    ? "Default"
                    : formatAIMode[info?.ai_assistant_mode]})
              </span>
          )}
          </span>
      </div>
      <div className={styles.infoBox}>
        <span className={styles.label}>Exchange:</span>
        <span className={styles.description}>
            {info?.exchange_name}
            <img className={styles.exchangeIcon} src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${info?.exchange_id}.svg`} alt={info?.exchange_id}/>
          </span>
      </div>
    </div>
  );
}

export default BotPoolTableExpandedRow;

import styles from "../../styles/shared/exchanges/ExchangeRecommendationModal.module.css";
import { Modal } from "antd";
import icon from "../../asset/Icons/question.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setExchangeForConnection,
  setPreviousPath,
  setSelectedPortfolioExchange,
} from "../../redux/exchange/actions";

function ExchangeRecommendationModal({ show, setShow, exchange }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const goToSettings = () => {
    navigate(`/integrate-exchange/${exchange.name}`);
    dispatch(setPreviousPath(location.pathname));
    dispatch(setExchangeForConnection(exchange.id));
  };

  const goToPortfolio = () => {
    dispatch(setSelectedPortfolioExchange(exchange.id));
    navigate("/integration/portfolio");
  };

  return (
    <Modal
      open={show}
      onCancel={() => setShow(false)}
      centered
      footer={null}
      maskClosable={false}
      width={450}
    >
      <div className={styles.container}>
        <img src={icon} alt={"question"} />
        <h3 className={styles.title}>
          What would you like to do next?
          <br />
          See your portfolio or tweak the settings?
        </h3>
        <div className={styles.btns}>
          <span className={styles.portfolioBtn} onClick={goToPortfolio}>
            See your portfolio
          </span>
          <span className={styles.settingBtn} onClick={goToSettings}>
            Tweak the settings
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default ExchangeRecommendationModal;

import { Button, message } from "antd";
import styles from "../../../styles/Components/pricing-plan/billing/SubscriptionActionButton.module.css";
import { MdOutlineCancel } from "react-icons/md";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useState } from "react";
import CancelSubscriptionModal from "../../../pages/pricing-plan/CancelSubscriptionModal";
import Chat from "../../../pages/pricing-plan/Chat";

const calcRemainingTimeOfYearlySubscription = (endDate) => {
  const endTime = moment.unix(endDate);
  const now = moment();
  const diff = endTime.diff(now, "months");
  return diff > 2;
};

const calcLastChatDiff = (date) => {
  if (date) {
    const now = moment.utc();
    const diff = now.diff(date, "hours");
    return diff < 24;
  }
};

function CancelSubscriptionButton({
  isActive,
  subscription,
  subscriptionType,
}) {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const isProPlan = subscriptionType.includes("pro");

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showChat, setShowChat] = useState(false);

  // current_period_end
  const handleCancel = () => {
    if (!isActive) {
      return message.info("This subscription has already been canceled.");
    }
    // check if subscription is yearly type and there is two or more months
    if (
      subscriptionType.includes("yearly") &&
      calcRemainingTimeOfYearlySubscription(subscription?.current_period_end)
    ) {
      return message.info(
        "You still have plenty of time left in this period. Please come back later when it's closer to the end.",
        10,
      );
    }
    // check has chat in past 24 hours or not
    if (
      (!isProPlan && calcLastChatDiff(profileData?.last_cancel_chat_date)) ||
      (isProPlan && calcLastChatDiff(profileData?.last_cancel_pro_chat_date))
    ) {
      return message.info(
        "We will get in touch with you within 24 hours. After that, you can cancel again if you want.",
        10,
      );
    }

    setShowCancelModal(true);
  };

  return (
    <>
      <Button
        className={`${styles.cancelBtn} ${styles.btn} ${
          !isActive ? styles.disabled : ""
        }`}
        onClick={handleCancel}
      >
        <MdOutlineCancel /> Cancel
      </Button>
      {showCancelModal && (
        <CancelSubscriptionModal
          show={showCancelModal}
          // show={true}
          setShow={setShowCancelModal}
          showChat={setShowChat}
          subscriptionType={subscriptionType}
        />
      )}
      {showChat && (
        <Chat
          show={showChat}
          close={setShowChat}
          planType={isProPlan ? "Pro" : "Basic"}
        />
      )}
    </>
  );
}

export default CancelSubscriptionButton;

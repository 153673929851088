import React from "react";
import Chart from "react-apexcharts";

const BotPerformanceChart = ({ balanceLists, balanceDateList }) => {
  const chartData = balanceDateList?.map((date, index) => ({
    x: new Date(date).getTime(),
    y: Math.trunc(balanceLists[index]),
  }));

  const series = [
    {
      name: "Profit (%)",
      data: chartData,
    },
  ];

  const options = {
    chart: {
      id: "balance-chart",
      type: "line",
    },
    xaxis: {
      type: "datetime",
    },
    // yaxis: {
    //   title: {
    //     text: "Balance",
    //   },
    // },
    title: {
      text: "NAV Profit (%)",
      align: "center",
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="400"
    />
  );
};

export default BotPerformanceChart;

import styles from "../../styles/Components/errors/ErrorComponent.module.css";
import errorImage from "../../asset/Images/error.svg";

function ErrorComponent() {
  const refreshHandler = () => window.location.reload();

  return (
    <div className={styles.container}>
      <img src={errorImage} alt={"error"} />
      <h3>
        Dear user,
        <br />
        We apologize for the inconvenience. We're currently facing some issues
        that need our attention. <br />
        Please check back later. Your patience and understanding are greatly
        appreciated. Thank you!
      </h3>
      <span className={styles.refreshBtn} onClick={refreshHandler}>
        Refresh
      </span>
    </div>
  );
}

export default ErrorComponent;

import axios from "axios";
import {
  CHECK_EXCHANGE_IP_ERROR,
  CHECK_EXCHANGE_IP_REQUEST,
  CHECK_EXCHANGE_IP_SUCCESS,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS,
  EXCHANGE_TRADE_HISTORY_ERROR,
  EXCHANGE_TRADE_HISTORY_REQUEST,
  EXCHANGE_TRADE_HISTORY_SUCCESS,
  GET_ASSETS_ERROR,
  GET_ASSETS_REQUEST,
  GET_ASSETS_SUCCESS,
  GET_CONNECTION_INFO_ERROR,
  GET_CONNECTION_INFO_REQUEST,
  GET_CONNECTION_INFO_SUCCESS,
  GET_EXCHANGE_BALANCE_CHART_ERROR,
  GET_EXCHANGE_BALANCE_CHART_REQUEST,
  GET_EXCHANGE_BALANCE_CHART_SUCCESS,
  GET_EXCHANGE_PORTFOLIO_ERROR,
  GET_EXCHANGE_PORTFOLIO_FAST_ERROR,
  GET_EXCHANGE_PORTFOLIO_FAST_REQUEST,
  GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS,
  GET_EXCHANGE_PORTFOLIO_REQUEST,
  GET_EXCHANGE_PORTFOLIO_SUCCESS,
  GET_EXCHANGE_PRICE_ERROR,
  GET_EXCHANGE_PRICE_REQUEST,
  GET_EXCHANGE_PRICE_SUCCESS,
  GET_EXCHANGES_INFO_ERROR,
  GET_EXCHANGES_INFO_REQUEST,
  GET_EXCHANGES_INFO_SUCCESS,
  GET_INTEGRATED_EXCHANGES_ERROR,
  GET_INTEGRATED_EXCHANGES_REQUEST,
  GET_INTEGRATED_EXCHANGES_SUCCESS,
  GET_TRUSTED_IPS_ERROR,
  GET_TRUSTED_IPS_REQUEST,
  GET_TRUSTED_IPS_SUCCESS,
  INTEGRATE_EXCHANGE_ERROR,
  INTEGRATE_EXCHANGE_REQUEST,
  INTEGRATE_EXCHANGE_SUCCESS,
  LINK_WITH_EXCHANGE_ERROR,
  LINK_WITH_EXCHANGE_REQUEST,
  LINK_WITH_EXCHANGE_SUCCESS,
  ORDER_TRADE_ERROR,
  ORDER_TRADE_REQUEST,
  ORDER_TRADE_SUCCESS,
  SET_EXCHANGE_FOR_CONNECTION,
  SET_PREVIOUS_PATH,
  SET_SELECTED_PORTFOLIO_EXCHANGE,
  UNLINK_FROM_EXCHANGE_ERROR,
  UNLINK_FROM_EXCHANGE_REQUEST,
  UNLINK_FROM_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_HISTORY_SUCCESS,
  UPDATE_EXCHANGE_PORTFOLIO_DATA,
} from "./consts";
import { errorHandler, reorderArray } from "../../utility/helper";
import alert from "../../components/alerts/Alert";
import { getProfileData, updateProfileAttribute } from "../profile/actions";
import {
  setVirtualPortfolio,
  updateAIPortfolioData,
} from "../trading-assistant/actions";
import { formatAssetNameForTrade } from "../../utility/tradeHelper";
import { axiosErrorHandler } from "../../utility/action-helper";

const baseUrl =
  "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api";

export const getExchangesInfo = (retryCount) => async (dispatch) => {
  dispatch({ type: GET_EXCHANGES_INFO_REQUEST });
  await axios
    .post(
      baseUrl,
      // { mode: "get_exchanges_info" }
      { mode: "get_exchanges_info_and_connections" },
    )
    .then((res) => {
      dispatch({ type: GET_EXCHANGES_INFO_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => getExchangesInfo(retryCount + 1),
        GET_EXCHANGES_INFO_ERROR,
        "user",
        "email",
        "get_exchanges_info_and_connections",
      );
    });
};

export const integrateWithExchange =
  (
    user,
    email,
    apiKey,
    secretKey,
    exchange_id,
    passphrase,
    restricted_ip,
    name,
    showAlert = true,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: INTEGRATE_EXCHANGE_REQUEST });
    await axios
      .post(baseUrl, {
        mode: "integrate_with_exchange",
        user,
        email,
        apiKey,
        secretKey,
        exchange_id,
        passphrase,
        // restricted_ip: restricted ? "3.27.44.237" : "",
        restricted_ip,
      })
      .then((res) => {
        dispatch({ type: INTEGRATE_EXCHANGE_SUCCESS, payload: res.data });
        // You have successfully integrated with

        if (showAlert) {
          alert(null, res.data?.MESSAGE, "success", {
            cancel: "Ok",
          });
        }
        // dispatch(getIntegratedExchanges(user, email));
        dispatch(updateIntegratedData(exchange_id, name));
        // notify(res.data.message, "success");
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            integrateWithExchange(
              user,
              email,
              apiKey,
              secretKey,
              exchange_id,
              passphrase,
              restricted_ip,
              name,
              showAlert,
              retryCount + 1,
            ),
          INTEGRATE_EXCHANGE_ERROR,
          user,
          email,
          "integrate_with_exchange",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // dispatch({ type: INTEGRATE_EXCHANGE_ERROR, payload: MESSAGE });
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const updateIntegratedData = (exchangeId, name) => (dispatch) => {
  dispatch({ type: "update-integrated-data", payload: { exchangeId, name } });
};

export const clearIntegrationProcessData = () => (dispatch) => {
  dispatch({ type: "clear-integration-process-data" });
};

export const getIntegratedExchanges =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    // dispatch(getTrustedIps());
    dispatch({ type: GET_INTEGRATED_EXCHANGES_REQUEST });
    await axios
      .post(baseUrl, { mode: "get_integrated_exchange", user, email })
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_INTEGRATED_EXCHANGES_SUCCESS,
          payload: reorderArray(res.data),
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getIntegratedExchanges(user, email, retryCount + 1),
          GET_INTEGRATED_EXCHANGES_ERROR,
          user,
          email,
          "get_integrated_exchange",
        );
      });
  };

export const checkExchangeStatus =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: CHECK_EXCHANGE_IP_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "get_integrated_exchange",
          user,
          email,
          exchange_id,
        },
      )
      .then((res) => {
        // console.log(res);
        dispatch({ type: CHECK_EXCHANGE_IP_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => checkExchangeStatus(user, email, exchange_id, retryCount + 1),
          CHECK_EXCHANGE_IP_ERROR,
          user,
          email,
          "get_integrated_exchange",
        );
      });
  };

export const resetExchangeStatusData = () => (dispatch) => {
  dispatch({ type: "reset-exchange-status" });
};

export const getExchangeTradeHistory =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: EXCHANGE_TRADE_HISTORY_REQUEST });

    const data = {
      mode: "get_exchange_trade_history",
      user,
      email,
      exchange_id,
    };

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({ type: EXCHANGE_TRADE_HISTORY_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getExchangeTradeHistory(user, email, exchange_id, retryCount + 1),
          EXCHANGE_TRADE_HISTORY_ERROR,
          user,
          email,
          "get_exchange_trade_history",
        );
      });
  };

export const getExchangeComprehensiveTradeHistory =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST });

    const data = {
      mode: "get_exchange_trade_history_comphrensive",
      user,
      email,
      exchange_id,
    };

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getExchangeComprehensiveTradeHistory(
              user,
              email,
              exchange_id,
              retryCount + 1,
            ),
          EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR,
          user,
          email,
          "get_exchange_trade_history_comphrensive",
        );
      });
  };

export const updateExchangeHistory =
  (user, email, exchange_id, last_trade_history_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: EXCHANGE_TRADE_HISTORY_REQUEST });

    await axios
      .post(baseUrl, {
        mode: "get_exchange_trade_history_comphrensive",
        user,
        email,
        exchange_id,
        last_trade_history_date,
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: UPDATE_EXCHANGE_HISTORY_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            updateExchangeHistory(
              user,
              email,
              exchange_id,
              last_trade_history_date,
              retryCount + 1,
            ),
          EXCHANGE_TRADE_HISTORY_ERROR,
          user,
          email,
          "get_exchange_trade_history",
        );
      });
  };

export const orderTrade =
  (
    user,
    email,
    exchange_id,
    asset,
    trade_type,
    amount,
    trigger_price,
    freeze,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: ORDER_TRADE_REQUEST });

    await axios
      .post(baseUrl, {
        mode: "order_trade",
        user,
        email,
        exchange_id,
        asset: formatAssetNameForTrade(asset, exchange_id),
        trade_type,
        amount: `${amount}`,
        trigger_price: `${trigger_price}`,
        currency: "USDT",
        freeze_limit_order: freeze ? "True" : "False",
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: ORDER_TRADE_SUCCESS, payload: res.data });
          dispatch(getExchangePortfolio(user, email, exchange_id));
          dispatch(setSelectedPortfolioExchange(exchange_id));
        } else {
          dispatch({ type: ORDER_TRADE_ERROR, payload: res.response.data });
        }
      })
      .catch((err) => {
        if (err.response.status === 421) {
          dispatch({ type: ORDER_TRADE_ERROR, payload: err.response.data });
          return;
        }
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            orderTrade(
              user,
              email,
              exchange_id,
              asset,
              trade_type,
              amount,
              trigger_price,
              freeze,
              retryCount + 1,
            ),
          ORDER_TRADE_ERROR,
          user,
          email,
          "order_trade",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // if (MESSAGE) {
        //   dispatch({ type: ORDER_TRADE_ERROR, payload: MESSAGE });
        //   errorHandler(STATUS_CODE, MESSAGE);
        // }
        // if (err.response.status === 421) {
        //   dispatch({ type: ORDER_TRADE_ERROR, payload: err.response.data });
        // }
      });
  };

export const getExchangePrice =
  (user, email, exchange_id, assets, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_EXCHANGE_PRICE_REQUEST });
    await axios
      .post(baseUrl, {
        mode: "get_prices",
        user,
        email,
        exchange_id,
        assets,
      })
      .then((res) => {
        dispatch({ type: GET_EXCHANGE_PRICE_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getExchangePrice(user, email, exchange_id, assets, retryCount + 1),
          GET_EXCHANGE_PRICE_ERROR,
          user,
          email,
          "get_prices",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // dispatch({ type: GET_EXCHANGE_PRICE_ERROR, payload: MESSAGE });
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getExchangePortfolio =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_EXCHANGE_PORTFOLIO_REQUEST });

    const data = {
      mode: "get_portfolio",
      user,
      email,
      exchange_id,
    };

    if (retryCount > 0) {
      data.use_more_caching = "True";
    }

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: GET_EXCHANGE_PORTFOLIO_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getExchangePortfolio(user, email, exchange_id, retryCount + 1),
          GET_EXCHANGE_PORTFOLIO_ERROR,
          user,
          email,
          "get_portfolio",
        );
      });
  };

export const getPortfolioFast =
  (user, email, exchange_id) => async (dispatch) => {
    dispatch({ type: GET_EXCHANGE_PORTFOLIO_FAST_REQUEST });
    await axios
      .post(baseUrl, {
        mode: "get_portfolio",
        user,
        email,
        exchange_id,
        usd_balance_calculation: "False",
      })
      .then((res) => {
        dispatch({
          type: GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        const { STATUS_CODE, MESSAGE } = err?.response?.data;
        if (STATUS_CODE === 454) {
          dispatch(getExchangePortfolio(user, email, exchange_id));
        } else {
          dispatch({
            type: GET_EXCHANGE_PORTFOLIO_FAST_ERROR,
            payload: MESSAGE,
          });
          errorHandler(STATUS_CODE, MESSAGE);
        }
      });
  };

export const updateExchangePortfolio = (newPortfolio) => {
  return { type: UPDATE_EXCHANGE_PORTFOLIO_DATA, payload: newPortfolio };
};

export const getAssetList =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_ASSETS_REQUEST });

    await axios
      .post(baseUrl, {
        mode: "get_asset_list",
        user,
        email,
        exchange_id,
      })
      .then((res) => {
        dispatch({ type: GET_ASSETS_SUCCESS, payload: res.data.asset_list });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getAssetList(user, email, exchange_id, retryCount + 1),
          GET_ASSETS_ERROR,
          user,
          email,
          "get_asset_list",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // dispatch({ type: GET_ASSETS_ERROR, payload: MESSAGE });
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getExchangeConnectionInfo = (exchange_id) => async (dispatch) => {
  dispatch({ type: GET_CONNECTION_INFO_REQUEST });
  await axios
    .post(baseUrl, {
      mode: "get_exchange_connection_info",
      exchange_id,
    })
    .then((res) => {
      dispatch({ type: GET_CONNECTION_INFO_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      const { STATUS_CODE, MESSAGE } = err?.response.data;
      dispatch({ type: GET_CONNECTION_INFO_ERROR, payload: MESSAGE });
      errorHandler(STATUS_CODE, MESSAGE);
    });
};

export const setPreviousPath = (path) => {
  return { type: SET_PREVIOUS_PATH, payload: path };
};

export const setSelectedPortfolioExchange = (exchange) => {
  return { type: SET_SELECTED_PORTFOLIO_EXCHANGE, payload: exchange };
};

export const setExchangeForConnection = (exchange) => {
  return { type: SET_EXCHANGE_FOR_CONNECTION, payload: exchange };
};

export const linkWithExchange =
  (email, user, exchange_id, portfolio_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: LINK_WITH_EXCHANGE_REQUEST });

    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          request_type: "link_exchange_to_portfolio",
          email,
          user,
          exchange_id,
          portfolio_type,
        },
      )
      .then((res) => {
        const { MESSAGE, STATUS_CODE } = res?.data;
        dispatch({
          type: LINK_WITH_EXCHANGE_SUCCESS,
          payload: MESSAGE,
        });

        const profileAttribute =
          portfolio_type === "primary"
            ? "portfolio1_linked_exchange"
            : "portfolio2_linked_exchange";

        dispatch(updateAIPortfolioData(res?.data));
        dispatch(setVirtualPortfolio(portfolio_type));
        dispatch(updateProfileAttribute(profileAttribute, exchange_id));
        dispatch(getProfileData(email, user));

        alert(null, res?.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        if (err?.code === "ERR_NETWORK") {
          dispatch({
            type: LINK_WITH_EXCHANGE_SUCCESS,
            payload: "The portfolio is linked with exchange successfuly",
          });
          const profileAttribute =
            portfolio_type === "primary"
              ? "portfolio1_linked_exchange"
              : "portfolio2_linked_exchange";

          dispatch(
            updateAIPortfolioData({
              STATUS_CODE: 200,
              MESSAGE: "The portfolio is linked with exchange successfuly",
            }),
          );
          dispatch(setVirtualPortfolio(portfolio_type));
          dispatch(updateProfileAttribute(profileAttribute, exchange_id));
          dispatch(getProfileData(email, user));
        } else {
          axiosErrorHandler(
            err,
            retryCount,
            dispatch,
            () =>
              linkWithExchange(
                email,
                user,
                exchange_id,
                portfolio_type,
                retryCount + 1,
              ),
            LINK_WITH_EXCHANGE_ERROR,
            user,
            email,
            "link_exchange_to_portfolio",
          );
          // const { STATUS_CODE, MESSAGE } = err?.response.data;
          // dispatch({ type: LINK_WITH_EXCHANGE_ERROR, payload: MESSAGE });
          // errorHandler(STATUS_CODE, MESSAGE, true);
        }
      });
  };

export const unlinkFromExchange =
  (email, user, portfolio_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: UNLINK_FROM_EXCHANGE_REQUEST });

    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          request_type: "unlink_portfolio_from_exchanges",
          email,
          user,
          portfolio_type,
        },
      )
      .then((res) => {
        dispatch({
          type: UNLINK_FROM_EXCHANGE_SUCCESS,
          payload: res?.data?.MESSAGE,
        });

        const profileAttribute =
          portfolio_type === "primary"
            ? "portfolio1_linked_exchange"
            : "portfolio2_linked_exchange";

        dispatch(setVirtualPortfolio(portfolio_type));
        dispatch(updateProfileAttribute(profileAttribute, "None"));
        dispatch(getProfileData(email, user));
        // dispatch(getPortfolioData(email, user, portfolio_type));

        alert(null, res?.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => unlinkFromExchange(email, user, portfolio_type, retryCount + 1),
          UNLINK_FROM_EXCHANGE_ERROR,
          user,
          email,
          "unlink_portfolio_from_exchanges",
        );
        // console.log(err);
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // dispatch({ type: UNLINK_FROM_EXCHANGE_ERROR, payload: MESSAGE });
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getTrustedIps =
  (retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_TRUSTED_IPS_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "get_restricted_ip",
        },
      )
      .then((res) => {
        dispatch({
          type: GET_TRUSTED_IPS_SUCCESS,
          payload: res?.data?.trusted_ips,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getTrustedIps(retryCount + 1),
          GET_TRUSTED_IPS_ERROR,
          "user",
          "email",
          "get_restricted_ip",
        );
        // if (err?.message === "Network Error" || err?.response?.status === 504) {
        //   if (retryCount < 2) {
        //     dispatch(getTrustedIps(retryCount + 1));
        //   } else {
        //     dispatch({ type: GET_TRUSTED_IPS_ERROR, payload: "Network Error" });
        //     networkErrorHandler();
        //   }
        // }
        //
        // if (err?.response?.data) {
        //   const { STATUS_CODE, MESSAGE } = err?.response.data;
        //   dispatch({ type: GET_TRUSTED_IPS_ERROR, payload: MESSAGE });
        // }
      });
  };

export const getExchangeBalanceChart =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_EXCHANGE_BALANCE_CHART_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "get_balance_chart",
          user,
          email,
          exchange_id,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_EXCHANGE_BALANCE_CHART_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getExchangeBalanceChart(user, email, exchange_id, retryCount + 1),
          GET_EXCHANGE_BALANCE_CHART_ERROR,
          user,
          email,
          "exchange/get_balance_chart",
        );
      });
  };

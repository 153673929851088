import styles from "../../../styles/Components/bot/bot-backtest/BotBacktestTableExpandedRow.module.css";
import { formatCustomDate } from "../../../utility/helper";

const statusFormatter = {
  processing: "Under Process",
  processed: "Finished",
  not_processed: "Pending",
  failed: "Failed",
};

function BotBacktestTableExpandedRow({ data }) {
  const hasROIData = Object.keys(data.navROI).length > 0;
  const symbolList = hasROIData ? Object.keys(data.navROI) : [];

  return (
    <div className={styles.container}>
      {/*{console.log(data, symbolList)}*/}
      <h3 className={styles.title}>Overview:</h3>
      {!hasROIData && <p>There is no data for now.</p>}
      {hasROIData && (
        <ul className={styles.dataList}>
          <li className={styles.dataItem}>
            <span className={styles.label}>Nav ROI:</span>
            <ul className={styles.subDataList}>
              {symbolList.map((symbol) => (
                <li key={symbol}>
                  <span className={styles.secLabel}>{symbol}:</span>
                  <span>{Number(data.navROI[symbol])?.toFixed(2)}%</span>
                </li>
              ))}
            </ul>
          </li>
          <li className={styles.dataItem}>
            <span className={styles.label}>Win Rate:</span>
            <ul className={styles.subDataList}>
              {symbolList.map((symbol) => (
                <li key={symbol}>
                  <span className={styles.secLabel}>{symbol}:</span>
                  <span>{Number(data.winRates[symbol])?.toFixed(2)}%</span>
                </li>
              ))}
            </ul>
          </li>
          <li className={styles.dataItem}>
            <span className={styles.label}>Closed Positions:</span>
            <ul className={styles.subDataList}>
              {symbolList.map((symbol) => (
                <li key={symbol}>
                  <span className={styles.secLabel}>{symbol}:</span>
                  <span>{data.closedPositions[symbol]}</span>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      )}
      <ul
        className={`${styles.dataList} ${styles.moreData}`}
        style={{ marginTop: "1rem" }}
      >
        {data?.date && (
          <li className={styles.dataItem}>
            <span className={styles.label}>Request Time:</span>
            <span>{formatCustomDate(data?.date, "yes")}</span>
          </li>
        )}
        {data?.endDate && (
          <li className={styles.dataItem}>
            <span className={styles.label}>End Date:</span>
            <span>{formatCustomDate(data?.endDate)}</span>
          </li>
        )}
        {data?.status && (
          <li className={styles.dataItem}>
            <span className={styles.label}>End Date:</span>
            <span>{statusFormatter[data?.status]}</span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default BotBacktestTableExpandedRow;

import SkeletonContainer from "./SkeletonContainer";
import { Link } from "react-router-dom";
import { notify } from "../../utility/Toastify/toastify";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import TradeModal from "../modal/TradeModal";

import styles from "../../styles/Components/dashboard/TopCryptoPrediction.module.css";

// ICONS
import down from "../../asset/Icons/down.gif";
import up from "../../asset/Icons/up.gif";
import prediction from "../../asset/Icons/prediction.gif";
import { setBasicTradeInfo, setMode, setStep } from "../../redux/trade/actions";
import TradeModal from "../../shared/trade-modal/TradeModal";
import ContentContainer from "./ContentContainer";
import toolsIcon from "../../asset/Icons/user(1).gif";
import DashboardCardTitle from "./DashboardCardTitle";

const TopCryptoPrediction = ({ loading, data }) => {
  const [showTradeModal, setShowTradeModal] = useState(false);

  const dispatch = useDispatch();

  const {} = useSelector((state) => state);

  const setTradeHandler = (asset, suggestedTrade) => {
    dispatch(
      setBasicTradeInfo({
        market: "crypto",
        asset,
        tradeType: suggestedTrade,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  return (
    <div className={styles.container}>
      {loading && !data ? (
        <SkeletonContainer />
      ) : (
        data && (
          <ContentContainer>
            <DashboardCardTitle
              text={"Top Crypto Predictions"}
              icon={prediction}
            />
            {/*<h3 className={styles.title}>*/}
            {/*  <img*/}
            {/*    src={prediction}*/}
            {/*    style={{ width: "35px", marginRight: ".5rem" }}*/}
            {/*    alt="prediction"*/}
            {/*  />*/}
            {/*  Top Crypto Predictions*/}
            {/*</h3>*/}
            {/*<p>*/}
            {/*  These are some of the best and worst coins to trade right now.*/}
            {/*</p>*/}
            <div className={styles.predictContainer}>
              <div className={styles.predictBox}>
                <h4 className={styles.predictTitle}>
                  Going up (Buy)
                  <img src={up} className={styles.predictIcon} alt="buy" />
                </h4>
                {data.increasing.map(([name, value, action]) => (
                  <div className={styles.coin} key={name}>
                    <img
                      className={styles.coinImage}
                      src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${name}.png`}
                      alt={name}
                    />
                    <div
                      // onClick={() => getPriceHandler(name)}
                      onClick={() => setTradeHandler(name, "Buy")}
                      className={styles.coinName}
                    >
                      {name}
                    </div>
                    <div style={{ color: value > 0 ? "green" : "red" }}>
                      {value}%
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.predictBox}>
                <h4 className={styles.predictTitle}>
                  Going down (Sell)
                  <img src={down} className={styles.predictIcon} alt="sell" />
                </h4>
                {data.decreasing.map(([name, value, action]) => (
                  <div className={styles.coin} key={name}>
                    <img
                      className={styles.coinImage}
                      src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${name}.png`}
                      alt={name}
                    />
                    <div
                      // onClick={() => getPriceHandler(name)}
                      onClick={() => setTradeHandler(name, "Sell")}
                      className={styles.coinName}
                    >
                      {name}
                    </div>
                    <div style={{ color: value > 0 ? "green" : "red" }}>
                      {value}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.linkBtns}>
              <Link
                onClick={() =>
                  notify("You are redirecting to the Insights page.", "success")
                }
                className={styles.link}
                to="/ai-assistant"
              >
                See more signals
              </Link>
              <Link
                onClick={() =>
                  notify(
                    "You are redirecting to the AI Assistant page.",
                    "success",
                  )
                }
                className={styles.link}
                to="/ai-assistant/portfolio"
              >
                Manage Portfolio
              </Link>
            </div>
            <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
          </ContentContainer>
        )
      )}
    </div>
  );
};

export default TopCryptoPrediction;

// InputComponent.js
import React, { useRef, useState } from "react";
import styles from "../../styles/shared/connect-exchange/InputComponent.module.css";
import { HiOutlineKey } from "react-icons/hi2";
import { message } from "antd";
import { HiOutlineClipboardList } from "react-icons/hi";

const InputComponent = ({
  label,
  setValue,
  value,
  customClassName,
  customStyle,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value.trim());
    setValue(e.target.value.trim());
  };

  const pasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputValue(clipboardText);
      setValue(clipboardText);
    } catch (err) {
      //   console.error('Failed to read clipboard contents: ', err);
      message.warning("Failed to read clipboard contents.");
    }
  };

  const handleTitleClick = () => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  return (
    <div
      className={`${styles.inputContainer} ${
        isFocused || value || inputValue ? styles.focused : ""
      } ${customClassName || ""}`}
      style={customStyle || {}}
    >
      <div className={styles.icon}>
        <HiOutlineKey size={20} color="gray" />
      </div>
      <input
        ref={inputRef}
        type="text"
        className={styles.input}
        value={value || inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div
        className={`${styles.title} ${
          isFocused || inputValue ? styles.up : ""
        }`}
        onClick={handleTitleClick}
      >
        {label}
      </div>
      <div className={styles.clipboardIcon} onClick={pasteFromClipboard}>
        <HiOutlineClipboardList size={20} color="gray" />
      </div>
    </div>
  );
};

export default InputComponent;

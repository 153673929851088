import React, { useState } from "react";
import AlertRiskSetting from "./AlertRiskSetting";
import styles from "../../../styles/Components/alert-system/AlertSystem.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { Button, message } from "antd";
import { IoChevronBack, IoClose } from "react-icons/io5";
import AlertFrequencySetting from "./AlertFrequencySetting";
import AlertNotificationTimeSetting from "./AlertNotificationTimeSetting";
import AlertPortfolioSetting from "./AlertPortfolioSetting";
import {
  activeAutomaticAlertSystem,
  getAlertStatus,
} from "../../../redux/alert-system/actions";

function AlertSystemSetting({ setDisplayMode }) {
  const {
    alertSystem: { alertStatus, activeAlertLoading },
    main: { userData },
  } = useSelector((state) => state);

  const {
    risk_taking_amount: riskAppetite,
    alert_frequency: alertFrequency,
    balance_thresh: balanceThresh,
    start_time_interval_active: startTime,
    prefered_alert_time: notificationTime,
    end_time_interval_active: endTime,
    asset_in_danger_watch: assetInDanger,
  } = alertStatus;

  const [step, setStep] = useState(1);
  const [settingData, setSettingData] = useState({
    riskAppetite,
    alertFrequency,
    balanceThresh: `${Math.abs(balanceThresh)}`,
    startTime: "",
    endTime: "",
    notificationTime: "",
    assetInDanger,
    timeRange: "",
  });

  const dispatch = useDispatch();

  const changeAlertSettings = (keyName, value) => {
    setSettingData((prevSetting) => {
      return {
        ...prevSetting,
        [keyName]: value,
      };
    });
  };

  const goBackHandler = () => {
    if (step === 1) {
      setDisplayMode("overview");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const goNextHandler = async () => {
    if (step === 1 && !settingData.riskAppetite) {
      return message.info("Please select a valid risk appetite.");
    }

    if (step === 2 && !settingData.alertFrequency) {
      return message.info("Please select a valid frequency.");
    }

    if (
      step === 3 &&
      (!settingData.startTime ||
        !settingData.endTime ||
        !settingData.notificationTime)
    ) {
      return message.info("Please choose a valid time");
    }

    if (step === 4) {
      if (
        settingData.assetInDanger === "Yes" &&
        (!settingData.balanceThresh || settingData.balanceThresh === "0")
      ) {
        return message.info("Please select a valid percentage.");
      } else {
        if (activeAlertLoading) return;

        await dispatch(
          activeAutomaticAlertSystem(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            settingData.notificationTime,
            settingData.alertFrequency,
            settingData.assetInDanger,
            settingData.riskAppetite,
            settingData.startTime,
            settingData.endTime,
            `${-settingData.balanceThresh}`,
          ),
        ).then(() => {
          dispatch(
            getAlertStatus(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
            ),
          );
        });
        return setDisplayMode("overview");
      }
    }

    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div>
      <ul className={styles.timeLine} style={{ marginBottom: "2.5rem" }}>
        <li
          className={`${styles.timeItem} ${step >= 1 ? styles.complete : ""}`}
        >
          <div className={styles.timeStamp}>
            <h4>Risk Appetite</h4>
          </div>
          <div className={styles.status}>
            <span> Enter your desired risk appetite. </span>
          </div>
        </li>
        <li
          className={`${styles.timeItem} ${step >= 2 ? styles.complete : ""}`}
        >
          <div className={styles.timeStamp}>
            <h4>Alert Rate</h4>
          </div>
          <div className={styles.status}>
            <span> Enter your expected alert rate. </span>
          </div>
        </li>
        <li
          className={`${styles.timeItem} ${step >= 3 ? styles.complete : ""}`}
        >
          <div className={styles.timeStamp}>
            <h4>Timing</h4>
          </div>
          <div className={styles.status}>
            <span> Set your prefered time to receive alerts. </span>
          </div>
        </li>
        <li
          className={`${styles.timeItem} ${step >= 4 ? styles.complete : ""}`}
        >
          <div className={styles.timeStamp}>
            <h4>Portfolio</h4>
          </div>
          <div className={styles.status}>
            <span> Set portfolio tracker parameters. </span>
          </div>
        </li>
      </ul>

      {step === 1 && (
        <AlertRiskSetting
          setRiskAppetite={changeAlertSettings}
          defaultRisk={settingData.riskAppetite}
        />
      )}

      {step === 2 && (
        <AlertFrequencySetting
          setAlertFrequency={changeAlertSettings}
          alertFrequency={settingData.alertFrequency}
        />
      )}

      {step === 3 && (
        <AlertNotificationTimeSetting
          setNotificationTime={changeAlertSettings}
          alertFrequency={settingData.alertFrequency}
          notificationTime={settingData.notificationTime}
          timeRange={settingData.timeRange}
          startTime={settingData.startTime}
          endTime={settingData.endTime}
        />
      )}

      {step === 4 && (
        <AlertPortfolioSetting
          setPortfolioSetting={changeAlertSettings}
          assetInDanger={settingData.assetInDanger}
          balanceThresh={settingData.balanceThresh}
        />
      )}

      <div className={styles.stepBtns}>
        <span className={styles.backBtn} onClick={goBackHandler}>
          {step === 1 ? <IoClose size={17} /> : <IoChevronBack size={17} />}
          {step === 1 ? "Close" : "Back"}
        </span>
        <Button
          className={styles.nextBtn}
          onClick={goNextHandler}
          loading={activeAlertLoading}
        >
          {step < 4 ? "Next" : "Submit"}
        </Button>
      </div>
    </div>
  );
}

export default AlertSystemSetting;

import { useEffect, useState } from "react";
import styles from "../../styles/Components/pricing-plan/CancelSubscriptionModal.module.css";
import { Modal, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription, discount } from "../../redux/pricing-plan/action";
import moment from "moment";

import congrateIcon from "../../asset/Icons/congrats(1).gif";

const questionData = [
  {
    step: 1,
    title: "We're sorry to see you go.\nHow did we fall short?",
    options: [
      "Not usefull for now",
      "Didn't see the value",
      "Poor support",
      "Missing features/hard to use",
      "Other",
    ],
  },
  {
    step: 2,
    title: "It wasn't all bad, right?\nDid we do anything well?",
    options: [
      "Many things - I'll be back",
      "Good value",
      "Helpful support",
      "Easy to use",
      "Other",
    ],
  },
  {
    step: 3,
    title: "We're here for you -\ncan we chat more about this?",
    options: ["Yes, let's chat", "No, I'd rather just cancel"],
  },
  {
    step: 4,
    title: "One last",
    options: ["I'll take that", "No, I'd rather just cancel"],
  },
  {
    step: 5,
    title: "We'll miss you.",
  },

  {
    step: 31,
    title:
      "Wonderfull - we'll be in touch shortly!\n In meantime, do you want to keep your subscription active?",
    options: ["Yes, I'll stay on for now", "No, I cancel my subscription"],
  },
  {
    step: 41,
    title: "Congratulation",
  },
];

const AnswerBox = ({
  data,
  changeStep,
  submitAnswer,
  reset,
  step,
  filtered,
  showChat,
  subscriptionType,
}) => {
  const dispatch = useDispatch();
  const {
    main: { userData },
  } = useSelector((state) => state);
  const handleAnswer = (e) => {
    const answer = e.target.dataset.answer;
    if (filtered) {
      if (step === 3 && answer === "No, I'd rather just cancel") {
        changeStep(5);
        return;
      }
    }
    if (answer === "Yes, I'll stay on for now") {
      reset();
      return;
    }
    if (step === 3 && answer === "Yes, let's chat") {
      // changeStep(31);
      // submitAnswer((answers) => [...answers, answer]);
      showChat(true);
      reset();
      return;
    }
    if (step === 31 && answer === "No, I cancel my subscription") {
      filtered ? changeStep(5) : changeStep(4);
      submitAnswer((answers) => [...answers, answer]);
      return;
    }
    if (step === 4) {
      if (answer === "No, I'd rather just cancel") {
        changeStep(5);
      } else {
        changeStep(41);
        dispatch(
          discount(
            userData["cognito:username"],
            userData?.["custom:custom_username"],
            subscriptionType,
          ),
        );
      }

      submitAnswer((answers) => [...answers, answer]);
      return;
    }
    submitAnswer((answers) => [...answers, answer]);
    changeStep((prevStep) => prevStep + 1);
  };
  return (
    <ul className={styles.options}>
      {data.map((op, i) => (
        <li
          key={`${op.title}${i}`}
          data-answer={op}
          onClick={handleAnswer}
          className={styles.option}
        >
          {op}
        </li>
      ))}
    </ul>
  );
};

function CancelSubscriptionModal({
  show,
  setShow,
  showChat,
  subscriptionType,
}) {
  const {
    pricingPlan: { subscriptions, discountLoading, discountError },
    profile: { profileData },
    main: { userData },
  } = useSelector((state) => state);

  const isYearlySubscription = subscriptionType?.includes("yearly");
  const isProPlan = subscriptionType?.includes("pro");

  const [questions, setQuestions] = useState(questionData);
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [cancelLoading, setCancelLoading] = useState(false);

  const dispatch = useDispatch();

  const resetModal = () => {
    setShow(false);
    setStep(1);
    setAnswers([]);
  };

  const getStepFourTitle = () => {
    const base = "One last thing -\nHow about ";
    if (!isYearlySubscription) return base + "50% off for 2 months?";
    if (isYearlySubscription) return base + "10% off?";
  };

  const getCancellationDate = () => {
    if (!isYearlySubscription) {
      return new Date(
        subscriptions[subscriptionType]?.current_period_end * 1000,
      ).toLocaleString();
    } else if (!isYearlySubscription) {
      return new Date(
        subscriptions[subscriptionType]?.current_period_end * 1000,
      ).toLocaleString();
    }
  };

  const renderDiscountMessage = () => {
    const monthlyMsg =
      "Great! You've landed a huge deal! Get 50% off on your invoice for two months as a bonus. This is a rare chance to lower your costs and get more from our services. Snatch this amazing offer now!";
    const yearlyMsg =
      "Wow! You've earned a fantastic offer! Save 10% on your invoice for a year as a bonus. This is a rare chance to cut costs and get more benefits from our services. Grab this awesome deal now!";
    return !isYearlySubscription ? monthlyMsg : yearlyMsg;
  };

  const confirmHandler = async () => {
    setCancelLoading(true);
    await dispatch(
      cancelSubscription(
        userData["cognito:username"],
        userData?.["custom:custom_username"],
        subscriptionType,
        getCancellationDate(),
      ),
    );
    setCancelLoading(false);
    resetModal();
    // message.success(
    //   `Your subscription will be cancelled at ${getCancellationDate()}`,
    // );
  };

  useEffect(() => {
    const {
      last_used_discount_for_continue: discount,
      last_used_pro_discount_for_continue: proDiscount,
    } = profileData;
    if ((discount && !isProPlan) || (isProPlan && proDiscount)) {
      const date = isProPlan ? proDiscount : discount;
      const discountDate = moment(date);
      const now = moment();
      const diff = now.diff(discountDate, "years");

      if (diff < 1) setQuestions(questionData.filter((q) => q.step !== 4));
    }
  }, [isProPlan, profileData]);

  return (
    <Modal
      open={show}
      maskClosable={false}
      width={450}
      onOk={resetModal}
      onCancel={resetModal}
      footer={false}
      centered={true}
      closable={false}
      bodyStyle={{ padding: 0, borderRadius: "10px", overflow: "hidden" }}
      children={
        <div className={styles.container}>
          <div className={styles.header}>
            <h3>
              {step === 4
                ? getStepFourTitle()
                : questions.filter((q) => q.step === step)[0].title}
            </h3>
          </div>
          <div className={styles.body}>
            {step === 5 ? (
              <div>
                <TextArea
                  style={{
                    minHeight: "250px",
                    borderRadius: "5px",
                    border: "2px solid #E9E9E9",
                    color: "#787B82",
                    resize: "none",
                    fontSize: "15px",
                  }}
                  placeholder="If there's anything else, we're listening..."
                />
                <span
                  className={`${styles.submitBtn} ${
                    cancelLoading ? styles.disabledSubmitBtn : ""
                  }`}
                  onClick={() => {
                    if (cancelLoading) return;
                    confirmHandler();
                  }}
                >
                  {cancelLoading ? <Spin /> : ""}
                  CONFIRM CANCELLATION
                </span>
              </div>
            ) : step === 41 ? (
              <div className={styles.discountContainer}>
                {discountLoading ? (
                  <Spin tip="Please wait" />
                ) : !discountError ? (
                  <div className={styles.congratContainer}>
                    <img src={congrateIcon} alt="congratuation" />
                    <p>{renderDiscountMessage()}</p>
                  </div>
                ) : (
                  <p>
                    Something went wrong!
                    <br />
                    Please try later.
                  </p>
                )}
                {discountLoading ? (
                  ""
                ) : (
                  <span className={styles.closeBtn} onClick={resetModal}>
                    Ok
                  </span>
                )}
              </div>
            ) : (
              <AnswerBox
                showChat={showChat}
                data={questions.filter((q) => q.step === step)[0].options}
                changeStep={setStep}
                submitAnswer={setAnswers}
                step={step}
                reset={resetModal}
                answers={answers}
                filtered={questions.length === 6}
                subscriptionType={subscriptionType}
              />
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.timeline}>
              {questions.map((_, index) => (
                <div
                  key={index}
                  className={`${styles.timelineDot} ${
                    index < step ? styles.passed : ""
                  }`}
                ></div>
              ))}
            </div>
            <p
              onClick={() => {
                if (discountLoading) return;
                setShow(false);
                resetModal();
              }}
            >
              Never mind. I don't want to cancel
            </p>
          </div>
        </div>
      }
    />
  );
}

export default CancelSubscriptionModal;

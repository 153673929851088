import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setVirtualTradeAmountUsd,
  setVirtualTradeAmount,
  setVirtualTradeAmountPercent,
} from "../../../../redux/trade/actions";
import styles from "../../../../styles/shared/trade-modal/InstantOrderAmountSetting.module.css";
import { InputNumber, Slider } from "antd";

function VirtualTradeAmountSetting({ setIsDone }) {
  const {
    tradeState: {
      virtualSelectedAsset,
      virtualRemainingBudget,
      virtualTradeAmount,
      virtualTradeAmountUsd,
      virtualTradeType,
      virtualSecondTradeType,
      virtualAssetAmount,
      modalMode: mode,
      basicTradeData,
    },
    tradingAssistant: { price },
  } = useSelector((state) => state);

  const [amountError, setAmountError] = useState(false);
  const [sliderValue, setSliderValue] = useState(50); // Initial slider value set to 50%
  const sliderMax = virtualTradeType.includes("sell") ? 100 : 99.99;
  const [defaultMode, setDefaultMode] = useState(true);

  const dispatch = useDispatch();
  const changeNativeAmountHandler = (value) => {
    // if (!value) {
    //   dispatch(setVirtualTradeAmount(price ? 1 / price?.PRICE : 0.001));
    //   dispatch(setVirtualTradeAmountUsd(price ? 1 / price?.PRICE : 0.001));
    //   return;
    // }
    if (virtualTradeType.includes("sell")) {
      if (value > Number(virtualAssetAmount)) return;
    } else {
      if (value > virtualRemainingBudget / price?.PRICE) {
        return;
      }
    }

    if (virtualTradeType.includes("sell")) {
      setSliderValue((value / virtualAssetAmount) * 100);
      dispatch(
        setVirtualTradeAmountPercent((value / virtualAssetAmount) * 100),
      );
    } else {
      const percentage =
        ((value * price?.PRICE) / virtualRemainingBudget) * 100;
      setSliderValue(percentage);
      dispatch(setVirtualTradeAmountPercent(percentage));
    }
    dispatch(setVirtualTradeAmount(value));
    dispatch(setVirtualTradeAmountUsd(value * price?.PRICE));
  };

  const changeUsdAmountHandler = (value) => {
    if (virtualTradeType.includes("buy")) {
      if (value > virtualRemainingBudget) return;
    } else {
      if (value > virtualAssetAmount * price?.PRICE) {
        return;
      }
    }

    if (virtualTradeType.includes("sell")) {
      const percentage = (value / (virtualAssetAmount * price?.PRICE)) * 100;
      setSliderValue(percentage);
      dispatch(setVirtualTradeAmountPercent(percentage));
    } else {
      const percentage = (value / virtualRemainingBudget) * 100;
      setSliderValue(percentage);
      dispatch(setVirtualTradeAmountPercent(percentage));
    }

    dispatch(setVirtualTradeAmountUsd(value));
    dispatch(setVirtualTradeAmount(value / price?.PRICE));
  };

  const handleSliderChange = (value) => {
    if (!value) {
      setSliderValue(
        price
          ? virtualTradeType.includes("sell")
            ? 100.001 / (price?.PRICE * virtualAssetAmount)
            : 100.001 / virtualRemainingBudget
          : 0.001,
      );
      return;
    }
    setSliderValue(value);
    dispatch(setVirtualTradeAmountPercent(value));
    let percentage = value / 100;

    if (virtualTradeType.includes("sell")) {
      const nativeAmount = virtualAssetAmount * percentage;
      dispatch(setVirtualTradeAmount(nativeAmount));
      dispatch(setVirtualTradeAmountUsd(nativeAmount * price?.PRICE));
    } else {
      const usdAmount = virtualRemainingBudget * percentage;
      dispatch(setVirtualTradeAmountUsd(usdAmount));
      dispatch(setVirtualTradeAmount(usdAmount / price?.PRICE));
    }
  };

  const focusHandler = () => {
    if (!virtualSecondTradeType) return;
  };

  useEffect(() => {
    if (virtualTradeAmount > 0) {
      if (virtualTradeType.includes("buy")) {
        if (virtualTradeAmount * price?.PRICE > virtualRemainingBudget) {
          setAmountError(true);
          setIsDone(false);
        } else {
          setAmountError(false);
          setIsDone(true);
        }
      }

      if (virtualTradeType.includes("sell")) {
        if (virtualTradeAmount > Number(virtualAssetAmount)) {
          setAmountError(true);
          setIsDone(false);
        } else {
          setAmountError(false);
          setIsDone(true);
        }
      }
    }
  }, [
    virtualAssetAmount,
    virtualTradeAmount,
    virtualTradeType,
    virtualRemainingBudget,
  ]);

  // Set a default value for the native and usd amount
  useEffect(() => {
    if (
      mode === "ai-suggestion" &&
      (virtualTradeType.toLowerCase() === "buy" ||
        virtualSecondTradeType === "Sell") &&
      basicTradeData.amountPercent
    ) {
      if (
        (virtualSecondTradeType === "Sell" &&
          Number(basicTradeData.amountPercent) >=
            100.001 / (price?.PRICE * virtualAssetAmount)) ||
        (virtualTradeType.toLowerCase() === "buy" &&
          Number(basicTradeData.amountPercent) >=
            100.001 / virtualRemainingBudget)
      ) {
        setSliderValue(Number(basicTradeData.amountPercent));
        dispatch(
          setVirtualTradeAmountPercent(Number(basicTradeData.amountPercent)),
        );
        setDefaultMode(false);
      } else {
        setSliderValue(sliderMax);
        dispatch(setVirtualTradeAmountPercent(sliderMax));
      }
    } else {
      setSliderValue(sliderMax);
      dispatch(setVirtualTradeAmountPercent(sliderMax));
    }

    const percentage = sliderValue / 100;
    if (
      virtualSecondTradeType === "stop_loss" ||
      virtualSecondTradeType === "take_profit"
    ) {
      const nativeAmount = virtualAssetAmount * percentage;
      dispatch(setVirtualTradeAmount(nativeAmount));
      dispatch(setVirtualTradeAmountUsd(nativeAmount * price?.PRICE));
    } else {
      const usdAmount = virtualRemainingBudget * percentage;
      dispatch(setVirtualTradeAmountUsd(usdAmount));
      dispatch(setVirtualTradeAmount(usdAmount / price?.PRICE));
    }
  }, [virtualSecondTradeType]);

  // update values based on the updated price
  useEffect(() => {
    if (price && virtualSecondTradeType) {
      const percentage = sliderValue / 100;
      if (
        virtualSecondTradeType === "stop_loss" ||
        virtualSecondTradeType === "take_profit" ||
        virtualSecondTradeType === "Sell"
      ) {
        const nativeAmount = virtualAssetAmount * percentage;
        dispatch(setVirtualTradeAmount(nativeAmount));
        dispatch(setVirtualTradeAmountUsd(nativeAmount * price?.PRICE));
      } else {
        const usdAmount = virtualRemainingBudget * percentage;
        dispatch(setVirtualTradeAmountUsd(usdAmount));
        dispatch(setVirtualTradeAmount(usdAmount / price?.PRICE));
      }
    }
  }, [virtualSecondTradeType, virtualAssetAmount]);

  return (
    <div
      className={`${styles.container} ${
        !virtualSecondTradeType ? styles.inactive : ""
      }`}
    >
      <div className={styles.inputBox}>
        <label>Native Amount:</label>
        <InputNumber
          className={styles.input}
          min={price ? 1 / price?.PRICE : 0.001}
          step={0.0001}
          max={
            virtualTradeType.includes("sell")
              ? Number(virtualAssetAmount)
              : virtualRemainingBudget / price?.PRICE
          }
          value={virtualTradeAmount}
          status={amountError ? "error" : ""}
          onChange={changeNativeAmountHandler}
          onFocus={focusHandler}
        />
      </div>
      <div className={styles.inputBox}>
        <label>USD Amount:</label>
        <InputNumber
          className={styles.input}
          value={virtualTradeAmountUsd}
          min={1}
          max={
            virtualTradeType.includes("buy")
              ? virtualRemainingBudget
              : virtualAssetAmount * price?.PRICE
          }
          step={0.01}
          status={amountError ? "error" : ""}
          onChange={changeUsdAmountHandler}
          onFocus={focusHandler}
          formatter={(value) => `$${value}`}
          parser={(value) => value.replace("$", "")}
        />
      </div>
      <div className={styles.sliderBox}>
        <Slider
          min={
            price
              ? virtualTradeType.includes("sell")
                ? 100.001 / (price?.PRICE * virtualAssetAmount)
                : 100.001 / virtualRemainingBudget
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          value={sliderValue}
          onChange={handleSliderChange}
          className={styles.slider}
        />

        <InputNumber
          min={
            price
              ? virtualTradeType.includes("sell")
                ? 100.001 / (price?.PRICE * virtualAssetAmount)
                : 100.001 / virtualRemainingBudget
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={sliderValue}
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>

      {mode === "ai-suggestion" &&
        sliderValue === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}

      {mode === "ai-suggestion" &&
        basicTradeData?.amountPercent &&
        ((virtualSecondTradeType === "Sell" &&
          Number(basicTradeData.amountPercent) >=
            100.001 / (price?.PRICE * virtualAssetAmount)) ||
          (virtualTradeType.toLowerCase() === "buy" &&
            Number(basicTradeData.amountPercent) >=
              100.001 / virtualRemainingBudget)) &&
        sliderValue !== Number(basicTradeData?.amountPercent) && (
          <p
            onClick={() =>
              setSliderValue(Number(basicTradeData?.amountPercent))
            }
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {/*{(mode === "ai-suggestion" &&*/}
      {/*        basicTradeData?.amountPercent &&*/}
      {/*        virtualSecondTradeType === "Sell" &&*/}
      {/*        Number(basicTradeData.amountPercent) >=*/}
      {/*        100.001 / (price?.PRICE * virtualAssetAmount)) ||*/}
      {/*    (virtualTradeType.toLowerCase() === "buy" &&*/}
      {/*        Number(basicTradeData.amountPercent) >=*/}
      {/*        100.001 / virtualRemainingBudget &&*/}
      {/*        sliderValue !== Number(basicTradeData?.amountPercent) && (*/}
      {/*        <p*/}
      {/*          onClick={() =>*/}
      {/*            setSliderValue(Number(basicTradeData?.amountPercent))*/}
      {/*          }*/}
      {/*          style={{*/}
      {/*            gridColumn: "span 2",*/}
      {/*            marginBottom: 0,*/}
      {/*            textDecoration: "underline",*/}
      {/*            cursor: "pointer",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          Reset to AI suggestion.*/}
      {/*        </p>*/}
      {/*      ),*/}
      {/*    )}*/}

      {amountError && (
        <p className={styles.errorMessage}>
          {(virtualSecondTradeType === "stop_loss" ||
            virtualSecondTradeType === "take_profit" ||
            virtualSecondTradeType === "Sell") &&
            `The amount you enter should be lower than the amount of the ${virtualSelectedAsset} in your portfolio. [${Number(
              virtualAssetAmount,
            )}]`}
          {(virtualSecondTradeType === "breakout" ||
            virtualSecondTradeType === "discount" ||
            virtualSecondTradeType === "Buy") &&
            `The amount [${
              virtualTradeAmount * Number(price?.PRICE)
            }] is greater than your remaining budget.[${Number(
              virtualRemainingBudget * 0.99,
            )}]`}
        </p>
      )}
    </div>
  );
}

export default VirtualTradeAmountSetting;

import { Card, Spin } from "antd";
import {
  MdFreeCancellation,
  MdOutlineFreeCancellation,
  MdSubscriptions,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { RxCalendar } from "react-icons/rx";
import styles from "../../styles/Components/pricing-plan/SubscriptionDetails.module.css";
import { IoLogoUsd } from "react-icons/io";
import { BiBarcodeReader } from "react-icons/bi";
import { GrFormNext, GrStatusInfo } from "react-icons/gr";
import { GiDuration } from "react-icons/gi";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";
import RenewSubscriptionButton from "../../components/pricing-plan/billing/RenewSubscriptionButton";
import CancelSubscriptionButton from "../../components/pricing-plan/billing/CancelSubscriptionButton";

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const Subscription = ({ data, title, subscriptionType }) => {
  const isActive = !data?.cancel_at;

  return (
    <div className={styles.subscriptionInfo}>
      {data ? (
        <>
          <h3 className={styles.cardTitle} style={{ textAlign: "center" }}>
            <RxCalendar className={styles.cardTitleIcon} />
            {title}
          </h3>{" "}
          <p className={styles.text}>
            <strong>
              <BiBarcodeReader className={styles.textIcon} />
              Subscription ID:
            </strong>{" "}
            {data.id}
          </p>
          <p className={styles.text}>
            <strong>
              {" "}
              <GrStatusInfo className={styles.textIcon} />
              Status:
            </strong>{" "}
            {data.canceled_at ? "Cancelled" : "Active"}
          </p>
          <p className={styles.text}>
            <strong>
              <GiDuration className={styles.textIcon} />
              Current Period:
            </strong>{" "}
            {new Date(data.current_period_start * 1000).toLocaleString(
              "en-GB",
              dateOptions,
            )}{" "}
            -{" "}
            {new Date(data.current_period_end * 1000).toLocaleString(
              "en-GB",
              dateOptions,
            )}
          </p>
          {!data.canceled_at && (
            <p className={styles.text}>
              <strong>
                <GrFormNext className={styles.textIcon} />
                Next invoice:
              </strong>{" "}
              {new Date(data.current_period_end * 1000).toLocaleString(
                "en-GB",
                dateOptions,
              )}
            </p>
          )}
          {data.cancel_at && (
            <p className={styles.text}>
              <strong>
                {" "}
                <MdOutlineFreeCancellation className={styles.textIcon} />
                Cancellation:
              </strong>
              {data.cancel_at
                ? new Date(data.cancel_at * 1000).toLocaleString(
                    "en-GB",
                    dateOptions,
                  )
                : null}
            </p>
          )}
          {data.canceled_at && (
            <p className={styles.text}>
              <strong>
                <MdFreeCancellation className={styles.textIcon} />
                Canceled At:
              </strong>{" "}
              {new Date(data.canceled_at * 1000).toLocaleString(
                "en-GB",
                dateOptions,
              )}
            </p>
          )}
          <p className={styles.text}>
            <strong>
              <IoLogoUsd className={styles.textIcon} />
              Amount:
            </strong>{" "}
            <span>{data.plan.amount / 100} USD</span>
          </p>
          <div className={styles.actionBtns}>
            <CancelSubscriptionButton
              isActive={isActive}
              subscription={data}
              subscriptionType={subscriptionType}
            />
            <RenewSubscriptionButton
              isActive={isActive}
              subscriptionType={subscriptionType}
            />
          </div>
        </>
      ) : (
        <p style={{ textAlign: "center" }}>There is no subscription</p>
      )}
    </div>
  );
};

const SubscriptionDetails = ({ data, planType, plan, subscription }) => {
  const {
    pricingPlan: { getSubscriptionLoading },
  } = useSelector((state) => state);

  const { proColor, basicColor } = useSubscriptionPlans();

  return (
    <Card
      title={
        <>
          <h4 style={{ marginBottom: 0 }}>
            <MdSubscriptions /> Subscription Details
          </h4>
        </>
      }
      headStyle={{
        backgroundColor: planType === "pro" ? proColor : basicColor,
        color: "#ffffff",
      }}
      bodyStyle={{ height: `calc(100% - 56px)` }}
      className={styles.card}
    >
      {!getSubscriptionLoading && plan ? (
        <Subscription
          data={data}
          title={plan}
          subscriptionType={subscription}
        />
      ) : !plan && !getSubscriptionLoading ? (
        <p style={{ textAlign: "center" }}>No subscription</p>
      ) : (
        <Spin className={styles.spinner} tip="Please wait" />
      )}
    </Card>
  );
};

export default SubscriptionDetails;

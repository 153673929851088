import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TfiMoreAlt } from "react-icons/tfi";

import styles from "../../styles/start-wizard/TopExchanges.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ExchangesModal from "../ExchangesModal";
import {
  getExchangesInfo,
  getIntegratedExchanges,
  setExchangeForConnection,
  setPreviousPath,
} from "../../redux/exchange/actions";
import { certificatedExchange } from "../../utility/data";
import { LiaCertificateSolid } from "react-icons/lia";
import ExchangeRecommendationModal from "../exchanges/ExchangeRecommendationModal";
import UpdateExchangeIpModal from "../exchange-ip-setting/UpdateExchangeIpModal";
import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// const imgUrl =
//   "https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/binance.svg";

function TopExchanges() {
  const {
    exchangeState: {
      exchangesInfo,
      exchangesLoading,
      integratedData,
      integratedDataLoading,
      getIntegratedList,
    },
    main: { userData },
  } = useSelector((state) => state);
  const [topExchangesName, setTopExchangesName] = useState([]);
  const [topExchangesId, setTopExchangesId] = useState([]);
  const [selectedExchangeName, setSelectedExchangeName] = useState("");
  const [selectedExchangeId, setSelectedExchangeId] = useState("");
  const [showExchanges, setShowExchanges] = useState(false);
  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
  const [showIpSettingsModal, setShowIpSettingsModal] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState({});

  const otherIntegratedExchange = integratedData?.filter(
    (item) => !topExchangesId?.includes(item?.id),
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    australian_name_list,
    australian_id_list,
    top_name_list,
    top_id_list,
  } = exchangesInfo;

  const navigateToConnectionPage = (name, id) => {
    navigate(`/integrate-exchange/${name}`);
    dispatch(setPreviousPath(location.pathname));
    dispatch(setExchangeForConnection(id));
  };
  const clickHandler = (e, name, i) => {
    if (e.target.closest("li")) {
      const exchangeId = e.target.closest("li").id;
      setSelectedExchangeId(exchangeId);
      setSelectedExchangeName(name);

      const isInWizard = location.pathname.includes("wizard");

      const exchangeData = integratedData?.find(
        (item) => item?.id === exchangeId,
      );

      if (exchangeData) {
        if (
          exchangeData?.status !== "Active" &&
          exchangeData?.ip_status !== "OK"
        ) {
          setSelectedExchange(exchangeData);
          setShowIpSettingsModal(true);
          return;
        }
      }

      if (!isInWizard) {
        if (exchangeData?.status === "Active") {
          setShowRecommendationModal(true);
        } else {
          navigateToConnectionPage(name, exchangeId);
        }
      } else {
        navigateToConnectionPage(name, exchangeId);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(exchangesInfo).length) {
      setTopExchangesName(
        [...australian_name_list]
          ?.map((ex) => (ex === "CryptoCrispy" ? "Test Exchange" : ex))
          .concat(top_name_list),
      );
      setTopExchangesId(australian_id_list.concat(top_id_list));
    }
  }, [
    australian_id_list,
    australian_name_list,
    exchangesInfo,
    top_id_list,
    top_name_list,
  ]);

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !Object.keys(exchangesInfo).length &&
      !exchangesLoading
    ) {
      dispatch(getExchangesInfo());
    }
  }, [
    dispatch,
    exchangesInfo,
    exchangesLoading,
    integratedData,
    integratedDataLoading,
    userData,
  ]);

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !integratedDataLoading &&
      !getIntegratedList
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [dispatch, integratedDataLoading, userData, getIntegratedList]);

  // <LiaCertificateSolid />

  useEffect(() => {
    // console.log(location);
    localStorage.setItem("location", location.pathname);
  }, [location]);

  return (
    <div className={styles.container}>
      {exchangesLoading && (
        <div className={styles.loader}>
          <Spin tip="Loading exchanges list..." />
        </div>
      )}

      {!exchangesLoading && Object.keys(exchangesInfo).length && (
        <ul className={styles.exchangesList}>
          {topExchangesName.map((name, i) => (
            <li
              onClick={(e) => clickHandler(e, name, i)}
              className={`${styles.exchangeItem} ${
                i === 0
                  ? styles.virtualExchange
                  : i === 1
                  ? styles.auExchange
                  : i === 2
                  ? styles.firstExchange
                  : i === 3
                  ? styles.secondExchange
                  : i === 4
                  ? styles.thirdExchange
                  : ""
              } ${
                !!integratedData.find(
                  (item) => item.name === name && item.status === "Active",
                )
                  ? styles.connected
                  : ""
              } ${
                !!integratedData.find(
                  (item) => item.name === name && item.status !== "Active",
                )
                  ? styles.expired
                  : ""
              }
              ${
                !!integratedData.find(
                  (item) =>
                    item.name === name &&
                    item.ip_status !== "OK" &&
                    item.status !== "Active",
                )
                  ? styles.ipRequired
                  : ""
              }`}
              key={name}
              id={topExchangesId[i]}
            >
              <LazyLoadImage
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${topExchangesId[i]}.svg`}
                alt={name}
                width="25px"
                height="25px"
                effect="blur"
              />
              {/*<img*/}
              {/*  className={styles.exchangeImg}*/}
              {/*  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${topExchangesId[i]}.svg`}*/}
              {/*  alt={name}*/}
              {/*/>*/}
              <span className={styles.exchangeName}>{name}</span>
              {certificatedExchange.includes(topExchangesId[i]) && (
                <LiaCertificateSolid
                  title={"Certificated"}
                  className={styles.certificatedBadge}
                />
              )}
            </li>
          ))}
          {otherIntegratedExchange.length > 0 &&
            otherIntegratedExchange.map((item, i) => (
              <li
                onClick={(e) => clickHandler(e, item.name, i)}
                className={`${styles.exchangeItem} ${
                  item.status === "Active" ? styles.connected : ""
                } ${item.status !== "Active" ? styles.expired : ""} ${
                  !!integratedData.find(
                    (i) =>
                      i.name === item.name &&
                      i.ip_status !== "OK" &&
                      item.status !== "Active",
                  )
                    ? styles.ipRequired
                    : ""
                }`}
                key={item.name}
                id={item.id}
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                  alt={item.name}
                />
                <span className={styles.exchangeName}>{item.name}</span>
                {certificatedExchange.includes(topExchangesId[i]) && (
                  <LiaCertificateSolid
                    title={"Certificated"}
                    className={styles.certificatedBadge}
                  />
                )}
              </li>
            ))}
          <li
            className={styles.exchangeItem}
            onClick={() => setShowExchanges(true)}
          >
            <TfiMoreAlt />
            <span>More</span>
          </li>
          {integratedDataLoading && (
            <li className={styles.loadingOverlay}>
              <div className={styles.loaderBox}>
                <Spin />
                <p>
                  Our platform is currently assessing the expiration status for
                  the exchanges integrated within our system.
                </p>
              </div>
            </li>
          )}
        </ul>
      )}

      <ExchangeRecommendationModal
        show={showRecommendationModal}
        setShow={setShowRecommendationModal}
        exchange={{ name: selectedExchangeName, id: selectedExchangeId }}
      />

      <ExchangesModal show={showExchanges} setShow={setShowExchanges} />
      <UpdateExchangeIpModal
        show={showIpSettingsModal}
        setShow={setShowIpSettingsModal}
        exchangeData={selectedExchange}
      />
    </div>
  );
}

export default TopExchanges;

import { useSelector } from "react-redux";
import Loader from "../shared/Loader";
import ErrorBox from "../shared/ErrorBox";
import MiniAppTopBot from "./MiniAppTopBot";

function MiniAppTopBots({ type }) {
  const {
    botState: { botStatisticLoading, botStatisticData, botStatisticError },
  } = useSelector((state) => state);

  const outputData =
    Object.keys(botStatisticData).length > 0
      ? [...botStatisticData[type]].slice(0, 3)
      : [];

  // ?.sort((a, b) =>
  //       new Date(b?.bot_created_date) > new Date(a?.bot_created_date) ? 1 : -1,
  //   )

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {botStatisticLoading && <Loader />}
      {!botStatisticLoading && botStatisticError && (
        <ErrorBox style={{ height: "200px" }} />
      )}
      {!botStatisticLoading &&
        !botStatisticError &&
        outputData.map((bot) => (
          <MiniAppTopBot
            data={bot}
            key={`${bot.bot_created_date}${bot.bot_name}`}
          />
        ))}
    </div>
  );
}

export default MiniAppTopBots;

import React from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCaretRight } from "react-icons/ai";
import { setStep, setTradeType } from "../../../redux/trade/actions";
import { checkUsdtAmount } from "../../../utility/tradeHelper";
import { message } from "antd";

function Step1B({ step }) {
  const {
    tradeState: { tradeType },
    exchangeState: { portfolioData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  return (
    <div className={`${step === "1-b" ? styles.active : ""} ${styles.step}`}>
      <h3 className={styles.title}>
        <AiFillCaretRight />
        What type of trade do you want to do?
      </h3>
      <ul className={styles.tradeTypes}>
        <li
          className={`${styles.tradeType} ${
            tradeType === "buy" ? styles.selected : ""
          } ${!checkUsdtAmount(portfolioData) ? styles.inactive : ""}`}
          onClick={() => {
            if (!checkUsdtAmount(portfolioData)) {
              message.warning(
                "You don't have enough 'USDT' in your portfolio!",
              );
              return;
            }
            dispatch(setTradeType("buy"));
          }}
        >
          Buy
        </li>
        <li
          className={`${styles.tradeType} ${
            tradeType === "sell" ? styles.selected : ""
          }`}
          onClick={() => dispatch(setTradeType("sell"))}
        >
          Sell
        </li>
      </ul>
      <div className={styles.btnContainer}>
        <span
          className={`${styles.nextBtn} ${tradeType ? "" : styles.inactive}`}
          onClick={() => dispatch(setStep("2"))}
        >
          Next
        </span>
      </div>
    </div>
  );
}

export default Step1B;

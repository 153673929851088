import { Modal, message } from "antd";
import { useEffect, useState } from "react";
import { BiSmile } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import styles from "../../styles/Components/pricing-plan/Chat.module.css";
import TextArea from "antd/lib/input/TextArea";
import { IoMdSend } from "react-icons/io";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import typing from "../../asset/Icons/typing.gif";
import { sendChat } from "../../redux/pricing-plan/action";

const systemMsg1 =
  "Hello! We'd love to hear why you're thinking of leaving our service. Your insights matter to us.";
const systemMsg2 =
  "We appreciate your feedback! Our team will be in touch with you shortly. Kindly keep an eye on your email for further communication.";

function Chat({ show, close, planType }) {
  const [showSystemMessage, setShowSystemMessage] = useState(false);
  const [firstMessageDate, setFirstMessageDate] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [showUserMessage, setShowUserMessage] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const {
    main: { userData },
    pricingPlan: { sendChatLoading, sendChatMessage },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowSystemMessage(true);
        setFirstMessageDate(moment());
        //   setShowInput(true);
      }, 500);
      setTimeout(() => {
        setShowInput(true);
      }, 700);
    }
  }, [show]);

  const sendMessageHandler = () => {
    const regex = /^[a-zA-Z0-9\s?/\,.$&amp;]{10,}$/;
    const text = userMessage.trim();
    if (regex.test(text)) {
      const formatText = `system: \n ${systemMsg1} \n \n user: ${text} \n \n system: ${systemMsg2}`;
      setShowInput(false);
      setShowUserMessage(true);
      setTimeout(() => {
        dispatch(
          sendChat(
            userData["cognito:username"],
            userData?.["custom:custom_username"],
            formatText,
            planType,
          ),
        );

        setShowResponse(true);
      }, 1000);
    } else {
      message.warning(
        "Please enter a valid message, it should be at least 10 character!",
      );
    }
  };

  const closeHandler = () => {
    close(false);
    setShowInput(false);
    setShowResponse(false);
    setShowSystemMessage(false);
    setShowUserMessage(false);
    setUserMessage("");
  };
  return (
    <Modal
      title="Let's chat"
      centered
      open={show}
      closable={false}
      footer={null}
      style={{ overflow: "hidden" }}
      children={
        <div className={styles.container}>
          <div
            className={styles.messageContainer}
            style={{ alignItems: "flex-start" }}
          >
            <div
              className={`${styles.systemMessage} ${styles.message} ${
                showSystemMessage ? styles.show : ""
              }`}
            >
              <p>
                Hello! We'd love to hear why you're thinking of leaving our
                service.
                <br /> Your insights matter to us.
                <BiSmile fontSize={17} />
              </p>
            </div>
            {firstMessageDate && (
              <div className={styles.msgTime}>
                <AiOutlineClockCircle style={{ fontStyle: "italic" }} />
                <span>{firstMessageDate.format("hh:mm")}</span>
              </div>
            )}
          </div>
          <div
            className={styles.messageContainer}
            style={{ alignSelf: "flex-end" }}
          >
            <div
              className={`${styles.userMessage} ${styles.message} ${
                showUserMessage ? styles.sent : ""
              }`}
            >
              <p style={{ whiteSpace: "pre-wrap" }}>{userMessage}</p>
            </div>
            {showUserMessage && (
              <div className={styles.msgTime}>
                <AiOutlineClockCircle style={{ fontStyle: "italic" }} />
                <span>{moment().format("hh:mm")}</span>
              </div>
            )}
          </div>
          {showResponse && (
            <div
              className={styles.messageContainer}
              style={{ alignItems: "flex-start" }}
            >
              <div
                className={`${styles.responseMessage} ${styles.message}`}
                style={{ display: "inline-block" }}
              >
                {sendChatLoading ? (
                  <img src={typing} alt="typing" style={{ width: "40px" }} />
                ) : (
                  <p>
                    We appreciate your feedback! Our team will be in touch with
                    you shortly. Kindly keep an eye on your email for further
                    communication.
                    <BiSmile fontSize={17} />
                  </p>
                )}
              </div>
              {!sendChatLoading && sendChatMessage && (
                <div className={styles.msgTime}>
                  <AiOutlineClockCircle style={{ fontStyle: "italic" }} />
                  <span>{moment().format("hh:mm")}</span>
                </div>
              )}
            </div>
          )}
          {!sendChatLoading && sendChatMessage && showResponse && (
            <span className={styles.closeBtn} onClick={closeHandler}>
              Close
            </span>
          )}
          <div
            className={`${styles.inputBox} ${
              showInput ? styles.openInput : ""
            }`}
            style={{ display: `${!showInput ? "none" : "flex"}` }}
          >
            <TextArea
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Leave your message..."
              rows={3}
              style={{ resize: "none" }}
            />
            <span className={styles.sendBtn} onClick={sendMessageHandler}>
              Send
              <IoMdSend />
            </span>
          </div>
        </div>
      }
    />
  );
}

export default Chat;

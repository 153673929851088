import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/mini-app/shared/ExchangesListDropdownMenu.module.css";

function ExchangesListDropdownMenu({
  setExchange,
  selectedExchange,
  customStyle,
  customClassName,
}) {
  const {
    exchangeState: {
      exchangesLoading,
      integratedData,
      integratedDataLoading,
      exchangesConnectionInfo,
    },
  } = useSelector((state) => state);

  const notIntegratedExchanges = [...exchangesConnectionInfo]
    ?.filter((item) => item.id !== "cryptocrispy")
    ?.filter((ex) => !integratedData.some((intgEx) => intgEx?.id === ex?.id));

  const options = notIntegratedExchanges.map((item) => {
    const { id, name } = item;
    return {
      // value: item.includes("/") ? item.split("/")[0] : item,
      value: id,
      label: (
        <p className={styles.exchange}>
          <img
            className={styles.symbolIcon}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${id}.svg`}
            alt={id}
          />
          {name}
        </p>
      ),
    };
  });
  return (
    <Select
      showSearch
      className={`${styles.select} ${customClassName || ""}`}
      style={customStyle || {}}
      placeholder={"Select an exchange"}
      optionFilterProp="children"
      value={selectedExchange || "Explore exchanges"}
      filterOption={(input, option) =>
        (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onChange={(value) => setExchange(value)}
      options={
        exchangesLoading || integratedDataLoading
          ? [{ label: "Loading...", value: "" }]
          : options
      }
    />
  );
}

export default ExchangesListDropdownMenu;

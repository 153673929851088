import styles from "../styles/shared/RiskAppetiteOptions.module.css";
import { FaBalanceScale, FaShieldAlt } from "react-icons/fa";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { HiRocketLaunch } from "react-icons/hi2";
import { AiTwotoneFire } from "react-icons/ai";
import { useState } from "react";

function RiskAppetiteOptions({
  defaultRisk,
  activeBtnClassName,
  containerClassName,
  onChange,
  riskBtnClassName,
  riskBtnStyle,
}) {
  const [selectedRisk, setSelectedRisk] = useState(defaultRisk || "");

  const changeRiskHandler = (e) => {
    const targetRiskEl = e.target.closest("li");
    if (targetRiskEl) {
      const riskValue = targetRiskEl.id;
      setSelectedRisk(riskValue);
      onChange(riskValue);
    }
  };

  return (
    <ul
      className={`${containerClassName || styles.container}`}
      onClick={changeRiskHandler}
    >
      <li
        id={"1"}
        title="Slow and Steady"
        className={`${
          selectedRisk === "1" ? activeBtnClassName || styles.active : ""
        } ${riskBtnClassName || styles.riskBtn}`}
        style={riskBtnStyle || {}}
      >
        <FaShieldAlt className={styles.riskIcon} />
        Very Cautious
      </li>

      <li
        id={"2"}
        title="Safe and Sound"
        className={`${
          selectedRisk === "2" ? activeBtnClassName || styles.active : ""
        } ${riskBtnClassName || styles.riskBtn}`}
        style={riskBtnStyle || {}}
      >
        <BsExclamationTriangleFill className={styles.riskIcon} />
        Cautious
      </li>

      <li
        id={"3"}
        title="Steady as She Goes"
        className={`${
          selectedRisk === "3" ? activeBtnClassName || styles.active : ""
        } ${riskBtnClassName || styles.riskBtn}`}
        style={riskBtnStyle || {}}
      >
        <FaBalanceScale className={styles.riskIcon} />
        Balanced
      </li>

      <li
        id={"4"}
        title="High Wire Act"
        className={`${
          selectedRisk === "4" ? activeBtnClassName || styles.active : ""
        } ${riskBtnClassName || styles.riskBtn}`}
        style={riskBtnStyle || {}}
      >
        <HiRocketLaunch className={styles.riskIcon} />
        Adventurous
      </li>

      <li
        id={"5"}
        title="All In"
        className={`${
          selectedRisk === "5" ? activeBtnClassName || styles.active : ""
        } ${riskBtnClassName || styles.riskBtn}`}
        style={riskBtnStyle || {}}
      >
        <AiTwotoneFire className={styles.riskIcon} />
        Very Adventurous
      </li>
    </ul>
  );
}

export default RiskAppetiteOptions;

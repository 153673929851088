import { Table } from "antd";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatCustomDate } from "../../../utility/helper";
import { activateBot, selectBackteset } from "../../../redux/bot/actions";
import ExpandedRowBotTable from "../bot-configuration/ExpandedRowBotTable";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import BotBacktestTableExpandedRow from "./BotBacktestTableExpandedRow";

const statusFormatter = {
  processing: "Under Process",
  processed: "Finished",
  not_processed: "Pending",
  failed: "Failed",
};

function BotBacktestHistoryTable() {
  const {
    botState: {
      selectedBacktest,
      backtestHistoryData,
      backktestHistoryLoading,
    },
  } = useSelector((state) => state);

  const sortedData = [...backtestHistoryData].sort(
    (a, b) => new Date(b?.createdDate) - new Date(a?.createdDate),
  );

  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState([]);

  const onRowClick = (record) => {
    if (expandedRowKey.includes(record.key)) {
      return setExpandedRowKey([]);
    }
    setExpandedRowKey([record.key]);
  };

  const handleRowExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.key : []);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
      dispatch(selectBackteset(keys.length > 0 ? sortedData[keys[0]] : {}));
    },
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 8,
      align: "center",
      responsive: ["md"],
      render: (index) => <span>{index + 1}</span>,
    },
    {
      title: "Bot Name",
      dataIndex: "name",
      key: "name",
      width: 20,
      align: "center",
      render: (name) => <span>{name}</span>,
    },
    {
      title: "Request Time",
      dataIndex: "date",
      key: "date",
      width: 20,
      align: "center",
      responsive: ["md"],
      render: (date) => <span>{formatCustomDate(date, "yes")}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 20,
      align: "center",
      render: (date) => <span>{formatCustomDate(date)}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 20,
      align: "center",
      responsive: ["md"],
      render: (date) => <span>{formatCustomDate(date)}</span>,
    },
    {
      title: "Net Value Profit (%)",
      dataIndex: "netValue",
      key: "netValue",
      width: 20,
      align: "center",
      render: ([result, budget]) => {
        const value =
          result?.Total &&
          ((result?.Total?.value / Number(budget) - 1) * 100).toFixed(2);
        return (
          <span
            style={
              value ? { color: `${Number(value) > 0 ? "green" : "red"}` } : {}
            }
          >
            {value ? value + "%" : "TBA"}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 12,
      align: "center",
      responsive: ["md"],
      render: (status) => <span>{statusFormatter[status]}</span>,
    },
  ];

  useEffect(() => {
    if (!backktestHistoryLoading && backtestHistoryData.length) {
      setTableData(
        sortedData.map((item, i) => {
          const {
            bot_name,
            start_date,
            end_date,
            createdDate,
            results,
            budget,
            status,
          } = item;
          return {
            key: i,
            index: i,
            name: bot_name,
            date: createdDate,
            startDate: start_date,
            endDate: end_date,
            netValue: [results, budget],
            status,
            navROI: item?.nav_roi,
            winRates: item?.win_rates,
            closedPositions: item?.num_closed_positions,
          };
        }),
      );
    }
  }, [backktestHistoryLoading, backtestHistoryData]);

  useEffect(() => {
    if (Object.keys(selectedBacktest).length) {
      const index = sortedData.findIndex(
        (item) => item.createdDate === selectedBacktest.createdDate,
      );
      setSelectedRowKeys([index]);
    }
  }, [selectedBacktest]);

  useEffect(() => {
    if (backktestHistoryLoading) {
      dispatch(selectBackteset({}));
      setSelectedRowKeys([]);
    }
  }, [backktestHistoryLoading]);

  return (
    <Table
      columns={columns}
      scroll={{ y: 700 }}
      rowSelection={{
        type: "radio",
        ...rowSelection,
      }}
      height={500}
      pagination={false}
      dataSource={tableData}
      loading={backktestHistoryLoading}
      className={`${styles.table} bot-table`}
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
      expandable={{
        expandedRowRender: (record) => (
          <BotBacktestTableExpandedRow data={record} />
        ),
        expandedRowKeys: expandedRowKey,
        onExpand: handleRowExpand,
        expandIcon: ({ expanded, onExpand, record }) => {
          // if (Object.keys(record?.navROI).length === 0) return "";
          return expanded ? (
            <IoChevronUp
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <IoChevronDown
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          );
        },
      }}
    />
  );
}

export default BotBacktestHistoryTable;

import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { MdChecklistRtl } from "react-icons/md";
import React from "react";
import StepTitle from "./StepTitle";
import { RxDot } from "react-icons/rx";
import { BiEdit } from "react-icons/bi";
import { riskOptions } from "../../../../utility/data";

const EditButton = ({ step, setStep, setEditMode }) => {
  return (
    <BiEdit
      size={16}
      color={"#305cfc"}
      style={{ marginLeft: ".5rem", cursor: "pointer" }}
      onClick={() => {
        setStep(step);
        setEditMode(true);
      }}
    />
  );
};

function CreateBotReview({ botData, setStep, setEditMode }) {
  return (
    <>
      <StepTitle title="Confirmation" />
      <div>
        <h4 className={styles.stepTitle}>
          {/*<FaCaretRight />*/}
          <MdChecklistRtl />
          Review your selections:
        </h4>
        <ul className={styles.botDataList}>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Bot Name:</span>{" "}
            {botData.bot_name}
            <EditButton setEditMode={setEditMode} step={1} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Selected Exchange:</span>{" "}
            {botData.selected_exchange}
            <EditButton setEditMode={setEditMode} step={2} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Risk Tolerance:</span>{" "}
            {riskOptions[botData.risk_tolerance - 1]}
            <EditButton setEditMode={setEditMode} step={3} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Target Returns:</span>{" "}
            {botData.target_return_percentage}%
            <EditButton setEditMode={setEditMode} step={4} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Crypto Allocation:</span>{" "}
            {botData.crypto_allocation_strategy}
            <EditButton setEditMode={setEditMode} step={5} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>
              CryptoCrispy Signal Source:
            </span>{" "}
            {botData.signal_source}
            <EditButton setEditMode={setEditMode} step={6} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Trading Strategies:</span>{" "}
            {botData.selected_trading_strategies}
            <EditButton setEditMode={setEditMode} step={7} setStep={setStep} />
          </li>
          <li>
            <RxDot color={"#0C4CFC"} size={16} />
            <span style={{ fontWeight: "500" }}>Number of Coins:</span>{" "}
            {botData.number_of_coins}
            <EditButton setEditMode={setEditMode} step={8} setStep={setStep} />
          </li>
        </ul>
        <p>
          <strong>Note:</strong> By clicking "Confirm," you agree to let Your
          CryptoBot manage your crypto portfolio based on the selected
          preferences and AI recommendations.
        </p>
      </div>
    </>
  );
}

export default CreateBotReview;

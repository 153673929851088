import styles from "../../../styles/Components/pricing-plan/plans/PlanDurationTabs.module.css";
import { Badge } from "antd";
import { useSelector } from "react-redux";
import { servicesCost } from "../../../utility/data";

function PlanDurationTabs({ duration, setDuration }) {
  const {
    pricingPlan: { pricingPlan },
  } = useSelector((state) => state);

  const offPercent =
    pricingPlan?.["yearly_discount"] || servicesCost["yearly_discount"];

  return (
    <div className={styles.tabs}>
      <span
        className={`${styles.tab} ${
          duration === "monthly" ? styles.activeTab : ""
        }`}
        onClick={() => setDuration("monthly")}
      >
        Monthly
      </span>
      <Badge
        count={`${offPercent}% OFF`}
        offset={[-25, -2]}
        className={`${styles.badge} ${styles.tab} ${
          duration === "annually" ? styles.activeTab : ""
        }`}
        onClick={() => setDuration("annually")}
      >
        <span>Annually</span>
      </Badge>
    </div>
  );
}

export default PlanDurationTabs;

import { Modal, message } from "antd";
import styles from "../../styles/Components/auth/ReferralModal.module.css";
import { useState } from "react";
import alert from "../../asset/Icons/warn100.gif";
import shine from "../../asset/Icons/happy(100).gif";

const Content = ({ icon, title, children }) => {
  return (
    <>
      <img
        className={styles.icon}
        src={icon}
        alt="icon"
        style={{ width: "80px" }}
      />
      <h3 className={styles.title}>{title}</h3>
      {children}
    </>
  );
};

const titles = [
  `Do you have a referral code?`,
  "This is a great opportunity to get some rewards and discounts for yourself and your friends.",
];

function ReferralModal({ submit, setReferral, data, show, setShow }) {
  const [step, setStep] = useState(1);
  const [referralCode, setReferralCode] = useState("");
  const [referralValidation, setReferralValidation] = useState(true);

  const changeHandler = (e) => {
    const regex = /^[A-Z0-9]{10}$/;
    const code = e.target.value.trim();
    setReferralCode(code);
    setReferral(code);
    setReferralValidation(regex.test(code.toUpperCase()));
  };

  const submittHandler = () => {
    if (!referralValidation) {
      message.warning("Please enter a valid referral code.");
      return;
    }

    setReferral(referralCode);
    setShow(false);
    submit(data);
  };

  const cancelHandler = () => {
    setReferral("");
    setShow(false);
    submit(data);
  };

  return (
    <Modal
      open={show}
      closable={false}
      footer={false}
      centered
      children={
        <div className={styles.container}>
          {step === 1 && (
            <Content icon={alert} title={titles[0]}>
              <div className={styles.btns}>
                <span
                  className={`${styles.yesBtn} ${styles.btn}`}
                  onClick={() => setStep(2)}
                >
                  Yes, I have.
                </span>
                <span
                  className={`${styles.noBtn} ${styles.btn}`}
                  onClick={cancelHandler}
                >
                  No I don't.
                </span>
              </div>
            </Content>
          )}
          {step === 2 && (
            <Content icon={shine} title={titles[1]}>
              <div className={styles.inputBox}>
                <input
                  placeholder="Enter referral code"
                  className={`${styles.input} ${
                    !referralValidation && referralCode
                      ? styles.invalidInput
                      : ""
                  }`}
                  value={referralCode}
                  onChange={changeHandler}
                />

                <p
                  className={styles.invalidMessage}
                  style={{
                    opacity: `${!referralValidation && referralCode ? 1 : 0}`,
                  }}
                >
                  Enter a valid referral code!
                </p>
              </div>
              <div className={styles.btns}>
                <span
                  className={`${styles.yesBtn} ${styles.btn}`}
                  onClick={submittHandler}
                >
                  Submit
                </span>
                {/* <span className={`${styles.noBtn} ${styles.btn}`}>
                  No, I have not.
                </span> */}
              </div>
            </Content>
          )}
        </div>
      }
    />
  );
}

export default ReferralModal;

import React, { useEffect, useState } from "react";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { AiFillCaretRight } from "react-icons/ai";
import cryptoIcon from "../../../../asset/Icons/cryptoIcon.png";
import forexIcon from "../../../../asset/Icons/forexIcon.png";
import stockIcon from "../../../../asset/Icons/stockIcon.png";
import { message, Radio, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get200Coins } from "../../../../redux/trading-assistant/actions";
import {
  getVirtualPortfolio,
  setStep,
  setVirtualAssetAmount,
  setVirtualMarketType,
  setVirtualSecondTradeType,
  setVirtualSelectedAsset,
  setVirtualSelectedPortfolio,
  setVirtualTradeType,
  setVirtualTriggerPrice,
} from "../../../../redux/trade/actions";
import RechargeBudget from "../../RechargeBudget";
import Loader from "../Loader";
import VirtualPortfolioOptions from "./VirtualPortfolioOptions";

const marketTypeData = [
  {
    value: "crypto",
    label: "Crypto",
    icon: cryptoIcon,
  },
  {
    value: "forex",
    label: "Forex",
    icon: forexIcon,
  },
  {
    value: "stock",
    label: "Stock",
    icon: stockIcon,
  },
];

function VirtualTradeStep1() {
  // const [marketType, setMarketType] = useState("");
  const [options, setOptions] = useState([]);
  const [isDone, setIsDone] = useState(false);

  const {
    main: { userData },
    tradingAssistant: {
      _200Coins: { top_200_coin },
      get200CoinsLoading,
    },
    tradeState: {
      modalMode,
      virtualSelectedAsset,
      virtualMarketType,
      virtualSelectedPortfolio,
      virtualTradeType,
      virtualPortfolioLoading,
      virtualPortfolioData,
      virtualSecondTradeType,
    },
  } = useSelector((state) => state);

  const [buyTradeError, setBuyTradeError] = useState(false);

  const dispatch = useDispatch();

  const marketTypeOnChange = (e) => {
    // setMarketType(e.target.value);
    dispatch(setVirtualTradeType(e.target.value));
  };

  const handleTradeType = (type) => {
    setBuyTradeError(false);
    if (modalMode === "ai-btn") {
      dispatch(setVirtualSelectedAsset(""));
    }
    dispatch(setVirtualSecondTradeType(""));
    dispatch(setVirtualTriggerPrice("", ""));
    dispatch(setVirtualTradeType(type));
  };

  const symbolOnChange = (value) => {
    if (!virtualTradeType) return;
    if (virtualSecondTradeType) {
      dispatch(setVirtualSecondTradeType(""));
    }
    dispatch(setVirtualTriggerPrice("", ""));
    dispatch(setVirtualSelectedAsset(value));
    dispatch(setVirtualAssetAmount(""));
  };

  const focusHandler = () => {
    if (!virtualTradeType) return;
  };

  const goNextHandler = () => {
    if (!isDone) {
      return message.warning("Please enter the necessary fields.");
    }
    dispatch(setStep("paper-2"));
  };

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !top_200_coin &&
      !get200CoinsLoading
    ) {
      dispatch(
        get200Coins(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, top_200_coin, dispatch, get200CoinsLoading]);

  useEffect(() => {
    if (
      virtualTradeType === "buy" &&
      !virtualPortfolioLoading &&
      Object.keys(virtualPortfolioData).length > 0
    ) {
      const budget = Number(virtualPortfolioData.REMANINING_INVESTMENT_BUDGET);
      if (budget < 1) {
        setBuyTradeError(true);
      }
    }
  }, [virtualTradeType, virtualPortfolioLoading, virtualPortfolioData]);

  useEffect(() => {
    if (
      virtualTradeType &&
      Object.keys(virtualPortfolioData).length > 0 &&
      !virtualPortfolioLoading &&
      virtualMarketType
    ) {
      if (top_200_coin) {
        let symbols;

        if (virtualTradeType === "buy") {
          symbols = top_200_coin.map((item) => {
            return {
              value: item,
              label: (
                <p>
                  <img
                    className={styles.symbolIcon}
                    src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                    alt=""
                  />
                  {item}
                </p>
              ),
            };
          });
        }
        if (virtualTradeType === "sell") {
          const assets = virtualPortfolioData?.PORTFOLIO_TABLE_ROWS;

          let filteredAssets = [];

          for (let i = 0; i < assets.length; i++) {
            // Check if the market type is equal to the selected market type
            if (assets[i]?.MARKET_TYPE === virtualMarketType) {
              // If yes, push the corresponding asset to the filtered array
              filteredAssets.push(assets[i]?.SYMBOL_NAME);
            }
          }

          symbols = filteredAssets.map((item) => {
            return {
              value: item,
              label: (
                <p>
                  <img
                    className={styles.symbolIcon}
                    src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                    alt=""
                  />
                  {item}
                </p>
              ),
            };
          });
        }

        setOptions(symbols);
      }
    }
  }, [
    top_200_coin,
    virtualTradeType,
    virtualMarketType,
    virtualPortfolioData,
    virtualPortfolioLoading,
  ]);

  useEffect(() => {
    if (
      virtualSelectedPortfolio &&
      Object.keys(virtualPortfolioData).length === 0
    ) {
      dispatch(
        getVirtualPortfolio(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedPortfolio.toLowerCase(),
        ),
      );
    }
  }, [virtualSelectedPortfolio]);

  useEffect(() => {
    if (virtualSelectedPortfolio && virtualTradeType && virtualSelectedAsset) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [virtualSelectedAsset, virtualSelectedPortfolio, virtualTradeType]);

  useEffect(() => {
    if (!virtualMarketType) {
      dispatch(setVirtualMarketType("crypto"));
    }
  }, [virtualMarketType]);

  return (
    <div>
      <div>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Choose market type
        </h3>
        <Radio.Group
          className={styles.marketTypeOptions}
          onChange={marketTypeOnChange}
          value={virtualMarketType || "crypto"}
        >
          {marketTypeData.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              className={styles.marketType}
              disabled={item.value !== "crypto"}
            >
              {" "}
              <img
                src={item.icon}
                className={styles.marketTypeIcon}
                alt={item.value}
              />{" "}
              {item.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>

      <div className={styles.portfolioBox}>
        <h3 className={styles.title}>
          <AiFillCaretRight />
          Choose your portfolio:
        </h3>
        <VirtualPortfolioOptions />
      </div>

      <div
        className={`${styles.tradeTypeBox} ${
          !virtualSelectedPortfolio ? styles.inactive : ""
        }`}
      >
        <h3 className={styles.title}>
          <AiFillCaretRight />
          What type of trade do you want to do?
        </h3>
        <ul className={styles.tradeTypes}>
          <li
            className={`${styles.tradeType} ${
              virtualTradeType === "buy" ? styles.selected : ""
            }`}
            style={
              !virtualSelectedPortfolio ? { cursor: "default" } : undefined
            }
            onClick={() => handleTradeType("buy")}
          >
            Buy
          </li>
          <li
            className={`${styles.tradeType} ${
              virtualTradeType === "sell" ? styles.selected : ""
            }`}
            style={
              !virtualSelectedPortfolio ? { cursor: "default" } : undefined
            }
            onClick={() => handleTradeType("sell")}
          >
            Sell
          </li>
        </ul>
      </div>

      {virtualTradeType === "buy" &&
      !virtualPortfolioLoading &&
      buyTradeError ? (
        <div className={styles.budgetError}>
          <RechargeBudget
            portfolio={virtualSelectedPortfolio.toLowerCase()}
            neededBudget={1}
          />
        </div>
      ) : (
        <div
          className={`${styles.coinBox} ${
            !virtualTradeType ? styles.inactive : ""
          }`}
        >
          <div>
            <h3 className={styles.title}>
              <AiFillCaretRight />
              Select a coin
            </h3>
            {virtualPortfolioLoading ? (
              <Loader text={"Getting asset"} />
            ) : (
              <Select
                showSearch
                className={styles.select}
                value={virtualSelectedAsset}
                placeholder="Select a Coin"
                optionFilterProp="children"
                onChange={symbolOnChange}
                onFocus={focusHandler}
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
              />
            )}
          </div>
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <span
          className={`${styles.nextBtn} ${!isDone ? styles.inactive : ""}`}
          onClick={goNextHandler}
        >
          Next
        </span>
      </div>
    </div>
  );
}

export default VirtualTradeStep1;

import styles from "../../styles/Components/dashboard/ContentContainer.module.css";

function ContentContainer({ children, className, style }) {
  return (
    <div className={`${className || ""} ${styles.content}`} style={style || {}}>
      {children}
    </div>
  );
}

export default ContentContainer;

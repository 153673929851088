import React, { useState } from "react";
import Modal from "../modal/Modal";
import styles from "../../styles/Components/layout/DemoModal.module.css";
import { BsFillPlayCircleFill } from "react-icons/bs";
import VideoPlayer from "../player/VideoPlayer";
import aiposter from "../../asset/Images/aiPoster.jpg";
import insightPoster1 from "../../asset/Images/videoPoster1.jpg";
import alertPoster from "../../asset/Images/tut-alert.jpg";
import integrationPoster from "../../asset/Images/tut-integration.jpg";
import botPoster from "../../asset/Images/tut-bot.jpg";
import dashboardPoster from "../../asset/Images/tut-dashboard.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const demoData = {
  Dashboard: {
    videoUrl: "https://d13eiv722he0se.cloudfront.net/dashboard_demo.mp4",
    poster: dashboardPoster,
  },
  AI_Assistant: {
    videoUrl: "https://d13eiv722he0se.cloudfront.net/ai_assistant_demo.mp4",
    poster: aiposter,
  },
  Integration: {
    videoUrl:
      "https://d13eiv722he0se.cloudfront.net/CryptoCrispy_Integration_demo.mp4",
    poster: integrationPoster,
  },
  Bot_and_Automation: {
    videoUrl: "https://d13eiv722he0se.cloudfront.net/CryptoCrispy_Bot_demo.mp4",
    poster: botPoster,
  },
  Alert_System: {
    videoUrl: "https://d13eiv722he0se.cloudfront.net/alert_system_demo.mp4",
    poster: alertPoster,
  },
  Insight: {
    videoUrl: "https://d13eiv722he0se.cloudfront.net/insight_demo1.mp4",
    poster: insightPoster1,
  },
};

function DemoModal({ open, handleClose, page }) {
  const [showVideo, setShowVideo] = useState(false);

  const playVideoHandler = () => {
    handleClose();
    setShowVideo(true);
  };

  return (
    <>
      <Modal
        title={`Watch this video to quickly learn how to use ${page.replaceAll(
          "_",
          " ",
        )}`}
        open={open}
        handleCancel={handleClose}
        width={550}
        footer={null}
        centered
        content={
          <div className={styles.wrapper}>
            <LazyLoadImage
              src={demoData?.[page]?.poster}
              alt={page}
              effect="blur"
              style={{ minHeight: "100px" }}
              width="100%"
            />
            {/*<img src={demoData?.[page]?.poster} alt={page} />*/}

            <div className={styles.videoController}>
              <span>Introduction to {page.replaceAll("_", " ")}</span>
              <BsFillPlayCircleFill size={35} onClick={playVideoHandler} />
            </div>
          </div>
        }
      />
      <VideoPlayer
        clickHandler={handleClose}
        show={showVideo}
        hide={setShowVideo}
        url={demoData?.[page]?.videoUrl}
      />
    </>
  );
}

export default DemoModal;

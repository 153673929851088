import React, { useEffect, useState } from "react";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { updateFeaturesList } from "../../redux/insight/action";
import { AiFillLock, AiOutlineInfoCircle } from "react-icons/ai";
import uniqid from "uniqid";
import { Modal, Tooltip } from "antd";
import styles from "../../styles/Components/multiLevelSideBar.module.sass";
import { notify } from "../../utility/Toastify/toastify";

const MultiLevelSideBar = () => {
  const {
    selectedFeatures,
    initialInfo: { CATEGORIES_FEATURE_TREE, categories_tooltip_mapping },
  } = useSelector((state) => state?.insightState);

  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(selectedFeatures);
  const dispatch = useDispatch();
  const [newObject, setNewObject] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSymbolId, setSelectedSymbolId] = useState("");
  const handleSidebarToggle = (isOpen) => {
    setIsOpen(isOpen);
  };
  useEffect(() => {
    if (CATEGORIES_FEATURE_TREE) {
      setNewObject([]);
      Object?.keys(CATEGORIES_FEATURE_TREE)?.map((item, index) => {
        return setNewObject((prev) => [
          ...prev,
          {
            content: [
              {
                id: uniqid(),
                name: (
                  <span data-tut="reactour__test1">
                    <span>{item}</span>
                    <Tooltip
                      placement="topRight"
                      title={categories_tooltip_mapping?.[item]}
                    >
                      {" "}
                      <AiOutlineInfoCircle />
                    </Tooltip>
                  </span>
                ),
                icon: (
                  <img
                    src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_category_icons/${item}.png`}
                    alt={item}
                  />
                ),
                children: [
                  ...Object.keys(CATEGORIES_FEATURE_TREE[item]).map(
                    (itm, idx) => {
                      return {
                        // title: "title",
                        content: [
                          {
                            id: uniqid(),
                            name: itm,
                            icon: (
                              <img
                                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_subcategory_icons/${itm}.png`}
                                alt={item}
                              />
                            ),
                            children: [
                              ...Object.keys(
                                CATEGORIES_FEATURE_TREE[item][itm],
                              ).map((itm2, idx2) => {
                                return {
                                  // title: "title",

                                  content: [
                                    {
                                      id: uniqid(),
                                      name: itm2,
                                      icon: CATEGORIES_FEATURE_TREE[item][itm][
                                        itm2
                                      ]?.accessibility === "Pro" && (
                                        <AiFillLock />
                                      ),
                                      options: {
                                        value:
                                          CATEGORIES_FEATURE_TREE[item][itm][
                                            itm2
                                          ],
                                      },
                                      // disabled: true
                                    },
                                  ],
                                };
                              }),
                            ],
                          },
                        ],
                      };
                    },
                  ),
                ],
              },
            ],
          },
        ]);
      });
    }
  }, [CATEGORIES_FEATURE_TREE]);
  useEffect(() => {
    setChecked(selectedFeatures);
  }, [selectedFeatures]);

  const handleClick = (e) => {
    if (!e?.children) {
      if (selectedFeatures?.length >= 3) {
        return notify("You can't select more than 3 signal", "info");
      }
      setChecked((prev) => [
        ...prev,
        {
          symbol_list: e?.options?.value?.symbol_list,
          symbol_icon_list: e?.options?.value?.symbol_icon_list,
          subcategory: e?.options?.value?.subcategory,
          category: e?.options?.value?.category,
          feature: e?.name,
          description: e?.options?.value?.description,
          feature_key: e?.options?.value?.feature_key,
        },
      ]);
      if (!e?.options?.value?.symbol_list?.length > 0) {
        dispatch(
          updateFeaturesList([
            ...checked,
            {
              symbol_list: e?.options?.value?.symbol_list,
              symbol_icon_list: e?.options?.value?.symbol_icon_list,
              subcategory: e?.options?.value?.subcategory,
              category: e?.options?.value?.category,
              feature: e?.name,
              symbol: "",
              description: e?.options?.value?.description,
              feature_key: e?.options?.value?.feature_key,
            },
          ]),
        );
      } else {
        setIsModalOpen(true);
      }
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(updateFeaturesList([...checked]));
    setSelectedSymbolId("");
  };

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setChecked((prev) =>
      prev?.filter((item, index) => index != prev?.length - 1 && item),
    );
  };

  return (
    <>
      <div
        className={styles.multiLevelSideBarContainer}
        data-tut="reactour__sideBar"
      >
        {newObject?.length > 0 && (
          <MultilevelSidebar
            open={true}
            onToggle={handleSidebarToggle}
            onItemClick={handleClick}
            options={newObject}
          />
        )}
      </div>

      <Modal
        title="Symbols"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={600}
        style={{ borderRadius: 8 }}
      >
        <div className={styles.modalItems}>
          {checked[checked?.length - 1]?.symbol_list?.map((item, index) => {
            return (
              <div
                className={`${styles.modalItem} ${
                  selectedSymbolId === index ? styles.activeSymbol : ""
                }`}
                onClick={() => {
                  let newList = checked;
                  newList[checked?.length - 1]["symbol"] =
                    checked[checked?.length - 1]?.symbol_list?.length > 0
                      ? item
                      : null;
                  newList[checked?.length - 1]["symbol_icon"] =
                    checked[checked?.length - 1]?.symbol_icon_list[index];
                  setChecked(newList);
                  setSelectedSymbolId(index);
                }}
              >
                <span>{item}</span>
                <span>
                  <img
                    src={
                      checked?.[checked?.length - 1]?.symbol_icon_list?.[index]
                    }
                  />
                </span>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default MultiLevelSideBar;

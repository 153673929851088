import { Modal } from "antd";

export const amountValidation = (
  type,
  amount,
  price,
  usdtAmount,
  sellAmount,
) => {
  if (type === "sell") {
    return Number(amount) * Number(price) <= Number(sellAmount);
  }
  if (type === "buy") {
    return Number(amount) * Number(price) <= Number(usdtAmount);
  }
};

export const checkIsCoin = (coinList, asset) => {
  // if (!coinList) return;
  return coinList.includes(asset.split("/")[0]);
};

export const checkUsdtAmount = (data) => {
  if (data && data.length) {
    const asset = data.find((item) => item.assetName === "USDT");
    if (asset && parseFloat(asset.availableBalanceUSD) > 0) {
      return true; // Item with the specified assetName exists and availableBalanceUSD is greater than 0
    }
    return false; // Item does not exist or availableBalanceUSD is not greater than 0
  }
};

export const extractCoinName = (str) => {
  return str.replace("/USDT", "");
};

export const isCoinInAssetList = (assetList, coin, exchange) => {
  if (assetList && assetList.length > 0) {
    const coinName = exchange === "swyftx" ? coin.replace("/USDT", "") : coin;
    return assetList.find((item) => item === coinName);
  }
};

export const isCoinInPortfolio = (portfolio, coin, exchange) => {
  if (portfolio.length) {
    // const coinName =
    //   exchange === "swyftx" || exchange === "cryptocrispy"
    //     ? coin.replace("/USDT", "")
    //     : coin;
    const coinName = coin.replace("/USDT", "");
    return portfolio.find(
      (item) => item.assetName.replace("/USDT", "") === coinName,
    );
  }
};

export const checkBudgetIsEnough = (data) => {
  if (data && data.length > 0) {
    const usdt = data.find((item) => item.assetName === "USDT");
    if (usdt) {
      return parseFloat(usdt.availableBalanceUSD) > 1;
    } else {
      return false;
    }
  }
};

export const isCoinInVirtualPortfolio = (portfolio, coin) => {
  if (Object.keys(portfolio).length) {
    const { PORTFOLIO_TABLE_ROWS: data } = portfolio;
    return data.find((item) => item.SYMBOL_NAME === coin);
  }
};

export const formatAssetNameForTrade = (asset, exchange) => {
  const isSwyftx = exchange === "swyftx";
  const hasUsdt = asset.toLowerCase().includes("/usdt");

  if (isSwyftx) {
    return asset.split("/")[0];
  } else {
    return !hasUsdt ? asset + "/USDT" : asset;
  }
};

export const showWarning = () => {
  Modal.confirm({
    title: "Warning",
    centered: true,
    content:
      "We recommend not conducting manual trades in this exchange since the bot is also pushing trades.",
    cancelText: "Ok",
    okButtonProps: { style: { display: "none" } },
    onCancel: () => {
      Modal.destroyAll();
    },
  });
};

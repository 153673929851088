import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExchangesInfo,
  integrateWithExchange,
} from "../../../redux/exchange/actions";
import Loader from "../shared/Loader";
import IntegrationForm from "../shared/IntegrationForm";
import styles from "../../../styles/Components/mini-app/mini-bots/ReintegrateForm.module.css";
import { Button } from "antd";

function ReintegrateForm({ exchange, checkStatus, closeForm, buttonTitle }) {
  const {
    main: { userData },
    exchangeState: {
      integrateExchangeLoading,
      integrateExchangeMessage,
      exchangesConnectionInfo,
      exchangesLoading,
      trustedIps,
    },
  } = useSelector((state) => state);

  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [isRestricted, setIsRestricted] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);

  const dispatch = useDispatch();

  const connectionInfo = exchangesConnectionInfo.find(
    (ex) => ex.id === exchange,
  );

  const isValidForIntegration =
    exchange &&
    (exchange === "cryptocrispy" ||
      (apiKey &&
        (!connectionInfo?.mandatory?.includes("passphrase") || passphrase) &&
        (!connectionInfo?.mandatory?.includes("secret") || secretKey)));

  const integrateHandler = () => {
    if (integrateExchangeLoading || !isValidForIntegration) return;

    const ip = isRestricted ? trustedIps[0] : "";
    dispatch(
      integrateWithExchange(
        userData["custom:custom_username"],
        userData?.email,
        apiKey,
        secretKey,
        exchange,
        passphrase,
        ip,
        connectionInfo?.name,
        false,
      ),
    );
    setIsIntegrated(true);
  };

  useEffect(() => {
    if (
      userData?.email &&
      !exchangesConnectionInfo.length &&
      !exchangesLoading
    ) {
      dispatch(getExchangesInfo());
    }
  }, [exchangesConnectionInfo, userData, exchangesLoading, dispatch]);

  useEffect(() => {
    if (
      Object.keys(integrateExchangeMessage).length > 0 &&
      isIntegrated &&
      checkStatus
    ) {
      checkStatus();
      setIsIntegrated(false);
    }
  }, [integrateExchangeMessage, isIntegrated]);

  return (
    <div className={styles.container}>
      {exchangesLoading && (
        <Loader height={"120px"} title={"Preparing integration form"} />
      )}
      {!exchangesLoading && exchangesConnectionInfo.length > 0 && (
        <IntegrationForm
          exchange={exchange}
          apiKey={apiKey}
          setApiKey={setApiKey}
          secretKey={secretKey}
          setSecretKey={setSecretKey}
          isRestricted={isRestricted}
          setIsRestricted={setIsRestricted}
          passphrase={passphrase}
          setPassphrase={setPassphrase}
        />
      )}
      <Button
        loading={integrateExchangeLoading}
        disabled={!isValidForIntegration}
        className={styles.actionBtn}
        onClick={integrateHandler}
      >
        {buttonTitle}
      </Button>
    </div>
  );
}

export default ReintegrateForm;

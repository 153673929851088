import { Button, message } from "antd";
import styles from "../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIntegratedExchanges } from "../../../redux/exchange/actions";
import { createBot } from "../../../redux/bot/actions";
import CreateBitStep1 from "./Steps/CreateBitStep1";
import CreateBotStep2 from "./Steps/CreateBotStep2";
import CreateBotStep3 from "./Steps/CreateBotStep3";
import CreateBotStep4 from "./Steps/CreateBotStep4";
import CreateBotStep5 from "./Steps/CreateBotStep5";
import CreateBotStep6 from "./Steps/CreateBotStep6";
import CreateBotStep7 from "./Steps/CreateBotStep7";
import CreateBotStep8 from "./Steps/CreateBotStep8";
import CreateBotReview from "./Steps/CreateBotReview";
import { RxCross2 } from "react-icons/rx";

function CreateBotSteps({ step, setStep, goHome }) {
  const {
    exchangeState: { integratedData, integratedDataLoading, getIntegratedList },
    botState: { createBotLoading },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [botData, setBotData] = useState({
    bot_name: "CryptoCrispy bot1",
    selected_exchange: "None",
    trade_in_virtual_portfolio: "False",
    risk_tolerance: "3",
    target_return_percentage: "10",
    crypto_allocation_strategy: "balanced",
    signal_source: "AI_Assistant",
    ai_assistant_mode: "default",
    selected_trading_strategies: ["Time-based AI-assisted"],
    number_of_coins: "3",
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const changeBotData = (key, value) => {
    if (key === "selected_trading_strategies") {
      let updatedStrategiesList;
      if (botData.selected_trading_strategies.includes(value)) {
        updatedStrategiesList = botData.selected_trading_strategies.filter(
          (item) => item !== value,
        );
      } else {
        updatedStrategiesList = [...botData.selected_trading_strategies, value];
      }

      setBotData((prevState) => {
        return {
          ...prevState,
          [key]: updatedStrategiesList,
        };
      });
      return;
    }

    setBotData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleNextStep = () => {
    if (step < 9) {
      if (step === 1 && botData["bot_name"].length < 3)
        return message.info("The name must be minimum 3 character.");
      if (step === 2 && integratedDataLoading)
        return message.info("Please wait to loading data");
      if (step === 7 && botData.selected_trading_strategies.length === 0)
        return message.info("Please select a valid option");
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleConfirm = async () => {
    if (!createBotLoading) {
      await dispatch(
        createBot(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          botData,
        ),
      ).then(() => {
        goHome();
      });
    }
  };

  useEffect(() => {
    if (
      Object.keys(userData).length &&
      !integratedDataLoading &&
      !getIntegratedList
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    dispatch,
    integratedData,
    getIntegratedList,
    integratedDataLoading,
    userData,
  ]);

  return (
    <div className={styles.container}>
      <>
        <span className={styles.cancelBtn} onClick={() => goHome()}>
          <RxCross2 />
          Cancel
        </span>
        {step === 1 && (
          <CreateBitStep1 botData={botData} changeBotData={changeBotData} />
        )}

        {step === 2 && (
          <CreateBotStep2 botData={botData} changeBotData={changeBotData} />
        )}

        {step === 3 && (
          <CreateBotStep3 botData={botData} changeBotData={changeBotData} />
        )}

        {step === 4 && (
          <CreateBotStep4 changeBotData={changeBotData} botData={botData} />
        )}

        {step === 5 && (
          <CreateBotStep5 changeBotData={changeBotData} botData={botData} />
        )}

        {step === 6 && (
          <CreateBotStep6 changeBotData={changeBotData} botData={botData} />
        )}

        {step === 7 && (
          <CreateBotStep7 botData={botData} changeBotData={changeBotData} />
        )}

        {step === 8 && (
          <CreateBotStep8 changeBotData={changeBotData} botData={botData} />
        )}

        {step === 9 && (
          <CreateBotReview
            botData={botData}
            setStep={setStep}
            setEditMode={setIsEditMode}
          />
        )}
      </>

      <div className={styles.handlersBtn}>
        {step > 1 && (
          <Button className={styles.backBtn} onClick={handlePreviousStep}>
            Back
          </Button>
        )}

        <div className={styles.rightBtn}>
          {isEditMode && step !== 9 && (
            <span className={styles.skipBtn} onClick={() => setStep(9)}>
              Go to Confirmation
            </span>
          )}
          {step !== 9 && (
            <Button className={styles.nextBtn} onClick={handleNextStep}>
              Next
            </Button>
          )}
          {step === 9 && (
            <Button
              type={"primary"}
              loading={createBotLoading}
              className={styles.submitBtn}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateBotSteps;

// STYLES
import styles from "../styles/Logo.module.css";

// IMAGES
import logoImg from "../asset/Images/cryptocrispy (1).png";
import logoTxt from "../asset/Images/logo-text.png";

const Logo = function () {
    return (
        <div className={styles.container}>
            <img className={styles.logoImg} src={logoImg} />
            <img className={styles.logoTxt} src={logoTxt} />
        </div>);
}

export default Logo;
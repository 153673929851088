import React from 'react';
import {Modal as AntModal} from 'antd';

const Modal = ({title, open, handleOk, handleCancel, footer, content, width, cancelText, closable,centered}) => {
    return (
        <AntModal
            title={title}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={footer}
            width={width}
            cancelText={cancelText}
            closable={closable}
            centered={centered}
        >
            {content}
        </AntModal>
    );
};

export default Modal;

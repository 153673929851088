import { Radio } from "antd";
import { useSelector } from "react-redux";

const RiskAppetite = ({ amount, changeHandler, style }) => {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  return (
    <Radio.Group
      value={amount || profileData?.ai_assistant_risk_appetite}
      onChange={changeHandler}
      style={
        style
          ? style
          : {
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
            }
      }
    >
      <Radio value="1" defaultChecked={amount === "1"}>
        <span title="Slow and Steady">Very Cautious</span>
      </Radio>
      <Radio value="2" defaultChecked={amount === "2"}>
        <span title="Safe and Sound">Cautious</span>
      </Radio>
      <Radio value="3" defaultChecked={amount === "3"}>
        <span title="Steady as She Goes">Balanced</span>
      </Radio>
      <Radio value="4" defaultChecked={amount === "4"}>
        <span title="High Wire Act">Adventurous</span>
      </Radio>
      <Radio value="5" defaultChecked={amount === "5"}>
        <span title="All In">Very Adventurous</span>
      </Radio>
    </Radio.Group>
  );
};

export default RiskAppetite;

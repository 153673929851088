import styles from "../../../styles/Components/mini-app/mini-bots/MiniAppTopBot.module.css";
import {
  FaBalanceScale,
  FaCoins,
  FaPlus,
  FaRegMoneyBillAlt,
  FaRobot,
  FaSignal,
} from "react-icons/fa";
import { BsCalendarDate } from "react-icons/bs";
import { formatCustomDate } from "../../../utility/helper";
import { GoDependabot } from "react-icons/go";
import { AiFillTrophy } from "react-icons/ai";
import {
  MdAccountBalanceWallet,
  MdAssessment,
  MdTrendingUp,
} from "react-icons/md";
import { useState } from "react";
import MinimalCreateBotModal from "./create-bot/MinimalCreateBotModal";
import { capitalizeString } from "../../../utility/formatter-helper";

const formatAIMode = {
  only_ai: "Only AI",
  only_onchain: "Only on-chain",
  only_ta: "Only technical indicator",
  only_social: "Only social",
};

function MiniAppTopBot({ data }) {
  const {
    bot_name: name,
    ai_assistant_mode: aiMode,
    bot_created_date: date,
    budget_usd: budget,
    end_date: endDate,
    exchange_id: exchangeId,
    exchange_name: exchangeName,
    number_of_coins: coinsNum,
    profit_percent: profitPercent,
    signal_mode: signalMode,
    start_date: startDate,
    leverage,
    win_rate: winRate,
    market_type: marketType,
    risk_apetite: riskAppetite,
  } = data;

  const [showCreatBotModal, setShowCreatBotModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <FaRobot />
          Bot Owner
        </span>
        <p className={styles.botInfoValue}>{name}</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <BsCalendarDate />
          Activity Duration
        </span>
        <p className={styles.botInfoValue} style={{ fontSize: "13px" }}>
          {formatCustomDate(startDate)} - {formatCustomDate(endDate)}
        </p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <AiFillTrophy />
          Win Rate
        </span>
        <p className={styles.botInfoValue}>
          {winRate} {winRate !== "TBA" && "%"}
        </p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <MdTrendingUp />
          Market Type
        </span>
        <p className={styles.botInfoValue}>{capitalizeString(marketType)}</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <FaBalanceScale />
          Risk Appetite
        </span>
        <p className={styles.botInfoValue}>{riskAppetite}</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <MdAssessment />
          Leverage
        </span>
        <p className={styles.botInfoValue}>{leverage}</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <FaRegMoneyBillAlt />
          Profit
        </span>
        <p className={styles.botInfoValue}>{profitPercent?.toFixed(2)}%</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <FaCoins />
          Number of Coins
        </span>
        <p className={styles.botInfoValue}>{coinsNum}</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <MdAccountBalanceWallet />
          Minimum USDT
        </span>
        <p className={styles.botInfoValue}>{coinsNum * 10}$</p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <GoDependabot />
          AI Assistant Mode
        </span>
        <p className={styles.botInfoValue}>
          {aiMode === "default" ? "All Categories" : formatAIMode[aiMode]}
        </p>
      </div>

      <div className={styles.botInfo}>
        <span className={styles.botInfoTitle}>
          <FaSignal />
          Signal Mode
        </span>
        <p className={styles.botInfoValue}>
          {signalMode?.replaceAll("_", " ")}
        </p>
      </div>

      <div
        className={styles.addBotBtn}
        onClick={() => setShowCreatBotModal(true)}
      >
        <FaPlus size={14} />
      </div>

      {showCreatBotModal && (
        <MinimalCreateBotModal
          show={showCreatBotModal}
          setShow={setShowCreatBotModal}
          botData={data}
        />
      )}
    </div>
  );
}

export default MiniAppTopBot;

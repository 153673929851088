import React, { useEffect, useState } from "react";
import { InputNumber, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../styles/shared/trade-modal/SetLimitAmount.module.css";
import {
  setStopLossAmount,
  setStopLossAmountPercent,
  setStopLossUsdAmount,
  setTakeProfitAmount,
  setTakeProfitAmountPercent,
  setTakeProfitUsdAmount,
  setTradeAmount,
  setTradeAmountUsd,
} from "../../../../redux/trade/actions";

function SetLimitAmount({ type, isSelect }) {
  const {
    tradeState: {
      tradeType,
      secondTradeType,
      selectedAsset,
      tradeAmount,
      usdtAmount,
      assetAmount,
      tradeAmountUsd,
      assetAmountUsd,
      takeProfitAmount,
      takeProfitAmountUsd,
      stopLossAmount,
      stopLossAmountUsd,
      stopLossAmountPercent,
      takeProfitAmountPercent,
      modalMode: mode,
      basicTradeData,
      currentStep: step,
    },
    exchangeState: { priceData, priceLoading },
  } = useSelector((state) => state);

  const [amountError, setAmountError] = useState(false);
  const [sliderValue, setSliderValue] = useState(50); // Initial slider value set to 50%
  const sliderMax = 100;

  const dispatch = useDispatch();
  const changeNativeAmountHandler = (value) => {
    if (value > Number(assetAmount)) return;
    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent((value / assetAmount) * 100));
      dispatch(setStopLossAmount(value));
      dispatch(
        setStopLossUsdAmount(value * priceData[selectedAsset.split("/")[0]]),
      );
    } else {
      dispatch(setTakeProfitAmountPercent((value / assetAmount) * 100));
      dispatch(setTakeProfitAmount(value));
      dispatch(
        setTakeProfitUsdAmount(value * priceData[selectedAsset.split("/")[0]]),
      );
    }
  };

  const changeUsdAmountHandler = (value) => {
    if (value > Number(assetAmountUsd)) {
      return;
    }

    const percentage =
      ((value * 100) / assetAmount) * priceData[selectedAsset.split("/")[0]];

    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent(percentage));
      dispatch(
        setStopLossAmount(value / priceData[selectedAsset.split("/")[0]]),
      );
      dispatch(setStopLossUsdAmount(value));
    } else {
      dispatch(setTakeProfitAmountPercent(percentage));
      dispatch(
        setTakeProfitAmount(value / priceData[selectedAsset.split("/")[0]]),
      );
      dispatch(setTakeProfitUsdAmount(value));
    }
  };

  const handleSliderChange = (value) => {
    // setSliderValue(value);
    let percentage = value / 100;

    const nativeAmount = assetAmount * percentage;

    if (type === "stopLoss") {
      dispatch(setStopLossAmountPercent(value));
      dispatch(setStopLossAmount(nativeAmount));
      dispatch(
        setStopLossUsdAmount(
          nativeAmount * priceData[selectedAsset.split("/")[0]],
        ),
      );
    } else {
      dispatch(setTakeProfitAmountPercent(value));
      dispatch(setTakeProfitAmount(nativeAmount));
      dispatch(
        setTakeProfitUsdAmount(
          nativeAmount * priceData[selectedAsset.split("/")[0]],
        ),
      );
    }
  };

  useEffect(() => {
    if (tradeAmount > 0) {
      if (type === "stopLoss" && stopLossAmount > Number(assetAmount)) {
        setAmountError(true);
      }
      if (type === "takeProfit" && takeProfitAmount > Number(assetAmount)) {
        setAmountError(true);
      }
    }
  }, [
    assetAmount,
    stopLossAmount,
    takeProfitAmount,
    tradeAmount,
    tradeType,
    type,
    usdtAmount,
  ]);

  // Set a default value for the native and usd amount
  useEffect(() => {
    if (isSelect) {
      if (type === "stopLoss") {
        if (mode === "ai-suggestion" && step !== "set-limit-order") {
          dispatch(
            setStopLossAmountPercent(Number(basicTradeData.amountPercent)),
          );
        } else {
          dispatch(setStopLossAmountPercent(sliderMax));
        }
        // dispatch(setStopLossAmountPercent(sliderMax));
        dispatch(setStopLossAmount(assetAmount));
        dispatch(setStopLossUsdAmount(assetAmountUsd));
      } else {
        if (mode === "ai-suggestion" && step !== "set-limit-order") {
          dispatch(
            setTakeProfitAmountPercent(Number(basicTradeData.amountPercent)),
          );
        } else {
          dispatch(setTakeProfitAmountPercent(sliderMax));
        }
        // dispatch(setTakeProfitAmountPercent(sliderMax));
        dispatch(setTakeProfitAmount(assetAmount));
        dispatch(setTakeProfitUsdAmount(assetAmountUsd));
      }
    }
  }, [isSelect, step]);

  // update values based on the updated price
  useEffect(() => {
    if (Object.keys(priceData).length > 0 && isSelect) {
      // const percentage = sliderValue / 100;
      const percentage =
        type === "stopLoss"
          ? stopLossAmountPercent / 100
          : takeProfitAmountPercent / 100;
      const nativeAmount = assetAmount * percentage;
      if (type === "stopLoss") {
        dispatch(setStopLossAmount(nativeAmount));
        dispatch(
          setStopLossUsdAmount(
            nativeAmount * priceData[selectedAsset.split("/")[0]],
          ),
        );
      } else {
        dispatch(setTakeProfitAmount(nativeAmount));
        dispatch(
          setTakeProfitUsdAmount(
            nativeAmount * priceData[selectedAsset.split("/")[0]],
          ),
        );
      }
    }
  }, [
    assetAmount,
    dispatch,
    isSelect,
    priceData,
    selectedAsset,
    stopLossAmountPercent,
    takeProfitAmountPercent,
    type,
  ]);

  return (
    <div
      className={`${styles.container} ${
        !secondTradeType ? styles.inactive : ""
      }`}
    >
      <div className={styles.inputBox}>
        <label>Native Amount:</label>
        <InputNumber
          className={styles.input}
          min={
            Object.keys(priceData).length > 0
              ? 1 / priceData[selectedAsset.split("/")[0]]
              : 0.001
          }
          step={0.0001}
          max={Number(assetAmount)}
          value={type === "stopLoss" ? stopLossAmount : takeProfitAmount}
          status={amountError ? "error" : ""}
          onChange={changeNativeAmountHandler}
        />
      </div>

      {step !== "set-limit-order" && (
        <div className={styles.inputBox}>
          <label>USD Amount:</label>
          <InputNumber
            className={styles.input}
            value={
              type === "stopLoss" ? stopLossAmountUsd : takeProfitAmountUsd
            }
            min={1}
            max={assetAmount * priceData[selectedAsset.split("/")[0]]}
            step={0.01}
            status={amountError ? "error" : ""}
            onChange={changeUsdAmountHandler}
            formatter={(value) => `$${value}`}
            parser={(value) => value.replace("$", "")}
          />
        </div>
      )}

      <div className={styles.sliderBox}>
        <Slider
          min={
            Object.keys(priceData).length > 0
              ? 100.001 / (priceData[selectedAsset.split("/")[0]] * assetAmount)
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          value={
            type === "stopLoss"
              ? stopLossAmountPercent
              : takeProfitAmountPercent
          }
          onChange={handleSliderChange}
          className={styles.slider}
        />

        <InputNumber
          min={
            Object.keys(priceData).length > 0
              ? 100.001 / (priceData[selectedAsset.split("/")[0]] * assetAmount)
              : 0.001
          }
          max={sliderMax}
          step={0.01}
          style={{ borderRadius: ".4rem" }}
          value={
            type === "stopLoss"
              ? stopLossAmountPercent
              : takeProfitAmountPercent
          }
          onChange={handleSliderChange}
          formatter={(value) => `${parseFloat(value).toFixed(2)}%`}
          parser={(value) => value.replace("%", "")}
        />
      </div>
      {mode === "ai-suggestion" &&
        type === "stopLoss" &&
        step !== "set-limit-order" &&
        stopLossAmountPercent === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "stopLoss" &&
        step !== "set-limit-order" &&
        basicTradeData?.amountPercent &&
        stopLossAmountPercent !== Number(basicTradeData?.amountPercent) && (
          <p
            onClick={() =>
              dispatch(
                setStopLossAmountPercent(Number(basicTradeData?.amountPercent)),
              )
            }
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {mode === "ai-suggestion" &&
        type === "takeProfit" &&
        step !== "set-limit-order" &&
        takeProfitAmountPercent === Number(basicTradeData?.amountPercent) && (
          <p style={{ gridColumn: "span 2", marginBottom: 0 }}>
            The amount is suggested by AI.
          </p>
        )}
      {mode === "ai-suggestion" &&
        type === "takeProfit" &&
        step !== "set-limit-order" &&
        basicTradeData?.amountPercent &&
        takeProfitAmountPercent !== Number(basicTradeData?.amountPercent) && (
          <p
            onClick={() =>
              dispatch(
                setTakeProfitAmountPercent(
                  Number(basicTradeData?.amountPercent),
                ),
              )
            }
            style={{
              gridColumn: "span 2",
              marginBottom: 0,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Reset to AI suggestion.
          </p>
        )}

      {amountError && (
        <p className={styles.errorMessage}>
          The amount you enter should be lower than the amount of the $
          {selectedAsset} in your portfolio. {Number(assetAmount)}
        </p>
      )}
    </div>
  );
}

export default SetLimitAmount;

import styles from "../../styles/pages/pricingPlan/index.module.sass";
import BasicPlan from "./plans/BasicPlan";
import ProPlan from "./plans/ProPlan";
import EnterprisePlan from "./plans/EnterprisePlan";

function PlansList() {
  return (
    <div className={styles.plans}>
      <BasicPlan />
      <ProPlan />
      <EnterprisePlan />
    </div>
  );
}

export default PlansList;

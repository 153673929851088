import { useNavigate } from "react-router";
import coming from "../asset/Images/comming-soon.jpg";

function ComingSoon() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <img
        style={{ width: "500px", marginBottom: "1rem" }}
        src={coming}
        alt="Coming soon"
      />
      <h2>Trade Simulator</h2>
      <p>Our trade simulator feature will be coming soon. Stay tuned!</p>
      <span
        style={{
          backgroundColor: "#0C4CFC",
          padding: ".3rem 1.5rem",
          borderRadius: ".3rem",
          cursor: "pointer",
          color: "white",
          fontWeight: "bold",
        }}
        onClick={() => navigate("/dashboard")}
      >
        Go to Dashboard
      </span>
    </div>
  );
}

export default ComingSoon;

import { useEffect, useState } from "react";
import axios from "axios";
import { setToken, setUserData } from "../redux/main/action/main";
import { formatUserData } from "../utility/helper";
import { getProfileData } from "../redux/profile/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import styles from "../styles/pages/TokenExpired.module.css";
import logo from "../asset/Images/cryptocrispyLogo.png";
import MiniAppLoginPage from "../components/mini-app/auth/MiniAppLoginPage";

function TokenExpired() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState("");

  const telegramData = localStorage.getItem("telegramData");
  const telegram_id = telegramData.slice(0, 20);
  const user = telegramData.slice(20, 25);
  const email = telegramData.slice(25, 30);

  const urlString = localStorage.getItem("prevPath");
  // Split the string by '*'
  const parts = urlString.split("*");

  // Get the base URL from the first part
  const baseUrl = parts[0];
  // Find the index where the base URL ends
  const baseUrlIndex = urlString.indexOf(baseUrl);

  // Extract the substring after the base URL
  const path = parts[1].substring(baseUrlIndex + baseUrl.length);

  const checkAuthentication = async () => {
    if (error) setError("");
    setStatus("loading");
    await axios
      .post(
        "https://0znr1xesf9.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "login",
          telegram_id,
          email,
          user,
        },
      )
      .then((res) => {
        const {
          accessToken: { jwtToken, payload },
        } = res?.data;
        setStatus("done");
        // console.log(res?.data);
        dispatch(setToken(jwtToken));
        dispatch(setUserData(formatUserData(res?.data?.accessToken)));
        axios.defaults.headers.common["Authorization"] = `${jwtToken}`;
        dispatch(
          getProfileData(payload?.email, payload["custom:custom_username"]),
        );

        localStorage.setItem("telegramData", telegramData);
        if (path) {
          navigate(path);
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setStatus("error");
        setError(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (telegram_id && telegram_id !== "null") {
      checkAuthentication();
    } else {
      navigate("/telegram-authentication");
    }
  }, [telegram_id]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={logo} alt={"logo"} />
      </div>

      {status === "loading" && (
        <div className={styles.loader}>
          <Spin />
        </div>
      )}

      {status === "error" && <MiniAppLoginPage />}
    </div>
  );
}

export default TokenExpired;

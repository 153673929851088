import {
  ACTIVATE_BOT_ERROR,
  ACTIVATE_BOT_REQUEST,
  ACTIVATE_BOT_SUCCESS,
  CHECK_BOT_STATUS_REQUEST,
  CHECK_BOT_STATUS_SUCCESS,
  CREATE_BACKTEST_ERROR,
  CREATE_BACKTEST_REQUEST,
  CREATE_BACKTEST_SUCCESS,
  CREATE_BOT_ERROR,
  CREATE_BOT_REQUEST,
  CREATE_BOT_SUCCESS,
  DEACTIVATE_BOT_ERROR,
  DEACTIVATE_BOT_REQUEST,
  DEACTIVATE_BOT_SUCCESS,
  DELETE_BACKTEST_ERROR,
  DELETE_BACKTEST_REQUEST,
  DELETE_BACKTEST_SUCCESS,
  DELETE_BOT_ERROR,
  DELETE_BOT_REQUEST,
  DELETE_BOT_SUCCESS,
  GET_BACKTESET_REPORT_ERROR,
  GET_BACKTESET_REPORT_PDF_ERROR,
  GET_BACKTESET_REPORT_PDF_REQUEST,
  GET_BACKTESET_REPORT_PDF_SUCCESS,
  GET_BACKTESET_REPORT_REQUEST,
  GET_BACKTESET_REPORT_SUCCESS,
  GET_BACKTEST_HISTORY_ERROR,
  GET_BACKTEST_HISTORY_REQUEST,
  GET_BACKTEST_HISTORY_SUCCESS,
  GET_BOT_ACTIVITY_ERROR,
  GET_BOT_ACTIVITY_REQUEST,
  GET_BOT_ACTIVITY_SUCCESS,
  GET_BOT_LIST_ERROR,
  GET_BOT_LIST_REQUEST,
  GET_BOT_LIST_SUCCESS,
  GET_BOT_REPORT_ERROR,
  GET_BOT_REPORT_PDF_ERROR,
  GET_BOT_REPORT_PDF_REQUEST,
  GET_BOT_REPORT_PDF_SUCCESS,
  GET_BOT_REPORT_REQUEST,
  GET_BOT_REPORT_SUCCESS,
  GET_BOT_STATISTICS_REPORT_ERROR,
  GET_BOT_STATISTICS_REPORT_REQUEST,
  GET_BOT_STATISTICS_REPORT_SUCCESS,
  GET_BOT_STATUS_ERROR,
  GET_BOT_STATUS_LOADING,
  GET_BOT_STATUS_SUCCESS,
  SELECT_BACKTEST,
  SELECT_BOT_ACTIVITY,
  SELL_ALL_CRYPTO_ASSETS_ERROR,
  SELL_ALL_CRYPTO_ASSETS_REQUEST,
  SELL_ALL_CRYPTO_ASSETS_SUCCESS,
  SELL_ALL_FIAT_ASSETS_ERROR,
  SELL_ALL_FIAT_ASSETS_REQUEST,
  SELL_ALL_FIAT_ASSETS_SUCCESS,
} from "./consts";
import axios from "axios";
import alert from "../../components/alerts/Alert";
import { getProfileData } from "../profile/actions";
import { axiosErrorHandler } from "../../utility/action-helper";

const baseUrl =
  "https://ez5zd19dfh.execute-api.us-east-1.amazonaws.com/dev/api";

export const getBotList =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_LIST_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "get_bots_list",
        user,
        email,
      })
      .then((res) => {
        dispatch({ type: GET_BOT_LIST_SUCCESS, payload: res.data?.bots_list });
        const activeBot = res.data?.bots_list?.find(
          (bot) => bot?.bot_status === "active",
        );
        if (activeBot) {
          const {
            bot_configurations: { selected_exchange: botExchange },
            CreatedDate: botId,
          } = activeBot;
          // dispatch(getBotStatus(user, email, botId, botExchange));
        }
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBotList(user, email, retryCount + 1),
          GET_BOT_LIST_ERROR,
          user,
          email,
          "get_bots_list",
        );
        console.log(err);
      });
  };

export const createBot =
  (user, email, bot_configurations, showAlert = true, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: CREATE_BOT_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "create_bot",
        user,
        email,
        bot_configurations,
      })
      .then((res) => {
        dispatch({ type: CREATE_BOT_SUCCESS, payload: res.data?.MESSAGE });
        if (showAlert) {
          alert(null, res.data?.MESSAGE, "success", {
            cancel: "Ok",
          });
        }
        dispatch(getBotList(user, email));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            createBot(
              user,
              email,
              bot_configurations,
              showAlert,
              retryCount + 1,
            ),
          CREATE_BOT_ERROR,
          user,
          email,
          "create_bot",
        );
      });
  };

export const clearCreateBotData = () => (dispatch) => {
  dispatch({ type: "clear-create-bot-data" });
};

export const activateBot =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_BOT_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "activate_bot",
        user,
        email,
        created_date,
      })
      .then((res) => {
        dispatch({ type: ACTIVATE_BOT_SUCCESS, payload: res.data?.MESSAGE });
        alert(null, res.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
        dispatch(getBotList(user, email));
        // dispatch(getBacktestHistory(user, email));
        dispatch(getBotActivity(user, email));
        dispatch(getProfileData(email, user));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => activateBot(user, email, created_date, retryCount + 1),
          ACTIVATE_BOT_ERROR,
          user,
          email,
          "activate_bot",
        );
      });
  };

export const deactivateBot =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DEACTIVATE_BOT_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "deactivate_bot",
        created_date,
        user,
        email,
      })
      .then((res) => {
        dispatch({ type: DEACTIVATE_BOT_SUCCESS, payload: res.data?.MESSAGE });
        alert(null, res.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
        dispatch(getBotList(user, email));
        dispatch(getBotActivity(user, email));
        dispatch(getProfileData(email, user));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deactivateBot(user, email, created_date, retryCount + 1),
          DEACTIVATE_BOT_ERROR,
          user,
          email,
          "deactivate_bot",
        );
      });
  };

export const deleteBot =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DELETE_BOT_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "delete_bot",
        user,
        email,
        created_date,
      })
      .then((res) => {
        dispatch({ type: DELETE_BOT_SUCCESS, payload: res.data?.MESSAGE });
        alert(null, res.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
        dispatch(getBotList(user, email));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deleteBot(user, email, created_date, retryCount + 1),
          DELETE_BOT_ERROR,
          user,
          email,
          "delete_bot",
        );
      });
  };

export const createBacktest =
  (
    user,
    email,
    created_date,
    start_date,
    end_date,
    market_type,
    margin_leverage,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: CREATE_BACKTEST_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "create_backtest_request",
        user,
        email,
        created_date,
        start_date,
        end_date,
        market_type,
        margin_leverage,
      })
      .then((res) => {
        dispatch({ type: CREATE_BACKTEST_SUCCESS, payload: res.data?.MESSAGE });
        dispatch(getBacktestHistory(user, email));
        alert(null, res.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            createBacktest(
              user,
              email,
              created_date,
              start_date,
              end_date,
              market_type,
              margin_leverage,
              retryCount + 1,
            ),
          CREATE_BACKTEST_ERROR,
          user,
          email,
          "create_backtest_request",
        );
      });
  };

export const deleteBacktest =
  (user, email, created_date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DELETE_BACKTEST_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "delete_backtest_request",
        user,
        email,
        created_date,
      })
      .then((res) => {
        dispatch({ type: DELETE_BACKTEST_SUCCESS, payload: res.data?.MESSAGE });
        dispatch(getBacktestHistory(user, email));
        alert(null, res.data?.MESSAGE, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => deleteBacktest(user, email, created_date, retryCount + 1),
          DELETE_BACKTEST_ERROR,
          user,
          email,
          "delete_backtest_request",
        );
      });
  };

export const getBacktestHistory =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BACKTEST_HISTORY_REQUEST });

    await axios
      .post(baseUrl, {
        request_type: "get_backtest_history",
        user,
        email,
      })
      .then((res) => {
        dispatch({
          type: GET_BACKTEST_HISTORY_SUCCESS,
          payload: res.data?.items,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBacktestHistory(user, email, retryCount + 1),
          GET_BACKTEST_HISTORY_ERROR,
          user,
          email,
          "get_backtest_history",
        );
      });
  };

export const getBacktestReport =
  (user, email, createdDate, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BACKTESET_REPORT_REQUEST });

    const requestPayload = {
      request_type: "get_backtest_report",
      user,
      email,
      createdDate,
    };

    await axios
      .post(
        "https://q6lgyd4c1m.execute-api.us-east-1.amazonaws.com/dev/api",
        requestPayload,
      )
      .then((res) => {
        dispatch({
          type: GET_BACKTESET_REPORT_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBacktestReport(user, email, createdDate, retryCount + 1),
          GET_BACKTESET_REPORT_ERROR,
          user,
          email,
          "get_backtest_report",
        );
      });
  };

export const getBacktestReportPdf =
  (user, email, createdDate, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BACKTESET_REPORT_PDF_REQUEST });

    await axios
      .post("https://q6lgyd4c1m.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "get_backtest_report",
        user,
        email,
        createdDate,
        only_pdf_report: "True",
      })
      .then((res) => {
        dispatch({
          type: GET_BACKTESET_REPORT_PDF_SUCCESS,
          payload: res?.data?.pdf_url,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBacktestReportPdf(user, email, createdDate, retryCount + 1),
          GET_BACKTESET_REPORT_PDF_ERROR,
          user,
          email,
          "get_backtest_report",
        );
      });
  };

export const selectBackteset = (data) => (dispatch) => {
  dispatch({ type: SELECT_BACKTEST, payload: data });
};

export const getBotActivity =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_ACTIVITY_REQUEST });

    await axios
      .post("https://ez5zd19dfh.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "get_overall_bot_activity",
        user,
        email,
      })
      .then((res) => {
        dispatch({ type: GET_BOT_ACTIVITY_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBotActivity(user, email, retryCount + 1),
          GET_BOT_ACTIVITY_ERROR,
          user,
          email,
          "get_overall_bot_activity",
        );
      });
  };

export const getBotPerformanceReport =
  (user, email, trader_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_REPORT_REQUEST });

    await axios
      .post("https://q6lgyd4c1m.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "get_bot_performance",
        user,
        email,
        trader_id,
      })
      .then((res) => {
        dispatch({ type: GET_BOT_REPORT_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBotPerformanceReport(user, email, trader_id, retryCount + 1),
          GET_BOT_REPORT_ERROR,
          user,
          email,
          "get_bot_performance",
        );
      });
  };

export const getBotPerformanceReportPdf =
  (user, email, trader_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_REPORT_PDF_REQUEST });

    await axios
      .post("https://q6lgyd4c1m.execute-api.us-east-1.amazonaws.com/dev/api", {
        request_type: "get_bot_performance",
        user,
        email,
        trader_id,
        only_pdf_report: "True",
      })
      .then((res) => {
        dispatch({
          type: GET_BOT_REPORT_PDF_SUCCESS,
          payload: res?.data?.pdf_url,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getBotPerformanceReportPdf(user, email, trader_id, retryCount + 1),
          GET_BOT_REPORT_PDF_ERROR,
          user,
          email,
          "get_bot_performance/only_pdf",
        );
      });
  };

export const setSelectedBot = (bot) => {
  return { type: SELECT_BOT_ACTIVITY, payload: bot };
};

export const getBotStatus =
  (user, email, created_date, bot_exchange, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_STATUS_LOADING });

    await axios
      .post("https://ez5zd19dfh.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        created_date,
        bot_exchange,
        request_type: "get_bot_status",
      })
      .then((res) => {
        dispatch({ type: GET_BOT_STATUS_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getBotStatus(
              user,
              email,
              created_date,
              bot_exchange,
              retryCount + 1,
            ),
          GET_BOT_STATUS_ERROR,
          user,
          email,
          "get_bot_status",
        );
      });
  };

export const checkBotStatus =
  (user, email, created_date, bot_exchange, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: CHECK_BOT_STATUS_REQUEST });

    await axios
      .post("https://ez5zd19dfh.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        created_date,
        bot_exchange,
        request_type: "get_bot_status",
      })
      .then((res) => {
        dispatch({ type: CHECK_BOT_STATUS_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            checkBotStatus(
              user,
              email,
              created_date,
              bot_exchange,
              retryCount + 1,
            ),
          GET_BOT_STATUS_ERROR,
          user,
          email,
          "get_bot_status",
        );
      });
  };

export const getBotStatistics =
  (user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_BOT_STATISTICS_REPORT_REQUEST });

    await axios
      .post("https://ez5zd19dfh.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        request_type: "get_traders_bot_stat",
      })
      .then((res) => {
        dispatch({
          type: GET_BOT_STATISTICS_REPORT_SUCCESS,
          payload: res?.data?.TRADES_REPORT,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getBotStatistics(user, email, retryCount + 1),
          GET_BOT_STATISTICS_REPORT_ERROR,
          user,
          email,
          "get_traders_bot_stat",
        );
      });
  };

export const sellAllFiatAssets =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: SELL_ALL_FIAT_ASSETS_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "sell_all_fiat_assets",
          user,
          email,
          exchange_id,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: SELL_ALL_FIAT_ASSETS_SUCCESS,
          payload: res?.data?.message,
        });
        alert(null, res.data?.message, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => sellAllFiatAssets(user, email, exchange_id, retryCount + 1),
          SELL_ALL_FIAT_ASSETS_ERROR,
          user,
          email,
          "sell_all_fiat_assets",
        );
      });
  };

export const sellAllCryptoAssets =
  (user, email, exchange_id, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: SELL_ALL_CRYPTO_ASSETS_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "sell_all_crypto_assets",
          user,
          email,
          exchange_id,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: SELL_ALL_CRYPTO_ASSETS_SUCCESS,
          payload: res?.data?.message,
        });
        alert(null, res.data?.message, "success", {
          cancel: "Ok",
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => sellAllCryptoAssets(user, email, exchange_id, retryCount + 1),
          SELL_ALL_CRYPTO_ASSETS_ERROR,
          user,
          email,
          "sell_all_crypto_assets",
        );
      });
  };

import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { handleBlur } from "../../../utility/action";
import styles from "../../../styles/Components/mini-app/auth/MiniAppLoginPage.module.css";
import SixDigitInput from "./SixDigitInput";
import { FaGoogle, FaRegEdit } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  otpCodeValidation,
  otpEmailValidation,
} from "../../../utility/Validation/validation";
import axios from "axios";
import { checkEmailException } from "../../../utility/helper";
import { notify } from "../../../utility/Toastify/toastify";
import alert from "../../alerts/Alert";

function MiniAppOtpLogin({ children, setAuthData, navigate, isGoogleLogin }) {
  const [otpStep, setOtpStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState(isGoogleLogin ? "@gmail.com" : "");

  const validationSchema =
    otpStep === 1 ? otpEmailValidation : otpCodeValidation;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmitOtp = async (data) => {
    if (loading) return;
    setLoading(true);
    if (otpStep === 1) {
      setEmail(data?.email);
      await axios
        .post(
          "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
          {
            operation_mode: "send_verification_code",
            email: checkEmailException(data?.email),
          },
        )
        .then((res) => {
          notify("Verification code sent", "success");
          setOtpStep(2);
        })
        .catch((err) => {
          alert(null, err?.response?.data?.message, "error", {
            cancel: "Ok",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (otpStep === 2) {
      setCode(data?.code);
      await axios
        .post(
          "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
          {
            operation_mode: "verify_verification_code",
            email: checkEmailException(data?.email),
            verification_code: data?.code,
          },
        )
        .then((res) => {
          setAuthData(res?.data, "telegram");
          navigate();
        })
        .catch((err) => {
          alert(null, err?.response?.data?.message, "error", {
            cancel: "Ok",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Form onFinish={handleSubmit(onSubmitOtp)}>
      {isGoogleLogin && otpStep === 1 && (
        <h4 style={{ fontWeight: 600 }}>
          {/*<FaGoogle /> */}
          In Telegram mini-app you can get an one-time-password (OTP) to login!
          Enter your gmail.
        </h4>
      )}
      {otpStep === 1 && (
        <>
          <Form.Item
            label={isGoogleLogin ? "Gmail" : "Email"}
            validateStatus={errors.email ? "error" : ""}
            help={errors.email ? errors.email.message : ""}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) =>
                isGoogleLogin ? (
                  <Input
                    {...field}
                    type="text"
                    value={email.replace("@gmail.com", "")} // Show only the username part
                    onBlur={(e) => {
                      field.onBlur(e);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      const username = e?.target?.value.replace(
                        "@gmail.com",
                        "",
                      ); // Prevent appending multiple '@gmail.com'
                      setEmail(`${username}@gmail.com`); // Append the domain
                      field.onChange(`${username}@gmail.com`);
                    }}
                    addonAfter="@gmail.com"
                    autoComplete="email"
                  />
                ) : (
                  <Input
                    {...field}
                    type="email"
                    value={email}
                    onBlur={(e) => {
                      field.onBlur(e);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      setEmail(e?.target?.value);
                      field.onChange(e?.target?.value);
                    }}
                    autoComplete="email"
                  />
                )
              }
            />
          </Form.Item>
          <Form.Item
            style={{
              alignSelf: "center",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.submitBtn}
            >
              Send Code
            </Button>
          </Form.Item>
          {children}
        </>
      )}

      {otpStep === 2 && (
        <>
          <Form.Item
            label="Verification Code"
            validateStatus={errors.code ? "error" : ""}
            help={errors.code ? errors.code.message : ""}
          >
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <SixDigitInput
                  value={code}
                  onChange={(value) => {
                    field.onChange(value);
                    setCode(value);
                  }}
                  onBlur={(e) => {
                    field.onBlur(e);
                    handleBlur(e);
                  }}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            style={{
              alignSelf: "center",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.submitBtn}
            >
              Login
            </Button>
          </Form.Item>
          <Form.Item>
            <div
              className={styles.editEmailBtn}
              onClick={() => {
                setOtpStep(1);
                setCode("");
              }}
            >
              <FaRegEdit /> Edit {isGoogleLogin ? "gmail" : "email"}
            </div>
          </Form.Item>
        </>
      )}
    </Form>
  );
}

export default MiniAppOtpLogin;

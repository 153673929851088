import Modal from "./Modal";
import { Card, Col, Row, Statistic, Typography } from "antd";
import { useState } from "react";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { IoAnalyticsOutline } from "react-icons/io5";
import SignalStrengthUi from "../trading-assistant/SignalStrengthUi";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { formatPrice } from "../../utility/helper";

const getColor = (type) => {
  return type === "Buy" ? "#52c41a" : "#f5222d";
};

// A function that returns an arrow icon based on the trade type
const getArrow = (type) => {
  return type === "Buy" ? <BsArrowUp /> : <BsArrowDown />;
};

const textStyle = {
  color: "#666666",
};

const { Text } = Typography;

const SuggestionDetailsModal = ({ show, setShow, data }) => {
  const [ellipsis, setEllipsis] = useState(true);

  return (
    <Modal
      // title={`Trade suggestion for ${data.SYMBOL_NAME}`}
      footer={false}
      open={show}
      centered
      handleCancel={() => {
        setShow(false);
        setEllipsis(true);
      }}
      handleOk={() => {
        setShow(false);
        setEllipsis(true);
      }}
      width={800}
      content={
        data.MARKET_TYPE ? (
          <div>
            <Card
              title={
                // Show the symbol name and trade type as the card title
                <span
                  style={{ color: getColor(data.TRADE_TYPE), fontSize: 24 }}
                >
                  {data.SYMBOL_NAME} {getArrow(data.TRADE_TYPE)}{" "}
                  {data.TRADE_TYPE}
                </span>
              }
              extra={
                // Show the signal strength as a star rating component with custom icons and colors
                <SignalStrengthUi level={data.SIGNAL_STRENGTH} />
              }
              style={{ borderRadius: 10, margin: "1rem" }} // Custom style for the card border
            >
              <p>
                Based on the{" "}
                {data.CATEGORY_INFO.category === "On-Chain"
                  ? data.CATEGORY_INFO.category
                  : data.CATEGORY_INFO.category.replace(/-/g, " ")}{" "}
                ({data.CATEGORY_INFO.name}), you should {data.TRADE_TYPE}{" "}
                {data.SYMBOL_NAME} with an expected return of{" "}
                {data.EXPECTED_RETURN}
                %.
              </p>
              <Row gutter={16}>
                <Col span={12} sm={6}>
                  <Statistic
                    title="Current Price"
                    value={formatPrice(data.CURRENT_PRICE, 3)}
                    prefix={<AiOutlineDollarCircle />}
                    valueStyle={{ fontSize: "19px" }}
                  />
                </Col>
                <Col span={12} sm={6}>
                  <Statistic
                    title="Stop Loss"
                    value={formatPrice(data.STOP_LOSS, 3)}
                    prefix={<AiOutlineDollarCircle />}
                    valueStyle={{ fontSize: "19px" }}
                  />
                </Col>
                <Col span={12} sm={6}>
                  <Statistic
                    title="Take Profit"
                    value={formatPrice(data.TAKE_PROFIT, 3)}
                    prefix={<AiOutlineDollarCircle />}
                    valueStyle={{ fontSize: "19px" }}
                  />
                </Col>
                <Col span={12} sm={6}>
                  <Statistic
                    title="Maximum Profit"
                    value={formatPrice(data.CATEGORY_INFO.maximum_profit / 10)}
                    precision={2}
                    prefix={<MdOutlineRocketLaunch />}
                    suffix="%"
                    valueStyle={{ color: "#3f8600", fontSize: "19px" }}
                  />
                </Col>
                <Col span={12} sm={6}>
                  <Statistic
                    title="Median Profit"
                    value={formatPrice(data.CATEGORY_INFO.median_profit / 10)}
                    precision={2}
                    prefix={<MdOutlineRocketLaunch />}
                    suffix="%"
                    valueStyle={{ color: "#3f8600", fontSize: "19px" }}
                  />
                </Col>
                {data.CATEGORY_INFO.prediction_accuracy ? (
                  <Col span={12} sm={6}>
                    <Statistic
                      title="Prediction Accuracy"
                      value={data.CATEGORY_INFO.prediction_accuracy}
                      precision={2}
                      prefix={<IoAnalyticsOutline />}
                      suffix="%"
                      valueStyle={{ color: "#cf1322", fontSize: "19px" }}
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {/* <p style={{ marginTop: "1rem" }}>
                <GrLaunch /> This is a{" "}
                {data.SIGNAL_STRENGTH === 5
                  ? "strong"
                  : data.SIGNAL_STRENGTH === 4
                  ? "moderate"
                  : "weak"}{" "}
                {data.TRADE_TYPE.toLowerCase()} signal with a high maximum
                profit and a low prediction accuracy.
              </p> */}
              <p style={{ marginTop: "1rem" }}>
                {" "}
                The {data.CATEGORY_INFO.subcategory} indicators suggest that{" "}
                {data.SYMBOL_NAME} is in an{" "}
                {data.TRADE_TYPE === "Buy" ? "uptrend" : "downtrend"} and has a{" "}
                {data.TRADE_TYPE === "Buy" ? "positive" : "negative"} momentum.
              </p>
              <p style={{ marginTop: "1rem" }}>
                <span style={{ color: "#2B303A" }}>
                  {" "}
                  <AiOutlineInfoCircle /> Decription:
                </span>{" "}
                <Text
                  style={
                    ellipsis ? { width: 500, ...textStyle } : { ...textStyle }
                  }
                  ellipsis={!ellipsis ? false : { rows: 2 }}
                >
                  {data.CATEGORY_INFO.description}
                </Text>
                {data.CATEGORY_INFO.description.length > 100 ? (
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#0c4cfc",
                      marginLeft: ".5rem",
                    }}
                    onClick={() => setEllipsis(!ellipsis)}
                  >
                    Show {!ellipsis ? "less" : "more"}{" "}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </Card>
          </div>
        ) : (
          "Something went wrong."
        )
      }
    />
  );
};

export default SuggestionDetailsModal;

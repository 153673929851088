import AlertSettingStepHeader from "./AlertSettingStepHeader";
import styles from "../../../styles/Components/alert-system/alert-setting/AlertSettingsStep.module.css";
import React, { useEffect, useState } from "react";
import { TimePicker, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineInfoCircle } from "react-icons/ai";

const hourlyFrequencies = ["1H", "3H", "6H", "12H"];

const activeTime = [
  {
    title: "Morning",
    startTime: "6am",
    endTime: "12pm",
    start: 6,
    end: 11,
  },
  {
    title: "Afternoon",
    startTime: "12pm",
    endTime: "6pm",
    start: 12,
    end: 17,
  },
  {
    title: "Evening",
    startTime: "6pm",
    endTime: "12am",
    start: 18,
    end: 23,
  },
  {
    title: "Overnight",
    startTime: "12am",
    endTime: "6am",
    start: 0,
    end: 5,
  },
  {
    title: "All Day",
    startTime: "00:00",
    endTime: "23:59",
    start: 0,
    end: 23,
  },
];

function AlertNotificationTimeSetting({
  alertFrequency,
  setNotificationTime,
  notificationTime,
  timeRange,
  startTime: startTimeRange,
  endTime: endTimeRange,
}) {
  const [selectedRange, setSelectedRange] = useState(timeRange || "");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const isBasedOnHour = hourlyFrequencies.includes(alertFrequency);

  const changeRangeHandler = (e) => {
    const rangeEl = e.target.closest("li");
    if (rangeEl) {
      setNotificationTime("notificationTime", "");
      setNotificationTime("endTime", "");
      setNotificationTime("startTime", "");

      const range = rangeEl.id;
      setSelectedRange(range);
      if (range !== "custom") {
        const rangeData = activeTime.find((t) => t.title === range);
        setNotificationTime("timeRange", rangeData.title);

        const startTime = moment()
          .set({ hour: rangeData.start, minute: 0, second: 0 }) // Set specific time
          .format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
        const endTime = moment()
          .set({ hour: rangeData.end, minute: 59, second: 0 }) // Set specific time
          .format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
        const notificationTime = moment()
          .set({ hour: rangeData.start, minute: 0, second: 0, millisecond: 0 }) // Set specific time
          .toISOString();

        setNotificationTime("notificationTime", notificationTime);
        setNotificationTime("endTime", endTime);
        setNotificationTime("startTime", startTime);
      } else {
        setNotificationTime("timeRange", "custom");
      }
    }
  };

  const handleTimeChange = (times) => {
    if (!times) {
      setStartTime(null);
      setEndTime(null);
      setNotificationTime("endTime", "");
      setNotificationTime("startTime", "");
      return;
    }

    const [start, end] = times;
    if (start && end && start.isAfter(end)) {
      // Swap start and end times
      setStartTime(end);
      setEndTime(start);
      setNotificationTime("notificationTime", end.toISOString());
      setNotificationTime(
        "endTime",
        start.format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
      setNotificationTime(
        "startTime",
        end.format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
    } else {
      setStartTime(start);
      setEndTime(end);
      setNotificationTime("notificationTime", start.toISOString());
      setNotificationTime(
        "endTime",
        end.format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
      setNotificationTime(
        "startTime",
        start.format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
    }
  };

  const changeNotificationTimeHandler = (e) => {
    setNotificationTime("notificationTime", e?.toISOString());
  };

  useEffect(() => {
    if (!isBasedOnHour) {
      setNotificationTime(
        "endTime",
        moment()
          .set({ hour: 23, minute: 59, second: 0, millisecond: 0 }) // Set specific time
          .format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
      setNotificationTime(
        "startTime",
        moment()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // Set specific time
          .format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      );
    }
  }, [alertFrequency]);

  useEffect(() => {
    if (timeRange === "custom" && endTimeRange && startTimeRange) {
      setStartTime(moment(startTimeRange, "ddd, DD MMM YYYY HH:mm:ss [GMT]"));
      setEndTime(moment(endTimeRange, "ddd, DD MMM YYYY HH:mm:ss [GMT]"));
    }
  }, [endTimeRange, startTimeRange, timeRange]);

  return (
    <div>
      <AlertSettingStepHeader
        title={"Preferred Notification Time"}
        caption={
          "Please specify your preferred time for receiving regular email notifications. Ensure you can access your email and are awake during these times."
        }
      />

      {isBasedOnHour && (
        <>
          <p>Select the time you prefer for alerts:</p>
          <ul
            className={styles.optionsBtn}
            style={{ margin: "2rem auto" }}
            onClick={changeRangeHandler}
          >
            {activeTime.map((item) => (
              <li
                key={item.title}
                id={item.title}
                className={`${styles.blueBtn} ${
                  selectedRange === item.title ? styles.selectedBtn : ""
                }`}
              >
                {item.title} ({item.startTime} - {item.endTime})
              </li>
            ))}
            <li
              id={"custom"}
              className={`${styles.blueBtn} ${
                selectedRange === "custom" ? styles.selectedBtn : ""
              }`}
            >
              Custom time interval
            </li>
          </ul>
        </>
      )}

      {/*{showCustomInput && }*/}
      {isBasedOnHour && selectedRange === "custom" && (
        <div className={styles.customTimeInput}>
          <span>Choose the interval active time range:</span>
          <TimePicker.RangePicker
            format={"HH:mm a"}
            onChange={handleTimeChange}
            value={[startTime, endTime]}
          />
        </div>
      )}

      {!isBasedOnHour && (
        <div className={styles.timePickerBox}>
          <div
            className={`${styles.blueBtn} ${styles.selectedBtn}`}
            style={{ width: "fit-content" }}
          >
            All day (00:00 - 23:59)
          </div>

          <div className={styles.customTimeInput}>
            <span>
              <Tooltip
                title={
                  "Any time between the start time interval and the end time interval\n"
                }
                color={"geekblue"}
                placement={"bottomLeft"}
              >
                <AiOutlineInfoCircle style={{ marginRight: 8 }} />
              </Tooltip>{" "}
              Select the time you prefer for alerts:
            </span>
            <TimePicker
              value={notificationTime ? moment(notificationTime) : null}
              format={"HH:mm a"}
              onSelect={changeNotificationTimeHandler}
              onChange={changeNotificationTimeHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AlertNotificationTimeSetting;

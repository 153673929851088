export default {
    part1: `
import requests


API_url = '<YOUR API URL>'

API_key = '<YOUR API KEY>'



email_address = '<Your email address>' 

user_name = '<Your username>' 





payload = \{'email' : email_address , 'user' : user_name, 'request_type': 'get_user_info'  , 'API_KEY' : API_key }\


response = requests.post(url=API_url,json=payload)

`,
    part2: `
import requests

API_url = '<YOUR API URL>'

API_key = '<YOUR API KEY>'



email_address = '<Your email address>' 

user_name = '<Your username>' 



payload = \{'email' : email_address , 'user' : user_name, 'request_type': 'get_available_markets'  , 'API_KEY' : API_key }\


response = requests.post(url=API_url,json=payload)
        `,
    part3: `import requests

API_url = '<YOUR API URL>'

API_key = '<YOUR API KEY>'



email_address = '<Your email address>'
 
user_name = '<Your username>' 

market_type = '<Market Type>' # For example crypto



payload = \{'email' : email_address ,'market_type' : market_type 'user' : user_name, 'request_type': 'get_initial_info'  , 'API_KEY' : API_key }\


response = requests.post(url=API_url,json=payload)
`,
    part4: `import requests

API_url = '<YOUR API URL>'

API_key = '<YOUR API KEY>'



email_address = '<Your email address>' 

user_name = '<Your username>' 

market_type = '<Market Type>' # For example crypto



feature_list = [{'symbol':'<Symbol>','category':'<category>','subcategory':'<subcategory>','feature':'<feature>'},...] 

# For example: <symbol> = 'MATIC' , <category> = 'AI-Signal' , <subcategory> = 'Predicted Price' ,<feature> = 'Next 24 H


payload = {'email' : email_address , 'user' : user_name ,'market_type' : market_type , 'request_type': 'get_signal_value' ,
 'feature_list':feature_list , 'API_KEY' : API_key }

response = requests.post(url=API_url,json=payload)
`
}

import { Skeleton } from "antd";

// STYLES
import styles from "../../styles/Components/dashboard/SkeletonContainer.module.css";


const SkeletonContainer = function () {
    return (
        <div className={styles.container}>
            <Skeleton.Input active={true} width={"100%"} />
        </div>
    )

}

export default SkeletonContainer;
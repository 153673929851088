import axios from "axios";

const initialState = {
  messages: [],
  messagesStatus: "",
  messagesError: "",
};

export const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "get_message_request":
      return {
        ...state,
        messagesStatus: "loading",
        messagesError: "",
      };
    case "get_message_success":
      return {
        ...state,
        messagesStatus: "idle",
        messages: payload,
      };
    case "get_message_error":
      return {
        ...state,
        messagesStatus: "error",
        messages: payload,
      };
    default:
      return state;
  }
};

export const getPublicMessage = () => async (dispatch) => {
  dispatch({ type: "get_message_request" });

  await axios
    .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
      platform_mode: "monitor",
      request_type: "get_latest_general_message",
    })
    .then((res) => {
      dispatch({ type: "get_message_success", payload: res?.data?.Items });
      // console.log(res);
    })
    .catch((err) => {
      dispatch({ type: "get_message_error", payload: err?.data?.message });
      console.log(err);
    });
};

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "../../../styles/Components/new-insight/chart-section/TradingViewWidget.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import chartPreview from "../../../asset/Images/chart-preview.png";

const TradingViewWidget = () => {
  const [searchParams] = useSearchParams();
  const symbol = searchParams.get("symbol") || "BTC";
  const [timezone, setTimezone] = useState("Etc/UTC");

  useEffect(() => {
    // Get user's time zone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone || "Etc/UTC");
  }, []);

  useEffect(() => {
    const chartScript = document.createElement("script");
    chartScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    chartScript.async = true;
    const formattedSymbol = `BINANCE:${symbol}USDT`;
    chartScript.innerHTML = JSON.stringify({
      autosize: true,
      symbol: formattedSymbol,
      interval: "D",
      timezone,
      theme: "light",
      style: "1",
      locale: "en",
      allow_symbol_change: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
      studies: ["MACD@tv-basicstudies"], // Add this line to include the MACD indicator
    });
    document.getElementById("chart_widget").appendChild(chartScript);

    // Cleanup script elements when component unmounts or symbol/timezone changes
    return () => {
      document.getElementById("ticker_widget").innerHTML = "";
      document.getElementById("chart_widget").innerHTML = "";
    };
  }, [symbol, timezone]);

  return (
    <div className={styles.container}>
      <div
        className="tradingview-widget-container"
        style={{ position: "relative", zIndex: 1 }}
      >
        <div id="ticker_widget"></div>
      </div>
      <div
        className="tradingview-widget-container"
        style={{ height: "100%", width: "100%" }}
      >
        <div
          id="chart_widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        ></div>
      </div>

      <LazyLoadImage
        src={chartPreview}
        alt="Chart loading..."
        height="370px"
        width="100%"
        effect="blur"
        wrapperClassName={styles.preview}
      />
    </div>
  );
};

export default TradingViewWidget;

import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import styles from "../../styles/Components/tables/portfolioTable.module.sass";
import Modal from "../modal/Modal";

const PreviousEmails = ({ data }) => {
  const changeTimeZoneHandler = (time) => {
    const date = new Date(time);
    const dateAsString = date.toLocaleString();
    return dateAsString;
  };
  const [selectedEmail, setSelectedEmail] = useState(0);
  const date = new Date();
  const dateAsString = date.toString();
  const localTimeZone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      align: "center",
      width: 50,
      responsive: ["md"],

      render: (text) => (
        <span className={styles.tableContent}>{text.split("_")[1]}</span>
      ),
    },
    {
      title: `Date (${localTimeZone})`,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 50,
      responsive: ["xs", "sm"],
      render: (text) => (
        <span className={styles.tableContent}>
          {changeTimeZoneHandler(new Date(text))}
        </span>
      ),
    },

    {
      title: "Details",
      dataIndex: "showEmail",
      key: "showEmail",
      width: 50,
      align: "center",
      responsive: ["xs", "sm"],

      render: (text) => (
        <Button
          onClick={() => {
            setOpen(true);
            setSelectedEmail(text.id);
          }}
        >
          Show
        </Button>
      ),
    },
  ];
  const [tabletData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  useEffect(() => {
    setTableData(
      data?.map((item, index) => {
        return {
          key: index,
          user: item?.user,
          date: item?.sent_date,
          showEmail: { id: index, ...item },
        };
      })
    );
  }, [data]);

  // console.log(data[0]?.jsx_email)
  return (
    <div className={styles.trackedSignalsWrapper}>
      <h3>Alert system emails</h3>
      <p>View the latest alerts sent by email.</p>
      <Table
        columns={columns}
        pagination={false}
        dataSource={tabletData}
        scroll={{ y: 800 }}
        className={styles.table}
      />
      <Modal
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: data?.[selectedEmail]?.html_txt,
            }}
          />
        }
      />
    </div>
  );
};

export default PreviousEmails;

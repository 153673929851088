// track signals
export const TRACK_SIGNALS_REQUEST = "TRACK_SIGNALS_REQUEST";
export const SET_TRACK_SIGNALS_SUCCESS = "SET_TRACK_SIGNALS_SUCCESS";
export const DISABLE_TRACK_SIGNALS_SUCCESS = "DISABLE_TRACK_SIGNALS_SUCCESS";
export const GET_TRACK_SIGNALS_SUCCESS = "GET_TRACK_SIGNALS_SUCCESS";
export const TRACK_SIGNALS_ERROR = "TRACK_SIGNALS_ERROR";

// get tracked Signals
export const GET_TRACKED_SIGNALS_REQUEST = "GET_TRACKED_SIGNALS_REQUEST";
export const GET_TRACKED_SIGNALS_SUCCESS = "GET_TRACKED_SIGNALS_SUCCESS";
export const GET_TRACKED_SIGNALS_ERROR = "GET_TRACKED_SIGNALS_ERROR";

// activate automatic alert system
export const ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST =
  "ACTIVATE_AUTOMATIC_ALERT_SYSTEM_REQUEST";
export const ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS =
  "ACTIVATE_AUTOMATIC_ALERT_SYSTEM_SUCCESS";
export const ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR =
  "ACTIVATE_AUTOMATIC_ALERT_SYSTEM_ERROR";

// get alert status
export const GET_ALERT_STATUS_SUCCESS = "GET_ALERT_STATUS_SUCCESS";
export const GET_ALERT_STATUS_REQUEST = "GET_ALERT_STATUS_REQUEST";
export const GET_ALERT_STATUS_ERROR = "GET_ALERT_STATUS_ERROR";

// get previous emails
export const GET_PREVIOUS_EMAILS_SUCCESS = "GET_PREVIOUS_EMAILS_SUCCESS";
export const GET_PREVIOUS_EMAILS_REQUEST = "GET_PREVIOUS_EMAILS_REQUEST";
export const GET_PREVIOUS_EMAILS_ERROR = "GET_PREVIOUS_EMAILS_ERROR";

export const GET_TELEGRAM_BOT_TOKEN_REQUEST = "GET_TELEGRAM_BOT_TOKEN_REQUEST";
export const GET_TELEGRAM_BOT_TOKEN_SUCCESS = "GET_TELEGRAM_BOT_TOKEN_SUCCESS";
export const GET_TELEGRAM_BOT_TOKEN_ERROR = "GET_TELEGRAM_BOT_TOKEN_ERROR";

// IMAGES
import in1 from "../../asset/Images/faq/Insights/HowToUseAISignals/1.PNG";
import in2 from "../../asset/Images/faq/Insights/HowToUseAISignals/2.png";
import in3 from "../../asset/Images/faq/Insights/HowToUseAISignals/3.png";
import in4 from "../../asset/Images/faq/Insights/HowToUseAISignals/4.png";
import in5 from "../../asset/Images/faq/Insights/HowToUseAISignals/5.png";
import in5_1 from "../../asset/Images/faq/Insights/HowToUseAISignals/5-1.png";
import in5_2 from "../../asset/Images/faq/Insights/HowToUseAISignals/5-2.png";

// STYLES
import "../../styles/Components/faq/faqPublic.css";
import { useNavigate } from "react-router";
import { BiChevronLeft } from "react-icons/bi";
import faqImg from "../../asset/Images/faq.png";
import styles from "../../styles/pages/Help/index.module.sass";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GetPricePredictions = function () {
  const navigate = useNavigate();
  return (
    <div className="helpContainer">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          color: "#0c4cfc",
          cursor: "pointer",
        }}
        onClick={() => navigate("/faq/all")}
      >
        <BiChevronLeft style={{ marginRight: ".3rem" }} />
        Back
      </div>
      <h3 className="helpTitle">How to get AI price predictions?</h3>
      <p>
        Price predictions powered by AI can help you discover price actions and
        find trading opportunities. Follow these steps to access price
        predictions on the Insights platform:{" "}
      </p>

      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 1: </span>Navigate to the Insights
          from the left-hand menu.
        </p>
        <LazyLoadImage
          src={in1}
          alt="Get Price predictions"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in1} alt="Get Price predictions" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 2: </span>Select "AI-Signal" on the
          left side.
        </p>
        <LazyLoadImage
          src={in2}
          alt="Get Price predictions"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in2} alt="Get Price predictions" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 3: </span>Select "Predicted Price"
          from the available options.
        </p>
        <LazyLoadImage
          src={in3}
          alt="Get Price predictions"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in3} alt="Get Price predictions" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 4: </span>Choose the time interval
          for the prediction. For example, if you want to know the forecast for
          the next 6 hours, select "Next 6 Hours Price".
        </p>
        <LazyLoadImage
          src={in4}
          alt="Get Price predictions"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in4} alt="Get Price predictions" />*/}
      </div>
      <div className="stepContainer">
        <p>
          <span className="stepNumber">Step 5: </span> You will be prompted to
          choose the coin for which you want the prediction generated.
        </p>
        <LazyLoadImage
          src={in5}
          alt="Get Price predictions"
          effect="blur"
          wrapperClassName="imageContainer"
        />
        {/*<img className="helpImage" src={in5} alt="Get Price predictions" />*/}
        <div className="subStepWrapper">
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 5-1:</span> Once you've selected
              your coin, you can view the historical predictions in the "Chart"
              area on the right-hand side. The current predictions can be seen
              in the "Signals" area on the right-hand side.
            </p>
            <LazyLoadImage
              src={in5_1}
              alt="Get Price predictions"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img*/}
            {/*  className="helpImage"*/}
            {/*  src={in5_1}*/}
            {/*  alt="Get Price predictions"*/}
            {/*/>*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 5-2:</span> In the "Signals"
              area, we provide suggested buy/sell signals or long/short
              positions based on the prediction. You can find detailed
              information about the profitability of the signal and the
              prediction confidence in this area.
            </p>
            <LazyLoadImage
              src={in5_2}
              alt="Get Price predictions"
              effect="blur"
              wrapperClassName="imageContainer"
            />
            {/*<img*/}
            {/*  className="helpImage"*/}
            {/*  src={in5_2}*/}
            {/*  alt="Get Price predictions"*/}
            {/*/>*/}
          </div>
          <div className="subStepContainer">
            <p>
              <span className="stepNumber">Step 5-3:</span> After you've made
              your decision, you can go to your exchange and execute your trade.
              You can also save your trade actions on our platform to manage
              your portfolio and receive suggestions from our alert system.
              Please note that our platform does not offer trading
              functionalities.
            </p>
            {/* <img
              className="helpImage"
              src={insights5_1}
              alt="Get Price predictions"
            /> */}
          </div>
        </div>
      </div>
      <p>
        By following these steps, you can access price predictions powered by AI
        on the Insights platform and use them to inform your trading decisions.
      </p>
    </div>
  );
};

export default GetPricePredictions;
